

















import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WsLazyRender extends Vue {
    @Prop({ default: () => [] }) public items!: any[];
    @Prop({ default: 20 }) public chunkSize!: number;
    @Prop({ default: 0.3 }) public threshold!: number;
    /*
        Do not remove line with prop min height because it breaks lazy render
     */
    @Prop({ default: 200 }) public minHeight!: number;

    public vLazyModel: boolean[] = [];

    get chunks() {
        return _.chunk(this.items, this.chunkSize);
    }

    get firstChunk() {
        return this.chunks[0];
    }

    get lazyChunks() {
        return this.chunks.slice(1);
    }
}
