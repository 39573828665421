









import { Component, Prop, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class WsTableHeaderSkeleton extends Vue {
    @Prop({ type: Object, default: () => ({}) }) public options!: any;
}
