

















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IssueTrackerFields } from '@/constants';
import {
    DiffComment,
    IssueTrackerDiffInlineProperties,
    IssueTrackerDiffMultilineProperties,
    IssueTrackerDiffSpecialProperties,
} from '@/models';
import IssueCommentDeletedAt from '@/components/project/issueTracker/comments/IssueCommentDeletedAt.vue';
import IssueStatusText from '@/components/project/issueTracker/IssueStatusText.vue';
import IssueCommentDiffMultipleItems from '@/components/project/issueTracker/comments/IssueCommentDiffMultipleItems.vue';
import IssueCommentDiffChanged from '@/components/project/issueTracker/comments/IssueCommentDiffChanged.vue';
import IssuePriorityText from '@/components/project/issueTracker/IssuePriorityText.vue';
import IssueCommentDiffInline from '@/components/project/issueTracker/comments/IssueCommentDiffInline.vue';
import IssueCommentDiffCde from '@/components/project/issueTracker/comments/IssueCommentDiffCde.vue';
import IssueCommentDiffProcore from '@/components/project/issueTracker/comments/IssueCommentDiffProcore.vue';
import IssueCommentDiffStatus from '@/components/project/issueTracker/comments/IssueCommentDiffStatus.vue';
import IssueCommentDiffType from '@/components/project/issueTracker/comments/IssueCommentDiffType.vue';

@Component({
    components: {
        IssueCommentDeletedAt,
        IssueStatusText,
        IssueCommentDiffMultipleItems,
        IssueCommentDiffChanged,
        IssuePriorityText,
        IssueCommentDiffInline,
        IssueCommentDiffCde,
        IssueCommentDiffProcore,
        IssueCommentDiffStatus,
        IssueCommentDiffType,
    },
})
export default class IssueCommentDiff extends Vue {
    @Prop({ required: true }) public comment!: DiffComment;

    public readonly IssueTrackerFields = IssueTrackerFields;

    public readonly inlineProperties = IssueTrackerDiffInlineProperties;
    public readonly multilineProperties = IssueTrackerDiffMultilineProperties;
    public readonly specialProperties = IssueTrackerDiffSpecialProperties;

    get isDiffHasDeletedAt() {
        return this.comment.diff.deletedAt;
    }

    @Emit()
    public click() {
        return;
    }
}
