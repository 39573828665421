export const STAMPS_PER_PAGE = 200;
export const ALLOWED_IMAGES_TYPES = ['image/jpeg', 'image/png'];
export const LOCAL_STORAGE_DRAFT_KEY = 'issueTrackerDraft';
export const DRAFT_KEY = 'drafts';
export const DEADLINE_NOT_SET = '2000-01-01 00:00:00';
export const ISSUES_PER_PAGE = 100;
export const MAX_NOT_COLLAPSED_LENGTH = 60;

export enum ReasonsWhyCantApplyMultiEdit {
    statusAndTypeNotCompatible = 'statusAndTypeNotCompatible',
}

export enum ReasonsWhyIssuesHaveProblems {
    notAllowedType = 'notAllowedType',
    deletedType = 'deletedType',
    permissionError = 'permissionError',
    navisIssuesCantChangeType = 'navisIssuesCantChangeType',
    notAllowedToChangeStatusCategoryDone = 'notAllowedToChangeStatusCategoryDone',
}
