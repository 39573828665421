












































import { Component, Emit, Prop } from 'vue-property-decorator';
import WsInput from '@/components/common/WsInput.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg24,
    },
})
export default class WsInputSearch extends WsInput {
    @Prop({ default: 'auto' }) public hideDetails!: boolean | string;
    @Prop({ type: Boolean, default: true }) public clearable!: boolean;
    @Prop({ type: Boolean, default: false }) public small!: boolean;

    public focused: boolean = false;

    @Emit()
    public clear(value: any) {
        return value;
    }

    public handleFocus(value: any) {
        this.focused = true;
        this.focus(value);
    }
    public handleBlur(value: any) {
        this.focused = false;
        this.blur(value);
    }
}
