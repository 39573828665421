









































import _ from 'lodash';
import VueI18n from 'vue-i18n';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ISelectedItem } from '@/types/ISelectedItem';
import { AmplitudeEvent, IssueFilterExpr, IssueFilterExprT } from '@/constants';
import { ITableFilter } from '@/models';
import { amplitudeLog } from '@/services';
import { IssueTrackerFilterValue } from '@/services/issueTracker/IssueTrackerFilterValue';
import WsTableFilterSelect from '@/components/common/WsTableFilterSelect.vue';

const defaultModifiers: IssueFilterExprT[] = [
    IssueFilterExpr.HAS_ONE_IN,
    IssueFilterExpr.HAS_ALL_IN,
    IssueFilterExpr.HAS_NOT_ALL_IN,
];

const defaultValue = {
    selections: [],
    modifier: IssueFilterExpr.HAS_ONE_IN,
} as ITableFilter;

@Component({
    components: {
        WsTableFilterSelect,
    },
})
export default class WsTableFilterSelectWithModifiers extends Vue {
    @Prop() public eventSource!: string;
    @Prop({ required: true, default: () => defaultValue }) public value!: ITableFilter;
    @Prop({ required: true }) public items!: ISelectedItem[];
    @Prop({ default: () => defaultModifiers }) public modifiers!: IssueFilterExprT[];
    @Prop() public label!: VueI18n.TranslateResult;
    @Prop() public labelNegative!: VueI18n.TranslateResult;
    @Prop({ type: Boolean }) public colorSelect!: boolean;
    @Prop({ type: Boolean, default: false }) public hideModifiers!: boolean;
    @Prop({ default: true }) public appendToBody!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: String }) public type!: string;

    public IssueFilterExpr = IssueFilterExpr;

    get model() {
        return this.value;
    }
    get selections() {
        return this.value.selections;
    }
    set selections(selections: Array<string | number>) {
        this.input({
            selections,
            modifier: this.value.modifier,
        });
    }
    get modifier() {
        return this.value.modifier;
    }
    set modifier(modifier: IssueFilterExprT | undefined) {
        this.input({
            selections: this.value.selections,
            modifier,
        });
    }

    get modifiedLabel() {
        return ([IssueFilterExpr.NOT_IN, IssueFilterExpr.HAS_NOT_ALL_IN].includes(Number(this.value.modifier)) && !_.isEmpty(this.selections))
            ? this.labelNegative || `${this.label}${this.$t('Simple_word.not')}`
            : this.label;
    }

    @Emit()
    public input(value: ITableFilter) {
        this.logChange();
        return new IssueTrackerFilterValue(this.type, value.modifier as any, value.selections);
    }

    @Emit()
    public clear() {
        return;
    }

    public logChange() {
        const eventNames: any = {
            project: AmplitudeEvent.projectDashboardFilterChange,
            license: AmplitudeEvent.licenseDashboardFilterChange,
        };
        const name = eventNames[this.eventSource];
        if (name) {
            amplitudeLog(name, { filter: this.label });
        }
    }
}
