import moment from 'moment/moment';
import { ScheduleWithTranslationKey } from '@/types/project/Project';
import { TaskType } from '@/types/scheduler';
import { Dict } from '@/types/Dict';
import { isObjectEmpty } from '@/services/Common';
import { i18n } from '@/services/i18n';

export enum schedulePeriod {
    customDates = 'customDates',
    eachWeek = 'eachWeek',
    biWeekly = 'biWeekly',
    onDemand = 'onDemand',
}

export const parseSchedule = (source: any): ScheduleWithTranslationKey => {
    const result: ScheduleWithTranslationKey = {
        translationKey: null,
        dates: [],
        times: [],
        enabled: null,
        endDate: 0,
    };

    result.enabled = source.enabled;

    if (source.endDate) {
        result.endDate = source.endDate;
    }

    if (isObjectEmpty(source)) {
        result.translationKey = schedulePeriod.onDemand;
    }

    if (source?.triggers) {
        if (source?.triggers?.oneTime) {
            result.translationKey = schedulePeriod.customDates;
            source?.triggers?.oneTime.forEach((oneTime: any) => {
                result.dates.push(oneTime);
            });
        }

        if (source?.triggers?.weekly) {
            result.translationKey = schedulePeriod.eachWeek;
            result.dates = source?.triggers?.weekly?.daysOfTheWeek.map((day: any) => day);
            result.times = source?.triggers?.weekly?.times;
        }

        if (source?.triggers?.biWeekly) {
            result.translationKey = schedulePeriod.biWeekly;
            result.dates = source?.triggers?.biWeekly?.daysOfTheWeek.map((day: any) => day);
            result.times = source?.triggers?.biWeekly?.times;
        }
    }

    return result;
};

export const getScheduleHTML = (schedule: ScheduleWithTranslationKey, userDateFormat: string, comma: string): string => {
    if (!schedule) {
        return '';
    }

    let result = '';

    result += i18n.t('Project.scheduler.tasks.' + schedule.translationKey);

    if (schedule.translationKey === schedulePeriod.customDates) {
        result += '<br />' + schedule.dates.map((date: number) => moment.utc(date).local().format(userDateFormat + ' HH:mm')).join('<br />');
    }

    if (schedule.translationKey === schedulePeriod.eachWeek) {
        result += ' - ' + schedule.dates.map((day: string) => i18n.t('Project.scheduler.weekDays.' + day)).join(comma);
        result += '<br />' + schedule.times.join(', ');
    }

    if (schedule.translationKey === schedulePeriod.biWeekly) {
        result += ' - ' + schedule.dates.map((day: string) => i18n.t('Project.scheduler.weekDays.' + day)).join(comma);
        result += '<br />' + schedule.times.join(', ');
    }
    return result;
};

export const extractDeviceStatuses = (list: [{ deviceId: string, lastOnline: number }]): Dict<boolean> => {
    const result: any = {};
    list.forEach((item: any) => {
        const deviceId = item.deviceId || item?.deviceData?.deviceId;
        result[deviceId] = isDeviceOnline(item.lastOnline);
    });
    return result;
};

export const isDeviceOnline = (lastOnline: number): boolean => {
    return lastOnline ? (Date.now() - lastOnline) < 30 * 1000 : false;
};

export const getSchedulerTaskTypeTranslation = (type: string) : { key: string, from: string | null } => {
    if (type === TaskType.ClashDetection) {
        return {
            key: TaskType.ClashDetection,
            from: '',
        };
    }

    if (type === TaskType.Import) {
        return {
            key: TaskType.Import,
            from: '',
        };
    }

    return {
        key: TaskType.Export,
        from: type.split('_')[1],
    };
};
