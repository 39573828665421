









import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { IDiffItem } from '@/models';
import { dateFormatter } from '@/services/MomentFormats';
import CustomIssueType from '@/components/common/CustomIssueType.vue';
import IssueCommentDiffChanged from '@/components/project/issueTracker/comments/IssueCommentDiffChanged.vue';

@Component({
    components: {
        CustomIssueType,
        IssueCommentDiffChanged,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueCommentDiffType extends Vue {
    @Prop({ required: true }) public diff!: Dict<IDiffItem>;

    get projectUuid(): string {
        const projectId = this.$route.params.projectId;
        return this.$store.getters.projectById(projectId)?.uuid || '';
    }

    get customTypeDiff() {
        return {
            newType: this.$store.getters.customIssueTypeByUuid(this.projectUuid, this.diff.customType.new),
            oldType: this.$store.getters.customIssueTypeByUuid(this.projectUuid, this.diff.customType.old),
        };
    }

    @Emit()
    public click() {
        return;
    }
}
