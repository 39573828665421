




























import { Component } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';

// deprecated

@Component
export default class MoreIcon extends BaseIcon {}
