





























import { Component, Prop } from 'vue-property-decorator';
import { Color } from '@/constants';
import ResizeMixin from '@/mixins/resizeMixin';

@Component
export default class ReviztoLogo extends ResizeMixin {
    @Prop({ type: Boolean, default: false }) public white!: boolean;

    public readonly Color = Color;

    public handleResize() {
        const el = this.$refs.logo as HTMLElement;
        this.$store.commit('setHeaderComponentSize', { name: 'logo', size: el.offsetWidth });
    }
}
