const RecentSelectedUsersKey = 'recentSelectedUsers';
const MaxCountOfSavedProjects = 10;
const MaxCountOfSavedUsers = 3;

type RecentSelectedUsers = Array<{
    projectId: number,
    users: string[]
}>

export class RecentSelectedUsersService {
    static reset() {
        localStorage.removeItem(RecentSelectedUsersKey);
    }

    static parseRecentSelectedUsers(value: string | null): RecentSelectedUsers {
        if (!value) {
            return [];
        }

        try {
            return JSON.parse(value);
        } catch {
            return [];
        }
    }

    static saveRecentSelectedUser(projectId: number, userEmail: string) {
        const valueInLS = localStorage.getItem(RecentSelectedUsersKey);
        const recentByProject = RecentSelectedUsersService.parseRecentSelectedUsers(valueInLS);

        const recentIndex = recentByProject.findIndex((recent) => recent.projectId === projectId);
        if (recentIndex === -1) {
            if (recentByProject.length === MaxCountOfSavedProjects) {
                recentByProject.shift();
            }

            const users = [userEmail];

            recentByProject.push({
                projectId,
                users,
            });

            localStorage.setItem(RecentSelectedUsersKey, JSON.stringify(recentByProject));

            return users;
        }

        const recent = recentByProject[recentIndex];
        const userIndex = recent.users.indexOf(userEmail);

        if (userIndex !== -1) {
            return recent.users;
        }

        if (recent.users.length === MaxCountOfSavedUsers) {
            recent.users.shift();
        }

        recent.users.push(userEmail);
        recentByProject[recentIndex] = recent;

        localStorage.setItem(RecentSelectedUsersKey, JSON.stringify(recentByProject));
        return recent.users;
    }

    static getRecentSelectedUsers(projectId: number): string[] {
        const valueInLS = localStorage.getItem(RecentSelectedUsersKey);
        const recentByProject = RecentSelectedUsersService.parseRecentSelectedUsers(valueInLS);

        return recentByProject.find((recent) => recent.projectId === projectId)?.users || [];
    }
}
