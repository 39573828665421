

































































import _ from 'lodash';
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { ISelectedItem } from '@/types/ISelectedItem';
import { IComboboxOption } from '@/types/common';
import { IssuesFilterType } from '@/constants';
import { truncateString } from '@/services';
import CustomStatusItem from '@/components/project/workflow/CustomStatusItem.vue';
import CustomIssueType from '@/components/common/CustomIssueType.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';
import WsTableFilterElement from '@/components/common/WsTableFilterElement.vue';
import WsMultiSelect from '@/components/common/WsMultiSelect.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        CustomStatusItem,
        CustomIssueType,
        WsTruncateAuto,
        WsTruncate,
        WsTableFilterElement,
        WsLazyRender,
        WsMultiSelect,
        WsCloseButton,
        IconSvg16,
    },
})
export default class WsTableFilterSelectV2 extends Vue {
    @VModel({ required: true }) public selected!: Array<string | number>;
    @Prop({ required: true }) public items!: ISelectedItem[];
    @Prop({ type: Boolean, default: true }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean }) public colorSelect!: boolean;
    @Prop({ type: String }) public type!: string;
    // @Prop({ default: 'bottom' }) public placement!: string; // TODO revive it in future
    @Prop() public placeholder!: TranslateResult;
    @Prop() public label!: TranslateResult;

    public readonly IssuesFilterType = IssuesFilterType;
    public readonly truncateString = truncateString;

    public refreshKey = 0;

    get multiSelectOptions(): IComboboxOption[] {
        return this.items.map(({ value, text, searchValue }) => ({
            label: String(text),
            value: String(value),
            searchValue: String(searchValue || value),
        }));
    }

    get selectedItems() {
        return this.multiSelectOptions.filter(({ value }) => this.selected.includes(value));
    }

    get selectedString() {
        if (!this.selected.length) {
            return this.$t('Simple_word.any');
        }

        return this.selectedItems.map(({ label }) => label).join(String(this.$t('Punctuation.comma')));
    }

    @Emit()
    public input(value: Array<string | number>) {
        return value;
    }
    @Emit()
    public clear() {
        this.input([]);
        return;
    }

}
