export enum CssClassNameStampColor {
    Red = 1,
    Orange,
    Yellow,
    Lime,
    Green,
    Sky,
    Blue,
    Purple,
    Violet,
    Black,
}
