












import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseIcon from '@/components/common/icon/BaseIcon.vue';
import MoreIcon from '@/components/common/icon/MoreIcon.vue';

@Component({
    components: {
        MoreIcon,
    },
})
export default class WsIcon extends BaseIcon {
    @Prop({ type: Boolean, default: false }) public more!: boolean;

    @Emit()
    public click() {
        return;
    }

    public handleClick() {
        if (!this.disable) {
            this.click();
        }
    }
}
