export enum IssueDetailsRows {
    editable = 'editable',
    row = 'row',
    valueKey = 'valueKey',
    title = 'title',
    type = 'type',
    items = 'items',
    counter = 'counter',
    permissions = 'permissions',
    disabled = 'disabled',
}

export enum IssueClashValuesByDetailsRow {
    grid = 'grids',
    level = 'clashLevel',
    room = 'clashRoom',
    space = 'clashSpace',
    area = 'clashArea',
    zone = 'clashZone',
    clashSourceFile = 'clashSourceFile',
}
