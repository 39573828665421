
















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import { PopoverPlacement } from '@/constants';
import WsButton from '@/components/common/WsButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';

@Component({
    components: {
        WsButton,
        IconSvg16,
        WsCloseButton,
    },
})
export default class WsTableFilterElement extends Vue {
    @Prop() public value!: boolean;
    @Prop() public label!: VueI18n.TranslateResult;
    @Prop({ default: 'bottom' }) public placement!: PopoverPlacement;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ default: 300 }) public width!: number;
    @Prop() public appendToBody!: boolean;
    @Prop() public id!: string;
    @Prop({ default: '' }) public stopClickOutsideClass!: string;

    get model() {
        return this.value;
    }
    set model(value: boolean) {
        if (!value) {
            this.$store.commit('setOpenedFilter', null);
        }
        this.input(value);
    }

    @Emit()
    public open() {
        return;
    }
    @Emit()
    public clear() {
        this.input(false);
        return;
    }
    @Emit()
    public input(value: boolean) {
        return value;
    }
}
