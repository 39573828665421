import _ from 'lodash';
import moment from 'moment';
import { ProjectCategory, ProjectStatusEnum, VersionNumber } from '@/constants';
import { AccessRole, LicenseMember, ProjectRevision } from '@/models';
import { i18n } from '@/services/i18n';

export class Project {
    public id: number;
    public uuid: string;
    public title: string;
    public abbreviate: string;
    public accessLevel: number;
    public accessRole: AccessRole;
    public archiveProject: number;
    public archived: boolean;
    public created: string;

    public frozen: boolean;
    public frozenReason: number;
    public frozenReasons: number[];
    public lastActive: string;
    public lastArchived: string;
    public licenseId: number;
    public licenseRole: number;
    public members: number;
    public metaTags: string[];
    public issueTags: string[];
    public notification: number;
    public permissions: { [key: string]: number };
    public preview?: any;
    public previewImages?: any;
    public region: string;
    public revision: ProjectRevision;
    public smallPreview?: any;
    public updated: string;
    public visibility: number;
    public status: number;
    public owner: LicenseMember;
    public role: any;
    public totalIssues: number;
    public memberLastActiveDate: string;
    public isLocal: boolean;
    public issueCounters: any;
    public disableRegularIssueCreation: any;

    constructor(project: any = {}) {
        this.id = project.id;
        this.uuid = project.uuid;
        this.title = _.unescape(project.title);
        this.abbreviate = project.abbreviate || '';
        this.accessLevel = project.accessLevel; // со слов Паши, это поле - это то же самое, что и accessRole.system, только там другие значения; обещал отрефакторить в v5
        this.accessRole = new AccessRole(project.accessRole || project.currentMemberRole); // костыль, потому что на беке называется по-разному; задача на исправление [WEB-5211]
        this.archiveProject = project.archiveProject;
        this.archived = project.archived;
        this.created = project.created;
        this.frozen = project.frozen;
        this.frozenReason = project.frozenReason;
        this.frozenReasons = project.frozenReasons;
        this.lastActive = project.lastActive;
        this.lastArchived = project.archived ? project.lastArchived : '';
        this.licenseId = project.licenseId || project.license_id;
        this.owner = new LicenseMember(project.member || project.owner);
        this.licenseRole = project.licenseRole;
        this.members = project.members;
        this.metaTags = project.metaTags || [];
        this.issueTags = project.issueTags || [];
        this.notification = project.notification;
        this.permissions = project.permissions || {};
        this.preview = project.preview;
        this.previewImages = project.previewImages || {};
        this.region = project.region;
        this.revision = new ProjectRevision(project.revision || {});
        this.smallPreview = project.smallPreview;
        this.updated = project.updated;
        this.visibility = project.visibility;
        this.status = project.archived ? ProjectStatusEnum.archived : ProjectStatusEnum.active;
        this.role = project.role || {};
        this.totalIssues = project.totalIssues || 0;
        this.memberLastActiveDate = project.memberLastActiveDate || '';
        this.isLocal = Boolean(project.isLocal);
        this.issueCounters = {
            all: project.issueCounters?.all || 0,
            assignee: project.issueCounters?.assignee || 0,
            reporter: project.issueCounters?.reporter || 0,
            closed: project.issueCounters?.closed || 0,
            unread: project.issueCounters?.unread?.total || 0,
        };
        this.disableRegularIssueCreation = project.disableRegularIssueCreation;
    }

    get searchValue() {
        return `${this.title} ${this.owner.user.fullname} ${this.owner.user.email} ${this.id}`.toLowerCase();
    }

    get apiParams(): any {
        return {
            title: this.title,
            abbreviate: this.abbreviate,
            disableRegularIssueCreation: this.disableRegularIssueCreation ? 1 : 0,
        };
    }

    get lastUpdated(): string {
        if (this.archived) {
            return this.lastArchived;
        }
        if (!this.updated) {
            return '';
        }
        if (!this.revision) {
            return this.updated;
        }
        return (moment(this.updated).unix() < moment(this.revision.created).unix())
            ? this.revision.created
            : this.updated;
    }

    get category() {
        if (this.archived) {
            return ProjectCategory.archived;
        }
        if (this.accessLevel === 4) {
            return ProjectCategory.my;
        }
        return ProjectCategory.shared;
    }

    get isAdminRights() {
        return this.isPermissionAvailable('manageProjectRights');
    }

    get isChangeProjectOwner() {
        return this.isPermissionAvailable('changeProjectOwner');
    }

    get isCanViewPublicIssues() {
        return this.isPermissionAvailable('viewPublicIssue');
    }

    get isCanViewPrivateIssues() {
        return this.isPermissionAvailable('viewPrivateIssues');
    }

    get isCanBeArchived() {
        return this.isPermissionAvailable('archiveProject');
    }

    get isCanBeDeleted() {
        return this.isPermissionAvailable('deleteProject');
    }

    get isCanCreateTags() {
        return this.isPermissionAvailable('createTag');
    }

    get isCanManageStamps() {
        return this.isPermissionAvailable('manageIssuePreset');
    }

    get isCanManageTags() {
        return this.isPermissionAvailable('manageIssueTagsAcrossProject');
    }

    get isCanManageShareEntities() {
        return this.isPermissionAvailable('manageSharedEntities');
    }

    get isCanSeeIssueSettings() {
        return this.isPermissionAvailable('manageIssueTagsAcrossProject') || this.isPermissionAvailable('manageIssuePreset');
    }

    get version() {
        const fullVersion = this.revision.projectVersion;
        return fullVersion ? fullVersion.slice(0, 2) : '-';
    }

    get versionNumber(): VersionNumber {
        const versionNumber = Number(this.version[1]);
        return isNaN(versionNumber) ? VersionNumber.unknown : versionNumber;
    }

    get localizedVersion() {
        return i18n.t(`Version.${this.version}`);
    }

    get isV4() {
        return this.revision.projectVersion.indexOf('v4.') !== -1;
    }

    public addIssueTag(tag: string) {
        if (!this.issueTags.includes(tag)) {
            this.issueTags.push(tag);
        }
    }

    private isPermissionAvailable(permissionName: string) {
        return (this.permissions[permissionName] & 4) > 0;
    }

    private has(searchQuery: string) {
        return this.searchValue.includes(searchQuery.toLowerCase());
    }

    public static has(searchQuery: string) {
        return (project: Project) => project.has(searchQuery);
    }
}
