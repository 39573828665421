import { SortDirections } from '@/constants';

export interface IChartSortParams {
    field: string;
    direction?: string; // направление сортировки SortDirections
    directionOptions?: string[];
    values?: any[];
    valuesOptions?: any[];
}

export class ChartSort {
    public readonly field: string; // название сортировки
    public direction: string; // направление сортировки SortDirections
    public directionOptions: string[];
    public values?: any[];
    public valuesOptions?: any[];

    constructor(sort: IChartSortParams) {
        this.field = sort.field;
        this.direction = sort.direction || SortDirections.asc;
        this.directionOptions = sort.directionOptions || Object.keys(SortDirections);
        this.values = sort.values;
        this.valuesOptions = sort.valuesOptions || [];
    }
}
