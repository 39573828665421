
















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ContextMenuItem } from '@/types/ui';

@Component({})
export default class CustomIssueType extends Vue {
    @Prop({ type: Array, required: true }) public options!: ContextMenuItem[];
    @Prop() public activeIndex!: number;

    @Emit()
    public close() {

    }
}
