












import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

// https://vuetifyjs.com/en/api/v-switch/

type acceptableValue = boolean | 0 | 1 | string[];

@Component
export default class WsSwitch extends Vue {
    @Prop({ required: true }) public value!: acceptableValue;
    @Prop({ type: Boolean, default: false }) public dense!: boolean;
    @Prop({ type: Boolean, default: true }) public flat!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop() public hideDetails!: boolean | string;
    @Prop() public label!: TranslateResult;

    @Emit()
    public input(value: acceptableValue) {
        return value;
    }
    @Emit()
    public change(value: acceptableValue) {
        return value;
    }

    get model() {
        return this.value;
    }
    set model(value: acceptableValue) {
        this.input(value);
        this.change(value);
    }
}
