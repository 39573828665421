

























































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { TranslateResult } from 'vue-i18n';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';

@Component({
    components: {
        WsSkeletonLoader,
    },
})
export default class WsTable extends Vue {
    @Prop() public value!: any;
    @Prop({ type: Array }) public items!: any[];
    @Prop({ type: Array }) public headers!: DataTableHeader[];
    @Prop({ type: String }) public itemKey!: string;
    @Prop() public rowClasses!: string;
    @Prop({ type: Number }) public itemsPerPage!: number;
    @Prop() public noDataText!: TranslateResult;
    @Prop({ type: Boolean }) public loading!: boolean;
    @Prop({ type: Boolean }) public showSelect!: boolean;
    @Prop({ type: Boolean }) public disablePagination!: boolean;
    @Prop({ type: Boolean }) public hideDefaultHeader!: boolean;
    @Prop({ type: String, default: 'default' }) public loaderContent!: string;
    @Prop({ type: String, default: 'default' }) public sortIcon!: string;
    @Prop() public page!: any;
    @Prop() public sortDesc!: any;
    @Prop() public sortBy!: any;
    @Prop() public customSort!: any;
    @Prop({ type: Boolean, default: false }) public fixedHeader!: boolean;
    @Prop({ type: Boolean, default: false }) public new!: boolean;
    @Prop({ type: Boolean, default: false }) public singleSelect!: boolean;

    @Ref() public readonly tableRef!: any;

    get isNew() {
        return this.new;
    }

    get headerProps() {
        return this.isNew ? {
            'sort-icon': 'mdi-chevron-up',
        } : undefined;
    }

    @Emit()
    public input(value: any) {
        return value;
    }

    @Emit('update:sort-desc')
    public handleUpdateSortDesc(value: boolean | undefined) {
        return value;
    }

    @Emit('update:sort-by')
    public handleUpdateSortBy(value: string | undefined) {
        return value;
    }

    @Emit('page-count')
    public handlePageCount(value: number) {
        return value;
    }

    @Emit('table-ref')
    public emitRef() {
        return this.tableRef;
    }

    @Emit()
    public onRowClick(args: any) {
        return args;
    }
    @Emit()
    public itemSelected(args: any) {
        return args;
    }

    public mounted() {
        this.emitRef();
    }
}
