



















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class ChartSettingsInlineButton extends Vue {
    @Prop({ required: true }) public icon!: string; // IconSvg16
    @Prop({ type: Boolean, default: true }) public hideDetails!: boolean;
    @Prop({ type: Boolean }) public outlined!: boolean;
    @Prop({ type: Boolean }) public wide!: boolean;
    @Prop({ type: Boolean }) public disabled!: boolean;

    @Emit()
    public click() {
        return;
    }
}
