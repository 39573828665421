import Vue from 'vue';
import { Module } from 'vuex';
import _ from 'lodash';
import { ITaskHistory } from '@/types/project/Project';
import { TUuid } from '@/types/common';
import { ProjectApi } from '@/api';
import { TaskDefinition } from '@/models/TaskDefinition';
import { TaskHistory } from '@/models/TaskHistory';
import { ScheduledTask } from '@/models/ScheduledTask';
import { extractDeviceStatuses } from '@/services/Scheduler';

interface ISchedulerStorage {
    tasks: {
        [projectUuid: string]: any[];
    };
    taskHistory: {
        [projectUuid: string]: any[];
    };
    taskCount: {
        [projectUuid: string]: number;
    };
    taskHistoryCount: {
        [projectUuid: string]: number;
    };
    scheduledTasks: {
        [projectUuid: string]: ScheduledTask[];
    };
    scheduledTaskCount: {
        [projectUuid: string]: number;
    };
    devices: {
        [projectUuid: string]: {
            [key: string]: boolean,
        };
    }
    filterParams: any;
    isLoading: boolean;
}

export default {
    state: {
        tasks: {},
        taskHistory: {},
        scheduledTasks: {},
        taskCount: {},
        taskHistoryCount: {},
        scheduledTaskCount: {},
        filterParams: [],
        isLoading: false,
        devices: {},
    } as ISchedulerStorage,
    getters: {
        schedulerTasksByProjectUuid(state: ISchedulerStorage): (projectUuid: string) => any[] {
            return (projectUuid) => state.tasks[projectUuid] || [];
        },
        schedulerDevicesByProjectUuid(state: ISchedulerStorage): (projectUuid: string) => any {
            return (projectUuid) => state.devices[projectUuid] || {};
        },
        schedulerTasksCountByProjectUuid(state: ISchedulerStorage): (projectUuid: string) => number {
            return (projectUuid) => state.taskCount[projectUuid];
        },
        schedulerTaskHistoryByProjectUuid(state: ISchedulerStorage): (projectUuid: string) => any[] {
            return (projectUuid) => state.taskHistory[projectUuid] || [];
        },
        schedulerTaskHistoryCountByProjectUuid(state: ISchedulerStorage): (projectUuid: string) => number {
            return (projectUuid) => state.taskHistoryCount[projectUuid];
        },
        schedulerFilterParams(state: ISchedulerStorage): any {
            return state.filterParams || {};
        },
        schedulerIsLoading(state: ISchedulerStorage): any {
            return state.isLoading;
        },
        schedulerFilterTypes(state: ISchedulerStorage) {
            return (state.filterParams.types || []).map((key: any) => {
                return { value: key, text: key };
            });
        },
        schedulerFilterDevices(state: ISchedulerStorage): any {
            return (state.filterParams.devices || []).map((device: any) => {
                return { value: device.deviceId, text: device.name };
            });
        },
        schedulerFilterUsers(state: ISchedulerStorage): any {
            return (state.filterParams.users || []).map((user: any) => {
                return { value: user.uuid, text: user.fullname };
            });
        },
        schedulerDeviceById(state: ISchedulerStorage): (deviceId: string) => any {
            return (deviceId) => state.filterParams?.devices?.find((device: any) => device.deviceId === deviceId);
        },
        schedulerUserByEmail(state: ISchedulerStorage): (email: string) => any {
            return (email) => state.filterParams?.users?.find((user: any) => user.email === email);
        },
        schedulerUserById(state: ISchedulerStorage): (id: number) => any {
            return (id) => state.filterParams?.users?.find((user: any) => user.id === id);
        },
        scheduledTasksByProjectUuid(state: ISchedulerStorage): (projectUuid: string) => any[] {
            return (projectUuid) => state.scheduledTasks[projectUuid] || [];
        },
        scheduledTaskCountByProjectUuid(state: ISchedulerStorage): (projectUuid: string) => number {
            return (projectUuid) => state.scheduledTaskCount[projectUuid];
        },
    },
    mutations: {
        setSchedulerDeviceStatus(state: ISchedulerStorage, { projectUuid, data }: { projectUuid: TUuid, data: [{[key: string]: boolean}] }) {
            state.devices[projectUuid] = {
                ...state.devices[projectUuid],
                ...data,
            } as any;
        },
        setSchedulerIsLoading(state: ISchedulerStorage, value: boolean) {
            state.isLoading = value;
        },
        setSchedulerTasks(state: ISchedulerStorage, { projectUuid, tasks }: { projectUuid: string, tasks: any[] }) {
            Vue.set(state.tasks, projectUuid, tasks);
        },
        setSchedulerTask(state: ISchedulerStorage, { projectUuid, task }: { projectUuid: string, task: TaskDefinition }) {
            const tasks = state.tasks[projectUuid].filter((item: TaskDefinition) => item.uuid !== task.uuid);
            Vue.set(state.tasks, projectUuid, [...tasks, task]);
        },
        removeSchedulerTask(state: ISchedulerStorage, { projectUuid, uuid }: { projectUuid: string, uuid: string }) {
            Vue.set(state.tasks, projectUuid, state.tasks[projectUuid].filter((item: TaskDefinition) => item.uuid !== uuid));
        },
        setSchedulerTasksCount(state: ISchedulerStorage, { projectUuid, count }: { projectUuid: string, count: number }) {
            Vue.set(state.taskCount, projectUuid, count);
        },
        setSchedulerTasksHistory(state: ISchedulerStorage, { projectUuid, taskHistory }: { projectUuid: string, taskHistory: any[] }) {
            Vue.set(state.taskHistory, projectUuid, taskHistory);
        },
        setSchedulerTaskHistory(state: ISchedulerStorage, { projectUuid, taskHistory }: { projectUuid: string, taskHistory: TaskHistory }) {
            const tasksHistory = state.taskHistory[projectUuid].filter((item: TaskHistory) => item.uuid !== taskHistory.uuid);
            Vue.set(state.taskHistory, projectUuid, [...tasksHistory, taskHistory]);
        },
        setSchedulerTasksHistoryCount(state: ISchedulerStorage, { projectUuid, count }: { projectUuid: string, count: number }) {
            Vue.set(state.taskHistoryCount, projectUuid, count);
        },
        setSchedulerFilterParams(state: ISchedulerStorage, filterParams: any) {
            state.filterParams = filterParams;
        },
        setScheduledTasks(state: ISchedulerStorage, { projectUuid, tasks }: { projectUuid: string, tasks: any[] }) {
            Vue.set(state.scheduledTasks, projectUuid, tasks);
        },
        setScheduledTask(state: ISchedulerStorage, { projectUuid, task }: { projectUuid: string, task: ScheduledTask }) {
            const scheduledTasks = state.scheduledTasks[projectUuid].filter((item: ScheduledTask) => item.uuid !== task.uuid);
            Vue.set(state.scheduledTasks, projectUuid, [...scheduledTasks, task]);
        },
        removeScheduledTask(state: ISchedulerStorage, { projectUuid, uuid }: { projectUuid: string, uuid: string }) {
            Vue.set(state.scheduledTasks, projectUuid, state.scheduledTasks[projectUuid].filter((item: ScheduledTask) => item.uuid !== uuid));
        },
        setScheduledTasksCount(state: ISchedulerStorage, { projectUuid, count }: { projectUuid: string, count: number }) {
            Vue.set(state.scheduledTaskCount, projectUuid, count);
        },
    },
    actions: {
        deleteSchedulerTaskDefinition(context: any, taskUuid: string): Promise<any> {
            return ProjectApi.deleteSchedulerTaskDefinition(taskUuid);
        },
        async loadSchedulerFilterParams({ commit }: any, { projectUuid }: { projectUuid: string }): Promise<any> {
            const result = await ProjectApi.getSchedulerFilterParams({ projectUuid });
            commit('setSchedulerFilterParams', result);
        },
        async loadSchedulerTasks({ commit }: any, { projectUuid, filters, sort, limit, page, query }: any): Promise<any> {
            commit('setSchedulerIsLoading', true);
            try {
                const tasks = await ProjectApi.getSchedulerTasks({
                    projectUuid,
                    append: ['appendNextRun', 'appendLastRun', 'appendLastOnline'], // appendDeviceStatus
                    filters,
                    sort,
                    limit,
                    query,
                    page,
                });

                commit('setSchedulerDeviceStatus', { projectUuid, data: extractDeviceStatuses(tasks.data) });
                commit('setSchedulerTasks', {
                    projectUuid,
                    tasks: _.uniqBy(tasks.data, 'uuid').map((task: any) => new TaskDefinition(task)),
                });
                commit('setSchedulerTasksCount', { projectUuid, count: tasks.count });
            } finally {
                commit('setSchedulerIsLoading', false);
            }
        },
        async loadSchedulerTask(context: any, { projectUuid, taskUuid }: any): Promise<TaskDefinition> {
            const result = await ProjectApi.getSchedulerTask({
                projectUuid,
                uuid: taskUuid,
            });

            return new TaskDefinition(result);
        },
        async loadSchedulerTaskHistory({ commit }: any, { projectUuid, sort, limit, filters, query, page = 1, taskDefinitionUuids = [] }: any): Promise<any> {
            commit('setSchedulerIsLoading', true);
            try {
                const tasks = await ProjectApi.getSchedulerTaskHistory({
                    projectUuid,
                    filters,
                    sort,
                    limit,
                    page,
                    query,
                    taskDefinitionUuids,
                });
                commit('setSchedulerDeviceStatus', { projectUuid, data: extractDeviceStatuses(tasks.data) });
                commit('setSchedulerTasksHistory', {
                    projectUuid,
                    taskHistory: tasks.data.map((task: ITaskHistory) => new TaskHistory(task)),
                });
                commit('setSchedulerTasksHistoryCount', { projectUuid, count: tasks.count });
            } finally {
                commit('setSchedulerIsLoading', false);
            }
        },
        async loadSchedulerFutureTask({ commit }: any, { projectUuid,  page = 1 }: any): Promise<any> { // filters
            commit('setSchedulerIsLoading', true);
            try {
                await ProjectApi.getSchedulerFutureTasks({
                    projectUuid,
                    // filters,
                    // sort,
                    // limit,
                    page,
                });

            } finally {
                commit('setSchedulerIsLoading', false);
            }
        },
        async loadScheduledTasks({ commit }, payload: {
            projectUuid: string,
            page?: number,
            limit?: number,
            orderBy?: string[],
            orderDirection?: string[],
            deviceIds?: string[],
            types?: string[],
            userEmails?: string[],
            dateStart?: number,
            dateEnd?: number,
            query?: string,
        }): Promise<any> {
            commit('setSchedulerIsLoading', true);

            try {
                const response = await ProjectApi.getScheduledTasks({
                    projectUuid: payload.projectUuid,
                    page: payload.page,
                    limit: payload.limit,
                    orderBy: payload.orderBy,
                    orderDirection: payload.orderDirection,
                    deviceIds: payload.deviceIds,
                    types: payload.types,
                    userEmails: payload.userEmails,
                    dateStart: payload.dateStart,
                    dateEnd: payload.dateEnd,
                    query: payload.query,
                    append: ['appendLastOnline'],
                });

                const rawTasks = response?.data || [];
                const upcomingTasks = rawTasks.map((task: any) => new ScheduledTask(task));
                commit('setSchedulerDeviceStatus', { projectUuid: payload.projectUuid, data: extractDeviceStatuses(rawTasks) });
                commit('setScheduledTasks', { projectUuid: payload.projectUuid, tasks: upcomingTasks });
                commit('setScheduledTasksCount', { projectUuid: payload.projectUuid, count: response?.count || 0 });
            } finally {
                commit('setSchedulerIsLoading', false);
            }
        },
    },
} as Module<ISchedulerStorage, any>;
