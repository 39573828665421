export enum MemberRoleEnum {
    none = 0,
    guest = 1,
    collaborator = 2,
    creator = 3,
    admin = 4,
    superAdmin = 5,
}

export const MemberRoleEnumList = [
    MemberRoleEnum.admin,
    MemberRoleEnum.creator,
    MemberRoleEnum.collaborator,
    MemberRoleEnum.guest,
    MemberRoleEnum.superAdmin,
];
