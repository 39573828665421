








































import { uniqBy } from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { ICantApplyMultiEditModalParams, IIssuesWithWarningsItem } from '@/types/issue-tracker';
import {
    BusEvent,
    IssueStatusEnum,
    IssueTrackerFields,
    ReasonsWhyCantApplyMultiEdit,
    ReasonsWhyIssuesHaveProblems,
} from '@/constants';
import { CustomStatus, Issue, IssueType, License } from '@/models';
import { eventBus } from '@/services/eventBus';
import CustomIssueType from '@/components/common/CustomIssueType.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';
import IssueDetailsEditPopover from '@/components/project/issueTracker/details/IssueDetailsEditPopover.vue';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueStatus from '@/components/project/issueTracker/IssueStatus.vue';
import CustomStatusItem from '@/components/project/workflow/CustomStatusItem.vue';

@Component({
    components: {
        IssueStatus,
        IconSvg16,
        CustomStatusItem,
        CustomIssueType,
        IssueDetailsItem,
        IssueDetailsEditPopover,
    },
})
export default class IssueDetailsCustomStatus extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: CustomStatus[];

    public readonly IssueStatusDeleted = IssueStatusEnum.deleted;

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get itemsForSelect() {
        return this.items?.map((status) => ({
            text: status.name,
            value: status,
            key: status.uuid,
        })) || [];
    }

    get currentIssueType(): IssueType {
        return this.$store.getters.customIssueTypeByUuid(this.currentProject.uuid, this.currentIssue.customType);
    }

    get currentIssueStatus(): IssueType {
        return this.$store.getters.customIssueStatusByUuid(this.currentProject.uuid, this.currentValue);
    }

    get issuesWithNotAllowedWorkflow(): Issue[] {
        return this.$store.getters.issuesHasConflictWithSelectedStatus(this.currentProject.uuid);
    }

    get selectedTypeNotCompatibleWithSelectedStatus(): boolean {
        return this.$store.getters.selectedTypeNotCompatibleWithSelectedStatus(this.currentProject.uuid);
    }

    get countOfIssuesWithNotAllowedWorkflow() {
        return this.issuesWithNotAllowedWorkflow.length;
    }

    get cantEditStatusBecauseCurrentTypeDeleted() {
        return !this.isMultiselectEditModeActive && this.currentIssueType?.isDeleted && this.editable;
    }

    get issuesHasNotAllowedToChangeStatusCategoryDone(): Issue[] {
        return this.$store.getters.issuesHasNotAllowedToChangeStatusCategoryDone(this.currentProject.uuid, this.currentProject.id);
    }

    get extendedIssuesWithPermissionError(): Issue[] {
        const issues = [...this.issuesWithPermissionError, ...this.issuesHasNotAllowedToChangeStatusCategoryDone];

        return uniqBy(issues, 'id');
    }

    get reasonWhyStatusHaveAProblem(): string | undefined {
        if (this.selectedTypeNotCompatibleWithSelectedStatus) {
            return ReasonsWhyCantApplyMultiEdit.statusAndTypeNotCompatible;
        }

        if (this.countOfIssuesWithNotAllowedWorkflow > 0 && this.license?.isWorkflowEnabled) {
            return ReasonsWhyIssuesHaveProblems.notAllowedType;
        }

        if (this.cantEditStatusBecauseCurrentTypeDeleted) {
            return ReasonsWhyIssuesHaveProblems.deletedType;
        }

        if (this.extendedIssuesWithPermissionError?.length > 0) {
            return ReasonsWhyIssuesHaveProblems.permissionError;
        }

        if (this.issuesHasNotAllowedToChangeStatusCategoryDone?.length > 0) {
            return ReasonsWhyIssuesHaveProblems.notAllowedToChangeStatusCategoryDone;
        }

        return undefined;
    }

    get isShowWarning() {
        return Boolean(this.reasonWhyStatusHaveAProblem);
    }

    get localAllowEdit() {
        if (this.editable && this.cantEditStatusBecauseCurrentTypeDeleted) {
            return false;
        }

        return this.editable;
    }

    public handleSelect(value: CustomStatus) {
        if (this.isMultiselectEditModeActive) {
            this.change(value.uuid);
            return;
        }

        const isEqual = this.currentValue === value.uuid;
        if (!isEqual) {
            this.change(value.uuid);
        }
    }

    public openCantApplyChangesWarningDialog() {
        switch (this.reasonWhyStatusHaveAProblem) {
            case ReasonsWhyCantApplyMultiEdit.statusAndTypeNotCompatible:
                const selectedTypeUuid = this.$store.getters.multiEditNewValues[IssueTrackerFields.customType].value;
                const selectedStatusUuid = this.$store.getters.multiEditNewValues[IssueTrackerFields.customStatus].value;

                eventBus.$emit(BusEvent.cantApplyMultiEdit, {
                    reason: ReasonsWhyCantApplyMultiEdit.statusAndTypeNotCompatible,
                    translationArguments: {
                        typeName: this.$store.getters.customIssueTypeByUuid(this.currentProject.uuid, selectedTypeUuid)?.name,
                        statusName: this.$store.getters.customIssueStatusByUuid(this.currentProject.uuid, selectedStatusUuid)?.name,
                    },
                    buttons: [{
                        text: this.$t('IssueTracker.cantApplyMultiEdit.statusAndTypeNotCompatible.applyWithout'),
                        action: () => {
                            this.$store.commit('removeMultiEditValue', IssueTrackerFields.customStatus);
                        },
                    }],
                } as ICantApplyMultiEditModalParams);

                break;
            case ReasonsWhyIssuesHaveProblems.notAllowedType:
                eventBus.$emit(BusEvent.showDialogIssuesWithProblems, [{
                    issues: this.issuesWithNotAllowedWorkflow,
                    reason: ReasonsWhyIssuesHaveProblems.notAllowedType,
                }] as IIssuesWithWarningsItem[]);

                break;
            case ReasonsWhyIssuesHaveProblems.deletedType: {
                eventBus.$emit(BusEvent.showDialogInvalidIssueType);
                break;
            }
            case ReasonsWhyIssuesHaveProblems.permissionError: {
                eventBus.$emit(BusEvent.showDialogIssuesWithProblems, [{
                    issues: this.extendedIssuesWithPermissionError,
                    reason: ReasonsWhyIssuesHaveProblems.permissionError,
                }] as IIssuesWithWarningsItem[]);
                break;
            }
            default:
                break;
        }
    }
}
