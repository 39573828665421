import { ChartTypeStringEnum } from '@/constants';

export class ChartOptions {
    constructor(
        public readonly options: any,
    ) {}

    private get type(): ChartTypeStringEnum | undefined {
        return this.options?.series?.[0]?.type || this.options?.series?.type;
    }

    get isBar() {
        return this.type === ChartTypeStringEnum.bar;
    }

    get isPie() {
        return this.type === ChartTypeStringEnum.pie;
    }

    get isTimeline() {
        return this.type === ChartTypeStringEnum.line;
    }

    get isFlowchart() {
        return this.type === ChartTypeStringEnum.flowchart;
    }

    get isTreeMap() {
        return this.type === ChartTypeStringEnum.treemap;
    }

    get isScatter() {
        return this.type === ChartTypeStringEnum.scatter;
    }
}
