import _ from 'lodash';
import { compareStrings } from '@/services';

export function sortStrings(strings: string[], isDesc = false) {
    return [...strings].sort((left, right) => compareStrings(left, right, isDesc));
}

export function sortStringsWithTail(
    strings: string[],
    tailCallbacks: Array<((arg: any) => boolean)>,
    isDesc = false,
): string[] {
    const [tail, head] = _.partition(strings, (str) => tailCallbacks.some((callback) => callback(str)));
    const tailSorted = tailCallbacks.reduce((acc, callback) => {
        const [tailItem, headLocal] = _.partition(acc, callback);
        return [...headLocal, ...tailItem];
    }, tail);
    return (sortStrings(head as string[], isDesc) as any).concat(tailSorted);
}
