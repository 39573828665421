import _ from 'lodash';

const DisciplineName = {
    NONE: 'NONE',
    ARCHITECTURE: 'ARCHITECTURE',
    ARCHITECTURAL: 'ARCHITECTURAL',
    STRUCTURE: 'STRUCTURE',
    STRUCTURAL: 'STRUCTURAL',
    MECHANICAL: 'MECHANICAL',
    ELECTRICAL: 'ELECTRICAL',
    PIPES: 'PIPES',
    PLUMBING: 'PLUMBING',
    MASK: 'MASK',
    COORDINATION: 'COORDINATION',
};

export function enumDisciplineTypeNormalizeFn(discipline: string): number {
    discipline = discipline.toUpperCase();
    switch (discipline) {
        case DisciplineName.NONE:
            return 0;
        case DisciplineName.ARCHITECTURE:
        case DisciplineName.ARCHITECTURAL:
            return 1;
        case DisciplineName.STRUCTURE:
        case DisciplineName.STRUCTURAL:
            return 2;
        case DisciplineName.MECHANICAL:
            return 4;
        case DisciplineName.ELECTRICAL:
            return 8;
        case DisciplineName.PIPES:
        case DisciplineName.PLUMBING:
            return 16;
        case DisciplineName.MASK:
            return 31;
        case DisciplineName.COORDINATION:
            return 4095;
        default:
            return -1;
    }
}

export function numberToDiscipline(number: number) {
    const obj = {
        0: DisciplineName.NONE,
        1: DisciplineName.ARCHITECTURE,
        2: DisciplineName.STRUCTURE,
        4: DisciplineName.MECHANICAL,
        8: DisciplineName.ELECTRICAL,
        16: DisciplineName.PIPES,
        31: DisciplineName.MASK,
        4095: DisciplineName.COORDINATION,
    };
    const objUpperFirst: any = _.mapValues(obj, _.flowRight(_.upperFirst, _.toLower));
    return objUpperFirst[number];
}

// enum discipline_type { // this version comes from revizto
//     NONE = 0,
//     ARCHITECTURE = 1,
//     STRUCTURE = 2,
//     MECHANICAL = 4,
//     ELECTRICAL = 8,
//     PIPES = 16,
// }
//
// enum IssueDisciplineEnum { // this version comes from core
//     NONE = 0,
//     ARCHITECTURAL = 1,
//     STRUCTURAL = 2,
//     MECHANICAL = 4,
//     ELECTRICAL = 8,
//     PLUMBING = 16,
//     MASK = 31,
//     COORDINATION = 4095,
// }
