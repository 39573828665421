import { IStatusItem, IWorkflow } from '@/types/workflow';
import { TUuid } from '@/types/common';
import { STANDARD_STATUS_CLOSED_UUID, STANDARD_STATUS_OPEN_UUID } from '@/constants/Workflow';
import { MS_IN_SEC } from '@/constants';

export class Workflow {
    public uuid: TUuid;
    public description: string;
    public name: string;
    public statuses: IStatusItem[];
    public deletedAt: string;
    public isStandard: boolean;
    public resolvedClashStatusUuid: TUuid;
    public reappearedClashStatusUuid: TUuid;

    constructor(workflow: IWorkflow) {
        this.uuid = workflow.uuid;
        this.statuses = workflow.statuses || [];
        this.name = workflow.name;
        this.description = workflow.description;
        this.deletedAt = workflow.deletedAt;
        this.isStandard = workflow.isStandard;
        this.resolvedClashStatusUuid = workflow.resolvedClashStatusUuid || STANDARD_STATUS_CLOSED_UUID;
        this.reappearedClashStatusUuid = workflow.reappearedClashStatusUuid || STANDARD_STATUS_OPEN_UUID;
    }

    get isDeleted() {
        return Boolean(this.deletedAt);
    }

    get statusesUuids() {
        return this.statuses
            .filter((status) => !status.deletedAt)
            .map((status) => status.uuid);
    }

    public addStatus(statusUuid: TUuid) {
        this.statuses.push({ uuid: statusUuid, 'deletedAt': null });
    }

    public removeStatus(statusUuid: TUuid) {
        this.statuses.splice(this.statuses.findIndex((status) => status.uuid === statusUuid), 1);
    }

    public remove() {
        this.deletedAt = String(+ new Date() / MS_IN_SEC);
    }

    public setStatuses(statuses: IStatusItem[]) {
        this.statuses = statuses;
    }
}
