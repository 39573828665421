interface IResponse {
    result: number;
    data: any;
    message: string;
}
export function transformSAMLResponse(response: string | IResponse) {
    if (typeof response !== 'string') {
        if (response.result !== 0) {
            throw response;
        }
        return response.data;
    } else {
        const message = String(response).replace(/{.*/, '');
        throw { message };
    }
}
