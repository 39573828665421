import { render, staticRenderFns } from "./UserWidget.vue?vue&type=template&id=1539cb13&scoped=true&"
import script from "./UserWidget.vue?vue&type=script&lang=ts&"
export * from "./UserWidget.vue?vue&type=script&lang=ts&"
import style0 from "./UserWidget.vue?vue&type=style&index=0&id=1539cb13&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1539cb13",
  null
  
)

export default component.exports