import _ from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import Vue from 'vue';
import { Dict } from '@/types/Dict';
import { CommentType } from '@/types/CommentType';
import {
    ErrorsForDisableChat,
    IssueCommentsFilter,
    MomentFormats,
    NotifierUserID,
    RESPONSE,
    RESPONSE_Type,
} from '@/constants';
import ProjectApi from '@/api/project.api';
import { AbstractComment, DiffComment, FileComment, MarkupComment, TextComment } from '@/models';
import { getOperationId, IssueCommentFinder } from '@/services';
import { Issue as IssueProtobuf, Protobuf } from '@/services/Protobuf';

interface IIssueCommentItem {
    receivedFromRequest?: boolean;
    comments: AbstractComment[];
}

interface IMarkupEditorData {
    background: Dict;
    content: any[];
    isOpen: boolean;
    previousBackground: Dict;
}

interface IIssueCommentStorage {
    commentsObj: {
        [projectId: string]: {
            [issueUuid: string]: IIssueCommentItem;
        };
    };
    pendingCommentsObj: {
        [projectId: string]: { [issueUuid: string]: AbstractComment[] };
    };
    filters: {
        [IssueCommentsFilter.isComments]: boolean;
        [IssueCommentsFilter.isAttachments]: boolean;
        [IssueCommentsFilter.isFieldChanges]: boolean;
        [IssueCommentsFilter.isMarkupChanges]: boolean;
        search: string;
    };
    isFiltersOpen: boolean;
    isCommentSearchOpen: boolean;
    commentSendFormDisablingReason?: RESPONSE_Type;
    markupEditor: IMarkupEditorData;
    isMarkupLoading: boolean;
    isLoadingComments: boolean;
}

interface IIssueCommentContext {
    state: IIssueCommentStorage;
    getters: any;
    rootGetters: any;
    commit: any;
    dispatch: any;
}

const LastLoadedIssueComments: string[] = [];
const MaxIssueCommentsInStorage = 100;

export default {
    state: {
        commentsObj: {},
        pendingCommentsObj: {},
        filters: {
            isComments: false,
            isAttachments: false,
            isFieldChanges: false,
            isMarkupChanges: false,
            search: '',
        },
        isFiltersOpen: false,
        isCommentSearchOpen: false,
        commentSendFormDisablingReason: undefined,
        markupEditor: {},
        isMarkupLoading: false,
        isLoadingComments: false,
    } as IIssueCommentStorage,
    getters: {
        markupEditor(state: any) {
            return state.markupEditor;
        },
        isMarkupLoading(state: any) {
            return state.isMarkupLoading;
        },
        pinnedCommentsByIssue(state: IIssueCommentStorage): (projectId: number, issueUuid: string) => AbstractComment[] {
            return (projectId: number, issueUuid: string) => {
                if (state.commentsObj[projectId] && state.commentsObj[projectId][issueUuid]) {
                    return state.commentsObj[projectId][issueUuid]?.comments.filter((comment: AbstractComment) => comment.pinned);
                }

                return [];
            };
        },
        isLoadingComments(state: IIssueCommentStorage): boolean {
            return state.isLoadingComments;
        },
        isIssueCommentsLoadedFromRequest(state: IIssueCommentStorage): (projectId: number, issueUuid: string) => boolean {
            return (projectId: number, issueUuid: string) => {
                return Boolean(state.commentsObj[projectId]?.[issueUuid]?.receivedFromRequest);
            };
        },
        commentsByIssue(state: IIssueCommentStorage): (projectId: number, issueUuid: string) => AbstractComment[] {
            return (projectId, issueUuid) => {
                if (state.commentsObj[projectId] && state.commentsObj[projectId][issueUuid]) {
                    const comments = state.commentsObj[projectId][issueUuid].comments;

                    if (state.filters.isComments
                        || state.filters.isAttachments
                        || state.filters.isFieldChanges
                        || state.filters.isMarkupChanges
                        || state.filters.search.length
                    ) {
                        let result: AbstractComment[] = [];

                        if (state.filters.isComments) {
                            result = [...result, ...comments.filter((comment: AbstractComment) => comment instanceof TextComment)];
                        }

                        if (state.filters.isAttachments) {
                            result = [...result, ...comments.filter((comment: AbstractComment) => comment instanceof FileComment)];
                        }

                        if (state.filters.isFieldChanges) {
                            result = [...result, ...comments.filter((comment: AbstractComment) => comment instanceof DiffComment)];
                        }

                        if (state.filters.isMarkupChanges) {
                            result = [...result, ...comments.filter((comment: AbstractComment) => comment instanceof MarkupComment)];
                        }

                        if (state.filters.search.length) {
                            result = [...result, ...comments.filter((comment: AbstractComment) => new IssueCommentFinder(comment).contains(state.filters.search))];
                        }

                        return result;
                    } else {
                        return comments;
                    }
                }

                return [];
            };
        },
        pendingCommentsByIssue(state: IIssueCommentStorage): (projectId: number, issueUuid: string) => AbstractComment[] {
            return (projectId, issueUuid) => {
                if (state.pendingCommentsObj[projectId] && state.pendingCommentsObj[projectId][issueUuid]) {
                    let comments = state.pendingCommentsObj[projectId][issueUuid];

                    if (state.filters.isComments) {
                        comments = comments.filter((comment: AbstractComment) => comment instanceof TextComment);
                    }

                    if (state.filters.isAttachments) {
                        comments = comments.filter((comment: AbstractComment) => comment instanceof FileComment);
                    }

                    if (state.filters.search.length) {
                        comments = comments.filter((comment: AbstractComment) => new IssueCommentFinder(comment).contains(state.filters.search));
                    }

                    return comments;
                }
                return [];
            };
        },
        commentsFiltersActive(state: IIssueCommentStorage) {
            return state.filters.isComments || state.filters.isAttachments || state.filters.isFieldChanges || state.filters.isMarkupChanges;
        },
        commentsFilters(state: IIssueCommentStorage) {
            return state.filters;
        },
        isChatFiltersOpen(state: IIssueCommentStorage) {
            return state.isFiltersOpen;
        },
        isChatSearchOpen(state: IIssueCommentStorage) {
            return state.isCommentSearchOpen;
        },
        commentSendFormDisablingReason(state: IIssueCommentStorage) {
            return state.commentSendFormDisablingReason;
        },
    },
    mutations: {
        setCommentsForIssue(
            state: IIssueCommentStorage,
            payload: {
                projectId: number,
                issueUuid: string,
                comments: AbstractComment[],
                fromRequest?: boolean,
            },
        ) {
            const { projectId, issueUuid, comments } = payload;
            const isExistCommentsForProject = Boolean(state.commentsObj[projectId]);

            if (isExistCommentsForProject) {
                const issueCommentsItem: IIssueCommentItem = { comments };

                if (_.isBoolean(payload.fromRequest)) {
                    issueCommentsItem.receivedFromRequest = payload.fromRequest;
                }

                Vue.set(state.commentsObj[projectId], issueUuid, issueCommentsItem);
            } else {
                Vue.set(state.commentsObj, projectId, {
                    [issueUuid]: {
                        receivedFromRequest: payload.fromRequest,
                        comments,
                    },
                });
            }

            if (payload.fromRequest && !LastLoadedIssueComments.includes(issueUuid)) {
                LastLoadedIssueComments.push(issueUuid);

                if (LastLoadedIssueComments.length > MaxIssueCommentsInStorage) {
                    const issueUuidForClear = LastLoadedIssueComments.shift() as string;
                    Vue.delete(state.commentsObj[projectId], issueUuidForClear);
                }
            }
        },
        addCommentsForIssue(
            state: IIssueCommentStorage,
            payload: { projectId: number, issueUuid: string, comments: Array<{ data: AbstractComment }> },
        ) {
            const { projectId, issueUuid } = payload;
            const comments = payload.comments.map((comment) => AbstractComment.create(comment.data));

            if (!state.commentsObj[projectId]) {
                state.commentsObj[projectId] = {};
            }

            const existIssueComments = state.commentsObj[projectId][issueUuid];

            if (existIssueComments) {
                const existingCommentsUuids = existIssueComments.comments.map((comment: AbstractComment) => comment.uuid);
                const newComments = comments.filter((comment: AbstractComment) => !existingCommentsUuids.includes(comment.uuid));

                state.commentsObj[projectId][issueUuid].comments.push(...newComments);
                return;
            }

            Vue.set(state.commentsObj[projectId], issueUuid, {
                comments,
            });
        },
        removeCommentsForIssue(
            state: IIssueCommentStorage,
            payload: { projectId: number, issueUuid: string },
        ) {
            Vue.delete(state.commentsObj[payload.projectId], payload.issueUuid);
        },
        addPendingCommentsForIssue(
            state: IIssueCommentStorage,
            {
                projectId,
                issueUuid,
                comments,
            }: { projectId: number, issueUuid: string, comments: AbstractComment[] },
        ) {
            if (!state.pendingCommentsObj[projectId]) {
                Vue.set(state.pendingCommentsObj, projectId, {});
            }
            if (!state.pendingCommentsObj[projectId][issueUuid]) {
                Vue.set(state.pendingCommentsObj[projectId], issueUuid, []);
            }
            const existingComments = state.pendingCommentsObj[projectId][issueUuid];
            const newComments = comments
                .map((comment) => {
                    const index = existingComments.findIndex((existedComment: AbstractComment) => existedComment.uuid === comment.uuid);
                    if (index !== -1) {
                        existingComments.splice(index, 1);
                    }
                    comment.pending = true;
                    comment.focused = true;
                    return comment;
                });
            Vue.set(state.pendingCommentsObj[projectId], issueUuid, existingComments.concat(newComments));
        },
        removePendingCommentsForIssue(
            state: IIssueCommentStorage,
            {
                projectId,
                issueUuid,
                comments,
            }: { projectId: number, issueUuid: string, comments: AbstractComment[] },
        ) {
            const existingComments = state.pendingCommentsObj[projectId][issueUuid];
            const resultComments = _.differenceBy(existingComments, comments, 'uuid');
            Vue.set(state.pendingCommentsObj[projectId], issueUuid, resultComments);
        },
        updatePendingCommentsForIssue(
            state: IIssueCommentStorage,
            {
                projectId,
                issueUuid,
                comments,
            }: { projectId: number, issueUuid: string, comments: Array<{ data: AbstractComment, result: number }> },
        ) {
            const existingComments: AbstractComment[] = state.pendingCommentsObj[projectId][issueUuid];

            comments.forEach(({ data, result }) => {
                if (ErrorsForDisableChat.includes(result)) {
                    state.commentSendFormDisablingReason = result;
                }

                const index = existingComments.findIndex((pendedComment: AbstractComment) => pendedComment.uuid === data.uuid);
                if (index !== -1) {
                    if (result === RESPONSE.SUCCESS) {
                        existingComments.splice(index, 1);
                    } else {
                        existingComments[index].pendingError = result;
                    }
                }
            });

            Vue.set(state.pendingCommentsObj[projectId], issueUuid, existingComments);
        },
        resetChatFilters(state: IIssueCommentStorage) {
            state.filters.isComments = false;
            state.filters.isAttachments = false;
            state.filters.isFieldChanges = false;
            state.filters.isMarkupChanges = false;
        },
        setChatFiltersOpen(state: IIssueCommentStorage, value: boolean) {
            state.isFiltersOpen = value;
        },
        setCommentSearchOpen(state: IIssueCommentStorage, value: boolean) {
            state.isCommentSearchOpen = value;

            if (!value) {
                state.filters.search = '';
            }
        },
        setCommentsSearchValue(state: IIssueCommentStorage, value: string) {
            Vue.set(state.filters, 'search', value);
        },
        setCommentsFilterValue(state: IIssueCommentStorage, { filter, value }: { filter: IssueCommentsFilter, value: boolean }) {
            Vue.set(state.filters, filter, value);
        },
        setIsLoadingComments(state: IIssueCommentStorage, value: boolean) {
            state.isLoadingComments = value;
        },
        setMarkupEditorContent(state: IIssueCommentStorage, payload: any) {
            Vue.set(state.markupEditor, 'content', payload);
        },
        setMarkupEditorBackground(state: IIssueCommentStorage, payload: any) {
            Vue.set(state.markupEditor, 'background', payload);
        },
        setMarkupEditorPreviousBackground(state: IIssueCommentStorage, payload: any) {
            Vue.set(state.markupEditor, 'previousBackground', payload);
        },
        setMarkupEditorBackgroundUpdated(state: IIssueCommentStorage, updated: any) {
            Vue.set(state.markupEditor, 'backgroundUpdated', updated);
        },
        setMarkupEditorIsOpen(state: IIssueCommentStorage, value: any) {
            Vue.set(state.markupEditor, 'isOpen', value);
        },
        setIsMarkupLoading(state: IIssueCommentStorage, value: any) {
            state.isMarkupLoading = value;
        },
    },
    actions: {
        loadCommentsForIssue(
            { state, commit, getters }: IIssueCommentContext,
            {
                projectId,
                issueUuid,
                isForce = false,
            }: { projectId: number, issueUuid: string, isForce: boolean },
        ) {
            return new Promise((resolve, reject) => {
                const existIssueCommentsState = state.commentsObj[projectId]?.[issueUuid];
                if (!isForce && existIssueCommentsState && existIssueCommentsState.receivedFromRequest) {
                    resolve(existIssueCommentsState.comments);

                    return;
                }

                commit('setIsLoadingComments', true);

                // synchronized page
                const allowDeleted = getters.deletedIssuesByProjectId(projectId).length ? 1 : 0;
                ProjectApi.getComments(issueUuid, { project_id: projectId, offset: 0, allowDeleted }).then((response) => {
                    if (!response) {
                        response = { data: [], pages: 1 };
                    }
                    let comments = response.data;

                    const requests = [];
                    for (let page = 1; page < response.pages; page++) {
                        requests.push(ProjectApi.getComments(issueUuid, { project_id: projectId, offset: 0, page, allowDeleted }));
                    }

                    Promise.all(requests).then((responses) => {
                        responses.forEach((res) => {
                            comments = comments.concat(res.data);
                        });
                        comments = _.map(comments, (commentData) => AbstractComment.create(commentData));
                        commit('setCommentsForIssue', {
                            projectId,
                            issueUuid,
                            comments,
                            fromRequest: true,
                        });
                        resolve(comments);
                    }).catch((error) => {
                        reject(error);
                    }).finally(() => {
                        commit('setIsLoadingComments', false);
                    });
                }).catch((error) => {
                    commit('setIsLoadingComments', false);
                    reject(error);
                });
            });
        },
        addComments(context: IIssueCommentContext, { issueUuid, contentToSend }: any): Promise<any[]> {
            const operationId = getOperationId({ contentToSend });
            return ProjectApi.postComments(issueUuid, { ...contentToSend, operationId });
        },
        async sendCommentsForIssue(
            { commit, dispatch, rootGetters }: any,
            {
                projectId,
                issueUuid,
                comments,
                email,
                silent,
            }: {
                projectId: number,
                issueUuid: string,
                email: string,
                comments: any[],
                silent?: boolean,
            },
        ) {
            const created = moment().utc().format(MomentFormats.serverSide);

            const textComments = comments.filter(_.isString);
            const fileComments = comments.filter((comment: any) => comment.commentType === CommentType.File);

            const textCommentsToSend = _.fromPairs(
                textComments.map((comment: string) => {
                    const textUuid: string = uuid();
                    return [textUuid, { type: CommentType.Text, uuid: textUuid, reporter: email, created, text: comment }];
                }),
            );
            const textContent = {
                comments: textCommentsToSend,
            };

            let fileContent;
            if (fileComments[0]) {
                fileContent = fileComments[0].reduce((acc: any, rawFile: File) => {
                    const fileUuid: string = uuid();
                    let internalProperties = '';

                    if ((rawFile as any).is360) {
                        internalProperties = new IssueProtobuf.InternalProperties({
                            list: [
                                new IssueProtobuf.InternalProperties.KeyValue({
                                    key: 'Is360',
                                }),
                            ],
                        });
                    }

                    const commentsObj = {
                        [fileUuid]: {
                            type: 'file',
                            uuid: fileUuid,
                            reporter: email,
                            created,
                            rawFile,
                            internalProperties: Protobuf.encodeInternalProperties(internalProperties),
                        },
                    };

                    const newComments = { ...acc.comments, ...commentsObj };
                    return { ...acc, ['file_' + fileUuid]: rawFile, comments: newComments };
                }, {});
            }

            const contentToSend = {
                project_id: projectId,
                issue_uuid: issueUuid,
                ...textContent,
                ...fileContent,
                comments: JSON.stringify({
                    ...textContent?.comments,
                    ...fileContent?.comments,
                }),
                [NotifierUserID]: rootGetters.notifierActorId,
            };

            const commentsToSend = Object.values({
                ...textContent?.comments,
                ...fileContent?.comments,
            }).map(AbstractComment.create);

            if (!silent) {
                commit('addPendingCommentsForIssue', { projectId, issueUuid, comments: commentsToSend });
            }

            try {
                const postedComments = await dispatch('addComments', { issueUuid, contentToSend });
                const succeededComments = postedComments.filter(({ result }: any) => result === RESPONSE.SUCCESS);

                if (!silent) {
                    commit('updatePendingCommentsForIssue', { projectId, issueUuid, comments: postedComments });
                }

                commit('addCommentsForIssue', { projectId, issueUuid, comments: succeededComments });

                return Boolean(succeededComments.length);
            } catch (error) {
                if (!silent) {
                    const commentObjs = commentsToSend.map((comment) => ({
                        data: comment,
                        result: error?.result || RESPONSE.SERVER_ERROR,
                    }));

                    commit('updatePendingCommentsForIssue', { projectId, issueUuid, comments: commentObjs });
                }

                return false;
            }
        },
        async resendCommentsForIssue(
            { commit, dispatch, rootGetters }: IIssueCommentContext,
            {
                projectId,
                issueUuid,
                comments,
            }: { projectId: number, issueUuid: string, comments: Array<TextComment | FileComment> },
        ) {

            const textComments = comments.filter((comment: AbstractComment) => comment instanceof TextComment) as TextComment[];
            const fileComments = comments.filter((comment: AbstractComment) => comment instanceof FileComment) as FileComment[];

            const textCommentsToSend = _.fromPairs(
                textComments.map(({ uuid: textUuid, reporter, created, text }: TextComment) => {
                    return [textUuid, { type: 'text', uuid: textUuid, reporter, created, text }];
                }),
            );
            const textContent = {
                comments: textCommentsToSend,
            };

            const fileContent = fileComments.reduce((acc: any, { rawFile, uuid: fileUuid, reporter, created, internalProperties }: FileComment) => {
                const commentsObj = {
                    [fileUuid]: {
                        type: 'file',
                        uuid: fileUuid,
                        reporter,
                        created,
                        internalProperties: Protobuf.encodeInternalProperties(internalProperties),
                    },
                };

                const resendComments = { ...acc.comments, ...commentsObj };

                return { ...acc, ['file_' + fileUuid]: rawFile, comments: resendComments };
            }, {});

            const contentToSend = {
                project_id: projectId,
                issue_uuid: issueUuid,
                ...textContent,
                ...fileContent,
                comments: JSON.stringify({
                    ...textContent.comments,
                    ...fileContent.comments,
                }),
                [NotifierUserID]: rootGetters.notifierActorId,
            };

            commit('addPendingCommentsForIssue', { projectId, issueUuid, comments });

            try {
                const postedComments = await dispatch('addComments', { issueUuid, contentToSend });
                const success = postedComments.filter(({ result }: any) => result === RESPONSE.SUCCESS);

                commit('updatePendingCommentsForIssue', { projectId, issueUuid, comments: postedComments });
                commit('addCommentsForIssue', { projectId, issueUuid, comments: success });
            } catch (error) {
                const commentObjs = comments.map((comment: AbstractComment) => ({
                    data: comment,
                    result: RESPONSE.SERVER_ERROR,
                }));

                commit('updatePendingCommentsForIssue', { projectId, issueUuid, comments: commentObjs });
            }
        },
        toggleChatFilters({ state, commit }: IIssueCommentContext) {
            commit('setChatFiltersOpen', !state.isFiltersOpen);
        },
        toggleChatSearch({ state, commit }: IIssueCommentContext) {
            commit('setCommentSearchOpen', !state.isCommentSearchOpen);
        },
        pinIssueComment(context: IIssueCommentContext, { projectId, issueUuid, commentUuid }: { projectId: number, issueUuid: string, commentUuid: string }) {
            return ProjectApi.pinChatComment(projectId, issueUuid, commentUuid);
        },
        unpinIssueComment(context: IIssueCommentContext, { projectId, issueUuid, commentUuid }: { projectId: number, issueUuid: string, commentUuid: string }) {
            return ProjectApi.unpinChatComment(projectId, issueUuid, commentUuid);
        },
    },
};
