import { AccessRole, ILicenseMemberGuestActivity, Project } from '@/models';

export class LicenseMemberSettingsTableItem {
    public archived?: boolean;
    public external?: boolean;
    public projectId: number;
    public smallPreview?: string;
    public title: string;
    public accessRole?: AccessRole;
    public lastActiveDate: string; // MomentFormats.serverSide
    public licenseName?: string;
    public searchValue: string;
    public accessRoleSystem?: number;

    constructor(params: Project | ILicenseMemberGuestActivity) {
        if (params instanceof Project) {
            this.archived = params.archived;
            this.projectId = params.id;
            this.smallPreview = params.smallPreview;
            this.title = params.title;
            this.accessRole = params.accessRole;
            this.lastActiveDate = params.memberLastActiveDate;
        } else {
            this.external = true;
            this.projectId = params.project;
            this.title = `External project ID ${params.project}`;
            this.licenseName = params.license.name;
            this.lastActiveDate = params.lastActive;
        }
        this.searchValue = `${this.title} ${this.projectId}`;
    }
}
