var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"projects-dropdown-item",class:{ 'router-link-exact-active': _vm.isActive },attrs:{"to":{
        name: _vm.RouterNames.ProjectSettings,
        params: {
            licenseId: _vm.licenseId,
            projectId: _vm.project.id,
            language: _vm.language,
        }
    }}},[_c('WsProjectPreview',{staticClass:"project-logo",attrs:{"previewImages":_vm.project.previewImages}}),_c('div',{staticClass:"description"},[_c('WsTruncate',{attrs:{"value":_vm.project.title,"size":35}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }