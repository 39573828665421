import { ActionContext, Module } from 'vuex';
import { VueConstructor } from 'vue';
import { Dict } from '@/types/Dict';
import TrackerPresets from '@/components/project/issueTracker/columns/TrackerPresets.vue';
import TrackerPreview from '@/components/project/issueTracker/columns/TrackerPreview.vue';
import TrackerIssues from '@/components/project/issueTracker/columns/TrackerIssues.vue';
import TrackerChat from '@/components/project/issueTracker/columns/TrackerChat.vue';
import TrackerInfo from '@/components/project/issueTracker/columns/TrackerInfo.vue';

export interface ITrackerColumn {
    name: string;
    component?: VueConstructor;
    collapsed: boolean;
    side: 'right' | 'left';
    size: string; // px or fr
    fixed?: boolean;
    minSize: number;
}

export interface IColumnToggle {
    name: string;
    side: 'right' | 'left';
    collapsed: boolean;
}

export interface IColumnSize {
    name: string;
    size: string; // px or fr
}

const components: { [name: string]: VueConstructor } = {
    TrackerPresets,
    TrackerPreview,
    TrackerIssues,
    TrackerChat,
    TrackerInfo,
};

const defaultColumns: ITrackerColumn[] = [
    { name: 'TrackerPresets', fixed: true },
    { name: 'TrackerPreview', collapsed: true },
    { name: 'TrackerIssues', size: '40%', minSize: 360 },
    { name: 'TrackerChat', minSize: 260 },
    { name: 'TrackerInfo', fixed: true },
].map((item, index, array) => {
    return {
        collapsed: false,
        side: index < (array.length - 1) / 2 ? 'left' : 'right',
        size: '20%',
        minSize: 200,
        ...item,
    };
});
const localStoreKey = 'trackerColumns';

function sortColumns(columns: ITrackerColumn[], defaults: ITrackerColumn[] = defaultColumns) {
    function findIndex(arr: ITrackerColumn[], searchColumn: ITrackerColumn) {
        return arr.findIndex((col) => col.name === searchColumn.name);
    }

    function booleanCompare(compare1: number | boolean, compare2: number | boolean) {
        return Number(compare1) - Number(compare2);
    }

    return [...columns].sort((col1, col2) => {
        if (col1.side !== col2.side) {
            // не сравниваем между сторонами
            return 0;
        }
        if (col1.collapsed === col2.collapsed) {
            // сотрировка на случай если обе колонки схлопнуты или обе развернуты
            return booleanCompare(findIndex(defaults, col1), findIndex(defaults, col2));
        }
        // сортировка если только одна из колонок свернута
        return col1.side === 'left'
            ? booleanCompare(col2.collapsed, col1.collapsed)
            : booleanCompare(col1.collapsed, col2.collapsed);
    });
}

interface IIssueTrackerStorage {
    columns: ITrackerColumn[];
    lastIssueFilterQuery: {
        projectId: number | null;
        params: Dict | undefined;
    }
}

export default {
    state: {
        columns: JSON.parse(String(localStorage.getItem(localStoreKey))) || sortColumns(defaultColumns),
        lastIssueFilterQuery: {
            projectId: null,
            params: undefined,
        },
    } as IIssueTrackerStorage,
    getters: {
        trackerColumns(state: IIssueTrackerStorage): ITrackerColumn[] {
            return state.columns.map((column) => {
                column.component = components[column.name];
                return column;
            });
        },
        lastIssueFilterQueryParams(state: IIssueTrackerStorage) {
            return (projectId: number) => state.lastIssueFilterQuery.projectId === projectId ? state.lastIssueFilterQuery.params : '';
        },
    },
    mutations: {
        setTrackerColumns(state: IIssueTrackerStorage, value: ITrackerColumn[]) {
            state.columns = value;
            const withoutComponent = value.map(({ component, ...another }) => another);
            localStorage.setItem(localStoreKey, JSON.stringify(withoutComponent));
        },
        setLastIssueFilterQuery(state: IIssueTrackerStorage, value: { projectId: number, params: Dict }) {
            state.lastIssueFilterQuery.projectId = value.projectId;
            state.lastIssueFilterQuery.params = value.params;
        },
        clearLastIssueFilterQuery(state: IIssueTrackerStorage) {
            state.lastIssueFilterQuery.projectId = null;
            state.lastIssueFilterQuery.params = undefined;
        },
    },
    actions: {
        toggleColumn({ state, commit }: ActionContext<IIssueTrackerStorage, any>, value: IColumnToggle) {
            const nameCompare = (column: ITrackerColumn) => column.name === value.name;
            const from = state.columns.findIndex(nameCompare);
            state.columns[from].collapsed = value.collapsed;
            commit('setTrackerColumns', sortColumns(state.columns));
        },
        resizeColumn({ state, commit }: ActionContext<IIssueTrackerStorage, any>, value: IColumnSize) {
            const nameCompare = (column: ITrackerColumn) => column.name === value.name;
            const target = state.columns.findIndex(nameCompare);
            state.columns[target].size = value.size;
            commit('setTrackerColumns', state.columns);
        },
    },
} as Module<IIssueTrackerStorage, any>;
