








































































































































import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TRulesObj } from '@/types/TRulesObj';
import { AmplitudeEvent, API, NoAccessCode, RESPONSE, RouterNames } from '@/constants';
import { Region } from '@/models';
import { FormValidator, sanitizeHtml, startTimer } from '@/services';
import { normalizeRegionId } from '@/services/RegionService';
import { amplitudeMixin, autofocusMixin } from '@/mixins';
import OTPInput from '@/components/common/OTPInput.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsSelect from '@/components/common/WsSelect.vue';

enum TwoFactorMethod {
    initial = '',
    email = 'email',
    google = 'google',
}

const Empty = {
    get twoFA() {
        return {
            key: '',
            secret: '',
            twoFactorMethod: TwoFactorMethod.initial,
            code: '',
        };
    },
    get credentials() {
        return {
            email: '',
            password: '',
            regionId: '',
        };
    },
};

@Component({
    components: {
        OTPInput,
        WsButton,
        WsInput,
        WsSelect,
    },
    methods: {
        sanitizeHtml,
    },
})

export default class LoginForm extends Mixins(autofocusMixin, amplitudeMixin) {
    @Prop({ type: Boolean }) public isOAuth2Redirect!: boolean;
    @Prop({ type: String }) public email!: string;

    public isAuthorizing = false;

    public localAuthError: TranslateResult = '';
    public credentials = Empty.credentials;
    public isTwoFAStep = false;
    public isInvalidCode = false;
    public hasBeenUsedCode = false;
    public isDisabledResend = false;
    public secondsLeft = 0;
    public twoFAData = Empty.twoFA;
    public previousSessionRegionId = this.$store.getters.regionId;
    public isTwoFAAccessCode = false;

    public otpTriggerKey = 1;

    get isEmailTwoFactorMethod() {
        return this.twoFAData.twoFactorMethod === TwoFactorMethod.email;
    }

    get isGoogleTwoFactorMethod() {
        return this.twoFAData.twoFactorMethod === TwoFactorMethod.google;
    }

    get isRequestAccessCode() {
        return this.$route.query.request === API.accessCode;
    }

    get form() {
        return this.$refs.credentials as HTMLFormElement;
    }

    get regionsOptions() {
        const regions = this.isRequestAccessCode ? this.$store.getters.pureRegions : this.$store.getters.regionsList;
        return Region.makeOptions(regions);
    }

    get rules(): TRulesObj {
        return {
            email: [
                FormValidator.email,
            ],
            password: [
                (value: string) => Boolean(value) || this.$t('errors.empty_pass'),
            ],
            ...(this.isRequestAccessCode && {
                regionId: [
                    (value: string) => (this.pureRegionsIds.includes(value) || this.$t('Simple_word.required')),
                ],
            }),
        };
    }

    get pureRegionsIds() {
        return this.$store.getters.pureRegionsIds;
    }

    get authError() {
        return this.localAuthError || this.$store.getters.authError;
    }

    get presetRegionId() {
        return this.$route.params.region || this.$route.query.region;
    }

    @Emit()
    public authorized() {
        return;
    }

    @Emit()
    public returnToLogin() {
        return;
    }

    @Emit()
    public showWelcome(value = true) {
        return value;
    }

    @Emit()
    public showTabs(value: boolean) {
        return value;
    }

    @Watch('email', { immediate: true })
    public onEmailChange() {
        this.credentials.email = this.email;
        this.credentials.password = '';
        this.clearAuthError();
    }

    @Watch('isTwoFAStep')
    public onTwoFAStepChange() {
        this.showTabs(!this.isTwoFAStep);
    }

    public created() {
        this.credentials.regionId = normalizeRegionId(this.presetRegionId || this.previousSessionRegionId);
        if (this.presetRegionId) {
            this.changeRegion(this.credentials.regionId);
        }
    }

    public login() {
        if (this.isAuthorizing) {
            return;
        }
        this.clearAuthError();
        if (this.form.validate()) {
            this.isAuthorizing = true;
            const { email, password } = this.credentials;
            if (this.isRequestAccessCode) {
                this.$store.dispatch('authorizeOAuth2', { email, password })
                    .then((response) => {
                        return response.result === RESPONSE.SUCCESS
                            ? this.goToAccessCode(response)
                            : Promise.reject(response);
                    }).catch((error) => {
                        this.isTwoFAAccessCode = error.result === RESPONSE.TWO_FA_REQUIRED;
                        this.handleLoginError(error);
                    });
                return;
            }
            if (this.isOAuth2Redirect) {
                const query = this.$route.query;
                this.$store.dispatch('authorizeOAuth2', {
                    email,
                    password,
                    authType: 'oauth2redirect',
                    query,
                });
                return;
            }

            this.$store.dispatch('authorize', { email, password }).then(() => {
                if (this.credentials.regionId !== this.previousSessionRegionId) {
                    this.$store.commit('setStartPathAfterLogin', '/');
                }
                this.authorized();
                this.amplitudeLog(AmplitudeEvent.loginSuccessfulAuth);
            }).catch(this.handleLoginError);
        }
    }

    public clearAuthError() {
        this.$store.commit('setAuthError', '');
        this.localAuthError = '';
    }

    public handleLoginError(error: any) {
        this.isAuthorizing = false;
        this.checkTwoFA(error);
        this.checkAuthErrors(error);
    }

    public checkTwoFA(error: any) {
        if (error?.result === RESPONSE.TWO_FA_REQUIRED) {
            this.isTwoFAStep = true;
            this.showWelcome(false);
            this.twoFAData = { ...this.twoFAData, ...error.data };
            if (this.isEmailTwoFactorMethod) {
                this.startTimerResendCode();
            }
        }
    }

    public checkAuthErrors(error: any) {
        switch (error.result) {
            case RESPONSE.BANNED_PERMANENTLY:
                this.localAuthError = this.$t('errors.bannedPermanently');
                break;
            case RESPONSE.USER_AUTH_BANNED:
                this.localAuthError = this.$t('errors.authorizationBanned');
                break;
            case RESPONSE.INVALID_DATA:
                this.localAuthError = this.$t('errors.incorrectCredentials');
                break;
            case RESPONSE.INVALID_PARAMS:
                this.localAuthError = this.$t('errors.invalidEmail');
                break;
            case RESPONSE.NO_LICENSES_FOUND:
                this.goToAccessCode({ data: { code: NoAccessCode, licenses: [] } });
                break;
            default:
                this.localAuthError = error.message || JSON.stringify(error);
        }

        this.amplitudeLog(AmplitudeEvent.loginUnsuccessfulAuth, {
            localAuthError: this.localAuthError,
        });
    }

    public goToAccessCode(response: any) {
        const { code: accessCode, licenses } = response.data;
        const shortLicenses = licenses.map((license: any) => {
            return {
                id: license.id,
                name: license.name,
            };
        });

        const props = this.$router.resolve({
            name: RouterNames.AccessCode,
            query: { accessCode, licenses: JSON.stringify(shortLicenses) },
        });

        location.href = props.href;
    }

    public loginTwoFA() {
        if (this.isAuthorizing) {
            return;
        }
        this.isAuthorizing = true;

        let action;
        switch (true) {
            case (this.isTwoFAAccessCode):
                action = this.$store.dispatch('authorizeTwoFA', { ...this.twoFAData, isAccessCode: true })
                    .then(this.goToAccessCode)
                    .catch((error) => {
                        if (error.result === RESPONSE.NO_LICENSES_FOUND) {
                            this.goToAccessCode({ data: { code: NoAccessCode, licenses: [] } });
                        } else {
                            throw error;
                        }
                    });
                break;
            case (this.isOAuth2Redirect):
                action = this.$store.dispatch('authorizeTwoFA', {
                    ...this.twoFAData,
                    authType: 'oauth2redirect',
                    query: this.$route.query,
                });
                break;
            default:
                action = this.$store.dispatch('authorizeTwoFA', this.twoFAData).then(this.authorized);
        }

        action.then(() => {
            this.isInvalidCode = false;
            this.hasBeenUsedCode = false;
        }).catch((error) => {
            this.isInvalidCode = true;
            if (error?.message === 'The security code has alredy been used.') {
                this.hasBeenUsedCode = true;
            }
            this.isAuthorizing = false;
            this.otpTriggerKey++;
        });
    }

    public resendCode() {
        this.startTimerResendCode();
        this.$store.dispatch('resendCodeTwoFA', { key: this.twoFAData.key });
    }

    public startTimerResendCode() {
        startTimer(
            60,
            () => this.isDisabledResend = true,
            () => this.isDisabledResend = false,
            (secondsLeft: number) => this.secondsLeft = secondsLeft,
        );
    }

    public navigateToLoginForm() {
        this.isTwoFAStep = false;
        this.showWelcome();
        this.clearAuthError();
        this.isInvalidCode = false;
        this.twoFAData = Empty.twoFA;
    }

    public changeTwoFAMethodToEmailForLogin() {
        this.$store.dispatch('changeTwoFAMethodToEmail', {
            login: this.credentials.email,
            password: this.credentials.password,
            merge: 0,
        }).then((response) => {
            this.twoFAData = Object.assign({}, this.twoFAData, response);
            this.startTimerResendCode();
        });
    }

    public changeRegion(selectedRegionId: string) {
        this.$store.commit('setRegionId', selectedRegionId);
    }

    public navigateForgotPassword() {
        this.$router.replace({ name: RouterNames.ForgotPassword });
    }
}
