import _ from 'lodash';
import { Dict } from '@/types/Dict';

export class Immutable {
    private static sealDescriptor(descriptor: PropertyDescriptor) {
        descriptor.writable = false;
        descriptor.configurable = false;
        return descriptor;
    }

    private static seal<T extends Dict>(object: T, keys: Array<keyof T>) {
        const descriptorsObject = _
            .chain(Object.getOwnPropertyDescriptors(object))
            .pick(keys)
            .mapValues(Immutable.sealDescriptor)
            .value();
        Object.defineProperties(object, descriptorsObject);
        return object;
    }

    public static sealExcept<T extends Dict>(object: T, keysToExclude: Array<keyof T> = []) {
        const keys  = _.difference(_.keys(object), keysToExclude as any[]) as Array<keyof T>;
        return Immutable.seal(object, keys);
    }

    public static sealAll<T extends Dict>(object: T) {
        const keys = _.keys(object) as Array<keyof T>;
        return Immutable.seal(object, keys);
    }

    public static sealOne<T extends Dict>(object: T, key: keyof T) {
        return Immutable.seal(object, [key]);
    }
}
