
















































































import _ from 'lodash';
import { Component } from 'vue-property-decorator';
import { AmplitudeEvent, BusEvent, Color, RouterNames, TooltipPositions } from '@/constants';
import { License, Project } from '@/models';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog } from '@/services';
import { ResizeMixin } from '@/mixins';
import NavMenu from '@/components/header/NavMenu.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import UserWidget from '@/components/header/UserWidget.vue';
import LocaleSwitcher from '@/components/header/LocaleSwitcher.vue';
import HelpMenu from '@/components/header/HelpMenu.vue';
import NotifierStatus from '@/components/common/NotifierStatus.vue';
import ReviztoLogo from '@/components/svg/ReviztoLogo.vue';
import RegionWidget from '@/components/header/RegionWidget.vue';
import NotificationWidget from '@/components/header/NotificationWidget.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import ProjectsDropdown from '@/components/header/ProjectsDropdown.vue';

@Component({
    components: {
        NavMenu,
        UserWidget,
        LocaleSwitcher,
        HelpMenu,
        NotifierStatus,
        ReviztoLogo,
        RegionWidget,
        NotificationWidget,
        WsTooltip,
        ProjectsDropdown,
        IconSvg16,
    },
})
export default class Header extends ResizeMixin {
    public readonly TooltipPositions = TooltipPositions;
    public readonly Color = Color;
    public readonly RouterNames = RouterNames;

    public isMobile = false;
    public readonly currentYear = new Date().getFullYear();
    public isOpenNotifications = false;

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get currentLicense(): License {
        return this.$store.getters.currentLicense;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get hasUserData() {
        return this.$store.getters.userData.id;
    }

    get isAuthorized() {
        return !this.$route.meta?.allowedWithoutAuth;
    }

    get isAuthenticate(): boolean  {
        return this.$store.getters.isAuthenticate;
    }

    get hasLicenses(): boolean {
        return this.$store.getters.hasLicenses;
    }

    get myProjectsCount(): number {
        switch (this.$route.name) {
            case RouterNames.MyLicenseProjectsArchived:
                return this.$store.getters.myProjectsArchived(this.currentLicense).length;
            case RouterNames.MyLicenseProjects:
                return this.currentLicense.isFrozen
                    ? this.$store.getters.myProjectsSuspended(this.currentLicense).length
                    : this.$store.getters.myProjectsOperational(this.currentLicense).length;
            default:
                return this.$store.getters.myProjectsOperational(this.currentLicense).length;
        }
    }

    get currentLanguage() {
        return this.$store.getters.currentLanguage;
    }

    public created() {
        eventBus.$on(BusEvent.isOpenNotifications, (isOpen: boolean) => {
            this.isOpenNotifications = isOpen;
        });
    }

    public onMyProjectsClick() {
        amplitudeLog(AmplitudeEvent.toolbarChangeProject, { userProjects: this.myProjectsCount, action: 'my projects' });
    }

    public beforeDestroy() {
        eventBus.$off(BusEvent.isOpenNotifications);
    }

    public handleResize() {
        this.$store.commit('setScreenWidth', innerWidth);
    }

    public onSelectLanguage(language: string) {
        this.$router.replace({
            params: {
                ..._.cloneDeep(this.$route.params),
                language,
            },
        });

        this.$store.commit('setCurrentLanguage', language);

        if (this.isAuthenticate) {
            this.$store.dispatch('uploadUserData', { language });
        }
    }
}
