import { RESPONSE } from '@/constants';

interface IValue {
    code: number;
    email: string;
}

export interface IParsedResponse<K> {
    overflow: K[];
    residence: K[];
    success: K[];
    error: K[];
    yourself: K[];
    owner: K[];
    authMethod: K[];
    authMethodNotAllowed: K[];
}

export function membersChangeRoleResponsesParser<T>(responses: IValue[], whatToPushFn = (value: any): T => value): IParsedResponse<T> {
    const overflow: T[] = [];
    const residence: T[] = [];
    const success: T[] = [];
    const error: T[] = [];
    const yourself: T[] = [];
    const owner: T[] = [];
    const authMethod: T[] = [];
    const authMethodNotAllowed: T[] = [];
    
    responses.forEach((value: IValue) => {
        const valueToPush: T = whatToPushFn(value);
        switch (value.code) {
            case (RESPONSE.LICENCE_OVERFLOW):
            case (RESPONSE.LICENCE_OVERFLOW_BY_USER):
                overflow.push(valueToPush);
                break;
            case (RESPONSE.NO_AVAILABLE_LICENSE):
                residence.push(valueToPush);
                break;
            case (RESPONSE.SUCCESS):
                success.push(valueToPush);
                break;
            case (RESPONSE.INVALID_DATA):
            case (RESPONSE.SUPER_ADMIN_CHANGE_FORBIDDEN):
                owner.push(valueToPush);
                break;
            case (RESPONSE.AUTH_METHOD_CHANGE_FORBIDDEN):
                authMethod.push(valueToPush);
                break;
            case (RESPONSE.AUTH_METHOD_NOT_ALLOWED):
                authMethodNotAllowed.push(valueToPush);
                break;
            case (RESPONSE.OWNER_CHANGE_FORBIDDEN):
            case (RESPONSE.SECURITY_ERROR):
                yourself.push(valueToPush);
                break;
            default:
                error.push(valueToPush);
        }
    });
    return { overflow, residence, success, error, yourself, owner, authMethod, authMethodNotAllowed };
}
