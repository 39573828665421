export enum EventTypeEnum {
    // Issues
    ISSUE_CREATED = 1,
    ISSUE_EDITED = 2,
    ISSUE_DELETED = 3,
    ISSUE_RESTORED = 4,

    // Issue commentaries
    ISSUE_COMMENT_ADDED = 5,

    // Projects
    PROJECT_MEMBER_ADDED = 101,
    PROJECT_MEMBER_DELETED = 102,
    PROJECT_REVISION_ADDED = 103,

    // License
    LICENSE_MEMBER_ADDED = 201,
    LICENSE_MEMBER_DELETED = 202,
    LICENSE_MEMBER_ADDED_PENDING = 203,

    // Reports
    REPORT_PERIODIC_SENDING = 301,  // Sent by a regular task (not yet sent, just registered the need to send)
    REPORT_MANUAL_SENDING = 302, // Sent manually (not yet sent, just registered the need to send)
    REPORT_SENT = 303, // Report sent to Mailgun (ANY EXTERNAL MAILING SERVICE)
    REPORT_SENT_EMPTY = 304, // Report sent blank
    REPORT_SENT_WITH_DEGRADATION = 305, // The report was sent, but there was degradation (e.g. no pictures or XLS instead of PDF)
    REPORT_ERROR = 306, // there was an error

    // Project dashboards
    PROJECT_DASHBOARD_PERIODIC_SENDING = 401,
    PROJECT_DASHBOARD_MANUAL_SENDING = 402,
    PROJECT_DASHBOARD_SENT = 403,
    PROJECT_DASHBOARD_SENT_WITH_DEGRADATION = 404,
    PROJECT_DASHBOARD_ERROR = 405,
    PROJECT_DASHBOARD_CANCELED_NO_DATA = 406, // Dashboard has no data and will not be sent to the client

    // Project dashboard charts
    PROJECT_GRAPH_PERIODIC_SENDING = 501,
    PROJECT_GRAPH_MANUAL_SENDING = 502,
    PROJECT_GRAPH_SENT = 503,
    PROJECT_GRAPH_SENT_WITH_DEGRADATION = 504,
    PROJECT_GRAPH_ERROR = 505,
    PROJECT_GRAPH_CANCELED_NO_DATA = 506,

    // Cross-project dashboards
    LICENSE_DASHBOARD_PERIODIC_SENDING = 601,
    LICENSE_DASHBOARD_MANUAL_SENDING = 602,
    LICENSE_DASHBOARD_SENT = 603,
    LICENSE_DASHBOARD_SENT_WITH_DEGRADATION = 604,
    LICENSE_DASHBOARD_ERROR = 605,
    LICENSE_DASHBOARD_CANCELED_NO_DATA = 606,

    // Cross-project dashboard charts
    LICENSE_GRAPH_PERIODIC_SENDING = 701,
    LICENSE_GRAPH_MANUAL_SENDING = 702,
    LICENSE_GRAPH_SENT = 703,
    LICENSE_GRAPH_SENT_WITH_DEGRADATION = 704,
    LICENSE_GRAPH_ERROR = 705,
    LICENSE_GRAPH_CANCELED_NO_DATA = 706,
}

export const EventClass: any = {
    issues: {
        [EventTypeEnum.ISSUE_CREATED]: true,
        [EventTypeEnum.ISSUE_EDITED]: true,
        [EventTypeEnum.ISSUE_DELETED]: true,
        [EventTypeEnum.ISSUE_RESTORED]: true,
        [EventTypeEnum.ISSUE_COMMENT_ADDED]: true,
    },
    otherNormal: {
        [EventTypeEnum.PROJECT_MEMBER_ADDED]: true,
        [EventTypeEnum.PROJECT_MEMBER_DELETED]: true,
        [EventTypeEnum.PROJECT_REVISION_ADDED]: true,
        [EventTypeEnum.LICENSE_MEMBER_ADDED]: true,
        [EventTypeEnum.LICENSE_MEMBER_DELETED]: true,
        [EventTypeEnum.LICENSE_MEMBER_ADDED_PENDING]: true,
    },
    otherProgress: {
        [EventTypeEnum.REPORT_PERIODIC_SENDING]: true,
        [EventTypeEnum.REPORT_MANUAL_SENDING]: true,
        [EventTypeEnum.REPORT_SENT]: true,
        [EventTypeEnum.REPORT_SENT_EMPTY]: true,
        [EventTypeEnum.REPORT_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.REPORT_ERROR]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_PERIODIC_SENDING]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_MANUAL_SENDING]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_SENT]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_ERROR]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_CANCELED_NO_DATA]: true,
        [EventTypeEnum.PROJECT_GRAPH_PERIODIC_SENDING]: true,
        [EventTypeEnum.PROJECT_GRAPH_MANUAL_SENDING]: true,
        [EventTypeEnum.PROJECT_GRAPH_SENT]: true,
        [EventTypeEnum.PROJECT_GRAPH_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.PROJECT_GRAPH_ERROR]: true,
        [EventTypeEnum.PROJECT_GRAPH_CANCELED_NO_DATA]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_PERIODIC_SENDING]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_MANUAL_SENDING]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_SENT]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_ERROR]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_CANCELED_NO_DATA]: true,
        [EventTypeEnum.LICENSE_GRAPH_PERIODIC_SENDING]: true,
        [EventTypeEnum.LICENSE_GRAPH_MANUAL_SENDING]: true,
        [EventTypeEnum.LICENSE_GRAPH_SENT]: true,
        [EventTypeEnum.LICENSE_GRAPH_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.LICENSE_GRAPH_ERROR]: true,
        [EventTypeEnum.LICENSE_GRAPH_CANCELED_NO_DATA]: true,
    },
    report: {
        [EventTypeEnum.REPORT_PERIODIC_SENDING]: true,
        [EventTypeEnum.REPORT_MANUAL_SENDING]: true,
        [EventTypeEnum.REPORT_SENT]: true,
        [EventTypeEnum.REPORT_SENT_EMPTY]: true,
        [EventTypeEnum.REPORT_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.REPORT_ERROR]: true,
    },
    projectDashboard: {
        [EventTypeEnum.PROJECT_DASHBOARD_PERIODIC_SENDING]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_MANUAL_SENDING]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_SENT]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_ERROR]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_CANCELED_NO_DATA]: true,
    },
    projectGraph: {
        [EventTypeEnum.PROJECT_GRAPH_PERIODIC_SENDING]: true,
        [EventTypeEnum.PROJECT_GRAPH_MANUAL_SENDING]: true,
        [EventTypeEnum.PROJECT_GRAPH_SENT]: true,
        [EventTypeEnum.PROJECT_GRAPH_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.PROJECT_GRAPH_ERROR]: true,
        [EventTypeEnum.PROJECT_GRAPH_CANCELED_NO_DATA]: true,
    },
    licenseDashboard: {
        [EventTypeEnum.LICENSE_DASHBOARD_PERIODIC_SENDING]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_MANUAL_SENDING]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_SENT]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_ERROR]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_CANCELED_NO_DATA]: true,
    },
    licenseGraph: {
        [EventTypeEnum.LICENSE_GRAPH_PERIODIC_SENDING]: true,
        [EventTypeEnum.LICENSE_GRAPH_MANUAL_SENDING]: true,
        [EventTypeEnum.LICENSE_GRAPH_SENT]: true,
        [EventTypeEnum.LICENSE_GRAPH_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.LICENSE_GRAPH_ERROR]: true,
        [EventTypeEnum.LICENSE_GRAPH_CANCELED_NO_DATA]: true,
    },
    initial: {
        [EventTypeEnum.REPORT_PERIODIC_SENDING]: true,
        [EventTypeEnum.REPORT_MANUAL_SENDING]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_PERIODIC_SENDING]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_MANUAL_SENDING]: true,
        [EventTypeEnum.PROJECT_GRAPH_PERIODIC_SENDING]: true,
        [EventTypeEnum.PROJECT_GRAPH_MANUAL_SENDING]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_PERIODIC_SENDING]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_MANUAL_SENDING]: true,
        [EventTypeEnum.LICENSE_GRAPH_PERIODIC_SENDING]: true,
        [EventTypeEnum.LICENSE_GRAPH_MANUAL_SENDING]: true,
    },
    result: {
        [EventTypeEnum.REPORT_SENT]: true,
        [EventTypeEnum.REPORT_SENT_EMPTY]: true,
        [EventTypeEnum.REPORT_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.REPORT_ERROR]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_SENT]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_ERROR]: true,
        [EventTypeEnum.PROJECT_DASHBOARD_CANCELED_NO_DATA]: true,
        [EventTypeEnum.PROJECT_GRAPH_SENT]: true,
        [EventTypeEnum.PROJECT_GRAPH_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.PROJECT_GRAPH_ERROR]: true,
        [EventTypeEnum.PROJECT_GRAPH_CANCELED_NO_DATA]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_SENT]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_ERROR]: true,
        [EventTypeEnum.LICENSE_DASHBOARD_CANCELED_NO_DATA]: true,
        [EventTypeEnum.LICENSE_GRAPH_SENT]: true,
        [EventTypeEnum.LICENSE_GRAPH_SENT_WITH_DEGRADATION]: true,
        [EventTypeEnum.LICENSE_GRAPH_ERROR]: true,
        [EventTypeEnum.LICENSE_GRAPH_CANCELED_NO_DATA]: true,
    },
};

export const EventTypesError = [
    EventTypeEnum.PROJECT_GRAPH_ERROR,
    EventTypeEnum.PROJECT_GRAPH_CANCELED_NO_DATA,
    EventTypeEnum.LICENSE_DASHBOARD_ERROR,
    EventTypeEnum.LICENSE_DASHBOARD_CANCELED_NO_DATA,
    EventTypeEnum.LICENSE_GRAPH_ERROR,
    EventTypeEnum.LICENSE_GRAPH_CANCELED_NO_DATA,
    EventTypeEnum.REPORT_ERROR,
    EventTypeEnum.PROJECT_DASHBOARD_ERROR,
    EventTypeEnum.PROJECT_DASHBOARD_CANCELED_NO_DATA,
];

export const EventTypesGenerated  = [
    EventTypeEnum.REPORT_SENT,
    EventTypeEnum.REPORT_SENT_WITH_DEGRADATION,
    EventTypeEnum.PROJECT_DASHBOARD_SENT,
    EventTypeEnum.PROJECT_DASHBOARD_SENT_WITH_DEGRADATION,
    EventTypeEnum.PROJECT_GRAPH_SENT,
    EventTypeEnum.PROJECT_GRAPH_SENT_WITH_DEGRADATION,
    EventTypeEnum.LICENSE_DASHBOARD_SENT,
    EventTypeEnum.LICENSE_DASHBOARD_SENT_WITH_DEGRADATION,
    EventTypeEnum.LICENSE_GRAPH_SENT,
    EventTypeEnum.LICENSE_GRAPH_SENT_WITH_DEGRADATION,
];