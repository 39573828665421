








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IDiffItem, ProcoreRFILink } from '@/models';
import IssueStatusText from '@/components/project/issueTracker/IssueStatusText.vue';
import IssueCommentDiffChanged from '@/components/project/issueTracker/comments/IssueCommentDiffChanged.vue';
import IssueStatusProcoreText from '@/components/project/issueTracker/IssueStatusProcoreText.vue';

type PossibleEmptyProcoreRFILink = ProcoreRFILink | string[];

@Component({
    components: {
        IssueStatusText,
        IssueCommentDiffChanged,
        IssueStatusProcoreText,
    },
})
export default class IssueCommentDiffProcore extends Vue {
    @Prop({ required: true }) public diff!: IDiffItem;
    @Prop({ required: true }) public statusDiff!: IDiffItem;

    get new() {
        return this.diff.new as PossibleEmptyProcoreRFILink;
    }

    get newRfiLink() {
        return Array.isArray(this.new) ? undefined :  this.new;
    }

    get old() {
        return this.diff.old as PossibleEmptyProcoreRFILink;
    }

    get oldRfiLink() {
        return Array.isArray(this.old) ? undefined : this.old;
    }

    get isHasOld() {
        return !Array.isArray(this.old);
    }

    get isHasNew() {
        return !Array.isArray(this.new) || this.new.length !== 0;
    }

    get isChangedRFISubject() {
        return this.isHasOld
            && this.isHasNew
            && this.oldRfiLink !== this.newRfiLink;
    }

    get isStatusChanged() {
        const oldStatus = this.statusDiff?.old;
        const newStatus = this.statusDiff?.new;

        return oldStatus
            && newStatus
            && oldStatus !== newStatus;
    }

    get isUndraft() {
        return this.isDraftOld && typeof this.isDraftNew === 'boolean' && !this.isDraftNew;
    }

    get isProcoreStatusChanged() {
        return this.oldRfiLink?.status !== this.newRfiLink?.status;
    }

    get isOfficialReplyChanged() {
        return this.oldRfiLink?.officialReply !== this.newRfiLink?.officialReply;
    }

    get isDraftOld() {
        return this.oldRfiLink?.RFIDraft;
    }

    get isDraftNew() {
        return this.newRfiLink?.RFIDraft;
    }

    get status() {
        if (this.isStatusChanged) {
            return {
                old: this.isDraftOld ? `draft-${this.statusDiff.old}` : this.statusDiff.old,
                new: this.isDraftNew ? `draft-${this.statusDiff.new}` : this.statusDiff.new,
            };
        }

        if (this.isUndraft) {
            return {
                old: this.oldRfiLink?.status,
                new: this.newRfiLink?.status,
            };
        }

        return {
            old: this.statusDiff.old,
            new: this.statusDiff.new,
        };
    }
}
