import { TUuid } from '@/types/common';

export interface IMainSheet {
    externalUuid: string;
    path: any[];
    tags: string[];
    title: string;
    uuid: string;
}

export interface IStamp {
    abbreviation: string;
    color: number;
    title: string;
}

interface ICustomStatusVariant {
    name: string;
    uuid: TUuid;
}

interface ICustomTypeVariant {
    name: string;
    uuid: TUuid;
}

export class FieldVariants {
    public readonly statuses: ICustomStatusVariant[];
    public readonly types: ICustomTypeVariant[];
    public readonly assignees: string[];
    public readonly reporters: string[];
    public readonly tags: string[];
    public readonly stampCategories: any[];
    public readonly stampColors: any[];
    public readonly stamps: IStamp[];
    public readonly mainSheets: IMainSheet[];
    public readonly mainSheetTags: any[];
    public readonly clashCategories: string[];
    public readonly clashGridXs: string[];
    public readonly clashGridYs: string[];
    public readonly clashLevels: string[];
    public readonly clashSourceFiles: string[];
    public readonly clashTests: string[];
    public readonly clashDisciplines: any[];
    public readonly clashRooms: string[];
    public readonly clashSpaces: string[];
    public readonly clashAreas: string[];
    public readonly clashZones: string[];
    public readonly clashGrids: string[];

    constructor(fieldVariants: any = {}) {
        this.statuses = fieldVariants.statuses || [];
        this.types = fieldVariants.types || [];
        this.assignees = fieldVariants.assignees || [];
        this.reporters = fieldVariants.reporters || [];
        this.tags = fieldVariants.tags || [];
        this.stampCategories = fieldVariants.stampCategories || [];
        this.stampColors = fieldVariants.stampColors || [];
        this.stamps = fieldVariants.stamps || [];
        this.mainSheets = fieldVariants.mainSheets || [];
        this.mainSheetTags = fieldVariants.mainSheetTags || [];
        this.clashCategories = fieldVariants.clashCategories || [];
        this.clashGridXs = fieldVariants.clashGridXs || [];
        this.clashGridYs = fieldVariants.clashGridYs || [];
        this.clashLevels = fieldVariants.clashLevels || [];
        this.clashSourceFiles = fieldVariants.clashSourceFiles || [];
        this.clashTests = fieldVariants.clashTests || [];
        this.clashDisciplines = fieldVariants.clashDisciplines || [];
        this.clashRooms = fieldVariants.clashRooms || [];
        this.clashSpaces = fieldVariants.clashSpaces || [];
        this.clashAreas = fieldVariants.clashAreas || [];
        this.clashZones = fieldVariants.clashZones || [];
        this.clashGrids = fieldVariants.clashGrids || [];
    }
}
