export const DEFAULT_WORKFLOW_UUID = '00000000-0000-0000-0000-000000000000';
export const customStatusColors = [
    'D34F46',
    'E0635D',
    'EA8A86',
    'EA8747',
    'EBAB4D',
    'E5BC50',
    '9DCE52',
    '6DC877',
    '63C794',
    '63BBAD',
    '62BAD5',
    '5EACE6',
    '618DEF',
    '7678EC',
    '9271F0',
    'A86CF1',
    'CF63E9',
    'DE66A1',
    'E36071',
    '827A77',
    '6F7E93',
    'D94C9C',
    '4060D8',
    '4C5C75',
];

export default customStatusColors;
export const DEFAULT_CUSTOM_STATUS_OPEN = '2ed005c6-43cd-4907-a4d6-807dbd0197d5';
export const DEFAULT_CUSTOM_STATUS_IN_PROGRESS = 'cd52ac3e-f345-4f99-870f-5be95dc33245';
export const DEFAULT_CUSTOM_STATUS_SOLVED = 'b8504242-3489-43a2-9831-54f64053b226';
export const DEFAULT_CUSTOM_STATUS_CLOSED = '135b58c6-1e14-4716-a134-bbba2bbc90a7';
export const STANDARD_STATUS_OPEN_UUID = '2ed005c6-43cd-4907-a4d6-807dbd0197d5';
export const STANDARD_STATUS_CLOSED_UUID = '135b58c6-1e14-4716-a134-bbba2bbc90a7';
export const STATUS_NAME_MAX_LENGTH = 16;