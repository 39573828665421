import Vue from 'vue';
import _ from 'lodash';
import { Dict } from '@/types/Dict';
import type { IWorkflow, IWorkflowSettings } from '@/types/workflow';
import { ICustomStatus } from '@/types/customStatus';
import { IIssueType, IIssueTypeSettings } from '@/types/issueType';
import { StatusReplacementMap } from '@/types/statusReplacementMap';
import { THexColor, TUuid } from '@/types/common';
import {
    DEFAULT_CUSTOM_STATUS_CLOSED,
    DEFAULT_CUSTOM_STATUS_IN_PROGRESS,
    DEFAULT_CUSTOM_STATUS_OPEN,
    DEFAULT_CUSTOM_STATUS_SOLVED,
} from '@/constants/Workflow';
import {
    CustomStatusCategory,
    IssueStatusEnum,
} from '@/constants';
import { ProjectApi } from '@/api';
import { CustomStatus, IssueType, Workflow } from '@/models';
import { compareAlphanumerically, NormalizeStatus } from '@/services';

interface IWorkflowState {
    isLoadingWorkflowSettings: boolean;
    workflows: Dict<Workflow[]>;
    types: Dict<IssueType[]>;
    statuses: Dict<CustomStatus[]>;
    currentWorkflowUuid: string;
}

interface IWorkflowContext {
    state: IWorkflowState;
    getters: any;
    commit: any;
    rootGetters: any;
    dispatch: any;
}

export default {
    state: {
        isLoadingWorkflowSettings: false,
        workflows: {},
        types: {},
        statuses: {},
        currentWorkflowUuid: '',
    } as IWorkflowState,
    getters: {
        customStatusesColors(
            state: IWorkflowState,
            _getters: any,
            _rootState: any,
            rootGetters: any,
        ): (projectUuid: number) => Dict<THexColor> {
            return (projectUuid) => {
                const statuses = projectUuid ? state.statuses[projectUuid] : rootGetters.customStatusesStubs;
                return _
                    .chain(statuses)
                    .map(({ name, backgroundColor }) => [name, backgroundColor])
                    .fromPairs()
                    .value();
            };
        },
        currentWorkflowUuid(state: IWorkflowState): string {
            return state.currentWorkflowUuid;
        },
        isLoadingWorkflowSettings(state: IWorkflowState): boolean {
            return state.isLoadingWorkflowSettings;
        },
        allWorkflowsByProject(state: IWorkflowState): (projectUuid: number, isFilterDeleted?: boolean) => Workflow[] {
            return (projectUuid) => (state.workflows[projectUuid] || []);
        },
        workflowsByProject(state: IWorkflowState): (projectUuid: number, isFilterDeleted?: boolean) => Workflow[] {
            return (projectUuid) => (state.workflows[projectUuid] || []).filter((workflow: Workflow) => !workflow.isDeleted);
        },
        workflowByCustomTypeUuid(state: IWorkflowState): (projectUuid: number, customTypeUuid: string) => Workflow | undefined {
            return (projectUuid, customTypeUuid) => {
                const customType = state.types[projectUuid]?.find((type: IssueType) => type.uuid === customTypeUuid);

                if (!customType) {
                    return;
                }

                const workflows = state.workflows[projectUuid] || [];

                return workflows.find((workflow: Workflow) => workflow.uuid === customType.workflowUuid);
            };
        },
        workflowByUuid(state: IWorkflowState): (projectUuid: number, workflowUuid: string) => Workflow | undefined {
            return (projectUuid, workflowUuid) => {
                const workflows = state.workflows[projectUuid] || [];

                return workflows.find((workflow: Workflow) => workflow.uuid === workflowUuid);
            };
        },
        customStatusesByProjectUuid(state: IWorkflowState): (projectUuid: number) => CustomStatus[] {
            return (projectUuid, isFilterDeleted = true) => {
                if (isFilterDeleted) {
                    return (state.statuses[projectUuid] || []).filter((status: CustomStatus) => !status.isDeleted);
                }

                return state.statuses[projectUuid] || [];
            };
        },
        customStatusesByUuidForProject(state: IWorkflowState): (projectUuid: number) => Dict<CustomStatus> {
            return (projectUuid) => {
                const statuses = state.statuses[projectUuid] || [];

                return _.keyBy(statuses, 'uuid');
            };
        },
        issueTypesByProjectUuid(state: IWorkflowState): (projectUuid: number) => IssueType[] {
            return (projectUuid) => {
                return state.types[projectUuid] || [];
            };
        },
        issueTypesByUuidForProject(state: IWorkflowState): (projectUuid: number) => Dict<IssueType> {
            return (projectUuid) => {
                const issueTypes = state.types[projectUuid] || [];

                return _.keyBy(issueTypes, 'uuid');
            };
        },
        customStatusesByProjectUuidAndWorkflowUuid(state: IWorkflowState, getters: any): (projectUuid: TUuid, workflowUuid: TUuid) => CustomStatus[] {
            return (projectUuid, workflowUuid) => {
                const workflow = getters.workflowByUuid(projectUuid, workflowUuid);

                if (!workflow) {
                    return [];
                }

                return (state.statuses[projectUuid] || []).filter((status: CustomStatus) => workflow.statusesUuids.includes(status.uuid));
            };
        },
        customStatusesSortKeysByProjectUuid(state: IWorkflowState, getters: any, rootState: any, rootGetters: any): (projectUuid: TUuid) => string[] {
            return (projectUuid) => {
                const getLegacyVariants = (status: IssueStatusEnum) => [
                    NormalizeStatus.reverse(status),
                    status,
                ];

                const allStatuses = projectUuid
                    ? getters.customStatusesByProjectUuid(projectUuid, false)
                    : rootGetters.customStatusesStubs;

                const statusesUuidsSet = new Set(_.map(allStatuses, 'uuid'));
                const hasStatusUuid = (statusUuid: string) => statusesUuidsSet.has(statusUuid);

                const legacyStatusesUuids = [
                    DEFAULT_CUSTOM_STATUS_OPEN,
                    DEFAULT_CUSTOM_STATUS_IN_PROGRESS,
                    DEFAULT_CUSTOM_STATUS_SOLVED,
                    DEFAULT_CUSTOM_STATUS_CLOSED,
                ];
                const legacyStatusesUuidsSet = new Set(legacyStatusesUuids);

                const hasLegacyStatusObj = _
                    .chain(legacyStatusesUuids)
                    .keyBy()
                    .mapValues(hasStatusUuid)
                    .value();

                const nonLegacyStatuses = _.reject(
                    allStatuses,
                    (status) => legacyStatusesUuidsSet.has(status.uuid),
                );

                const open = hasLegacyStatusObj[DEFAULT_CUSTOM_STATUS_OPEN]
                    ? getLegacyVariants(IssueStatusEnum.open)
                    : [];
                const in_progress = hasLegacyStatusObj[DEFAULT_CUSTOM_STATUS_IN_PROGRESS]
                    ? getLegacyVariants(IssueStatusEnum.in_progress)
                    : [];
                const solved = hasLegacyStatusObj[DEFAULT_CUSTOM_STATUS_SOLVED]
                    ? getLegacyVariants(IssueStatusEnum.solved)
                    : [];
                const closed = hasLegacyStatusObj[DEFAULT_CUSTOM_STATUS_CLOSED]
                    ? getLegacyVariants(IssueStatusEnum.closed)
                    : [];

                const compareStatuses = (a: CustomStatus, b: CustomStatus) => compareAlphanumerically(a.name, b.name);
                const toDo = _
                    .filter(nonLegacyStatuses, { category: CustomStatusCategory.ToDo })
                    .sort(compareStatuses);
                const tracking = _
                    .filter(nonLegacyStatuses, {  category: CustomStatusCategory.Tracking })
                    .sort(compareStatuses);
                const completed = _
                    .filter(nonLegacyStatuses, { category: CustomStatusCategory.Completed })
                    .sort(compareStatuses);

                const rawStatuses = [
                    ...open,
                    ...toDo,
                    ...in_progress,
                    ...tracking,
                    ...solved,
                    ...completed,
                    ...closed,
                ];
                const getStatusName = (status: string | CustomStatus) => _.isString(status) ? status : status.name;
                return _.compact(rawStatuses).map(getStatusName);
            };
        },
        customStatusesSortKeysByProjectUuidAndWorkflowUuid(state: IWorkflowState, getters: any): (projectUuid: TUuid, workflowUuid: TUuid) => TUuid[] {
            return (projectUuid, workflowUuid) => {
                const workflow = getters.workflowByUuid(projectUuid, workflowUuid);

                if (!workflow) {
                    return [];
                }

                return workflow.statusesUuids.map((uuid: TUuid) => {
                    return uuid;
                });
            };
        },
        customIssueTypeByUuid(state: IWorkflowState): (projectUuid: string, issueTypeUuid: string) => IssueType | undefined {
            return (projectUuid, issueTypeUuid) => {
                const issueTypes = state.types[projectUuid] || [];

                return issueTypes.find((issueType) => issueType.uuid === issueTypeUuid);
            };
        },
        customIssueStatusByUuid(state: IWorkflowState): (projectUuid: string, customStatusUuid: string) => CustomStatus | undefined {
            return (projectUuid, customStatusUuid) => {
                const statuses = state.statuses[projectUuid] || [];

                return statuses.find((status) => status.uuid === customStatusUuid);
            };
        },
        currentWorkflow(state: IWorkflowState): (projectUuid: string) => Workflow | undefined {
            return (projectUuid) => state.workflows[projectUuid].length > 0
                ? state.workflows[projectUuid].filter((workflow: Workflow) => workflow.uuid === state.currentWorkflowUuid)[0]
                : undefined;
        },
    },
    mutations: {
        setIsLoadingWorkflowSettings(state: IWorkflowState, value: boolean) {
            state.isLoadingWorkflowSettings = value;
        },
        setWorkflows(state: IWorkflowState, { projectUuid, workflows }: { projectUuid: string; workflows: IWorkflow[] }) {
            Vue.set(state.workflows, projectUuid, workflows);
        },
        setCustomStatuses(state: IWorkflowState, { projectUuid, statuses }: { projectUuid: string; statuses: ICustomStatus[] }) {
            Vue.set(state.statuses, projectUuid, statuses);
        },
        setIssueTypes(state: IWorkflowState, { projectUuid, types }: { projectUuid: string; types: IssueType[] }) {
            Vue.set(state.types, projectUuid, types.sort((a, b) => compareAlphanumerically(a.name, b.name)));
        },
        addIssueTypes(state: IWorkflowState, { projectUuid, type }: { projectUuid: string; type: IssueType }) {
            state.types[projectUuid].push(type);
        },
        deleteIssueType(state: IWorkflowState, { projectUuid, typeUuid }: { projectUuid: string; typeUuid: string }) {
            const types = _.cloneDeep(state.types[projectUuid]);
            const oldTypeIndex = types.findIndex((type: IssueType) => type.uuid === typeUuid);

            if (oldTypeIndex === -1) {
                return;
            }

            types.splice(oldTypeIndex, 1);
            Vue.set(state.types, projectUuid, types);
        },
        updateIssueType(state: IWorkflowState, { projectUuid, type }: { projectUuid: string; type: IssueType }) {
            const types = _.cloneDeep(state.types[projectUuid]);
            const typeIndex = types.findIndex((t: IssueType) => t.uuid === type.uuid);

            if (typeIndex === -1) {
                state.types[projectUuid].push(type);
            } else {
                types[typeIndex] = type;
                Vue.set(state.types, projectUuid, types);
            }
        },
        setCurrentWorkflow(state: IWorkflowState, value: string) {
            state.currentWorkflowUuid = value;
        },
        addWorkflow(state: IWorkflowState, { projectUuid, workflow }: { projectUuid: string; workflow: IWorkflow }) {
            const workflows = _.clone(state.workflows[projectUuid]);
            workflows.push(new Workflow(workflow));
            Vue.set(state.workflows, projectUuid, workflows);
        },
        addCustomStatus(state: IWorkflowState, { projectUuid, status }: { projectUuid: string; status: ICustomStatus }) {
            const statuses = _.clone(state.statuses[projectUuid]);
            statuses.push(new CustomStatus(status));
            Vue.set(state.statuses, projectUuid, statuses);
        },
        editCustomStatus(state: IWorkflowState, { projectUuid, status }: { projectUuid: string; status: ICustomStatus }) {
            const statuses = _.clone(state.statuses[projectUuid]).filter((item: CustomStatus) => item.uuid !== status.uuid);
            statuses.push(new CustomStatus(status));
            Vue.set(state.statuses, projectUuid, statuses);
        },
        updateWorkflow(state: IWorkflowState, { projectUuid, workflow }: { projectUuid: string; workflow: IWorkflow }) {
            const workflows = _.clone(state.workflows[projectUuid].filter((item: Workflow) => item.uuid !== workflow.uuid));
            workflows.push(new Workflow(workflow));
            Vue.set(state.workflows, projectUuid, workflows);
        },
        removeWorkflow(state: IWorkflowState, { projectUuid, workflowUuid }: { projectUuid: string; workflowUuid: TUuid }) {
            const workflows = _.clone(state.workflows[projectUuid]);
            const currentWorkflowIndex = workflows.findIndex((item: Workflow) => item.uuid === workflowUuid);
            const currentWorkflow = workflows[currentWorkflowIndex];
            currentWorkflow.remove();
            workflows.splice(currentWorkflowIndex, 1, currentWorkflow);
            Vue.set(state.workflows, projectUuid, workflows);
        },
        addStatusToWorkflow(state: IWorkflowState, { projectUuid, workflowUuid, statusesUuids }: {
            projectUuid: string;
            workflowUuid: TUuid,
            statusesUuids: TUuid[],
        }) {
            const workflows = _.clone(state.workflows[projectUuid]);
            const currentWorkflowIndex = workflows.findIndex((item: Workflow) => item.uuid === workflowUuid);
            const currentWorkflow = workflows[currentWorkflowIndex];
            statusesUuids.forEach((status) => {
                currentWorkflow.addStatus(status);
            });
            workflows.splice(currentWorkflowIndex, 1, currentWorkflow);
            Vue.set(state.workflows, projectUuid, workflows);
        },
        removeStatusFromWorkflow(state: IWorkflowState, { projectUuid, workflowUuid, statusesUuids }: {
            projectUuid: string;
            workflowUuid: TUuid,
            statusesUuids: TUuid[],
        }) {
            const workflows = _.clone(state.workflows[projectUuid]);
            const currentWorkflowIndex = workflows.findIndex((item: Workflow) => item.uuid === workflowUuid);
            const currentWorkflow = workflows[currentWorkflowIndex];
            statusesUuids.forEach((status) => {
                currentWorkflow.removeStatus(status);
            });
            workflows.splice(currentWorkflowIndex, 1, currentWorkflow);
            Vue.set(state.workflows, projectUuid, workflows);
        },
    },
    actions: {
        loadProjectWorkflowSettings({ commit }: IWorkflowContext, projectUuid: string | undefined) {
            if (!projectUuid) {
                return Promise.resolve();
            }
            return new Promise((resolve, reject) => {
                commit('setIsLoadingWorkflowSettings', true);

                ProjectApi.getWorkflowSettings(projectUuid).then((response) => {
                    commit('setCustomStatuses', {
                        projectUuid,
                        statuses: response.statuses.map((customStatus: ICustomStatus) => new CustomStatus(customStatus)),
                    });
                    commit('setIssueTypes', {
                        projectUuid,
                        types: response.types.map((issueType: IIssueType) => new IssueType(issueType)),
                    });
                    commit('setWorkflows', {
                        projectUuid,
                        workflows: response.workflows.map((workflow: IWorkflow) => new Workflow(workflow)),
                    });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingWorkflowSettings', false);
                });
            });
        },
        saveProjectWorkflowSettings(context: IWorkflowContext, { projectUuid, workflowUuid, params }: { projectUuid: TUuid, workflowUuid: TUuid, params: IWorkflowSettings }) {
            return ProjectApi.setWorkflowSettings(projectUuid, workflowUuid, params);
        },
        saveProjectWorkflow(context: IWorkflowContext, { projectUuid, params }: { projectUuid: TUuid, params: IWorkflowSettings }) {
            return ProjectApi.createWorkflow(projectUuid, params);
        },
        addStatusToWorkflow(context: any, { projectUuid, workflowUuid, statusesUuids }: { projectUuid: TUuid, workflowUuid: TUuid, statusesUuids: string[] }) {
            return ProjectApi.addStatusToWorkflow(projectUuid,  workflowUuid, statusesUuids);
        },
        reorderStatusesInWorkflow(context: any, { projectUuid, workflowUuid, statusesUuids }: { projectUuid: TUuid, workflowUuid: TUuid, statusesUuids: TUuid[] }) {
            return ProjectApi.reorderStatusesInWorkflow(projectUuid,  workflowUuid, statusesUuids);
        },
        addCustomStatus(context: any, { projectUuid, status }: { projectUuid: TUuid, status: ICustomStatus }) {
            return ProjectApi.addCustomStatus(projectUuid, status);
        },
        editCustomStatus(context: any, { projectUuid, status }: { projectUuid: TUuid, status: ICustomStatus }) {
            return ProjectApi.editCustomStatus(projectUuid, status);
        },
        removeCustomStatusFromWorkflow(context: any, { projectUuid, workflowUuid, statusReplacementMap }: { projectUuid: TUuid, workflowUuid: TUuid, statusReplacementMap: StatusReplacementMap[] }) {
            return ProjectApi.removeCustomStatusFromWorkflow(projectUuid, workflowUuid, statusReplacementMap);
        },
        removeWorkflow(context: IWorkflowContext, { projectUuid, workflowUuid, newWorkflowUuid, statusReplacementMap }: { projectUuid: TUuid, workflowUuid: TUuid, newWorkflowUuid: TUuid, statusReplacementMap: StatusReplacementMap[] }) {
            return ProjectApi.removeWorkflow(projectUuid, workflowUuid, {
                newWorkflowUuid,
                statusReplacementMap,
            });
        },
        createIssueType(context: IWorkflowContext, { projectUuid, params }: { projectUuid: string, params: IIssueTypeSettings }) {
            return ProjectApi.createIssueType(projectUuid, params);
        },
        updateIssueType(context: IWorkflowContext, { projectUuid, params }: { projectUuid: string, params: IIssueTypeSettings[] }) {
            return ProjectApi.updateIssueType(projectUuid, params);
        },
        deleteIssueType({ commit, dispatch }: IWorkflowContext, payload: {
            projectUuid: string,
            projectId: number,
            typeUuid: string,
            newTypeUuid: string,
            statusReplacementMap: StatusReplacementMap[],
        }) {
            return ProjectApi.deleteIssueType(payload.projectUuid, {
                typeUuid: payload.typeUuid,
                newTypeUuid: payload.newTypeUuid,
                statusReplacementMap: payload.statusReplacementMap,
            }).then(() => {
                dispatch('clearProjectIssuesStates', payload.projectId);
                commit('deleteIssueType', { projectUuid: payload.projectUuid, typeUuid: payload.typeUuid });
            });
        },
        restoreIssueType(context: IWorkflowContext, payload: {
            projectUuid: string,
            typeUuid: string,
        }) {
            return ProjectApi.restoreIssueType(payload.projectUuid, payload.typeUuid);
        },
        editIssueType(context: IWorkflowContext, payload: {
            projectId: number,
            projectUuid: string;
            uuid: string;
            name: string;
            description: string;
            icon: string;
            isDefault: boolean;
            workflowUuid: string;
            iconColor: string;
            statusReplacementMap: StatusReplacementMap[];
            deletedAt: null | string;
        }) {
            const params = {
                name: payload.name,
                description: payload.description,
                icon: payload.icon,
                isDefault: payload.isDefault,
                workflowUuid: payload.workflowUuid,
                iconColor: payload.iconColor,
                statusReplacementMap: payload.statusReplacementMap,
                deletedAt: payload.deletedAt,
            };

            return ProjectApi.editIssueType(payload.projectUuid, payload.uuid, params);
        },
    },
};
