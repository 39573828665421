












import { Component, Prop, Vue } from 'vue-property-decorator';
import { Color, IssueStatusEnum } from '@/constants';
import { ColorService } from '@/services';

@Component
export default class IssueStatusText extends Vue {
    @Prop({ required: true }) public status!: IssueStatusEnum;
    @Prop() public isAuto!: boolean;
    @Prop({ type: Boolean, default: false }) public tagLike!: boolean;
    @Prop({ type: Boolean }) public pointer!: boolean;

    public readonly Color = Color;

    get color() {
        return ColorService.getColorForField(this.status);
    }
}
