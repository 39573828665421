import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { notificationWarning } from '@/services';

@Component
export default class ScrollToHashMixin extends Vue {
    /**
     * Функция принимает id-шник того html-элемента, к которому нужно
     * проматывать скролл, дожидается, когда на странице всё отстроилось,
     * и проматывает
     */
    public scrollToHash(hash: string) {
        if (!hash) {
            return;
        }
        let count = 0;
        let notification: any;
        const interval = setInterval(() => {
            if (++count === 3) {
                notificationWarning({
                    title: this.$t('Simple_word.loading'),
                    text: this.$t('Dashboard.chart.loading'),
                });
            }
            const els = Array.from(document.querySelectorAll('.loading'))
                            // @ts-ignore
                             .filter((el) => el.style.display.trim() as string !== 'none');
            if (!els.length) {
                if (notification) {
                    notification.close();
                }
                location.href = hash;
                clearInterval(interval);
            }
        }, 500);
    }
}
