





















import { Component, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

@Component
export default class WsFieldLabel extends Vue {
    @Prop() public label!: VueI18n.TranslateResult;
    @Prop() public messages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public errorMessages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public successMessages!: VueI18n.TranslateResult[] | VueI18n.TranslateResult;
    @Prop() public rules!: any[];
    @Prop() public value!: any;
    @Prop({ default: 2 }) public maxMessages!: number;
    @Prop({ default: false }) public error!: boolean;
}
