




























import { Component, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import Router from '@/router';
import { BusEvent, RouterNames } from '@/constants';
import authHttp from '@/api/authHttp';
import http from '@/api/http';
import { License } from '@/models';
import { eventBus } from '@/services/eventBus';
import Header from '@/components/header/Header.vue';
import LoginMergeForm from '@/components/user/LoginMergeForm.vue';
import WsDialog from '@/components/common/WsDialog.vue';
import StepsDialog from '@/components/user/boarding/StepsDialog.vue';
import WelcomeDialog from '@/components/user/boarding/WelcomeDialog.vue';
import WsNotifier from '@/components/common/Notifier/WsNotifier.vue';

@Component({
    components: {
        Header,
        LoginMergeForm,
        WsDialog,
        WelcomeDialog,
        StepsDialog,
        WsNotifier,
    },
})
export default class App extends Vue {
    public isLoginToLicenseNeeded = false;
    public pathnameToRedirect = '';

    get isLoadingRegions() {
        return this.$store.getters.isLoadingRegions;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get licenses(): License[] {
        return this.$store.getters.licenses;
    }

    get isShowBoardingWelcome(): boolean {
        return this.$store.getters.isShowBoardingWelcome;
    }

    get isShowBoardingSteps(): boolean {
        return this.$store.getters.isShowBoardingSteps;
    }

    @Watch('license')
    public onNewLicense(license: License, oldLicense: License) {
        if (oldLicense.id && license.id !== oldLicense.id) {
            this.$store.commit('setPreviousLicenseId', oldLicense.id);
        }
        if (!license.isValidAuth && license.id) {
            this.pathnameToRedirect = location.pathname;
            VueCookies.set('pathnameToRedirect', this.pathnameToRedirect);
            VueCookies.set('pathnameLicenseId', String(license.id));
            this.isLoginToLicenseNeeded = true;
        } else {
            this.isLoginToLicenseNeeded = false;
        }
    }

    public created() {
        http.setLogoutCallback(() => {
            this.$store.dispatch('logout', { isGoToLogin: true });
        });
        authHttp.setLogoutCallback(() => {
            this.$store.dispatch('logout', { isGoToLogin: true });
        });
        
        this.handle2FaRequired();

        eventBus.$on(BusEvent.requestCopyToBuffer, this.copyToBuffer);
    }

    public handle2FaRequired() {
        eventBus.$on(BusEvent.faRequired, () => {
            Router.push({
                name: RouterNames.UserSecurity,
                params: {
                    show2FAPopup: 'true',
                },
            });
        });
    }

    public copyToBuffer(content: string) {
        // Use textarea for copy to buffer for avoid problems with copy from code and from modals
        const textarea = document.createElement('textarea');
        textarea.value = content;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.focus(); // It's necessary for case when you try copy to buffer from code when page have modal with focus
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }
}
