


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WsProjectPreview extends Vue {
    @Prop() public smallPreview!: string;
    @Prop() public previewImages!: any;

    public isError = false;

    get webpOriginal(): string {
        return this.previewImages?.original?.webp || '';
    }
    get webpSmall(): string {
        return this.previewImages?.small?.webp || '';
    }
    get jpegOriginal(): string {
        return this.previewImages?.original?.jpeg || this.smallPreview || '';
    }
    get jpegSmall(): string {
        return this.previewImages?.small?.jpeg || this.smallPreview || '';
    }
}
