import moment from 'moment';
import { Component } from 'vue-property-decorator';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

const minutes = {
    justNow: 5,
    hour: 60,
    day: 1440,
};

function getDiffInMinutes(unixTimestamp: number) {
    return Math.round((moment().unix() - unixTimestamp) / 60);
}

@Component
export default class BuiltTimeMixin extends EventListenersBase {
    public builtTimeKey = 0;
    public refreshIntervalId: any = 0;
    public MINUTE_MS = 60000;
    public MINUTE_S = 60;

    public created() {
        this.refreshIntervalId = setInterval(() => this.builtTimeKey++, this.MINUTE_MS);
    }

    public beforeDestroy() {
        clearInterval(this.refreshIntervalId);
    }

    public builtTimeFormatter(timestamp: number) {
        if (!timestamp) {
            return '';
        }

        const diffInMinutes = getDiffInMinutes(timestamp);

        if (diffInMinutes < minutes.justNow) {
            return this.$t('Dashboard.chart.builtTime.now');
        } else if (diffInMinutes < minutes.hour) {
            return this.$t('Dashboard.chart.builtTime.minutes', { time: diffInMinutes });
        } else if (diffInMinutes < minutes.day) {
            return this.$t('Dashboard.chart.builtTime.hours', { time: Math.round((diffInMinutes) / minutes.hour) });
        }

        return this.$t('Dashboard.chart.builtTime.days', { time: Math.floor((diffInMinutes) / minutes.day) });
    }

    public isShowRefreshButton(timestamp: number) {
        return getDiffInMinutes(timestamp) >= minutes.justNow;
    }
}
