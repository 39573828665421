

























































import moment from 'moment/moment';
import { Component, Prop } from 'vue-property-decorator';
import { DateFormatByNumber, DEADLINE_NOT_SET } from '@/constants';
import { DatepickerDateRange } from '@/models';
import { dateFormatter } from '@/services/MomentFormats';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsInputDatepicker from '@/components/common/WsInputDatepicker.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
        IconSvg16,
        WsButton,
        WsInputDatepicker,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueDetailsDate extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    public maxDate = moment().add(30, 'years').toDate();

    public inlineEdit = false;

    public localValue: DatepickerDateRange = {} as DatepickerDateRange;

    get isDeadlineNotSet() {
        return this.currentValue === DEADLINE_NOT_SET;
    }

    get showFieldMultipleTextLocal() {
        return this.inlineEdit ? false : this.showFieldMultipleText;
    }

    public created() {
        this.localValue = new DatepickerDateRange({
            startDate: this.isDeadlineNotSet ? moment() : moment(this.currentValue),
            endDate: moment(),
        });
    }

    public handleSave() {
        const value = this.localValue?.startDate.format(`${DateFormatByNumber[6]} HH:mm:ss`);
        if (value && value !== this.currentValue) {
            this.change(value);
        }
        this.stopEdit();
    }

    public startEdit() {
        this.inlineEdit = true;
    }

    public stopEdit() {
        this.inlineEdit = false;
    }

    public resetDeadline() {
        this.localValue = new DatepickerDateRange({
            startDate: moment(),
            endDate: moment(),
        });
        this.change(DEADLINE_NOT_SET);
        this.stopEdit();
    }
}
