














// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { Component, Vue } from 'vue-property-decorator';
import { AmplitudeEvent, CookieName, RouterNames } from '@/constants';
import { License } from '@/models';
import { amplitudeLog, sanitizeHtml } from '@/services';
import DialogBoarding from '@/components/user/boarding/DialogBoarding.vue';
import DialogBoardingContent from '@/components/user/boarding/DialogBoardingContent.vue';

@Component({
    methods: {
        sanitizeHtml,
    },
    components: {
        DialogBoarding,
        DialogBoardingContent,
    },
})
export default class WelcomeDialog extends Vue {
    get license(): License {
        return this.$store.getters.currentLicense;
    }
    get licenseName() {
        return this.license.name;
    }
    get licenseRole() {
        return this.$t(`License.role.${this.license.role}`);
    }
    get roleRights() {
        const translations: { [role: number]: string } = {
            4: 'license.invite.text5',
            3: 'license.invite.text6',
            2: 'license.invite.text9',
        };
        return translations[this.license.role];
    }

    get fullText() {
        return `
            <p>${this.$t('Boarding.welcomeStep.paragraph')}</p>
            <p>${this.$t('Boarding.text2', { licenseName: this.licenseName, licenseRole: this.licenseRole })}</p>
            <p>${this.$t(this.roleRights)}</p>`;
    }

    public created() {
        VueCookies.set(CookieName.isShowOnboardingSteps, true);
        amplitudeLog(AmplitudeEvent.boardingOpen);
    }

    public onClose() {
        amplitudeLog(AmplitudeEvent.boardingClose, { action: 0 });
        this.close();
    }

    public navigateToDownload() {
        amplitudeLog(AmplitudeEvent.boardingClose, { action: 1 });
        this.$router.push({ name: RouterNames.Download });
        this.close();
    }

    public close() {
        this.$store.commit('setIsShowBoardingWelcome', false);
    }

}
