


































































































































import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import {
    AmplitudeEvent,
    Color,
    ErrorsForDisableChat,
    RouterNames,
} from '@/constants';
import {
    DiffComment,
    FileComment,
    IssueTrackerDiffInlineProperties,
    IssueTrackerDiffMultilineProperties,
    IssueTrackerDiffSpecialProperties,
    License,
    LicenseMember,
    ProjectMember,
    TextComment,
} from '@/models';
import { amplitudeLog, copyUrlToBuffer, timeFormatter } from '@/services';
import IssueCommentDiff from '@/components/project/issueTracker/comments/IssueCommentDiff.vue';
import IssueCommentFile from '@/components/project/issueTracker/comments/IssueCommentFile.vue';
import MemberAvatar from '@/components/common/MemberAvatar.vue';
import WsMemberName from '@/components/common/WsMemberName.vue';
import WsMarkdownText from '@/components/common/WsMarkdownText.vue';
import WsLoaderDotsInline from '@/components/common/WsLoaderDotsInline.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsMenuIcon from '@/components/common/WsMenuIcon.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import IconAttention from '@/components/common/icon/IconAttention.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
        MemberAvatar,
        IssueCommentDiff,
        IssueCommentFile,
        WsMemberName,
        WsMarkdownText,
        WsLoaderDotsInline,
        IconSvg24,
        IconSvg16,
        WsButton,
        WsMenuIcon,
        WsTruncateAuto,
        IconAttention,
    },
    methods: {
        timeFormatter,
    },
})
export default class IssueComment extends Vue {
    @Prop({ required: true }) public comment!: DiffComment | FileComment | TextComment;
    @Prop({ required: true }) public commentIndex!: number;
    @Prop({ default: false, type: Boolean }) public isPinnedFormat!: boolean;

    public readonly Color = Color;

    public focused = false;
    public removed = false;

    get isDiff() {
        return this.comment instanceof DiffComment;
    }

    get isFile() {
        return this.comment instanceof FileComment;
    }

    get isText() {
        return this.comment instanceof TextComment;
    }

    get isVisible() {
        return !this.isDiff || _.intersection(
            Object.keys((this.comment as DiffComment).diff),
            [
                ...IssueTrackerDiffInlineProperties,
                ...IssueTrackerDiffMultilineProperties,
                ...IssueTrackerDiffSpecialProperties,
            ],
        ).length > 0;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get projectId(): string {
        return this.$route.params.projectId;
    }

    get licenseId(): string {
        return this.$route.params.licenseId;
    }

    get licenseMember(): LicenseMember | undefined {
        return this.$store.getters.licenseMemberByEmail(this.comment.reporter, this.licenseId);
    }

    get projectMember(): ProjectMember | undefined {
        return this.$store.getters.projectMemberByEmail(this.comment.reporter, this.projectId);
    }

    get reporter(): LicenseMember | ProjectMember | undefined {
        return this.licenseMember ?? this.projectMember;
    }

    get reporterFullname() {
        return this.reporter?.fullname ?? this.comment.reporter;
    }

    get additionalParams() {
        const { visibleCompany, visibleDepartment, visibleLocation } = this.license;
        const company = visibleCompany ? this.reporter?.company : null;
        const department = visibleDepartment ? this.reporter?.department : null;
        const location = visibleLocation ? this.reporter?.location : null;

        return [company, department, location].filter(Boolean).join(' • ');
    }

    get avatarSrc() {
        return this.reporter?.avatar;
    }

    get memberId() {
        return this.reporter?.id;
    }

    get language(): string {
        return this.$route.params.language;
    }

    get selectedIssue() {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get disableResend() {
        return ErrorsForDisableChat.includes(this.comment.pendingError as number);
    }

    get errorMessageCode() {
        if (!this.comment.pendingError) {
            return '';
        }

        if (ErrorsForDisableChat.includes(this.comment.pendingError)) {
            return this.$t(`IssueTracker.chat.errorDetails.${this.comment.pendingError}`);
        }

        return this.$t('IssueTracker.chat.errorDetails.-1');
    }

    @Emit()
    public showImage(comment: DiffComment | FileComment | TextComment) {
        return comment;
    }

    @Emit()
    public click() {
        return;
    }

    @Emit('mounted')
    public emitMountedEvent() {
        return;
    }

    @Watch('comment.focused', { deep: true, immediate: true })
    public onFocusComment(newValue: boolean) {
        if (newValue) {
            this.focusComment();
        }
    }

    @Watch('$route', { deep: true, immediate: true })
    public onRouteChange() {
        this.tryToFocus();
    }

    public mounted() {
        this.tryToFocus();

        if (!this.isFile) {
            this.emitMountedEvent();
        }
    }

    public tryToFocus() {
        if (!this.isPinnedFormat) {
            const hashUuid = this.$route.hash.slice(1); // отрезаю #
            const isCommentInHash = hashUuid === this.comment.uuid;
            if (isCommentInHash || this.comment.focused) {
                this.focusComment();
            }
        }
    }

    public focusComment() {
        const focusInitialTime = 200;
        const focusDuration = 1000;
        setTimeout(() => {
            this.focused = true;
            setTimeout(() => {
                this.focused = false;
                this.comment.focused = false;
            }, focusDuration);
        }, focusInitialTime);
    }

    public copyCommentUrl() {
        const selectedIssue = this.$store.getters.selectedIssueByProjectId(this.projectId);
        const id = selectedIssue.id;
        const uuid = this.comment.uuid;
        const itPath = this.$router.resolve({
            name: RouterNames.ProjectIssueTracker,
            params: {
                language: this.language,
                licenseId: String(this.licenseId),
                projectId: String(this.projectId),
            },
        }).href;
        const commentUrl = `${location.origin}${itPath}?id=${id}#${uuid}`;
        copyUrlToBuffer(commentUrl);

        amplitudeLog(AmplitudeEvent.itCommentCopyLink);
    }

    public deleteComment() {
        this.removed = true;
        setTimeout(() => {
            this.$store.commit('removePendingCommentsForIssue', {
                projectId: this.projectId,
                issueUuid: this.selectedIssue.uuid,
                comments: [this.comment],
            });
        }, 300);
    }

    public resendComment() {
        this.$store.dispatch('resendCommentsForIssue', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            comments: [this.comment],
        });
    }

    public async pin() {
        await this.$store.dispatch('pinIssueComment', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            commentUuid: this.comment.uuid,
        });
        await this.$store.dispatch('loadCommentsForIssue', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            isForce: true,
        });

        amplitudeLog(AmplitudeEvent.pinComment);
    }

    public async unpin() {
        await this.$store.dispatch('unpinIssueComment', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            commentUuid: this.comment.uuid,
        });
        await this.$store.dispatch('loadCommentsForIssue', {
            projectId: this.projectId,
            issueUuid: this.selectedIssue.uuid,
            isForce: true,
        });

        amplitudeLog(AmplitudeEvent.unpinComment);
    }

    public onAttachmentLoaded() {
        this.emitMountedEvent();
    }
}
