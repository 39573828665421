export const FR = [
    {
        'uuid': '1ae71954-72e4-439c-90e2-3d21110a3284',
        'title': 'Structure vs Architecture',
        'fields': '{"stampAbbr":"AA"}',
        'created': '2022-02-28 08:34:31',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '7daf8517-1d0e-48f0-ac83-99fab559a3c5',
        'nodeRole': 1,
    },
    {
        'uuid': '7eccb87f-80c5-4454-950d-92054dd1f2cc',
        'title': 'Archi clash avec les autres lots',
        'fields': '{"stampAbbr":"AC"}',
        'created': '2022-12-16 02:52:44',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '5d17bf79-bace-4ddd-95d5-2e625e1beb78',
        'nodeRole': 1,
    },
    {
        'uuid': 'cb628d0e-3de9-4bbb-803a-e8228d9d06c9',
        'title': 'Facade: ouverture à vérifier',
        'fields': '{"stampAbbr":"AF"}',
        'created': '2022-02-28 08:34:15',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '7daf8517-1d0e-48f0-ac83-99fab559a3c5',
        'nodeRole': 1,
    },
    {
        'uuid': '422f1307-27cd-4d5d-bd8e-7e73e70ee90c',
        'title': 'Alignement mur',
        'fields': '{"stampAbbr":"AM"}',
        'created': '2022-02-28 08:24:08',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '5d17bf79-bace-4ddd-95d5-2e625e1beb78',
        'nodeRole': 1,
    },
    {
        'uuid': '5b047633-fd60-4b68-a9e5-b15fc5f21128',
        'title': 'AEC vs MEP',
        'fields': '{"priority":"major","stampAbbr":"AME"}',
        'created': '2022-07-16 05:50:56',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '7daf8517-1d0e-48f0-ac83-99fab559a3c5',
        'nodeRole': 1,
    },
    {
        'uuid': '4b3e27c8-1ad3-4a26-b734-a420c6a78ebf',
        'title': 'Clash avec MEP',
        'fields': '{"stampAbbr":"AMP"}',
        'created': '2022-02-28 08:25:17',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '5d17bf79-bace-4ddd-95d5-2e625e1beb78',
        'nodeRole': 1,
    },
    {
        'uuid': '4c446bd8-b2d6-4b2c-978f-ed5812a5eb27',
        'title': 'Ouverture à ajouter ou vérifier',
        'fields': '{"stampAbbr":"AO"}',
        'created': '2022-02-28 08:32:49',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '7daf8517-1d0e-48f0-ac83-99fab559a3c5',
        'nodeRole': 1,
    },
    {
        'uuid': '1df47e97-f60b-421d-bb3b-1c0a9bc25591',
        'title': 'Position de porte',
        'fields': '{"stampAbbr":"AP"}',
        'created': '2022-02-28 08:24:55',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '5d17bf79-bace-4ddd-95d5-2e625e1beb78',
        'nodeRole': 1,
    },
    {
        'uuid': 'bbf47de9-3026-41ca-ad9e-da2cc826d8b8',
        'title': 'Porte : ouverture à ajouter, vérifier ou enlever',
        'fields': '{"stampAbbr":"APO"}',
        'created': '2022-02-28 08:34:01',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '7daf8517-1d0e-48f0-ac83-99fab559a3c5',
        'nodeRole': 1,
    },
    {
        'uuid': 'ee876151-fe01-47a7-b3ba-968972b609e3',
        'title': 'Soft clash : à vérifier d\'après régulation',
        'fields': '{"stampAbbr":"ASC"}',
        'created': '2022-02-28 08:25:42',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': '5d17bf79-bace-4ddd-95d5-2e625e1beb78',
        'nodeRole': 1,
    },
    {
        'uuid': 'da3731ec-60c3-4778-b2b1-2ea52790a1aa',
        'title': 'Elec à ajuster d\'après ARC',
        'fields': '{"stampAbbr":"EA"}',
        'created': '2022-02-28 08:27:08',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'd92952a4-0fa7-43e7-81ea-df2f66e9c5ce',
        'nodeRole': 1,
    },
    {
        'uuid': 'e45931d3-5487-475e-87be-f077f471ef8c',
        'title': 'Clash Interne clash avec élec, CVC ou plomberie',
        'fields': '{"stampAbbr":"EM"}',
        'created': '2022-02-28 08:27:52',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'd92952a4-0fa7-43e7-81ea-df2f66e9c5ce',
        'nodeRole': 1,
    },
    {
        'uuid': '1f7cd5ca-72d6-4d3d-90ea-14c588cc47b4',
        'title': 'Elec à ajuster d\'après structure',
        'fields': '{"stampAbbr":"ES"}',
        'created': '2022-02-28 08:27:24',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'd92952a4-0fa7-43e7-81ea-df2f66e9c5ce',
        'nodeRole': 1,
    },
    {
        'uuid': 'a1639552-3a04-4de4-bd18-edc47863f4e6',
        'title': 'Soft clash : à vérifier d\'après régulation',
        'fields': '{"stampAbbr":"ESC"}',
        'created': '2022-02-28 08:28:14',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'd92952a4-0fa7-43e7-81ea-df2f66e9c5ce',
        'nodeRole': 1,
    },
    {
        'uuid': 'e6523cb3-3d51-4cb3-a970-82b4351d9069',
        'title': 'ELEC  à ajuster d\'après commentaires STR',
        'fields': '{"stampAbbr":"EST"}',
        'created': '2022-07-14 11:23:22',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'd92952a4-0fa7-43e7-81ea-df2f66e9c5ce',
        'nodeRole': 1,
    },
    {
        'uuid': 'a91114d3-13c3-451f-8d2d-73fab9599c3d',
        'title': 'Mec à ajuster d\'après ARC',
        'fields': '{"stampAbbr":"MA"}',
        'created': '2022-02-28 08:28:39',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'bf0cf5c5-b7d9-4abb-9826-6161888f4fea',
        'nodeRole': 1,
    },
    {
        'uuid': '9573295b-1e71-4536-867d-d2913fa8bddc',
        'title': 'Clash interne avec Elec, plomberie ou CVC',
        'fields': '{"stampAbbr":"ME"}',
        'created': '2022-02-28 08:29:27',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'bf0cf5c5-b7d9-4abb-9826-6161888f4fea',
        'nodeRole': 1,
    },
    {
        'uuid': 'db8f2bc4-2577-4622-88ba-57c5a4faeb0e',
        'title': 'CVC ou plomberie à ajuster d\'après Structure',
        'fields': '{"stampAbbr":"MS"}',
        'created': '2022-02-28 08:29:57',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'bf0cf5c5-b7d9-4abb-9826-6161888f4fea',
        'nodeRole': 1,
    },
    {
        'uuid': '1cc713ff-d5c0-4cbe-bcb6-a32ef030c93c',
        'title': 'Soft clash : à vérifier d\'après régulation',
        'fields': '{"stampAbbr":"MSC"}',
        'created': '2022-02-28 08:30:16',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'bf0cf5c5-b7d9-4abb-9826-6161888f4fea',
        'nodeRole': 1,
    },
    {
        'uuid': 'f5723f50-534c-495e-9154-b5a1fe28eb41',
        'title': 'MEC  à ajuster d\'après commentaires STR',
        'fields': '{"stampAbbr":"MST"}',
        'created': '2022-07-14 11:21:51',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'bf0cf5c5-b7d9-4abb-9826-6161888f4fea',
        'nodeRole': 1,
    },
    {
        'uuid': '6ad1587c-394f-4082-8f10-7ca177959747',
        'title': 'STR à ajuster d\'après MEP',
        'fields': '{"stampAbbr":"SM"}',
        'created': '2022-02-28 08:31:04',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'c39a5abf-8c97-423f-9de9-32766d3e512b',
        'nodeRole': 1,
    },
    {
        'uuid': 'fd0870c1-b63e-4d0c-939e-62268dfefc1f',
        'title': 'Soft clash : à vérifier d\'après régulation',
        'fields': '{"stampAbbr":"SSC"}',
        'created': '2022-02-28 08:31:51',
        'updated': '2023-08-09 10:23:41',
        'parentUuid': 'c39a5abf-8c97-423f-9de9-32766d3e512b',
        'nodeRole': 1,
    },
    {
        'uuid': '7daf8517-1d0e-48f0-ac83-99fab559a3c5',
        'title': 'AEC',
        'fields': '{"stampColor":10,"visibility":1}',
        'created': '2022-02-28 08:14:29',
        'updated': '2023-06-13 08:56:42',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '5d17bf79-bace-4ddd-95d5-2e625e1beb78',
        'title': 'ARC',
        'fields': '{"deadline":"2000-01-01 00:00:00","stampColor":1,"visibility":1,"deadline_offset":{"units":0,"value":7}}',
        'created': '2022-02-28 08:13:53',
        'updated': '2023-06-13 08:56:45',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'd92952a4-0fa7-43e7-81ea-df2f66e9c5ce',
        'title': 'ELEC',
        'fields': '{"stampColor":5,"visibility":1}',
        'created': '2022-02-28 08:14:07',
        'updated': '2023-06-13 08:56:49',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'bf0cf5c5-b7d9-4abb-9826-6161888f4fea',
        'title': 'MEC',
        'fields': '{"stampColor":7,"visibility":1}',
        'created': '2022-02-28 08:14:03',
        'updated': '2023-06-13 08:56:52',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'c39a5abf-8c97-423f-9de9-32766d3e512b',
        'title': 'STR',
        'fields': '{"stampColor":2,"visibility":1}',
        'created': '2022-02-28 08:14:16',
        'updated': '2023-06-13 08:56:54',
        'parentUuid': null,
        'nodeRole': 2,
    },
];
