























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg16,
        IconSvg24,
    },
})
export default class WsCloseButton extends Vue {
    @Prop({ default: Color.closeBlack }) public color!: string;
    @Prop({ type: Boolean, default: false }) public round!: boolean;
    @Prop({ type: Boolean, default: false }) public big!: boolean;
    @Prop({ type: Boolean, default: false }) public small!: boolean;

    public readonly Color = Color;

    get buttonStyle() {
        return `color: ${this.color};`;
    }

    get buttonClasses() {
        return {
            'ws-close-button--round': this.round,
            'ws-close-button--small': this.small,
            'ws-close-button': true,
        };
    }

    @Emit()
    public click() {
        return;
    }
}
