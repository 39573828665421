














































































import _ from 'lodash';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { MultiEditIssueFieldPayload } from '@/types/MultiEditIssueFieldPayload';
import { IssuesFilterType, LOCAL_STORAGE_TAG_KEY } from '@/constants';
import { MAX_NOT_COLLAPSED_LENGTH } from '@/constants/IssueTracker';
import { Issue, Project } from '@/models';
import { compareAlphanumerically } from '@/services';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/components/project/issueTracker/details/IssueDetailsEditPopover.vue';
import ProjectMemberName from '@/components/project/ProjectMemberName.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsCombobox from '@/components/common/WsCombobox.vue';
import WsButton from '@/components/common/WsButton.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';
import DialogEditTags from '@/components/project/issueTracker/modals/DialogEditTags.vue';
import DialogMultiEditTags from '@/components/project/issueTracker/modals/DialogMultiEditTags.vue';

@Component({
    components: {
        DialogEditTags,
        DialogMultiEditTags,
        IssueDetailsItem,
        IssueDetailsEditPopover,
        ProjectMemberName,
        WsTruncateAuto,
        IconSvg16,
        WsCombobox,
        WsButton,
    },
})
export default class IssueDetailsTags extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];
    @Prop() public allowCreateTag!: boolean;

    public readonly collapsingKey = LOCAL_STORAGE_TAG_KEY;

    public isVisibleDialogEditTags = false;
    public isVisibleDialogMultiEditTags = false;

    public localTags = [];
    public multiselectChanges: null | {
        previous: string[];
        added: string[];
        removed: string[];
    } = null;

    public readonly getCommaForListItem = getCommaForListItem;

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get joinedList(): string {
        if (Array.isArray(this.currentValue)) {
            return this.currentValue.join(this.$t('Punctuation.comma') as string);
        }

        return '';
    }

    get multiSelectIssues(): Issue[] {
        return this.$store.getters.multiSelectedIssues;
    }

    get multiSelectTagsEntries(): Array<{ tag: string, count: number }> {
        const tagsEntries = new Map();

        this.multiSelectIssues.forEach((issue: Issue) => {
            issue.tags.forEach((tag) => {
                const currentTagCount = tagsEntries.get(tag);

                if (currentTagCount) {
                    tagsEntries.set(tag, currentTagCount + 1);
                } else {
                    tagsEntries.set(tag, 1);
                }
            });
        });

        return Array.from(tagsEntries, ([tag, count]) => ({
            tag,
            count,
        }));
    }

    get collapsable() {
        return this.stringLongerThanPossible(this.joinedList) || this.currentValue.length > 2;
    }

    get sortedItems() {
        return this.items.sort(compareAlphanumerically);
    }

    get isShowManageTags() {
        const project = this.$store.getters.projectById(Number(this.$route.params.projectId));

        return project?.isCanSeeIssueSettings;
    }

    get tagsList() {
        return this.multiEditNewValues?.value || this.currentIssue.tags;
    }

    @Watch('multiSelectIssues')
    public onMultiSelectIssuesChange() {
        this.multiselectChanges = null;
    }

    @Emit()
    public click(value: string[]) {
        return { value, type: IssuesFilterType.tags };
    }

    public mounted() {
        this.localTags = this.currentValue;
    }

    public stringLongerThanPossible(string: string) {
        return string.length > MAX_NOT_COLLAPSED_LENGTH;
    }

    public edit() {
        this.localTags = this.currentValue;

        if (this.isMultiselectEditModeActive) {
            this.isVisibleDialogMultiEditTags = true;
        } else {
            this.isVisibleDialogEditTags = true;
        }
    }

    public save() {
        const isEqual = _.isEqual(this.currentValue.sort(), this.localTags.sort());
        if (!isEqual) {
            this.change(this.localTags);

            this.localTags.forEach((tag) => {
                this.currentProject.addIssueTag(tag);
            });
        }

        this.endEdit();
    }

    public saveMultiEdit(payload: MultiEditIssueFieldPayload) {
        this.multiselectChanges = {
            previous: this.multiSelectTagsEntries.map((entry) => entry.tag),
            added: payload.add,
            removed: payload.remove,
        };

        this.change(payload);
        this.endEdit();
    }

    public restore() {
        this.localTags = this.currentValue;
        this.endEdit();
    }

    public endEdit() {
        this.isVisibleDialogEditTags = false;
        this.isVisibleDialogMultiEditTags = false;
    }
}
