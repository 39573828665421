














































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { AbstractComment, FileComment, Issue, MarkupComment } from '@/models';
import IssueCommentFileItem from '@/components/project/issueTracker/comments/IssueCommentFileItem.vue';

@Component({
    components: {
        IssueCommentFileItem,
    },
})
export default class IssueCommentFile extends Vue {
    @Prop({ required: true }) public comment!: FileComment | MarkupComment;
    @Prop({ required: true }) public commentIndex!: number;
    @Prop({ default: false, type: Boolean }) public isPinnedFormat!: boolean;

    get isMarkup() {
        return this.comment instanceof MarkupComment;
    }

    get isImage() {
        return this.comment?.mimetype?.startsWith('image/');
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get currentIssueComments(): AbstractComment[] {
        return this.currentIssue
            ? this.$store.getters.commentsByIssue(this.projectId, this.currentIssue.uuid)
            : [];
    }

    @Emit()
    public showImage(comment: FileComment | MarkupComment) {
        return comment;
    }

    @Emit()
    public click() {
        return;
    }

    @Emit('img-loaded')
    public imageLoaded() {
        return;
    }

    public getFileUrl(commentUuid: string) {
        const commentElement = this.currentIssueComments.find((comment: AbstractComment | FileComment) => comment.uuid === commentUuid);
        return (commentElement as FileComment).preview?.original;

    }

    public handleImageClick() {
        this.showImage(this.comment);
    }

    public onLoadImage() {
        this.imageLoaded();
    }
}
