import { truncateString } from '@/services';

export class RecentProject {
    public static instantiate(rawRecentProject: any) {
        return new RecentProject(rawRecentProject);
    }

    public uuid: string;
    public title: string;
    public params: any;
    public componentName: string;

    constructor(rawRecentProject: any) {
        this.uuid = rawRecentProject.uuid;
        this.title = rawRecentProject.title;
        this.params = rawRecentProject.params;
        this.componentName = rawRecentProject.componentName;
    }

    get shortTitle() {
        return truncateString(this.title);
    }
}
