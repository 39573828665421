































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { SIDEBAR_DEFAULT_WIDTH } from '@/constants';

@Component
export default class WsSidebar extends Vue {
    @Prop({ default: false, type: Boolean }) public show!: boolean;
    @Prop({ default: true, type: Boolean }) public temporary!: boolean;
    @Prop({ default: true, type: Boolean }) public absolute!: boolean;
    @Prop({ default: false, type: Boolean }) public fixed!: boolean;
    @Prop({ default: true, type: Boolean }) public right!: boolean;
    @Prop({ default: true, type: Boolean }) public stateless!: boolean;
    @Prop({ default: true, type: Boolean }) public hideOverlay!: boolean;
    @Prop({ default: SIDEBAR_DEFAULT_WIDTH, type: Number }) public width!: number;
    @Prop({ default: 0, type: Number }) public topOffset!: number;

    @Emit()
    public open() {}

    @Emit()
    public close(value: boolean) {
        return value;
    }

    get visible() {
        return this.show;
    }

    set visible(value: boolean) {
        if (!value) {
            this.close(value);
        }
    }
}
