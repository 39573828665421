import { IssueFilterExpr, IssuesFilterType } from '@/constants';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import { IssueTrackerFilterValue } from '@/services/issueTracker/IssueTrackerFilterValue';
import { IssueTrackerFilterByDeadlineValue } from '@/services/issueTracker/IssueTrackerFilterByDeadlineValue';
import { IssueTrackerFilterByDateValue } from '@/services/issueTracker/IssueTrackerFilterByDateValue';

const anyNoneEmptySelect = (type: string) => new IssueTrackerFilterValue(type, IssueFilterExpr.IN, []);
const allAnyNoneEmptySelect = (type: string) => new IssueTrackerFilterValue(type, IssueFilterExpr.HAS_ONE_IN, []);
const dateEmptyFilter = (type: string) => new IssueTrackerFilterByDateValue(type, -1);
const deadlineEmptyFilter = (type: string) => new IssueTrackerFilterByDeadlineValue(type, -1);
export const getEmptyFilters = (): TrackerFilters => ({
    customType: anyNoneEmptySelect(IssuesFilterType.customType),
    binding: anyNoneEmptySelect(IssuesFilterType.binding),
    assignee: anyNoneEmptySelect(IssuesFilterType.assignee),
    assigneeCompany: anyNoneEmptySelect(IssuesFilterType.assigneeCompany),
    assigneeDepartment: anyNoneEmptySelect(IssuesFilterType.assigneeDepartment),
    assigneeLocation: anyNoneEmptySelect(IssuesFilterType.assigneeLocation),
    watchers: allAnyNoneEmptySelect(IssuesFilterType.watchers),
    customStatus: anyNoneEmptySelect(IssuesFilterType.customStatus),
    statusCategory: anyNoneEmptySelect(IssuesFilterType.statusCategory),
    deadline: deadlineEmptyFilter(IssuesFilterType.deadline),
    priority: anyNoneEmptySelect(IssuesFilterType.priority),
    reporter: anyNoneEmptySelect(IssuesFilterType.reporter),
    tags: allAnyNoneEmptySelect(IssuesFilterType.tags),
    type: anyNoneEmptySelect(IssuesFilterType.type),
    stampAbbr: anyNoneEmptySelect(IssuesFilterType.stampAbbr),
    stampCategory: anyNoneEmptySelect(IssuesFilterType.stampCategory),
    clashDiscipline: allAnyNoneEmptySelect(IssuesFilterType.clashDiscipline),
    clashLevel: allAnyNoneEmptySelect(IssuesFilterType.clashLevel),
    clashArea: allAnyNoneEmptySelect(IssuesFilterType.clashArea),
    clashRoom: allAnyNoneEmptySelect(IssuesFilterType.clashRoom),
    clashSpace: allAnyNoneEmptySelect(IssuesFilterType.clashSpace),
    clashZone: allAnyNoneEmptySelect(IssuesFilterType.clashZone),
    clashSourceFile: allAnyNoneEmptySelect(IssuesFilterType.clashSourceFile),
    clashTest: allAnyNoneEmptySelect(IssuesFilterType.clashTest),
    clashGridX: allAnyNoneEmptySelect(IssuesFilterType.clashGridX),
    clashGridY: allAnyNoneEmptySelect(IssuesFilterType.clashGridY),
    clashCategory: allAnyNoneEmptySelect(IssuesFilterType.clashCategory),
    stampColor: allAnyNoneEmptySelect(IssuesFilterType.stampColor),
    procoreStatus: allAnyNoneEmptySelect(IssuesFilterType.procoreStatus),
    procoreType: allAnyNoneEmptySelect(IssuesFilterType.procoreType),
    closed: dateEmptyFilter(IssuesFilterType.closed),
    created: dateEmptyFilter(IssuesFilterType.created),
    id: anyNoneEmptySelect(IssuesFilterType.id),
    mainSheet: anyNoneEmptySelect(IssuesFilterType.mainSheet),
    mainSheetTag: allAnyNoneEmptySelect(IssuesFilterType.mainSheetTag),
    visibility: anyNoneEmptySelect(IssuesFilterType.visibility),
    deletedAt: dateEmptyFilter(IssuesFilterType.deletedAt),
});
