export enum IssuesGrouping {
    project = 'project',
    assignee = 'assignee',
    reporter = 'reporter',
    priority = 'priority',
    status = 'status', // Legacy, use statusCategory instead
    customStatus = 'customStatus',
    type = 'type', // Legacy, use customType instead
    customType = 'customType',
    statusCategory = 'statusCategory',
    tags = 'tags',
    stampAbbr = 'stampAbbr',
    stampCategory = 'stampCategory',
    stampColor = 'stampColor',
    level = 'clashLevel',
    room = 'clashRoom',
    space = 'clashSpace',
    area = 'clashArea',
    zone = 'clashZone',
    clashGrid = 'clashGrid',
    clashTest = 'clashTest',
    clashSourceFile = 'clashSourceFile',
    procoreType = 'procoreType',
    procoreStatus = 'procoreStatus',
    assigneeCompany = 'assigneeCompany',
    assigneeDepartment = 'assigneeDepartment',
    assigneeLocation = 'assigneeLocation',
    reporterCompany = 'reporterCompany',
    reporterDepartment = 'reporterDepartment',
    reporterLocation = 'reporterLocation',
}
