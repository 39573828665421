import { MemberRoleEnum } from '@/constants';

export class LicenseMemberForm {
    public email: string;
    public role: number;
    public authMethodUuid: string;
    public tags: string[];
    public firstName: string;
    public lastName: string;
    public company: string;
    public department: string;
    public location: string;

    constructor(licenseMemberForm: any = {}) {
        this.email = licenseMemberForm.email || '';
        this.role = licenseMemberForm.role || MemberRoleEnum.collaborator;
        this.authMethodUuid = licenseMemberForm.authMethodUuid || '';
        this.tags = licenseMemberForm.tags || [];
        this.firstName = licenseMemberForm.firstName || '';
        this.lastName = licenseMemberForm.lastName || '';
        this.company = licenseMemberForm.company || '';
        this.department = licenseMemberForm.department || '';
        this.location = licenseMemberForm.location || '';
    }
}
