// @ts-ignore
import amplitude from 'amplitude-js';
// @ts-ignore
import settings from 'reviztoSettings';

export const amplitudeInstance = amplitude.getInstance();

export function amplitudeLog(name: string, payload?: any) {
    if (settings.amplitudeApiKey) {
        return amplitudeInstance.logEvent(name, payload);
    } else if (process.env.NODE_ENV !== 'development') {
        window.console.info('Amplitude event', name, payload);
    }
}
