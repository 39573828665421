import { IntegratedServices, IUserConnection } from '@/types/User';
import IntegrationsApi, { IntegrationPayload } from '@/api/integrations.api';
import { UserApi } from '@/api';

interface IIntegrationsStorage {
    isIntegrationSending: boolean;
    isIntegrationSuccess: boolean;
    isIntegrationError: boolean;
    integrationErrorCode: INTEGRATION_ERRORS;
    connections: IUserConnection[];
    asiteRegions: any[];
}

export enum INTEGRATION_ERRORS {
    ACCESS_DENIED = -1303,
    NOT_FOUND = -1304,
}

export default {
    state: {
        isIntegrationSending: false,
        isIntegrationSuccess: false,
        isIntegrationError: false,
        integrationErrorCode: NaN,
        connections: [],
        asiteRegions: [],
    },
    getters: {
        procoreConnection(state: IIntegrationsStorage) {
            return state.connections.filter((connection: IUserConnection) => connection.service === IntegratedServices.PROCORE)![0];
        },
        boxConnection(state: IIntegrationsStorage) {
            return state.connections.filter((connection: IUserConnection) => connection.service === IntegratedServices.BOX)![0];
        },
        asiteConnection(state: IIntegrationsStorage) {
            return state.connections.filter((connection: IUserConnection) => connection.service === IntegratedServices.ASITE)![0];
        },
        forgeConnection(state: IIntegrationsStorage) {
            return state.connections.filter((connection: IUserConnection) => connection.service === IntegratedServices.FORGE)![0];
        },
        sharepointConnections(state: IIntegrationsStorage) {
            return state.connections.filter((connection: IUserConnection) => connection.service === IntegratedServices.SHAREPOINT);
        },
        isIntegrationSending(state: IIntegrationsStorage) {
            return state.isIntegrationSending;
        },
        isIntegrationSuccess(state: IIntegrationsStorage) {
            return state.isIntegrationSuccess;
        },
        isIntegrationError(state: IIntegrationsStorage) {
            return state.isIntegrationError;
        },
        integrationErrorCode(state: IIntegrationsStorage) {
            return state.integrationErrorCode;
        },
        asiteRegions(state: IIntegrationsStorage) {
            return state.asiteRegions;
        },
    },
    mutations: {
        setIsIntegrationSending(state: IIntegrationsStorage, value: boolean) {
            state.isIntegrationSending = value;
        },
        setIsIntegrationSuccess(state: IIntegrationsStorage, value: boolean) {
            state.isIntegrationSuccess = value;
        },
        setIsIntegrationError(state: IIntegrationsStorage, value: boolean) {
            state.isIntegrationError = value;
        },
        setIntegrationErrorCode(state: IIntegrationsStorage, value: number) {
            state.integrationErrorCode = value;
        },
        clearIntegrationStatuses(state: IIntegrationsStorage) {
            state.isIntegrationSending = false;
            state.isIntegrationSuccess = false;
            state.isIntegrationError = false;
        },
        setUserConnections(state: IIntegrationsStorage, connections: IUserConnection[]) {
            state.connections = connections;
        },
        setAsiteRegions(state: IIntegrationsStorage, regions: any[]) {
            state.asiteRegions = regions;
        },
    },
    actions: {
        sendAccessCode({ commit }: any, { service, payload }: { service: string; payload: IntegrationPayload }) {
            commit('clearIntegrationStatuses');
            commit('setIsIntegrationSending', true);
            return IntegrationsApi.postAccessCode(service, payload).then(() => {
                commit('setIsIntegrationSuccess', true);
            }).catch((error) => {
                commit('setIsIntegrationError', true);
                commit('setIntegrationErrorCode', error.result);
                throw error;
            }).finally(() => {
                commit('setIsIntegrationSending', false);
            });
        },
        async getUserConnection({ commit }: any) {
            const result = await UserApi.getUserConnection();
            commit('setUserConnections', result);
        },
        async getAsiteRegions({ commit }: any) {
            const result = await UserApi.getAsiteRegions();
            const regions: any = [];
            Object.keys(result).forEach((key: string) => {
                regions.push(result[key]);
            });
            commit('setAsiteRegions', regions);
        },
        async logoutUserFromService(context: any, { service, params }: { service: IntegratedServices, params: any }) {
            return await UserApi.logoutUserFromService(service, params);
        },
    },
};
