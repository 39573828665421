export enum LanguageKey {
    en = 'en',
    es = 'es',
    de = 'de',
    fr = 'fr',
    ru = 'ru',
    cn = 'cn',
    pt = 'pt',
    jp = 'jp',
}
