






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';
import LoginForm from '@/components/user/LoginForm.vue';
import LoginSSOForm from '@/components/user/LoginSSOForm.vue';

enum Tab {
    login = 'login',
    sso = 'sso',
}

@Component({
    components: {
        LoginForm,
        LoginSSOForm,
    },
})
export default class AuthVariants extends Vue {
    @Prop({ type: Boolean }) public isOAuth2Redirect!: boolean;
    public readonly Tab = Tab;

    public isReviztoInternalHidden = false;
    public appSSO = this.$store.getters.appSSO;
    public email = '';
    public isShowTabs = true;
    public isShowWelcome = true;
    public currentTabIdx = 0;

    public onTabChange(index: number) {
        if (index === 0) {
            this.activeTab = Tab.login;
        } else {
            this.activeTab = Tab.sso;
        }
    }

    get tabsHeader() {
        return [
            {
                tabsName: Tab.login,
                label: this.$t('Login.tabInternal'),
            },
            {
                tabsName: Tab.sso,
                label: this.$t('Login.tabSso'),
            },
        ];
    }

    get activeTab(): string {
        return this.$store.getters.lastAuth;
    }
    set activeTab(value: string) {
        this.$store.commit('setLastAuth', value);
    }

    @Emit()
    public authorized(): void {
        return;
    }

    public async created() {
        await this.checkAppAuth();
        // Current Tab index has no two way binding with actionTab, so we have to set it manually first.
        this.setCurrentTabIdx();
    }

    public onReviztoInternal(email: string) {
        this.email = email;
        this.activeTab = Tab.login;
    }

    public async checkAppAuth() {
        await this.$nextTick();
        this.appSSO = this.$store.getters.getAppSSO();
        if (this.appSSO.isEmpty) {
            return;
        }
        if (this.$route.query.redirect === '/user/security') {
            this.$store.commit('removeSSOCookies');
        } else if (this.appSSO.merge) {
            this.$router.push({
                name: RouterNames.SelectLicense,
                params: {
                    sso: this.appSSO.jsonString,
                },
            });
        } else {
            this.activeTab = Tab.sso;
            this.isReviztoInternalHidden = true;
        }
    }

    public setCurrentTabIdx() {
        this.currentTabIdx = this.tabsHeader.findIndex((item) => item.tabsName === this.activeTab);
    }

    public returnToLogin() {
        this.isShowTabs = true;
        this.email = '';
    }
}
