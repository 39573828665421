function traverse(obj: any, cb: any) {
    if (!obj) {
        return;
    }
    const keys = Object.keys(obj);
    for (const key of keys) {
        const value = obj[key];
        if (typeof value === 'object') {
            traverse(value, cb);
        } else {
            cb(obj, key, value);
        }
    }
}

export class SentryService {
    public static cleanHttpSensitive(breadcrumb: any) {
        traverse(breadcrumb, (obj: any, key: any, value: any) => {
            if (typeof value === 'string' && SentryService.sensitiveKeys.some((aKey) => value.includes(aKey))) {
                delete obj[key];
            }
        });
    }

    private static sensitiveKeys = [
        'device_id',
        'nonce',
        'signature',
    ];
}
