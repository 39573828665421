import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { IIssueFilter } from '@/types/IIssueFilter';
import { Dict } from '@/types/Dict';
import { THexColor, TUuid } from '@/types/common';
import {
    ChartSize,
    ChartType,
    CustomPeriod,
    CustomPeriodT,
    DateFormatT,
    GroupingType,
    IssuesGrouping,
    NumberBooleanType,
    PeriodType,
    PeriodTypeT,
    PredefinedPeriod,
    PredefinedPeriodT,
    PreviewSize,
    SortByValueTypeList,
    TimeFormatT,
    TimePeriodForTimeLine,
    TimePeriodType,
} from '@/constants';
import { ColorService } from '@/services/ColorService';
import { DashboardDataLine } from './DashboardDataLine';

interface ChartDelivery {
    periodType: PeriodTypeT;
    days: Array<1 | 2 | 3 | 4 | 5 | 6 | 7>;
    time: string; // HH:mm
    dayShift: -1 | 0 | 1;
    subscribers: string[]; // emails
    includeUserComments: NumberBooleanType;
    includeAttachments: NumberBooleanType;
    includeFieldChanges: NumberBooleanType;
    includeMarkupChanges: NumberBooleanType;
    includeCompanyLogo: NumberBooleanType;
    includeDataTables: NumberBooleanType;
    previewSize: PreviewSize;
    timeFormat: TimeFormatT;
    dateFormat: DateFormatT;
}

export interface DashboardChartParams {
    uuid: TUuid;
    title: string;
    type: ChartType;
    size: ChartSize;
    showFullList: NumberBooleanType;
    resultType: number;
    graphData: number;
    groupBy: IssuesGrouping[];
    groupTags: string[];
    groupClashGrids: string[];
    clashGridGroupingType: number;
    tagsGroupingType: GroupingType;
    sort: Dict<string>;
    timePeriodType: TimePeriodType;
    timePeriodDefineValue: PredefinedPeriodT;
    timeLinePeriodDefineValue: TimePeriodForTimeLine;
    timePeriodStartUnit: CustomPeriodT;
    timePeriodStartCount: number;
    timePeriodEndUnit: CustomPeriodT;
    timePeriodEndCount: number;
    lines: DashboardDataLine[];
    timePeriodForTimeLine: TimePeriodForTimeLine;
    alwaysFilters: IIssueFilter[];
    anyFilters: IIssueFilter[];
    predictedPointsCount?: number;
    delivery?: ChartDelivery;
    color?: string;
    colors?: Dict<string>;
    isShared?: boolean;
}

export class DashboardChart {
    public readonly uuid: TUuid;
    public title: string;
    public type: ChartType;
    public size: ChartSize;
    public showFullList: boolean;
    public resultType: number;
    public graphData: number;
    public groupBy: IssuesGrouping[];
    public groupTags: string[];
    public groupClashGrids: string[];
    public clashGridGroupingType: GroupingType;
    public tagsGroupingType: GroupingType;
    public sort: Dict<string>;
    public delivery: ChartDelivery;
    public timePeriodType: TimePeriodType;
    public timePeriodDefineValue: PredefinedPeriodT;
    public timeLinePeriodDefineValue: TimePeriodForTimeLine;
    public timePeriodStartUnit: CustomPeriodT;
    public timePeriodStartCount: number;
    public timePeriodEndUnit: CustomPeriodT;
    public timePeriodEndCount: number;
    public lines: DashboardDataLine[];
    public timePeriodForTimeLine: TimePeriodForTimeLine;
    public color?: string;
    public colors: Dict<string>;
    public predictedPointsCount?: number;
    public isShared: boolean;

    constructor(chart?: DashboardChartParams) {
        this.uuid = chart?.uuid || '';
        this.title = chart?.title || '';
        this.type = chart?.type || ChartType.doughnut;
        this.size = chart?.size ?? ChartSize.full;
        this.showFullList = chart?.showFullList != null ? Boolean(chart.showFullList) : true; // Показывать или нет
                                                                                              // пустые бары, 1 - yes,
                                                                                              // 0 - no
        this.resultType = 1; // пока захардкожено, так как теперь используется одно значение; было: count=1,
                             // percentage=2
        this.graphData = chart?.graphData || 0;

        const groupBy: IssuesGrouping[] = chart?.groupBy || [IssuesGrouping.assignee];
        const indexOfLegacyStatusGroup = groupBy.indexOf(IssuesGrouping.status);
        if (indexOfLegacyStatusGroup !== -1) {
            groupBy.splice(indexOfLegacyStatusGroup, 1, IssuesGrouping.customStatus);
        }

        const indexOfLegacyTypeGroup = groupBy.indexOf(IssuesGrouping.type);
        if (indexOfLegacyTypeGroup !== -1) {
            groupBy.splice(indexOfLegacyTypeGroup, 1, IssuesGrouping.customType);
        }

        this.groupBy = groupBy;
        this.groupTags = chart?.groupTags || [];
        this.groupClashGrids = chart?.groupClashGrids || [];
        this.clashGridGroupingType = chart?.clashGridGroupingType || 1;
        this.tagsGroupingType = chart?.tagsGroupingType || GroupingType.UNION;

        if (chart?.sort) {
            // support sort['status'] case
            if (chart.sort[IssuesGrouping.status]) {
                chart.sort[IssuesGrouping.customStatus] = chart.sort[IssuesGrouping.status];
                delete chart.sort[IssuesGrouping.status];
            }
            this.sort = chart.sort;
        } else {
            this.sort = { [groupBy[0] || IssuesGrouping.assignee]: SortByValueTypeList[0] };
        }

        this.delivery = chart?.delivery || {
            periodType: PeriodType.none,
            days: [1, 2, 3, 4, 5],
            time: '08:00',
            dayShift: 0,
            subscribers: [],
            includeUserComments: 1,
            includeAttachments: 0,
            includeFieldChanges: 0,
            includeMarkupChanges: 0,
            includeCompanyLogo: 0,
            includeDataTables: 0,
            previewSize: PreviewSize.SMALL_IMAGES,
            timeFormat: 1,
            dateFormat: 1,
        };

        this.timePeriodType = chart?.timePeriodType || TimePeriodType.pre_defined;
        const timePeriodDefineValue = chart?.timePeriodDefineValue ?? PredefinedPeriod.previous_week;
        this.timePeriodDefineValue = this.isStatusFlow
            ? timePeriodDefineValue
            : PredefinedPeriod.previous_week;
        this.timeLinePeriodDefineValue = this.isTimeLine || this.isScatter
            ? timePeriodDefineValue
            : TimePeriodForTimeLine.sixMonths;

        this.timePeriodStartUnit = chart?.timePeriodStartUnit ?? CustomPeriod.days;
        this.timePeriodStartCount = chart?.timePeriodStartCount ?? 7;
        this.timePeriodEndUnit = chart?.timePeriodEndUnit ? chart.timePeriodEndUnit : CustomPeriod.today;
        this.timePeriodEndCount = chart?.timePeriodEndCount != null ? chart.timePeriodEndCount : 1;
        this.lines = (chart?.lines || []).map((line: any) => new DashboardDataLine(line));
        this.timePeriodForTimeLine = chart?.timePeriodForTimeLine ?? TimePeriodForTimeLine.eightWeeks;

        // imitate the internal structure of the graph like Timeline for other graphs, i.e. storing information on the
        // filters in the first line, not in the root of the model
        if (!this.isTimeLine && !this.lines.length) {
            this.lines[0] = new DashboardDataLine({
                alwaysFilters: chart?.alwaysFilters,
                anyFilters: chart?.anyFilters,
            });
        }

        this.color = chart?.color;
        this.colors = chart?.colors ?? {};
        this.predictedPointsCount = chart?.predictedPointsCount || 0;

        this.isShared = chart?.isShared || false;
    }

    get isSupportZeroValues() {
        return [ChartType.bar, ChartType.pie, ChartType.doughnut].includes(this.type);
    }

    get showFullListValue() {
        return this.isSupportZeroValues ? Number(this.showFullList) : 0;
    }

    get hasDelivery() {
        return Boolean(this.delivery) && this.delivery.periodType !== PeriodType.none;
    }

    get apiParams() {
        return {
            title: this.title || 'Empty title',
            type: this.type,
            size: this.size,
            showFullList: this.showFullListValue,
            resultType: this.resultType,
            graphData: this.graphData,
            groupBy: this.groupBy,
            groupTags: JSON.stringify(this.groupTags),
            groupClashGrids: JSON.stringify(this.groupClashGrids),
            clashGridGroupingType: this.clashGridGroupingType,
            tagsGroupingType: this.tagsGroupingType,
            sort: this.sort,
            alwaysFiltersDTO: DashboardChart.getValidFiltersParams(this.lines[0].alwaysFiltersDTO),
            anyFiltersDTO: DashboardChart.getValidFiltersParams(this.lines[0].anyFiltersDTO),
            lines: this.linesParams,
            timePeriodType: this.timePeriodType,
            timePeriodDefineValue: this.isStatusFlow
                ? this.timePeriodDefineValue
                : this.timeLinePeriodDefineValue,
            timePeriodStartUnit: this.timePeriodStartUnit,
            timePeriodStartCount: this.timePeriodStartCount,
            timePeriodEndUnit: this.timePeriodEndUnit,
            timePeriodEndCount: this.timePeriodEndCount,
            color: this.color || '',
            predictedPointsCount: this.predictedPointsCount,
        };
    }

    /**
     * Функция подготовки массива линий графика TimeLine для отправки на сервер
     */
    get linesParams() {
        return this.lines.map((line) => ({
            uuid: line.uuid || uuidv4(),
            title: line.title,
            color: line.color,
            type: line.type,
            alwaysFiltersDTO: DashboardChart.getValidFiltersParams(line.alwaysFiltersDTO),
            anyFiltersDTO: DashboardChart.getValidFiltersParams(line.anyFiltersDTO),
        }));
    }

    // максимальное количество группировок в настройках
    get maxGroupingsCount() {
        switch (this.type) {
            case ChartType.pie:
            case ChartType.doughnut:
            case ChartType.scatter:
                return 2;
            case ChartType.bar:
            case ChartType.treeMap:
                return 3;
            default:
                return 0;
        }
    }

    get hasSorting() {
        return !this.isScatter && this.maxGroupingsCount > 0;
    }

    get isBar() {
        return this.type === ChartType.bar;
    }

    get isPie() {
        return this.type === ChartType.pie;
    }

    get isDoughnut() {
        return this.type === ChartType.doughnut;
    }

    get isScatter() {
        return this.type === ChartType.scatter;
    }

    get isTreeMap() {
        return this.type === ChartType.treeMap;
    }

    get isTimeLine() {
        return this.type === ChartType.timeLine;
    }

    get isStatusFlow() {
        return this.type === ChartType.statusFlow;
    }

    private get groupingsForColor() {
        if (this.isTreeMap) {
            return this.groupBy.slice(0, 1);
        }
        if ((this.isBar || this.isScatter) && this.groupBy.length > 1) {
            return this.groupBy.slice(1);
        }
        if (this.isStatusFlow) {
            return [IssuesGrouping.status];
        }
        return this.groupBy;
    }

    /**
     * Функция подготовки массива фильтров для отправки на сервер
     */
    public static getValidFiltersParams(filters: IIssueFilter[]) {
        return filters
            .filter((filter: IIssueFilter) => filter.isValidParams())
            .map((filter: IIssueFilter) => filter.getParams());
    }

    public getChartItemColor(
        values: Dict<string | number | string[]>,
        customStatusColors: Dict<THexColor> = {},
    ): { color: string; colorKey: string } {
        const colorKey = this.groupingsForColor.map((grouping: string) => {
            if (values[grouping]) {
                return values[grouping];
            }

            if (grouping === IssuesGrouping.customStatus && values[IssuesGrouping.status]) {
                // We didn't fix field key for chart data< but fix grouping key from legacy "status" to "customStatus".
                // Here we check if we have "status" key in chart data and use it for colorKey.
                // Legacy keys and colors connect by LegacyIssueStatusToCustomStatusName enum in Color class
                return values[IssuesGrouping.status];
            }

            return '';
        }).join('');

        if (!(_.isEqual(this.groupingsForColor, [IssuesGrouping.status])
            || _.isEqual(this.groupingsForColor, [IssuesGrouping.customStatus]))) {
            customStatusColors = {};
        }

        const normalizedCustomColor = customStatusColors[colorKey] ? ColorService.normalizeColor(customStatusColors[colorKey]) : null;

        const color = this.color ?? this.colors[colorKey] ?? normalizedCustomColor ?? ColorService.getColorForField(colorKey);

        return {
            color,
            colorKey,
        };
    }
}
