import { Dict } from '@/types/Dict';
import { DEFAULT_CUSTOM_STATUS_CLOSED, DEFAULT_CUSTOM_STATUS_IN_PROGRESS, DEFAULT_CUSTOM_STATUS_OPEN } from '@/constants/Workflow';
import { CustomStatusCategory } from '@/constants/CustomStatusCategory';
import { IssueStatusEnum } from '@/constants/issue/IssueStatus';

export const MapOfStatusCategoryToLegacyStatusesName: Dict = {
    [CustomStatusCategory.ToDo]: IssueStatusEnum.open,
    [CustomStatusCategory.Tracking]: IssueStatusEnum.in_progress,
    [CustomStatusCategory.Completed]: IssueStatusEnum.closed,
};

export const MapOfStatusCategoryToLegacyStatuses: Dict = {
    [CustomStatusCategory.ToDo]: DEFAULT_CUSTOM_STATUS_OPEN,
    [CustomStatusCategory.Tracking]: DEFAULT_CUSTOM_STATUS_IN_PROGRESS,
    [CustomStatusCategory.Completed]: DEFAULT_CUSTOM_STATUS_CLOSED,
};
