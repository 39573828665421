import { FileComment } from '@/models';

export class MarkupComment extends FileComment {
    public markup: any;
    public background: string;
    public backgroundType: number;
    public links: any;

    constructor(comment: any = {}) {
        super(comment);
        this.markup = comment.markup || comment.markups;
        this.background = comment.background;
        this.backgroundType = comment.backgroundType;
        this.links = comment.links;
    }
}
