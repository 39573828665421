import Vue from 'vue';
import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { RESPONSE } from '@/constants';
import ProjectApi from '@/api/project.api';
import { IMemberUpdateParams, IMemberUpdateResponse } from '@/api/license.api';
import { ProjectMember } from '@/models';
import { getOperationId, notificationSuccess } from '@/services';

const promise = {
    projectMembers: {} as any,
};

export default {
    state: {
        projectMembersObj: {},
        projectMembersActivityObj: {},
        projectMembersFiltersParamsObj: {},
        isDeletingProjectMembersObj: {},
        isLoadingProjectMembersObj: {},
        isLoadingAnyProjectMembers: false,
        isLoadingProjectMembersActivityObj: {},
        isSendingProjectMembersActivity: false,
        isSendingResendProjectInvitations: false,
        isSendingProjectMemberAccessRole: false,
        isDeletingProjectMembersBulk: false,
    },
    getters: {
        projectMembersByProjectId(state: any): (projectId: number) => ProjectMember[] {
            return (projectId) => state.projectMembersObj[projectId] || [];
        },
        projectMembersByEmailObj(state: any): (projectId: number) => Dict<ProjectMember> {
            return (projectId: number) => _.keyBy(state.projectMembersObj[projectId] || [], 'email');
        },
        projectMemberByEmail(state: any): (email: string, projectId: number) => ProjectMember | undefined {
            return (email: string, projectId: number) => (state.projectMembersObj[projectId] || [])
                    .find((projectMember: ProjectMember) => projectMember.email === email);
        },
        projectRoleUuidsByProjectId(state: any, getters: any): (projectId: number) => string[] {
            return (projectId: number) => getters.projectMembersByProjectId(projectId)
                .map((projectMember: ProjectMember) => projectMember.accessRole.uuid);
        },
        projectMembersFiltersParamsByProjectId(state: any): (projectId: number) => any {
            return (projectId) => state.projectMembersFiltersParamsObj[projectId] || {};
        },
        isMemberInProject(state: any): (projectId: number, userId: number) => boolean {
            return (projectId, userId) => {
                const members: ProjectMember[] = state.projectMembersObj[projectId] || [];
                return members.some(({ id }: ProjectMember) => id === userId);
            };
        },
        isDeletingProjectMembers(state: any): boolean {
            return state.isDeletingProjectMembers;
        },
        isSendingResendProjectInvitations(state: any): boolean {
            return state.isSendingResendProjectInvitations;
        },
        isLoadingProjectMembersByProjectId(state: any): (projectId: string) => boolean {
            return (projectId) => state.isLoadingProjectMembersObj[projectId] || false;
        },
        isLoadingAnyProjectMembers(state: any) {
            return state.isLoadingAnyProjectMembers;
        },
        isSendingProjectMemberAccessRole(state: any): boolean {
            return state.isSendingProjectMemberAccessRole;
        },
        isDeletingProjectMembersBulk(state: any): boolean {
            return state.isDeletingProjectMembersBulk;
        },
    },
    mutations: {
        setProjectMembers(state: any, { projectId, members }: { projectId: number, members: ProjectMember[] }) {
            Vue.set(state.projectMembersObj, projectId, members);
        },
        resetProjectMembers(state: any) {
            state.projectMembersObj = {};
        },
        setProjectMembersFiltersParams(state: any, { projectId, filtersParams }: { projectId: number; filtersParams: any }) {
            Vue.set(state.projectMembersFiltersParamsObj, projectId, filtersParams);
        },
        setIsLoadingProjectMembers(state: any, { projectId, value }: { projectId: string; value: boolean }) {
            Vue.set(state.isLoadingProjectMembersObj, projectId, value);
        },
        setIsLoadingAnyProjectMembers(state: any, value: boolean) {
            state.isLoadingAnyProjectMembers = value;
        },
        setIsDeletingProjectMembers(state: any, value: boolean = false) {
            Vue.set(state, 'isDeletingProjectMembers', value);
        },
        setIsSendingResendProjectInvitations(state: any, value: boolean = false) {
            Vue.set(state, 'isSendingResendProjectInvitations', value);
        },
        setIsSendingProjectMemberAccessRole(state: any, value: boolean = false) {
            Vue.set(state, 'isSendingProjectMemberAccessRole', value);
        },
        setisDeletingProjectMembersBulk(state: any, value: boolean = false) {
            Vue.set(state, 'isDeletingProjectMembersBulk', value);
        },
    },
    actions: {
        loadProjectMembers({ state, commit }: any, { projectId, isForce = false }: { projectId: number, isForce: boolean }): Promise<any> {
            if (promise.projectMembers[projectId]) {
                return promise.projectMembers[projectId];
            }

            if (!isForce && state.projectMembersObj[projectId]) {
                return Promise.resolve(state.projectMembersObj[projectId]);
            }

            promise.projectMembers[projectId] = new Promise((resolve, reject) => {
                commit('setIsLoadingProjectMembers', { projectId, value: true });
                commit('setIsLoadingAnyProjectMembers', true);

                ProjectApi.getProjectMembers(projectId).then((response) => {
                    const members = response.entities.map((member: any) => new ProjectMember(member));
                    commit('setProjectMembers', { projectId, members });
                    resolve(members);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    promise.projectMembers[projectId] = null;
                    commit('setIsLoadingProjectMembers', { projectId, value: false });
                    commit('setIsLoadingAnyProjectMembers', false);
                });
            });

            return promise.projectMembers[projectId];
        },
        sendDeleteProjectMember({ state, commit }: any, { projectId, memberId }: { projectId: number, memberId: number }): Promise<any> {
            commit('setIsDeletingProjectMembers', true);
            const operationId = getOperationId();
            return ProjectApi.postProjectMemberDelete(projectId, memberId, operationId).then(() => {
                notificationSuccess('memberDeleted');
                const members = state.projectMembersObj[projectId].filter((projectMember: ProjectMember) => projectMember.member.id !== memberId);
                commit('setProjectMembers', { projectId, members });
            }).finally(() => {
                commit('setIsDeletingProjectMembers', false);
            });
        },
        sendProjectMemberAccessRole({ commit }: any, { projectId, memberUuids, roleId }: { projectId: number, memberUuids: string[], roleId: number }): Promise<any> {
            commit('setIsSendingProjectMemberAccessRole', true);
            return ProjectApi.postUpdateProjectMembersAccessRole(projectId, memberUuids, roleId)
                .finally(() => commit('setIsSendingProjectMemberAccessRole', false));
        },
        sendInviteProjectMember(
            context: any,
            { projectId, invitations, roleId }: { projectId: number, invitations: string[], roleId: number },
        ): Promise<any> {
            const operationId = getOperationId();
            return ProjectApi.postProjectMemberInvite({ projectId, invitations, roleId, operationId });
        },
        sendDeleteProjectMembers({ commit }: any, { projectId, memberUuids }: { projectId: number, memberUuids: string[] }): Promise<any> {
            commit('setIsDeletingProjectMembers', true);
            const operationId = getOperationId();
            return ProjectApi.postProjectMembersDelete(projectId, memberUuids, operationId).finally(() => {
                commit('setIsDeletingProjectMembers', false);
            }).catch((error) => {
                if (error.result === RESPONSE.INVALID_DATA) {
                    return error;
                }
            });
        },
        sendDeleteProjectMembersBulk(
            { commit }: any,
            { projectIds, memberUuids }: { projectIds: number[], memberUuids: string[] },
        ): Promise<IMemberUpdateResponse> {
            commit('setisDeletingProjectMembersBulk', true);
            const operationId = getOperationId();
                        
            return Promise.all(
                projectIds.map((projectId) => ProjectApi.postProjectMembersDelete(projectId, memberUuids, operationId)),
            ).finally(() => {
                commit('setisDeletingProjectMembersBulk', false);
            }).catch((error) => {
                return error;
            });
        },
        sendResendProjectInvitations({ commit }: any, { projectId, emails }: { projectId: number, emails: string[] }): Promise<any> {
            commit('setIsSendingResendProjectInvitations', true);
            return ProjectApi.postResendProjectInvitations(projectId, { emails }).finally(() => {
                commit('setIsSendingResendProjectInvitations', false);
            });
        },
        groupUpdateProjectMemberAdditionalFields(
            { commit }: any,
            { projectUuid, params }: { projectUuid: string, params: IMemberUpdateParams },
        ): Promise<any> {
            commit('setIsSendingAdditionalFields', true);
            return ProjectApi.postGroupUpdateAdditionalFields(projectUuid, params).finally(() => {
                commit('setIsSendingAdditionalFields', false);
            });
        },
    },
};
