







import { Component, Prop, Vue } from 'vue-property-decorator';
import { IssuePriorityEnum } from '@/constants';
import IssuePriorityIcon from '@/components/project/issueTracker/IssuePriorityIcon.vue';

@Component({
    components: {
        IssuePriorityIcon,
    },
})
export default class IssuePriorityItem extends Vue {
    @Prop({ required: true }) public value!: IssuePriorityEnum;
}
