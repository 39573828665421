



















































import _ from 'lodash';
import { Component } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { ICantApplyMultiEditModalParams, IWarningNotFullyAppliedParams } from '@/types/issue-tracker';
import { DEFAULT_ISSUE_TYPE_UUID } from '@/constants/issue/customIssueType';
import {
    MapOfStatusCategoryToLegacyStatuses,
} from '@/constants/MapOfStatusCategoryToLegacyStatuses';
import {
    AmplitudeEvent,
    BusEvent,
    Color,
    IssueDetailsInputType,
    IssueStatusEnum,
    IssueTrackerFields,
    ReasonsWhyCantApplyMultiEdit,
} from '@/constants';
import { CustomStatus, Issue, License, Project, Workflow } from '@/models';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, isFiltersForDeletedIssues, openInAppLink } from '@/services';
import IssueColumn from '@/components/project/issueTracker/IssueColumn.vue';
import IssueDetails from '@/components/project/issueTracker/IssueDetails.vue';
import TrackerColumnComponentBase from '@/components/project/issueTracker/columns/TrackerColumnComponentBase.vue';
import WsDragContent from '@/components/common/dragZone/WsDragContent.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    name: 'TrackerInfo',
    components: {
        IssueColumn,
        IssueDetails,
        WsDragContent,
        WsButton,
    },
})
export default class TrackerInfo extends TrackerColumnComponentBase {
    public readonly Color = Color;
    public readonly IssueStatusEnum = IssueStatusEnum;

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get projectId() {
        return Number(this.$route.params.projectId);
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get currentUserEmail(): string {
        return this.$store.getters.userData.email;
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get isIssueDeleted() {
        return Boolean(this.currentIssue?.isDeleted);
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get isShowOpenInAppButton() {
        return Boolean(this.currentIssue)
            && this.currentIssue?.status !== IssueStatusEnum.deleted
            && !this.collapsed;
    }

    get isMultiEditLoading(): boolean {
        return this.$store.getters.isMultiEditLoading;
    }

    get allIssues(): Issue[] {
        return this.$store.getters.issuesByProjectId(this.projectId);
    }

    get deletedIssues(): Issue[] {
        return this.$store.getters.deletedIssuesByProjectId(this.projectId);
    }

    get trackerFilters(): TrackerFilters {
        return this.$store.getters.trackerFiltersByProjectId(this.projectId);
    }

    get issues(): Issue[] {
        return isFiltersForDeletedIssues(this.trackerFilters) ? this.deletedIssues : this.allIssues;
    }

    public openInApp() {
        open(openInAppLink(this.currentProject, this.projectId, this.currentIssue.id), '_blank');

        amplitudeLog(AmplitudeEvent.itOpenInApp);
    }

    public disableMultiSelectEditMode() {
        this.$store.dispatch('setMultiSelectEditMode', false);
        eventBus.$emit('cancelIssueEdit');
    }

    public async tryApplyMultiEditValues() {
        if (this.checkIfSelectedStatusAndTypeNotCompatible()) {
            this.showWarningCantApplyStatusAndType();
            return;
        }

        if (this.checkIfNeedToReplaceStatuses()) {
            this.showDialogReplaceStatuses();
            return;
        }

        let issuesHasConflictWithSelectedStatus: Issue[] = this.$store.getters
            .issuesHasConflictWithSelectedStatus(this.currentProject.uuid);

        if (!this.license.isWorkflowEnabled && issuesHasConflictWithSelectedStatus.length) {
            const replaceMaps = issuesHasConflictWithSelectedStatus.reduce((maps, issue) => {
                const customStatus: CustomStatus = this.$store.getters.customIssueStatusByUuid(
                    this.currentProject.uuid,
                    issue.customStatus,
                );

                if (!maps[issue.customType]) {
                    maps[issue.customType] = {};
                }

                maps[issue.customType][issue.customStatus] = MapOfStatusCategoryToLegacyStatuses[customStatus.category];

                return maps;
            }, {} as Dict<Dict<string>>);

            this.$store.commit('saveMultiEditStatusReplaceMaps', replaceMaps);
            this.$store.commit('addMultiEditValue', {
                currentUserEmail: this.currentUserEmail,
                row: IssueTrackerFields.customType,
                value: DEFAULT_ISSUE_TYPE_UUID,
                type: IssueDetailsInputType.select,
            });

            issuesHasConflictWithSelectedStatus = [];
        }

        const newValuesPermissionsErrors: Dict<Issue[]> = this.$store.getters
            .getNewValuesPermissionsErrors(this.currentUserEmail, this.currentProject.permissions);

        const issuesHasNotAllowedToChangeStatusCategoryDone = this.$store.getters
            .issuesHasNotAllowedToChangeStatusCategoryDone(this.currentProject.uuid, this.currentProject.id);

        const issuesWithNotAllowedForEditFields = Object.values(newValuesPermissionsErrors)
            .reduce(
                (acc: Issue[], issues: Issue[]) => {
                    acc = acc.concat(issues);
                    return acc;
                },
                [] as Issue[],
            );

        const issuesHasPermissionsErrors = _.uniqBy([
            ...issuesWithNotAllowedForEditFields,
            ...issuesHasNotAllowedToChangeStatusCategoryDone,
        ], 'id');

        const navisIssuesTryingChangeType = this.$store.getters.getNavisIssuesTryingChangeType();

        const allIssueWithProblems = issuesHasConflictWithSelectedStatus
            .concat(issuesHasPermissionsErrors)
            .concat(navisIssuesTryingChangeType);

        const uniqIssuesWithProblems = _.uniqBy(allIssueWithProblems, 'uuid');
        if (uniqIssuesWithProblems.length) {
            this.showDialogWarningNotFullyApplied(uniqIssuesWithProblems);
            return;
        }

        this.applyMultiEditValues();
    }

    public async applyMultiEditValues(exceptIssues: Issue[] = []) {
        exceptIssues.forEach((issue) => {
            this.$store.commit('removeIssueFromMultiSelect', issue);
        });

        await this.$store.dispatch('applyMultiEditValues', this.projectId);
        await this.$store.dispatch('loadProjectFieldVariants', { projectId: this.projectId, isForce: true });
        this.$store.commit('setSelectedIssue', { projectId: this.projectId, issue: this.issues[0] });
    }

    private checkIfSelectedStatusAndTypeNotCompatible() {
        const selectedTypeUuid = this.$store.getters.multiEditNewValues[IssueTrackerFields.customType]?.value;
        const selectedStatusUuid = this.$store.getters.multiEditNewValues[IssueTrackerFields.customStatus]?.value;

        if (!selectedTypeUuid || !selectedStatusUuid) {
            return false;
        }

        const workflowByType: Workflow = this.$store.getters.workflowByCustomTypeUuid(this.currentProject.uuid, selectedTypeUuid);

        if (!workflowByType) {
            return false;
        }

        return !workflowByType.statuses.some((status) => status.uuid === selectedStatusUuid);
    }

    private showWarningCantApplyStatusAndType() {
        const selectedTypeUuid = this.$store.getters.multiEditNewValues[IssueTrackerFields.customType].value;
        const selectedStatusUuid = this.$store.getters.multiEditNewValues[IssueTrackerFields.customStatus].value;

        eventBus.$emit(BusEvent.cantApplyMultiEdit, {
            reason: ReasonsWhyCantApplyMultiEdit.statusAndTypeNotCompatible,
            translationArguments: {
                typeName: this.$store.getters.customIssueTypeByUuid(this.currentProject.uuid, selectedTypeUuid)?.name,
                statusName: this.$store.getters.customIssueStatusByUuid(this.currentProject.uuid, selectedStatusUuid)?.name,
            },
            buttons: [{
                text: this.$t('IssueTracker.cantApplyMultiEdit.statusAndTypeNotCompatible.applyWithout'),
                action: () => {
                    this.$store.commit('removeMultiEditValue', IssueTrackerFields.customStatus);
                    this.tryApplyMultiEditValues();
                },
            }],
        } as ICantApplyMultiEditModalParams);
    }

    private checkIfNeedToReplaceStatuses() {
        const selectedTypeUuid = this.$store.getters.multiEditNewValues[IssueTrackerFields.customType]?.value;
        const selectedStatusUuid = this.$store.getters.multiEditNewValues[IssueTrackerFields.customStatus]?.value;

        if (!selectedTypeUuid || selectedStatusUuid) {
            return false;
        }

        const workflowByType: Workflow = this.$store.getters.workflowByCustomTypeUuid(this.currentProject.uuid, selectedTypeUuid);

        if (!workflowByType) {
            return false;
        }

        const conflictIssues = this.$store.getters.getMapOfIssuesNeedingStatusChangeByTypes(this.currentProject.uuid)?.issuesCount;

        return conflictIssues > 0;
    }

    private showDialogReplaceStatuses() {
        eventBus.$emit(BusEvent.showDialogReplaceStatuses, () => {

            this.tryApplyMultiEditValues();
        });
    }

    private showDialogWarningNotFullyApplied(issues: Issue[]) {
        eventBus.$emit(BusEvent.showDialogWarningNotFullyApplied, {
            callback: () => {
                this.applyMultiEditValues(issues);
            },
            allCount: this.$store.getters.multiSelectedIssues.length,
            issuesWithProblems: issues.length,
        } as IWarningNotFullyAppliedParams);
    }
}
