enum NotFoundEntityEnum {
    dashboard = 'dashboard',
}

export class NotFoundError extends Error {
    private constructor(message: NotFoundEntityEnum) {
        super(message);
    }

    public get isDashboard() {
        return this.message === NotFoundEntityEnum.dashboard;
    }

    public static dashboard() {
        return new NotFoundError(NotFoundEntityEnum.dashboard);
    }
}
