import _ from 'lodash';
import VueI18n from 'vue-i18n';
import { ActionLogTypeEnum } from '@/constants';
import { ActionLog } from '@/models/ActionLog';
import { IssueFilterPreset } from '@/models/IssueFilterPreset';
import { LicenseDeletedMember } from '@/models/license/LicenseDeletedMember';
import { LicenseMember } from '@/models/license/LicenseMember';
import { MemberForApproval, MemberForApprovalEnhanced } from '@/models/MemberForApproval';
import { Project } from '@/models/Project';
import { ProjectMember } from '@/models/ProjectMember';
import { User } from '@/models/User';
import { truncateString } from '@/services/StringMethods';
import { i18n } from '@/services/i18n';

export type NameFilterParams =
    LicenseMember
    | ProjectMember
    | LicenseDeletedMember
    | ActionLog
    | Project
    | MemberForApproval
    | MemberForApprovalEnhanced
    | User
    | IssueFilterPreset
    | string
    | undefined;

export function nameFilter(value: NameFilterParams): VueI18n.TranslateResult {
    if (value instanceof ActionLog) {
        return nameFilter(value.member);
    }
    if (value instanceof MemberForApproval || value instanceof MemberForApprovalEnhanced) {
        return value.email;
    }
    if (value instanceof LicenseMember) {
        return value.user.fullname ? `${value.user.fullname} (${value.user.email})` : value.user.email;
    }
    if (value instanceof ProjectMember || value instanceof User) {
        return value.fullname ? `${value.fullname} (${value.email})` : value.email;
    }
    if (value instanceof LicenseDeletedMember) {
        return value.name === '?.' ? i18n.t('Logs.deletedMember') : value.name;
    }
    if (value instanceof Project) {
        return value.title;
    }
    if (value instanceof IssueFilterPreset) {
        return value.title;
    }
    if (typeof value === 'string') {
        return value;
    }
    return i18n.t('Text.undefinedName');
}

export function truncateName(
    value: NameFilterParams,
    maxLen = 40, // максимальная допустимая длина строки
    independent = false, // резать каждое слово по отдельности и выдавать их дополнительно (для LicenseMember и ProjectMember)
): {
    full: VueI18n.TranslateResult, // стандартный вариант из nameFilter
    truncated?: VueI18n.TranslateResult, // обрезанная строка, если было превышение длины maxLen
    firstName?: VueI18n.TranslateResult, // если independent = true и это мембер или юзер
    lastName?: VueI18n.TranslateResult, // если independent = true и это мембер или юзер
    email?: VueI18n.TranslateResult, // если independent = true и это мембер или юзер
} {
    if (value instanceof ActionLog) {
        return truncateName(value.member, maxLen, independent);
    }
    const truncate = (str: VueI18n.TranslateResult) => truncateString(String(str), maxLen);
    const full = nameFilter(value);
    const truncated = truncate(full);
    const isNeedTruncate = maxLen < full.length;
    if (value instanceof LicenseMember) {
        if (independent) {
            const firstName = truncate(value.user.firstname);
            const lastName = truncate(value.user.lastname);
            const email = truncate(value.user.email);
            const isAtLeastOneNeedTruncate = value.user.firstname.length > maxLen
                || value.user.lastname.length > maxLen
                || value.user.email.length > maxLen;
            if (isAtLeastOneNeedTruncate) {
                return {
                    full,
                    truncated: `${firstName} ${lastName} (${email})`,
                    firstName,
                    lastName,
                    email,
                };
            }
            return {
                full,
                firstName,
                lastName,
                email,
            };
        }
    }
    if (value instanceof ProjectMember || value instanceof User) {
        if (independent) {
            const firstName = truncate(value.firstname);
            const lastName = truncate(value.lastname);
            const email = truncate(value.email);
            const isAtLeastOneNeedTruncate = value.firstname.length > maxLen
                || value.lastname.length > maxLen
                || value.email.length > maxLen;
            if (isAtLeastOneNeedTruncate) {
                return {
                    full,
                    truncated: `${firstName} ${lastName} (${email})`,
                    firstName,
                    lastName,
                    email,
                };
            }
            return {
                full,
                firstName,
                lastName,
                email,
            };
        }
    }
    if (isNeedTruncate) {
        return {
            full,
            truncated,
        };
    }
    return {
        full,
    };
}

export function filterLogsByEvents(logs: ActionLog[], events: ActionLogTypeEnum[]) {
    return logs.filter((log) =>  _.intersection(events, log.actionTypes).length);
}
