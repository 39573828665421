import _ from 'lodash';
import Vue from 'vue';
import LicenseApi from '@/api/license.api';
import { SentEmail } from '@/models';

export default {
    state: {
        sentEmailsObj: {},
        isLoadingSentEmailsObj: {},
        isAllSentEmailsLoadedObj: {},
    },
    getters: {
        sentEmailsAtLicenseUuid(state: any): (licenseUuid: string) => SentEmail[] {
            return (licenseUuid) => state.sentEmailsObj[licenseUuid] || [];
        },
        isLoadingSentEmailsAtLicenseUuid(state: any): (licenseUuid: string) => boolean {
            return (licenseUuid) => state.isLoadingSentEmailsObj[licenseUuid] || false;
        },
        isAllSentEmailsLoadedUuid(state: any): (licenseUuid: string) => boolean {
            return (licenseUuid) => state.isAllSentEmailsLoadedObj[licenseUuid] || false;
        },
    },
    mutations: {
        setSentEmails(state: any, { licenseUuid, sentEmails }: { licenseUuid: string, sentEmails: SentEmail[] }) {
            Vue.set(state.sentEmailsObj, licenseUuid, sentEmails);
        },
        setIsLoadingSentEmails(state: any, { licenseUuid, value }: { licenseUuid: string, value: boolean }) {
            Vue.set(state.isLoadingSentEmailsObj, licenseUuid, value);
        },
        setIsAllSentEmailsLoaded(state: any, { licenseUuid, value }: { licenseUuid: string, value: boolean }) {
            Vue.set(state.isAllSentEmailsLoadedObj, licenseUuid, value);
        },
    },
    actions: {
        loadSentEmailsAtLicenseByUuid({ state, commit, getters, dispatch }: any, { licenseUuid, isForce = false, page = 0 }: { licenseUuid: string, isForce: boolean, page: number }): Promise<any> {
            return new Promise((resolve, reject) => {
                if (!isForce && state.sentEmailsObj[licenseUuid]) {
                    resolve(state.sentEmailsObj[licenseUuid]);
                    return;
                }
                if (!isForce && state.isLoadingSentEmailsObj[licenseUuid]) {
                    return;
                }

                commit('setIsLoadingSentEmails', { licenseUuid, value: true });
                LicenseApi.getSentEmailByLicenseUuid(licenseUuid, page).then((response) => {
                    const sentEmails = _.map(response, (sentEmail) => new SentEmail(sentEmail));
                    let uploadedSentEmails = getters.sentEmailsAtLicenseUuid(licenseUuid);
                    uploadedSentEmails = _.differenceBy(uploadedSentEmails, sentEmails, 'uuid');
                    uploadedSentEmails = _.concat(uploadedSentEmails, sentEmails);
                    commit('setSentEmails', { licenseUuid, sentEmails: uploadedSentEmails });
                    if (sentEmails.length > 0) {
                        dispatch('loadSentEmailsAtLicenseByUuid', { licenseUuid, isForce, page: (page + 1) });
                    } else {
                        commit('setIsAllSentEmailsLoaded', { licenseUuid, value: true });
                    }
                    resolve(uploadedSentEmails);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingSentEmails', { licenseUuid, value: false });
                });
            });
        },
    },
};
