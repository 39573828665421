import _ from 'lodash';

export class ColorizeHtml {
    public query = '';
    public node = '';

    constructor(query: string, node: string) {
        this.query = query;
        this.node = node;
    }

    public queryReg() {
        const words: string[] = this.query.split(' ').filter(Boolean).filter((string: string) => string.length >= 3);
        return RegExp(words.map(_.escapeRegExp).join('|'), 'ig');
    }

    public getNode(additionalSelector: string = '') {
        return document.querySelectorAll(`${this.node}` + additionalSelector) as any;
    }

     public clean() {
        const chatElements = this.getNode(' .highlighted');
        if (chatElements) {
            chatElements.forEach((element: any) => {
                if (element.parentElement) {
                    element.parentElement.innerHTML = element.parentElement.innerText;
                }
            });
        }
    }

    public highlight() {
        if (this.query.length >= 3) {
            const queryReg = this.queryReg();
            const chatElements = this.getNode();
            chatElements.forEach((element: any) => {
                element.innerHTML = element.innerText.replace(
                    queryReg,
                    (str: string) => `<span class="highlighted">${str}</span>`,
                );
            });
        }
    }
}
