


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { StampColorsPaletteRGBEnum, StampColorsPaletteRGBText } from '@/constants';

@Component
export default class WsSelectColorItem extends Vue {
    @Prop({ required: true }) public color!: any;
    @Prop({ type: String, default: '' }) public colorTextKey!: string;
    @Prop({ default: 'default' }) public size!: 'default' | 'mini';
    // default - 38px, mini - 16px

    @Prop({ default: false, type: Boolean }) public rgb!: boolean;
    @Prop({ default: false, type: Boolean }) public selection!: boolean;
    @Prop({ default: false, type: Boolean }) public inline!: boolean;
    @Prop({ default: false, type: Boolean }) public dense!: boolean;
    @Prop({ default: false, type: Boolean }) public colorOnly!: boolean;

    public StampColorsPaletteRGBText = StampColorsPaletteRGBText;
    public StampColorsPaletteRGBEnum = StampColorsPaletteRGBEnum;

    get localColor() {
        if (this.rgb && this.color) {
            const colors = this.color?.split(',');
            return `rgb(${colors?.[0]},${colors?.[1]},${colors?.[2]})`;
        } else {
            return this.color;
        }
    }

    get colorTranslation() {
        if (this.colorTextKey) {
            return this.$t(`Colors.${this.colorTextKey}`);
        }

        const colorIndex = Number(StampColorsPaletteRGBEnum[this.color]);

        if (isNaN(colorIndex)) {
            return '';
        }

        return this.$t(`Colors.${StampColorsPaletteRGBText[colorIndex]}`);
    }
}
