import { TranslateResult } from 'vue-i18n';
import { ActionLog, IssueFilterPreset, LicenseDeletedMember, LicenseMember, MemberForApproval, MemberForApprovalEnhanced, Project, ProjectMember, User } from '@/models';
import { i18n, NameFilterParams } from '@/services';

export function getItemName(value: NameFilterParams): TranslateResult {
    if (value instanceof ActionLog) {
        return getItemName(value.member);
    }
    if (value instanceof MemberForApproval || value instanceof MemberForApprovalEnhanced) {
        return value.email;
    }
    if (value instanceof LicenseMember) {
        return value.user.fullname ? `${value.user.fullname}` : value.user.email;
    }
    if (value instanceof ProjectMember || value instanceof User) {
        return value.fullname ? `${value.fullname}` : value.email;
    }
    if (value instanceof LicenseDeletedMember) {
        return value.name === '?.' ? i18n.t('Logs.deletedMember') : value.name;
    }
    if (value instanceof Project) {
        return value.title;
    }
    if (value instanceof IssueFilterPreset) {
        return value.title;
    }
    if (typeof value === 'string') {
        return value;
    }
    return i18n.t('Text.undefinedName');
}

export function getItemEmail(value: NameFilterParams): TranslateResult {
    if (value instanceof ActionLog) {
        return '';
    }
    if (value instanceof MemberForApproval || value instanceof MemberForApprovalEnhanced) {
        return value.email;
    }
    if (value instanceof LicenseMember) {
        return value.user.email;
    }
    if (value instanceof ProjectMember || value instanceof User) {
        return value.email;
    }
    if (value instanceof LicenseDeletedMember) {
        return '';
    }
    if (value instanceof Project) {
        return '';
    }
    if (value instanceof IssueFilterPreset) {
        return '';
    }
    if (typeof value === 'string') {
        return value;
    }
    return '';

}