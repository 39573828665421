

























































import { Component, Prop } from 'vue-property-decorator';
import { DefaultStringMaxLength } from '@/constants';
import { Issue } from '@/models';
import { sanitizeHtml } from '@/services';
import { isFieldCollapsable } from '@/services/Common';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsInput from '@/components/common/WsInput.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
        IconSvg16,
        WsButton,
        WsInput,
    },
    methods: {
        sanitizeHtml,
    },
})
export default class IssueDetailsTitle extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    public readonly DefaultStringMaxLength = DefaultStringMaxLength;
    public collapsable = false;

    public inlineEdit = false;
    public localValue = '';

    get currentValue() {
        if (this.multiEditNewValues) {
            return this.multiEditNewValues.value;
        }

        return this.showFieldMultipleText
            ? ''
            : this.currentIssue[this.valueKey as keyof Issue];
    }

    set currentValue(value: string) {
        this.localValue = value;
    }

    get showFieldMultipleTextLocal() {
        return this.inlineEdit ? false : this.showFieldMultipleText;
    }

    public created() {
        this.setCollapsable();
    }

    public updated() {
        this.setCollapsable();
    }

    public setCollapsable() {
        if (isFieldCollapsable(this.currentValue)) {
            this.collapsable = !this.inlineEdit;
        } else {
            this.collapsable = false;
        }
    }

    public handleSave() {
        if (this.localValue && this.localValue !== this.currentValue) {
            this.change(this.localValue);
        }
        this.stopEdit();
    }

    public startEdit() {
        this.collapsable = false;
        this.inlineEdit = true;
    }

    public stopEdit() {
        this.inlineEdit = false;
        this.setCollapsable();
    }
}
