export enum StampColorsPaletteHexEnum {
    '#e31129' = 1,
    '#fa811c',
    '#fdde32',
    '#6ddc33',
    '#179427',
    '#4ef2f2',
    '#1d00e5',
    '#8a17ff',
    '#ef0fff',
    '#000000',
}
