import { LanguageKey } from './LanguageKey';

export const Languages = [
    {
        key: LanguageKey.en,
        value: 'English',
    },
    {
        key: LanguageKey.es,
        value: 'Español',
    },
    {
        key: LanguageKey.de,
        value: 'Deutsch',
    },
    {
        key: LanguageKey.fr,
        value: 'Français',
    },
    {
        key: LanguageKey.ru,
        value: 'Русский',
    },
    {
        key: LanguageKey.cn,
        value: '中文',
    },
    {
        key: LanguageKey.pt,
        value: 'Português',
    },
    {
        key: LanguageKey.jp,
        value: '日本語',
    },
];
