export interface ILicenseDeletedMemberProp {
    deletedAt: number;
    id: number;
    licenseId: number;
    licenseUuid: string;
    name: string;
    role: number;
    teamMemberId: number;
    teamMemberUuid: string;
    userId: number;
    userUuid: string;
}

export class LicenseDeletedMember {
    public readonly invitedAt: number;
    public readonly deletedAt: number;
    public readonly id: number;
    public readonly licenseId: number;
    public readonly licenseUuid: string;
    public readonly name: string;
    public readonly role: number;
    public readonly teamMemberId: number;
    public readonly teamMemberUuid: string;
    public readonly userId: number;
    public readonly userUuid: string;

    public constructor(data: any) {
        this.id = data.id;
        this.deletedAt = data.deletedAt;
        this.invitedAt = data.invitedAt;
        this.licenseId = data.licenseId;
        this.role = data.role;
        this.licenseUuid = data.licenseUuid;
        this.name = data.name.trim();
        this.teamMemberId = data.teamMemberId;
        this.teamMemberUuid = data.teamMemberUuid;
        this.userId = data.userId;
        this.userUuid = data.userUuid;
    }
}
