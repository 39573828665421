
import _ from 'lodash';
import { TUuid } from '@/types/common';
import { MemberRoleEnum } from '@/constants';
import { IInviteMemberFormData, IMemberUpdateParams, IProjectRolePair } from '@/api/license.api';
import { Project } from './Project';
import { LicenseMember } from './license/LicenseMember';
import { AccessRole, AccessRoleSystemEnum } from './AccessRole';

// It is 0 because all fields by default are empty strings, but default role is 0.
const emptyCompareString = '0';
export class LicenseMemberSingleForm {
    public email: string;
    public role: MemberRoleEnum;
    public authMethodUuid: string;
    public tags: string[];
    public tagsToAdd: string[];
    public tagsToRemove: string[];
    public firstName: string;
    public lastName: string;
    public company: string;
    public department: string;
    public location: string;
    public invitationsToProjects: IProjectRolePair[];
    public updateProjectRoles: IProjectRolePair[];
    public removeFromProjects: TUuid[]; // uuid array
    public roleToAdd: AccessRole | null; // role selected in role dropdown to add to projects.
    public projectsToAdd: Project[]; // projects selected in select, without selected role.
    public projects: Project[]; // projects with checked role, added to form, and ready to be sent.

    public memberProjects: Project[]; // member license projects to see what updated (changed only once, by load projects)
    public memberToCopy: LicenseMember | null; // Member chosen in import user details dropdown or whe click on create new with same details
    public licenseMember: LicenseMember; // member data to see what was updated.

    constructor(licenseMember: LicenseMember = {} as LicenseMember) {
        // field from the form
        this.email = licenseMember.email || '';
        this.role = licenseMember.role || MemberRoleEnum.none;
        this.authMethodUuid = licenseMember.authMethodUuid || '';
        this.tags = _.cloneDeep(licenseMember.metaTags) || [];
        this.firstName = licenseMember.firstname || '';
        this.lastName = licenseMember.lastname || '';
        this.company = licenseMember.company || '';
        this.department = licenseMember.department || '';
        this.location = licenseMember.location || '';
        
        // service fields
        this.licenseMember = licenseMember;
        this.memberToCopy = null;
        this.roleToAdd = null;
        this.projectsToAdd = [];
        this.invitationsToProjects = [];
        this.updateProjectRoles = [];
        this.removeFromProjects = [];
        this.tagsToAdd = [];
        this.tagsToRemove = [];
        this.memberProjects = [];
        this.projects = [];
    }

    get stringToCompare(): string {
        const projectsString = [...this.invitationsToProjects, ...this.updateProjectRoles, ...this.updateProjectRoles, ...this.removeFromProjects].join('');
        const tagsString = this.tags.join('');
        return `${this.email}${this.firstName}${this.lastName}${this.role}${this.authMethodUuid}${tagsString}${this.company}${this.department}${this.location}${projectsString}`;
    }

    get isSuperAdmin() {
        return this.licenseMember.role === MemberRoleEnum.superAdmin;
    }

    get isFormNotEmpty() {
        return this.stringToCompare !== emptyCompareString;
    }

    get hasProjectWithNoRoles() {
        return this.projects.some(({ accessRole }) => !accessRole?.id);
    }

    get hasProjectsToAddWithNoRole() {
        return Boolean(this.projectsToAdd.length) && !this.roleToAdd;
    }

    get projectsHasSystemRole() {
        // Remove Ownered projects from list and look for another system roles (admin, superadmin)
        return this.projects.filter((project: Project) => project.accessRole.system !== AccessRoleSystemEnum.OWNER).some((project: Project) => project.accessRole.system !== 0);
    }

    public copyDataFromOtherMember(newMember: LicenseMember, isMe: boolean) {
        // these fields copy for every member.
        this.tags = newMember.metaTags || [];

        if (this.isSuperAdmin) {
            return;
        }

        // these fields are copyied for every member except superAdmin
        this.company = newMember.company || '';
        this.department = newMember.department || '';
        this.location = newMember.location || '';

        if (isMe) {
            return;
        }

        // role and authMethod copies for every member except superAdmin and user who copy
        if (newMember.role === MemberRoleEnum.superAdmin) {
            this.role = MemberRoleEnum.admin;
        } else {
            this.role = newMember.role || MemberRoleEnum.collaborator;
        }
        this.authMethodUuid = newMember.authMethodUuid || '';
       
    }

    public setProjectsToAdd(projects: Project[]) {
        this.projectsToAdd = projects;
    }

    public resetRole() {
        this.role = this.licenseMember.role;
    }

    public setMemberProjects(projects: Project[]) {
        this.memberProjects = projects;
        this.projects = projects;
    }

    public setProjects(projects: Project[]) {
        this.projects = projects;
    }

    // Form lists of remove, update and invitation projects
    public projectsUpdate(projects: Project[]) {
        this.clearProjectsData();
        this.projects = projects;

        this.memberProjects.forEach((project) => {
            const projectFromForm = projects.find(({ uuid }) => uuid === project.uuid);
            if (!projectFromForm) {
                this.removeFromProjects.push(project.uuid);
            } else if (!_.isEqualWith(projectFromForm, project, (a, b) => a.accessRole.uuid === b.accessRole.uuid)) {
                // We cannot change project roles for License admin.
                if (this.role !== MemberRoleEnum.admin) {
                    this.updateProjectRoles.push(this.toProjectRolePair(projectFromForm));
                }
            }
        });

        projects.forEach((project) => {
            if (!this.isMemberInProject(project)) {
                this.invitationsToProjects.push(this.toProjectRolePair(project));
            }
        });
    }

    // Form list of add and remove tags
    public updateTags(tags: string[]) {
        // Order of arrays in _.difference function is crucial and should be different for adds and removes.
        this.tagsToAdd = _.difference(tags, this.licenseMember.metaTags);
        this.tagsToRemove = _.difference(this.licenseMember.metaTags, tags);
    }

    public isMemberInProject(project: Project) {
        return Boolean(this.memberProjects.find(({ uuid }) => uuid === project.uuid));
    }

    public clearProjectsData() {
        this.updateProjectRoles = [];
        this.invitationsToProjects = [];
        this.removeFromProjects = [];
    }

    public clearNames() {
        this.email = '';
        this.firstName = '';
        this.lastName = '';
    }

    public clearProjectsToAdd() {
        this.projectsToAdd = [];
        this.roleToAdd = null;
    }

    public clearAuthMethod() {
        this.authMethodUuid = '';
    }

    public fillEmptyRolesWithPlaceholderRole(placeholderRole: AccessRole) {
        const filledProjects = this.projects.map((project) => {
            if (!project.accessRole.uuid) {
                return new Project({ ...project, accessRole: placeholderRole });
            } else {
                return project;
            }
        });

        this.projectsUpdate(filledProjects);
    }

    public toProjectRolePair(project: Project): IProjectRolePair {
        return { projectUuid: project.uuid, roleUuid: project.accessRole.uuid };
    }

    public prepareDataToUpdate(): IMemberUpdateParams {
        const { uuid } = this.licenseMember;
        const {
            tagsToAdd,
            tagsToRemove,
            authMethodUuid,
            invitationsToProjects,
            updateProjectRoles,
            removeFromProjects,
            role,
            company,
            department,
            location,
        } = this;
        return {
            data: [{
                ...(uuid ? { uuid } : undefined),
                ...(authMethodUuid !== this.licenseMember.authMethodUuid ? { authMethodUuid } : undefined),
                ...(tagsToAdd.length ? { tagsToAdd } : undefined),
                ...(tagsToRemove.length ? { tagsToRemove } : undefined),
                ...(invitationsToProjects.length ? { invitationsToProjects } : undefined),
                ...(updateProjectRoles.length ? { updateProjectRoles } : undefined),
                ...(removeFromProjects.length ? { removeFromProjects } : undefined),
                ...(role && this.licenseMember.role !== role ? { role } : undefined),
                ...(this.licenseMember.company !== company ? { company } : undefined),
                ...(this.licenseMember.department !== department ? { department } : undefined),
                ...(this.licenseMember.location !== location ? { location } : undefined),
            }],
        };
    }

    public prepareDataToInvite(): IInviteMemberFormData[] {
        const {
            email,
            firstName,
            lastName,
            tags,
            authMethodUuid,
            invitationsToProjects,
            role,
            company,
            department,
            location,
        } = this;

        return [{
            email,
            authMethod: authMethodUuid,
            role,
            ...(firstName.length ? { firstName } : undefined),
            ...(lastName.length ? { lastName } : undefined),
            ...(tags.length ? { tags } : undefined),
            ...(invitationsToProjects.length ? { invitationsToProjects } : undefined),
            ...(company && this.licenseMember.company !== company ? { company } : undefined),
            ...(department && this.licenseMember.department !== department ? { department } : undefined),
            ...(location && this.licenseMember.location !== location ? { location } : undefined),
        }];
    }

}
