








import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomStatus } from '@/models';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
    },
})
export default class CustomStatusItem extends Vue {
    @Prop({ type: Object, required: true }) public status!: CustomStatus;

}
