import Vue from 'vue';

const LOCAL_STORAGE_KEY = 'tableSort';

export interface ITableSort {
    by?: string | undefined;
    desc?: boolean | undefined;
}
interface ITableSortObj {
    [tableType: string]: ITableSort;
}

interface ITableSortStorage {
    tableSort: { [licenseId: number]: ITableSortObj; };
}

interface ITableSortSavePayload {
    licenseId: number;
    tableType: string;
    tableSort: ITableSort;
}

export default {
    state: {
        tableSort: {},
    } as ITableSortStorage,
    getters: {
        tableSortById(state: ITableSortStorage) {
            return state.tableSort;
        },
    },
    mutations: {
        loadAllTableSort(state: ITableSortStorage) {
            const data = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (data) {
                state.tableSort = JSON.parse(data);
            }
        },
        saveTableSort(state: ITableSortStorage, { licenseId, tableType, tableSort }: ITableSortSavePayload) {
            const oldValue = (state.tableSort[licenseId] || {})[tableType];
            const newValue = { ...oldValue, ...tableSort };
            const newTableSort = {
                ...state.tableSort[licenseId],
                [tableType]: newValue,
            };
            Vue.set(state.tableSort, licenseId, newTableSort);
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.tableSort));
        },
    },
};
