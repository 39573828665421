









































import { Component } from 'vue-property-decorator';
import { TRules } from '@/types/TRulesObj';
import { AmplitudeEvent } from '@/constants';
import { FormValidator } from '@/services';
import { amplitudeMixin } from '@/mixins';
import ReturnToLoginButton from '@/components/user/ReturnToLoginButton.vue';
import WsInput from '@/components/common/WsInput.vue';

@Component({
    components: {
        ReturnToLoginButton,
        WsInput,
    },
})
export default class ForgotPasswordForm extends amplitudeMixin {

    private forgotForm = {
        email: '',
    };

    get form(): HTMLFormElement {
        return this.$refs.forgotForm as HTMLFormElement;
    }
    get isSending() {
        return this.$store.getters.forgotIsSending;
    }
    get isSent() {
        return this.$store.getters.forgotIsSent;
    }
    get forgotFormError() {
        return this.$store.getters.forgotFormError;
    }
    get emailRules(): TRules {
        return [
            FormValidator.email,
        ];
    }

    public created() {
        this.amplitudeLog(AmplitudeEvent.loginRestorePasswordFormOpened);
    }

    public resetPassword() {
        if (this.form.validate()) {
            this.$store.dispatch('forgotPassword', this.forgotForm.email);
        }
    }
    public clearError() {
        this.$store.commit('setForgotPasswordFormError');
    }
}
