import _ from 'lodash';
import { Project } from '@/models';
import { sortArrayOfObjects } from '@/services';

export function customSortProjects(items: Project[], sortBy: string[] | [], isDesc: boolean[] | []) {
    // sortBy and isDesc are returned by vuetify as an array
    const sortKey = sortBy[0] as keyof Project;
    const isOrderDescending: boolean | undefined = isDesc[0];

    if (sortKey === 'lastArchived') {
        return [...items].sort((a: any, b: any) => b.archived ? 1 : -1);
    } else if (_.isBoolean(isOrderDescending)) {
        return sortArrayOfObjects<Project>(items, sortKey, isOrderDescending);
    } else {
        return [...items].sort((a: any, b: any) => a.archived > b.archived ? 1 : -1);
    }
}
