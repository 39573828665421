













import { Component, Prop, Vue } from 'vue-property-decorator';
import { NameFilterParams, truncateName } from '@/services/FiltersService';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
    },
})
export default class WsTruncate extends Vue {
    @Prop({ required: true }) public value!: NameFilterParams;
    @Prop({ type: String }) public cssClass!: string;
    @Prop({ type: Number, default: 40 }) public size!: number;
    @Prop({ type: Boolean, default: false }) public singleLine!: boolean;
    @Prop({ type: Number, default: 450 }) public maxTooltipWidth!: number;

    get truncatedName() {
        return truncateName(this.value, this.size, !this.singleLine);
    }

    get truncated() {
        return this.truncatedName.truncated;
    }
    get full() {
        return this.truncatedName.full;
    }
}
