import _ from 'lodash';

export enum PermissionGroupNamesEnum {
    administration = 'administration',
    issueTracker = 'issueTracker',
    exportFromRevizto = 'exportFromRevizto',
    clashAutomation = 'clashAutomation',
    _2D = '_2D',
    _3D = '_3D',
    dashboards = 'dashboards',
}

export enum PermissionNamesEnum {
    manage_project_rights = 'manage_project_rights',
    revert_project_to_older_revision = 'revert_project_to_older_revision',
    view_public_issues = 'view_public_issues',
    create_issue = 'create_issue',
    comment_issue = 'comment_issue',
    edit_issue_status = 'edit_issue_status',
    close_issue = 'close_issue',
    edit_issue_title = 'edit_issue_title',
    edit_issue_priority = 'edit_issue_priority',
    edit_issue_deadline = 'edit_issue_deadline',
    edit_issue_assignee = 'edit_issue_assignee',
    edit_issue_reporter = 'edit_issue_reporter',
    edit_issue_watchers = 'edit_issue_watchers',
    edit_issue_visibility = 'edit_issue_visibility',
    tag_issue = 'tag_issue',
    create_new_tags = 'create_new_tags',
    manage_issue_tags_across_project = 'manage_issue_tags_across_project',
    edit_issue_markup = 'edit_issue_markup',
    delete_issue = 'delete_issue',
    manage_issue_preset = 'manage_issue_preset',
    manage_issue_filter_set = 'manage_issue_filter_set',
    allow_clash_sync = 'allow_clash_sync',
    manage_export_sheets_to_pdf = 'manage_export_sheets_to_pdf',
    manage_export_project_to_exe = 'manage_export_project_to_exe',
    export_to_ifc = 'export_to_ifc',
    clash_test_admin = 'clash_test_admin',
    create_clash_test = 'create_clash_test',
    view_public_clash_test = 'view_public_clash_test',
    edit_2d = 'edit_2d',
    append_2d = 'append_2d',
    edit_3d = 'edit_3d',
    append_3d = 'append_3d',
    add_edit_viewpoints = 'add_edit_viewpoints',
    add_edit_videotracks = 'add_edit_videotracks',
    manage_search_set = 'manage_search_set',
    manage_favorite_property_set = 'manage_favorite_property_set',
    manage_object_properties = 'manage_object_properties',
    assign_object_properties = 'assign_object_properties',
    manage_appearance_templates = 'manage_appearance_templates',
    manage_shared_entities = 'manage_shared_entities',
}

export const AccessRoles: { [key in PermissionGroupNamesEnum]: PermissionNamesEnum[] } = {
    [PermissionGroupNamesEnum.administration]: [
        PermissionNamesEnum.manage_project_rights,
        PermissionNamesEnum.revert_project_to_older_revision,
    ],
    [PermissionGroupNamesEnum.issueTracker]: [
        PermissionNamesEnum.view_public_issues,
        PermissionNamesEnum.create_issue,
        PermissionNamesEnum.comment_issue,
        PermissionNamesEnum.edit_issue_status,
        PermissionNamesEnum.close_issue,
        PermissionNamesEnum.edit_issue_title,
        PermissionNamesEnum.edit_issue_priority,
        PermissionNamesEnum.edit_issue_deadline,
        PermissionNamesEnum.edit_issue_assignee,
        PermissionNamesEnum.edit_issue_reporter,
        PermissionNamesEnum.edit_issue_watchers,
        PermissionNamesEnum.edit_issue_visibility,
        PermissionNamesEnum.tag_issue,
        PermissionNamesEnum.create_new_tags,
        PermissionNamesEnum.manage_issue_tags_across_project,
        PermissionNamesEnum.edit_issue_markup,
        PermissionNamesEnum.delete_issue,
        PermissionNamesEnum.manage_issue_preset,
        PermissionNamesEnum.manage_issue_filter_set,
        PermissionNamesEnum.allow_clash_sync,
    ],
    [PermissionGroupNamesEnum.exportFromRevizto]: [
        PermissionNamesEnum.manage_export_sheets_to_pdf,
        PermissionNamesEnum.manage_export_project_to_exe,
        PermissionNamesEnum.export_to_ifc,
    ],
    [PermissionGroupNamesEnum.clashAutomation]: [
        PermissionNamesEnum.clash_test_admin,
        PermissionNamesEnum.create_clash_test,
        PermissionNamesEnum.view_public_clash_test,
    ],
    [PermissionGroupNamesEnum._2D]: [
        PermissionNamesEnum.edit_2d,
        PermissionNamesEnum.append_2d,
    ],
    [PermissionGroupNamesEnum._3D]: [
        PermissionNamesEnum.edit_3d,
        PermissionNamesEnum.append_3d,
        PermissionNamesEnum.add_edit_viewpoints,
        PermissionNamesEnum.add_edit_videotracks,
        PermissionNamesEnum.manage_search_set,
        PermissionNamesEnum.manage_favorite_property_set,
        PermissionNamesEnum.manage_object_properties,
        PermissionNamesEnum.assign_object_properties,
        PermissionNamesEnum.manage_appearance_templates,
    ],
    [PermissionGroupNamesEnum.dashboards]: [
        PermissionNamesEnum.manage_shared_entities,
    ],
};

export const AccessRolesArray = _.values(AccessRoles).flat();

export const PermissionGroupNames: Array<keyof typeof AccessRoles> =  [
    PermissionGroupNamesEnum.administration,
    PermissionGroupNamesEnum.issueTracker,
    PermissionGroupNamesEnum.exportFromRevizto,
    PermissionGroupNamesEnum.clashAutomation,
    PermissionGroupNamesEnum._2D,
    PermissionGroupNamesEnum._3D,
    PermissionGroupNamesEnum.dashboards,
];
