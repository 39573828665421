export enum BusEvent {
    faRequired = 'faRequired',
    openFilter = 'openFilter',
    resetFilter = 'resetFilter',
    toggleColumn = 'toggleColumn',
    printIssue = 'printIssue',
    cancelIssueEdit = 'cancelIssueEdit',
    newPresetCreated = 'newPresetCreated',
    issuesColumnWidth = 'issuesColumnWidth',
    isOpenNotifications = 'isOpenNotifications',
    showMultiIssueComment = 'showMultiIssueComment',
    demandSelectNewStatus = 'demandSelectNewStatus',
    showDialogIssuesWithProblems = 'showDialogIssuesWithProblems',
    cantApplyMultiEdit = 'cantApplyMultiEdit',
    showDialogReplaceStatuses = 'showDialogReplaceStatuses',
    showDialogWarningNotFullyApplied = 'showDialogWarningNotFullyApplied',
    showDialogInvalidIssueType = 'showDialogInvalidIssueType',
    startEditWorkflowField = 'startEditWorkflowField',
    showDialogRemoveUsersFromProjects = 'showDialogRemoveUsersFromProjects',
    licenseProjectsReset = 'licenseProjectsReset',
    updateCharts = 'updateCharts',
    closeAdditionalFieldsAddSidebar = 'closeAdditionalFieldsAddSidebar',
    closeAdditionalFieldsEditSidebar = 'closeAdditionalFieldsEditSidebar',
    clearQuery = 'clearQuery',
    addNotifier = 'addNotifier',
    clearNotifier = 'clearNotifier',
    clearAllNotifier = 'clearAllNotifier',
    requestCopyToBuffer = 'requestCopyToBuffer',
}
