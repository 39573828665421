























import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';
import { Project } from '@/models';
import WsTruncate from '@/components/common/WsTruncate.vue';
import WsProjectPreview from '@/components/common/WsProjectPreview.vue';

@Component({
    components: {
        WsTruncate,
        WsProjectPreview,
    },
})
export default class ProjectsDropdownItem extends Vue {
    @Prop({ required: true }) public project!: Project;
    @Prop({ type: Boolean }) public isActive!: boolean;

    public readonly RouterNames = RouterNames;

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }
}
