













































import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TooltipPositions, TooltipPositionsType } from '@/constants';
import { sanitizeHtml } from '@/services/Sanitizer';

@Component({
    methods: {
        sanitizeHtml,
    },
})
export default class WsTooltip extends Vue {
    @Prop() public tooltip!: TranslateResult; // ignored if content in slot #tooltip
    @Prop() public activator!: TranslateResult; // ignored if content in slot #activator
    @Prop() public activatorSelector!: string;
    @Prop({ type: String }) public tooltipClass!: string;
    @Prop() public activatorClass!: string | string[] | { [key: string]: boolean }; // ignored if content in slot #activator
    @Prop({ default: 'top' }) public placement!: TooltipPositionsType; // change only of it REALLY important
    @Prop({ type: Number }) public maxWidth!: number;
    @Prop({ type: Boolean }) public disabled!: boolean;
    @Prop({ type: Boolean }) public block!: boolean; // activator as block
    @Prop({ type: Boolean }) public inlineFlex!: boolean; // activator as inline-flex
    @Prop({ type: Boolean, default: true }) public sanitizeTooltip!: boolean; // ignored if content in slot #tooltip
    @Prop({ type: Boolean, default: true }) public sanitizeActivator!: boolean; // ignored if content in slot #activator

    get top(): boolean {
        return this.placement === TooltipPositions.top;
    }

    get bottom(): boolean {
        return this.placement === TooltipPositions.bottom;
    }

    get right(): boolean {
        return this.placement === TooltipPositions.right;
    }

    get left(): boolean {
        return this.placement === TooltipPositions.left;
    }

    get activatorClasses(): { [key: string]: boolean } {
        let activatorClassObj = {};
        if (typeof this.activatorClass === 'string') {
            activatorClassObj = { [this.activatorClass]: Boolean(this.activatorClass) };
        } else if (_.isArray(this.activatorClass)) {
            activatorClassObj = this.activatorClass.reduce((acc, next) => {
                acc[next] = true;
                return acc;
            }, {} as { [key: string]: boolean });
        } else if (_.isObject(this.activatorClass)) {
            activatorClassObj = this.activatorClass;
        }
        return {
            'activator-block': this.block,
            'activator-inline-flex': this.inlineFlex,
            ...activatorClassObj,
        };
    }

    get isHereTooltip() {
        return this.$slots.tooltip || this.$scopedSlots.tooltip || this.tooltip;
    }

    get localDisabled() {
        return this.disabled || !this.isHereTooltip;
    }
}
