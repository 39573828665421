export enum SortByValueType {
    DESC = 'BY-VALUE-DESC',
    ASC = 'BY-VALUE-ASC',
    FIXED = 'FIXED',
}

export const SortByValueTypeList = [
    SortByValueType.FIXED,
    SortByValueType.ASC,
    SortByValueType.DESC,
];
