import moment, { Moment } from 'moment';

export interface IDateRange {
    startDate?: Moment;
    endDate?: Moment;
}

const initialStartDate = moment().subtract(1, 'months').startOf('day');
const initialEndDate = moment().endOf('day');

export class DatepickerDateRange {
    public startDate: Moment;
    public endDate: Moment;

    constructor(params: IDateRange | undefined = {}) {
        this.startDate = params.startDate || initialStartDate;
        this.endDate = params.endDate || initialEndDate;
    }
}
