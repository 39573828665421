export enum IssueDetailsInputType {
    select = 'select',
    text = 'text',
    procore = 'procore',
    procoreStatus = 'procoreStatus',
    date = 'date',
    color = 'color',
    multiselect = 'multiselect',
    switch = 'switch',
    dialog = 'dialog',
}
