import { i18n } from '@/services/i18n';

export class LicenseHighLights {
    public issues: number;
    public sheets: number;
    public attachments: number;
    public projectReports: number;
    public projectCharts: number;
    public licenseCharts: number;
    public diskUsage: number;

    constructor(highLights: any = {}) {
        this.issues = highLights.issues;
        this.sheets = highLights.sheets;
        this.attachments = highLights.attachments;
        this.projectReports = highLights.projectReports || 0;
        this.projectCharts = highLights.projectCharts || 0;
        this.licenseCharts = highLights.licenseCharts || 0;
        this.diskUsage = highLights.diskUsage || 0;
    }

    get reports() {
        return this.projectReports + this.projectCharts + this.licenseCharts;
    }

    get diskUsageString() {
        const kilo = 1024;
        const thousand = 1000;
        let diskUsage = this.diskUsage;
        if (diskUsage < thousand) {
            return i18n.t('DiskUsage.B', { count: diskUsage });
        }
        diskUsage = diskUsage / kilo;
        if (diskUsage < thousand) {
            return i18n.t('DiskUsage.KB', { count: Math.ceil(diskUsage) });
        }
        diskUsage = diskUsage / kilo;
        if (diskUsage < thousand) {
            return i18n.t('DiskUsage.MB', { count: Math.ceil(diskUsage) });
        }
        diskUsage = diskUsage / kilo;
        if (diskUsage < thousand) {
            return i18n.t('DiskUsage.GB', { count: Math.ceil(diskUsage) });
        }
        diskUsage = diskUsage / kilo;
        return i18n.t('DiskUsage.TB', { count: Math.ceil(diskUsage) });
    }
}
