












import { Component, Prop } from 'vue-property-decorator';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsProcoreStatus extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
}
