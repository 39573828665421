import { CustomStatusCategory, IssueFilterExpr, IssuesFilterType } from '@/constants';
import { getEmptyFilters } from '@/services';
import { IssueTrackerFilterValue } from '@/services/issueTracker/IssueTrackerFilterValue';

export const getFirstOpenFilters = () => {
    const filters = getEmptyFilters();

    filters.statusCategory = new IssueTrackerFilterValue(
        IssuesFilterType.statusCategory,
        IssueFilterExpr.NOT_IN,
        [CustomStatusCategory.Completed],
    );

    return filters;
};
