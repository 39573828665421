
















































































import _ from 'lodash';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AmplitudeEvent, BusEvent } from '@/constants';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, isFiltersForDeletedIssues } from '@/services';
import AutofocusMixin from '@/mixins/autofocusMixin';
import IssueColumn from '@/components/project/issueTracker/IssueColumn.vue';
import IssueCommentList from '@/components/project/issueTracker/comments/IssueCommentList.vue';
import IssueCommentAdd from '@/components/project/issueTracker/comments/IssueCommentAdd.vue';
import TrackerColumnComponentBase from '@/components/project/issueTracker/columns/TrackerColumnComponentBase.vue';
import ChatFilter from '@/components/project/issueTracker/ChatFilter.vue';
import ChatFilterActions from '@/components/project/issueTracker/ChatFilterActions.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsInputSearch from '@/components/common/WsInputSearch.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    name: 'TrackerChat',
    components: {
        WsButton,
        ChatFilter,
        IssueColumn,
        IssueCommentList,
        IssueCommentAdd,
        ChatFilterActions,
        WsInputSearch,
        IconSvg24,
    },
})
export default class TrackerChat extends Mixins(AutofocusMixin, TrackerColumnComponentBase) {
    public searchQueryPrivate = this.filters.search;
    public allChatItemsMounted = false;

    get isLoading() {
        return this.isLoadingComments || !this.allChatItemsMounted;
    }

    get isLoadingComments() {
        return this.$store.getters.isLoadingComments;
    }

    get isCloseChatCommentAdd() {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get isOpenChatSearch(): boolean {
        return this.$store.getters.isChatSearchOpen;
    }

    get searchQuery() {
        return this.searchQueryPrivate;
    }

    set searchQuery(value) {
        this.setSearchQueryDebounced(value);
    }

    get setSearchQueryDebounced() {
        return _.debounce((value: string) => {
            this.$store.commit('setCommentsSearchValue', value);
            amplitudeLog(AmplitudeEvent.searchComments, { value });
        }, 350);
    }

    get filters(): any {
        return this.$store.getters.commentsFilters;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get project() {
        return this.$store.getters.projectById(this.projectId);
    }

    get isCurrentIssueExist() {
        return Boolean(this.$store.getters.selectedIssueByProjectId(this.projectId));
    }

    get trackerFilters(): TrackerFilters {
        return this.$store.getters.trackerFiltersByProjectId(this.projectId);
    }

    get isDeleted() {
        return isFiltersForDeletedIssues(this.trackerFilters);
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.multiSelectedIssues.length > 1;
    }

    get countSelectedIssues() {
        return this.$store.getters.multiSelectedIssues.length;
    }

    @Watch('currentIssue', { immediate: true, deep: true })
    public currentIssueChanged() {
        this.resetAll();
    }

    public reset() {
        this.$store.commit('resetChatFilters');
    }

    public resetAll() {
        this.reset();
        this.$store.commit('setChatFiltersOpen', false);
        this.cancelSearch();
    }

    public cancelSearch() {
        if (this.isOpenChatSearch) {
            this.filters.search = '';
            this.searchQueryPrivate = '';
            this.$store.commit('setCommentSearchOpen', false);
        }
    }

    public onAllChatItemsMounted() {
        this.allChatItemsMounted = true;
    }

    public onClearSelection() {
        this.$store.dispatch('setMultiSelectEditMode', false);
    }

    public openMultiCommentModal() {
        eventBus.$emit(BusEvent.showMultiIssueComment);
        amplitudeLog(AmplitudeEvent.itOpenMulticommentFromChat, { count: this.countSelectedIssues });
    }
}
