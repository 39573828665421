

























































import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { IComboboxOption } from '@/types/common';
import { Color } from '@/constants';
import { COMMA_SPACER } from '@/constants/Common';
import { truncateString } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsTags extends Vue {
    @VModel() currentTags!: string[];
    @Prop() public tags!: string[];
    @Prop() public tagsToAdd!: string[];
    @Prop() public tagsToRemove!: string[];

    public readonly Color = Color;

    public tagsToAddModel: string[] = this.tagsToAdd ?? [];

    public tagsToRemoveModel: string[] = this.tagsToRemove ?? [];

    public localTags: string[] = _.cloneDeep(this.tags);

    get tagsOptions(): IComboboxOption[] {
        return this.localTags.map(this.stringToOption);
    }

    get tagString() {
        return this.currentTags.map((tag) => truncateString(tag, 20)).join(COMMA_SPACER);
    }

    get tagsToAddString() {
        return this.tagsToAddModel.map((tag) => truncateString(tag, 20)).join(COMMA_SPACER);
    }

    get tagsToRemoveString() {
        return this.tagsToRemoveModel.map((tag) => truncateString(tag, 20)).join(COMMA_SPACER);
    }

    @Emit()
    public change() {
        return [this.tagsToAddModel, this.tagsToRemoveModel];
    }

    @Watch('tagsToAdd')
    public onTagstoAddChange() {
        this.tagsToAddModel = this.tagsToAdd;
    }

    @Watch('tagsToRemove')
    public onTagstoRemoveChange() {
        this.tagsToRemoveModel = this.tagsToRemove;
    }

    public stringToOption(tag: string): IComboboxOption {
       return {
            value: tag,
            label: tag,
            searchValue: tag,
        };
    }
}
