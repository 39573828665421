import { ISelectedItem } from '@/types/ISelectedItem';
import { compareAlphanumerically } from '@/services/Comparators';

export function mapFilterItems(array: string[] = []): ISelectedItem<string>[] {
    return [...array]
        .sort(compareAlphanumerically)
        .map((item) => ({
            text: item,
            value: item,
        }));
}
