
import _ from 'lodash';
import moment from 'moment';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { unitOfTime } from 'moment';
import { IssuesFilterType } from '@/constants';
import { IDateRange } from '@/models';
import {
    dateFormatter,
    getUtcMidday,
} from '@/services';
import { IssueTrackerFilterByDateValue } from '@/services/issueTracker/IssueTrackerFilterByDateValue';
import { IssueTrackerFilterByDeadlineValue } from '@/services/issueTracker/IssueTrackerFilterByDeadlineValue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import WsSelect from '@/components/common/WsSelect.vue';
import WsInputNumber from '@/components/common/WsInputNumber.vue';
import WsTableFilterElement from '@/components/common/WsTableFilterElement.vue';

const DateFilterRuleType = {
    NotSet: 0,
    NowOverdue: 1,
    MoreThan: 2,
    WithinLastNDays: 3,
    DueInNext: 4,
    CustomEqual: 5,
    CustomLess: 6,
    CustomLessOrEqual: 7,
    CustomMore: 8,
    CustomMoreOrEqual: 9,
    CustomBetween: 10,
};

const DateFilterOptions = {
    notSet: 0,
    nowOverdue: 1,
    moreThan: 2,
    withinLast: 3,
    dueInNext: 4,
    custom: 5,
};

const ComparisonOptions = {
    is: 'is',
    gt: 'gt',
    ge: 'ge',
    lt: 'lt',
    le: 'le',
    between: 'between',
};
const ComparisonOptionsKeys = [
    ComparisonOptions.is,
    ComparisonOptions.gt,
    ComparisonOptions.ge,
    ComparisonOptions.lt,
    ComparisonOptions.le,
    ComparisonOptions.between,
];

const TimeUnits = {
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years',
};
const TimeUnitsKeys = [
    TimeUnits.days,
    TimeUnits.weeks,
    TimeUnits.months,
    TimeUnits.years,
];
const TimeUnitFromNumber: any = {
    0: TimeUnits.days,
    1: TimeUnits.weeks,
    2: TimeUnits.months,
    3: TimeUnits.years,
};
const NumberFromTimeUnit: any = _.invert(TimeUnitFromNumber);

const makeEmptyForm = () => ({
    moreThanDays: 1,
    dueInNextDays: 0,
    isIncludeOverdueNow: false,
    withinLastDays: 0,
    comparisonOption: ComparisonOptions.is,
    isFromRelative: true,
    isMinusFromRelative: 0,
    isToRelative: false,
    isMinusToRelative: 0,
    fromRelativeNumber: 0,
    toRelativeNumber: 0,
    fromRelativeTimeUnit: TimeUnits.days as unitOfTime.DurationConstructor,
    toRelativeTimeUnit: TimeUnits.days as unitOfTime.DurationConstructor,
    datePickedFrom: new Date(),
    datePickedTo: new Date(),
});

const makeEmptyFilter = () => ({ dateFilterType: -1 });

@Component({
    methods: {
        dateFormatter,
    },
    components: {
        WsTruncate,
        WsSelect,
        WsInputNumber,
        WsTableFilterElement,
    },
})
export default class FilterByDateBase extends Vue {
    @Prop() public label!: TranslateResult;
    @Prop() public value!: any;
    @Prop() public type!: string;

    public readonly DateFilterOptions = DateFilterOptions;
    public readonly ComparisonOptions = ComparisonOptions;
    public readonly StopClickOutsideFilterClass = 'stop-click-outside-date-filter';

    public popover = false;
    public dateFilterType = -1;

    public form = makeEmptyForm();

    get comparisonOptions() {
        return ComparisonOptionsKeys.map((key) => ({
            value: key,
            text: this.$t(`IssueTracker.timeFilters.${key}`),
        }));
    }
    get timeUnits() {
        return TimeUnitsKeys.map((key) => ({
            value: key,
            text: this.$t(`IssueTracker.timeFilters.timeUnits.${key}`),
        }));
    }
    get dueInNextDates() {
        const start = getUtcMidday();
        const end = getUtcMidday().add(this.form.dueInNextDays, 'days');
        if (this.form.isIncludeOverdueNow) {
            return this.$t('IssueTracker.timeFilters.before', {
                date: dateFormatter(end),
            });
        } else {
            return [dateFormatter(start), dateFormatter(end)].join(' — ');
        }
    }
    get withinLastDates() {
        const start = getUtcMidday().subtract(this.form.withinLastDays, 'days');
        const end = getUtcMidday();
        return [dateFormatter(start), dateFormatter(end)].join(' — ');
    }
    get customDates() {
        let from;
        let to;
        if (this.form.isFromRelative) {
            const fromSign = this.form.isMinusFromRelative ? -1 : 1;
            const shift = fromSign * this.form.fromRelativeNumber;
            from = getUtcMidday().add(shift, this.form.fromRelativeTimeUnit);
        } else {
            from = moment(this.form.datePickedFrom);
        }
        if (this.form.isToRelative) {
            const toSign = this.form.isMinusToRelative ? -1 : 1;
            const shift = toSign * this.form.toRelativeNumber;
            to = getUtcMidday().add(shift, this.form.toRelativeTimeUnit);
        } else {
            to = moment(this.form.datePickedTo);
        }
        switch (this.form.comparisonOption) {
            case (ComparisonOptions.is):
                return dateFormatter(from);
            case (ComparisonOptions.gt):
                return this.$t('IssueTracker.timeFilters.from', {
                    date: dateFormatter(moment(from).add(1, 'day')),
                });
            case (ComparisonOptions.ge):
                return this.$t('IssueTracker.timeFilters.from', {
                    date: dateFormatter(from),
                });
            case (ComparisonOptions.lt):
                return this.$t('IssueTracker.timeFilters.before', {
                    date: dateFormatter(from),
                });
            case (ComparisonOptions.le):
                return this.$t('IssueTracker.timeFilters.before', {
                    date: dateFormatter(moment(from).add(1, 'day')),
                });
            case (ComparisonOptions.between):
                if (from.unix() > to.unix()) {
                    [from, to] = [to, from];
                }
                return [
                    dateFormatter(from),
                    dateFormatter(to),
                ].join(' — ');
        }
        return 1e9 - 1;
    }
    get filterState() {
        return { dateFilterType: this.dateFilterType, ...this.form };
    }
    get datePickedToValue(): IDateRange {
        const momentDate = moment(this.form.datePickedTo);
        return {
            startDate: momentDate,
            endDate: momentDate,
        };
    }
    get datePickedFromValue(): IDateRange {
        const momentDate = moment(this.form.datePickedFrom);
        return {
            startDate: momentDate,
            endDate: momentDate,
        };
    }

    @Emit()
    public clear() {
        this.input(makeEmptyFilter());
        return;
    }

    @Emit()
    public input(value: any) {
        switch (this.type) {
            case IssuesFilterType.deadline:
                return new IssueTrackerFilterByDeadlineValue(this.type, value.dateFilterType, value.date, value.date2, value.days, value.includeNow);
            case IssuesFilterType.closed:
            case IssuesFilterType.created:
                return new IssueTrackerFilterByDateValue(this.type, value.dateFilterType, value.date, value.date2, value.days);
        }
    }

    @Watch('value', { deep: true, immediate: true })
    public onValueChange(value: any) {
        switch (value.dateFilterType) {
            case DateFilterRuleType.NotSet:
                this.dateFilterType = DateFilterOptions.notSet;
                break;
            case DateFilterRuleType.NowOverdue:
                this.dateFilterType = DateFilterOptions.nowOverdue;
                break;
            case DateFilterRuleType.MoreThan:
                this.dateFilterType = DateFilterOptions.moreThan;
                this.$set(this.form, 'moreThanDays', String(value.days));
                break;
            case DateFilterRuleType.DueInNext:
                this.dateFilterType = DateFilterOptions.dueInNext;
                this.$set(this.form, 'dueInNextDays', String(value.days));
                this.$set(this.form, 'isIncludeOverdueNow', value.includeNow);
                break;
            case DateFilterRuleType.WithinLastNDays:
                this.dateFilterType = DateFilterOptions.withinLast;
                this.$set(this.form, 'withinLastDays', String(value.days));
                break;
            case DateFilterRuleType.CustomEqual:
                this.$set(this.form, 'comparisonOption', ComparisonOptions.is);
                break;
            case DateFilterRuleType.CustomLess:
                this.$set(this.form, 'comparisonOption', ComparisonOptions.lt);
                break;
            case DateFilterRuleType.CustomLessOrEqual:
                this.$set(this.form, 'comparisonOption', ComparisonOptions.le);
                break;
            case DateFilterRuleType.CustomMore:
                this.$set(this.form, 'comparisonOption', ComparisonOptions.gt);
                break;
            case DateFilterRuleType.CustomMoreOrEqual:
                this.$set(this.form, 'comparisonOption', ComparisonOptions.ge);
                break;
            case DateFilterRuleType.CustomBetween:
                this.$set(this.form, 'comparisonOption', ComparisonOptions.between);
                break;
            default:
                this.dateFilterType = -1;
        }
        if ([
            DateFilterRuleType.CustomEqual,
            DateFilterRuleType.CustomLess,
            DateFilterRuleType.CustomLessOrEqual,
            DateFilterRuleType.CustomMore,
            DateFilterRuleType.CustomMoreOrEqual,
            DateFilterRuleType.CustomBetween,
        ].includes(value.dateFilterType)) {
            this.dateFilterType = DateFilterOptions.custom;
            if (value.date.isRelative) {
                this.$set(this.form, 'isFromRelative', true);
                this.$set(this.form, 'isMinusFromRelative', value.date.value < 0 ? 1 : 0);
                this.$set(this.form, 'fromRelativeNumber', String(Math.abs(value.date.value)));
                this.$set(this.form, 'fromRelativeTimeUnit', TimeUnitFromNumber[value.date.units]);
            } else {
                this.$set(this.form, 'isFromRelative', false);
                this.$set(this.form, 'datePickedFrom', moment(value.date.value, 'DD-MM-YYYY').toDate());
            }
        }
        if (value.dateFilterType === DateFilterRuleType.CustomBetween) {
            if (value.date2.isRelative) {
                this.$set(this.form, 'isToRelative', true);
                this.$set(this.form, 'isMinusToRelative', value.date2.value < 0 ? 1 : 0);
                this.$set(this.form, 'toRelativeNumber', String(Math.abs(value.date2.value)));
                this.$set(this.form, 'toRelativeTimeUnit', TimeUnitFromNumber[value.date2.units]);
            } else {
                this.$set(this.form, 'isToRelative', false);
                this.$set(this.form, 'datePickedTo', moment(value.date2.value, 'DD-MM-YYYY').toDate());
            }
        }
    }

    @Watch('filterState', { deep: true, immediate: true })
    public onFilterChange(val: any, old: any) {
        if (old === undefined) {
            return;
        }
        const value: any = {};
        switch (this.dateFilterType) {
            case DateFilterOptions.notSet:
                value.dateFilterType = DateFilterRuleType.NotSet;
                break;
            case DateFilterOptions.nowOverdue:
                value.dateFilterType = DateFilterRuleType.NowOverdue;
                break;
            case DateFilterOptions.moreThan:
                value.dateFilterType = DateFilterRuleType.MoreThan;
                value.days = this.form.moreThanDays;
                break;
            case DateFilterOptions.dueInNext:
                value.dateFilterType = DateFilterRuleType.DueInNext;
                value.days = this.form.dueInNextDays;
                value.includeNow = this.form.isIncludeOverdueNow;
                break;
            case DateFilterOptions.withinLast:
                value.dateFilterType = DateFilterRuleType.WithinLastNDays;
                value.days = this.form.withinLastDays;
                break;
            case DateFilterOptions.custom: {
                value.date = { isRelative: this.form.isFromRelative };
                if (this.form.isFromRelative) {
                    const fromSign = this.form.isMinusFromRelative ? -1 : 1;
                    value.date.value = fromSign * this.form.fromRelativeNumber;
                    value.date.units = NumberFromTimeUnit[this.form.fromRelativeTimeUnit];
                } else {
                    value.date.value = moment(this.form.datePickedFrom).format('DD-MM-YYYY');
                }
                switch (this.form.comparisonOption) {
                    case ComparisonOptions.is:
                        value.dateFilterType = DateFilterRuleType.CustomEqual;
                        break;
                    case ComparisonOptions.lt:
                        value.dateFilterType = DateFilterRuleType.CustomLess;
                        break;
                    case ComparisonOptions.le:
                        value.dateFilterType = DateFilterRuleType.CustomLessOrEqual;
                        break;
                    case ComparisonOptions.gt:
                        value.dateFilterType = DateFilterRuleType.CustomMore;
                        break;
                    case ComparisonOptions.ge:
                        value.dateFilterType = DateFilterRuleType.CustomMoreOrEqual;
                        break;
                    case ComparisonOptions.between:
                        value.dateFilterType = DateFilterRuleType.CustomBetween;
                        value.date2 = { isRelative: this.form.isToRelative };
                        if (this.form.isToRelative) {
                            const toSign = this.form.isMinusToRelative ? -1 : 1;
                            value.date2.value = toSign * this.form.toRelativeNumber;
                            value.date2.units = NumberFromTimeUnit[this.form.toRelativeTimeUnit];
                        } else {
                            value.date2.value = moment(this.form.datePickedTo).format('DD-MM-YYYY');
                        }
                        break;
                }
                break;
            }
        }
        this.input(value);
    }

    // This is a stub which should be rewritten in https://revizto.atlassian.net/browse/WEB-5698
    public render(h: any) {
        return h();
    }

    public onReset() {
        this.dateFilterType = -1;
        this.form = makeEmptyForm();
    }

    public onInputDateFrom(value: IDateRange) {
        this.form.datePickedFrom = value.startDate?.toDate() || new Date();
    }

    public onInputDateTo(value: IDateRange) {
        this.form.datePickedTo = value.startDate?.toDate() || new Date();
    }
}
