import Vue from 'vue';

const LOCAL_STORAGE_KEY = 'tableControls';

export interface ITableControl {
    value: string;
    isHidden?: boolean;
}
interface ITableControlsObj {
    [tableType: string]: ITableControl[];
}
interface ITableControlsStorage {
    tableControls: { [licenseId: number]: ITableControlsObj };
}

interface ITableControlsToSave {
    licenseId: number;
    tableType: string;
    tableControls: ITableControl[];
}

export default {
    state: {
        tableControls: {},
    } as ITableControlsStorage,
    getters: {
        tableControlsById(state: ITableControlsStorage) {
            return state.tableControls;
        },
    },
    mutations: {
        loadAllTableControls(state: ITableControlsStorage) {
            const data = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (data) {
                state.tableControls = JSON.parse(data);
            }
        },
        saveTableControls(state: ITableControlsStorage, { licenseId, tableType, tableControls }: ITableControlsToSave) {
            const newTableControls = {
                ...state.tableControls[licenseId],
                [tableType]: tableControls,
            };
            Vue.set(state.tableControls, licenseId, newTableControls);
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.tableControls));
        },
    },
};
