






























import { Component, Prop } from 'vue-property-decorator';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class WsCheckbox extends VuetifyElement {
    @Prop({ type: Boolean, default: false }) public dense!: boolean;
    @Prop({ default: 0 }) public maxMessages!: number;
    @Prop({ type: Boolean, default: false }) public numbers!: boolean;
    @Prop() public indeterminate!: boolean;
    @Prop({ default: () => [] }) public customClasses!: string[];
}
