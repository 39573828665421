var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{ref:"combobox",class:{
        'w-label': !_vm.isNew && _vm.label,
        'ws-combobox': _vm.isNew,
    },attrs:{"id":_vm.uuid,"outlined":_vm.outlined,"dense":_vm.dense,"error":_vm.error,"required":_vm.required,"clearable":_vm.clearable,"disabled":_vm.disabled,"hideDetails":_vm.hideDetails,"label":_vm.label,"placeholder":_vm.placeholder,"messages":_vm.messages,"errorMessages":_vm.errorMessages,"successMessages":_vm.successMessages,"rules":_vm.rules,"items":_vm.items,"attach":_vm.attach,"hideSelected":_vm.hideSelected,"autoSelectFirst":_vm.autoSelectFirst,"openOnClear":_vm.openOnClear,"searchInput":_vm.search,"multiple":_vm.multiple,"append-icon":_vm.icon,"noDataText":_vm.noDataText,"autofocus":_vm.autofocus,"item-text":_vm.itemText},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":[function($event){_vm.search=$event},_vm.updateSearchInput],"change":_vm.change,"focusout":_vm.focusout,"focus":_vm.onFocus,"click:clear":_vm.clear},scopedSlots:_vm._u([(_vm.isLimit)?{key:"append-outer",fn:function(){return [_c('div',{staticClass:"text-limit"},[_c('b',[_vm._v(_vm._s(_vm.valueLength))]),_vm._v("/"+_vm._s(_vm.maxlength)+" ")])]},proxy:true}:null,(_vm.$scopedSlots.selection)?{key:"selection",fn:function(ref){
    var item = ref.item;
return [_vm._t("selection",null,{"item":item})]}}:{key:"selection",fn:function(ref){
    var attrs = ref.attrs;
    var selected = ref.selected;
    var select = ref.select;
    var item = ref.item;
return [(_vm.multiple)?_c('v-chip',_vm._b({class:Object.assign({}, {disabled: _vm.disabled},
                _vm.itemClassesFormatter(item)),attrs:{"input-value":selected,"close-icon":"mdi-close","close":"","outlined":"","label":"","small":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"text",style:({ color: _vm.colorFormatter(item) })},[_vm._v(_vm._s(_vm.selectFormatter(item)))])]):(!_vm.multiple)?_c('div',{staticClass:"ws-combobox-selection"},[_vm._v(" "+_vm._s(_vm.selectFormatter(item))+" ")]):_vm._e()]}},(_vm.showAdditionalCreateNew)?{key:"prepend-item",fn:function(){return [(_vm.allowCreate && !_vm.prohibitToCreateNew && _vm.trimmedSearch)?_c('v-list-item',{on:{"click":_vm.add}},[_c('div',{staticClass:"create-text-wrapper"},[_c('span',{staticClass:"new-value"},[_vm._v(_vm._s(_vm.trimmedSearch))]),_c('span',{staticClass:"create-text"},[_vm._v(_vm._s(_vm.creationText)+" ")])])]):_vm._e()]},proxy:true}:null,{key:"no-data",fn:function(){return [(_vm.allowCreate && !_vm.prohibitToCreateNew)?[(!_vm.showAdditionalCreateNew)?[(_vm.isRestrictedNewItem && _vm.search)?_c('v-list-item',[_vm._v(" "+_vm._s(_vm.restrictionText)+" ")]):(_vm.trimmedSearch)?_c('v-list-item',{on:{"click":_vm.add}},[_c('div',{staticClass:"create-text-wrapper"},[_c('span',{staticClass:"new-value"},[_vm._v(_vm._s(_vm.trimmedSearch))]),_c('span',{staticClass:"create-text"},[_vm._v(_vm._s(_vm.creationText)+" ")])])]):_vm._e()]:_c('div',{staticClass:"invisible"})]:[(_vm.isRestrictedNewItem)?_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t('Collocation.noDataAvailable'))+" ")]):_c('v-list-item',[_vm._v(" "+_vm._s(_vm.prohibitToCreateNew ? _vm.restrictionText : _vm.noDataText)+" ")])]]},proxy:true},(_vm.required)?{key:"label",fn:function(){return [_c('span',{staticClass:"required-star"},[_vm._v("*")]),_vm._v(_vm._s(_vm.label)+" ")]},proxy:true}:(_vm.$scopedSlots.item)?{key:"item",fn:function(ref){
            var item = ref.item;
return [_vm._t("item",null,{"item":item})]}}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }