import _ from 'lodash';
import { LicenseMember } from '@/models/license/LicenseMember';

export interface IAdditionalFieldForm {
    fieldItemToAssign?: string;
    assignedMembers?: LicenseMember[];
}

export class AdditionalFieldsForm  {
    public fieldItemToAssign: string;
    public assignedMembers: LicenseMember[];
    public assignedMembersUuids: string[];
    public initialData: IAdditionalFieldForm;

    constructor(data: IAdditionalFieldForm = {} as IAdditionalFieldForm) {
        this.fieldItemToAssign = data.fieldItemToAssign || '';
        this.assignedMembers = data.assignedMembers || [];
        this.assignedMembersUuids = data.assignedMembers?.map(({ uuid }) => uuid) || [];

        // It means to be filed only in constructor to further comparison.
        this.initialData = {
            fieldItemToAssign: data.fieldItemToAssign || '',
            assignedMembers: data.assignedMembers || [],
        };
    }

    get isUnsavedDataInForm() {
        const membersUuidsStringToCompare = this.assignedMembersUuids.sort().join(',');
        return this.stringToSeeIsFormFilled !== `${membersUuidsStringToCompare}-${this.fieldItemToAssign}`;
    }

    get stringToSeeIsFormFilled() {
        const membersUuidsStringToCompare = this.initialData.assignedMembers?.map(({ uuid }) => uuid).sort().join(',');
        return `${membersUuidsStringToCompare}-${this.initialData.fieldItemToAssign}`;
    }

    public clearForm() {
        this.fieldItemToAssign = '';
        this.assignedMembers = [];
        this.assignedMembersUuids = [];
        this.initialData = {
            fieldItemToAssign: '',
            assignedMembers: [],
        };
    }

    public prepareDataToCreate() {
        return {
            fieldValue: this.fieldItemToAssign,
            memberUuids: this.assignedMembers.map(({ uuid }) => uuid),
        };
    }

    public prepareDataToUpdate() {
        const newFieldValue = this.fieldItemToAssign;
        const oldFieldValue = this.initialData.fieldItemToAssign || '';

        // This is neccesary due to backend features with new company name as CREATE, so we need to add all members from 'old' field
        // But we don't change field name, wee have to calculate which users are to add, which to delete
        const addMemberUuids = newFieldValue !== oldFieldValue
            ? this.assignedMembers.map(({ uuid }) => uuid)
            : _.differenceBy(this.assignedMembers, this.initialData.assignedMembers ?? [], 'uuid').map(({ uuid }) => uuid);
        
        const deleteMemberUuids = newFieldValue !== oldFieldValue
            ? []
            : _.differenceBy(this.initialData.assignedMembers, this.assignedMembers, 'uuid').map(({ uuid }) => uuid);

        return {
            oldFieldValue,
            newFieldValue,
            deleteMemberUuids,
            addMemberUuids,
        };
    }

    public prepareDataToMerge(fieldToMerge: string) {
        const oldFieldValue = fieldToMerge || this.initialData.fieldItemToAssign || '';
        
        return {
            oldFieldValue,
            newFieldValue: this.fieldItemToAssign,
            deleteMemberUuids: [],
            addMemberUuids: this.assignedMembers.map(({ uuid }) => uuid),
        };
    }
}
