import { TranslateResult } from 'vue-i18n';
import { i18n } from '@/services/i18n';

const mailFormatRegex = RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/, 'i');

export class FormValidator {
    public static required(value: any, error: TranslateResult = '') {
        const valid = typeof value === 'string'
            ? FormValidator.isRequiredTrimmed(value)
            : FormValidator.isRequired(value);
        return valid || error || i18n.t('Simple_word.required');
    }
    public static isRequired(value: any) {
        return Boolean(value);
    }
    public static isRequiredTrimmed(value: any) {
        return FormValidator.isRequired(value) && FormValidator.isRequired(String(value).trim());
    }
    public static email(value: string) {
        return FormValidator.isValidEmail(value) || i18n.t('errors.invalid_email');
    }
    public static isValidEmail(value: string) {
        return mailFormatRegex.test(String(value).trim());
    }
    public static isExist(array: string[], value: string) {
        return Boolean(value && array.find((item: string) => item.trim().toLowerCase() === value.trim().toLowerCase()));
    }
    public static maxLength(value: string, limit: number) {
        return value.length <= limit;
    }
}
