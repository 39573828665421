/* tslint:disable no-var-requires */
import { TranslateResult } from 'vue-i18n';
const sanitize = require('sanitize-html');

const ALLOWED_TAGS = [
    'br',
    'a',
    'p',
    'span',
    'b',
    'strong',
    'kbd',
    'ul',
    'li',
];

export function sanitizeHtml(source: TranslateResult): string {
    return sanitize(String(source), {
        allowedTags: ALLOWED_TAGS,
        allowedAttributes: {
            'a': ['href', 'style', 'class', 'target'],
        },
    });
}
