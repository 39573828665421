

































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class WsInputNumberExtended extends VuetifyElement {
    @Prop({ type: Number, default: 1 }) public min!: number;
    @Prop({ type: Number, default: 100 }) public max!: number;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public required!: boolean;
    @Prop({ type: String, default: '' }) public suffix!: string;

    public start = 0;
    public id = 'input-' + uuidv4();

    @Emit()
    public input(value: string) {
        if (Number(value) > this.max) {
            return this.max;
        }

        if (Number(value) < this.min) {
            return this.min;
        }

        return Number(value);
    }

    @Emit()
    public change(value: string) {
        if (Number(value) > this.max) {
            return this.max;
        }

        if (Number(value) < this.min) {
            return this.min;
        }

        return Number(value);
    }

    @Emit()
    public blur(event: MouseEvent) {
        const target = event?.target as HTMLInputElement;
        if (target) {
            target.value = this.model;
        }

        const vTextField = this.$refs.vTextField as any;
        if (vTextField && vTextField.initialValue && vTextField.lazyValue) {
            vTextField.initialValue = this.model;
            vTextField.lazyValue = this.model;
        }

        return this.model;
    }

    @Watch('model')
    public onModelChanged(model: number) {
        if (model > this.max) {
            this.model = this.max;
        }
    }

    public mounted() {
        const handler = (evt: any) => {
            if (Number(evt.target.value) > this.max) {
                evt.target.value = this.max;
                this.value = this.max;
            }
        };
        this.eventListeners.add({ node: document, selector: `#${this.id}`, event: 'input', handler });
    }

    public up() {
        if (this.model < this.max) {
            this.model++;
        }
    }

    public down() {
        if (this.model > (this.min)) {
            this.model--;
        }
    }
}
