import { TranslateResult } from 'vue-i18n';
import { Dict } from '@/types/Dict';

const defaultMaxLength = 40;

export function truncateString(str: TranslateResult | string | undefined, maxLength = defaultMaxLength) {
    str = str ? String(str) : '';
    return (str && str.length > maxLength) ? str.slice(0, maxLength - 2).trim() + '...' : str;
}

export function getTruncateString(maxLength: number = defaultMaxLength) {
    return (str: string) => truncateString(str, maxLength);
}

export function spacesToNoBreak(str: string) {
    const nbsp = String.fromCharCode(0x00A0);
    return str.replace(/\s/g, nbsp);
}

export function truncateQuotedParts(str: string, maxLength = defaultMaxLength) {
    const parts = str.split(/"/);
    const truncatedParts = parts.map((part, i) => i % 2 ? truncateString(part, maxLength) : part);
    return truncatedParts.join('"');
}

const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

export function measureTextWidth(str: string, fontSize = 12) {
    if (!context) {
        return 0;
    }
    context.font = `${fontSize}px Arial`;
    return context.measureText(str).width;
}

export function truncateStringByWidth(str: TranslateResult, maxWidth: number) {
    str = String(str);
    function truncateRecursive(currentStr: string, currentLength: number): string {
        const currentWidth = measureTextWidth(currentStr);

        if (!currentWidth || currentLength <= 0) {
            return currentStr;
        }

        if (currentWidth <= maxWidth) {
            return currentStr;
        }

        const truncatedStr = currentStr.slice(0, currentLength).trim() + '...';
        return truncateRecursive(truncatedStr, currentLength - 1);
    }

    return truncateRecursive(str, str.length);
}

export function getTruncateStringByWidth(maxWidth: number) {
    return (str: string) => truncateStringByWidth(str, maxWidth);
}

export function stableStringify(obj: Dict) {
    return JSON.stringify(obj, Object.keys(obj).sort());
}

export function isEqualLowerCase(left: string, right: string) {
    return left.trim().toLowerCase() === right.trim().toLowerCase();
}
