export enum DashboardVisibility {
    groupPublicIssues = 0,
    publicIssues = 1,
    allIssues = 2,
    groupPrivateIssues = 3,
}

export const DashboardVisibilityPublicList = [
    DashboardVisibility.groupPublicIssues,
    DashboardVisibility.publicIssues,
];

export const DashboardVisibilityPrivateList = [
    DashboardVisibility.allIssues,
    DashboardVisibility.groupPrivateIssues,
];
