interface FeedbackQuestion {
    titleKey: string;
    closeDelay?: number;
}
export interface OpenFeedbackQuestion extends FeedbackQuestion {
    isOpenQuestion: boolean;
    maxLength: number;
}
export interface RatingFeedbackQuestion extends FeedbackQuestion {
    isRating: boolean;
}
export interface LinkFeedbackQuestion extends FeedbackQuestion {
    isLink: boolean;
}
export type AnyFeedbackQuestion = OpenFeedbackQuestion | RatingFeedbackQuestion | LinkFeedbackQuestion | FeedbackQuestion;

export type FeedbackNotify = (props: {
    question?: AnyFeedbackQuestion;
    answerCallback?: any;
    isEnd?: boolean;
}) => void;

export type FeedbackResult = null | number | string;

export interface FeedbackPoll {
    questions: AnyFeedbackQuestion[];
    endCallback: (results: FeedbackResult[]) => any;
}

export enum FeedbackType {
    DashboardsCreating = 'DashboardsCreating',
    IssueTracker = 'IssueTracker',
}
