export const IssuesFilterType = {
    assignee: 'assignee',
    assigneeCompany: 'assigneeCompany',
    assigneeDepartment: 'assigneeDepartment',
    assigneeLocation: 'assigneeLocation',
    binding: 'binding', // поддержка значения на фронте
    created: 'created',
    closed: 'closed',
    deadline: 'deadline',
    duration: 'duration',
    clashArea: 'clashArea',
    clashCategory: 'clashCategory',
    clashDiscipline: 'clashDiscipline',
    clashGridX: 'clashGridX',
    clashGridY: 'clashGridY',
    clashLevel: 'clashLevel',
    clashRoom: 'clashRoom',
    clashSourceFile: 'clashSourceFile',
    clashSpace: 'clashSpace',
    clashTest: 'clashTest',
    clashZone: 'clashZone',
    createdFrom: 'createdFrom',
    type: 'type', // Legacy, use customType instead
    customType: 'customType',
    status: 'status', // Legacy, use statusCategory or customStatus instead
    customStatus: 'customStatus',
    statusCategory: 'statusCategory',
    id: 'id',
    mainSheet: 'mainSheet',
    mainSheetTag: 'mainSheetTag',
    stampAbbr: 'stampAbbr',
    stampCategory: 'stampCategory',
    stampCategoryByTitle: 'stampCategoryByTitle',
    stampColor: 'stampColor',
    priority: 'priority',
    procoreType: 'procoreType',
    procoreStatus: 'procoreStatus',
    project: 'project',
    reporter: 'reporter',
    reporterCompany: 'reporterCompany',
    reporterDepartment: 'reporterDepartment',
    reporterLocation: 'reporterLocation',
    sheets: 'sheets',
    tags: 'tags',
    title: 'title',
    visibility: 'visibility',
    watchers: 'watchers',
    attachedDocs: 'attachedDocs',
    projectTags: 'projectTags',
    deletedAt: 'deletedAt',
};

export const OrderedIssuesFilterTypes = [
    IssuesFilterType.project,
    IssuesFilterType.assignee,
    IssuesFilterType.watchers,
    IssuesFilterType.reporter,
    IssuesFilterType.priority,
    IssuesFilterType.customStatus,
    IssuesFilterType.visibility,
    IssuesFilterType.customType,
    IssuesFilterType.tags,
    IssuesFilterType.created,
    IssuesFilterType.closed,
    IssuesFilterType.duration,
    IssuesFilterType.deadline,
    IssuesFilterType.clashCategory,
    IssuesFilterType.clashDiscipline,
    IssuesFilterType.clashGridX,
    IssuesFilterType.clashGridY,
    IssuesFilterType.clashLevel,
    IssuesFilterType.clashSourceFile,
    IssuesFilterType.clashTest,
    IssuesFilterType.mainSheet,
    IssuesFilterType.mainSheetTag,
    IssuesFilterType.stampAbbr,
    IssuesFilterType.stampCategory,
    IssuesFilterType.stampColor,
    IssuesFilterType.procoreType,
    IssuesFilterType.procoreStatus,
    IssuesFilterType.title,
    IssuesFilterType.assigneeCompany,
    IssuesFilterType.assigneeDepartment,
    IssuesFilterType.assigneeLocation,
    IssuesFilterType.reporterCompany,
    IssuesFilterType.reporterDepartment,
    IssuesFilterType.reporterLocation,
    IssuesFilterType.createdFrom,
    IssuesFilterType.binding,
    IssuesFilterType.clashRoom,
    IssuesFilterType.clashSpace,
    IssuesFilterType.clashArea,
    IssuesFilterType.clashZone,
    IssuesFilterType.id,
    IssuesFilterType.visibility,
];
