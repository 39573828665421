















import { TranslateResult } from 'vue-i18n';
import { Component, Prop } from 'vue-property-decorator';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsSourceFile extends IssueDetailsBase {
    @Prop({ required: true }) public label!: TranslateResult;

    get multiSelectMode() {
        return this.$store.getters.multiSelectedIssues.length > 1;
    }
}
