







































import { Component, Prop } from 'vue-property-decorator';
import { StampColorsPaletteRGBEnum } from '@/constants';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover, {
    ListItem,
} from '@/components/project/issueTracker/details/IssueDetailsEditPopover.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';
import StampAbbr from '@/components/common/StampAbbr.vue';

@Component({
    components: {
        IssueDetailsItem,
        IssueDetailsEditPopover,
        StampAbbr,
    },
})
export default class IssueDetailsStampAbbr extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean }) public editable!: boolean;
    @Prop({ required: true }) public items!: ListItem[];

    public readonly StampColorsPaletteRGBEnum = StampColorsPaletteRGBEnum;

    get itemsForSelect(): ListItem[] {
        return this.items;
    }

    public handleSelect(value: string) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
        }
    }
}
