export enum IssueTypeIcon {
    diamond,
    time,
    heart,
    star,
    arrowdown,
    arrowup,
    message,
    bug,
    packman,
    location,
    lock,
    gear,
    avatar,
    lightning,
    bookmark,
    lightbulb,
    robot,
    checkmark,
    warning,
    question,
    plus,
    minus,
    block,
    refresh,
    circle,
    dollar,
    magnifier,
    clash,
    text,
    pulse,
    photo,
    envelope,
    list,
}

export const getIssueTypeIconPath = (icon: IssueTypeIcon): string =>  {
    // @todo refactor it
    return 'issuetype/' + Object.keys(IssueTypeIcon)[Object.values(IssueTypeIcon).indexOf(icon)];
};

export const IssueTypeIconsValues = Object.values(IssueTypeIcon);
