


































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgSource24 extends Vue {
    @Prop({ default: 'approval' }) public icon!: string;
    @Prop({ default: '#616161' }) public fill!: string;
}
