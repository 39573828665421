




































import { Component, Vue } from 'vue-property-decorator';
import { AmplitudeEvent, IssueCommentsFilter } from '@/constants';
import { amplitudeLog } from '@/services';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsInput from '@/components/common/WsInput.vue';

@Component({
    components: {
        WsInput,
        IconSvg24,
    },
})
export default class ChatFilter extends Vue {
    public IssueCommentsFilter = IssueCommentsFilter;

    get filters(): any {
        return this.$store.getters.commentsFilters;
    }

    get isOpenChatFilters(): boolean {
        return this.$store.getters.isChatFiltersOpen;
    }

    get commentsFiltersActive(): any {
        return this.$store.getters.commentsFiltersActive;
    }

    public onChangeFilter(filter: IssueCommentsFilter, value: boolean) {
        this.$store.commit('setCommentsFilterValue', {
            filter,
            value,
        });

        if (value) {
            amplitudeLog(AmplitudeEvent.setChatFilter, {
                filter,
                value,
            });
        }
    }

    public reset() {
        this.$store.commit('resetChatFilters');
        amplitudeLog(AmplitudeEvent.resetChatFilters);
    }
}
