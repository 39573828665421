







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SkeletonBone extends Vue {
    @Prop({ type: Boolean }) public avatar!: boolean;
    @Prop({ type: Boolean }) public textFull!: boolean;
    @Prop({ type: Boolean }) public textShort!: boolean;
    @Prop({ type: Boolean }) public full!: boolean;

    get modifier() {
        const prop = Object.entries(this.$props).find(([, value]) => value);
        return [prop?.[0]];
    }
}
