import _ from 'lodash';
import { Route } from 'vue-router';
import { LanguageKey } from '@/constants';

export function isIncorrectLang(language: string) {
    return !_.includes(LanguageKey, language);
}

export function isRouteNeedAdmin(route: Route) {
    return route.matched.some((record) => record.meta.isNeedAdmin);
}
