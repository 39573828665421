

























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
        IconSvg24,
    },
})
export default class WsCollapsableColumn extends Vue {
    @Prop({ required: true, type: Boolean }) public collapsed!: boolean;
    @Prop({ required: true }) public title!: TranslateResult;
    @Prop({ required: true }) public icon!: string; // IconSvg
    @Prop({ type: String, default: '' }) public entity!: string;

    @Emit()
    public toggle() {
        return !this.collapsed;
    }
}
