






























import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { Issue } from '@/models';
import { amplitudeLog } from '@/services';
import WsCheckbox from '@/components/common/WsCheckbox.vue';

@Component({
    components: {
        WsCheckbox,
    },
})
export default class IssueItemColumnCheck extends Vue {
    @Prop({ default: false }) public listHasScroll!: boolean;
    @Prop({ default: false }) public loading!: boolean;
    @Prop({ default: [] }) public issues!: Issue[];
    @Prop({ default: [] }) public selected!: Issue[];

    get selectedOnPage(): Issue[] {
        return _.intersectionBy(this.selected, this.issues, 'id');
    }

    get isAllOnPageSelected(): boolean {
        return this.issues.length > 0 && this.selectedOnPage.length === this.issues.length;
    }

    set isAllOnPageSelected(value: boolean) {
        this.$store.commit('multiSelectAllIssues', value ? _.uniqBy([...this.selected, ...this.issues], 'id') : _.xorBy(this.selected, this.issues, 'id'));
    }

    get isAnyOnPageSelected(): boolean {
        return this.selected.length > 0 && !this.isAllOnPageSelected && this.selectedOnPage.length > 0;
    }

    public onCheckboxChange(value: boolean) {
        if (value) {
            this.sendAllIssuesCheckEvent('header-checkbox');
        } else {
            amplitudeLog(AmplitudeEvent.itIssueUnCheck, { method: 'header-checkbox' });
        }
    }

    public selectCurrentPage() {
        this.sendAllIssuesCheckEvent('header-menu');
        this.$store.commit('multiSelectAllIssues', _.uniqBy([...this.selected, ...this.issues], 'id'));
    }

    public unselectCurrentPage() {
        amplitudeLog(AmplitudeEvent.itIssueUnCheck, { method: 'header-menu' });
        this.$store.commit('multiSelectAllIssues', _.xorBy(this.selected, this.selectedOnPage, 'id'));
    }

    public unselectAll() {
        amplitudeLog(AmplitudeEvent.itIssueUnCheck, { method: 'header-menu' });
        this.$store.dispatch('clearMultiSelect');
    }

    public async sendAllIssuesCheckEvent(method: string) {
        // We need to wait untill issues are actually checked.
        await this.$nextTick();
        amplitudeLog(AmplitudeEvent.itIssueCheck, { method, count: this.selectedOnPage });
    }
}
