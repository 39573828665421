export const UK2 = [
    {
        'uuid': '149e7538-e0b6-4699-ae1a-296e443df57d',
        'title': 'Architectural Clash',
        'fields': '{"stampAbbr":"ACL","visibility":1}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'c5f8cd5e-b1f9-4764-92d3-0c82d9691591',
        'nodeRole': 1,
    },
    {
        'uuid': '2e3adac9-a1e9-4d42-b080-b6aa798409b5',
        'title': 'Architecture Drawing Review',
        'fields': '{"deadline":"2000-01-01 00:00:00","priority":"minor","stampAbbr":"ADR","stampColor":7,"visibility":1,"deadline_offset":{"units":0,"value":7}}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '02f82a03-c449-408b-bee2-e26f53bec391',
        'nodeRole': 1,
    },
    {
        'uuid': '2d47456f-f1ca-4c8a-96ed-83ba300551ca',
        'title': 'Architectural Error',
        'fields': '{"stampAbbr":"AE"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'c5f8cd5e-b1f9-4764-92d3-0c82d9691591',
        'nodeRole': 1,
    },
    {
        'uuid': 'bcaa1b87-0fd7-428a-b429-15286d4ccff4',
        'title': 'Architectural Query',
        'fields': '{"stampAbbr":"AQ"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'c5f8cd5e-b1f9-4764-92d3-0c82d9691591',
        'nodeRole': 1,
    },
    {
        'uuid': 'c011e332-5aa4-4fb3-96f7-e80573caf827',
        'title': 'Civil Clash',
        'fields': '{"stampAbbr":"CCL"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '1276dadb-46fc-4836-a0d7-40a909db066d',
        'nodeRole': 1,
    },
    {
        'uuid': '80b7d370-73a9-4a76-a1af-68039d5fa280',
        'title': 'Civil Error',
        'fields': '{"stampAbbr":"CE"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '1276dadb-46fc-4836-a0d7-40a909db066d',
        'nodeRole': 1,
    },
    {
        'uuid': '8998946e-855f-4c3a-91f8-9c042d60f5be',
        'title': 'Civil Query',
        'fields': '{"stampAbbr":"CQ"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '1276dadb-46fc-4836-a0d7-40a909db066d',
        'nodeRole': 1,
    },
    {
        'uuid': 'f7095727-2316-457f-8d87-7221a0b779af',
        'title': 'Civil Drawing Review',
        'fields': '{"stampAbbr":"DRC","stampColor":3}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '02f82a03-c449-408b-bee2-e26f53bec391',
        'nodeRole': 1,
    },
    {
        'uuid': 'f06ead84-a14f-4dfe-bb91-bf3348b6f7fb',
        'title': 'Electrical Drawing Review',
        'fields': '{"stampAbbr":"DRE","stampColor":5}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '02f82a03-c449-408b-bee2-e26f53bec391',
        'nodeRole': 1,
    },
    {
        'uuid': 'fe51ae04-2ac3-4221-824c-e2794abd3673',
        'title': 'Mechanical Drawing Review',
        'fields': '{"stampAbbr":"DRM","stampColor":2}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '02f82a03-c449-408b-bee2-e26f53bec391',
        'nodeRole': 1,
    },
    {
        'uuid': 'a762012f-af3b-43fd-8ff0-902ff4088d56',
        'title': 'Structure Drawing Review',
        'fields': '{"stampAbbr":"DRS","stampColor":1}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '02f82a03-c449-408b-bee2-e26f53bec391',
        'nodeRole': 1,
    },
    {
        'uuid': '9b2c85f9-eac8-43a0-8fde-a223030c5af2',
        'title': 'Electrical Error',
        'fields': '{"stampAbbr":"EE"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '1890f024-0ad6-4209-b744-4e159ef4bab9',
        'nodeRole': 1,
    },
    {
        'uuid': 'f3614a5c-7cae-47c1-a2a4-9c9493ae330a',
        'title': 'Electrical Query',
        'fields': '{"stampAbbr":"EQ"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '1890f024-0ad6-4209-b744-4e159ef4bab9',
        'nodeRole': 1,
    },
    {
        'uuid': 'c68ca87b-f46b-413b-aefd-0b92c8c06827',
        'title': 'Hazard identified',
        'fields': '{"stampAbbr":"HAZ"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '10180b3e-9ba9-4dc6-a6c7-bd0bffe1365c',
        'nodeRole': 1,
    },
    {
        'uuid': 'a7a7e8db-3a55-4361-b900-46ec52194c3a',
        'title': 'Landscape Clash',
        'fields': '{"stampAbbr":"LCL"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'd1e94214-5e91-4fc4-ab7a-4197702a04d7',
        'nodeRole': 1,
    },
    {
        'uuid': '2d0c416d-2653-4bc0-b79d-fa533900ad29',
        'title': 'Landscape Error',
        'fields': '{"stampAbbr":"LE"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'd1e94214-5e91-4fc4-ab7a-4197702a04d7',
        'nodeRole': 1,
    },
    {
        'uuid': '578e4334-9643-4e50-87e0-965ef91b20bc',
        'title': 'Landscape Query',
        'fields': '{"stampAbbr":"LQ"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'd1e94214-5e91-4fc4-ab7a-4197702a04d7',
        'nodeRole': 1,
    },
    {
        'uuid': '049d37ce-fb47-48f2-b0ff-6899ded49057',
        'title': 'Mechanical Clash',
        'fields': '{"stampAbbr":"MC"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'acdd37a5-9b47-4331-bd34-801864ec7f20',
        'nodeRole': 1,
    },
    {
        'uuid': 'd63ec375-9b94-43ac-b57c-af8d23935934',
        'title': 'Electrical Clash',
        'fields': '{"stampAbbr":"MCL"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': '1890f024-0ad6-4209-b744-4e159ef4bab9',
        'nodeRole': 1,
    },
    {
        'uuid': 'ef6212b9-4914-429b-845a-789a1793e74e',
        'title': 'Mechanical Error',
        'fields': '{"stampAbbr":"ME"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'acdd37a5-9b47-4331-bd34-801864ec7f20',
        'nodeRole': 1,
    },
    {
        'uuid': 'c27ba5a7-0d5c-46f1-8f5f-7b03e3355a9c',
        'title': 'Mechanical Query',
        'fields': '{"stampAbbr":"MQ"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'acdd37a5-9b47-4331-bd34-801864ec7f20',
        'nodeRole': 1,
    },
    {
        'uuid': '2acdd458-602a-4de3-bca1-7a875ebbdeef',
        'title': 'Structural Clash',
        'fields': '{"stampAbbr":"SCL","visibility":0}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'f23dc52d-526a-48f4-bf7b-b6e31a5603e6',
        'nodeRole': 1,
    },
    {
        'uuid': 'd2f425a6-d562-40d1-96fa-a3c75dad5410',
        'title': 'Structural Error',
        'fields': '{"stampAbbr":"SE"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'f23dc52d-526a-48f4-bf7b-b6e31a5603e6',
        'nodeRole': 1,
    },
    {
        'uuid': '424475ac-92dd-4db8-8215-d3d14005c605',
        'title': 'Structural Query',
        'fields': '{"stampAbbr":"SQ"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-08-09 10:52:31',
        'parentUuid': 'f23dc52d-526a-48f4-bf7b-b6e31a5603e6',
        'nodeRole': 1,
    },
    {
        'uuid': '02f82a03-c449-408b-bee2-e26f53bec391',
        'title': '1.1 Drawing Review',
        'fields': '{"priority":"major"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-07-19 16:49:57',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'c5f8cd5e-b1f9-4764-92d3-0c82d9691591',
        'title': '2.1 Architecture',
        'fields': '{"priority":"major","stampColor":7}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-07-19 16:50:01',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'd1e94214-5e91-4fc4-ab7a-4197702a04d7',
        'title': '2.2 Landscape Architecture',
        'fields': '{"priority":"major","stampColor":4}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-07-19 16:50:07',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'f23dc52d-526a-48f4-bf7b-b6e31a5603e6',
        'title': '2.3 Structure',
        'fields': '{"priority":"major"}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-07-19 16:50:12',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'acdd37a5-9b47-4331-bd34-801864ec7f20',
        'title': '2.4 Mechanical',
        'fields': '{"priority":"major","stampColor":2}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-07-19 16:50:16',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '1890f024-0ad6-4209-b744-4e159ef4bab9',
        'title': '2.5 Electrical',
        'fields': '{"priority":"major","stampColor":5}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-07-19 16:50:20',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '1276dadb-46fc-4836-a0d7-40a909db066d',
        'title': '2.6 Civil',
        'fields': '{"priority":"major","stampColor":3}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-07-19 16:50:23',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '10180b3e-9ba9-4dc6-a6c7-bd0bffe1365c',
        'title': '3.1 Health and Safety',
        'fields': '{"priority":"critical","stampColor":1}',
        'created': '2023-06-09 12:33:40',
        'updated': '2023-07-19 16:50:27',
        'parentUuid': null,
        'nodeRole': 2,
    },
];
