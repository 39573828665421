import SupportApi from '@/api/support.api';
import { notificationSuccess } from '@/services';

export interface IFeedbackForm {
    id: string;
    message: string;
}
export interface IUserDataToSend {
    email: string;
    name: string;
}
export interface IFeedbackPayload extends IFeedbackForm, IUserDataToSend {}

interface ISupportStorage {
    isFeedbackSending: boolean;
    isFeedbackSuccessSent: boolean;
    feedbackSendingError: string;
}

export default {
    state: {
        isFeedbackSending: false,
        isFeedbackSuccessSent: false,
        feedbackSendingError: '',
    },
    getters: {
        isFeedbackSuccessSent(state: ISupportStorage) {
            return state.isFeedbackSuccessSent;
        },
        isFeedbackSending(state: ISupportStorage) {
            return state.isFeedbackSending;
        },
    },
    mutations: {
        setIsFeedbackSuccessSent(state: ISupportStorage, bool: boolean) {
            state.isFeedbackSuccessSent = bool;
        },
        setIsFeedbackSending(state: ISupportStorage, bool: boolean) {
            state.isFeedbackSending = bool;
        },
        setFeedbackSendingError(state: ISupportStorage, value: string) {
            state.feedbackSendingError = value;
        },
    },
    actions: {
        sendFeedback({ commit }: any, feedback: IFeedbackPayload) {
            commit('setIsFeedbackSending', true);
            return SupportApi.postFeedback(feedback).then(() => {
                commit('setIsFeedbackSuccessSent', true);
                notificationSuccess('messageSent');
            }).catch((error) => {
                commit('setFeedbackSendingError', error.message);
            }).finally(() => {
                commit('setIsFeedbackSending', false);
            });
        },
    },
};
