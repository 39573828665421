


















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { DEADLINE_NOT_SET, IssueTrackerFields, StampColorsPaletteRGBEnum } from '@/constants';
import { IDiffItem, IssueTrackerDiffInlinePropertiesType } from '@/models';
import { dateFormatter } from '@/services/MomentFormats';
import IssueStatusText from '@/components/project/issueTracker/IssueStatusText.vue';
import IssueCommentDiffChanged from '@/components/project/issueTracker/comments/IssueCommentDiffChanged.vue';
import IssuePriorityText from '@/components/project/issueTracker/IssuePriorityText.vue';
import WsMemberName from '@/components/common/WsMemberName.vue';
import WsSelectColorItem from '@/components/common/WsSelectColorItem.vue';

@Component({
    components: {
        IssueCommentDiffChanged,
        IssueStatusText,
        IssuePriorityText,
        WsMemberName,
        WsSelectColorItem,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueCommentDiffInline extends Vue {
    @Prop({ required: true }) public entity!: IssueTrackerDiffInlinePropertiesType;
    @Prop({ required: true }) public diff!: IDiffItem;

    public readonly StampColorsPaletteRGBEnum = StampColorsPaletteRGBEnum;
    public readonly IssueTrackerFields = IssueTrackerFields

    @Emit()
    public click() {
        return;
    }

    public localDateFormatter(date: string) {
        return date === DEADLINE_NOT_SET ? this.$t('Simple_word.none') : dateFormatter(date);
    }
}
