











import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { IDiffItem } from '@/models';

@Component
export default class IssueCommentDeletedAt extends Vue {
    @Prop({ required: true }) public diff!: Dict<IDiffItem>;

    get isDelete() {
        return Boolean(this.diff.new);
    }
}
