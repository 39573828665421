export enum MutableIssueField {
    customType = 'customType',
    customStatus = 'customStatus',
    compareSheet = 'compareSheet',
    cdeLinks = 'cdeLinks',
    priority = 'priority',
    assignee = 'assignee',
    reporter = 'reporter',
    visibility = 'visibility',
    status = 'status',
    tags = 'tags',
    watchers = 'watchers',
    stampAbbr = 'stampAbbr',
    stampColor = 'stampColor',
    deadline = 'deadline',
    statusAuto = 'statusAuto',
    created = 'created',
    title = 'title',
    procore = 'procore',
}
