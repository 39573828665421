




































import { Component, Prop } from 'vue-property-decorator';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-textarea/#props

@Component
export default class WsTextarea extends VuetifyElement {
    @Prop({ default: 5 }) public rows!: number | string;
    @Prop({ type: Boolean, default: false }) public autoGrow!: boolean;
    @Prop({ type: Boolean, default: false }) public autofocus!: boolean;
    @Prop({ type: Boolean, default: false }) public new!: boolean;

    get isNew() {
        return this.new;
    }
}
