




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WsLoaderDotsInline extends Vue {
    @Prop({ type: Number, default: 1 }) public start!: number;
    @Prop({ type: Number, default: 3 }) public max!: number; // должно быть больше старта
    @Prop({ type: Number, default: 1000 }) public updateSpeed!: number; // скорость появления точек ms

    public length: number = 0;

    get dots() {
        return new Array(this.length + 1).join('.');
    }

    public updateDots() {
        this.length++;
        if (this.length > this.max) {
            this.length = this.start;
        }
    }

    public created() {
        if (this.start >= this.max) {
            throw Error('Incorrect params: start, max');
        }
        this.length = this.start;
        setInterval(this.updateDots, this.updateSpeed);
    }
}
