import { Location } from 'vue-router';
import VueI18n from 'vue-i18n';
import { RouterNamesT } from '@/constants';

export interface ITopMenuItem {
    to: Location;
    icon: string;
    text: VueI18n.TranslateResult;
    notification?: number;
    nestFor?: RouterNamesT[];
}

export class TopMenuItem {
    public to: Location;
    public icon: string;
    public text: VueI18n.TranslateResult;
    public notification?: number;
    public nestFor: RouterNamesT[]; // роут считается активным если мы находимся на одном из этих роутов

    constructor(props: ITopMenuItem) {
        this.to = props.to;
        this.icon = props.icon;
        this.text = props.text;
        this.notification = props.notification;
        this.nestFor = props.nestFor || [];
    }

}
