import _ from 'lodash';
import moment from 'moment/moment';
import { EventTypeEnum, RESPONSE } from '@/constants';
import NotificationApi from '@/api/notification.api';
import {
    Notification,
    NotificationGroup,
    NotificationRecord,
    NotificationRecordStatus,
    NotificationRecordType,
} from '@/models';
import { getOperationId, i18n, sortArrayOfObjects } from '@/services';

export default {
    state: {
        localNotificationRecords: [],
        notificationGroups: [],
    },
    getters: {
        notificationRecords(state: any, getters: any, rootState: any, rootGetters: any) {
            const apiRecords = getters.notificationGroups
                .flatMap(NotificationRecord.createArrayFromNotificationGroup)
                .filter(Boolean); // both from REST and notifier
            const records = _.unionBy<NotificationRecord>(state.localNotificationRecords, apiRecords, 'operationId');
            const filteredRecords = records.filter((record) => record.licenseUuid === rootGetters.currentLicense.uuid);
            return sortArrayOfObjects(filteredRecords, 'timestamp');
        },
        notificationGroups(state: any): NotificationGroup[] {
            return state.notificationGroups;
        },
        notificationGroupByNotificationUuid(state: any) {
            const pairs = state.notificationGroups.flatMap((group: NotificationGroup) => {
                return group.notifications.map(({ uuid }: Notification) => [uuid, group]);
            });
            return _.fromPairs(pairs);
        },
        localNotificationRecordsByOperationId(state: any) {
            return _.keyBy(state.localNotificationRecords, 'operationId');
        },
    },
    mutations: {
        setNotificationGroups(state: any, value: NotificationGroup[]) {
            state.notificationGroups = value;
        },
        addNotificationGroups(state: any, value: NotificationGroup[]) {
            state.notificationGroups = [...state.notificationGroups, ...value];
        },
        addRecord(state: any, record: NotificationRecord) {
            // either create a new record or update an existing one
            for (let i = state.localNotificationRecords.length - 1; i >= 0; i--) {
                const aRecord = state.localNotificationRecords[i];
                if (record.operationId === aRecord.operationId) {
                    aRecord.data = [...aRecord.data, ...record.data];
                    return;
                }
            }
            state.localNotificationRecords = [record, ...state.localNotificationRecords];
        },
        addGroup(state: any, payload: { operationId: string, notification: any }) {
            for (let i = state.notificationGroups.length - 1; i >= 0; i--) {
                const aGroup = state.notificationGroups[i];
                if (aGroup.operationId === payload.operationId) {
                    const notification = payload.notification instanceof Notification ?
                        payload.notification :
                        Notification.instantiate(payload.notification);

                    aGroup.notifications.unshift(notification);

                    return;
                }
            }

            state.notificationGroups.unshift(new NotificationGroup({
                operationId: payload.operationId,
                notifications: [payload.notification],
            }));
        },
        markRecordsAsSuccess(state: any, commentUuids: string[]) {
            state.localNotificationRecords.forEach((record: NotificationRecord) => {
                record.data.forEach(((dataElement: any) => {
                    if (dataElement.comments?.some((comment: any) => commentUuids.includes(comment.uuid))) {
                        dataElement.status = NotificationRecordStatus.success;
                    }
                }));
            });
        },
        markRecordsAsError(state: any, payload: any) {
            state.localNotificationRecords.forEach((record: NotificationRecord) => {
                record.data.forEach((dataElement: any) => {
                    payload.every(({ uuid, errorCode, errorMsg }: any) => {
                        if (dataElement.comments?.some((comment: any) => comment.uuid === uuid)) {
                            dataElement.status = NotificationRecordStatus.error;
                            dataElement.errorCode = errorCode;
                            dataElement.errorMsg = errorMsg;
                            return false;
                        }
                        return true;
                    });
                });
            });
        },
        resolveRecordsInProgress(state: any, commentUuidsAll: string[]) {
            state.localNotificationRecords.forEach((record: NotificationRecord) => {
                record.data.forEach((dataElement: any) => {
                    if (dataElement.status === NotificationRecordStatus.in_progress) {
                        const commentUuids = dataElement.comments?.map((comment: any) => comment.uuid);
                        if (commentUuids?.some((uuid: string) => commentUuidsAll.includes(uuid))) {
                            dataElement.status = NotificationRecordStatus.error;
                            dataElement.errorCode = RESPONSE.INVALID_PARAMS;
                            dataElement.errorMsg = i18n.t('errors.unexpected');
                        }
                    }
                });
            });
        },
    },
    actions: {
        async getNotifications({ getters, commit }: any) {
            commit('setNotificationGroups', []);

            await (async function getNotifications(page: number = 0) {
                const response = await NotificationApi.getNotifications({ page });
                if (!response.entities.length) {
                    return;
                }
                const notificationGroups = response.entities.map(NotificationGroup.instantiate);
                commit('addNotificationGroups', notificationGroups);
                await getNotifications(page + 1);
            })();

            return getters.notificationGroups;
        },
        readNotificationUuids({ getters }: any, uuids: string[]) {
            uuids.forEach((uuid) => {
                const group = getters.notificationGroupByNotificationUuid[uuid];
                group?.readUuid(uuid);
                getters.localNotificationRecordsByOperationId[group.operationId]?.read();
            });
        },
        readGroups(context: any, groups: NotificationGroup[]) {
            const unreadGroups = groups.filter(({ isRead }) => !isRead);
            if (!unreadGroups.length) {
                return;
            }
            unreadGroups.forEach((group: NotificationGroup) => group.read());
            const uuids = getUuidsStringFromGroups(unreadGroups);
            return NotificationApi.markAsRead({ uuids });
        },
        readNotification({ getters, dispatch }: any, record: NotificationRecord) {
            record.read();
            const normalizeOperationId = (operationId: string) => operationId.split('_')[0];
            const groups = getters.notificationGroups
                .filter(({ operationId }: NotificationGroup) => operationId === normalizeOperationId(record.operationId));
            dispatch('readGroups', groups);
        },
        readAllNotifications({ getters, dispatch }: any) {
            dispatch('readGroups', getters.notificationGroups);
        },
        addNotificationRecordErrorUpdateIssue(
            { commit, rootGetters }: any,
            { issueId, projectId }: { issueId: string, projectId: string },
        ) {
            commit('addRecord', new NotificationRecord({
                isLocal: true,
                operationId: getOperationId(),
                licenseUuid: rootGetters.currentLicense.uuid,
                projectId,
                data: [{
                    status: NotificationRecordStatus.error,
                    issueId,
                    errorCode: RESPONSE.INVALID_PARAMS,
                    errorMsg: i18n.t('errors.unexpected'),
                }],
                type: NotificationRecordType.issue,
                eventType: EventTypeEnum.ISSUE_EDITED,
                authorEmail: rootGetters.userData.email,
                timestamp: moment().unix(),
            }));
        },
    },
};

function getUuidsStringFromGroups(groups: NotificationGroup[]) {
    const uuids = groups.flatMap((group: NotificationGroup) => {
        return group.notifications.map(({ uuid }) => uuid);
    });
    return JSON.stringify(uuids);
}
