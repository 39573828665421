import _ from 'lodash';
import Vue from 'vue';
import { Component, Emit } from 'vue-property-decorator';
import { Watch } from 'vue-property-decorator';
import { ITableSort } from '@/storage/tableSort.storage';

@Component
export default class TableSortMixin extends Vue {
    public tableSort: ITableSort = {} as ITableSort;

    get tableType(): string {
        return this.$route.name || this.$route.path as string;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    @Emit()
    public sortDesc() {
        return;
    }

    @Emit()
    public sortBy() {
        return;
    }

    @Watch('licenseId', { deep: true, immediate: true })
    public onLicenseIdChange() {
       this.initTableSort();
    }

    public created() {
        this.saveTableSort = _.debounce(this.saveTableSort, 50);
        this.initTableSort();
    }

    public initTableSort() {
        this.tableSort = (this.$store.getters.tableSortById[this.licenseId] || {})[this.tableType] || {};
    }

    public handleUpdateSortBy(value: string | undefined): void {
        this.sortBy();
        this.tableSort = { ...this.tableSort, by: value };
        this.saveTableSort();
    }
    public handleUpdateSortDesc(value: boolean | undefined): void {
        this.sortDesc();
        this.tableSort = { ...this.tableSort, desc: value };
        this.saveTableSort();
    }

    public saveTableSort(): void {
        this.$store.commit('saveTableSort', {
            licenseId: this.licenseId,
            tableType: this.tableType,
            tableSort: this.tableSort,
        });
    }
}
