import { render, staticRenderFns } from "./DialogMultiEditWatchers.vue?vue&type=template&id=39076437&scoped=true&"
import script from "./DialogMultiEditWatchers.vue?vue&type=script&lang=ts&"
export * from "./DialogMultiEditWatchers.vue?vue&type=script&lang=ts&"
import style0 from "./DialogMultiEditWatchers.vue?vue&type=style&index=0&id=39076437&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39076437",
  null
  
)

export default component.exports