export enum MemberStatusEnum {
    active = 1,
    outer = 2,
    frozen = 4,
    deactivated = 5,
    pending = 6,
    pendingApproval = 7,
}

export const memberPendingStatusesArr = [MemberStatusEnum.pending, MemberStatusEnum.pendingApproval];
