










































































































































































import _ from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { AmplitudeEvent, RouterNames } from '@/constants';
import { License, RecentProject } from '@/models';
import { truncateString } from '@/services';
import { amplitudeMixin } from '@/mixins';
import HeaderSelect from '@/components/header/HeaderSelect.vue';
import LoginMergeForm from '@/components/user/LoginMergeForm.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    components: {
        HeaderSelect,
        LoginMergeForm,
        WsDialog,
    },
    methods: {
        truncateString,
    },
})
export default class NavMenu extends amplitudeMixin {
    @Prop(String) public mode!: string;

    public isLoginToLicenseNeeded = false;
    public nonAuthLicense: License | null = null;
    public RouterNames = RouterNames;

    get language(): string {
        return this.$route.params.language;
    }
    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }
    get license(): License {
        return this.$store.getters.currentLicense;
    }
    get currentUserId() {
        return this.$store.getters.userData.id;
    }
    get regionId() {
        return this.$store.getters.regionId;
    }
    get recentProjects(): RecentProject[] {
        return this.$store.getters.recentProjects(this.licenseId, this.currentUserId, this.regionId);
    }
    get licensesCanView(): License[] {
        return this.orderLicenses(this.$store.getters.licensesCanView);
    }
    get activeLicenses(): License[] {
        return this.licensesCanView.filter((license: License) => !license.frozen);
    }
    get frozenLicenses(): License[] {
        return this.licensesCanView.filter((license: License) => license.frozen);
    }
    get isLoadingLicenses(): boolean {
        return this.$store.getters.isLoadingLicenses;
    }
    get currentCategoryTool() {
        const routeName = this.$route.name || '';
        switch (routeName) {
            case RouterNames.LicenseSettings:
                return this.license.isAdminRights ? this.$t('Menu.licenseManagement') : this.$t('Menu.tools');
            case RouterNames.LicenseMembers:
            case RouterNames.LicenseMembersApproval:
            case RouterNames.LicenseMembersAuthMethods:
            case RouterNames.LicenseMembersImportUsers:
            case RouterNames.LicenseActivity:
            case RouterNames.LicenseMembersAdditionalFields:
            case RouterNames.LicenseProjects:
            case RouterNames.ProjectAccessRole:
            case RouterNames.ProjectRoles:
            case RouterNames.ActionLog:
                return this.$t('Menu.licenseManagement');
            default:
                return this.$t('Menu.tools');
        }
    }
    get currentTool() {
        const routeName = this.$route.name || '';

        switch (routeName) {
            case RouterNames.MyLicenseProjects:
            case RouterNames.MyLicenseProjectsArchived:
                return this.$t('Menu.projects');
            case RouterNames.LicenseDashboard:
            case RouterNames.LicenseDashboards:
                return this.$t('Menu.licenseDashboard');
            case RouterNames.LicenseSettings:
                return this.$t('Menu.licenseInfo');
            case RouterNames.LicenseMembers:
            case RouterNames.LicenseMembersApproval:
            case RouterNames.LicenseMembersAuthMethods:
            case RouterNames.LicenseMembersImportUsers:
            case RouterNames.LicenseActivity:
            case RouterNames.LicenseMembersAdditionalFields:
                return this.$t('Menu.userManagement');
            case RouterNames.LicenseProjects:
                return this.$t('Menu.project');
            case RouterNames.ProjectAccessRole:
                return this.$t('Menu.projectRoles');
            case RouterNames.ProjectRoles:
                return this.$t('Menu.projectRoles');
            case RouterNames.ReportingHistory:
                return this.$t('Menu.reportingHistory');
            case RouterNames.ActionLog:
                return this.$t('Menu.actionLog');
            default:
                return this.$t('Menu.selectTool');
        }
    }

    get sizeForLicense() {
        return `${this.$store.getters.headerFreeSpace - 50}px`;
    }

    public clickTools(isClickOpen: boolean) {
        if (isClickOpen) {
            this.amplitudeLog(AmplitudeEvent.navbarOpenToolsMenu);
        }
    }

    public onNotAuthLicenseClick(nonAuthLicense: License) {
        this.nonAuthLicense = nonAuthLicense;
        this.isLoginToLicenseNeeded = true;
    }

    public orderLicenses(licenses: any): License[] {
        return _.sortBy(licenses, ['frozen', 'name']);
    }

    public trackRecentProject(recentProject: RecentProject) {
        this.amplitudeLog(AmplitudeEvent.recentProjectOpen, { recentProjectUuid: recentProject.uuid });
    }

    public setToolSize(size: number) {
        this.$store.commit('setHeaderComponentSize', { name: 'tool', size });
    }
}
