

























































import { Component } from 'vue-property-decorator';
import { TRulesObj } from '@/types/TRulesObj';
import { AmplitudeEvent, RESPONSE, RouterNames } from '@/constants';
import { changePasswordErrorMessage, notificationError, notificationSuccess } from '@/services';
import { amplitudeMixin } from '@/mixins';
import WsSelect from '@/components/common/WsSelect.vue';
import WsInput from '@/components/common/WsInput.vue';
import PasswordMustContain from '@/components/user/PasswordMustContain.vue';
import ReturnToLoginButton from '@/components/user/ReturnToLoginButton.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        PasswordMustContain,
        ReturnToLoginButton,
        WsButton,
        WsSelect,
        WsInput,
    },
})

export default class ChangePasswordForm extends amplitudeMixin {
    public readonly RouterNames = RouterNames;

    public changePasswordParams = {
        key: '',
    };
    public isSending = false;
    public error = '';

    public isValidNewPassword = false;
    public isPasswordReset = false;

    public isCheckingExpired = false;
    public isExpired = false;

    public password = {
        newPassword: '',
        confirmPassword: '',
    };

    get form(): HTMLFormElement {
        return this.$refs.userSettingsFormRef as HTMLFormElement;
    }

    get passwordFormRules(): TRulesObj {
        return {
            newPassword: [
                () => this.isValidNewPassword,
            ],
            confirmPassword: [
                (value: string) => this.password.newPassword.trim() === value.trim() || this.$t('errors.pass_dont_match'),
            ],
        };
    }

    public created() {
        this.changePasswordParams = {
            key: this.$route.params.key,
        };
        this.amplitudeLog(AmplitudeEvent.loginRestoreAccessPageFromEmail);
        this.checkExpired();
    }

    public beforeDestroy() {
        this.$store.commit('setAuthError');
    }

    public checkExpired() {
        this.isCheckingExpired = true;
        this.$store.dispatch('checkSNPKey', {
            key: this.changePasswordParams.key,
        }).catch((error) => {
            if (error?.result === RESPONSE.EXPIRED_CODE) {
                this.isExpired = true;
            }
        }).finally(() => {
            this.isCheckingExpired = false;
        });
    }

    public async action() {
        if (this.form.validate()) {
            if (this.isPasswordReset) {
                this.$router.replace({ name: RouterNames.Login });
                return;
            }
            this.saveNewPassword();
        }
    }

    public onNewPasswordValidation(isValidNewPassword: boolean): void {
        this.isValidNewPassword = isValidNewPassword;
    }

    public saveNewPassword() {
        if (!this.form.validate()) {
            return;
        }

        this.$store.dispatch('setForgottenPassword', {
            key: this.changePasswordParams.key,
            newpass: this.password.newPassword.trim(),
        }).then(() => {
            notificationSuccess('passwordChanged');
            this.isPasswordReset = true;
        }).catch((error) => {
            if (error?.result === RESPONSE.EXPIRED_CODE) {
                this.isExpired = true;
                return;
            }
            notificationError({
                title: '',
                text: changePasswordErrorMessage(error),
            });
        }).finally(() => {
            this.isSending = false;
        });
    }
}
