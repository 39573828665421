





















import _ from 'lodash';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { TooltipPositions } from '@/constants';
import { isOverflownElementHorizontally } from '@/services/isOverflownElement';
import { sanitizeHtml } from '@/services/Sanitizer';
import WsTooltip from '@/components/common/WsTooltip.vue';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

@Component({
    computed: {
        TooltipPositions() {
            return TooltipPositions;
        },
    },
    components: {
        WsTooltip,
    },
})
export default class WsTruncateAuto extends EventListenersBase {
    @Prop({ required: true }) public value!: string;
    @Prop({ type: String }) public cssClass!: string;
    @Prop({ default: 200 }) public limit!: number;
    @Prop({ type: Number }) public maxWidth!: number;
    @Prop({ default: false }) public isForce!: boolean;
    @Prop({ default: null }) public tooltip!: string | null;

    @Ref() public readonly activator!: HTMLElement;

    public readonly sanitizeHtml = sanitizeHtml;
    public truncated = false;

    get parentEl() {
        return this.$el.parentNode as HTMLElement;
    }

    public created() {
        this.setTruncated = _.debounce(this.setTruncated, this.limit);
        this.eventListeners.add({ event: 'resize', handler: this.setTruncated });
    }

    public mounted() {
        const isVisibleOverflow = this.getCss(this.parentEl, 'overflow') === 'visible';
        if (isVisibleOverflow) {
            this.parentEl.style.overflow = 'auto';
        }
        this.setTruncated();
    }

    public updated() {
        this.setTruncated();
    }

    public setTruncated() {
        if (!this.isForce) {
            this.truncated = isOverflownElementHorizontally(this.activator);
        } else {
            this.truncated = true;
        }
    }

    public beforeDestroy() {
        this.parentEl.style.removeProperty('overflow');
    }

    public getCss(element: Element, attr: string): string {
        const css = document.defaultView?.getComputedStyle(element, null);
        const cssKey = attr as keyof CSSStyleDeclaration;
        return css?.[cssKey] as string;
    }
}
