








import { Component, Prop, Vue } from 'vue-property-decorator';
import VueMarkdown from 'vue-markdown';
import { sanitizeHtml } from '@/services';
// @ts-ignore

// SOURCE https://www.npmjs.com/package/vue-markdown#props
@Component({
    components: {
        VueMarkdown,
    },
})
export default class WsMarkdownText extends Vue {
    @Prop({ required: true }) public value!: string;
    @Prop({ default: () => ({ target: '_blank', rel: 'noopener noreferrer' }) }) public anchorAttributes!: string;

    get sanitizedValue() {
        return sanitizeHtml(this.value);
    }
}
