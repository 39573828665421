import _ from 'lodash';
import Vue from 'vue';
import { IIssueFilter } from '@/types/IIssueFilter';
import { TUuid } from '@/types/common';
import { Dict } from '@/types/Dict';
import {
    allIssuesGuid,
    allIssuesPresetUuid,
    AllSettledResult,
    BrokenFilterEnum,
    BrokenPresetEnum,
    currentIssuesGuid,
    currentIssuesPresetUuid,
    CustomStatusCategory,
    deletedIssuesPresetUuid,
    IssueFilterExpr,
    IssuesFilterType,
    IssueSortBase,
    NotifierUserID,
} from '@/constants';
import { MapOfStatusCategoryToLegacyStatusesName } from '@/constants/MapOfStatusCategoryToLegacyStatuses';
import ProjectApi from '@/api/project.api';
import {
    CustomStatus,
    IssueFilterPreset,
    IssuesFilter,
    IssuesFilterConstructor,
    Project,
} from '@/models';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import {
    convertDesktopFilterSetToTrackerFilters,
    i18n,
    IssueFilterSetOrder,
    NormalizeStatus,
    Protobuf,
} from '@/services';
import { IssueTrackerFilterValue } from '@/services/issueTracker/IssueTrackerFilterValue';
const MaxUnreadPresetsPerRequest = 5;

const AllIssuesPreset = new IssueFilterPreset({
    title: i18n.t('IssueTracker.presets.allIssues'),
    uuid: allIssuesPresetUuid,
    isAllowShowCounts: true,
});

export interface IIssueFilterPresetUnreadStat {
    uuid: TUuid,
    unread: number
    total?: number
}

const ALlIssuesPresetUnreadStat: IIssueFilterPresetUnreadStat = {
    uuid: allIssuesPresetUuid,
    unread: 0,
};

interface IIssueFilterPresetState {
    issueFilterPresetsObj: Dict<IssueFilterPreset[]>;
    issueFilterPresetsUnread: Dict<IIssueFilterPresetUnreadStat>;
    issueFilterPresetsOrderObj: Dict<any[]>;
    isLoadingIssueFilterPresetsObj: Dict<boolean>;
    issueFilterPresetsRequestsObj: Dict<Promise<IssueFilterPreset[]>>;
    selectedIssueFilterPresetsObj: Dict<IssueFilterPreset>;
}

interface IIssueFilterPresetStorage {
    state: IIssueFilterPresetState;
    getters: any;
    commit: any;
    dispatch: any;
    rootGetters: any;
}

export default {
    state: {
        issueFilterPresetsObj: {},
        issueFilterPresetsUnread: {},
        issueFilterPresetsOrderObj: {},
        isLoadingIssueFilterPresetsObj: {},
        issueFilterPresetsRequestsObj: {},
        selectedIssueFilterPresetsObj: {},
    } as IIssueFilterPresetState,
    getters: {
        issueFilterPresetsByProjectId(state: IIssueFilterPresetState): (projectId: number) => IssueFilterPreset[] {
            return (projectId) => state.issueFilterPresetsObj[projectId] || [];
        },
        getIssueFilterPresetByUuid(state: IIssueFilterPresetState): (projectId: number, presetUuid: TUuid) => IssueFilterPreset | undefined {
            return (projectId, presetUuid) => (state.issueFilterPresetsObj[projectId] || [])
                .find((preset: IssueFilterPreset) => preset.uuid === presetUuid);
        },
        issueFilterPresetsUnreadByProjectId(state: IIssueFilterPresetState): (projectId: number) => IIssueFilterPresetUnreadStat {
            return (projectId) => state.issueFilterPresetsUnread[projectId] || { [allIssuesPresetUuid]: ALlIssuesPresetUnreadStat };
        },
        selectedIssueFilterPresetByProjectId(state: IIssueFilterPresetState, getters: any): (projectId: number) => IssueFilterPreset {
            return (projectId) => state.selectedIssueFilterPresetsObj[projectId] || getters.issueFilterPresetsByProjectId(projectId)[0];
        },
        isLoadingIssueFilterPresets(state: IIssueFilterPresetState): (projectId: number) => boolean {
            return (projectId) => Boolean(state.isLoadingIssueFilterPresetsObj[projectId]);
        },
        issueFilterPresetsRequest(state: IIssueFilterPresetState): (projectId: number) => Promise<IssueFilterPreset[]> {
            return (projectId) => state.issueFilterPresetsRequestsObj[projectId];
        },
        findPresetByFilters(state: IIssueFilterPresetState): (projectId: number, filters: TrackerFilters) => IssueFilterPreset | undefined {
            return (projectId, filters) => (state.issueFilterPresetsObj[projectId] || [])
                .find((preset: IssueFilterPreset) => _.isEqual(preset.trackerFilters, filters));
        },
    },
    mutations: {
        setIssueFilterPresets(state: IIssueFilterPresetState, { projectId, issueFilterPresets }: { projectId: number, issueFilterPresets: IssueFilterPreset[] }) {
            Vue.set(state.issueFilterPresetsObj, projectId, issueFilterPresets);
        },
        setSelectedIssueFilterPreset(state: IIssueFilterPresetState, { projectId, issueFilterPreset }: { projectId: number, issueFilterPreset: IssueFilterPreset }) {
            Vue.set(state.selectedIssueFilterPresetsObj, projectId, issueFilterPreset);
        },
        clearSelectedIssueFilterPreset(state: IIssueFilterPresetState, { projectId }: { projectId: number, issueFilterPreset: IssueFilterPreset }) {
            const allIssuesPreset = state.issueFilterPresetsObj[projectId]
                ?.find(({ title }: { title: string }) => title === i18n.t('IssueTracker.presets.allIssues'));

            Vue.set(state.selectedIssueFilterPresetsObj, projectId, allIssuesPreset);
        },
        setIssueFilterPresetsOrder(state: IIssueFilterPresetState, { projectId, order }: { projectId: number, order: any[] }) {
            Vue.set(state.issueFilterPresetsOrderObj, projectId, order);
        },
        setIssueFilterPresetsUnread(state: IIssueFilterPresetState, { projectId, unread }: { projectId: number, unread: any }) {
            Vue.set(state.issueFilterPresetsUnread, projectId, unread);
        },
        setIsLoadingIssueFilterPresets(state: IIssueFilterPresetState, { projectId, value }: { projectId: number, value: boolean }) {
            Vue.set(state.isLoadingIssueFilterPresetsObj, projectId, value);
        },
        setIssueFilterPresetsRequest(state: IIssueFilterPresetState, { projectId, request }: { projectId: number, request: Promise<IssueFilterPreset[]> }) {
            Vue.set(state.issueFilterPresetsRequestsObj, projectId, request);
        },
        removeIssueFilterPresetsRequest(state: IIssueFilterPresetState, { projectId }: { projectId: number }) {
            Vue.delete(state.issueFilterPresetsRequestsObj, projectId);
        },
    },
    actions: {
        async saveFiltersAsPreset(
            { getters, dispatch, rootGetters }: IIssueFilterPresetStorage,
            { projectId, isNew, title, uuid, visibilityNumber, isSendVisibilityRequest }:
                { projectId: number, isNew: boolean, title: string, uuid: string, visibilityNumber: number, isSendVisibilityRequest: boolean },
        ) {
            const filters: TrackerFilters = _.cloneDeep(getters.trackerFiltersByProjectId(projectId));

            const filterByCustomStatus = filters.customStatus as IssueTrackerFilterValue;
            if (filterByCustomStatus.selections.length) {
                // need to add filter by legacy statuses for backward compatibility
                const selections = filterByCustomStatus.selections as string[];
                const project = rootGetters.projectById(projectId) as Project;
                const customStatuses: CustomStatus[] = rootGetters.customStatusesByProjectUuid(project.uuid);

                const convertedSelections = selections.map((selection: string) => {
                    const customStatus = customStatuses.find((status) => status.name === selection);

                    if (!customStatus) {
                        return;
                    }

                    const defaultStatusVariant = NormalizeStatus.forward(customStatus.name);
                    if (defaultStatusVariant !== customStatus.name) {
                        return defaultStatusVariant;
                    }

                    return MapOfStatusCategoryToLegacyStatusesName[customStatus.category];
                });

                filters[IssuesFilterType.status] = new IssueTrackerFilterValue(
                    IssuesFilterType.status,
                    filterByCustomStatus.modifier,
                    convertedSelections,
                );
            }

            const filterByStatusCategory = filters.statusCategory as IssueTrackerFilterValue;
            if (filterByStatusCategory.selections.length && !filters.status) {
                const convertedSelections = (filterByStatusCategory.selections as string[])
                    .map((selection: string) => MapOfStatusCategoryToLegacyStatusesName[selection]);

                filters[IssuesFilterType.status] = new IssueTrackerFilterValue(
                    IssuesFilterType.status,
                    filterByStatusCategory.modifier,
                    convertedSelections,
                );
            }

            const filtersEncoded = Protobuf.encodeFilters(filters, rootGetters.fieldVariantsByProjectId(projectId), rootGetters.projectMembersByProjectId(projectId));

            const sorting = getters.selectedIssueFilterPresetByProjectId(projectId)?.sorting
               || getters.issuesSortByProjectId(projectId);
            const sortingEncoded = Protobuf.encodeSorting(sorting);

            const apiSaveRequest = isNew ? ProjectApi.postAddIssuesFilterSet : ProjectApi.postEditIssuesFilterSet;
            const visibilityObj = isNew ? { visibility: visibilityNumber } : {};
            const apiPromises = [];

            let newPresetRaw: any;

            apiPromises[0] = apiSaveRequest(projectId, [{
                [NotifierUserID]: rootGetters.notifierActorId,
                filters: filtersEncoded,
                title,
                uuid,
                sorting: sortingEncoded,
                ...visibilityObj,
            }]).then((result) => {
                newPresetRaw = result?.[0]?.data;
            });

            if (isSendVisibilityRequest) {
                apiPromises[1] = ProjectApi.postEditIssuesFilterSetVisibility(projectId, [{
                    uuid,
                    visibility: visibilityNumber,
                }]);
            }

            const currentPresets = getters.issueFilterPresetsByProjectId(projectId);

            const orderArr = currentPresets.map((preset: IssueFilterPreset, index: number) => ({
                guid: preset.uuid,
                Order: index,
            }));

            if (isNew) {
                orderArr.push({
                    guid: uuid,
                    Order: currentPresets.length,
                });
            }

            dispatch('reorderPresets', {
                projectId,
                orderArr,
            });

            await Promise.all(apiPromises);

            if (newPresetRaw) {
                try {
                    newPresetRaw.filters = Protobuf.decodeRules(newPresetRaw.filters).map((filter: any) => {
                        return IssuesFilterConstructor.fromProtobuf(filter, rootGetters.fieldVariantsByProjectId(projectId), rootGetters.projectMembersByProjectId(projectId));
                    });
                    return new IssueFilterPreset(newPresetRaw);
                } catch (e) {
                    window.console.error('Error while saving filters as preset', e);
                    return null;
                }
            }

            return null;
        },
        removePresets(context: IIssueFilterPresetStorage, { projectId, entities }: { projectId: number, entities: any[] }) {
            return ProjectApi.postRemoveIssueFilterSet(projectId, entities);
        },
        changePresetsVisibility(context: IIssueFilterPresetStorage, { projectId, entities }: { projectId: number, entities: any[] }) {
            return ProjectApi.postEditIssuesFilterSetVisibility(projectId, entities);
        },
        reorderPresets(context: IIssueFilterPresetStorage, { projectId, orderArr }: { projectId: number, orderArr: any[] }) {
            const orderAllIssues = { guid: allIssuesGuid, Order: 0 };
            const orderCurrentIssues = { guid: currentIssuesGuid, Order: 1 };
            const orderArrShifted = orderArr.map(({ guid, Order }) => ({ guid, Order: Order + 2 }));
            const orderArrWithFixed = [
                orderAllIssues,
                orderCurrentIssues,
                ...orderArrShifted,
            ].sort((itemA, itemB) => itemA.Order - itemB.Order);
            const order = Protobuf.encodeOrder(orderArrWithFixed);

            return ProjectApi.postReorderIssueFilterSets(projectId, order);
        },
        loadIssueFilterPresetsByProjectId(
            { state, commit, rootGetters }: IIssueFilterPresetStorage,
            { projectId, isForce = false }: { projectId: number, isForce: boolean },
        ) {
            const existCurrentRequest = state.issueFilterPresetsRequestsObj[projectId];
            if (!isForce && existCurrentRequest) {
                return existCurrentRequest;
            }

            const request = new Promise<IssueFilterPreset[]>((resolve, reject) => {
                if ((!isForce && state.issueFilterPresetsObj[projectId]) || state.isLoadingIssueFilterPresetsObj[projectId]) {
                    resolve(state.issueFilterPresetsObj[projectId]);
                    return;
                }

                commit('setIsLoadingIssueFilterPresets', { projectId, value: true });

                const currentIssuesPreset = new IssueFilterPreset({
                    title: i18n.t('IssueTracker.presets.currentIssues'),
                    uuid: currentIssuesPresetUuid,
                    filters: [
                        new IssuesFilter({
                            type: IssuesFilterType.statusCategory,
                            expr: IssueFilterExpr.NOT_IN,
                            value: [CustomStatusCategory.Completed],
                        }),
                    ],
                });

                const deletedIssuesPreset = new IssueFilterPreset({
                    title: i18n.t('IssueTracker.presets.deletedIssues'),
                    uuid: deletedIssuesPresetUuid,
                    filters: [
                        new IssuesFilter({
                            type: IssuesFilterType.deletedAt,
                            expr: IssueFilterExpr.EQUALS,
                            value: [1],
                        }),
                    ],
                });

                ProjectApi.getIssueFilterPresets(projectId).then((response) => {
                    let presetsOrder;
                    try {
                        presetsOrder = Protobuf.decodeOrder(response.order);
                    } catch (err) {
                        presetsOrder = new IssueFilterSetOrder({ Items: [] });
                    }

                    const entitiesByGuid = _.keyBy(response.entities, 'uuid');
                    const entitiesWithOrder = presetsOrder.Items.map(({ guid }: any) => entitiesByGuid[guid]).filter(Boolean);
                    const entitiesWithoutOrder = _.differenceBy(response.entities, entitiesWithOrder, 'uuid');

                    const entities = [...entitiesWithOrder, ...entitiesWithoutOrder];

                    const issueFilterPresets = [AllIssuesPreset, currentIssuesPreset].concat(
                        entities.map((issueFilterPreset: any) => {
                            const newPreset = _.cloneDeep(issueFilterPreset);
                            let presetFilters;
                            try {
                                presetFilters = Protobuf.decodeRules(issueFilterPreset.filters);
                            } catch (e) {
                                return null;
                            }

                            if (issueFilterPreset.sorting) {
                                newPreset.sorting = Protobuf.decodeSorting(issueFilterPreset.sorting);
                            } else {
                                newPreset.sorting = { ...IssueSortBase };
                            }

                            const filterObjs = presetFilters.map((filter: any) => {
                                return IssuesFilterConstructor.fromProtobuf(
                                    filter,
                                    rootGetters.fieldVariantsByProjectId(projectId),
                                    rootGetters.projectMembersByProjectId(projectId),
                                );
                            });

                            if (filterObjs.some((filterObj: any) => filterObj?.broken === BrokenFilterEnum.brokenOnFrontend)) {
                                newPreset.broken = BrokenPresetEnum.brokenOnFrontend;
                            } else if (filterObjs.some((filterObj: any) => filterObj?.broken === BrokenFilterEnum.brokenInApp)) {
                                newPreset.broken = BrokenPresetEnum.brokenInApp;
                            } else {
                                newPreset.broken = BrokenPresetEnum.notBroken;
                            }

                            // In future, we will output the full reason why the preset is broken with the following text
                            // (see BrokenPresetMessage.cs in revizto repo)
                            // "The following filters cannot be applied to this project:"
                            // "You can either add missing entities into the project or use the {0}
                            // button below to remove all unapplied filters from the preset." - 0 is the name of the button (Clean up)
                            // "Filter name 1: missing id 1", "Filter name 2: missing id 2", ...

                            newPreset.filters = filterObjs
                                .filter((filterObj: any) => filterObj?.broken === BrokenFilterEnum.notBroken) // for now, we break sheet tags filter
                                                                                // Later there should be a validation CREATED_ON_MODE_GUID
                                .map((rule: any) => rule?.filter)
                                .filter(Boolean); // filter out non-supported filters

                            return new IssueFilterPreset(newPreset);
                        }).filter(Boolean) as IssueFilterPreset[],
                        deletedIssuesPreset,
                    );

                    const order = response.order;

                    commit('setIssueFilterPresets', { projectId, issueFilterPresets });

                    const currentFilters = _.cloneDeep(rootGetters.rawTrackerFiltersByProjectId(projectId));

                    if (!state.selectedIssueFilterPresetsObj[projectId] && currentFilters) {
                        const foundPreset = issueFilterPresets.find((preset: any) => {
                            const presetFilters = convertDesktopFilterSetToTrackerFilters(preset.filters);
                            return _.isEqual(presetFilters, currentFilters);
                        });

                        if (foundPreset) {
                            commit('setSelectedIssueFilterPreset', { projectId, issueFilterPreset: foundPreset });
                            commit('setTrackerFilters', {
                                projectId,
                                filters: convertDesktopFilterSetToTrackerFilters(foundPreset.filters),
                            });
                        }
                    }

                    commit('setIssueFilterPresetsOrder', { projectId, order });
                    resolve(issueFilterPresets);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingIssueFilterPresets', { projectId, value: false });
                    commit('removeIssueFilterPresetsRequest', { projectId });
                });
            });

            commit('setIssueFilterPresetsRequest', { projectId, request });
        },
        loadIssueFilterPresetsUnread({ state, commit, getters, rootGetters }: IIssueFilterPresetStorage, {
            projectId,
            projectUuid,
            isForce = false,
        }: {
            projectId: number,
            projectUuid: string,
            isForce: boolean,
        }): Promise<void> {
            return new Promise((resolve, reject) => {
                if (!isForce && (state.issueFilterPresetsUnread[projectId])) {
                    resolve();
                    return;
                }

                const presetsToSend: any[] = _.cloneDeep(getters.issueFilterPresetsByProjectId(projectId));
                presetsToSend.forEach((preset) => {
                    if (!preset.filters) {
                        return;
                    }

                    preset.filters = preset.filters
                        .map((filter: IIssueFilter) => {
                            return IssueTrackerFilterValue.instantiate({
                                ...filter,
                                type: filter.type,
                                modifier: filter.expr,
                                selections: filter.value,
                            }).getApiParams(rootGetters.userData.email);
                        });
                });

                const requests = [];
                let lastSend = 0;

                while (lastSend < presetsToSend.length) {
                    const filters = presetsToSend.slice(lastSend, lastSend + MaxUnreadPresetsPerRequest);
                    requests.push(ProjectApi.getProjectPresetUnread({ projectUuid, filters }));
                    lastSend += MaxUnreadPresetsPerRequest;
                }

                Promise
                    .allSettled(requests)
                    .then((results) => {
                        let unreadResults: Dict = {};
                        results.forEach((result) => {
                            if (result.status !== AllSettledResult.fulfilled) {
                                return;
                            }

                            unreadResults = {
                                ...unreadResults,
                                ...result.value,
                            };
                        });

                        commit('setIssueFilterPresetsUnread', { projectId, unread: unreadResults });
                    })
                    .catch((errors: Error[]) => {
                        reject(errors[0]);
                    });
            });
        },
        readAllUnseenIssues({ getters, rootGetters }: IIssueFilterPresetStorage, { projectId, projectUuid }: { projectId: number, projectUuid: string }) {
            return ProjectApi.readAllUnseenIssues({
                projectUuid,
                filters: getters.selectedIssueFilterPresetByProjectId(projectId),
                alwaysFiltersDTO: getters.trackerFiltersApiParams(
                    getters.trackerFiltersByProjectId(projectId),
                    rootGetters.userData.email,
                ),
            });
        },
    },
};
