import { AbstractComment } from '@/models';

export class TextComment extends AbstractComment {
    public text: string;
    public internalProperties: string;

    constructor(comment: any = {}) {
        super(comment);
        this.text = comment.text;
        this.internalProperties = comment.internalProperties;
    }
}
