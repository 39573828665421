export class SentEmail {
    public readonly uuid: string;
    public readonly subject: string;
    public readonly status: number;
    public readonly createdAt: string;
    public readonly finishedAt: string;
    public readonly subscribers: string;
    public readonly results: string;

    constructor(sentEmail: any = {}) {
        this.uuid = sentEmail.uuid;
        this.subject = sentEmail.subject;
        this.status = sentEmail.status;
        this.createdAt = sentEmail.createdAt;
        this.finishedAt = sentEmail.finishedAt;
        this.subscribers = sentEmail.subscribers;
        this.results = sentEmail.results;
    }
}
