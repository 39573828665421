import { AbstractComment, IPreview } from '@/models';
import { Issue as IssueProtobuf, Protobuf } from '@/services/Protobuf';

export class FileComment extends AbstractComment {
    private _internalProperties: [];

    public size: number;
    public filename: string;
    public mimetype: string;
    public preview: IPreview;
    public rawFile?: File; // существует только у тех что висят в пендинге

    constructor(comment: any = {}) {
        super(comment);
        this.size = comment.size;
        this.filename = comment.filename;
        this.mimetype = comment.mimetype;
        this._internalProperties = comment._internalProperties || comment.internalProperties;
        this.preview = comment.preview || [];
        this.rawFile = comment.rawFile;

        if (comment.internalProperties) {
            this.unpackInternalProperties(comment.internalProperties);
        }
    }

    get is360() {
        let is360 = false;
        if (this._internalProperties && (this._internalProperties as any).list) {
            (this._internalProperties as any).list.forEach((record: IssueProtobuf.KeyValue) => {
                if (record.key === 'Is360') {
                    is360 = true;
                }
            });
        }
        return is360;
    }

    get internalProperties() {
        return this._internalProperties;
    }

    private unpackInternalProperties(value: string) {
        if (value === '') {
            return;
        }

        try {
            this._internalProperties = Protobuf.decodeInternalProperties(value);
        } catch (e) {
            // todo
        }
    }
}
