




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotifierStatus extends Vue {
    get notifierStatusCss(): string {
        if (!this.$store.getters.isOnline) {
            return '';
        }
        if (this.$store.getters.isConnectedToNotifier) {
            return 'connected';
        }
        return 'warning';
    }
}
