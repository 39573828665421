import moment from 'moment';
import _ from 'lodash';
import { MomentFormats } from '@/constants';

export function formDayShift(form: any, timeFormat = MomentFormats.time) {
    const localForm = _.cloneDeep(form);
    localForm.dayShift = 0;
    const m = moment(localForm.time, timeFormat);
    const offset = m.hours() * 60 + m.minutes() - m.utcOffset();
    if (offset < 0) {
        localForm.dayShift = -1;
    }
    if (offset >= 1440) {
        localForm.dayShift = 1;
    }
    localForm.time = formTimeShiftToServer(localForm.time, timeFormat);
    return localForm;
}

export function formTimeShiftFromServer(time: string, timeFormat = MomentFormats.time) {
    return moment(time, timeFormat).add(moment().utcOffset(), 'minutes').format(timeFormat);
}
export function formTimeShiftToServer(time: string, timeFormat = MomentFormats.time) {
    return moment(time, timeFormat).subtract(moment().utcOffset(), 'minutes').format(timeFormat);
}
export function timestampToServer(timeStamp: number) {
    return moment(timeStamp).add(moment().utcOffset(), 'minutes').unix();
}
