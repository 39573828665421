






import { Component, Vue } from 'vue-property-decorator';
import { Issue, Project } from '@/models';
import { issuePrefix } from '@/services/issueTracker/base';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsId extends Vue {
    get showId(): boolean {
        return this.multiSelectedIssuesCount < 2;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get issuePrefix() {
        return issuePrefix(this.currentProject.abbreviate, this.currentIssue.id, this.$t('Simple_word.id'));
    }

    get multiSelectedIssuesCount() {
        return this.$store.getters.multiSelectedIssues.length;
    }
}
