











import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class WsRouterLink extends Vue {
    @Prop({ required: true }) public to!: object | string;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: String, default: '' }) public text!: TranslateResult;
}
