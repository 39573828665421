














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WsStickyLine extends Vue {
    @Prop({ type: Number, default: 0 }) public threshold!: number; // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver#parameters
    @Emit()
    public change(visible: boolean, target: HTMLElement) {
        return {
            visible,
            target,
        };
    }
    public visibilityChanged(visible: boolean, entry: any) {
        this.change(visible, entry.target);
    }
}
