








































import { Component, Prop } from 'vue-property-decorator';
import VuetifyElement from '@/components/common/VuetifyElement.vue';
import WsSelectColorItem from '@/components/common/WsSelectColorItem.vue';

// https://vuetifyjs.com/en/api/v-select/#props

@Component({
    components: {
        WsSelectColorItem,
    },
})
export default class WsSelectColor extends VuetifyElement {
    @Prop({ required: true }) public items!: any[];
    @Prop({ type: Object, default: undefined }) public itemsEnum!: { [key: string]: number };
    @Prop({ type: Object, default: undefined }) public itemsTextKeys!: { [key: number]: string };
    @Prop({ default: false, type: Boolean }) public rgb!: boolean;
    @Prop({ type: Array, default: () => [] }) public itemClasses!: string[];
    @Prop({ type: Boolean, default: false }) public inlineItems!: boolean;
    @Prop({ type: Boolean, default: false }) public showEmptyBorder!: boolean;
}
