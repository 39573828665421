import _ from 'lodash';
import { MemberForApproval } from '@/models';

function extractInvitations(waitingMembersFromApi: any[]) {
    return waitingMembersFromApi.map(({ email, projectUuid, role: roleName, invitedAt: date, senderUuid }: any) => ({
        email,
        invitation: {
            projectUuid,
            roleName,
            date,
            senderUuid,
        },
    }));
}

function aggregateInvitationsByEmail(membersWithInvitations: any[]) {
    const invitationArraysByEmails = membersWithInvitations
        .reduce((acc: any, { email, invitation }: any) => ({ ...acc, [email]: [...(acc[email] || []), invitation] }), {});
    return _.toPairs(invitationArraysByEmails)
        .map(([email, invitations]) => ({ email, invitations }));
}

function instantiateMembersForApproval(membersWithInvitationArrays: any[]) {
    return membersWithInvitationArrays.map(MemberForApproval.instantiate);
}

export function makeMembersForApproval(waitingMembers: any[]): MemberForApproval[] {
    return _.flow([
        extractInvitations,
        aggregateInvitationsByEmail,
        instantiateMembersForApproval,
    ])(waitingMembers);
}
