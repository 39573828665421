export enum PreviewSize {
    SMALL_IMAGES = 0,
    BIGGER_IMAGES = 1,
    HIDE_IMAGES = 2,
}

export const PreviewSizeArray = [
    PreviewSize.SMALL_IMAGES,
    PreviewSize.BIGGER_IMAGES,
    PreviewSize.HIDE_IMAGES,
];
