import { v4 as uuidv4 } from 'uuid';
import { TranslateResult } from 'vue-i18n';
import moment from 'moment';
import { Dict } from '@/types/Dict';
import {
    DateFormatByNumber,
    DEADLINE_NOT_SET,
    IssueDirections,
    IssueFilterExpr,
    IssuesFilterType,
    IssueSort,
    IssueSorts,
    MomentFormats,
    StampColorsPaletteRGB,
} from '@/constants';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import { ISorting } from '@/services/IssueOrderParse';
import { IssueTrackerFilterValue } from '@/services/issueTracker/IssueTrackerFilterValue';

enum diffRows {
    oldValue = 'old',
    newValue = 'new',
    type = 'type',
    uuid = 'uuid',
    reporter = 'reporter',
    created = 'created',
    diff = 'diff',
}

const findColorIndex = (color: string) => {
    return StampColorsPaletteRGB.findIndex((item: string) => color === item) + 1;
};

const prepareValueForChangeIssueField = ({ row, type, newValue }: any) => {
    if (row === 'stampColor') {
        newValue = findColorIndex(newValue);
    }

    if (type === 'date' && (typeof newValue !== 'string')) {
        newValue = newValue.startDate ? newValue.startDate.format(`${DateFormatByNumber[6]} HH:mm:ss`) : DEADLINE_NOT_SET;
    }

    return newValue;
};

const createDiff = ({ sourceValue, value, type }: any) => {
    const diffValues: any = {
        [diffRows.oldValue]: sourceValue,
        [diffRows.newValue]: value,
    };

    if (type === 'switch') {
        diffValues[diffRows.oldValue] = sourceValue ? 1 : 0;
        diffValues[diffRows.newValue] = value ? 1 : 0;
    }

    return diffValues;
};

interface DiffCommentObject {
    type: string;
    uuid: string;
    reporter: any;
    created: string;
    diff: any;
}

const createDiffCommentObject = ({
        diff,
        currentUserEmail,
        time,
    }: any) => {

    const diffUuid = uuidv4();
    const diffObject: Dict<DiffCommentObject> = {};

    diffObject[diffUuid] = {
        [diffRows.type]: 'diff',
        [diffRows.uuid]: diffUuid,
        [diffRows.reporter]: currentUserEmail,
        [diffRows.created]: time || moment().utc().format(MomentFormats.serverSide),
        [diffRows.diff]: diff,
    };

    return diffObject;
};

const issuesSortApiParams = (sort: ISorting): any => {
    const apiParams = [];
    if (sort[IssueSorts.unread]) {
        apiParams.push({
            [IssueSort.field]: IssueSorts.unread,
            [IssueSort.direction]: IssueDirections.ASC,
        });
    }
    if (sort[IssueSorts.primary]) {
        apiParams.push({
            [IssueSort.field]: sort[IssueSorts.primary][IssueSort.field],
            [IssueSort.direction]: sort[IssueSorts.primary][IssueSort.direction],
        });
    }
    if (sort[IssueSorts.secondary]) {
        apiParams.push({
            [IssueSort.field]: sort[IssueSorts.secondary][IssueSort.field],
            [IssueSort.direction]: sort[IssueSorts.secondary][IssueSort.direction],
        });
    }

    return apiParams;
};

const issuePrefix = (abbr: string | undefined, id: number, text: TranslateResult) =>  {
    return (abbr ? `${abbr}-` : `${text} `) + id;
};

const isFiltersForDeletedIssues = (filters: TrackerFilters) => {
    const deletedAtFilter = (filters[IssuesFilterType.deletedAt] as IssueTrackerFilterValue);

    return deletedAtFilter?.modifier === IssueFilterExpr.EQUALS && deletedAtFilter?.selections[0] === 1;
};

const openInAppLink = (currentProject: any, projectId: number, currentIssueId: number) => {
    const prefix = currentProject.isV4 ? 'revizto' : 'revizto5';
    return `${prefix}://viewer/${projectId}/issue_tracker/${currentIssueId}`;
};

export {
    createDiffCommentObject,
    findColorIndex,
    prepareValueForChangeIssueField,
    createDiff,
    issuesSortApiParams,
    issuePrefix,
    isFiltersForDeletedIssues,
    openInAppLink,
};
