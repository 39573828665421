// i18n-setup.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import { LanguageKey } from '@/constants/LanguageKey';
import enTranslations from '@/translations/en';

Vue.use(VueI18n);

const messages = {
    [LanguageKey.en]: enTranslations,
};

export const i18n = new VueI18n({
    locale: LanguageKey.en, // set locale
    fallbackLocale: LanguageKey.en,
    messages, // set locale messages
});

const loadedLanguages = [LanguageKey.en]; // our default language that is preloaded

function setI18nLanguage(lang: LanguageKey) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    const DOMhtml = document.querySelector('html');
    if (DOMhtml) {
        DOMhtml.setAttribute('lang', lang);
    }
    return lang;
}

export function loadLanguageAsync(lang: LanguageKey) {
    if (i18n.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            return import(/* webpackChunkName: "[request]" */ `@/translations/${lang}`).then((msgs) => {
                i18n.setLocaleMessage(lang, msgs.default);
                loadedLanguages.push(lang);
                return setI18nLanguage(lang);
            });
        }
        return Promise.resolve(setI18nLanguage(lang));
    }
    return Promise.resolve(lang);
}

export function getTranslationEndingByCount(value: number, translationSupportPlural2 = false) {
    if (value < 0) {
        return ''; // Right now pluralization checking accessed only for positive values
    }

    switch (i18n.locale) {
        case LanguageKey.cn:
        case LanguageKey.jp: {
            return ''; // No pluralization
        }
        case LanguageKey.fr:
        case LanguageKey.pt: {
            if (value > 1) {
                return '_plural';
            }

            return '';
        }
        case LanguageKey.ru: {
            if (value % 10 === 1) {
                return ''; // 1; 21; 31; 41 and so on
            }

            if ([0, 5, 6, 7, 8, 9].includes(value % 10)) {
                return '_plural'; // 0; 5-20; 25-30; 35-40; 45-50 and so on
            }

            return translationSupportPlural2 ? '_plural2' : '_plural'; // 2-4; 22-24; 32-34 and so on
        }
        case LanguageKey.en:
        case LanguageKey.es:
        case LanguageKey.de:
        default: {
            if (value === 1) {
                return ''; // Default translation key without any special ends like "Something.translation"
            }

            return '_plural'; // Translation key with plural end like "Something.translation_plural"
        }
    }
}
