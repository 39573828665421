import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { ActionLogTypeEnum, ResultStatusEnum, TargetTypeEnum } from '@/constants';
import { LicenseDeletedMember } from '@/models/license/LicenseDeletedMember';
import { LicenseMember } from '@/models/license/LicenseMember';
import { User } from '@/models/User';
import { Immutable } from '@/services';

const memberTypesSet = new Set([
    ActionLogTypeEnum.PROJECT_MEMBER_ADDITION,
    ActionLogTypeEnum.PROJECT_MEMBER_CHANGE_ROLE,
    ActionLogTypeEnum.PROJECT_MEMBER_DELETION,
    ActionLogTypeEnum.LICENSE_MEMBER_ADDITION,
    ActionLogTypeEnum.LICENSE_MEMBER_CHANGE_ROLE,
    ActionLogTypeEnum.LICENSE_MEMBER_DEACTIVATION,
    ActionLogTypeEnum.LICENSE_MEMBER_DELETION,
    ActionLogTypeEnum.LICENSE_MEMBER_ACTIVATION,
    ActionLogTypeEnum.LICENSE_MEMBER_CHANGE_FIELDS,
    ActionLogTypeEnum.AUTH_METHOD_REPLACE,
]);

export interface IActionLog {
    id: number;
    license: {
        id: number;
        title: string;
    };
    project: {
        id: number;
        title: string;
    };
    projectTitle: string;
    savedProjectTitle: string;
    actionType: ActionLogTypeEnum;
    originActionType: ActionLogTypeEnum;
    createdAt: number;
    creatorEmail: string;
    resultStatus: ResultStatusEnum;
    operationUuid: string;
    targetType: TargetTypeEnum;
    targetUuid: string;
    creator: User;
    params?: any;
    results?: any;
}

export class ActionLog {
    public readonly id: number;
    public readonly licenseId: number;
    public readonly licenseTitle: string;
    public readonly projectId: number;
    public readonly projectTitle: string;
    public readonly savedProjectTitle: string;
    public readonly actionType: ActionLogTypeEnum;
    public readonly originActionType: ActionLogTypeEnum;
    public readonly createdAt: number;
    public readonly creatorEmail: string;
    public readonly resultStatus: ResultStatusEnum;
    public readonly params: any;
    public readonly results: any;
    public readonly operationUuid: string;
    public readonly targetType: TargetTypeEnum;
    public readonly targetUuid: string;
    public readonly creator: User;
    public readonly children: ActionLog[];
    public member?: LicenseMember | LicenseDeletedMember;

    public constructor(data: IActionLog) {
        this.id = data.id || 0;
        this.licenseId = Number(data.license.id);
        this.licenseTitle = data.license.title;
        this.projectId = Number(data.project.id);
        this.projectTitle = data.project.title;
        this.savedProjectTitle = data.projectTitle; // ???
        this.actionType = data.actionType;
        this.originActionType = data.originActionType;
        this.createdAt = data.createdAt;
        this.creatorEmail = data.creatorEmail;
        this.resultStatus = data.resultStatus;
        this.params = data.params || {};
        this.results = data.results || {};
        this.operationUuid = data.operationUuid || '';
        this.targetType = data.targetType || 0;
        this.targetUuid = data.targetUuid || '';
        this.creator = new User(data.creator);
        this.children = []; // пушатся извне
    }

    get isDashboard() {
        return this.targetType === TargetTypeEnum.DASHBOARD;
    }

    get isCrossProject() {
        return !this.projectId;
    }

    get isCrossProjectDashboard() {
        return this.isDashboard && this.isCrossProject;
    }

    get isChart() {
        return this.targetType === TargetTypeEnum.DASHBOARD_GRAPH;
    }

    get isMember() {
        return memberTypesSet.has(this.actionType);
    }

    get isSuccess() {
        return this.resultStatus === ResultStatusEnum.SUCCESS;
    }

    get statusTranslationKey() {
        return this.isSuccess ? 'Simple_word.success' : 'Simple_word.error';
    }

    get actionTypes() {
        return _
            .chain(this.children)
            .map('actionType')
            .concat(this.originActionType)
            .uniq()
            .value();
    }

    public enrichByMember(membersByUuid: Dict<LicenseMember | LicenseDeletedMember>) {
        if (this.isMember) {
            this.member = membersByUuid[this.targetUuid];
            Immutable.sealOne(this, 'member');
        }
    }

    public addChildren(children: ActionLog[]) {
        this.children.push(...children);
    }

    public addChild(child: ActionLog) {
        this.children.push(child);
    }

    public clearChildren() {
        this.children.length = 0;
    }

    public makeRootInsteadOf(oldRoot: ActionLog) {
        this.addChildren(oldRoot.children);
        oldRoot.clearChildren();
        this.addChild(oldRoot);
    }

    public static instantiateImmutable(data: IActionLog) {
        return Immutable.sealExcept(new ActionLog(data), ['member']);
    }

    public static enrichByMember(membersByUuids: Dict<LicenseMember | LicenseDeletedMember>) {
        return (actionLog: ActionLog) => {
            actionLog.enrichByMember(membersByUuids);
            return actionLog;
        };
    }
}

