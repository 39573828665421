import { Dict } from '@/types/Dict';

/**
 * Permission by IssueTrackerField that user as row of IssueDetails
 */
export const PermissionNameByIssueField = {
    customStatus: 'editIssueCustomStatus',
    stampAbbr: 'editIssueStampAbbr',
    title: 'changeTitle',
    customType: 'editIssueCustomType',
    assignee: 'changeAssigned',
    priority: 'changePriority',
    deadline: 'changeDeadline',
    stampColor: 'editIssueStampColor',
    reporter: 'changeReporter',
    watchers: 'changeWatchers',
    visibility: 'changeVisibility',
    tags: 'changeTags',
} as Dict<string>;
