






































































































import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { ISelectedItem } from '@/types/ISelectedItem';
import { IssuesFilterType } from '@/constants';
import WsSelectColorItem from '@/components/common/WsSelectColorItem.vue';
import CustomStatusItem from '@/components/project/workflow/CustomStatusItem.vue';
import CustomIssueType from '@/components/common/CustomIssueType.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';
import WsTableFilterElement from '@/components/common/WsTableFilterElement.vue';
import WsInput from '@/components/common/WsInput.vue';

@Component({
    components: {
        WsSelectColorItem,
        CustomStatusItem,
        CustomIssueType,
        WsTruncateAuto,
        WsTableFilterElement,
        WsLazyRender,
        WsInput,
    },
})
export default class WsTableFilterSelect extends Vue {
    @Prop({ required: true }) public value!: Array<string | number>;
    @Prop({ required: true }) public items!: ISelectedItem[];
    @Prop({ type: Boolean, default: false }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean }) public colorSelect!: boolean;
    @Prop({ type: String }) public type!: string;
    @Prop({ default: 'bottom' }) public placement!: string;
    @Prop({ default: true }) public appendToBody!: boolean;
    @Prop() public placeholder!: TranslateResult;
    @Prop() public label!: TranslateResult;

    public search = '';
    public popover = false;
    public selected: ISelectedItem[] = [];
    public allItems: ISelectedItem[] = [];
    public refreshKey = 0;

    public readonly IssuesFilterType = IssuesFilterType;

    get translatedSelected(): ISelectedItem[] {
        return this.selected.map((selectedItem) => this.items.find(({ value }) => selectedItem.value === value) || selectedItem);
    }

    get filteredItems(): ISelectedItem[] {
        if (this.search) {
            const result = this.allItems.filter((item) => String(item.text).toLowerCase().includes(this.search.toLowerCase()));

            if (this.type === 'id') {
                const searchArray = this.search.split(',');
                searchArray.forEach((text: string) => {
                    if (text) {
                        result.push({
                            text,
                            value: text,
                        });
                    }
                });
            }
            return _.uniqBy(result, 'value');
        }
        const itemsOrAllItems = this.allItems.length ? this.allItems : this.items;
        if (this.type === 'id') {
            return _.uniqBy([
                ...itemsOrAllItems,
                ...this.value.map((val) => ({ text: String(val), value: String(val) })),
            ], 'value');
        }
        return itemsOrAllItems;
    }
    get allSelected() {
        return this.filteredItems.length === this.selected.length;
    }

    get isFilterOpen() {
        return this.$store.getters.openedFilter === this.type;
    }

    @Emit()
    public input(value: Array<string | number>) {
        return value;
    }
    @Emit()
    public clear() {
        this.input([]);
        return;
    }

    @Watch('search', { immediate: true, deep: true })
    public afterSearch() {
        if (this.type === 'id') {
            this.selected = this.selected.filter((selectedItem: any) => this.filteredItems.find(({ value }: any) => selectedItem.value === value));
        }
    }

    @Watch('items', { immediate: true, deep: true })
    @Watch('value', { immediate: true, deep: true })
    public afterValue() {
        if (!this.value || !this.value.length) {
            this.selected = [];
        } else {
            this.selected = this.value
                .map((value) => this.filteredItems.find((item) => item.value === value))
                .filter((value) => Boolean(value)) as ISelectedItem[];
        }
    }

    @Watch('popover')
    public changePopover(newValue: boolean) {
        // On open set filter type, on close reset to null.
        const commitedValue = newValue ? this.type : null;
        this.$store.commit('setOpenedFilter', commitedValue);
    }

    public mounted() {
        this.popover = this.isFilterOpen;
    }

    public isActive(item: ISelectedItem) {
        return this.selected.map(({ value }) => value).includes(item.value);
    }

    public openDropdown() {
        this.allItems = _.uniqBy([...this.translatedSelected, ...this.items], 'value');
        this.refreshKey++;
    }

    public toggle(item: ISelectedItem) {
        const i = this.selected.map(({ value }) => value).indexOf(item.value);

        if (i > -1) {
            this.selected.splice(i, 1);
        } else {
            this.selected.push(item);
        }

        const values = this.selected.map(({ value }) => value);
        this.input(values);
    }

    public toggleAll() {
        if (this.allSelected) {
            this.selected = [];
        } else {
            this.selected = [...this.filteredItems];
        }
        const values = this.selected.map(({ value }) => value);
        this.input(values);
    }

    public isRGBColor(color: string) {
        return color && color.split(',').length === 3;
    }

    public getColorCircleBackground(item: any) {
        if (item.color) {
            if (this.isRGBColor(item.color)) {
                return `rgb(${item.color})`;
            }

            return item.color;
        }

        return item.value;
    }
}
