var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-notification-issue-item notification-issue-item"},[_c('div',{staticClass:"top-line"},[_c('div',[(_vm.record.projectTitle)?_c('span',{staticClass:"project-title"},[_vm._v(_vm._s(_vm.record.projectTitle))]):_vm._e(),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.time))])]),(!_vm.record.isRead)?_c('div',[_c('div',{staticClass:"new-badge"},[_vm._v(_vm._s(_vm.$t('Simple_word.new')))])]):_vm._e()]),(_vm.isShowAuthor)?_c('div',{staticClass:"flex-align-center author"},[_c('MemberAvatar',{attrs:{"member-id":_vm.authorMember.id,"avatar-src":_vm.authorMember.avatar,"noLink":""}}),_c('div',{staticClass:"author-name"},[_vm._v(_vm._s(_vm.authorName))])],1):_vm._e(),(_vm.record.type === _vm.NotificationRecordType.issue)?[_c('p',{staticClass:"total-issues"},[_vm._v(_vm._s(_vm.$t('Notification.totalIssues', { count: _vm.record.data.length })))]),([_vm.EventTypeEnum.ISSUE_COMMENT_ADDED, _vm.EventTypeEnum.ISSUE_EDITED].includes(_vm.record.eventType))?_c('div',[(_vm.record.isSameIssueChanges)?[(_vm.record.isDiff)?_vm._l((_vm.comments),function(comment,idx){return _c('IssueCommentDiff',{key:'diff_' + idx,attrs:{"comment":comment}})}):_vm._e(),(_vm.record.isText)?_vm._l((_vm.comments),function(ref,idx){
var text = ref.text;
return _c('WsMarkdownText',{key:'text_' + idx,attrs:{"value":text}})}):_vm._e()]:[(_vm.record.countsWithComments.length < 10)?_vm._l((_vm.record.countsWithComments),function(ref,index){
var count = ref.count;
var comments = ref.comments;
return _c('div',{key:'separate_' + index},[_c('div',{staticClass:"suppressed"},[_vm._v(_vm._s(_vm.$t('Notification.issues' + _vm.getTEndByCount(count), { count: count })))]),_vm._l((comments),function(ref,idx){
var text = ref.text;
return [(text)?_c('WsMarkdownText',{key:'text_' + idx,attrs:{"value":text}}):_vm._e()]}),_vm._l((comments),function(comment,idx){return _c('IssueCommentDiff',{key:'diff_' + idx,attrs:{"comment":comment}})})],2)}):_vm._e()]],2):_vm._e()]:_vm._e(),(_vm.record.isReport)?[_vm._v(" "+_vm._s(_vm.$t('Notification.report'))+" "+_vm._s(_vm.reportTitle)+" ")]:_vm._e(),(_vm.record.isProjectDashboard)?[_vm._v(" "+_vm._s(_vm.$t('Notification.dashboard'))+" "+_vm._s(_vm.dashboardTitle)+" ")]:_vm._e(),(_vm.record.isProjectGraph)?[_c('div',[_vm._v(_vm._s(_vm.$t('Notification.dashboard'))+" "+_vm._s(_vm.graphDashboardTitle))]),_c('div',[_vm._v(_vm._s(_vm.$t('Notification.chart'))+" "+_vm._s(_vm.graphTitle))])]:_vm._e(),(_vm.record.isLicenseDashboard)?[_vm._v(" "+_vm._s(_vm.$t('Notification.licenseDashboard'))+" "+_vm._s(_vm.dashboardTitle)+" ")]:_vm._e(),(_vm.record.isLicenseGraph)?[_c('div',[_vm._v(_vm._s(_vm.$t('Notification.licenseDashboard'))+" "+_vm._s(_vm.graphDashboardTitle))]),_c('div',[_vm._v(_vm._s(_vm.$t('Notification.licenseChart'))+" "+_vm._s(_vm.graphTitle))])]:_vm._e(),_c('div',{staticClass:"results"},[(_vm.isInProgress)?_c('div',{staticClass:"progress"},[_c('v-progress-circular',{attrs:{"size":30,"color":"gray","indeterminate":""}}),_c('span',{staticClass:"processing"},[_vm._v(_vm._s(_vm.$t('Notification.processing')))])],1):(_vm.isRecordOneOf(
                _vm.EventTypeEnum.PROJECT_MEMBER_ADDED,
                _vm.EventTypeEnum.PROJECT_MEMBER_DELETED
            ))?_c('div',[_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryGreen,"icon-name":"check","passive":""}}),_c('div',{staticClass:"notification-success-count"},[(_vm.record.eventType === _vm.EventTypeEnum.PROJECT_MEMBER_ADDED)?[_vm._v(" "+_vm._s(_vm.$t(("Notification.projectMembersAdded" + (_vm.record.data.length > 1 ? '_pl' : '')), { count: _vm.record.data.length }))+" ")]:_vm._e(),(_vm.record.eventType === _vm.EventTypeEnum.PROJECT_MEMBER_DELETED)?[_vm._v(" "+_vm._s(_vm.$t(("Notification.projectMembersDeleted" + (_vm.record.data.length > 1 ? '_pl' : '')), { count: _vm.record.data.length }))+" ")]:_vm._e()],2)],1),_c('div',{staticClass:"notification-data"},_vm._l((_vm.record.data),function(ref){
            var userIdentifier = ref.userIdentifier;
return _c('div',{key:userIdentifier},[_vm._v(_vm._s(userIdentifier))])}),0)]):(_vm.isRecordOneOf(
                _vm.EventTypeEnum.LICENSE_MEMBER_ADDED,
                _vm.EventTypeEnum.LICENSE_MEMBER_DELETED,
                _vm.EventTypeEnum.LICENSE_MEMBER_ADDED_PENDING
            ))?_c('div',[_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryGreen,"icon-name":"check","passive":""}}),_c('div',{staticClass:"notification-success-count"},[(_vm.record.eventType === _vm.EventTypeEnum.LICENSE_MEMBER_ADDED)?[_vm._v(" "+_vm._s(_vm.$t(("Notification.licenseMembersAdded" + (_vm.record.data.length > 1 ? '_pl' : '')), { count: _vm.record.data.length }))+" ")]:_vm._e(),(_vm.record.eventType === _vm.EventTypeEnum.LICENSE_MEMBER_DELETED)?[_vm._v(" "+_vm._s(_vm.$t(("Notification.licenseMembersDeleted" + (_vm.record.data.length > 1 ? '_pl' : '')), { count: _vm.record.data.length }))+" ")]:_vm._e(),(_vm.record.eventType === _vm.EventTypeEnum.LICENSE_MEMBER_ADDED_PENDING)?[_vm._v(" "+_vm._s(_vm.$t(("Notification.licenseMembersAddedPending" + (_vm.record.data.length > 1 ? '_pl' : '')), { count: _vm.record.data.length }))+" ")]:_vm._e()],2)],1),_c('div',{staticClass:"notification-data"},_vm._l((_vm.record.data),function(ref){
            var userIdentifier = ref.userIdentifier;
return _c('div',{key:userIdentifier},[_vm._v(_vm._s(userIdentifier))])}),0),(_vm.record.eventType === _vm.EventTypeEnum.LICENSE_MEMBER_ADDED_PENDING)?_c('div',{staticClass:"link approval-link",on:{"click":_vm.viewApprovalList}},[_vm._v(" "+_vm._s(_vm.$t('Notification.viewApprovalList'))+" ")]):_vm._e()]):(_vm.record.eventType === _vm.EventTypeEnum.PROJECT_REVISION_ADDED)?_c('div',{staticClass:"view-something-wrapper"},[_c('div',[_vm._v(_vm._s(_vm.$t('Notification.revisionAdded')))]),_c('div',{staticClass:"link view-link",on:{"click":_vm.viewRevisions}},[_vm._v(_vm._s(_vm.$t('Notification.viewVersions')))])]):(_vm.record.isReport)?_c('div',{staticClass:"view-something-wrapper"},[(_vm.isRecordOneOf(_vm.EventTypeEnum.REPORT_SENT, _vm.EventTypeEnum.REPORT_SENT_WITH_DEGRADATION))?[_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryGreen,"icon-name":"check","passive":""}}),_c('div',{staticClass:"notification-other-result"},[_vm._v(" "+_vm._s(_vm.$t('Notification.done'))+" ")])],1),_c('div',{staticClass:"link view-link",on:{"click":_vm.downloadReport}},[_vm._v(" "+_vm._s(_vm.$t('Notification.downloadFile'))+" ")])]:(_vm.isRecordOneOf(_vm.EventTypeEnum.REPORT_ERROR))?_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryRed,"icon-name":"x","passive":""}}),_c('div',{staticClass:"notification-other-result"},[_vm._v(" "+_vm._s(_vm.$t('Notification.reportError'))+" ")])],1):(_vm.isRecordOneOf(_vm.EventTypeEnum.REPORT_SENT_EMPTY))?_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryRed,"icon-name":"x","passive":""}}),_c('div',{staticClass:"notification-other-result"},[_vm._v(" "+_vm._s(_vm.$t('Notification.reportCanceled'))+" ")])],1):_vm._e()],2):(_vm.record.isProjectDashboard)?_c('div',{staticClass:"view-something-wrapper"},[(_vm.isRecordOneOf(
                    _vm.EventTypeEnum.PROJECT_DASHBOARD_SENT,
                    _vm.EventTypeEnum.PROJECT_DASHBOARD_SENT_WITH_DEGRADATION
                ))?[_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryGreen,"icon-name":"check","passive":""}}),_c('div',{staticClass:"notification-other-result"},[_vm._v(" "+_vm._s(_vm.$t('Notification.done'))+" ")])],1),_c('div',{staticClass:"link view-link",on:{"click":_vm.downloadDashboard}},[_vm._v(" "+_vm._s(_vm.$t('Notification.downloadFile'))+" ")])]:(_vm.isRecordOneOf(
                    _vm.EventTypeEnum.PROJECT_DASHBOARD_ERROR,
                    _vm.EventTypeEnum.PROJECT_DASHBOARD_CANCELED_NO_DATA
                ))?_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryRed,"icon-name":"x","passive":""}}),_c('div',{staticClass:"notification-other-result"},[(_vm.record.eventType === _vm.EventTypeEnum.PROJECT_DASHBOARD_ERROR)?[_vm._v(" "+_vm._s(_vm.$t('Notification.dashboardError'))+" ")]:_vm._e(),(_vm.record.eventType === _vm.EventTypeEnum.PROJECT_DASHBOARD_CANCELED_NO_DATA)?[_vm._v(" "+_vm._s(_vm.$t('Notification.dashboardCanceled'))+" ")]:_vm._e()],2)],1):_vm._e()],2):(_vm.record.isProjectGraph)?_c('div',{staticClass:"view-something-wrapper"},[(_vm.isRecordOneOf(
                    _vm.EventTypeEnum.PROJECT_GRAPH_SENT,
                    _vm.EventTypeEnum.PROJECT_GRAPH_SENT_WITH_DEGRADATION
                ))?[_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryGreen,"icon-name":"check","passive":""}}),_c('div',{staticClass:"notification-other-result"},[_vm._v(" "+_vm._s(_vm.$t('Notification.done'))+" ")])],1),_c('div',{staticClass:"link view-link",on:{"click":_vm.downloadGraph}},[_vm._v(" "+_vm._s(_vm.$t('Notification.downloadFile'))+" ")])]:(_vm.isRecordOneOf(
                    _vm.EventTypeEnum.PROJECT_GRAPH_ERROR,
                    _vm.EventTypeEnum.PROJECT_GRAPH_CANCELED_NO_DATA
                ))?_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryRed,"icon-name":"x","passive":""}}),_c('div',{staticClass:"notification-other-result"},[(_vm.record.eventType === _vm.EventTypeEnum.PROJECT_GRAPH_ERROR)?[_vm._v(" "+_vm._s(_vm.$t('Notification.chartError'))+" ")]:_vm._e(),(_vm.record.eventType === _vm.EventTypeEnum.PROJECT_GRAPH_CANCELED_NO_DATA)?[_vm._v(" "+_vm._s(_vm.$t('Notification.chartCanceled'))+" ")]:_vm._e()],2)],1):_vm._e()],2):(_vm.record.isLicenseDashboard)?_c('div',{staticClass:"view-something-wrapper"},[(_vm.isRecordOneOf(
                    _vm.EventTypeEnum.LICENSE_DASHBOARD_SENT,
                    _vm.EventTypeEnum.LICENSE_DASHBOARD_SENT_WITH_DEGRADATION
                ))?[_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryGreen,"icon-name":"check","passive":""}}),_c('div',{staticClass:"notification-other-result"},[_vm._v(" "+_vm._s(_vm.$t('Notification.done'))+" ")])],1),_c('div',{staticClass:"link view-link",on:{"click":_vm.downloadDashboard}},[_vm._v(" "+_vm._s(_vm.$t('Notification.downloadFile'))+" ")])]:(_vm.isRecordOneOf(
                    _vm.EventTypeEnum.LICENSE_DASHBOARD_ERROR,
                    _vm.EventTypeEnum.LICENSE_DASHBOARD_CANCELED_NO_DATA
                ))?_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryRed,"icon-name":"x","passive":""}}),_c('div',{staticClass:"notification-other-result"},[(_vm.record.eventType === _vm.EventTypeEnum.LICENSE_DASHBOARD_ERROR)?[_vm._v(" "+_vm._s(_vm.$t('Notification.dashboardError'))+" ")]:_vm._e(),(_vm.record.eventType === _vm.EventTypeEnum.LICENSE_DASHBOARD_CANCELED_NO_DATA)?[_vm._v(" "+_vm._s(_vm.$t('Notification.dashboardCanceled'))+" ")]:_vm._e()],2)],1):_vm._e()],2):(_vm.record.isLicenseGraph)?_c('div',{staticClass:"view-something-wrapper"},[(_vm.isRecordOneOf(
                    _vm.EventTypeEnum.LICENSE_GRAPH_SENT,
                    _vm.EventTypeEnum.LICENSE_GRAPH_SENT_WITH_DEGRADATION
                ))?[_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryGreen,"icon-name":"check","passive":""}}),_c('div',{staticClass:"notification-other-result"},[_vm._v(" "+_vm._s(_vm.$t('Notification.done'))+" ")])],1),_c('div',{staticClass:"link view-link",on:{"click":_vm.downloadGraph}},[_vm._v(" "+_vm._s(_vm.$t('Notification.downloadFile'))+" ")])]:(_vm.isRecordOneOf(
                    _vm.EventTypeEnum.LICENSE_GRAPH_ERROR,
                    _vm.EventTypeEnum.LICENSE_GRAPH_CANCELED_NO_DATA
                ))?_c('div',{staticClass:"notification-other"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryRed,"icon-name":"x","passive":""}}),_c('div',{staticClass:"notification-other-result"},[(_vm.record.eventType === _vm.EventTypeEnum.LICENSE_GRAPH_ERROR)?[_vm._v(" "+_vm._s(_vm.$t('Notification.chartError'))+" ")]:_vm._e(),(_vm.record.eventType === _vm.EventTypeEnum.LICENSE_GRAPH_CANCELED_NO_DATA)?[_vm._v(" "+_vm._s(_vm.$t('Notification.chartCanceled'))+" ")]:_vm._e()],2)],1):_vm._e()],2):(_vm.record.type === _vm.NotificationRecordType.issue)?_c('div',[(_vm.countSuccess)?_c('div',{staticClass:"notification-success"},[_c('IconSvg16',{attrs:{"color":_vm.Color.primaryGreen,"icon-name":"check","passive":""}}),_c('div',{staticClass:"notification-success-count"},[_vm._v(_vm._s(_vm.issueSuccessString))]),(_vm.record.eventType !== _vm.EventTypeEnum.ISSUE_DELETED)?_c('div',{staticClass:"link view-link",on:{"click":_vm.openSuccessIssues}},[_vm._v(" "+_vm._s(_vm.$t('Notification.openIssues'))+" ")]):_vm._e()],1):_vm._e(),(_vm.countError)?_c('div',{staticClass:"notification-error"},[_c('IconSvg16',{attrs:{"icon-name":"none","color":_vm.Color.primaryRed,"passive":""}}),_c('div',{staticClass:"notification-error-count"},[_vm._v(_vm._s(_vm.$t('Notification.nErrors', { count: _vm.countError })))])],1):_vm._e(),_vm._l((_vm.errorItemsByErrorType),function(errorType){return _c('div',{key:errorType.errorCode,staticClass:"notification-error-item"},[_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errorType.errorMsg))]),_c('div',{staticClass:"link view-link",on:{"click":function($event){return _vm.openIssuesInIssueTracker(errorType.ids)}}},[_vm._v(" "+_vm._s(_vm.$t('Notification.openIssues'))+" ")])])})],2):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }