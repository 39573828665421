










import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { getIssueTypeIconPath, IssueTypeIcon } from '@/constants/issue/IssueTypeIcons';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    methods: {
        getIssueTypeIconPath,
    },
    components: {
        IconSvg24,
    },
})
export default class CustomIssueTypeIcon extends Vue {
    @Prop() icon!: IssueTypeIcon;
    @Prop({ type: String, required: true }) public iconColor!: string;

    @Emit()
    public click() {
        return;
    }
}
