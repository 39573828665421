































import { Component, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { Issue } from '@/models';
import { amplitudeLog } from '@/services';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsInput from '@/components/common/WsInput.vue';
import PinnedComments from '@/components/project/issueTracker/PinnedComments.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
        PinnedComments,
        WsInput,
        IconSvg24,
    },
})
export default class ChatFilterActions extends Vue {
    public isPinned = false;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get pinnedComments() {
        return this.currentIssue ? this.$store.getters.pinnedCommentsByIssue(this.projectId, this.currentIssue.uuid) : [];
    }

    get isOpenChatFilters(): boolean {
        return this.$store.getters.isChatFiltersOpen;
    }

    get isOpenChatSearch(): boolean {
        return this.$store.getters.isChatSearchOpen;
    }

    get commentsFiltersActive(): any {
        return this.$store.getters.commentsFiltersActive;
    }

    public toggleFilters() {
        this.$store.dispatch('toggleChatFilters');

        if (this.isOpenChatFilters) {
            amplitudeLog(AmplitudeEvent.isOpenChatFilters);
        }
    }

    public toggleSearch() {
        this.$store.dispatch('toggleChatSearch');

        if (this.isOpenChatSearch) {
            amplitudeLog(AmplitudeEvent.isOpenChatSearch);
        }
    }

    public togglePinnedComments() {
        this.isPinned = !this.isPinned;

        if (this.isPinned) {
            amplitudeLog(AmplitudeEvent.isOpenPinnedComments, { count: this.pinnedComments.length });
        }
    }
}
