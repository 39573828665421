















































































































































import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
import { IComboboxOption } from '@/types/common';
import { i18n } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsInputSearchV2 from '@/components/common/WsInputSearchV2.vue';
import WsMultiSelectItem from '@/components/common/WsMultiSelectItem.vue';

enum MultiSelectNamedSlot {
    Activator = 'activator',
    Right = 'right'
}

@Component({
    components: {
        IconSvg16,
        WsTruncate,
        WsLazyRender,
        WsTooltip,
        WsInputSearchV2,
        WsMultiSelectItem,
    },
})
export default class WsMultiSelect extends Vue {
    @VModel() selected!: string[];
    @Prop({ required: true, default: () => [] }) public options!: IComboboxOption[];
    @Prop({ default: 'value' }) public valueKey!: string;
    @Prop({ default: 'label' }) public labelKey!: string;
    @Prop({ default: i18n.t('Simple_word.disabled') }) public disabledTooltip!: string;
    @Prop({ default: i18n.t('Text.selectOrEnter') }) public inputPlaceholder!: string;
    @Prop({ default: '336px' }) public maxHeight!: string;
    @Prop({ default: '336px' }) public minHeight!: string;
    @Prop({ default: '351px' }) public width!: string;
    @Prop() public multiple!: boolean;
    @Prop({ type: Boolean, default: false }) public error!: boolean;
    @Prop({ type: Boolean, default: false }) public hideValues!: boolean;
    @Prop({ type: Boolean, default: false }) public creatable!: boolean;
    @Prop({ type: Boolean, default: true }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ default: '' }) public errorText!: string;
    @Prop({ default: i18n.t('Simple_word.new') }) public createActionText!: string;
    @Prop({ default: 15 }) public selectedLabelSize!: number;

    public readonly componentUuid = uuidv4();
    public readonly activatorId = `activator-${this.componentUuid}`;
    public readonly attachSelector = `[data-uuid="${this.componentUuid}"]`;
    public readonly MultiSelectNamedSlot = MultiSelectNamedSlot;
    public readonly itemStringLimit = 35;
    public isOpen = false;

    public searchQuery = '';

    get localOptions() {
        return this.options;
    }

    get localSelected() {
        return this.options.filter(({ value }) => this.selected.includes(value));
    }

    get itemsToShow(): IComboboxOption[]  {
        const lowerQuery = this.searchQuery.toLowerCase();

        if (!lowerQuery.length) {
            return this.localOptions;
        }

        return this.localOptions.filter(
            (option) => (
                option.searchValue.includes(lowerQuery)
            ),
        );
    }

    get ifSelectedStringArray() {
        return Boolean(this.selected.find((item: string | IComboboxOption) => typeof item === 'string'));
    }

    get activeItemsToShow(): IComboboxOption[] {
        return this.itemsToShow.filter(({ disabled }) => !disabled);
    }

    get isEmptySearch() {
        return Boolean(this.searchQuery.length) && !this.itemsToShow.length;
    }

    get isEmptyList() {
        return !this.itemsToShow.length && !this.searchQuery.length;
    }

    get styles() {
        return `max-height: ${this.maxHeight}; min-height: ${this.minHeight}`;
    }

    // If search value is not empty, we need to toggle only items which shows.
    get itemsToToggleAll() {
        return this.searchQuery.length ? this.itemsToShow : this.localOptions;
    }

    get isIndeterminate() {
        return this.localSelected.length > 0
            && _.intersectionBy(this.localSelected, this.itemsToToggleAll, 'value').length < this.itemsToToggleAll.length;
    }

    get isAllSelected() {
        return _.intersectionBy(this.localSelected, this.itemsToToggleAll, 'value').length === this.itemsToToggleAll.length;
    }

    get activeLocalOptions() {
        return this.localOptions.filter(({ disabled }) => !disabled);
    }

    get isItemsSlots() {
        const namedSlots = Object.values(MultiSelectNamedSlot);
        // Here we see if it is slots besides named one
        return Boolean(_.difference(Object.keys(this.$slots), namedSlots).length);
    }

    @Emit()
    public change() {
        return this.selected;
    }

    @Emit()
    public async createNewItem() {
        const newItem = {
            value: this.searchQuery,
            label: this.searchQuery,
            searchValue: this.searchQuery,
        };
        this.localOptions.push(newItem);
        this.onMenuItemChange(newItem);
        this.clearSearch();
  
        return this.localOptions;
    }

    public onChangeDropdownVisibility(value: boolean) {
        if (!value) {
            this.clearSearch();
        }
    }

    public isActive(item: IComboboxOption) {
        return _.some(this.localSelected, { value: item.value });
    }

    public onMenuItemChange(item: IComboboxOption) {
        if (item.disabled) {
            return;
        }

        const i = this.selected.findIndex((element) => element === item.value);

        if (i > -1) {
            this.selected.splice(i, 1);
        } else {
            this.selected.push(item.value);
        }

        this.change();
    }

    public toggleMenu() {
        this.isOpen = !this.isOpen;
    }

    public async onToggleAll(value: boolean) {
        this.selected = value ? this.itemsToToggleAll.map(({ value }) => value) : [];

        if (!this.localSelected) {
            return;
        }

        await this.$nextTick();
        this.change();
    }

    public clearSearch() {
        this.searchQuery = '';
    }
    
}
