import { AuthMethodType } from '@/constants';

export class ProtoLicense {
    public authMethodPort: number;
    public authMethodTitle: string;
    public authMethodType: AuthMethodType;
    public authMethodEntityId: string;
    public name: string;
    public require: string;
    public uuid: string;

    constructor(data: any) {
        this.authMethodPort = data.authMethod.port;
        this.authMethodTitle = data.authMethod.title;
        this.authMethodType = data.authMethod.type;
        this.authMethodEntityId = data.authMethod.spEntityId;
        this.require = data.authMethod.require;
        this.name = data.name;
        this.uuid = data.uuid;
    }

    public static instantiate(data: any) {
        return new ProtoLicense(data);
    }
}
