






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { getIssueTypeIconPath } from '@/constants/issue/IssueTypeIcons';
import { IssueType } from '@/models';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import CustomIssueTypeIcon from '@/components/common/CustomIssueTypeIcon.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';

@Component({
    methods: {
        getIssueTypeIconPath,
    },
    components: {
        CustomIssueTypeIcon,
        IconSvg24,
        WsTooltip,
        WsTruncateAuto,
    },
})
export default class CustomIssueType extends Vue {
    @Prop({ type: Object, required: true }) public issueType!: IssueType;
    @Prop({ type: Boolean, default: false }) public inline!: boolean;
    @Prop({ type: Boolean, default: false }) public hoverable!: boolean;
    @Prop({ type: Boolean, default: false }) public passive!: boolean;
    @Prop({ type: Boolean, default: false }) public hideDescription!: boolean;

    @Emit()
    public click() {
        return this.issueType;
    }

    get tooltip() {
        return this.hideDescription
            ? `<b>${this.issueType.name}</b>`
            : `<b>${this.issueType.name}</b><br />${this.issueType.description}`;
    }
}
