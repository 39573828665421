
























import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import { Dict } from '@/types/Dict';
import { TUuid } from '@/types/common';
import { PopoverPlacement } from '@/constants';

@Component({ })
export default class WsPopover extends Vue {
    @VModel({ type: Boolean }) isOpen!: boolean;
    @VModel({ type: Boolean, default: false }) noPadding!: boolean;
    @Prop({ type: Number, default: 300 }) public width!: number;
    @Prop({ default: PopoverPlacement.bottom }) public placement!: PopoverPlacement;
    @Prop({ default: '' }) public alignmentNode!: string;
    @Prop({ default: '' }) public id!: TUuid;
    @Prop({ default: '' }) public stopClickOutsideClass!: string;

    public popoverUuid = uuid();
    public marginForCenterOfAlignmentNode: null | number  = null;
    public isArrowShifted = '';
    public isLeftOverflowed = false;
    public isRightOverflowed = true;

    @Emit()
    public show() {
        return;
    }

    @Watch('isOpen')
    public onStateChanged(value: boolean) {
        if (value) {
            this.show();
            this.touchUpPosition();
        }
    }

    get popoverId() {
        return `ws-popover-${this.popoverUuid}`;
    }

    get popoverStyles() {
        const styles: Dict = {
            width: this.width + 'px',
        };

        if (this.isLeftOverflowed) {
            styles['left'] = '0';
            styles['right'] = 'auto';
            styles['margin-left'] = '0';
            return styles;
        }

        if (this.isRightOverflowed) {
            styles['right'] = '0';
            styles['left'] = 'auto';
            styles['margin-left'] = '0';
            return styles;
        }

        styles['margin-left'] = `-${this.marginForCenterOfAlignmentNode}px`;
        return styles;
    }

    public handleClick() {
        this.isOpen = !this.isOpen;
    }

    public onClickOutside(e: Event) {
        if (this.stopClickOutsideClass) {
            let element = e.target as HTMLElement;
            while (element !== document.body) {
                if (element.classList.contains(this.stopClickOutsideClass)) {
                    return;
                }

                element = element.parentElement!;
            }
        }

        this.isOpen = false;
    }

    public close() {
        this.isOpen = false;
    }

    public getAlignmentNodeRect() {
        if (!this.alignmentNode) {
            return;
        }

        const querySelector= this.alignmentNode.length ? `#${this.popoverId} .${this.alignmentNode}` : `#${this.popoverId}`;
        const alignmentNode = document.querySelector(querySelector);
        
        return alignmentNode?.getBoundingClientRect();
    }

    public mounted() {
        const alignmentRect = this.getAlignmentNodeRect();

        if (!alignmentRect) {
            return;
        }

        this.marginForCenterOfAlignmentNode = alignmentRect?.width
            ? Math.round((this.width / 2) - (Number(alignmentRect?.width) / 2) - 16)
            : null;
    }

    public touchUpPosition() {
        const rect = this.getAlignmentNodeRect();
        if (!rect) {
            return;
        }

        switch (this.placement) {
            // Now we support only bottom and same placements
            case PopoverPlacement.bottomEnd:
            case PopoverPlacement.bottom:
            case PopoverPlacement.bottomStart:
            default:
                if (rect.x < this.marginForCenterOfAlignmentNode!) {
                    this.marginForCenterOfAlignmentNode = rect.x - 16 - 8;
                    this.isArrowShifted = 'left';
                }

                const halfOfWidth = this.width / 2;
                const halfOfAlignmentNodeWidth = rect.width / 2;
                const overflowedWidth = halfOfWidth - halfOfAlignmentNodeWidth;

                if ((rect.x - overflowedWidth) < 0) {
                    this.isLeftOverflowed = true;
                    this.isRightOverflowed = false;
                    this.isArrowShifted = 'left';
                    return;
                }

                if ((rect.right + overflowedWidth) > window.innerWidth) {
                    this.isRightOverflowed = true;
                    this.isLeftOverflowed = false;
                    this.isArrowShifted = 'right';
                    return;
                }

                this.isLeftOverflowed = false;
                this.isRightOverflowed = false;
        }
    }
}
