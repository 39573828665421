

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IDiffItem, IssueTrackerDiffMultilinePropertiesType } from '@/models';
import { mappedDiff, truncateString } from '@/services';
import WsMemberName from '@/components/common/WsMemberName.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
        WsMemberName,
    },
})
export default class IssueCommentDiffMultipleItems extends Vue {
    @Prop({ required: true }) public diff!: IDiffItem;
    @Prop({ required: true }) public entity!: IssueTrackerDiffMultilinePropertiesType;

    public readonly truncateString = truncateString;

    get mappedDiff(): Array<{ deleted: boolean; added: boolean; value: string }> {
        return mappedDiff(this.diff);
    }

    get addedArray() {
        return this.mappedDiff.filter(({ added }) => added);
    }

    get deletedArray() {
        return this.mappedDiff.filter(({ deleted }) => deleted);
    }
}
