











import { Component, Model, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class WsToggle extends Vue {
    @Model('modelValue',  { type: Boolean }) public value!: boolean;
    @Prop() public label!: any;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;

    public toggle() {
        if (!this.disabled) {
            this.value = !this.value;
        }
    }

}
