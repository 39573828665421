import _ from 'lodash';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { CameFrom, MemberRoleEnum, RESPONSE, RouterNames } from '@/constants';
import { License } from '@/models';
import { isRouteNeedAdmin } from '@/services';

@Component
export default class CheckAccessMixin extends Vue {
    public isCheckingAccess = false;

    get routeLicenseId(): number {
        return Number(this.$route.params.licenseId);
    }

    get currentLicense(): License {
        return this.$store.getters.currentLicense;
    }

    get minimumRole() {
        return isRouteNeedAdmin(this.$route) ? MemberRoleEnum.admin : MemberRoleEnum.none;
    }

    public checkGetAccessToCurrentLicense() {
        if (!this.routeLicenseId) {
            return;
        }

        this.isCheckingAccess = true;
        return this.$store.dispatch('loadLicenseById', {
            licenseId: this.routeLicenseId,
            isForce: true,
        }).then(() => {
            const { role } = this.currentLicense;
            if (!role || role < this.minimumRole) {
                return  CameFrom.wrongPage;
            }
        }).catch(({ result }) => {
            if ([RESPONSE.OBJECT_NOT_EXISTS, RESPONSE.SECURITY_ERROR, RESPONSE.NOT_FOUND].includes(result)) {
                return  CameFrom.wrongLicense;
            }
        }).then((cameFrom) => {
            if (cameFrom) {
                return this.$router.replace({
                    name: RouterNames.NotFound,
                    params: {
                        ...this.$route.params,
                        0: '',
                        cameFrom,
                    },
                });
            }
        }).finally(() => {
            this.isCheckingAccess = false;
        });
    }
}
