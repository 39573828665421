import { IIssueFilter } from '@/types/IIssueFilter';
import { DashboardPrivacy, DashboardSharing, DashboardVisibility, PeriodType, PreviewSize } from '@/constants';
import { IssuesFilterConstructor } from '@/models/IssuesFilter';

export class BaseDashboard {
    public uuid: string;
    public title: string;
    public created: string;
    public color: string;
    public privacy: number;
    public sharing: number;
    public visibility: number;
    public delivery: any;
    public order: any;
    public author: string;
    public search: string;
    public isFavorite: boolean;
    public alwaysFilters: IIssueFilter[];
    public isShared: boolean;
    public hasSharedCharts: boolean;

    constructor(dashboard: any = {}) {
        this.uuid = dashboard.uuid || '';
        this.title = dashboard.title || '';
        this.created = dashboard.created || '';
        this.color = dashboard.color || '';
        this.privacy = dashboard.privacy || DashboardPrivacy.public;
        this.sharing = dashboard.sharing || DashboardSharing.byInvitation;
        this.visibility = dashboard.visibility || DashboardVisibility.allIssues;
        this.delivery = dashboard.delivery || {
            periodType: PeriodType.none,
            days: [1, 2, 3, 4, 5],
            time: '08:00',
            dayShift: 0,
            subscribers: [],
            includeUserComments: 1,
            includeAttachments: 0,
            includeFieldChanges: 0,
            includeMarkupChanges: 0,
            includeCompanyLogo: 0,
            includeDataTables: 0,
            previewSize: PreviewSize.SMALL_IMAGES,
            timeFormat: 1,
            dateFormat: 1,
        };
        this.order = dashboard.order;
        this.author = dashboard.author || '';
        this.search = `${this.title} ${this.author}`.toLowerCase();
        this.isFavorite = Boolean(dashboard.isFavorite);
        this.isShared = Boolean(dashboard.isShared);
        this.hasSharedCharts = Boolean(dashboard.hasSharedCharts);

        const alwaysFilters = dashboard.alwaysFilters || [];
        this.alwaysFilters = alwaysFilters.map((filter: any) => new IssuesFilterConstructor(filter));
    }

    get apiParams() {
        return {
            title: this.title,
            color: this.color,
            privacy: this.privacy,
            visibility: this.visibility,
            authorUuid: this.author,
            alwaysFilters: this.alwaysFilters
                .filter((filter: IIssueFilter) => filter.isValidParams())
                .map((filter) => filter.getParams()),
        };
    }

    get subscribers(): string[] {
        return this.delivery.periodType === PeriodType.none ? [] : this.delivery.subscribers;
    }
}
