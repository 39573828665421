import { render, staticRenderFns } from "./IssueDetailsPriority.vue?vue&type=template&id=42cd93bd&scoped=true&"
import script from "./IssueDetailsPriority.vue?vue&type=script&lang=ts&"
export * from "./IssueDetailsPriority.vue?vue&type=script&lang=ts&"
import style0 from "./IssueDetailsPriority.vue?vue&type=style&index=0&id=42cd93bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42cd93bd",
  null
  
)

export default component.exports