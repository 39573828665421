import updaterHttp from '@/api/updaterHttp';

export default class ReleaseApi {
    /** Получение всех доступных бранчей */
    public static requestBranches(): Promise<any> {
        return updaterHttp.get('public/branches').then((response) => response.data);
    }

    /** Получение всех доступных билдов */
    public static requestBuilds(params: any): Promise<any> {
        return updaterHttp.get('public/builds', params).then((response) => response.data);
    }
}
