














































import { Component, Prop } from 'vue-property-decorator';
import { Issue } from '@/models';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueStatus from '@/components/project/issueTracker/IssueStatus.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';
import WsSwitch from '@/components/common/WsSwitch.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IssueDetailsItem,
        IssueStatus,
        WsSwitch,
        IconSvg16,
    },
})
export default class IssueDetailsVisibility extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    public inlineEdit = false;
    public localValue: boolean | undefined = undefined;

    get isCurrentIssueDeleted() {
        return this.currentIssue.isDeleted;
    }
    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get currentValue(): boolean {
        if (this.multiEditNewValues) {
            return this.multiEditNewValues.value;
        }
        return this.showFieldMultipleText
            ? this.multiSelectIssueValues
            : this.currentIssue[this.valueKey as keyof Issue];
    }

    set currentValue(value) {
        this.handleChange(value);
    }

    public handleChange(value: boolean) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
        }
    }

    public setValue(value: boolean) {
        this.localValue = value;
        this.handleSave();
    }

    public handleSave() {
        if (this.localValue !== this.currentValue) {
            this.change(this.localValue);
        }
        this.stopEdit();
    }

    public startEdit() {
        this.inlineEdit = true;
    }

    public stopEdit() {
        this.inlineEdit = false;
    }
}
