import _ from 'lodash';
// @ts-ignore
import sha1 from 'crypto-js/sha1';
import moment from 'moment';
import { NotifierUserID } from '@/constants';

let licenseUuidForOperationId: string = '';

export const setLicenseUuidForOperationId = (uuid: string) => {
    licenseUuidForOperationId = uuid;
};

export function getOperationId(data: any = {}): string {
    const { contentToSend, projectId, eventType = 0 } = data;
    if (contentToSend) {
        return getOperationIdFromContent(contentToSend);
    } else if (projectId) {
        return getOperationIdFromProject(projectId, eventType);
    } else {
        return getRandomOperationId();
    }
}

function getOperationIdFromContent(contentToSend: any) {
    if (!contentToSend) {
        return getRandomOperationId();
    }
    const commentsObj = JSON.parse(contentToSend.comments);
    const comment = _.values(commentsObj)[0];
    if (_.keys(commentsObj).length !== 1 || comment?.type !== 'diff') {
        return getRandomOperationId();
    }
    const operationIdObj: any = {
        projectId: contentToSend.project_id,
        notifierUserId: contentToSend[NotifierUserID],
        diff: comment.diff,
        createdHour: moment(comment.created).format('YYYY-MM-DD hh'),
        reporter: comment.reporter,
    };
    return getHash(operationIdObj);
}

function getOperationIdFromProject(projectId: string, eventType: number) {
    const timeHour = moment().format('YYYY-MM-DD hh');
    const data = { projectId, eventType, timeHour };
    return getHash(data);
}

function getRandomOperationId() {
    return getHash(Math.random());
}

function getHash(data: any) {
    const seed = JSON.stringify(data) + licenseUuidForOperationId;
    return sha1(seed).toString();
}
