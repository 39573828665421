import { Comparison, sleep } from '@/services';

type TCompare<T = any> = (a: T, b: T) => Comparison;

const CHUNK_SIZE = 1000;

export async function asyncSort<T>(
    arr: T[],
    compare: TCompare<T>,
    left = 0,
    right = arr.length - 1,
) {
    if (left < right) {
        const pivotIndex = await partition();
        await asyncSort(arr, compare, left, pivotIndex - 1);
        await asyncSort(arr, compare, pivotIndex + 1, right);
    }
    return arr;

    async function partition<T>() {
        const middle = Math.floor((left + right) / 2);
        const pivot = getPivot(arr[left], arr[middle], arr[right]);

        let i = left - 1;
        let counter = 0;
        for (let j = left; j < right; j++) {
            if (++counter >= CHUNK_SIZE) {
                counter = 0;
                await sleep(0);
            }
            if (compare(arr[j], pivot) < 0) {
                i++;
                swapElements(arr, i, j);
            }
        }
        swapElements(arr, i + 1, right);
        return i + 1;
    }

    function getPivot(x: T, y: T, z: T) {
        if (compare(x, y) < 0) {
            if (compare(y, z) < 0) {
                return y;
            } else if (compare(z, x) < 0) {
                return x;
            } else {
                return z;
            }
        } else if (compare(y, z) > 0) {
            return y;
        } else if (compare(z, x) > 0) {
            return x;
        } else {
            return z;
        }
    }
}

function swapElements(arr: any[], i: number, j: number) {
    const length = arr.length;
    if (i < 0 || j < 0 || i >= length || j >= length) {
        return;
    }
    [arr[i], arr[j]] = [arr[j], arr[i]];
}
