import _ from 'lodash';
import Vue from 'vue';
import { ActionContext } from 'vuex';
import { TUuid } from '@/types/common';
import { IFilterSendValue } from '@/types/project/IFilterSendValue';
import { Dict } from '@/types/Dict';
import Router from '@/router';
import {
    DRAFT_KEY,
    EventTypeEnum,
    IssueFilterExpr,
    ISSUES_PER_PAGE,
    IssueSortBase,
    IssueStatusEnum,
    LOCAL_STORAGE_DRAFT_KEY,
} from '@/constants';
import ProjectApi from '@/api/project.api';
import { Issue, Project } from '@/models';
import {
    IIssueTrackerFilterValue,
    PresetFiltersStoreParams,
    TrackerFilters,
    TrackerFiltersStoreObj,
    TrackerFiltersStoreParams,
} from '@/models/ProjectIssuesFilters';
import { ISorting } from '@/services/IssueOrderParse';
import {
    convertDesktopFilterSetToTrackerFilters,
    getEmptyFilters,
    getOperationId,
    isFiltersForDeletedIssues,
    issueSort,
    issuesSortApiParams,
} from '@/services';
import { getIssueAssigneeMember } from '@/services/issueTracker/IssueAssigneeMember';
import applyFilterToIssues from '@/services/issueTracker/applyFilterToIssues';

const DefaultSynchronizedDate = '2001-01-01';

let issueRequestCount = 1;

export function filterIssues(issues: Issue[], filters: TrackerFilters) {
    return _.values(filters || {}).reduce((acc, filter) => applyFilterToIssues(filter, acc), issues);
}

export interface IssueOrderFields {
    id: number;
    uuid: string;
    dbid?: number;
    unread?: number;
    deadline?: string;
    updated?: string;
}

export enum IssueOrderRecordStatusEnum  {
    enable,
    disable,
}

export class IssueOrderRecord {
    public fields: IssueOrderFields;
    public status: IssueOrderRecordStatusEnum;

    public constructor(fields: IssueOrderFields) {
        delete fields.dbid; // No usages
        this.fields = fields;
        this.status = IssueOrderRecordStatusEnum.enable;
    }
}

const MaxCacheLifetime = 120000; // 2 minutes, but we still get updates from notifier

const IssueOrderRequestCache: {
    [projectId: number]: {
        timestamp: number;
        queryParams: any;
    }
} = {};

const addIssueOrderRequestCache = (projectId: number, queryParams: any) => {
    IssueOrderRequestCache[projectId] = {
        timestamp: Date.now(),
        queryParams,
    };
};
const removeIssueOrderRequestCache = (projectId: number) => {
    delete IssueOrderRequestCache[projectId];
};
const getIssueOrderRequestCache = (projectId: number, queryParams: any) => {
    const cache = IssueOrderRequestCache[projectId];

    if (cache) {
        if (cache.timestamp + MaxCacheLifetime > Date.now() && _.isEqual(cache.queryParams, queryParams)) {
            return true;
        }

        removeIssueOrderRequestCache(projectId);
    }

    return false;
};

const IssuesRequestCache: {
    [projectId: number]: {
        timestamp: number;
        queryParams: any;
    }
} = {};

const addIssueRequestCache = (projectId: number, queryParams: any) => {
    IssuesRequestCache[projectId] = {
        timestamp: Date.now(),
        queryParams,
    };
};
const removeIssueRequestCache = (projectId: number) => {
    delete IssuesRequestCache[projectId];
};
const getIssueRequestCache = (projectId: number, queryParams: any) => {
    const cache = IssuesRequestCache[projectId];

    if (cache) {
        if (cache.timestamp + MaxCacheLifetime > Date.now() && _.isEqual(cache.queryParams, queryParams)) {
            return true;
        }

        removeIssueRequestCache(projectId);
    }

    return false;
};

export interface IProjectIssuesStore {
    issuesObj: {
        [projectId: number]: {
            [issueUuid: string]: Issue;
        },
    };
    issuesCountObj: {
        [projectId: number]: number;
    };
    issuePagesObj: {
        [projectId: number]: number;
    };
    currentPagesObj: {
        [projectId: number]: number;
    };
    deletedIssuesObj: {
        [projectId: number]: Issue[];
    };
    isLoadingIssuesObj: {
        [projectId: number]: boolean;
    };
    selectedIssueObj: {
        [projectId: number]: Issue;
    };
    filtersObj: TrackerFiltersStoreObj;
    issuesSortObj: {
        [projectId: number]: ISorting;
    };
    issuesOrderObj: {
        [projectId: number]: IssueOrderRecord[][];
    };
    allIssuesOrdersIdsObj: {
        [projectId: number]: number[];
    };
    draftIssues: {
        [projectId: number]: string[];
    };
    openedFilter: string | null;
}

// load drafts from storage
const drafts: { [projectId: number]: string[] } = {};
let savedDrafts: any = localStorage.getItem(LOCAL_STORAGE_DRAFT_KEY);
if (savedDrafts) {
    savedDrafts = JSON.parse(savedDrafts);
    Object.keys(savedDrafts).forEach((projectId) => {
        drafts[Number(projectId)] = Object.keys(savedDrafts[Number(projectId)]);
    });
}

export default {
    state: {
        issuesObj: {},
        issuesCountObj: {},
        issuePagesObj: {},
        currentPagesObj: {},
        deletedIssuesObj: {},
        isLoadingIssuesObj: {},
        selectedIssueObj: {},
        filtersObj: {},
        issuesSortObj: {},
        issuesOrderObj: {},
        draftIssues: drafts,
        openedFilter: null,
        allIssuesOrdersIdsObj: {},
    } as IProjectIssuesStore,
    getters: {
        openedFilter(state: IProjectIssuesStore) {
            return state.openedFilter;
        },
        isIssuesLoaded(state: IProjectIssuesStore): (projectId: number) => any {
            return (projectId) => {
                return state.issuesObj.hasOwnProperty(projectId);
            };
        },
        issuesUuidById(state: IProjectIssuesStore): (projectId: number) => any {
            return (projectId) => {
                const issues = state.issuesObj[projectId] || {};
                const uuids = Object.keys(issues);
                const result: { [key: number]: string } = {};
                uuids.forEach((uuid: string) => {
                   result[issues[uuid].id] = uuid;
                });
                return result;
            };
        },
        issuesByProjectId(state: IProjectIssuesStore): (projectId: number) => Issue[] {
            return (projectId) => {
                const issues = state.issuesObj[projectId] ? Object.values(state.issuesObj[projectId]) : [];
                const filteredIssues = filterIssues(issues, state.filtersObj[projectId]);

                filteredIssues.forEach((issue: Issue) => {
                    const assignee = getIssueAssigneeMember(issue);
                    const fullname = assignee?.fullname || String.fromCharCode(0x10FFFF); // last unicode symbol
                    const parts = fullname.split(/\s+/);
                    const firstname = parts[0];
                    const lastname = parts.slice(1).join(' ');
                    issue.assigneeInfo = { firstname, lastname, email: issue.assignee };
                });
                return issueSort(filteredIssues, state.issuesSortObj[projectId]);
            };
        },
        draftIssues(state: IProjectIssuesStore): (projectId: number) => string[] {
            return (projectId) => state.draftIssues[projectId] || [];
        },
        issuesPageByProjectId(state: IProjectIssuesStore, getters: any): (projectId: number) => Issue[] {
            return (projectId) => {
                const issues = state.issuesObj[projectId] || {};
                let uuids = getters.issueUuidsByProjectAndPage(projectId, state.currentPagesObj[projectId] || 1);

                if (Router.currentRoute.query?.filter === DRAFT_KEY) {
                    if (state.draftIssues[projectId] && state.draftIssues[projectId].length > 0) {
                        const uuidObject = getters.issuesUuidById(projectId);
                        const draftUuids: string[] = state.draftIssues[projectId].map((id: string) => uuidObject[id]);
                        uuids = uuids.filter((uuid: string) => draftUuids.includes(uuid));
                    }
                }

                const sortIssues = [] as Issue[];
                _.forEach(uuids, (uuid: string) => {
                    if (issues[uuid]) {
                        sortIssues.push(issues[uuid]);
                    } else {
                        // 'issue not found'
                    }
                });

                sortIssues.forEach((issue) => {
                    const assignee = getIssueAssigneeMember(issue);
                    const fullname = assignee?.fullname || String.fromCharCode(0x10FFFF); // last unicode symbol
                    const parts = fullname.split(/\s+/);
                    const firstname = parts[0];
                    const lastname = parts.slice(1).join(' ');
                    issue.assigneeInfo = { firstname, lastname, email: issue.assignee };
                });

                return Object.values(sortIssues);
            };
        },
        allIssuesByProjectId(state: IProjectIssuesStore): (projectId: number) => Issue[] {
            return (projectId) => {
                return state.issuesObj[projectId]
                    ? Object.values(state.issuesObj[projectId])
                    : [];
            };
        },
        allIssuesByProjectIdObj(state: IProjectIssuesStore, getters: any): (projectId: number) => Dict<Issue> {
            return (projectId) => {
                return state.issuesObj[projectId]
                    ? _.keyBy(getters.allIssuesByProjectId(projectId), 'uuid')
                    : {};
            };
        },
        deletedIssuesByProjectIdObj(state: IProjectIssuesStore, getters: any): (projectId: number) => Dict<Issue> {
            return (projectId) => {
                return state.deletedIssuesObj[projectId]
                    ? _.keyBy(getters.deletedIssuesByProjectId(projectId), 'uuid')
                    : {};
            };
        },
        issuesCountByProjectId(state: IProjectIssuesStore): (projectId: number) => number {
            return (projectId) => state.issuesCountObj[projectId] || 0;
        },
        issuesSortByProjectId(state: IProjectIssuesStore): (projectId: number) => ISorting {
            return (projectId) => state.issuesSortObj[projectId] || {};
        },
        deletedIssuesByProjectId(state: IProjectIssuesStore): (projectId: number) => Issue[] {
            return (projectId) => state.deletedIssuesObj[projectId]
                ? issueSort(Object.values(state.deletedIssuesObj[projectId]), state.issuesSortObj[projectId])
                : [];
        },
        isDeletedIssuesByProjectId(state: IProjectIssuesStore): (projectId: number) => boolean {
            return (projectId) => state.deletedIssuesObj[projectId] && state.deletedIssuesObj[projectId].length !== 0;
        },
        selectedIssueByProjectId(state: IProjectIssuesStore): (projectId: number) => Issue {
            return (projectId) => state.selectedIssueObj[projectId];
        },
        issuePagesObjByProjectId(state: IProjectIssuesStore): (projectId: number) => number {
            return (projectId) => state.issuePagesObj[projectId] || 0;
        },
        currentPageObjByProjectId(state: IProjectIssuesStore): (projectId: number) => number {
            return (projectId) => state.currentPagesObj[projectId] || 1;
        },
        isLoadingIssuesByProjectId(state: IProjectIssuesStore): (projectId: number) => boolean {
            return (projectId) => Boolean(state.isLoadingIssuesObj[projectId]);
        },
        trackerFiltersByProjectId(state: IProjectIssuesStore): (projectId: number) => TrackerFilters {
            return (projectId) => state.filtersObj[projectId] ?? getEmptyFilters();
        },
        rawTrackerFiltersByProjectId(state: IProjectIssuesStore): (projectId: number) => TrackerFilters | undefined {
            return (projectId) => state.filtersObj[projectId];
        },
        trackerFiltersApiParams(): (trackerFilters: TrackerFilters, userEmail: string) => IFilterSendValue[] {
            return (trackerFilters, userEmail) => {
                const filters: IFilterSendValue[] = Object.entries(trackerFilters).reduce((acc, [, filter]) => {
                    if ((filter as IIssueTrackerFilterValue).isActive) {
                        acc.push((filter as IIssueTrackerFilterValue).getApiParams(userEmail) as any);
                    }

                    return acc;
                }, [] as IFilterSendValue[]);
                return filters;
            };
        },
        issueUuidsByProjectAndPage(state: IProjectIssuesStore): (projectId: number, page: number) => string[] {
            return (projectId: number, page: number) => {
                const order = state.issuesOrderObj[projectId] || [];
                if (order.length < page) {
                    page = order.length;
                }

                return (order[page - 1] || [])
                    .filter((data: IssueOrderRecord) => data.status === IssueOrderRecordStatusEnum.enable)
                    .map((data: IssueOrderRecord) => data.fields.uuid as string);
            };
        },
        issuesOrderPropertyByProjectAndPage(state: IProjectIssuesStore): (projectId: number, page: number, propertyName: string) => Array<string|number> {
            return (projectId: number, page: number, propertyName: string = 'uuid') => {
                const order = state.issuesOrderObj[projectId] || [];
                if (order.length < page) {
                    page = order.length;
                }
                if (page === 0) {
                    page = 1;
                }

                return (order[page - 1] || [])
                    .filter((data: IssueOrderRecord) => data.status === IssueOrderRecordStatusEnum.enable)
                    .map((data: IssueOrderRecord) => data.fields[propertyName as keyof IssueOrderFields] as string|number);
            };
        },
        allIssuesOrderIds(state: IProjectIssuesStore): (projectId: number) => any[] {
            return (projectId: number) => {
                return state.allIssuesOrdersIdsObj[projectId] || [];
            };
        },
        issuesOrder(state: IProjectIssuesStore): (projectId: number) => any[] {
            return (projectId: number) => {
                const allRecords = state.issuesOrderObj[projectId] || [];
                const result: number[] = [];
                allRecords.forEach((item: any) => {
                    item.forEach((record: any) => {
                        result.push(record.fields.id);
                    });
                });

                return result;
            };
        },
        searchIssueFromOrder(state: any): ({ projectId, issueUuid, statusToSearch }: any) => number {
            return ({ projectId, issueUuid, statusToSearch = IssueOrderRecordStatusEnum.enable }) => {
                const currentOrder = state.issuesOrderObj[projectId] || [];
                let foundNumber = -1;
                _.forEach(currentOrder, (page) => {
                    const issue = page.find((issueData: IssueOrderRecord) => issueData.fields.uuid === issueUuid);
                    if (issue) {
                        foundNumber = Number(issue.status === statusToSearch);
                        return false;
                    }
                });
                return foundNumber;
            };
        },
    },
    mutations: {
        setIssues(state: IProjectIssuesStore, { projectId, issues }: { projectId: number, issues: Issue[] }) {
            Vue.set(state.issuesObj, projectId, issues);
        },
        setAllIssuesOrdersIds(state: IProjectIssuesStore, { projectId, orders }: { projectId: number, orders: any[] }) {
            Vue.set(state.allIssuesOrdersIdsObj, projectId, orders.map((order: any) => order.id));
        },
        setDraftIssues(state: IProjectIssuesStore, { projectId, issues }: { projectId: number, issues: string[] }) {
            Vue.set(state.draftIssues, projectId, issues);
        },
        setIssuesCount(state: IProjectIssuesStore, { projectId, issuesCount }: { projectId: number, issuesCount: number}) {
            Vue.set(state.issuesCountObj, projectId, issuesCount);
        },
        addIssues(state: IProjectIssuesStore, { projectId, issues }: { projectId: number, issues: Issue[] }) {
            const newIssues = issues.reduce((acc, next) => {
                acc[next.uuid] = next;
                return acc;
            }, {} as { [uuid: string]: Issue });
            const currentIssues = state.issuesObj[projectId] ?? {};
            const allIssues = {
                ...currentIssues,
                ...newIssues,
            };
            Vue.set(state.issuesObj, projectId, allIssues);
        },
        removeIssue(state: IProjectIssuesStore, { projectId, issueUuid }: { projectId: number, issueUuid: TUuid }) {
            if (!state.issuesObj[projectId]) {
                return;
            }

            Vue.delete(state.issuesObj[projectId], issueUuid);
        },
        removeIssues(state: IProjectIssuesStore, { projectId, issues }: { projectId: number, issues: Issue[] }) {
            if (!state.issuesObj[projectId]) {
                return;
            }

            for (const issue of Object.values(issues)) {
                Vue.delete(state.issuesObj[projectId], issue.uuid);
            }
        },
        setDeletedIssues(state: IProjectIssuesStore, { projectId, issues }: { projectId: number, issues: Issue[] }) {
            Vue.set(state.deletedIssuesObj, projectId, issues);
        },
        removeDeletedIssue(state: IProjectIssuesStore, { projectId, issueUuid }: { projectId: number, issueUuid: TUuid }) {
            if (!state.deletedIssuesObj[projectId]) {
                return;
            }

            Vue.delete(state.deletedIssuesObj[projectId], issueUuid);
        },
        setSelectedIssue(state: IProjectIssuesStore, { projectId, issue }: { projectId: number, issue: Issue }) {
            Vue.set(state.selectedIssueObj, projectId, issue);
        },
        setIssuePages(state: IProjectIssuesStore, { projectId, value }: { projectId: number, value: number }) {
            Vue.set(state.issuePagesObj, projectId, value);
        },
        setCurrentPage(state: IProjectIssuesStore, { projectId, page }: { projectId: number, page: number }) {
            Vue.set(state.currentPagesObj, projectId, page);
        },
        setIsLoadingIssuesObj(state: IProjectIssuesStore, { projectId, value }: { projectId: number, value: boolean }) {
            Vue.set(state.isLoadingIssuesObj, projectId, value);
        },
        addTrackerFilters(state: IProjectIssuesStore, { projectId, filters }: TrackerFiltersStoreParams) {
            Vue.set(state.filtersObj, projectId, { ...state.filtersObj[projectId], ...filters });
        },
        setTrackerFilters(state: IProjectIssuesStore, { projectId, filters }: TrackerFiltersStoreParams) {
            Vue.set(state.filtersObj, projectId, filters);
        },
        setIssueSort(state: IProjectIssuesStore, { projectId, sort }: { projectId: number, sort: ISorting }) {
            Vue.set(state.issuesSortObj, projectId, sort);
        },
        resetIssueSort(state: IProjectIssuesStore, { projectId }: any) {
            Vue.set(state.issuesSortObj, projectId, _.cloneDeep(IssueSortBase));
        },
        setIssueOrder(state: IProjectIssuesStore, { projectId, order }: any) {
            Vue.set(state.issuesOrderObj, projectId, order);
        },
        setOpenedFilter(state: IProjectIssuesStore, filterType: string) {
            state.openedFilter = filterType;
        },
    },
    actions: {
        loadIssuesByProjectId(
            { getters, commit, rootGetters }: any,
            { projectId, page = 0 }: { projectId: number, page: number },
        ) {
            return new Promise((resolve, reject) => {
                const ids = getters.issuesOrderPropertyByProjectAndPage(projectId, Math.max(page, 0), 'id');
                const params: any = {
                    synchronized: DefaultSynchronizedDate,
                    page: 0,
                    alwaysFiltersDTO: [{
                        expr: IssueFilterExpr.IN,
                        type: 'id',
                        value: ids,
                    }],
                    reportSort: issuesSortApiParams(getters.selectedIssueFilterPresetByProjectId(projectId)?.sorting ?? getters.issuesSortByProjectId(projectId)),
                };

                const project = rootGetters.projectById(projectId) as Project;
                const projectUuid = project?.uuid;
                if (!projectUuid) {
                    reject(new Error('Project not found with ID ' + projectId));
                }

                const hasCached = getIssueRequestCache(projectId, params);
                if (hasCached) {
                    commit('setIsLoadingIssuesObj', { projectId, value: false });
                    resolve();
                    return;
                }

                commit('setIsLoadingIssuesObj', { projectId, value: true });

                ProjectApi.postGetIssues(projectUuid, params).then((response) => {
                    const issues = response.data
                        .filter((issue: any) => issue.status.value !== 'deleted')
                        .map((issue: any) => new Issue(issue));

                    // if we have arrived from dashboard, clean issues
                    if ((getters.issueTrackerLink?.graphUuid || getters.issueTrackerLink?.lineUuid) && !page) {
                        commit('setIssues', { projectId, issues: [] });
                    }

                    commit('addIssues', { projectId, issues });
                    if (!getters.selectedIssueByProjectId(projectId) || getters.selectedIssueByProjectId(projectId)?.status === IssueStatusEnum.deleted) {
                        commit('setSelectedIssue', { projectId, issue: getters.issuesPageByProjectId(projectId)[0] });
                    }
                    commit('setIsLoadingIssuesObj', { projectId, value: false });

                    if (issues.length === 0) {
                        commit('setSelectedIssue', { projectId, issue: null });
                    }
                    commit('setIsLoadingIssuesObj', { projectId, value: false });

                    addIssueRequestCache(projectId, params);
                    resolve(issues);
                }).catch((error) => {
                    reject(error);

                    commit('setIsLoadingIssuesObj', { projectId, value: false });
                });
            });
        },
        reloadIssuesById(
            { state, rootGetters }: any,
            { projectId, issueId }: { projectId: number, issueId: number },
        ) {
            return new Promise((resolve, reject) => {
                const params: any = {
                    synchronized: DefaultSynchronizedDate,
                    page: 0,
                    alwaysFiltersDTO: [{
                        expr: IssueFilterExpr.IN,
                        type: 'id',
                        value: [issueId],
                    }],
                };

                const project = rootGetters.projectById(projectId) as Project;
                const projectUuid = project?.uuid;

                if (!projectUuid) {
                    reject(new Error('Project not found with ID ' + projectId));
                }

                // tslint:disable-next-line:no-shadowed-variable
                const request = (projectId: number) => ProjectApi.postGetIssues(projectUuid, params).then((response) => {
                    const issues = response.data
                        .filter((issue: any) => issue.status.value !== 'deleted')
                        .map((issue: any) => new Issue(issue));

                    if (issues.length === 1) {
                        Vue.set(state.issuesObj[projectId], issues[0].uuid, issues[0]);

                        if (state.selectedIssueObj[projectId]?.id === issues[0]?.id) {
                            Vue.set(state.selectedIssueObj, projectId, issues[0]);
                        }
                    }

                    resolve(issues);
                }).catch((error) => {
                    reject(error);
                });

                request(projectId);
            });
        },
        async updateTrackerFilters(
            { commit, dispatch, rootGetters }: ActionContext<IProjectIssuesStore, any>,
            { projectId, filters, isAdd }: TrackerFiltersStoreParams,
        ) {
            if (!filters) {
                return;
            }

            commit(isAdd ? 'addTrackerFilters' : 'setTrackerFilters', { projectId, filters });
            commit('setCurrentPage', { projectId, page: 1 });
            commit('setIsLoadingIssuesObj', { projectId, value: true });

            const project = rootGetters.projectById(projectId) as Project;
            if (!project?.uuid) {
                await dispatch('loadProjectById', { projectId });
            }

            if (!isFiltersForDeletedIssues(filters)) {
                dispatch('loadIssuesOrderByProjectId', { projectId })
                    .then(() => {
                        dispatch('loadIssuesByProjectId', { projectId });
                        commit('setDeletedIssues', { projectId, issues: [] });
                    })
                    .catch(() => {
                        commit('setIsLoadingIssuesObj', { projectId, value: false });
                    });
            } else {
                removeIssueRequestCache(projectId);
                removeIssueOrderRequestCache(projectId);
                dispatch('loadDeletedIssues', { projectId, params: { reportSort: issuesSortApiParams(IssueSortBase) } });
            }
        },
        updatePresetFilters({ dispatch }: ActionContext<IProjectIssuesStore, any>, { projectId, presetFilters }: PresetFiltersStoreParams) {
            dispatch('updateTrackerFilters', { projectId, filters: presetFilters });
        },
        resetTrackerFilters({ commit, dispatch }: ActionContext<IProjectIssuesStore, any>, { projectId }: TrackerFiltersStoreParams) {
            commit('setTrackerFilters', { projectId, filters: getEmptyFilters() });
            dispatch('loadIssuesOrderByProjectId', { projectId });
            dispatch('loadIssuesByProjectId', { projectId });
        },
        updateTrackerPage(
            { commit, dispatch }: ActionContext<IProjectIssuesStore, any>,
            { projectId, page, filters }: { projectId: number; page: number, filters: TrackerFilters },
        ) {
            commit('setIsLoadingIssuesObj', { projectId, value: true });
            commit('setCurrentPage', { projectId, page });

            if (!isFiltersForDeletedIssues(filters)) {
                dispatch('loadIssuesByProjectId', { projectId, page });
            } else {
                dispatch('loadDeletedIssues', { projectId, params: { page: page - 1 } });
            }
        },
        loadDeletedIssues({ commit, getters, rootGetters }: any, { projectId, params }: { projectId: number, params: any }) {
            const requestCount = ++issueRequestCount;
            commit('setIsLoadingIssuesObj', { projectId, value: true });
            const filters = getters.trackerFiltersApiParams(getters.trackerFiltersByProjectId(projectId), rootGetters.userData.email);

            ProjectApi.getDeletedIssues(projectId, { ...params, alwaysFiltersDTO: filters }).then((response) => {
                if (response.data && requestCount === issueRequestCount) {
                    commit('setIssuesCount', { projectId, issuesCount: response.count });
                    commit('setIssuePages', { projectId, value: response.pages });
                    const issues = response.data.map((issue: any) => new Issue(issue));
                    commit('setDeletedIssues', {  projectId, issues });
                    commit('setIsLoadingIssuesObj', { projectId, value: false });
                    commit('setSelectedIssue', { projectId, issue: issues[0] });
                }
            });
        },
        undeleteIssue({ commit, getters, rootGetters }: any, { projectId, issueUuid }: { projectId: number, issueUuid: string }) {
            const operationId = getOperationId({ projectId, eventType: EventTypeEnum.ISSUE_RESTORED });
            return ProjectApi.undeleteIssue({
                projectId,
                issueUuid,
                operationId,
                notifierUserId: rootGetters.notifierActorId,
            }).then((response) => {
                if (getters.selectedIssueByProjectId(projectId)?.uuid === issueUuid) {
                    commit('setSelectedIssue', { projectId, issue: null });
                }

                commit('removeDeletedIssue', { projectId, issueUuid });
                commit('removeCommentsForIssue', { projectId, issueUuid });

                return response;
            });
        },
        async readIssue({ getters }: any, { projectId, issueUuid }: { projectId: number, issueUuid: string }) {
            if (getters.deletedIssuesByProjectId(projectId).length) {
                return;
            }
            await ProjectApi.readIssue({ projectId, issueUuid });
            const issues = getters.issuesByProjectId(projectId);
            const issue = issues.find((anIssue: Issue) => anIssue.uuid === issueUuid);
            if (issue) {
                Vue.set(issue.read, 'issue', false);
            }
        },
        async readIssueComments({ getters }: any, { projectId, issueUuid, count }: { projectId: number, issueUuid: string, count: number }) {
            if (getters.deletedIssuesByProjectId(projectId).length) {
                return;
            }
            await ProjectApi.readIssueComments({ projectId, issueUuid, count });
            const issues = getters.issuesByProjectId(projectId);
            const issue = issues.find((anIssue: Issue) => anIssue.uuid === issueUuid);
            if (issue) {
                Vue.set(issue.read, 'comments', issue.read.comments - count);
            }
        },
        sendFiltersForHash(context: any, { projectId, filters, sorting }: { projectId: number, filters: string, sorting: string }) {
            return ProjectApi.postAddTemporary({ projectId, filters, sorting });
        },
        getFiltersFromHash(context: any, { projectId, hash }: { projectId: number, hash: string }) {
            return ProjectApi.getTemporary({ projectId, hash });
        },
        postIssuesToPdf(context: any, { projectId, params }: { projectId: number, params: any }) {
            return ProjectApi.postIssuesToPdf({ projectId, params }).then((response) => response.data);
        },
        deleteIssue({ commit, getters, rootGetters }: any, { projectId, issueUuid }: { projectId: number, issueUuid: string }) {
            const operationId = getOperationId({ projectId, eventType: EventTypeEnum.ISSUE_DELETED });
            return ProjectApi.postDeleteIssue({
                projectId,
                issueUuid,
                operationId,
                notifierUserID: rootGetters.notifierActorId,
            })
                .then((response) => {
                    if (getters.selectedIssueByProjectId(projectId)?.uuid === issueUuid) {
                        commit('setSelectedIssue', { projectId, issue: null });
                    }

                    commit('removeIssue', { projectId, issueUuid });
                    commit('removeCommentsForIssue', { projectId, issueUuid });

                    return response;
                });
        },
        loadIssuesOrderByProjectId({ getters, commit, rootGetters }: any, { projectId }: { projectId: number }) {
            return new Promise((resolve, reject) => {
                const requestCount = ++issueRequestCount;
                const isIssueTrackerLink = getters.issueTrackerLink?.graphUuid || getters.issueTrackerLink?.lineUuid;
                let alwaysFiltersDTO = isIssueTrackerLink ?
                    getters.issueTrackerLink?.alwaysFiltersDTO :
                    getters.trackerFiltersApiParams(getters.trackerFiltersByProjectId(projectId), rootGetters.userData.email);

                if (Router.currentRoute.query?.filter === DRAFT_KEY) {
                    alwaysFiltersDTO = [{
                        expr: IssueFilterExpr.IN,
                        type: 'id',
                        value: getters.draftIssues(projectId),
                    }];
                }

                let issueIds: any = [];
                alwaysFiltersDTO.forEach((filter: any, index: number) => {
                    if (filter.type === 'id') {
                        issueIds = [...filter.value.map(Number)];
                        alwaysFiltersDTO.splice(index, 1);
                    }
                });

                const params: any = {
                    synchronized: DefaultSynchronizedDate,
                    alwaysFiltersDTO,
                    reportSort: issuesSortApiParams(getters.selectedIssueFilterPresetByProjectId(projectId)?.sorting ?? getters.issuesSortByProjectId(projectId)),
                };

                if (issueIds.length > 0) {
                    params.issueIds = JSON.stringify(issueIds);
                }

                if (isIssueTrackerLink) {
                    if (getters.issueTrackerLink?.graphUuid) {
                        params.graphUuid = getters.issueTrackerLink?.graphUuid;
                    }
                    if (getters.issueTrackerLink?.statuses) {
                        params.statuses = getters.issueTrackerLink?.statuses;
                    }
                    if (getters.issueTrackerLink?.lineUuid) {
                        params.lineUuid = getters.issueTrackerLink?.lineUuid;
                    }
                    if (getters.issueTrackerLink?.dotTimestamp) {
                        params.dotTimestamp = getters.issueTrackerLink?.dotTimestamp;
                    }
                    if (getters.issueTrackerLink?.groupBy) {
                        params.groupBy = getters.issueTrackerLink?.groupBy;
                    }
                }

                const tmpParams: any = [];
                params.alwaysFiltersDTO.forEach((item: any, index: any) => {
                    const itemString = JSON.stringify(item);
                    if (tmpParams.includes(itemString)) {
                        params.alwaysFiltersDTO.splice(index, 1);
                    }
                    tmpParams.push(itemString);
                });

                const hasCachedOrder = getIssueOrderRequestCache(projectId, params);
                if (hasCachedOrder) {
                    resolve();
                    return;
                }

                ProjectApi.postGetIssueOrder(projectId, params).then((response) => {
                    if (alwaysFiltersDTO.length === 0 || Router.currentRoute.query?.filter === DRAFT_KEY) {
                        commit('setAllIssuesOrdersIds', { projectId, orders: response.entities });
                    }

                    if (requestCount === issueRequestCount) {
                        const records = response.entities.map((record: any) => new IssueOrderRecord(record)) as IssueOrderRecord[];
                        const order = [];

                        for (let i = 0; i < records.length; i += ISSUES_PER_PAGE) {
                            order.push(records.slice(i, i + ISSUES_PER_PAGE));
                        }

                        commit('setIssueOrder', { projectId, order });
                        commit('setIssuesCount', { projectId, issuesCount: response.count });
                        commit('setIssuePages', { projectId, value: order.length });
                    }

                    addIssueOrderRequestCache(projectId, params);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        addIssueToOrderListToProject(
            { state, getters, commit }: any,
            { projectId, changedIssue }: { projectId: number, changedIssue: Issue},
        ) {
            const currentPage = getters.currentPageObjByProjectId(projectId);
            const preset = getters.selectedIssueFilterPresetByProjectId(projectId);

            let isPresetHasUnread = false;
            let issueCurrentPage: number = -1;

            if (preset) {
                if (preset.isUnread) {
                    isPresetHasUnread = true;
                } else {
                    const filteredIssues = filterIssues(
                        getters.allIssuesByProjectId(projectId),
                        convertDesktopFilterSetToTrackerFilters(preset.filters),
                    );
                    isPresetHasUnread = filteredIssues
                        .filter((checkIssue: Issue) => checkIssue.read.issue || (checkIssue.read.comments > 0))
                        .length > 0;
                }
            }

            const currentOrder = _.cloneDeep(state.issuesOrderObj[projectId]) || [];
            _.forEach(currentOrder, (page, key) => {
                const issue = page.find((issueData: IssueOrderRecord) => issueData.fields.uuid === changedIssue.uuid);
                if (issue) {
                    issueCurrentPage = key as any;
                    return false;
                }
            });

            if ((issueCurrentPage > -1)
            && (((issueCurrentPage + 1) === currentPage) || ((issueCurrentPage + 1) < currentPage) && isPresetHasUnread)
            ) {
                return;
            }

            let pageToAdd = 1;
            if (isPresetHasUnread) {
                pageToAdd = getters.currentPageObjByProjectId(projectId) + 1;
                if (pageToAdd  > (currentOrder.length)) {
                    pageToAdd = currentOrder.length;
                }
            }
            if (currentOrder.length === 0) {
                currentOrder.push([]);
            }

            if (issueCurrentPage > -1) {
                currentOrder[issueCurrentPage] = currentOrder[issueCurrentPage].filter((issueData: IssueOrderRecord) => issueData.fields.uuid !== changedIssue.uuid);

            }
            currentOrder[pageToAdd - 1].unshift(new IssueOrderRecord({ id: changedIssue.id, uuid: changedIssue.uuid }));

            commit('setIssueOrder', { projectId, order: currentOrder });
        },
        disableIssueFromOrder({ state }: any, { projectId, issueUuid }: any) {
            const currentOrder = state.issuesOrderObj[projectId] || [];
            let found = false;
            _.forEach(currentOrder, (page) => {
                const issue = page.find((issueData: IssueOrderRecord) => issueData.fields.uuid === issueUuid);
                if (issue) {
                    issue.status = IssueOrderRecordStatusEnum.disable;
                    Vue.set(state.issuesOrderObj, projectId, currentOrder);
                    found = true;
                    return false;
                }
            });
            return found;
        },
        enableIssueFromOrder({ state }: any, { projectId, issueUuid }: any) {
            const currentOrder = state.issuesOrderObj[projectId] || [];
            let found = false;
            _.forEach(currentOrder, (page) => {
                const issue = page.find((issueData: IssueOrderRecord) => issueData.fields.uuid === issueUuid);
                if (issue) {
                    issue.status = IssueOrderRecordStatusEnum.enable;
                    Vue.set(state.issuesOrderObj, projectId, currentOrder);
                    found = true;
                    return false;
                }
            });
            return found;
        },
        clearProjectIssuesStates({ state }: any, projectId: number) {
            Vue.delete(state.issuesObj, projectId);
            Vue.delete(state.issuesCountObj, projectId);
            Vue.delete(state.issuePagesObj, projectId);
            Vue.delete(state.currentPagesObj, projectId);
            Vue.delete(state.deletedIssuesObj, projectId);
            Vue.delete(state.isLoadingIssuesObj, projectId);
            Vue.delete(state.selectedIssueObj, projectId);
            Vue.delete(state.filtersObj, projectId);
            Vue.delete(state.issuesSortObj, projectId);
            Vue.delete(state.issuesOrderObj, projectId);
            Vue.delete(state.draftIssues, projectId);
        },
    },
};
