




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

const IconComponentBySize = {
    '24': 'IconSvg24',
    '16': 'IconSvg16',
};

@Component({
    components: {
        IconSvg16,
        IconSvg24,
    },
})
export default class WsMenuIcon extends Vue {
    @Prop({ default: 'more-vertical' }) public iconName!: string;
    @Prop() public logEvent!: string;
    @Prop() public color!: string;
    @Prop() public colorActive!: string;
    @Prop({ type: Boolean }) public passive!: boolean;
    @Prop() public trigger!: number;
    @Prop({
        default: '24',
        validator(value: any): boolean {
            return value === '24' || value === '16';
        },
    }) public size!: string;

    public isOpen = false;
    public readonly IconComponentBySize = IconComponentBySize;

    @Watch('trigger')
    public onTrigger() {
        if (!this.isOpen) {
            this.isOpen = true;
        }
    }

    public openMenu() {
        if (this.logEvent) {
            amplitudeLog(this.logEvent === 'license' ? AmplitudeEvent.licenseDashboardOpenChartMenu : AmplitudeEvent.projectDashboardOpenChartMenu);
        }

        this.isOpen = true;
    }
}
