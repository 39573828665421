






























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import WsSkeletonLoader from '@/components/common/skeleton/WsSkeletonLoader.vue';

@Component({
    components: {
        WsSkeletonLoader,
    },
})
export default class WsImage extends Vue {
    @Prop({ type: String, required: false }) public src!: string;
    @Prop({ type: String, required: false }) public id!: string;
    @Prop({ type: String, required: false }) public reference !: string;
    @Prop({ type: Number }) public width !: number;
    @Prop({ type: Number }) public height !: number;

    public isLoading = true;
    public element: any = null;
    public isError = false;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get imageId(): string {
        return 'ws-image-' + (this.id ?? uuid());
    }

    get s3enabled(): boolean {
        return this.$store.getters.s3enabled;
    }

    @Emit()
    public load() {
        return;
    }

    @Emit()
    public error() {
        return;
    }

    @Emit()
    public click() {
        if (!this.isError) {
            return;
        }
    }

    @Watch('src')
    public onSourceChange(source: string) {
        this.isLoading = true;
        this.setImage(source);
    }

    public async mounted() {
        this.element = document.querySelector('#' + this.imageId) as HTMLImageElement;
        if (this.src) {
            await this.setImage(this.src);
        }
    }

    public async downloadImage(element: HTMLImageElement, source: string) {
        try {
            if (!element) {
                return;
            }

            const base64 = await this.$store.getters.s3InstanceByProjectId(this.projectId).s3loadImageInBase64(source);
            if (base64) {
                element.src = base64;
            } else {
                this.isError = true;
            }

            this.isLoading = false;
        } catch (error) {
            this.isError = true;
        }
    }

    public async setImage(source: string) {
        if (this.s3enabled) {
            await this.downloadImage(this.element, source);
        } else {
            this.isLoading = false;
        }
    }

    public async reload() {
        this.isError = false;
        await this.setImage(this.src);
    }

}
