































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IComboboxOption } from '@/types/common';
import { i18n } from '@/services/i18n';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsCheckbox2 from './WsCheckbox2.vue';

@Component({
    components: {
        WsTooltip,
        WsCheckbox2,
    },
})
export default class WsMultiSelect extends Vue {
    @Prop({ required: true, default: () => [] }) public item!: IComboboxOption;
    @Prop({ default: i18n.t('Simple_word.disabled') }) public disabledTooltip!: string;
    @Prop({ type: Boolean, default: true }) public isActive!: boolean;
    @Prop({ type: Boolean, default: true }) public isItemsSlots!: boolean;

    @Emit()
    public click() {}
}

