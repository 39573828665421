



























import { Component, Prop } from 'vue-property-decorator';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/components/project/issueTracker/details/IssueDetailsEditPopover.vue';
import IssuePriorityItem from '@/components/project/issueTracker/IssuePriorityItem.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
        IssueDetailsEditPopover,
        IssuePriorityItem,
    },
})
export default class IssueDetailsPriority extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    get itemsForSelect(): Array<{ text: string; value: string }> {
        return this.items.map((value) => ({ text: value, value }));
    }

    public handleSelect(value: string) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
        }
    }
}
