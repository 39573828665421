import { FormItemType } from '@/constants';

export interface IFormCheckbox {
    name: string;
    translationCode: string;
    value?: boolean;
}

export class FormCheckbox {
    public readonly type = FormItemType.checkbox;
    public readonly name: string;
    public readonly value: boolean;
    public readonly translationCode: string;

    constructor(params: IFormCheckbox) {
        this.name = params.name;
        this.value = Boolean(params.value);
        this.translationCode = params.translationCode;
    }
}
