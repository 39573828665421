import _ from 'lodash';
import { AnyFunction } from '@/types/AnyFunction';
import { SortType } from '@/constants';
import { UnreachableError } from '@/models';
import { compareNumbers, compareStrings } from '@/services';

function getStringSortComparator<T>(isOrderDescending: boolean, sortKey: string, makeElemFn: AnyFunction) {
    return function compareStringsFn(a: T, b: T): number {
        const stringA = String(makeElemFn(_.get(a, sortKey)));
        const stringB = String(makeElemFn(_.get(b, sortKey)));
        const comparisonResult = compareStrings(stringA, stringB);
        return isOrderDescending ? comparisonResult : -comparisonResult;
    };
}

function getNumericSortComparator<T>(isOrderDescending: boolean, sortKey: string, makeElemFn: AnyFunction) {
    return function compareNumerically(a: T, b: T): number {
        const numA = Number(makeElemFn(_.get(a, sortKey)));
        const numB = Number(makeElemFn(_.get(b, sortKey)));
        const comparisonResult = compareNumbers(numA, numB);
        return isOrderDescending ? comparisonResult : -comparisonResult;
    };
}

const isEmptyValue = _.memoize(function isEmptyValueFn(value: any) {
    if ([undefined, null].includes(value)) {
        return true;
    }
    const stringValue = String(value).trim();
    return ['', '-'].includes(stringValue);
});

function getSortType<T>(arr: T[], sortKey: string) {
    const values = arr.map((elem) => _.get(elem, sortKey));
    if (values.every((numberCandidate) => !Number.isNaN(Number(numberCandidate)))) {
        return SortType.numeric;
    } else {
        return SortType.string;
    }
}

export function sortArrayOfObjectsWithTail<T>(
    items: T[],
    sortKey: string,
    tailCallbacks: Array<((field: any) => boolean)> = [],
    isOrderDescending = false,
    makeElemFn = (x: any) => x,
) {
    const getItemCallback = (item: T) => (callback: (field: any) => boolean) => callback(_.get(item, sortKey));
    const [tail, head] = _.partition(items, (item) => tailCallbacks.some(getItemCallback(item)));
    const sortType = getSortType<T>(head, sortKey);

    const getComparator = (() => {
        switch (sortType) {
            case SortType.numeric:
                return getNumericSortComparator;
            case SortType.string:
                return getStringSortComparator;
            default: {
                const _never: never = sortType;
                throw new UnreachableError(sortType);
            }
        }
    })();

    const comparator = getComparator(isOrderDescending, sortKey, makeElemFn);
    head.sort(comparator);

    const tailSorted = tailCallbacks.reduce((acc, callback) => {
        const [tailItem, head] = _.partition(acc, (item) => getItemCallback(item)(callback));
        return [...head, ...tailItem];
    }, tail);

    return [...head, ...tailSorted];
}

export function sortArrayOfObjects<T>(
    items: T[],
    sortKey: string,
    isOrderDescending = false,
    sortType = SortType.none,
    makeElemFn = (x: any) => x,
    isZerosToEnd = true,
) {
    const [tail, head] = isZerosToEnd
        ? _.partition(items, (item) => isEmptyValue(_.get(item, sortKey)))
        : [[], [...items]];
    sortType = sortType || getSortType<T>(head, sortKey);

    const getComparator = (() => {
        switch (sortType) {
            case SortType.numeric:
                return getNumericSortComparator;
            case SortType.string:
                return getStringSortComparator;
            default: {
                const _never: never = sortType;
                throw new UnreachableError(sortType);
            }
        }
    })();

    const comparator = getComparator(isOrderDescending, sortKey, makeElemFn);
    head.sort(comparator);
    return [...head, ...tail];
}
