import { enumToKeysArray } from '@/services/enumConverter';

export enum IssuePriorityEnum {
    blocker = 'blocker',
    critical = 'critical',
    major = 'major',
    minor = 'minor',
    trivial = 'trivial',
    none = 'none',
}

export const IssuePriorityList = enumToKeysArray(IssuePriorityEnum);
