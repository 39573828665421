// @ts-ignore
import settings from 'reviztoSettings';
import { RandomService } from '@/services';

interface IHttpParams {
    baseHost: string;
    apiHost: string;
    protocol: string;
    version: string;
    regionsPath: string;
    workspaceUrl: string;
    nonce: string;
    updaterPath: string;
    releaseRegion: string;
}

export const httpParams: IHttpParams = {
    baseHost: settings.baseHost,
    apiHost: settings.apiHost,
    protocol: settings.protocol,
    version: settings.version,
    regionsPath: settings.regionsPath,
    workspaceUrl: settings.workspaceUrl,
    updaterPath: settings.updaterPath,
    releaseRegion: settings.releaseRegion,
    nonce: RandomService.string(8),
};
