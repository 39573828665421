















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { Dict } from '@/types/Dict';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

const LOCAL_STORAGE_KEY = 'ws-collapsable-block-state';

const getCurrentBlockSavedState = (blockName: string) => {
    const lsValue = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!lsValue) {
        return;
    }

    try {
        const parsedValue = JSON.parse(lsValue);
        return parsedValue[blockName];
    } catch {
        return;
    }
};

const saveCurrentBlockStateInLS = (blockName: string, state: boolean) => {
    const lsValue = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!lsValue) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ [blockName]: state }));
        return;
    }

    let parsedValue: Dict<boolean>;

    try {
        parsedValue = JSON.parse(lsValue);
    } catch {
        parsedValue = {};
    }

    parsedValue[blockName] = state;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedValue));
};

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsCollapsableBlock extends Vue {
    @Prop({ type: Boolean, default: null }) public collapsed!: boolean;
    @Prop({ required: true }) public title!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public contentDisabled!: boolean;
    @Prop() public blockName!: string;
    @Prop({ type: Boolean, default: false }) public saveState!: boolean; // Save state to local storage, required blockName

    public localCollapsed: boolean = false;

    get model() {
        return this.collapsed ?? this.localCollapsed;
    }

    @Emit()
    public toggle() {
        this.localCollapsed = !this.model;

        if (this.saveState && this.blockName) {
            saveCurrentBlockStateInLS(this.blockName, this.localCollapsed);
        }

        return this.localCollapsed;
    }

    public created() {
        const savedState = getCurrentBlockSavedState(this.blockName);

        if (savedState !== undefined) {
            this.localCollapsed = savedState;
        }
    }
}
