











import { Component, Prop, Vue } from 'vue-property-decorator';
import { IssuePriorityEnum } from '@/constants';
import { ColorService } from '@/services';

@Component
export default class IssuePriorityText extends Vue {
    @Prop({ required: true }) public value!: IssuePriorityEnum;
    @Prop({ type: Boolean, default: false }) public tagLike!: boolean;

    get color() {
        return ColorService.getColorForField(this.value);
    }
    get textColor() {
        return ColorService.contrastHexColor(this.color);
    }
}
