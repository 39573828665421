
































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { IMultiselectNewValueItem } from '@/types/issue-tracker';
import { IssueTrackerFields } from '@/constants';
import { Issue } from '@/models';
import { prepareValueForChangeIssueField } from '@/services/issueTracker/base';
import IssueDetailsProcoreStatus from '@/components/project/issueTracker/details/IssueDetailsProcoreStatus.vue';
import IssueDetailsCustomType from '@/components/project/issueTracker/details/IssueDetailsCustomType.vue';
import IssueDetailsCustomStatus from '@/components/project/issueTracker/details/IssueDetailsCustomStatus.vue';
import IssueDetailsStampColor from '@/components/project/issueTracker/details/IssueDetailsStampColor.vue';
import IssueDetailsStatus from '@/components/project/issueTracker/details/IssueDetailsStatus.vue';
import IssueDetailsPriority from '@/components/project/issueTracker/details/IssueDetailsPriority.vue';
import IssueDetailsStampAbbr from '@/components/project/issueTracker/details/IssueDetailsStampAbbr.vue';
import IssueDetailsReporter from '@/components/project/issueTracker/details/IssueDetailsReporter.vue';
import IssueDetailsWatchers from '@/components/project/issueTracker/details/IssueDetailsWatchers.vue';
import IssueDetailsTitle from '@/components/project/issueTracker/details/IssueDetailsTitle.vue';
import IssueDetailsDate from '@/components/project/issueTracker/details/IssueDetailsDeadline.vue';
import IssueDetailsVisibility from '@/components/project/issueTracker/details/IssueDetailsVisibility.vue';
import IssueDetailsTags from '@/components/project/issueTracker/details/IssueDetailsTags.vue';
import IssueDetailsClashSimple from '@/components/project/issueTracker/details/IssueDetailsClashSimple.vue';
import IssueDetailsPreview from '@/components/project/issueTracker/details/IssueDetailsPreview.vue';
import IssueDetailsId from '@/components/project/issueTracker/details/IssueDetailsId.vue';
import IssueDetailsClashGrid from '@/components/project/issueTracker/details/IssueDetailsClashGrid.vue';
import IssueDetailsProcore from '@/components/project/issueTracker/details/IssueDetailsProcore.vue';
import IssueDetailsClashSourceFile from '@/components/project/issueTracker/details/IssueDetailsClashSourceFile.vue';

@Component({
    components: {
        IssueDetailsCustomType,
        IssueDetailsCustomStatus,
        IssueDetailsProcore,
        IssueDetailsProcoreStatus,
        IssueDetailsStampColor,
        IssueDetailsStatus,
        IssueDetailsPriority,
        IssueDetailsStampAbbr,
        IssueDetailsReporter,
        IssueDetailsWatchers,
        IssueDetailsTitle,
        IssueDetailsDate,
        IssueDetailsVisibility,
        IssueDetailsTags,
        IssueDetailsClashSimple,
        IssueDetailsPreview,
        IssueDetailsId,
        IssueDetailsClashGrid,
        IssueDetailsClashSourceFile,
    },
})
export default class IssueDetailsBody extends Vue {
    @Prop() public sourceValue!: any;
    @Prop() public row!: string;
    @Prop() public type!: string;
    @Prop({ required: true }) public rows!: any;
    @Prop({ required: true }) public field!: IssueTrackerFields;

    @Prop({ type: Boolean }) public editable!: boolean;
    @Prop({ type: Boolean }) public allowCreateTag!: boolean;
    @Prop() public items!: any[];
    @Prop() public label!: TranslateResult;
    @Prop() public valueKey!: any;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentUserEmail() {
        return this.$store.getters.userData.email;
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    @Emit()
    public setFilter(filterValue: { value: string; type: string }) {
        return filterValue;
    }

    public async setValue(newValue: boolean) {
        const payload = {
            row: this.row,
            sourceValue: this.prepareValue(this.sourceValue),
            value: this.prepareValue(newValue),
            currentUserEmail: this.currentUserEmail,
            type: this.type,
        } as IMultiselectNewValueItem;

        this.$store.commit('addMultiEditValue', payload);

        if (this.isMultiselectEditModeActive) {
            return;
        }

        await this.$store.dispatch('applySingleEditValue', {
            projectId: this.projectId,
            issue: this.currentIssue,
            currentUserEmail: this.currentUserEmail,
            payload,
        });

        await this.$store.dispatch('loadProjectFieldVariants', {
            projectId: this.projectId,
            isForce: true,
        });
    }

    public prepareValue(newValue: any) {
        return prepareValueForChangeIssueField({
            newValue,
            row: this.row,
            type: this.type,
        });
    }
}
