


















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { Color } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class IssueDetailsItem extends Vue {
    @Prop({ type: Number }) public counter!: number;
    @Prop() public label!: TranslateResult;
    @Prop() public collapsingKey!: string;
    @Prop({ type: Boolean, default: false }) public editable!: boolean;
    @Prop({ type: Boolean, default: false }) public collapsable!: boolean;
    @Prop({ type: Boolean, default: false }) public multiText!: boolean;
    @Prop({ type: Boolean, default: false }) public multiChanged!: boolean;
    @Prop({ type: Boolean, default: false }) public isShowWarningIcon!: boolean;

    public collapsed = (localStorage.getItem(this.collapsingKey) === 'true');

    public readonly Color = Color;

    @Emit()
    public edit() {
        dispatchEvent(new CustomEvent('closeEditSlots'));
        return;
    }

    @Emit()
    public onClickOnWarningIcon() {
        return;
    }

    public toggleCollapsed() {
        const value = this.collapsed;
        localStorage.setItem(this.collapsingKey, String(!value));
        this.collapsed = !value;
    }
}
