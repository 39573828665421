import { IssueTrackerFields } from '@/constants';

interface ConfigItemProps {
    row: IssueTrackerFields;
    valueKey?: string;
    title?: string;
    type?: string;
    items?: IssueTrackerFields;
    editable?: boolean;
    permissions?: string;
    disabled?: boolean;
}

export class IssueDetailsConfigItem {
    public row: IssueTrackerFields;
    public valueKey: string;
    public title: string;
    public type: string;
    public items: IssueTrackerFields;
    public editable: boolean;
    public permissions: string;

    constructor(props: ConfigItemProps) {
        this.row = props.row;
        this.valueKey = props.valueKey || props.row;
        this.title = props.title || props.row;
        this.type = props.type || 'static';
        this.items = props.items || props.row;
        this.editable = Boolean(props.editable);
        this.permissions = props.permissions || '';
    }
}
