export const SettingTransferVariants = Object.freeze({
    ALLOW: 1,
    DENY: 0,
    ALLOW_ONLY_YOURS: 2,
    ALLOW_ONLY_PUBLIC: 3,

    KEEP_BOTH: 0,
    REPLACE: 1,

    LEAVE_EXISTS: 0,
    TAKE_OFF_EXIST: 1,
});
