




import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectMember } from '@/models';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';

@Component({
    components: {
        WsTruncateAuto,
    },
})
export default class ProjectMemberName extends Vue {
    @Prop({ required: true }) public email!: string;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get projectMembers(): ProjectMember[] {
        return this.$store.getters.projectMembersByProjectId(this.projectId);
    }

    get member() {
        return this.projectMembers.find((member: ProjectMember) => member.email === this.email);
    }

    get fullName() {
        return this.member?.fullname;
    }
}
