
































import { Component, Prop } from 'vue-property-decorator';
import { StampColorsPaletteRGBEnum } from '@/constants';
import { Issue } from '@/models';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/components/project/issueTracker/details/IssueDetailsEditPopover.vue';
import WsSelectColorItem from '@/components/common/WsSelectColorItem.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
        IssueDetailsEditPopover,
        WsSelectColorItem,
    },
})
export default class IssueDetailsStampColor extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: string[];

    get currentValue() {
        if (this.multiEditNewValues) {
            return StampColorsPaletteRGBEnum[this.multiEditNewValues.value];
        }

        const rawValue = this.showFieldMultipleText
            ? this.multiValue
            : this.currentIssue[this.valueKey as keyof Issue];

        return StampColorsPaletteRGBEnum[rawValue];
    }

    get itemsForSelect(): Array<{ text: string; value: string }> {
        return this.items.map((value) => ({ text: value, value }));
    }

    public handleSelect(value: string) {
        const isEqual = this.currentValue === value;
        if (!isEqual) {
            this.change(value);
        }
    }
}
