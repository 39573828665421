import { ReleaseApi } from '@/api';
import { Branch, Build } from '@/models';

export default {
    state: {
        branches: [],
        builds: [],
    },
    getters: {
        branches(state: any) {
            return state.branches;
        },
        builds(state: any) {
            return state.builds;
        },
    },
    mutations: {
        setBranches(state: any, branches: Branch[]) {
            state.branches = branches;
        },
        setBuilds(state: any, builds: Build[]) {
            state.builds = builds;
        },
    },
    actions: {
        downloadBranches({ commit }: any) {
            return new Promise<void>((resolve) => {
                ReleaseApi.requestBranches().then((response: any) => {
                    commit('setBranches', response.map((branchData: any) => new Branch(branchData)));
                    resolve();
                });
            });
        },
        downloadBuilds({ commit }: any, locale: string = 'en') {
            return ReleaseApi.requestBuilds({
                limit: 20,
                offset: 0,
                locale,
            }).then((response: any) => {
                commit('setBuilds', response.map((buildData: any) => new Build(buildData)));
            });
        },
    },
};
