import axios from 'axios';
import http from '@/api/http';
import { httpParams } from '@/api/httpParams';

export default class RegionApi {
    /**
     * Получение дефолтного региона для пользователя
     */
    public static postUserRegion(params: object): Promise<any> {
        return http.post('user/region', Object.assign(params, { source: 'web' })).then((response) => response.data);
    }

    /**
     * Получение списка регионов
     */
    public static loadRegions(): Promise<any> {
        return axios.get(httpParams.regionsPath);
    }

    /**
     * Получение данных сервисных региона
     */
    public static getRegionData(): Promise<any> {
        return http.get('region/services');
    }
}
