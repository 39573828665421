import _ from 'lodash';

export function getClashLevel(clashes: any, rClashes: any, positionProperties: any): string[] {
    const arrClashes = _.uniq(_.concat(...(clashes?.items || []).map((item: any) => [item.ilevel])))
        .map((num) => clashes?.strings[num - 1]);
    const arrRClashes = _.uniq(_.concat(...(rClashes?.items || []).map((item: any) => [item.iLevel])))
        .map((num) => rClashes?.strings[num - 1]);

    const arrPosProp: string[] = [(positionProperties || {}).Level];

    return [...arrClashes, ...arrRClashes, ...arrPosProp].filter(Boolean);
}

export function getClashArea(rClashes: any, positionProperties: any): string[] {
    const arrRClashes = _.uniq(_.concat(...(rClashes?.items || []).map((item: any) => [item.iArea])))
        .map((num) => rClashes?.strings[num - 1]);
    const arrPosProp: string[] = [(positionProperties || {}).Area];

    return [...arrRClashes, ...arrPosProp].filter(Boolean);
}

export function getClashRoom(rClashes: any, positionProperties: any): string[] {
    const arrRClashes = _.uniq(_.concat(...(rClashes?.items || []).map((item: any) => [item.iRoom])))
        .map((num) => rClashes?.strings[num - 1]);
    const arrPosProp: string[] = [(positionProperties || {}).Room];

    return [...arrRClashes, ...arrPosProp].filter(Boolean);
}

export function getClashSpace(rClashes: any, positionProperties: any): string[] {
    const arrRClashes = _.uniq(_.concat(...(rClashes?.items || []).map((item: any) => [item.iSpace])))
        .map((num) => rClashes?.strings[num - 1]);
    const arrPosProp: string[] = [(positionProperties || {}).Space];

    return [...arrRClashes, ...arrPosProp].filter(Boolean);
}

export function getClashZone(rClashes: any, positionProperties: any): string[] {
    const arrRClashes = _.uniq(_.concat(...(rClashes?.items || []).map((item: any) => [item.iZone])))
        .map((num) => rClashes?.strings[num - 1]);
    const arrPosProp: string[] = [(positionProperties || {}).Zone];

    return [...arrRClashes, ...arrPosProp].filter(Boolean);
}

export function geClashSourceFile(clashes: any, rClashes: any): string[] {
    const arrClashes = _.uniq(_.concat(...(clashes?.items || []).map((item: any) => [item.isrc0, item.isrc1])))
        .map((num) => clashes?.strings[num - 1]);
    const arrRClashes = _.uniq(_.concat(...(rClashes?.items || []).map((item: any) => [item.isrcA, item.isrcB])))
        .map((num) => rClashes?.strings[num - 1]);

    return [...arrClashes, ...arrRClashes].filter(Boolean);
}

export function getClashCategory(clashes: any): string[] {
    return _.uniq(_.concat(...(clashes?.items || []).map((item: any) => [item.icategory0, item.icategory1])))
        .map((num) => clashes?.strings[num - 1]);
}

export function getClashTest(clashes: any, rClashes: any): string[] {
    const arrClashes: string[] = (clashes?.itest === undefined ? [] : [clashes.itest])
        .map((num: number) => clashes?.strings[num - 1]);
    const arrRClashes: string[] = (rClashes?.itest === undefined ? [] : [rClashes.itest])
        .map((num: number) => rClashes?.strings[num - 1]);

    return [...arrClashes, ...arrRClashes].filter(Boolean);
}

const joinGrid = (grids: string[][]) => {
    return _.uniq(grids.map((grid: any) => grid.join('-')));
};

export function getClashGrid(clashes: any, rClashes: any, positionProperties: any): string[] {
    const arrClashes = _.uniq(_.concat(...(clashes?.items || []).map((item: any) => [[item.igrid1, item.igrid2]])))
        .map((nums) => [clashes?.strings[nums[0] - 1], clashes?.strings[nums[1] - 1]]);
    const rClosestGrid = _.uniq((rClashes?.items || []).map((item: any) => [item.ClosestGrid?.gridPair.iItemA, item.ClosestGrid?.gridPair.iItemB]))
        .map((pair: any) => [rClashes?.strings?.[pair[0] as number - 1], rClashes?.strings?.[pair[1] as number - 1]]);
    const approximateGrids = ((positionProperties || {}).GridIntersectionsInProximity || []).map((pair: any) => [pair.ItemA, pair.ItemB]);
    const closestGrid = [(positionProperties || {}).ClosestGrid?.GridPair.ItemA, (positionProperties || {}).ClosestGrid?.GridPair.ItemB].filter(Boolean);

    return [
        ...joinGrid(_.uniq(rClosestGrid)),
        ...joinGrid(approximateGrids),
        ...joinGrid(arrClashes),
        !_.isEmpty(closestGrid) ? closestGrid.join('-') : null,
    ].filter(Boolean);
}
