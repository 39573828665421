import { TranslateResult } from 'vue-i18n';
import { IRegion, IRegionServices } from '@/types/IRegion';
import { i18n } from '@/services/i18n';

export class Region {
    public readonly id: string;
    public readonly name: string;
    public readonly services: IRegionServices;

    constructor({ id, name, services }: IRegion) {
        this.id = id;
        this.name = name;
        this.services = services;
    }

    public static instantiate(data: IRegion): Region {
        return new Region(data);
    }

    private static makeOption(region: Region) {
        return region.option;
    }

    public static makeOptions(regions: Region[]) {
        return regions.map(Region.makeOption);
    }

    public get normalizedName(): TranslateResult {
        const translationKey = `Region.${this.id}`;
        return i18n.te(translationKey) ? i18n.t(translationKey) : this.name;
    }

    public get option() {
        return {
            value: this.id,
            text: this.normalizedName,
        };
    }
}
