import _ from 'lodash';
import Vue from 'vue';
import { Dict } from '@/types/Dict';
import { MemberRoleEnum } from '@/constants';
import LicenseApi, { ICreateAdditionalFieldsParams, IDeleteAdditionalFieldsParams, IInviteLicenseMembersParams, IUpdateAdditionalFieldsParams } from '@/api/license.api';
import {
    ILicenseDeletedMemberProp,
    LicenseDeletedMember,
    LicenseMember,
    Project,
} from '@/models';
import { getOperationId, notificationSuccess } from '@/services';

export type IModifierStringParam = 'intersection' | 'union' | 'exclusive';

export enum PendingStatus {
    unknown = 'unknown',
    success = 'success',
    fail = 'fail',
}

export interface IFiltersParams {
    tags: string[];
    tagAction: IModifierStringParam;
    company: any;
    companyAction: IModifierStringParam;
    department: any;
    departmentAction: IModifierStringParam;
    location: any;
    locationAction: IModifierStringParam;
    statuses: any;
    licenseRoles: any;
    invitedAtType: any;
    invitedAtDateStart: number;
    invitedAtDateEnd: number;
    lastActiveAtType: any;
    lastActiveAtDateStart: number;
    lastActiveAtDateEnd: number;
    licenseProjectsAction: IModifierStringParam;
    licenseProjectsUuids: string[];
}

interface ILicenseMembersState {
    licenseMembersObj: {
        [licenseId: number]: LicenseMember[];
    };
    deletedMembersObj: {
        [licenseId: number]: LicenseMember[];
    };
    pendingStatusesObj: {
        [licenseId: number]: {
            [email: string]: PendingStatus;
        }
    }
    licenseMemberProjectsObj: {
        [licenseId: string]: {
            [licenseMemberUuid: string]: Project[];
        };
    };
    licenseMembersFiltersParamsObj: {
        [licenseId: number]: IFiltersParams;
    };
    isLoadingLicenseMembersObj: {
        [licenseId: number]: boolean;
    };
    isLoadingDeletedLicenseMembersObj: {
        [licenseId: number]: boolean;
    };
    isSendingLicenseMembers: boolean;
    isSendingGroupEditLicenseMemberTags: boolean;
    isLoadingLicenseMember: boolean;
    isLoadingLicenseMemberProjects: boolean;
    isSendingInviteLicenseMembers: boolean;
    isSendingCheckPaid: boolean;
    isSendingResendLicenseInvitations: boolean;
    isCreatingAdditionalField: boolean;
    isUpdatingAdditionalField: boolean;
    isDeletingAdditionalField: boolean;
    isInvitationStatusesLoading: boolean;
}

const promise = {
    licenseMembers: {} as any,
};

export default {
    state: {
        licenseMembersObj: {},
        deletedMembersObj: {},
        pendingStatusesObj: {},
        licenseMemberProjectsObj: {},
        licenseMembersFiltersParamsObj: {},
        isLoadingLicenseMembersObj: {},
        isLoadingDeletedLicenseMembersObj: {},
        isSendingLicenseMembers: false,
        isSendingGroupEditLicenseMemberTags: false,
        isLoadingLicenseMember: false,
        isLoadingLicenseMemberProjects: false,
        isSendingInviteLicenseMembers: false,
        isSendingCheckPaid: false,
        isSendingResendLicenseInvitations: false,
        isInvitationStatusesLoading: false,
        isCreatingAdditionalField: false,
        isUpdatingAdditionalField: false,
        isDeletingAdditionalField: false,
        countLoadLicenseMembersObj: {},
        licenseMembersInvitationStatusesObj: {},
    } as ILicenseMembersState,
    getters: {
        licenseMembersByLicenseId(state: ILicenseMembersState): (licenseId: number) => LicenseMember[] {
            return (licenseId: number) => state.licenseMembersObj[licenseId] || [];
        },
        licenseMembersByLicenseIdByUuid(_state: ILicenseMembersState, getters: any) {
            return _.memoize(
                (licenseId: number) => {
                    return _.keyBy(getters.licenseMembersByLicenseId(licenseId), 'uuid');
                },
            );
        },
        licenseMembersByLicenseIdByEmail(_state: ILicenseMembersState, getters: any) {
            return _.memoize(
                (licenseId: number) => _.keyBy(getters.licenseMembersByLicenseId(licenseId), 'email'),
            );
        },
        invitedLicenseMembers(state: ILicenseMembersState) {
            return (licenseId: number) =>  {
                state.licenseMembersObj[licenseId]?.map(({ email }) => email) || [];
            };
        },
        pendingStatusesObjByLicenseId(state: ILicenseMembersState): (licenseId: number) => Dict<PendingStatus> {
            return (licenseId: number) => state.pendingStatusesObj[licenseId] || {};
        },
        paidLicenseMembersByLicenseId(state: ILicenseMembersState): (licenseId: number) => LicenseMember[] {
            return (licenseId: number) => state.licenseMembersObj[licenseId]?.filter((member: LicenseMember) => {
                return member.role !== MemberRoleEnum.superAdmin
                    && member.role !== MemberRoleEnum.guest
                    && !member.deactivated;
            }) || [];
        },
        licenseMemberByEmail(state: ILicenseMembersState, rootGetters: any): (
            email: string,
            licenseId?: number,
        ) => LicenseMember | undefined {
            return (
                email: string,
                licenseId?: number,
            ) => (state.licenseMembersObj[licenseId || rootGetters.currentLicenseId] || [])
                .find((licenseMember: LicenseMember) => licenseMember.email === email);
        },
        deletedMembersByLicenseId(state: ILicenseMembersState) {
            return (licenseId: number) => state.deletedMembersObj[licenseId] || [];
        },
        deletedMembersByLicenseIdByUuid(_state: ILicenseMembersState, getters: any) {
            return _.memoize(
                (licenseId: number) => _.keyBy(getters.deletedMembersByLicenseId(licenseId), 'teamMemberUuid'),
            );
        },
        allMembersByLicenseIdByUuid(state: ILicenseMembersState, getters: any) {
            return (licenseId: number) => {
                const currentMembers: LicenseMember[] = getters.licenseMembersByLicenseId(licenseId);
                const deletedMembers: LicenseDeletedMember[] = getters.deletedMembersByLicenseId(licenseId);
                const currentMembersByUuid = _.keyBy(currentMembers, 'uuid');
                const deletedMembersByUuid = _.keyBy(deletedMembers, 'teamMemberUuid');
                return { ...deletedMembersByUuid, ...currentMembersByUuid };
            };
        },
        activatedLicenseMembersById(state: ILicenseMembersState, getters: any) {
            return (licenseId: number) => {
                return getters.licenseMembersByLicenseId(licenseId)
                    .filter((member: LicenseMember) => !member.deactivated);
            };
        },
        licenseMemberProjectsByMemberUuid(state: ILicenseMembersState): (licenseId: string, licenseMemberUuid?: string) => Project[] | null {
            return (licenseId, licenseMemberUuid) => {

                if (!state.licenseMemberProjectsObj[licenseId] || !licenseMemberUuid) {
                    return null;
                }

                return state.licenseMemberProjectsObj[licenseId][licenseMemberUuid] || null;
            };
        },
        licenseMembersFiltersParamsByLicenseId(state: ILicenseMembersState): (licenseId: number) => IFiltersParams {
            return (licenseId) => state.licenseMembersFiltersParamsObj[licenseId] || {};
        },
        isLoadingLicenseMembersByLicenseId(state: ILicenseMembersState): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingLicenseMembersObj[licenseId] || false;
        },
        isSendingLicenseMembers(state: ILicenseMembersState): boolean {
            return state.isSendingLicenseMembers;
        },
        isSendingGroupEditLicenseMemberTags(state: ILicenseMembersState): boolean {
            return state.isSendingGroupEditLicenseMemberTags;
        },
        isLoadingLicenseMember(state: ILicenseMembersState): boolean {
            return state.isLoadingLicenseMember;
        },
        isLoadingLicenseMemberProjects(state: ILicenseMembersState): boolean {
            return state.isLoadingLicenseMemberProjects;
        },
        isSendingInviteLicenseMembers(state: ILicenseMembersState): boolean {
            return state.isSendingInviteLicenseMembers;
        },
        isSendingCheckPaid(state: ILicenseMembersState): boolean {
            return state.isSendingCheckPaid;
        },
        isSendingResendLicenseInvitations(state: ILicenseMembersState): boolean {
            return state.isSendingResendLicenseInvitations;
        },
        isInvitationStatusesLoading(state: ILicenseMembersState): boolean {
            return state.isInvitationStatusesLoading;
        },
        isCreatingAdditionalField(state: ILicenseMembersState): boolean {
            return state.isCreatingAdditionalField;
        },
        isUpdatingAdditionalField(state: ILicenseMembersState): boolean {
            return state.isUpdatingAdditionalField;
        },
        isDeletingAdditionalField(state: ILicenseMembersState): boolean {
            return state.isDeletingAdditionalField;
        },
    },
    mutations: {
        setLicenseMembers(state: ILicenseMembersState, { licenseId, members }: { licenseId: number, members: LicenseMember[] }) {
            Vue.set(state.licenseMembersObj, licenseId, members);
        },
        setLicenseMember(state: ILicenseMembersState, { licenseId, member }: { licenseId: number, member: LicenseMember }) {
            const members = state.licenseMembersObj[licenseId] || [];
            const newMembers = members.map((licenseMember) => licenseMember.id === member.id ? member : licenseMember);
            Vue.set(state.licenseMembersObj, licenseId, newMembers);
        },
        setPendingStatusesObj(state: ILicenseMembersState, { licenseId, emailObj }: { licenseId: number, emailObj: Dict<PendingStatus> }) {
            const pendingStatusesObj = Object.assign({}, state.pendingStatusesObj[licenseId], emailObj);
            Vue.set(state.pendingStatusesObj, licenseId, pendingStatusesObj);
        },
        setDeletedMembersList(state: ILicenseMembersState, { licenseId, members }: { licenseId: number, members: LicenseDeletedMember[]}) {
            Vue.set(state.deletedMembersObj, licenseId, members);
        },
        setLicenseMemberProjects(state: ILicenseMembersState, { licenseId, licenseMemberUuid, projects }: { licenseId: string, licenseMemberUuid: string, projects: Project[] }) {
            const licenseMemberUuidProjects = Object.assign({}, state.licenseMemberProjectsObj[licenseId], { [licenseMemberUuid]: projects });
            Vue.set(state.licenseMemberProjectsObj, licenseId, licenseMemberUuidProjects);
        },
        setLicenseMembersFiltersParams(state: ILicenseMembersState, { licenseId, filtersParams }: { licenseId: number; filtersParams: IFiltersParams }) {
            Vue.set(state.licenseMembersFiltersParamsObj, licenseId, filtersParams);
        },
        setIsLoadingLicenseMembers(state: ILicenseMembersState, { licenseId, value }: { licenseId: number; value: boolean }) {
            Vue.set(state.isLoadingLicenseMembersObj, licenseId, value);
        },
        setIsLoadingDeletedLicenseMembers(state: ILicenseMembersState, { licenseId, value }: { licenseId: number; value: boolean }) {
            Vue.set(state.isLoadingDeletedLicenseMembersObj, licenseId, value);
        },
        setIsSendingLicenseMembers(state: ILicenseMembersState, value: boolean) {
            state.isSendingLicenseMembers = value;
        },
        setIsSendingGroupEditLicenseMemberTags(state: ILicenseMembersState, value: boolean) {
            state.isSendingGroupEditLicenseMemberTags = value;
        },
        setIsLoadingLicenseMember(state: ILicenseMembersState, value: boolean) {
            state.isLoadingLicenseMember = value;
        },
        setIsLoadingLicenseMemberProjects(state: ILicenseMembersState, value: boolean) {
            state.isLoadingLicenseMemberProjects = value;
        },
        setIsSendingInviteLicenseMembers(state: ILicenseMembersState, value: boolean) {
            state.isSendingInviteLicenseMembers = value;
        },
        setIsSendingCheckPaid(state: ILicenseMembersState, value: boolean) {
            state.isSendingCheckPaid = value;
        },
        setIsSendingResendLicenseInvitations(state: ILicenseMembersState, value: boolean) {
            state.isSendingResendLicenseInvitations = value;
        },
        setIsInvitationStatusesLoading(state: ILicenseMembersState, value: boolean) {
            state.isInvitationStatusesLoading = value;
        },
        setIsCreatingAdditionalField(state: ILicenseMembersState, value: boolean) {
            state.isCreatingAdditionalField = value;
        },
        setIsUpdatingAdditionalField(state: ILicenseMembersState, value: boolean) {
            state.isUpdatingAdditionalField = value;
        },
        setIsDeletingAdditionalField(state: ILicenseMembersState, value: boolean) {
            state.isDeletingAdditionalField = value;
        },
    },
    actions: {
        loadLicenseMembersOnAdditionalFieldsChange(
            { state, commit }: any,
            { licenseId, params }: { licenseId: number, params?: any },
        ): Promise<any> {
            state.countLoadLicenseMembersObj[licenseId] = state.countLoadLicenseMembersObj[licenseId] || 0;
            const requestCount = ++state.countLoadLicenseMembersObj[licenseId];
            return LicenseApi.getLicenseMembers(licenseId, params).then((response: any) => {
                if (requestCount === state.countLoadLicenseMembersObj[licenseId]) {
                    const members = response.entities.map(LicenseMember.instantiate);
                    commit('setLicenseMembers', { licenseId, members });
                }
            });
        },
        loadLicenseMembers(
            { state, commit }: any,
            { licenseId, params, isForce = false }
                : { licenseId: number, params?: any, isForce?: boolean },
        ): Promise<any> {
            if (promise.licenseMembers[licenseId]) {
                return promise.licenseMembers[licenseId];
            }

            if (!isForce && state.licenseMembersObj[licenseId]) {
                return Promise.resolve(state.licenseMembersObj[licenseId]);
            }

            promise.licenseMembers[licenseId] = new Promise((resolve, reject) => {
                params = Object.assign({ withDeactivated: 1 }, params); //  also always request deactivated members

                commit('setIsLoadingLicenseMembers', { licenseId, value: true });
                LicenseApi.getLicenseMembers(licenseId, params).then((response) => {
                    const members = response.entities.map(LicenseMember.instantiate);

                    commit('setLicenseMembers', { licenseId, members });
                    resolve(members);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    promise.licenseMembers[licenseId] = null;
                    commit('setIsLoadingLicenseMembers', { licenseId, value: false });
                });
            });

            return promise.licenseMembers[licenseId];
        },
        loadDeletedLicenseMembers({ state, commit }: any, { licenseId, isForce = false }: { licenseId: number, isForce?: boolean }): Promise<any> {
            if (!isForce && state.deletedMembersObj[licenseId]) {
                return Promise.resolve(state.deletedMembersObj[licenseId]);
            }
            commit('setIsLoadingDeletedLicenseMembers', { licenseId, value: true });
            return LicenseApi.getDeletedLicenseMembers(licenseId).then((response) => {
                const members = response.map((member: ILicenseDeletedMemberProp) => new LicenseDeletedMember(member));
                commit('setDeletedMembersList', { licenseId, members });
                return response;
            }).finally(() => {
                commit('setIsLoadingDeletedLicenseMembers', { licenseId, value: false });
            });
        },
        loadLicenseMember({ commit }: any, { licenseId, memberId }: { licenseId: number, memberId: number }): Promise<LicenseMember>  {
            return new Promise((resolve, reject) => {
                commit('setIsLoadingLicenseMember', true);
                LicenseApi.getLicenseMember(licenseId, memberId).then((response) => {
                    const member = new LicenseMember(response);
                    commit('setLicenseMember', { licenseId, member });
                    resolve(member);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingLicenseMember', false);
                });
            });
        },
        loadLicenseMembersInvitationStatus({ commit }: any, { licenseId, emails }: { licenseId: number, emails: string[] }): Promise<any> {
            commit('setIsInvitationStatusesLoading', true);
            return LicenseApi.getLicenseMembersInvitationStatus(licenseId, { emails }).then((response) => {
                const emailObj = response.entities.reduce((accum, { email, status }) => ({
                    ...accum,
                    [email]: status,
                }), {});

                commit('setPendingStatusesObj', { licenseId, emailObj });
                return response;
            }).finally(() => {
                commit('setIsInvitationStatusesLoading', false);
            });
        },
        loadLicenseMemberProjects({ commit }: any, { licenseId, licenseMemberUuid }: { licenseId: string, licenseMemberUuid: string }): Promise<Project[]>  {
            return new Promise((resolve, reject) => {
                commit('setIsLoadingLicenseMemberProjects', true);
                LicenseApi.getLicenseMemberProjects(licenseMemberUuid).then((response) => {
                    const projects = response.entities.map((project: any) => new Project(project));
                    commit('setLicenseMemberProjects', { licenseId, licenseMemberUuid, projects });
                    resolve(projects);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingLicenseMemberProjects', false);
                });
            });
        },
        sendInviteLicenseMembers({ commit }: any, { licenseId, params }: { licenseId: number, params: IInviteLicenseMembersParams }) {
            commit('setIsSendingInviteLicenseMembers', true);
            const operationId = getOperationId();
            return LicenseApi.postInviteLicenseMembers(licenseId, {
                ...params,
                operationId,
            }).finally(() => {
                commit('setIsSendingInviteLicenseMembers', false);
            });
        },
        sendCheckPaid({ commit }: any, { licenseId, params }: { licenseId: number, params: any }) {
            commit('setIsSendingCheckPaid', true);
            return LicenseApi.postCheckPaid(licenseId, params).finally(() => {
                commit('setIsSendingCheckPaid', false);
            });
        },
        sendLicenseMembersToEmail({ commit }: any, { licenseId, params }: { licenseId: number, params: any }) {
            commit('setIsSendingLicenseMembers', true);
            return LicenseApi.postExportLicenseMembers(licenseId, params).then(() => {
                notificationSuccess('activitySent');
            }).finally(() => {
                commit('setIsSendingLicenseMembers', false);
            });
        },
        groupAddLicenseMembersTags({ commit }: any, params: any) {
            commit('setIsSendingGroupEditLicenseMemberTags', true);
            return LicenseApi.postGroupAddLicenseMemberTags(params).finally(() => {
                commit('setIsSendingGroupEditLicenseMemberTags', false);
            });
        },
        groupDeleteLicenseMembersTags({ commit }: any, params: any) {
            commit('setIsSendingGroupEditLicenseMemberTags', true);
            return LicenseApi.postGroupDeleteLicenseMemberTags(params).finally(() => {
                commit('setIsSendingGroupEditLicenseMemberTags', false);
            });
        },
        sendResendLicenseInvitations({ commit }: any, { licenseId, emails }: { licenseId: number, emails: string[] }): Promise<any> {
            commit('setIsSendingResendLicenseInvitations', true);
            const operationId = getOperationId();
            return LicenseApi.postResendLicenseInvitations(licenseId, { emails, operationId }).finally(() => {
                commit('setIsSendingResendLicenseInvitations', false);
            });
        },
        createAdditionalField({ commit }: any, params: ICreateAdditionalFieldsParams): Promise<any> {
            commit('setIsCreatingAdditionalField', true);
            return LicenseApi.createAdditionalField(params).finally(() => {
                commit('setIsCreatingAdditionalField', false);
            });
        },
        updateAdditionalField({ commit }: any, params: IUpdateAdditionalFieldsParams): Promise<any> {
            commit('setIsUpdatingAdditionalField', true);
            return LicenseApi.updateAdditionalField(params).finally(() => {
                commit('setIsUpdatingAdditionalField', false);
            });
        },
        deleteAdditionalField({ commit }: any, params: IDeleteAdditionalFieldsParams): Promise<any> {
            commit('setIsDeletingAdditionalField', true);
            return LicenseApi.deleteAdditionalField(params).finally(() => {
                commit('setIsDeletingAdditionalField', false);
            });
        },
    },
};
