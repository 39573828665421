







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AmplitudeEvent, RouterNames } from '@/constants';
import { License, Project } from '@/models';
import { amplitudeLog, selectProjectsSort, truncateString } from '@/services';
import HeaderSelect from '@/components/header/HeaderSelect.vue';
import ProjectsDropdownItem from '@/components/header/ProjectsDropdownItem.vue';
import WsInputSearch from '@/components/common/WsInputSearch.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';

@Component({
    components: {
        HeaderSelect,
        ProjectsDropdownItem,
        WsInputSearch,
        WsLazyRender,
    },
    methods: {
        truncateString,
    },
})
export default class ProjectsDropdown extends Vue {
    @Prop({ required: true }) public activeProject!: Project;

    public readonly RouterNames = RouterNames;
    public searchQuery = '';

    get language() {
        return this.$route.params.language;
    }

    get isNoOtherProjects() {
        return !this.projectsToShow.length && !this.searchQuery.length;
    }

    get isEmptySearch() {
        return !this.projectsToShow.length && this.searchQuery.length;
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get licenseId(): number | null {
        return this.$store.getters.currentLicenseId;
    }

    get projectsToShow() {
        // Set same sorting as "my projects" table has by default, and active project goes to top.
        return selectProjectsSort(this.myProjects, this.activeProject.id).filter(Project.has(this.searchQuery));
    }

    get myProjects(): Project[] {
        return this.license.isFrozen
            ? this.$store.getters.myProjectsSuspended(this.license)
            : this.$store.getters.myProjectsOperational(this.license);
    }

    public onProjectItemClick() {
        amplitudeLog(AmplitudeEvent.toolbarChangeProject, { userProjects: this.myProjects.length, action: 'change project' });
    }

    public created() {
        this.$store.dispatch('loadLicenseMyProjects', { license: this.license, withIssueCounters: true });
    }

}
