import _ from 'lodash';
import moment from 'moment';
import { IssuePriorityList, IssueSorts, IssueStatusList } from '@/constants';
import { IssueDirections, IssueSort } from '@/constants';
import { IssueSortBy } from '@/constants';
import { Issue } from '@/models';

const dateFormat = 'YYYY-MM-DD';

const compare = (result: boolean, direction: IssueDirections): number => {
    return result
        ? direction === IssueDirections.ASC ? -1 : 1
        : direction === IssueDirections.ASC ? 1 : -1;
};

const compareList = (list: any[], aValue: string, bValue: string, direction: IssueDirections): number => {
    const aIndex = list.findIndex((item: string) => item === aValue);
    const bIndex = list.findIndex((item: string) => item === bValue);
    return compare(aIndex > bIndex, direction);
};

const compareDate = (aDate: string, bDate: string, direction: IssueDirections): number => {
    const aDateObject = moment(aDate, dateFormat);
    const bDateObject = moment(bDate, dateFormat);
    if (!aDate || !bDate) {
        return direction === IssueDirections.ASC ? 1 : -1;
    }
    return compare(aDateObject.isBefore(bDateObject), direction);
};

const compareId = (aId: number, bId: number, direction: IssueDirections): number => {
    return compare(aId < bId, direction);
};

const compareStatus = (aStatus: string, bStatus: string, direction: IssueDirections): number => {
    return compareList(IssueStatusList, aStatus, bStatus, direction);
};

const comparePriority = (aPriority: string, bPriority: string, direction: IssueDirections): number => {
    return compareList(IssuePriorityList, aPriority, bPriority, direction);
};

const compareAssignee = (aAssignee: string, bAssignee: string, direction: IssueDirections): number => {
    return compare(aAssignee > bAssignee, direction);
};

export const issueSort = (issues: Issue[], sort: any): Issue[] => {
    const localIssues = [...issues];

    localIssues.sort((a: Issue, b: Issue): any => {
        let aPrimaryValue = a[sort.primary[IssueSort.field] as keyof Issue];
        let bPrimaryValue = b[sort.primary[IssueSort.field] as keyof Issue];
        let aSecondaryValue = a[sort.secondary[IssueSort.field] as keyof Issue];
        let bSecondaryValue = b[sort.secondary[IssueSort.field] as keyof Issue];

        if (sort.primary[IssueSort.field] === IssueSortBy.assignee) {
            aPrimaryValue = a.assigneeInfo;
            bPrimaryValue = b.assigneeInfo;
        }

        if (sort.secondary[IssueSort.field] === IssueSortBy.assignee) {
            aSecondaryValue = a.assigneeInfo;
            bSecondaryValue = b.assigneeInfo;
        }

        /*
        * Т.к. юзер видит только день создания ишьюса, то будет логично не
        * учитывать время в сортировке, чтобы не вводить в заблуждение
        */

        if ([IssueSortBy.created, IssueSortBy.update].includes(sort.primary[IssueSort.field])) {
            aPrimaryValue = aPrimaryValue.split(' ')[0];
            bPrimaryValue = bPrimaryValue.split(' ')[0];
        }

        if ([IssueSortBy.created, IssueSortBy.update].includes(sort.secondary[IssueSort.field])) {
            aSecondaryValue = aSecondaryValue.split(' ')[0];
            bSecondaryValue = bSecondaryValue.split(' ')[0];
        }

        let aValue;
        let bValue;
        let field;
        let direction;

        if (!_.isEqual(aPrimaryValue, bPrimaryValue)) {
            aValue = aPrimaryValue;
            bValue = bPrimaryValue;
            field = sort.primary[IssueSort.field];
            direction = sort.primary[IssueSort.direction];
        } else {
            aValue = aSecondaryValue;
            bValue = bSecondaryValue;
            field = sort.secondary[IssueSort.field];
            direction = sort.secondary[IssueSort.direction];
        }

        if ([IssueSortBy.created, IssueSortBy.update, IssueSortBy.deadline].includes(field)) {
            return compareDate(aValue, bValue, direction);
        }

        if (field === IssueSortBy.status) {
            return compareStatus(aValue, bValue, direction);
        }

        if (field === IssueSortBy.priority) {
            return comparePriority(aValue, bValue, direction);
        }

        if (field === IssueSortBy.assignee) {
            if (aValue.firstname !== bValue.firstname) {
                return compareAssignee(aValue.firstname, bValue.firstname, direction);
            } else if (aValue.lastname !== bValue.lastname) {
                return compareAssignee(aValue.lastname, bValue.lastname, direction);
            } else {
                return compareAssignee(aValue.email, bValue.email, direction);
            }

        }
        // default sort
        return compareId(a.id, b.id, IssueDirections.ASC);
    });

    if (sort?.[IssueSorts.unread]) {
        const unreadIssues: Issue[] = [];
        const readIssues = issues.filter((issue: Issue): boolean => {
           const isUnread = issue.isUnread || (issue.countUnreadComments > 0);
           if (isUnread) {
               unreadIssues.push(issue);
           }
           return !isUnread;
        });

        return [...unreadIssues, ...readIssues];
    }

    return localIssues;
};
