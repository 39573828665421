import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { IssuesGrouping } from '@/constants';

// data from server
export interface CachedRawChartData {
    graph: RawChartDataUnion;
    cachedAt: number;
}

export class EmptyRawChartData {
    static get standard(): RawChartData {
        return {
            aggregationType: 0,
            resultItems: [],
            groupBy: [],
            filteredIssuesCount: 0,
        };
    }
    static get lineArray(): RawChartDataLine[] {
        return [];
    }
    static get scatter(): RawChartDataScatter {
        return {
            groupBy: [],
            groups: [],
        };
    }
}

export interface RawChartData {
    aggregationType: number;
    resultItems: RawChartDataItem[];
    groupBy: IssuesGrouping[];
    filteredIssuesCount: number;
}

export interface RawChartDataStatusFlow {
    aggregationType: number;
    resultItems: RawChartDataStatusFlowItem[];
    groupBy: [IssuesGrouping.status | IssuesGrouping.customStatus];
    filteredIssuesCount: number;
}

interface RawChartDataStatusFlowItemNormalized {
    aggregate: number;
    fields: {
        from: string;
        to: string;
    };
}

export interface RawChartDataStatusFlowNormalized {
    aggregationType: number;
    resultItems: RawChartDataStatusFlowItemNormalized[];
    groupBy: [IssuesGrouping.status | IssuesGrouping.customStatus];
    filteredIssuesCount: number;
}

export interface LegacyRawChartDataStatusFlow {
    aggregationType: number;
    resultItems: LegacyRawChartDataStatusFlowItem[];
    groupBy: [IssuesGrouping.status];
    filteredIssuesCount: number;
}

export interface RawChartDataScatter {
    groupBy: IssuesGrouping[];
    groups: RawChartDataScatterGroup[];
}

export type RawChartDataUnion = RawChartData | RawChartDataLine[] | RawChartDataScatter;

interface RawChartDataScatterGroup {
    dots: Array<{ date: number; value: number; }>
    fields: Dict<string>;
}

export const isRawChartDataGuard = (data: any): data is RawChartData => {
    return _.isArray(data?.groupBy) && _.isArray(data?.resultItems);
};

export interface RawChartDataItem {
    aggregate: number;
    fields: Dict<string>;
}

interface LegacyRawChartDataStatusFlowItem extends RawChartDataItem {
    aggregate: number;
    fields: {
        status: string;
    };
}

interface RawChartDataStatusFlowItem extends RawChartDataItem {
    aggregate: number;
    fields: { // should be removed in the future, then it will not extend RawChartDataItem, but become separate type in RawChartDataUnion
        status: string;
    };
    statusFlowValues: {
        from: string;
        to: string;
    };
}

export interface NormalizedStatusFlowItem {
    aggregate: number;
    fields: {
        from: string;
        to: string;
    };
}

export interface NormalizedStatusFlowData extends RawChartData {
    resultItems: NormalizedStatusFlowItem[];
}

interface TimelineDot {
    value: number;
    date: number;
}

export interface RawChartDataLine {
    title: string;
    uuid: string;
    type: number;
    color: string;
    dots: TimelineDot[];
    predictedDots?: TimelineDot[];
}

export interface ChartItem {
    key: string;
    keys: any[];
    value: number;
    label: string;
    uniqueKey: string;
    compositeKey: string;
    additionalLabel: string;
    viewName: any;
    sortingBySecondGrouping?: {
        key: string,
        grouping: string,
    };
}

export interface ViewName {
    value: string | string[];
    type: string;
}

// type guards
export function isRawChartData(data: RawChartDataUnion): data is RawChartData {
    return (data as RawChartData).aggregationType !== undefined;
}

export function isRawChartDataStatusFlow(data: RawChartData): data is RawChartDataStatusFlow {
    return Boolean((data as RawChartDataStatusFlow).resultItems?.[0]?.statusFlowValues);
}

export function isLegacyRawChartDataStatusFlow(data: RawChartData): data is LegacyRawChartDataStatusFlow {
    return Boolean(data.resultItems?.[0]?.fields?.status?.includes('-'));
}

export function isRawChartDataLineArray(data: RawChartDataUnion): data is RawChartDataLine[] {
    return (data as RawChartDataLine[]).length !== undefined;
}

export function isRawChartDataScatter(data: RawChartDataUnion): data is RawChartDataScatter {
    return (data as RawChartDataScatter).groups !== undefined;
}

export function isEmptyRawChartData(data: RawChartDataUnion): boolean {
    return isRawChartData(data) && data.resultItems.length === 0
        || isRawChartDataLineArray(data) && data.length === 0
        || isRawChartDataScatter(data) && data.groups.length === 0;
}
