export const NA = [
    {
        'uuid': '66430276-894f-4ea3-ad92-dad219f54d80',
        'title': '360 Photo',
        'fields': '{"stampAbbr":"360","stampColor":2}',
        'created': '2021-06-01 06:36:28',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '18f4029b-2d4d-4fb7-abd5-ca303d1704c2',
        'nodeRole': 1,
    },
    {
        'uuid': '34922ef3-cba5-49a1-b766-c5dd7962a145',
        'title': 'Architect',
        'fields': '{"stampAbbr":"ARCH"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': 'f3a342a1-f81e-4421-8f27-1e39fdbacd3b',
        'title': 'Architect General Clash',
        'fields': '{"deadline":"2000-01-01 00:00:00","stampAbbr":"C-AR","deadline_offset":{"units":0,"value":7}}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': '1a4041ab-4d94-48bb-8899-bf04e945ad17',
        'title': 'Electrical Clash',
        'fields': '{"priority":"critical","stampAbbr":"C-EL"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': 'ac64d4c0-127f-4623-8e82-84e5babd77b3',
        'title': 'Fire Clash',
        'fields': '{"stampAbbr":"C-FP"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': '791ff3ac-73be-47de-b34a-1b376a1950dd',
        'title': 'Low Voltage Clash',
        'fields': '{"deadline":"2000-01-01 00:00:00","stampAbbr":"C-LV","deadline_offset":{"units":0,"value":14}}',
        'created': '2022-02-16 21:46:18',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': '446dad12-d500-481c-ad39-b524ac3f335b',
        'title': 'Mechanical Clash',
        'fields': '{"deadline":"2000-01-01 00:00:00","priority":"major","stampAbbr":"C-ME","deadline_offset":{"units":0,"value":14}}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': '9cacb3e2-1297-4eb0-bed4-48d2bab2248e',
        'title': 'Plumbing Clash',
        'fields': '{"deadline":"2000-01-01 00:00:00","stampAbbr":"C-PL","deadline_offset":{"units":1,"value":2}}',
        'created': '2022-09-13 20:46:56',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': 'ec4ab77b-7539-4beb-a468-891752498215',
        'title': 'Revizto Clash',
        'fields': '{"stampAbbr":"C-RV","visibility":0}',
        'created': '2021-10-28 20:40:41',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': '0a5c4e0f-3734-4d4f-b199-2e035c167a85',
        'title': 'Structural Steel Clash',
        'fields': '{"stampAbbr":"C-SS"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': '27632b85-24b9-449d-a476-3da8856931ba',
        'title': 'Structural Clash',
        'fields': '{"stampAbbr":"C-ST"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'nodeRole': 1,
    },
    {
        'uuid': '69604e0f-24f7-433e-986a-ab92e21609ff',
        'title': 'Concrete Sequence',
        'fields': '{"stampAbbr":"CC1"}',
        'created': '2022-11-07 17:32:59',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': 'b6bcc81e-cccb-4eec-a27b-206514151e40',
        'nodeRole': 1,
    },
    {
        'uuid': 'b86ece14-702e-44ad-9e6a-38607e6fe923',
        'title': 'Cast in Place Concrete',
        'fields': '{"stampAbbr":"CIPC"}',
        'created': '2023-05-12 19:24:49',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': 'c591bfbb-c6e8-4995-b845-ff9a0a111d7d',
        'title': 'Architect General Design',
        'fields': '{"stampAbbr":"DARC"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '05d078f1-f006-4d0e-a894-0e385702462c',
        'nodeRole': 1,
    },
    {
        'uuid': '41d58625-e7fa-46b4-a7f4-9bb422aabfe5',
        'title': 'Electrical Design',
        'fields': '{"stampAbbr":"DELE"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '05d078f1-f006-4d0e-a894-0e385702462c',
        'nodeRole': 1,
    },
    {
        'uuid': 'cb253656-1cd5-4215-9068-a69a75d288bc',
        'title': 'Doors, Frames, and Hardware',
        'fields': '{"stampAbbr":"DFHD"}',
        'created': '2023-05-12 19:25:26',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': 'b34c01b5-0289-4690-834c-f8d923bea7a7',
        'title': 'Fire Design',
        'fields': '{"stampAbbr":"DFPR"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '05d078f1-f006-4d0e-a894-0e385702462c',
        'nodeRole': 1,
    },
    {
        'uuid': 'bc6fdc6c-0baa-4792-8d8e-7815c9b3eb62',
        'title': 'Mechanical Design',
        'fields': '{"stampAbbr":"DMEC"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '05d078f1-f006-4d0e-a894-0e385702462c',
        'nodeRole': 1,
    },
    {
        'uuid': '3d7be4af-ec18-4bcd-ab7b-aa924950f63e',
        'title': 'Plumbing Design',
        'fields': '{"deadline":"2000-01-01 00:00:00","stampAbbr":"DPLM","deadline_offset":{"units":0,"value":7}}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '05d078f1-f006-4d0e-a894-0e385702462c',
        'nodeRole': 1,
    },
    {
        'uuid': '3aff1f81-bd3e-4f29-999f-6d7ef9718209',
        'title': 'Drywall',
        'fields': '{"stampAbbr":"DRYW"}',
        'created': '2023-05-12 19:20:14',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': 'd971f25d-8820-4416-8ab0-a88c5aaf3b1d',
        'title': 'Structural Design',
        'fields': '{"stampAbbr":"DSTR"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '05d078f1-f006-4d0e-a894-0e385702462c',
        'nodeRole': 1,
    },
    {
        'uuid': '642dc133-f63f-4a92-abdd-33dd1d47ef4e',
        'title': 'Electrical',
        'fields': '{"stampAbbr":"ELEC"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': '5b0554e4-0a8f-4ae2-aa0f-f9bd2f124ae7',
        'title': 'Electrical saftey issue',
        'fields': '{"deadline":"2000-01-01 00:00:00","priority":"major","stampAbbr":"ELSI","deadline_offset":{"units":0,"value":7}}',
        'created': '2022-09-19 17:06:55',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': 'a1c673d3-7061-4419-9a2c-62738d268c92',
        'nodeRole': 1,
    },
    {
        'uuid': '2dc2f1e7-655e-4bee-9c9c-e3d68035406a',
        'title': 'Environmental Issue',
        'fields': '{"stampAbbr":"ENVI"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '18f4029b-2d4d-4fb7-abd5-ca303d1704c2',
        'nodeRole': 1,
    },
    {
        'uuid': '877f574d-e3c5-4a38-addc-119718287128',
        'title': 'Equipment',
        'fields': '{"stampAbbr":"EQU"}',
        'created': '2022-08-24 14:28:35',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '58b93f13-55bb-4735-b7f0-92491f20391d',
        'nodeRole': 1,
    },
    {
        'uuid': 'a05c135c-ee7e-44cf-a31f-94dc7bf0e2dd',
        'title': 'Field Observation',
        'fields': '{"stampAbbr":"FO"}',
        'created': '2022-11-17 18:22:48',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '18f4029b-2d4d-4fb7-abd5-ca303d1704c2',
        'nodeRole': 1,
    },
    {
        'uuid': '5f73f541-f787-42d0-aa70-e83e9c12407f',
        'title': 'Fire Protection',
        'fields': '{"stampAbbr":"FPRT"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': '8d1351f1-f24a-484d-bd77-0a4409c2b7c6',
        'title': 'Insulation',
        'fields': '{"stampAbbr":"INSL"}',
        'created': '2023-05-12 19:24:05',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': '764b209a-bc6c-400d-8d97-50b6148051e3',
        'title': 'Mechanical',
        'fields': '{"stampAbbr":"MECH"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': '7f7085eb-401e-4a6a-a5fb-cb2c11dbbd0c',
        'title': 'Millwork',
        'fields': '{"stampAbbr":"MILW"}',
        'created': '2023-05-12 19:24:23',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': 'fc4afab1-4e09-491b-89f7-6df7faa5c52a',
        'title': 'Masonry',
        'fields': '{"stampAbbr":"MSNY"}',
        'created': '2023-05-12 19:24:37',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': '34124ee0-83d6-47f0-8433-0c9f3147f8fc',
        'title': 'Observance',
        'fields': '{"stampAbbr":"OAQC"}',
        'created': '2022-05-23 14:01:53',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '6d79cb3b-bf1b-45ca-9721-00e715f5a77e',
        'nodeRole': 1,
    },
    {
        'uuid': 'eb5e9c9c-b99d-42c5-8f4f-9560f28b7eb8',
        'title': 'Owner',
        'fields': '{"stampAbbr":"OWN","visibility":0}',
        'created': '2023-04-25 17:42:23',
        'updated': '2023-06-01 13:50:29',
        'parentUuid': null,
        'nodeRole': 1,
    },
    {
        'uuid': 'dade97ad-f6c7-4463-a851-4633bbefcf10',
        'title': 'Plumbing',
        'fields': '{"stampAbbr":"PLUM"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': '3f1d596c-d75d-4e1f-9e56-4746315d4246',
        'title': 'Punchlist',
        'fields': '{"stampAbbr":"PNCH"}',
        'created': '2022-12-05 16:33:20',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '18f4029b-2d4d-4fb7-abd5-ca303d1704c2',
        'nodeRole': 1,
    },
    {
        'uuid': '9becdc4b-ff91-4f53-a01e-3fb8c4a22e7f',
        'title': 'Ordered',
        'fields': '{"stampAbbr":"PT1"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8da07f13-7df3-4158-a711-82edf3adacd1',
        'nodeRole': 1,
    },
    {
        'uuid': 'e1cf5b9e-1b01-46ac-a96c-e13140df2417',
        'title': 'Delivered',
        'fields': '{"stampAbbr":"PT2"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8da07f13-7df3-4158-a711-82edf3adacd1',
        'nodeRole': 1,
    },
    {
        'uuid': '9295e79e-3712-43bc-b62c-f1c7daeeef7e',
        'title': 'Installed',
        'fields': '{"stampAbbr":"PT3"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8da07f13-7df3-4158-a711-82edf3adacd1',
        'nodeRole': 1,
    },
    {
        'uuid': '234a7503-f25e-416c-8d2d-47c8bb3fdce8',
        'title': 'RFI',
        'fields': '{"stampAbbr":"RFI"}',
        'created': '2023-05-11 17:42:13',
        'updated': '2023-05-11 17:42:13',
        'parentUuid': null,
        'nodeRole': 1,
    },
    {
        'uuid': 'f0770d42-5c9b-4f8c-ba92-96b4d59a8b1a',
        'title': 'Safety In Design Issue',
        'fields': '{"stampAbbr":"SIDE"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': 'a1c673d3-7061-4419-9a2c-62738d268c92',
        'nodeRole': 1,
    },
    {
        'uuid': '73bd14ba-4a70-4b62-9f3a-71fc6ffe4bfb',
        'title': 'Structural',
        'fields': '{"stampAbbr":"STRC"}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-08-09 10:56:42',
        'parentUuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'nodeRole': 1,
    },
    {
        'uuid': '2ea07eed-b648-4c2b-995c-304b99760412',
        'title': 'Team Member Stamp',
        'fields': '{"stampAbbr":"TM","visibility":0}',
        'created': '2023-01-19 21:49:18',
        'updated': '2023-05-11 17:41:56',
        'parentUuid': null,
        'nodeRole': 1,
    },
    {
        'uuid': '05d078f1-f006-4d0e-a894-0e385702462c',
        'title': '01 Design',
        'fields': '{"priority":"minor","stampColor":7,"visibility":1}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-05-11 17:43:02',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '093aa4fd-a467-4243-a354-f4f0bc7fa00d',
        'title': '02 Clashes',
        'fields': '{"priority":"minor","stampColor":2,"visibility":1}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-05-11 17:36:13',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '8680e8a1-e61c-48f7-a37c-2db948aee46a',
        'title': '03 Construction',
        'fields': '{"priority":"minor","stampColor":8,"visibility":1}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-05-11 17:36:28',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'a1c673d3-7061-4419-9a2c-62738d268c92',
        'title': '04 Safety in Design',
        'fields': '{"stampColor":7,"visibility":1}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-05-11 17:36:37',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '18f4029b-2d4d-4fb7-abd5-ca303d1704c2',
        'title': '05 Field Observation',
        'fields': '{"stampColor":4,"visibility":1}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-05-11 17:45:46',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '6d79cb3b-bf1b-45ca-9721-00e715f5a77e',
        'title': '06 QA Quality Assurance',
        'fields': '{"stampColor":9,"visibility":1}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-05-11 17:45:58',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '8da07f13-7df3-4158-a711-82edf3adacd1',
        'title': '07 Procurement',
        'fields': '{"priority":"minor","stampColor":6,"visibility":1}',
        'created': '2021-02-19 04:08:34',
        'updated': '2023-05-11 17:46:04',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '58b93f13-55bb-4735-b7f0-92491f20391d',
        'title': '08 FM',
        'fields': '[]',
        'created': '2022-08-24 14:25:37',
        'updated': '2023-05-11 17:46:10',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'b6bcc81e-cccb-4eec-a27b-206514151e40',
        'title': '09 Tracking',
        'fields': '[]',
        'created': '2022-11-07 17:32:42',
        'updated': '2023-05-11 17:46:19',
        'parentUuid': null,
        'nodeRole': 2,
    },
];
