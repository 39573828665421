import { BaseDashboard } from '@/models';
import { sortArrayOfObjects } from '@/services';

export const sortDashboards = <T extends BaseDashboard>(dashboards: T[]): T[] => {
    const sorter = (array: T[]) => sortArrayOfObjects<T>(array, 'title', true);
    const sortedDashboards = sorter(dashboards);
    const group = sortedDashboards
        .reduce((acc, next) => {
            if (next.isFavorite) {
                acc.favorite.push(next);
            } else {
                acc.other.push(next);
            }
            return acc;
        }, { favorite: [], other: [] } as {
            favorite: T[],
            other: T[],
        });
    return [...group.favorite, ...group.other];
};
