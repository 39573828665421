import { render, staticRenderFns } from "./NotificationWidget.vue?vue&type=template&id=79c91bbb&scoped=true&"
import script from "./NotificationWidget.vue?vue&type=script&lang=ts&"
export * from "./NotificationWidget.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationWidget.vue?vue&type=style&index=0&id=79c91bbb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c91bbb",
  null
  
)

export default component.exports