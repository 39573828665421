import { IScheduleFlag, ITaskDefinition } from '@/types/project/Project';
import { IMutableField } from '@/types/common';
import { ClashAutomationSettings, TaskType } from '@/types/scheduler';
import { TaskHistory } from '@/models/TaskHistory';
import { updateMutableFieldTime } from '@/services/Common';
import { parseSchedule } from '@/services';

export class TaskDefinition {
    public uuid: string;
    public deviceId: string;
    public lastOnline: number | null;
    public creatorEmail: string;
    public statistics: any;
    public nextRun: number;
    public lastRun: number;
    public deviceStatus: number;
    public lastRunStatus: string;
    private _taskType: string;
    private _additionalProperties: any;
    private _alias: IMutableField<string>;
    private _schedule: IMutableField<any>;
    private _displayName: IMutableField<string>;
    private _notifications: IMutableField<any>;
    private _advanced: IMutableField<any>;
    private _settings: IMutableField<any>;

    public constructor(args: ITaskDefinition) {
        this.uuid = args.uuid;
        this._displayName = updateMutableFieldTime(args.displayName);
        this._notifications = updateMutableFieldTime(args.notifications);
        this._advanced = updateMutableFieldTime(args.advanced);
        this._taskType = args.type;
        this._alias = updateMutableFieldTime(args.alias);
        this.deviceId = args.deviceId;
        this.lastOnline = args.lastOnline || null;
        this.creatorEmail = args.creatorEmail;
        this._schedule = updateMutableFieldTime(args.schedule);
        this.statistics = updateMutableFieldTime(args.statistics);
        this._settings = updateMutableFieldTime(args.settings);
        this.nextRun = args.nextRunAt;
        this.lastRun = args.lastRunAt;
        this.deviceStatus = args.deviceStatus;
        this.lastRunStatus = args.lastRunStatus;
        this._additionalProperties = args.additionalProperties;
    }

    get taskType() {
        return this._settings?.value?.body?.from ? this._taskType + '_' + this._settings?.value?.body?.from : this._taskType;
    }

    get isExportType() {
        return this.taskType === TaskType.Export;
    }

    get isClashType() {
        return this.taskType === TaskType.ClashDetection;
    }

    get displayName() {
        return this._displayName.value;
    }

    get alias() {
        return this._alias.value;
    }

    get schedule() {
        return parseSchedule(this._schedule.value);
    }

    get scheduleScheme() {
        return this._schedule.value;
    }

    get additionalProperties() {
        return this._additionalProperties;
    }

    get isScheduleOnDemand(): boolean {
        return !this.schedule.enabled;
    }

    get averageDuration() {
        return this.statistics.value.averageDuration;
    }

    get syncProjects() {
        const syncProjectsFlag = this.additionalProperties.find((flag: IScheduleFlag) => flag.key === 'syncProject');
        return syncProjectsFlag && syncProjectsFlag['value'] === 'true';
    }

    get notifications() {
        return this._notifications.value;
    }

    get advanced() {
        return this._advanced.value;
    }

    get modelSettings() {
        const settings = this._settings?.value?.body?.models;
        return settings ? (settings.map((model: any) => {
            const path = model.path.replaceAll('\\', '/').replace(model.modelName, '');
            return {
                path,
                file: model.modelName,
                fullPath: model.path,
            };
        })) : null;
    }

    get model() {
        return this._settings?.value?.body?.model?.modelName;
    }

    get from() {
        return this._settings?.value?.body?.from;
    }

    public isFailure(taskHistory: TaskHistory | undefined) {
        return Boolean(taskHistory?.isFailure);
    }

    get clashAutomationSettings() {
        if (this.clashLabels) {
            return ClashAutomationSettings.Labels;
        } else if (this.clashTests) {
            return ClashAutomationSettings.Tests;
        }

        return ClashAutomationSettings.All;
    }

    get clashLabels() {
        return this._settings?.value?.body?.labels;
    }

    get clashTests() {
        return this._settings?.value?.body?.tests?.map((value: any) => value.DisplayName);
    }
}
