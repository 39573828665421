import { ICustomStatus } from '@/types/customStatus';
import { THexColor } from '@/types/common';
import { Color, CustomStatusCategory } from '@/constants';

export class CustomStatus {
    public uuid: string;
    public name: string;
    public category: CustomStatusCategory;
    public backgroundColor: THexColor;
    public textColor: THexColor;
    public description: string;
    public deletedAt: string;
    public isStandard: boolean;

    constructor(customStatus: ICustomStatus) {
        this.uuid = customStatus.uuid || '';
        this.name = customStatus.name;
        this.category = customStatus.category || null;
        this.backgroundColor = customStatus.backgroundColor || Color.red;
        this.textColor = customStatus.textColor || Color.white;
        this.description = customStatus.description || '';
        this.deletedAt = customStatus.deletedAt || '';
        this.isStandard = customStatus.isStandard || false;
    }

    get isDeleted(): boolean {
        return Boolean(this.deletedAt);
    }

    get isEditable(): boolean {
        return !this.isStandard;
    }

    get isToDoCategory() {
        return this.category === CustomStatusCategory.ToDo;
    }

    get isTrackingCategory() {
        return this.category === CustomStatusCategory.Tracking;
    }

    get isCompletedCategory() {
        return this.category === CustomStatusCategory.Completed;
    }

    public setDeleted(value: string) {
        this.deletedAt = value;
    }
}
