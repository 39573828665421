import _ from 'lodash';
import { EventClass } from '@/constants';
import { Notification } from '@/models';
import { isArrayTrivial } from '@/services';

interface INotificationGroupParams {
    operationId: string;
    notifications: Notification[]; // @todo | INotification[]
}

export class NotificationGroup {
    public operationId: string;
    public notifications: Notification[];

    constructor({ operationId, notifications }: INotificationGroupParams) {
        this.operationId = operationId;
        this.notifications = notifications.map((notification) => notification instanceof Notification ? notification : Notification.instantiate(notification));
    }

    get notificationByUuid() {
        return _.keyBy(this.notifications, 'uuid');
    }

    get someEventType() {
        return this.notifications[0].eventType;
    }

    get isRead() {
        return _.every(this.notifications, 'isRead');
    }

    get isIssue() {
        return EventClass.issues[this.someEventType];
    }

    get isOtherNormal() {
        return EventClass.otherNormal[this.someEventType];
    }

    get isOtherProgress() {
        return EventClass.otherProgress[this.someEventType];
    }

    get isSameIssueChanges() {
        return this.isIssue && isArrayTrivial(_.map(this.notifications, 'issueChangesVariants'));
    }

    get issueVariantsWithCount() {
        const objects: any = new Map();
        this.notifications.forEach((notification: Notification) => {
            notification.issueChangesVariants.forEach((variant: any, index: number) => {
                const uniq = notification.uniq[index];
                if (objects.get(uniq)) {
                    objects.get(uniq).issues[notification.data.issueId] = notification.data.issueId;
                    return;
                }
                objects.set(uniq, {
                    issues: { [notification.data.issueId]: notification.data.issueId },
                    issueChangesVariant: variant,
                });
            });
        });

        return Array.from(objects.values()).map((object: any) => {
           return {
               issueChangesVariant: object.issueChangesVariant,
               count: Object.keys(object.issues).length,
           };
        });
    }

    public static instantiate(data: INotificationGroupParams) {
        return new NotificationGroup(data);
    }

    public read() {
        this.notifications.forEach((notification) => notification.read());
    }

    public readUuid(uuid: string) {
        const notification = this.notificationByUuid[uuid];
        if (notification) {
            notification.read();
        }
    }
}
