















































































































































import _ from 'lodash';
import { Component, Emit, Ref, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { TranslateResult } from 'vue-i18n';
import { TRulesObj } from '@/types/TRulesObj';
import { API, AuthMethodType, RESPONSE, RouterNames, SAMLCookieNames } from '@/constants';
import { LoginLicense } from '@/models/SSO/LoginLicense';
import { Region, UnreachableError } from '@/models';
import { CookieHelper, FormValidator, startTimer } from '@/services';
import { normalizeRegionId } from '@/services/RegionService';
import { autofocusMixin } from '@/mixins';
import WsButton from '@/components/common/WsButton.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsSelect from '@/components/common/WsSelect.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import OTPInput from '@/components/common/OTPInput.vue';

enum Service {
    google = 'google',
    saml = 'saml',
}

enum LocalStorageKey {
    spEntityId = 'spEntityId',
}

enum Step {
    email,
    code,
    licenses,
    old,
}

enum Landing {
    appAuthorized = 'app-authorized',
    oauth = 'oauth',
}

@Component({
    components: {
        OTPInput,
        WsButton,
        WsInput,
        WsSelect,
        WsTruncate,
    },
})
export default class LoginSSOForm extends autofocusMixin {
    @Ref() public readonly emailForm!: HTMLFormElement;

    public readonly serviceList = [Service.google, Service.saml];
    public readonly Step = Step;

    public step = Step.email;
    public code = '';
    public service = '';
    public localError: TranslateResult = '';
    public regionId = this.$store.getters.regionId;
    public appSSO = this.$store.getters.appSSO;
    public email = '';
    public isDisabledResend = false;
    public secondsLeft = 0;
    public loginLicenses: LoginLicense[] = [];
    public spEntityId = '';
    public isInvalidCode = false;
    public otpTriggerKey = 1;

    get rules(): TRulesObj {
        return {
            email: [
                FormValidator.email,
            ],
            regionId: [
                (value: string) => (this.pureRegionsIds.includes(value) || this.$t('Simple_word.required')),
            ],
        };
    }

    get language() {
        return this.$store.getters.currentLanguage;
    }

    get pureRegionsIds() {
        return this.$store.getters.pureRegionsIds;
    }

    get isAppAuth(): boolean {
        return !this.appSSO.isEmpty;
    }

    get isSAML() {
        return this.service === Service.saml;
    }

    get isGoogle() {
        return this.service === Service.google;
    }

    get oauth() {
        return this.$store.getters.oauth;
    }

    get regionOptions() {
        return Region.makeOptions(this.$store.getters.pureRegions);
    }

    get authError() {
        return this.$store.getters.authError || this.localError;
    }

    get isNextButtonDisabled() {
        if (this.step !== Step.old) {
            return false;
        }
        return !this.service || (this.isSAML && !this.spEntityId);
    }

    get isAccessCode() {
        return this.$route.query.request === API.accessCode;
    }

    get presetRegionId() {
        return this.$route.params.region || this.$route.query.region;
    }

    @Emit()
    public reviztoInternal() {
        return this.email;
    }

    @Emit()
    public showTabs(value: boolean) {
        return value;
    }

    @Watch('step', { immediate: true })
    public onStepChange(step: Step) {
        const isShowTabs = [Step.email, Step.old].includes(step);
        this.showTabs(isShowTabs);
    }

    @Watch('code')
    public onChangeCode(code: string) {
        if (code) {
            this.isInvalidCode = false;
        }
    }

    public created() {
        this.regionId = normalizeRegionId(this.presetRegionId || this.regionId);
        if (this.presetRegionId) {
            this.changeRegion();
        }

        if (this.$route.query.oldSSO) {
            this.step = Step.old;
        }
    }

    public sendCode() {
        let secCodeDelay: number | undefined;
        this.$store.dispatch('getSendSSOCode', { email: this.email, language: this.language })
            .catch((error) => secCodeDelay = error.result === RESPONSE.TOO_EARLY ? Number(error.message) : undefined)
            .finally(() => this.startTimerResendCode(secCodeDelay));
    }

    public startTimerResendCode(secCodeDelay = 60) {
        startTimer(
            secCodeDelay,
            () => this.isDisabledResend = true,
            () => this.isDisabledResend = false,
            (secondsLeft) => this.secondsLeft = secondsLeft,
        );
    }

    public async onNextClick() {
        if (!this.emailForm.validate()) {
            return;
        }
        if (this.isAccessCode) {
            CookieHelper.setCookie('isAccessCode', 'true');
        }

        if (this.step === Step.old) {
            await this.changeRegion();
            if (this.isSAML) {
                this.onSAMLClick(this.spEntityId);
            } else if (this.isGoogle) {
                this.onOAuthClick();
            }
            return;
        }

        this.isInvalidCode = false;
        this.step = Step.code;
        this.sendCode();
    }

    public confirmCode() {
        this.$store.dispatch('getSendSSOLicenses', {
            email: this.email,
            code: this.code,
        }).then((loginLicenses) => {
            this.loginLicenses = this.isAppAuth
                ? _.reject(loginLicenses, { authMethodType: AuthMethodType.revizto_internal })
                : loginLicenses;

            if (this.loginLicenses.length === 1) {
                this.loginToLicense(this.loginLicenses[0]);
            } else {
                this.step = Step.licenses;
            }
        }).catch(() => {
            this.otpTriggerKey++;
            this.clearCode();
            this.isInvalidCode = true;
        });
    }

    public stepBack() {
        this.clearCode();
        this.clearEmail();
        this.step = Step.email;
    }

    public clearCode() {
        this.code = '';
    }

    public clearEmail() {
        this.email = '';
    }

    public loginToLicense(license: LoginLicense) {
        if (this.isAccessCode && !license.allowApiAccess) {
            return;
        }
        const resolved: Route = this.$router.resolve({
            name: RouterNames.MyLicenseProjects,
            params: { licenseId: String(license.id) },
        }).resolved;
        const json = JSON.stringify({ licenseId: license.id, language: this.language });
        CookieHelper.setCookie('switchLicenseAuth', json);
        this.$store.commit('setStartPathAfterLogin', resolved.path);
        switch (license.authMethodType) {
            case AuthMethodType.revizto_internal:
                this.reviztoInternal();
                break;
            case AuthMethodType.SAML:
                this.service = Service.saml;
                this.onSAMLClick(license.spEntityId);
                break;
            case AuthMethodType.google:
                this.service = Service.google;
                this.onOAuthClick();
                break;
            default: {
                const _never: never = license.authMethodType;
                throw new UnreachableError(license.authMethodType);
            }
        }
    }

    public async onSAMLClick(spEntityId: string) {
        const deviceId = this.$store.getters.deviceId;
        localStorage.setItem(LocalStorageKey.spEntityId, spEntityId);

        const data = {
            spEntityId,
            device_id: deviceId,
        };

        const response = await this.$store.dispatch('postSAMLInitData', data);
        if (response.result !== RESPONSE.SUCCESS) {
            switch (response.result) {
                case RESPONSE.BANNED_PERMANENTLY:
                    this.localError = this.$t('errors.bannedPermanently');
                    break;
                case RESPONSE.OBJECT_NOT_EXISTS:
                    this.localError = this.$t('Login.companyDomainNotFound');
                    break;
                default:
                    this.localError = this.$t('Collocation.internalError');
            }
            return;
        }
        const { url, authNRequestId, transitionalAuth } = response.data;
        CookieHelper.setCookie(SAMLCookieNames.authNRequestId, authNRequestId);
        CookieHelper.setCookie(SAMLCookieNames.transitionalAuth, transitionalAuth);
        location.href = url;
    }

    public async onOAuthClick() {
        const landing = this.isAppAuth ? Landing.appAuthorized : Landing.oauth;
        await this.$store.dispatch('loadOAuthServicesUrls', { service: this.service, landing });
        location.href = this.oauth[this.service].url;
    }

    public changeRegion() {
        this.$store.commit('setRegionId', this.regionId);
    }
}
