export const PredefinedPeriod = {
    previous_day: 1,
    previous_week: 2,
    previous_month: 3,
    current_day: 4,
    current_week: 5,
    current_month: 6,
};

type PredefinedPeriodKeys = keyof typeof PredefinedPeriod;
export type PredefinedPeriodT = typeof PredefinedPeriod[PredefinedPeriodKeys];
