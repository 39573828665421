import { Component } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

@Component
export default class AmplitudeMixin extends EventListenersBase {
    private enrichObject: Dict = {};

    private get defaultPayload(): Dict {
        const licenseRole = this.$store.getters.currentLicense?.role;
        const licenseRoleObj = licenseRole ? { licenseRole } : {};
        const licenseId = this.$store.getters.currentLicenseId;
        const licenseIdObj = licenseId ? { licenseId } : {};
        const projectId = Number(this.$route.params.projectId);
        const projectIdObj = projectId ? { projectId } : {};
        const senderPage = this.$route?.name || 'unknown';
        const senderComponent = this.$options.name;
        const regionId = this.$store.getters.regionId;
        const regionName = this.$store.getters.regionName;

        return {
            regionId,
            regionName,
            ...licenseIdObj,
            ...licenseRoleObj,
            ...projectIdObj,
            senderPage,
            senderComponent,
        };
    }

    private get enrichedPayload() {
        return {
            ...this.defaultPayload,
            ...this.enrichObject,
        };
    }

    public amplitudeLog(event: AmplitudeEvent, enrichObject: Dict = {}) {
        this.enrichObject = enrichObject;
        amplitudeLog(event, this.enrichedPayload);
    }
}
