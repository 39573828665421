import { AccessRole, LicenseMember } from '@/models';

export class ProjectMember {
    public readonly id: number;
    public readonly uuid: string;
    public readonly memberUuid: string;
    public email: string;
    public firstname: string;
    public lastname: string;
    public fullname: string;
    public avatar: string;
    public search: string;
    public accessRole: AccessRole;
    public member: LicenseMember;
    public tags: string[];
    public activated: boolean;
    public pending: boolean;
    public pendingApproval?: boolean;
    public deactivated: boolean;
    public frozen: boolean;
    public active: boolean;
    public lastActive: string;
    public company: string;
    public department: string;
    public location: string;
    public status: number;
    public suspendReason: number;

    constructor(projectMember: any = {}) {
        this.id = projectMember.id;
        this.uuid = projectMember.uuid;
        this.memberUuid = projectMember.member.uuid;
        this.email = projectMember.email;
        this.firstname = projectMember.firstname;
        this.lastname = projectMember.lastname;
        this.fullname = (projectMember.fullname || '').trim();
        this.avatar = projectMember.avatar;
        this.search = `${this.email} ${this.fullname}`;
        this.accessRole = new AccessRole(projectMember.accessRole);
        this.member = new LicenseMember(projectMember.member);
        this.tags = projectMember.tags || this.member.metaTags;
        this.activated = projectMember.activated; // поле необходимо, чтобы использовать конструктор для уже созданого экземпляра класса для последующей инициализации поля pending
        this.pending = !this.activated;
        this.pendingApproval = projectMember.pendingApproval || false;
        this.deactivated = projectMember.deactivated || false;
        this.frozen = projectMember.frozen || false;
        this.active = projectMember.active || false; // присваивается извне, так как данная переменная зависит от дополнительных внешних данных
        this.lastActive = projectMember.lastActive || '';
        this.company = projectMember.company;
        this.department = projectMember.department;
        this.location = projectMember.location;
        this.status = projectMember.status;
        this.suspendReason = projectMember.suspendReason;
        this.enrichMemberWithFields();
    }

    get suspended(): boolean {
        return this.frozen;
    }

    get displayName() {
        const fullname = (this.fullname || this.firstname + ' ' + this.lastname).trim();
        return fullname || this.email;
    }

    public enrichMemberWithFields() {
        this.member.company = this.member.company || this.company;
        this.member.department = this.member.department || this.department;
        this.member.location = this.member.location || this.location;
    }

    public getShortFullName(maxLength = 20) {
        return this.member.getShortFullName(maxLength);
    }
}

export interface ProjectMembersOption {
    text: string;
    value: string;
    search: string;
}
