import moment from 'moment';
import { IMutableField } from '@/types/common';
import { TResponse } from '@/types/common';

export const updateMutableFieldTime = <T>(field: IMutableField<T>): IMutableField<T> => {
    // todo дописать конвертацию времени в локальное
    if (field) {
        return {
            value: field.value,
            timestamp: moment.utc(field.timestamp).unix(),
        };
    }
    return field;
};

export const setMutableField = <T>(value: T): IMutableField<T> => {
    return {
        value,
        timestamp: moment.utc().unix(),
    };
};

export const isObjectEmpty = (object: any): boolean => {
    return Object.keys(object).length === 0;
};

export const isFieldCollapsable = (value: string): boolean => {
    return value.length > 61 || value.split('\n').length >= 3;
};
export const isResultSuccessfully = (result: TResponse): boolean => {
    let isSuccess = true;
    result.forEach((resultItem: any) => {
        if (resultItem.result !== 0) {
            isSuccess = false;
        }
    });
    return isSuccess;
};