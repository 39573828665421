






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NotifierPosition } from '@/constants/Notifier/NotifierPosition';
import { NotifierView } from '@/constants/Notifier/NotifierView';
import { NOTIFIER_DURATION_DEFAULT } from '@/constants/Notifier/NotifierDurationDefault';
import { BusEvent } from '@/constants';
import { eventBus } from '@/services/eventBus';
import WsNotification from '@/components/common/Notifier/WsNotification.vue';
import WsToast from '@/components/common/Notifier/WsToast.vue';

const notifierComponent = {
    [NotifierView.NOTIFICATION]: WsNotification,
    [NotifierView.TOAST]: WsToast,
};

const notifierWidth = {
    [NotifierView.NOTIFICATION]: '460px',
    [NotifierView.TOAST]: '300px',
};

@Component({
    components: {
        WsNotification,
        WsToast,
    },
})
export default class WsNotifier extends Vue {
    @Prop({ type: String, default: NotifierPosition.TOP_RIGHT }) position!: NotifierPosition;
    @Prop({ type: Number, default: NOTIFIER_DURATION_DEFAULT }) duration!: number;
    @Prop({ type: Number, default: Infinity }) maxNotifications!: number;

    public items: any[] = [];
    public queue: any[] = [];

    @Watch('items')
    public updateItems(value: any) {
        if (!Number.isFinite(this.maxNotifications) || value.length >= this.maxNotifications || !this.queue.length) {
            return;
        }

        const item = this.queue.shift();
        this.add(item);
    }

    public mounted() {
        eventBus.$on(BusEvent.addNotifier, this.add);
        eventBus.$on(BusEvent.clearNotifier, this.close);
        eventBus.$on(BusEvent.clearAllNotifier, this.closeAll);
    }
    
    public beforeUnmount() {
        eventBus.$off(BusEvent.addNotifier);
        eventBus.$off(BusEvent.clearNotifier);
        eventBus.$off(BusEvent.clearAllNotifier);
    }

    public getComponent(view: NotifierView) {
        return notifierComponent[view];
    }

    public getWidth(view: NotifierView) {
        return notifierWidth[view];
    }

    public add(item: any) {
        if (!Number.isFinite(this.maxNotifications) || this.items.length < this.maxNotifications) {
            this.items.push(item);

            const durationInternal = item.duration || this.duration;

            if (Number.isFinite(durationInternal)) {
                setTimeout(() => this.close(item.id), durationInternal);
            }
        } else {
            this.queue.push(item);
        }
    }

    public close(id: number | string) {
        this.items = this.items.filter((item) => item.id !== id);
    }

    public closeAll(view?: NotifierView) {
        if (view) {
            this.queue = this.queue.filter((item) => item.view !== view);
            this.items = this.items.filter((item) => item.view !== view);
        } else {
            this.queue = [];
            this.items = [];
        }
    }
}
