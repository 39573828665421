import _ from 'lodash';
import { Dict } from '@/types/Dict';

/**
 * Конвертация enum'а в массив
 * @param {Iterable} sourceEnum - исходный enum
 * @param {Object} itemScheme - схема итемов в массиве, для замены дефолтных имен полей на свои
 */
export function enumToObjectsArray(sourceEnum: any, itemScheme?: { key: string, value: string }) {
    const keyName = itemScheme ? itemScheme.key : 'key';
    const valueName = itemScheme ? itemScheme.value : 'value';
    return _.reduce(sourceEnum, (resultArray: object[], item: any) => {
        if (_.isString(item)) {
            resultArray.push({
                [keyName]: item,
                [valueName]: sourceEnum[item],
            });
        }
        return resultArray;
    }, []);
}

/**
 * Конвертация enum'а в Object
 */
export function enumToObject(sourceEnum: any): Dict {
    return _.reduce(sourceEnum, (result: Dict, item: any) => {
        if (_.isString(item)) {
            result[item] = sourceEnum[item];
        }
        return result;
    }, {});
}

/**
 * Получение списка значений из enum'а
 * @param {Iterable} sourceEnum - исходный enum
 */
export function enumToValuesArray(sourceEnum: any): number[] {
    return reducer<number>(sourceEnum);
}

/**
 * Получение списка ключей из enum'а
 * @param {Iterable} sourceEnum - исходный enum
 */
export function enumToKeysArray<valueType = string>(sourceEnum: any): valueType[] {
    return reducer<valueType>(sourceEnum, true);
}

function reducer<T>(sourceEnum: any, strings = false): T[] {
    return _.reduce(sourceEnum, (resultArray: T[], item: any) => {
        if (strings && _.isString(item) || !strings && !_.isString(item)) {
            resultArray.push(item);
        }
        return resultArray;
    }, []);
}

/**
 * Получение enum'а из массива
 * @param {Array} array - исходный массив
 */
export function arrayToEnum(array: string[]): any {
    const enumObject = {} as any;
    _.forEach(array, (value, index) => {
        enumObject[value] = index;
        enumObject[index] = value;
    });
    return Object.freeze(enumObject);
}
