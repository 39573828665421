export enum FilterType {
    created = 0,
    status,
    deadline,
    assignee,
    priority,
    commented,
    updated,
    unread,
    title,
    clashcount,
}
