












































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import { TranslateResult } from 'vue-i18n';
import { sanitizeHtml } from '@/services';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';

@Component({
    components: {
        WsTooltip,
        WsTruncateAuto,
        WsCloseButton,
    },
    methods: {
        sanitizeHtml,
    },
})
export default class WsDialog extends Vue {
    @Prop() public title!: TranslateResult;
    @Prop() public subtitle!: string;
    @Prop({ type: String, default: '15vh' }) public top!: string; // Margin from top of the window, in vh only.
    @Prop({ type: String, default: '50%' }) public width!: string;
    @Prop({ type: String, default: '' }) public maxWidth!: string;
    @Prop({ type: String, default: '.v-application' }) public attach!: string;
    @Prop({ type: String, default: '' }) public dataTest!: string;
    @Prop({ type: String, default: '' }) public dialogClass!: string;
    @Prop({ type: Array, default: () => [] }) public buttonsClasses!: string[];

    @Prop({ type: Boolean, default: false }) public loading!: boolean;
    @Prop({ type: Boolean, default: false }) public fullscreen!: boolean;
    @Prop({ type: Boolean, default: false }) public fullWidth!: boolean;
    @Prop({ type: Boolean, default: false }) public appendToBody!: boolean;
    @Prop({ type: Boolean, default: true }) public closeOnClickModal!: boolean;
    @Prop({ type: Boolean, default: false }) public noClickAnimation!: boolean;
    @Prop({ type: Boolean, default: false }) public modalAppendToBody!: boolean;
    @Prop({ type: Boolean, default: false }) public separatedButtons!: boolean;
    @Prop({ type: Boolean, default: false }) public noBodyScroll!: boolean;
    @Prop({ type: Boolean, default: false }) public fixedButtons!: boolean;
    @Prop({ type: Boolean, default: true }) public showClose!: boolean;
    @Prop({ type: Boolean, default: false }) public hideOverlay!: boolean;
    @Prop({ type: Boolean, default: false }) public stayInPlace!: boolean;
    @Prop({ type: Boolean, default: true }) public truncateTitle!: boolean;

    public visible = true;
    public readonly uuid = uuidv4();

    get wrapperClass() {
        return `ws-dialog-wrapper-${this.uuid}`;
    }

    get contentClass() {
        const extenderHeaderClass = this.$slots.subheader ? 'extended-header' : '';
        const emptyHeaderClass = this.isHeaderEmpty ? 'empty-header' : '';
        const fixedButtonsClass = this.fixedButtons ? 'fixed-buttons' : '';
        const noBodyScroll = this.noBodyScroll ? 'no-body-scroll' : '';

        return `ws-dialog ${extenderHeaderClass} ${emptyHeaderClass} ${fixedButtonsClass} ${noBodyScroll} ${this.dialogClass}`;
    }

    get persistent() {
        return this.fullscreen || !this.closeOnClickModal;
    }

    get noAnimationOnClick() {
        return this.fullscreen || this.noClickAnimation;
    }

    get transition() {
        return this.fullscreen ? 'dialog-fade-transition' : 'dialog-transition';
    }

    get isHeaderEmpty() {
        return !this.normalizedTitle.length && !this.subtitle;
    }

    get isLoading() {
        if (this.loading !== undefined) {
            return this.loading;
        }
        return this.$store.getters.isDialogLoading;
    }

    get normalizedTitle() {
        return this.title ? this.title.toString() : '';
    }

    public mounted() {
        const vdialogComponent = this.$refs.vdialog as Vue | undefined;

        if (!vdialogComponent?.$refs.dialog) {
            return;
        }

        if (!this.fullscreen) {
            (vdialogComponent.$refs.dialog as HTMLElement).style.maxHeight = `calc(100% - (${this.top} + 5vh))`;
            (vdialogComponent.$refs.dialog as HTMLElement).style.marginTop = this.top;
        }

        if (this.dataTest) {
            (vdialogComponent.$refs.dialog as HTMLElement).dataset.test = this.dataTest;
        }

    }

    @Watch('visible')
    public afterClose(value: boolean) {
        if (!value) {
            this.close();
        }
    }

    @Emit()
    public close() {
        return;
    }
}
