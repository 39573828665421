

















import { Component, Emit, Prop } from 'vue-property-decorator';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsClashSimple extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;

    @Emit()
    public click(value: string) {
        return { value, type: this.valueKey };
    }

    public readonly getCommaForListItem = getCommaForListItem;
}
