






























































import { Component, Vue } from 'vue-property-decorator';
import { AmplitudeEvent, Color } from '@/constants';
import { amplitudeLog, sanitizeHtml } from '@/services';
import DialogBoarding from '@/components/user/boarding/DialogBoarding.vue';
import DialogBoardingContent from '@/components/user/boarding/DialogBoardingContent.vue';
import WsButton from '@/components/common/WsButton.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    methods: {
        sanitizeHtml,
    },
    components: {
        DialogBoarding,
        DialogBoardingContent,
        WsButton,
        IconSvg24,
    },
})
export default class StepsDialog extends Vue {
    public readonly Color = Color;
    public activeStepIndex = 0;
    public stepsShown = 1;

    get activeStep() {
        return this.steps[this.activeStepIndex];
    }

    get steps() {
        return [
            {
                name: 'project-team',
                label: this.$t('Boarding.steps.projectTeam.label'),
                title: this.$t('Boarding.steps.projectTeam.title'),
                media: '/video/welcome/project-team.mp4',
                description: this.$t('Boarding.steps.projectTeam.text'),
            },
            {
                name: 'dashboards',
                label: this.$t('Boarding.steps.dashboards.label'),
                title: this.$t('Boarding.steps.dashboards.title'),
                media: '/video/welcome/dashboards.mp4',
                description: this.$t('Boarding.steps.dashboards.text'),
            },
            {
                name: 'web-issue-tracker',
                label: this.$t('Boarding.steps.webIssueTracker.label'),
                title: this.$t('Boarding.steps.webIssueTracker.title'),
                media: '/video/welcome/web-issue-tracker.mp4',
                description: this.$t('Boarding.steps.webIssueTracker.text'),
            },
            {
                name: 'notifications',
                label: this.$t('Boarding.steps.notifications.label'),
                title: this.$t('Boarding.steps.notifications.title'),
                media: '/video/welcome/notifications.mp4',
                description: this.$t('Boarding.steps.notifications.text'),
            },
        ];
    }

    public created() {
        amplitudeLog(AmplitudeEvent.boardingStepsOpen);
    }

    public close() {
        this.$store.commit('setIsShowBoardingSteps', false);
        amplitudeLog(AmplitudeEvent.boardingStepsClose, { steps: this.stepsShown });
    }

    public changeStep(index: number) {
        this.activeStepIndex = index;

        if (this.stepsShown < this.steps.length) {
            this.stepsShown++;
        }
    }
}
