import _ from 'lodash';
import { TranslateResult } from 'vue-i18n';
import { Dict } from '@/types/Dict';
import { IProjectItem } from '@/types/project/IProjectItem';
import {
    Color,
    IssuePriorityEnum,
    IssuesGrouping,
    ProcoreRFIStatus,
    ProcoreRFIType,
    SortByValueType,
    StampColorsPaletteHexEnum,
} from '@/constants';
import { NO_STAMP } from '@/constants/Stamps';
import { LicenseMember, ProjectMember, RawChartData } from '@/models';
import { FormValidator } from '@/services/FormValidator';
import { i18n } from '@/services/i18n';
import { NormalizeStatus } from '@/services/dashboards/NormalizeStatus';
import { sanitizeHtml } from '@/services/Sanitizer';
import { truncateString, truncateStringByWidth } from '@/services/StringMethods';

const DATALIST_LABEL_MAX_WIDTH = 200;

interface ILicenseOrganizationSettings {
    visibleCompany: boolean
    visibleDepartment: boolean
    visibleLocation: boolean
}

export class ChartViewService {
    public static get tailCallbacks() {
        return [
            (field: any) => _.isString(field) && ChartViewService.isMultiple(field),
            (field: any) => _.isString(field) && ChartViewService.isNo(field),
            (field: any) => !field,
        ];
    }

    public static sortPriority(a: string, b: string) {
        const prioritySort = {
            blocker: 1,
            critical: 2,
            major: 3,
            minor: 4,
            trivial: 5,
            none: 6,
        } as any;
        return prioritySort[a] > prioritySort[b] ? 1 : -1;
    }

    public static sortStatus(a: string, b: string) {
        const statusSort = {
            open: 1,
            in_progress: 2,
            solved: 3,
            closed: 4,
        } as any;
        return statusSort[a] > statusSort[b] ? 1 : -1;
    }

    public static sortDefault(keyA: string, keyB: string, grouping: string) {
        switch (grouping) {
            case IssuesGrouping.status:
                return this.sortStatus(keyA, keyB);
            case IssuesGrouping.priority:
                return this.sortPriority(keyA, keyB);
            default:
                return String(keyA).localeCompare(String(keyB), 'en-US', { numeric: true });
        }
    }

    public static getChartItemLabel(args: {
        field: string | null,
        grouping: string,
        members: LicenseMember[] | ProjectMember[],
        projects: IProjectItem[],
        stamps: any[],
        stampCategories: any[],
        selectedTags?: string[],
    } = { field: '', grouping: '', members: [], projects: [], stamps: [], stampCategories: [] }): TranslateResult {
        const { field, grouping, members, projects, stamps, stampCategories, selectedTags } = args;
        const localField = String(field);
        const translatePath = 'Dashboard.chart.settings.filter';

        if (localField === 'null') {
            if (grouping === IssuesGrouping.tags) {
                if (selectedTags?.length) {
                    return i18n.tc(`${translatePath}.${grouping}.notSelectedTags`);
                } else {
                    return i18n.tc(`${translatePath}.${grouping}.null`);
                }
            }
            if (grouping === IssuesGrouping.stampAbbr) {
                return i18n.tc(`${translatePath}.${grouping}.${localField}`);
            }
        }

        if (['', 'undefined', 'null'].includes(localField)) {
            if (grouping === IssuesGrouping.clashTest) {
                // It can happen when issue lose connect with clash test after the test changed without sync with issue tracker (in desktop app)
                return i18n.tc(`${translatePath}.${grouping}.unknown`);
            }

            return i18n.tc('Collocation.notSet');
        }

        if (localField === 'non-clash') {
            return i18n.tc(`${translatePath}.${grouping}.non-clash`);
        }

        if (localField === 'multiple-clashvalues') {
            return i18n.tc(`${translatePath}.${grouping}.multiple-clashValues`);
        }

        if (grouping === IssuesGrouping.project) {
            const foundProject = projects.find((project) => String(project.id) === localField);
            return foundProject ? foundProject.title : localField;
        }

        if (grouping === IssuesGrouping.stampAbbr) {
            const foundStamp = stamps.find((stamp: any) => String(stamp.abbreviation).toUpperCase() === localField.toUpperCase());
            return foundStamp?.title
                ? `${String(foundStamp.abbreviation).toUpperCase()} - ${foundStamp.title}`
                : localField.toUpperCase();
        }

        if (grouping === IssuesGrouping.stampColor) {
            return i18n.tc(`StampColor.${localField}`);
        }

        if (grouping === IssuesGrouping.stampCategory) {
            if (localField === 'noCategory') {
                return i18n.t('Collocation.noCategory');
            }
            if (localField === NO_STAMP) {
                return i18n.t('Collocation.noStamp');
            }
            const foundCategory = stampCategories.find(({ uuid }) => uuid === localField);
            return foundCategory?.title ? foundCategory.title : localField;
        }

        if (FormValidator.isValidEmail(localField)) {
            // @ts-ignore LicenseMember  and ProjectMember are not descendants of the same type
            const foundMember = members.find((member) => member.email.toLowerCase() === localField.toLowerCase());

            if (foundMember && foundMember.lastname) {
                return sanitizeHtml(foundMember.getShortFullName());
            }
        }

        const normalizedGrouping = NormalizeStatus.normalizeKey(grouping as IssuesGrouping);
        const translationKey = `${translatePath}.${normalizedGrouping}.${localField}`;

        return i18n.te(translationKey) ? i18n.tc(translationKey) : localField;
    }

    public static getChartItemAdditionalLabel(
        email: string,
        members: LicenseMember[] | ProjectMember[],
        settings: ILicenseOrganizationSettings,
    ): string {
        const arrayOfFields = [];
        if (FormValidator.isValidEmail(email)) {
            // @ts-ignore
            const foundMember = members.find((member) => member.email.toLowerCase() === email.toLowerCase());
            if (foundMember) {
                if (settings.visibleCompany && foundMember.company) {
                    arrayOfFields.push(foundMember.company);
                }
                if (settings.visibleDepartment && foundMember.department) {
                    arrayOfFields.push(foundMember.department);
                }
                if (settings.visibleLocation && foundMember.location) {
                    arrayOfFields.push(foundMember.location);
                }
            }
        }
        return arrayOfFields.join('・');
    }

    // Функция замены нулевых значений, приходящих в бэка, на дефолтные
    public static normalizeField(grouping: IssuesGrouping, field: string | number | null | undefined) {
        if (!field) {
            if (grouping === IssuesGrouping.procoreStatus) {
                return String(ProcoreRFIStatus.notConnected);
            }
            if (grouping === IssuesGrouping.procoreType) {
                return String(ProcoreRFIType.regular);
            }
            if (grouping === IssuesGrouping.priority) {
                return String(IssuePriorityEnum.none);
            }

            return 'null';
        }

        if ([IssuesGrouping.reporter, IssuesGrouping.assignee].includes(grouping)) {
            return String(field).toLowerCase();
        }

        return String(field);
    }

    public static isNo(word: string) {
        return word?.toLowerCase().startsWith('no ')
            || word?.toLowerCase().startsWith('non ')
            || word?.toLowerCase().startsWith('non-')
            || word?.toLowerCase() === 'null';
    }

    public static isMultiple(word: string) {
        return word?.toLowerCase().startsWith('multiple');
    }

    public static isOthers(word: string) {
        return word?.toLowerCase().startsWith('others');
    }

    public static compareForMultiple(a: string, b: string) {
        return ChartViewService.isMultiple(a) ? 1 : ChartViewService.isMultiple(b) ? -1 : 0;
    }

    public static compareForIsNo(a: string, b: string) {
        return ChartViewService.isNo(a) ? 1 : ChartViewService.isNo(b) ? -1 : 0;
    }

    public static compareForOthers(a: string, b: string) {
        return ChartViewService.isOthers(a) ? 1 : ChartViewService.isOthers(b) ? -1 : 0;
    }

    public static prepareData(rawChartData: RawChartData) {
        return rawChartData.resultItems.map(({ aggregate, fields }) => {
            const normalizedFieldParts = rawChartData.groupBy.map((grouping) => {
                const field = fields[grouping];
                return grouping && field !== undefined
                    ? { [grouping]: ChartViewService.normalizeField(grouping, field) }
                    : {};
            });
            const normalizedFields: any = normalizedFieldParts.reduce((acc, fieldPart) => ({ ...acc, ...fieldPart }), {});
            return {
                aggregate,
                fields: normalizedFields,
                value: aggregate,
                name: normalizedFields[rawChartData.groupBy[0]],
            };
        }) as Array<{ aggregate: number; fields: any; value: number; name: string; }>;
    }

    public static getSortedBarChartKeys(resultItems: any[], grouping: string, sorting: any, members: any[], projects: IProjectItem[], stamps: any[]): string[] {
        const namesKeyObject = {} as any;
        const prepareSortData = {} as any;

        resultItems.forEach(({ value, name }) => {
            // Default sorting occurs by translations for all cases except for statuses and priorities, for them keys in special sorting functions are used
            namesKeyObject[name] = (grouping === IssuesGrouping.status || grouping === IssuesGrouping.priority)
                ? name
                : ChartViewService.getChartItemLabel({
                    field: name,
                    grouping,
                    members,
                    projects,
                    stamps,
                    stampCategories: [],
                });
            prepareSortData[name] = { value: ((prepareSortData[name] || {}).value || 0) + value, name };
        });

        const sortedResultItems = ChartViewService.sortValues(Object.values(prepareSortData), namesKeyObject, sorting, grouping);
        return sortedResultItems.map(({ name }) => name);
    }

    public static sortValues(data: any[], legendKeysObj: any, sorting: any, grouping: string): Array<{
        name: string;
        value: number;
        aggregate: number;
        fields: object
    }> {
        const dataKeysObj = {} as any;
        data.forEach((dataItem: any) => dataKeysObj[dataItem.name] = dataItem);

        const sortedKeys = Object.keys(dataKeysObj);
        switch (sorting[grouping]) {
            case SortByValueType.ASC:
                sortedKeys.sort((keyA, keyB) => {
                    if (dataKeysObj[keyA].value === dataKeysObj[keyB].value) {
                        return ChartViewService.sortDefault(legendKeysObj[keyA], legendKeysObj[keyB], grouping);
                    }
                    return dataKeysObj[keyA].value > dataKeysObj[keyB].value ? 1 : -1;
                });
                break;
            case SortByValueType.DESC:
                sortedKeys.sort((keyA, keyB) => {
                    if (dataKeysObj[keyA].value === dataKeysObj[keyB].value) {
                        return ChartViewService.sortDefault(legendKeysObj[keyA], legendKeysObj[keyB], grouping);
                    }
                    return dataKeysObj[keyA].value < dataKeysObj[keyB].value ? 1 : -1;
                });
                break;
            default:
                sortedKeys.sort((keyA, keyB) => ChartViewService.sortDefault(legendKeysObj[keyA], legendKeysObj[keyB], grouping));
        }

        //  Принудительная сортировка non-clash ->  multiple-tags
        sortedKeys.sort((keyA, keyB) => {
            return ChartViewService.compareForMultiple(dataKeysObj[keyA].name, dataKeysObj[keyB].name);
        });

        sortedKeys.sort((keyA, keyB) => {
            return ChartViewService.compareForIsNo(dataKeysObj[keyA].name, dataKeysObj[keyB].name);
        });

        sortedKeys.sort((keyA, keyB) => {
            return ChartViewService.compareForOthers(dataKeysObj[keyA].name, dataKeysObj[keyB].name);
        });

        // дополнительная сортировка для штампов графика Bar: последним должен идти всегда 'Non-stamps'
        if (grouping === IssuesGrouping.stampAbbr) {
            const indexNonStamp = sortedKeys.indexOf('null') || sortedKeys.indexOf(NO_STAMP);
            if (indexNonStamp !== -1) {
                sortedKeys.push(sortedKeys.splice(indexNonStamp, 1)[0]);
            }
        }

        return sortedKeys.map((key: string) => dataKeysObj[key]);
    }

    public static getTooltipHtml({
        groupBy,
        data,
        labelName,
        part1,
        part2,
        value,
        percent,
        dataList = [data],
        part1List = [part1],
        valueList = [value],
        date,
    }: Dict) {
        const isStampColorArr = groupBy.map((grouping: string) => grouping === IssuesGrouping.stampColor);
        if (groupBy.length === 1) {
            isStampColorArr[1] = isStampColorArr[0];
        }

        // Enough for all labels as project title, statuses, types and others
        labelName = labelName ? truncateString(labelName, 250) : '';

        const border = `1px solid ${Color.dividerGray}`;
        const colorDefaultBlack = `color: ${Color.defaultBlack};`;

        let Style: any = {
            content: '' +
                'color: black;' +
                'max-width: 50vw;',
            labelName: '' +
                'padding: 4px 8px;' +
                `border-bottom: ${border};` +
                'white-space: normal;' +
                'word-break: break-word;' +
                'overflow: hidden;' +
                'max-width: 400px;',
            date: '' +
                'padding: 4px 8px;' +
                `border-bottom: ${border};` +
                'white-space: normal;' +
                'word-break: break-word;' +
                'overflow: hidden;',
            bottom: '' +
                'display: flex; ' +
                'align-items: center;' +
                'padding-left: 6px',
            legendPart: '' +
                'display: flex;' +
                'flex-direction: column;' +
                'flex: 0 1 auto;' +
                'padding: 4px 4px;' +
                colorDefaultBlack,
            numbers: '' +
                'padding: 4px 8px;' +
                'flex: 1;' +
                'text-align: right;',
            value: '' +
                'font-weight: bold;' +
                'font-size: 14px;',
            percent: colorDefaultBlack,
            part1: 'display: none;' +
                'white-space: normal;' +
                'word-break: break-word;' +
                'overflow: hidden;',
            part2: 'display: none;' +
                'white-space: normal;' +
                'word-break: break-word;' +
                'overflow: hidden;',
            ellipsis: '' +
                'padding: 4px 8px;',
        };
        if (part1List.some(Boolean)) {
            Style.part1 += 'display: inline-block';
        }
        if (part2) {
            Style.part2 += 'display: inline-block;';
        }
        if (!percent) {
            Style.percent += 'display: none;';
        }
        if (dataList.length <= 1) {
            Style.legendPart += `border-right: ${border};`;
        }
        if (!labelName) {
            Style.labelName += 'display: none;';
        }

        const stampColorStyleAdd = `
            display: inline-block;
            width: 48px;
            height: 24px;
            border: 3px solid ${Color.defaultBlack};
            border-radius: 30px;
            text-align: center;
            line-height: 20px;
            font-size: 14px;
            font-weight: bold;
        `;

        const preprocessedDataList = dataList.map((dataItem: any, index: number) => {
            let hasStampColor1 = false;
            let hasStampColor2 = false;
            let hasStampColor3 = false;
            const style = {
                ...Style,
                stampColor1: 'display: none;',
                stampColor2: 'display: none;',
                stampColor3: 'display: none;',
                seriesColor: dataItem.color
                    ? `color: ${dataItem.color};`
                    : 'display: none;',
            };
            const stampColor = StampColorsPaletteHexEnum[dataItem?.fields?.stampColor];
            if (stampColor) {
                const add = stampColorStyleAdd + `border-color: ${stampColor}; color: ${stampColor};`;
                if (isStampColorArr[0]) {
                    style.stampColor1 += add;
                    hasStampColor1 = true;
                }
                if (isStampColorArr[1]) {
                    style.stampColor2 += add;
                    hasStampColor2 = true;
                }
                if (isStampColorArr[2]) {
                    style.stampColor3 += add;
                    hasStampColor3 = true;
                }
            }

            const part1 = truncateStringByWidth(part1List[index], DATALIST_LABEL_MAX_WIDTH);
            const value = truncateStringByWidth(valueList[index], DATALIST_LABEL_MAX_WIDTH);

            const part1Html = hasStampColor2 ? `<span style="${style.stampColor2}">ST</span>` : part1;
            const part2Html = hasStampColor3 ? `<span style="${style.stampColor3}">ST</span>` : part2;

            return {
                value,
                part1Html,
                part2Html,
                style,
                hasStampColor1,
            };
        });

        const { hasStampColor1, style: { stampColor1: styleStampColor1 } } = preprocessedDataList[0];
        Style.stampColor1 = styleStampColor1;

        const bottomHtmlList = preprocessedDataList
            .map(({ value, part1Html, part2Html, style }: any) => `
                <div style="${style.bottom}">
                    <div style="${style.seriesColor}">⬤</div>
                    <div style="${style.legendPart}">
                        <span style="${style.part1}">
                            ${part1Html}
                        </span>
                        <span style="${style.part2}">
                            ${part2Html}
                        </span>
                    </div>
                    <div style="${style.numbers}">
                        <div style="${style.value}">${value}</div>
                        <div style="${style.percent}">(${percent}%)</div>
                    </div>
                </div>
            `);

        const bottomHtmlListSlice = bottomHtmlList.slice(0, 10);
        const bottomEllipsis = bottomHtmlListSlice.length === bottomHtmlList.length
            ? ''
            : `<div style="${Style.ellipsis}">...</div>`;
        const bottomHtml = bottomHtmlListSlice.join('') + bottomEllipsis;

        const dateHtml = date ? `<div style="${Style.date}">${date}</div>` : '';

        const labelNameHtml = hasStampColor1
            ? `<span style="${Style.stampColor1}">ST</span>`
            : labelName;

        return `
            <div style="${Style.content}">
                ${dateHtml}
                <div style="${Style.labelName}">
                    ${labelNameHtml}
                </div>
                ${bottomHtml}
            </div>
        `;
    }

    public static readonly tooltipExtraCssText = '' +
        'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);' +
        'margin: 0;' +
        'padding: 0;' +
        'border-radius: 5px;';
}
