import { SortDirections } from '@/constants';
import { FormSortField } from '@/models';

export interface IFormItemParams {
    field: FormSortField;
    direction?: string;
    additionalListValues?: string[];
}

export class FormSortItem implements IFormItemParams {
    public readonly field: FormSortField;
    public readonly direction: string;
    public readonly additionalListValues: string[];

    constructor(params: IFormItemParams) {
        this.field = params.field;
        this.direction = params.direction || SortDirections.asc;
        this.additionalListValues = params.additionalListValues || [];
    }
}
