import { LicenseMember, Project } from '@/models';

export class MemberForApproval {
    public static instantiate(memberForApproval: any = {}) {
        return new MemberForApproval(memberForApproval);
    }
    public email: string;
    public invitations: MemberForApprovalInvitation[];

    constructor(memberForApproval: any = {}) {
        this.email = memberForApproval.email;
        this.invitations = memberForApproval.invitations
            .map((memberInvitation: any = {}) => new MemberForApprovalInvitation(memberInvitation));
    }
}

export class MemberForApprovalInvitation {
    public projectUuid: string;
    public roleName: string;
    public date: number;
    public senderUuid: string;

    constructor(invitation: any = {}) {
        this.projectUuid = invitation.projectUuid;
        this.roleName = invitation.roleName;
        this.date = invitation.date;
        this.senderUuid = invitation.senderUuid;
    }
}

export class MemberForApprovalEnhanced {
    public email: string;
    public projectTitle: string;
    public roleName: string;
    public date: number;
    public invitedBy: string;
    public invitations: MemberForApprovalInvitationEnhanced[];
    public status: number;

    constructor(memberApproval: any = {}) {
        this.email = memberApproval.email;
        this.projectTitle = memberApproval.projectTitle;
        this.roleName = memberApproval.roleName;
        this.date = memberApproval.date;
        this.invitedBy = memberApproval.invitedBy;
        this.invitations = memberApproval.invitations;
        this.status = memberApproval.status;
    }
}

export class MemberForApprovalInvitationEnhanced {
    public project: Project;
    public role: { [name: string]: string; };
    public date: number;
    public invitedBy: LicenseMember | undefined;

    constructor(invitation: any = {}) {
        this.project = invitation.project;
        this.role = invitation.role;
        this.date = invitation.date;
        this.invitedBy = invitation.invitedBy;
    }
}
