



































import { Component, Prop } from 'vue-property-decorator';
import IconSvgBase from '@/components/common/icon/IconSvgBase.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
    },
})
export default class IconSvg16 extends IconSvgBase {
    @Prop({ default: 16 }) public size!: number;

    get url() {
        return require(`./svg/${this.iconName}_16.svg`);
    }
}
