import _ from 'lodash';

export enum AdditionalFieldName {
    Company = 'company',
    Department = 'department',
    Location = 'location',
}

export enum MergeDialogAction {
    Merge,
    Rename
}

export const ADDITIONAL_FIELDS_MAX_LENGTH = 120;
