import { Component, Vue } from 'vue-property-decorator';
import { TRules } from '@/types/TRulesObj';

const checkIsMaxLengthOfTag = (value: string) => value.length <= 1024;

@Component
export default class DialogEditTagsMixin extends Vue {
    get rules(): TRules {
        return [
            (values: string[]) =>  {
                return values.every(checkIsMaxLengthOfTag) || this.$t('IssueSettings.tags.tagTooLong');
            },
        ];
    }

    public isCheckDisableSave(tags: string[]) {
        return !tags.every(checkIsMaxLengthOfTag);
    }
}
