import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
    directives: {
        focus: {
            inserted(el) {
                const input = el.querySelector('input');
                if (input) {
                    input.focus();
                }
            },
        },
    },
})
export default class AutofocusMixin extends Vue {}
