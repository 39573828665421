


















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import WsButton from '@/components/common/WsButton.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    components: {
        WsButton,
        WsDialog,
    },
})
export default class DialogDeleteIssues extends Vue {
    @Prop({ required: true }) public count!: number;

    @Emit()
    public close() {
        return;
    }

    @Emit()
    public remove() {
        return;
    }
}
