import { ExtraFieldsManagementMode, ExtraFieldsManagementString } from '@/constants';
import { License, UnreachableError } from '@/models';
import { i18n } from '@/services/i18n';

export function getExtraFieldsManagementMode(license: License) {
    const { allowedExtraFieldsManagement } = license.licenseOptions;
    switch (allowedExtraFieldsManagement) {
        case ExtraFieldsManagementString.any:
            return ExtraFieldsManagementMode.any;
        case ExtraFieldsManagementString.listed:
            return ExtraFieldsManagementMode.listed;
        case null:
            return ExtraFieldsManagementMode.none;
        default: {
            const _never: never = allowedExtraFieldsManagement;
            throw new UnreachableError(allowedExtraFieldsManagement);
        }
    }
}

export function getExtraFieldsManagementName(mode: ExtraFieldsManagementMode) {
    switch (mode) {
        case ExtraFieldsManagementMode.none:
            return i18n.t('License.licenseAdmins');
        case ExtraFieldsManagementMode.any:
            return i18n.t('License.licenseAdminsAndAllProjectAdmins');
        case ExtraFieldsManagementMode.listed:
            return i18n.t('License.licenseAdminsAndSpecifiedProjectAdmins');
        default: {
            const _never: never = mode;
            throw new UnreachableError(mode);
        }
    }
}
