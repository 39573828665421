









































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Color, IssuePriorityEnum } from '@/constants';

@Component
export default class IssuePriorityIcon extends Vue {
    @Prop() public priority!: IssuePriorityEnum;

    public iconColor = Color.defaultBlack;
    public IssuePriorityEnum = IssuePriorityEnum;

    @Emit()
    public click() {
        return;
    }

    get rectFill() {
        const colors: { [key in IssuePriorityEnum]: string } = {
            [IssuePriorityEnum.blocker]: '#660000',
            [IssuePriorityEnum.critical]: '#CC2929',
            [IssuePriorityEnum.major]: '#FA9600',
            [IssuePriorityEnum.minor]: '#02B609',
            [IssuePriorityEnum.trivial]: '#24B2B2',
            [IssuePriorityEnum.none]: '',
        };
        return colors[this.priority];
    }

    get pathD() {
        const d: { [key in IssuePriorityEnum]: string } = {
            [IssuePriorityEnum.blocker]: 'M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 6C8.68629 6 6 8.68629 6 12C6 13.1127 6.30289 14.1547 6.83073 15.048L15.048 6.83073C14.1547 6.30289 13.1127 6 12 6ZM18 12C18 15.3137 15.3137 18 12 18C10.8873 18 9.84532 17.6971 8.95205 17.1693L17.1693 8.95205C17.6971 9.84532 18 10.8873 18 12Z',
            [IssuePriorityEnum.critical]: 'M10.9999 3.50086C11.5857 2.91508 12.5354 2.91508 13.1212 3.50086L18.6212 9.00086C19.207 9.58665 19.207 10.5364 18.6212 11.1222C18.0354 11.708 17.0857 11.708 16.4999 11.1222L13.5605 8.18284V14.0615C13.5605 14.89 12.889 15.5615 12.0605 15.5615C11.2321 15.5615 10.5605 14.89 10.5605 14.0615V8.18284L7.62121 11.1222C7.03542 11.708 6.08567 11.708 5.49989 11.1222C4.9141 10.5364 4.9141 9.58665 5.49989 9.00086L10.9999 3.50086Z',
            [IssuePriorityEnum.major]: 'M10.9393 5.43934C11.5251 4.85355 12.4749 4.85355 13.0607 5.43934L18.5607 10.9393C19.1464 11.5251 19.1464 12.4749 18.5607 13.0607C17.9749 13.6464 17.0251 13.6464 16.4393 13.0607L13.5 10.1213V17.5C13.5 18.3284 12.8284 19 12 19C11.1716 19 10.5 18.3284 10.5 17.5V10.1213L7.56066 13.0607C6.97487 13.6464 6.02513 13.6464 5.43934 13.0607C4.85355 12.4749 4.85355 11.5251 5.43934 10.9393L10.9393 5.43934Z',
            [IssuePriorityEnum.minor]: 'M13.0607 18.5607C12.4749 19.1464 11.5251 19.1464 10.9393 18.5607L5.43934 13.0607C4.85355 12.4749 4.85355 11.5251 5.43934 10.9393C6.02513 10.3536 6.97487 10.3536 7.56066 10.9393L10.5 13.8787L10.5 6.5C10.5 5.67157 11.1716 5 12 5C12.8284 5 13.5 5.67157 13.5 6.5L13.5 13.8787L16.4393 10.9393C17.0251 10.3536 17.9749 10.3536 18.5607 10.9393C19.1464 11.5251 19.1464 12.4749 18.5607 13.0607L13.0607 18.5607Z',
            [IssuePriorityEnum.trivial]: 'M13.0607 16.5607C12.4749 17.1464 11.5251 17.1464 10.9393 16.5607L5.43934 11.0607C4.85355 10.4749 4.85355 9.52513 5.43934 8.93934C6.02513 8.35355 6.97487 8.35355 7.56066 8.93934L10.5 11.8787L10.5 8.5C10.5 7.67157 11.1716 7 12 7C12.8284 7 13.5 7.67157 13.5 8.5L13.5 11.8787L16.4393 8.93934C17.0251 8.35355 17.9749 8.35355 18.5607 8.93934C19.1464 9.52513 19.1464 10.4749 18.5607 11.0607L13.0607 16.5607Z',
            [IssuePriorityEnum.none]: '',
        };
        return d[this.priority];
    }

    public handleClick() {
        this.click();
    }
}
