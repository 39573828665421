export const TimeFormat = {
    '24h': 1,
    '12h': 2,
};

export const GetTimeFormat: { [key: number]: string } = {
    1: '24h',
    2: '12h',
};

type TimeFormatKeys = keyof typeof TimeFormat;
export type TimeFormatT = typeof TimeFormat[TimeFormatKeys];
