import Router from '@/router';
import { Issue, ProjectMember } from '@/models';
import store from '@/storage';

// Don't export by index.ts in services dir for avoid dependency of Store in all services imports
export function getIssueAssigneeMember(issue: Issue): ProjectMember {
    const projectId = Number(Router.currentRoute.params.projectId);
    const projectMembersByEmail = store.getters.projectMembersByEmailObj(projectId);
    return projectMembersByEmail[issue.assignee];
}

