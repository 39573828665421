export const APAC = [
    {
        'uuid': '5adbdc3e-237f-47f3-a376-02ad10d585a4',
        'title': 'Architect Concept',
        'fields': '{"stampAbbr":"0AR"}',
        'created': '2022-11-04 03:23:24',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'nodeRole': 1,
    },
    {
        'uuid': '54a38c39-aa06-4863-9cfd-d097d0ad4288',
        'title': 'Civil Concept',
        'fields': '{"stampAbbr":"0CV"}',
        'created': '2022-11-04 03:23:54',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'nodeRole': 1,
    },
    {
        'uuid': '3b3b52e4-4db4-4fea-8530-0b36b8ca7ac9',
        'title': 'Electrical Concept',
        'fields': '{"stampAbbr":"0EL"}',
        'created': '2022-11-04 03:24:06',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'nodeRole': 1,
    },
    {
        'uuid': 'd59d2fd9-8524-42bf-9472-daa8f45d21d3',
        'title': 'Fire Concept',
        'fields': '{"stampAbbr":"0FR"}',
        'created': '2022-11-04 03:24:33',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'nodeRole': 1,
    },
    {
        'uuid': '3141b71a-e2dd-4629-a4d4-710804bf7bcf',
        'title': 'Hydraulic Concept',
        'fields': '{"stampAbbr":"0HY"}',
        'created': '2022-11-04 03:24:43',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'nodeRole': 1,
    },
    {
        'uuid': '9a1626a9-ce83-44a5-aa73-bf0be6bd1096',
        'title': 'ICT Concept',
        'fields': '{"stampAbbr":"0IT"}',
        'created': '2022-11-08 03:43:53',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'nodeRole': 1,
    },
    {
        'uuid': '782de825-0e94-4dca-9e24-26db9b44075e',
        'title': 'Mechanical Concept',
        'fields': '{"stampAbbr":"0ME"}',
        'created': '2022-11-04 03:23:36',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'nodeRole': 1,
    },
    {
        'uuid': '459d139d-f6cc-4aae-9106-4f7fe739a478',
        'title': 'Structure Concept',
        'fields': '{"stampAbbr":"0ST"}',
        'created': '2022-11-04 03:24:56',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'nodeRole': 1,
    },
    {
        'uuid': '0dea0dca-48d4-4dc4-b11f-d5d2544482b4',
        'title': 'Architect Design',
        'fields': '{"stampAbbr":"1AR"}',
        'created': '2022-06-22 00:27:59',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': '35ab88e8-ca12-4a66-bd8f-0256feb9ed4c',
        'title': 'Civil Design',
        'fields': '{"stampAbbr":"1CV"}',
        'created': '2022-06-22 00:31:49',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': 'cce0028c-f10f-4d52-97dd-c63345305f21',
        'title': 'Electrical Design',
        'fields': '{"tags":[],"stampAbbr":"1EL"}',
        'created': '2022-06-22 00:37:43',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': '9b759a64-2471-4fd2-b6b2-ed00e7b1d311',
        'title': 'Fire Design',
        'fields': '{"tags":[],"stampAbbr":"1FI"}',
        'created': '2022-06-22 00:39:10',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': 'd40968f9-afe1-47a6-93b5-b914948eac06',
        'title': 'Hydraulic Design',
        'fields': '{"tags":[],"stampAbbr":"1HY"}',
        'created': '2022-06-22 00:38:59',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': '5931147b-f0e0-4f3e-9c2b-a00146e85633',
        'title': 'ICT Design',
        'fields': '{"stampAbbr":"1IT"}',
        'created': '2022-06-22 00:34:48',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': 'f1b8b0f8-2b0f-435e-a780-bbc6ac283a2f',
        'title': 'Landscape Design',
        'fields': '{"stampAbbr":"1LA"}',
        'created': '2023-02-20 23:45:41',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': '256fa08c-1786-403f-83f4-4b0360709753',
        'title': 'Mechanical Design',
        'fields': '{"tags":[],"stampAbbr":"1ME"}',
        'created': '2022-06-22 00:37:28',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': '73522641-851e-491e-befd-3696537f5268',
        'title': 'Structure Design',
        'fields': '{"stampAbbr":"1ST"}',
        'created': '2022-06-22 00:34:02',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'nodeRole': 1,
    },
    {
        'uuid': 'f4bf7c2f-3b01-43b6-9de2-07cc1ea56e5d',
        'title': 'Architect',
        'fields': '{"stampAbbr":"2AR"}',
        'created': '2022-06-27 22:17:14',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': '78edc96c-c6a2-4c77-9a75-3af78c10dfbf',
        'title': 'Civil',
        'fields': '{"stampAbbr":"2CV"}',
        'created': '2022-06-27 22:50:15',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': '2f0d21ed-df02-4ee6-86e2-28db0d4ddbc0',
        'title': 'Electrical',
        'fields': '{"stampAbbr":"2EL"}',
        'created': '2022-06-27 22:49:22',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': '5f5f5574-cf66-4d38-a363-f37688dd2ec3',
        'title': 'Fire',
        'fields': '{"stampAbbr":"2FI"}',
        'created': '2022-06-27 22:49:33',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': '99769ec0-77a7-4ccd-ae48-d1c85d7eeb9e',
        'title': 'Hydraulic',
        'fields': '{"stampAbbr":"2HY"}',
        'created': '2022-06-27 22:49:45',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': '0b1b6b10-bc27-4f3e-9faf-711528574a0c',
        'title': 'Landscape',
        'fields': '{"stampAbbr":"2LA"}',
        'created': '2023-02-20 23:46:10',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': '6bfed84e-f499-47dd-9429-9c8c177c4dff',
        'title': 'Mechanical',
        'fields': '{"stampAbbr":"2ME"}',
        'created': '2022-06-27 22:49:57',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': 'd54bb165-1776-42d7-a78c-381e37c977df',
        'title': 'Structure',
        'fields': '{"stampAbbr":"2ST"}',
        'created': '2022-06-27 22:50:26',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': '822de146-4889-49e3-9aa4-40a4d302a947',
        'title': 'ICT',
        'fields': '{"stampAbbr":"2WT"}',
        'created': '2022-06-27 22:50:38',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'nodeRole': 1,
    },
    {
        'uuid': 'c84f5a0a-7913-4c9f-8ffd-2eace4068657',
        'title': '360 Photo',
        'fields': '{"stampAbbr":"360"}',
        'created': '2022-06-07 05:42:14',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '79cb32a5-2cac-434d-9ef0-8ff79b17770a',
        'nodeRole': 1,
    },
    {
        'uuid': '7ddc4c97-d7c2-4e20-9393-e77b3d61a37e',
        'title': 'A = approved',
        'fields': '{"stampAbbr":"4A","stampColor":5}',
        'created': '2022-09-21 03:44:59',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf765432-3b63-417c-b0bf-08191d0c5aae',
        'nodeRole': 1,
    },
    {
        'uuid': '0d36eaf0-f4aa-4ce0-b127-4fd20bb21267',
        'title': 'Architect 2D',
        'fields': '{"stampAbbr":"4AR"}',
        'created': '2022-06-27 22:19:03',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'nodeRole': 1,
    },
    {
        'uuid': '8f4e06dc-ba5f-4545-9115-d5535a55c83d',
        'title': 'B = approved with comments',
        'fields': '{"stampAbbr":"4B","stampColor":2}',
        'created': '2022-09-21 03:45:27',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf765432-3b63-417c-b0bf-08191d0c5aae',
        'nodeRole': 1,
    },
    {
        'uuid': 'e56b2da4-e953-4bbc-ab04-ded30ccda4ad',
        'title': 'C = rejected see comments',
        'fields': '{"stampAbbr":"4C","stampColor":1}',
        'created': '2022-09-21 03:45:40',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf765432-3b63-417c-b0bf-08191d0c5aae',
        'nodeRole': 1,
    },
    {
        'uuid': 'd471660e-f9cb-4613-a256-d02efead124a',
        'title': 'Civil 2D',
        'fields': '{"stampAbbr":"4CV"}',
        'created': '2022-06-27 22:47:42',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'nodeRole': 1,
    },
    {
        'uuid': '3c2c3a62-705c-48d3-b433-2b2db752b472',
        'title': 'Electrical 2D',
        'fields': '{"stampAbbr":"4EL"}',
        'created': '2022-06-27 22:44:49',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'nodeRole': 1,
    },
    {
        'uuid': 'bc7c83c0-9537-40d5-b9a3-cfc3a97ef6f7',
        'title': 'Fire 2D',
        'fields': '{"stampAbbr":"4FI"}',
        'created': '2022-06-27 22:45:15',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'nodeRole': 1,
    },
    {
        'uuid': 'fc6d1ad0-dd15-40f0-93bb-92e32280e25c',
        'title': 'Hydraulic 2D',
        'fields': '{"stampAbbr":"4HY"}',
        'created': '2022-06-27 22:45:55',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'nodeRole': 1,
    },
    {
        'uuid': 'd30bf517-4092-4ffb-8989-ced102284fb3',
        'title': 'ICT 2D',
        'fields': '{"stampAbbr":"4IT"}',
        'created': '2022-06-27 22:48:45',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'nodeRole': 1,
    },
    {
        'uuid': '1a0f31a5-61fa-4ba9-8566-863612c93c8d',
        'title': 'Mechanical 2D',
        'fields': '{"stampAbbr":"4ME"}',
        'created': '2022-06-27 22:47:07',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'nodeRole': 1,
    },
    {
        'uuid': '387b67df-aa74-42c1-975a-45f134cd8465',
        'title': 'Structure 2D',
        'fields': '{"stampAbbr":"4ST"}',
        'created': '2022-06-27 22:48:29',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'nodeRole': 1,
    },
    {
        'uuid': '6a2e5467-98e7-4f39-b0a4-8f617dc0cf84',
        'title': 'Architect ACD',
        'fields': '{"stampAbbr":"5AR"}',
        'created': '2022-07-19 01:54:16',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'nodeRole': 1,
    },
    {
        'uuid': '4cd286ce-01ce-4a7e-976e-605bcdb77ede',
        'title': 'Civil ACD',
        'fields': '{"stampAbbr":"5CV"}',
        'created': '2022-07-19 02:00:29',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'nodeRole': 1,
    },
    {
        'uuid': '23b4a56b-11f1-48a3-9e44-8764285b9caa',
        'title': 'Electrical ACD',
        'fields': '{"tags":[],"stampAbbr":"5EL"}',
        'created': '2022-07-19 02:01:49',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'nodeRole': 1,
    },
    {
        'uuid': '1ea7ba37-c849-4832-9885-6fb0f2032693',
        'title': 'Fire ACD',
        'fields': '{"tags":[],"stampAbbr":"5FI"}',
        'created': '2022-07-19 02:02:52',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'nodeRole': 1,
    },
    {
        'uuid': '6c81474d-7d39-4095-96ea-1535bfb1d8db',
        'title': 'Hydraulic ACD',
        'fields': '{"tags":[],"stampAbbr":"5HY"}',
        'created': '2022-07-19 02:03:57',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'nodeRole': 1,
    },
    {
        'uuid': '8654e994-b3b2-4a54-8a86-09c1c31e2763',
        'title': 'ICT ACD',
        'fields': '{"stampAbbr":"5IT"}',
        'created': '2022-07-19 02:05:02',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'nodeRole': 1,
    },
    {
        'uuid': '84cca280-5e1d-4b26-8246-380e53f6d6ab',
        'title': 'Mechanical ACD',
        'fields': '{"tags":[],"stampAbbr":"5ME"}',
        'created': '2022-07-19 02:01:08',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'nodeRole': 1,
    },
    {
        'uuid': '3080bf20-50a0-4332-804c-16a4ebaa47fb',
        'title': 'Structure ACD',
        'fields': '{"stampAbbr":"5ST"}',
        'created': '2022-07-19 02:06:04',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'nodeRole': 1,
    },
    {
        'uuid': 'd1162917-d027-4fdd-a221-aa3bc5105352',
        'title': 'Architect TACD',
        'fields': '{"stampAbbr":"6AR"}',
        'created': '2022-11-04 03:27:37',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'nodeRole': 1,
    },
    {
        'uuid': '0d8c2eb5-1dd9-4df7-911d-3933abfb1ac2',
        'title': 'Civil TACD',
        'fields': '{"stampAbbr":"6CV"}',
        'created': '2022-11-04 03:30:09',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'nodeRole': 1,
    },
    {
        'uuid': '1ddb0761-a9c0-41cf-9734-eb0d1441001d',
        'title': 'Electrical TACD',
        'fields': '{"stampAbbr":"6EL"}',
        'created': '2022-11-06 22:48:03',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'nodeRole': 1,
    },
    {
        'uuid': '3e9f30d4-401d-4378-b859-41a0833b927c',
        'title': 'Fire TACD',
        'fields': '{"stampAbbr":"6FR"}',
        'created': '2022-11-06 22:48:25',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'nodeRole': 1,
    },
    {
        'uuid': '84e1a8c9-30ed-49ea-a46c-0a85709461a8',
        'title': 'Hydrauilic TACD',
        'fields': '{"stampAbbr":"6HY"}',
        'created': '2022-11-06 22:49:04',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'nodeRole': 1,
    },
    {
        'uuid': '2f522e97-f43a-4057-8d29-5763552cc659',
        'title': 'ICT TACD',
        'fields': '{"stampAbbr":"6IT"}',
        'created': '2022-11-06 22:49:20',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'nodeRole': 1,
    },
    {
        'uuid': '5281f10f-b1c7-40af-aa43-72d14c92ba4a',
        'title': 'Mechanical TACD',
        'fields': '{"stampAbbr":"6ME"}',
        'created': '2022-11-06 22:49:31',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'nodeRole': 1,
    },
    {
        'uuid': '5fb8cf93-b547-4d15-9cbb-32cc29bb70ea',
        'title': 'Structure TACD',
        'fields': '{"stampAbbr":"6ST"}',
        'created': '2022-11-06 22:51:07',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'nodeRole': 1,
    },
    {
        'uuid': '7e243b99-fbb6-4e28-b6f5-5e0279709d4a',
        'title': 'Company A',
        'fields': '{"stampAbbr":"981"}',
        'created': '2022-06-14 00:41:29',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'deee3809-6d1a-4618-9d6d-448798361885',
        'nodeRole': 1,
    },
    {
        'uuid': '31d708cf-8af8-40ee-bf80-2f3436e06d40',
        'title': 'Company B',
        'fields': '{"stampAbbr":"982"}',
        'created': '2022-06-14 00:41:57',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'deee3809-6d1a-4618-9d6d-448798361885',
        'nodeRole': 1,
    },
    {
        'uuid': 'fdf2cf4c-c3e9-4a8d-9147-b64c0ce8fc5c',
        'title': 'Aluminium windows',
        'fields': '{"tags":[],"priority":"none","stampAbbr":"AL","visibility":1}',
        'created': '2018-12-17 01:51:26',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '94a36648-7c52-473a-a437-bf7b5e082a45',
        'title': 'Balustrade Defect',
        'fields': '{"tags":[],"stampAbbr":"BA","visibility":1}',
        'created': '2018-12-17 01:40:14',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'f8f822f6-d5b3-44e9-b9d3-f7c89df3c41e',
        'title': 'Architect General Buildability',
        'fields': '{"tags":[],"stampAbbr":"BAR"}',
        'created': '2021-01-20 00:30:50',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'nodeRole': 1,
    },
    {
        'uuid': '81d7f197-d43e-43d8-a0ac-f944d6566f83',
        'title': 'Civil Buildability',
        'fields': '{"stampAbbr":"BCV"}',
        'created': '2022-11-08 05:26:27',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'nodeRole': 1,
    },
    {
        'uuid': 'fc7fac5c-eef5-444d-887a-98bf5c4bc62e',
        'title': 'Electrical Buildability',
        'fields': '{"tags":[],"stampAbbr":"BEL"}',
        'created': '2021-01-20 00:41:03',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'nodeRole': 1,
    },
    {
        'uuid': '9f76fc27-581c-4b8f-b72f-f538ea8bb6b7',
        'title': 'Fire Buildability',
        'fields': '{"tags":[],"stampAbbr":"BFI"}',
        'created': '2021-01-20 00:39:10',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'nodeRole': 1,
    },
    {
        'uuid': '05465080-8573-4b40-9e2d-6c983fc34aed',
        'title': 'Hydraulic Buildability',
        'fields': '{"tags":[],"stampAbbr":"BHY"}',
        'created': '2021-01-20 00:40:38',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'nodeRole': 1,
    },
    {
        'uuid': '55aa8e2c-8867-4eda-ab0d-c7c52bd00080',
        'title': 'ICT Buildability',
        'fields': '{"stampAbbr":"BIT"}',
        'created': '2022-11-08 05:26:48',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'nodeRole': 1,
    },
    {
        'uuid': '2fa4d2a7-8535-4cbc-acfd-92dcb28e43e4',
        'title': 'Blockwork Defect',
        'fields': '{"tags":[],"stampAbbr":"BL","visibility":1}',
        'created': '2018-12-17 02:01:34',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'ad39d3a1-1c5a-4675-b440-c3da0d49c887',
        'title': 'Mechanical Buildability',
        'fields': '{"tags":[],"stampAbbr":"BMC"}',
        'created': '2021-01-20 00:38:49',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'nodeRole': 1,
    },
    {
        'uuid': '2897609d-453d-4ffa-add0-9484b66e8ccd',
        'title': 'Brickwork Defect',
        'fields': '{"tags":[],"stampAbbr":"BR","visibility":1}',
        'created': '2018-12-17 02:01:12',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'a5d4aa3e-a949-4fbe-ae72-d37b6186baad',
        'title': 'Structural Buildability',
        'fields': '{"tags":[],"stampAbbr":"BST"}',
        'created': '2021-01-20 00:38:08',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'nodeRole': 1,
    },
    {
        'uuid': 'b4bd3b13-461d-4324-bda1-919f4a067094',
        'title': 'Carpentry Defect',
        'fields': '{"tags":[],"stampAbbr":"CA","visibility":1}',
        'created': '2018-12-14 00:46:00',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'cfda5a59-a38d-456e-b476-5fb3630b9978',
        'title': 'Concrete Footing Defect',
        'fields': '{"tags":[],"stampAbbr":"CF","visibility":1}',
        'created': '2018-12-17 01:42:08',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '43f7c611-4a7d-48fe-be13-e1fdcad3cb9d',
        'title': 'Cladding Defect',
        'fields': '{"tags":[],"stampAbbr":"CL","visibility":1}',
        'created': '2018-12-17 01:44:15',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '72188c15-def9-454f-8430-f363860fabd5',
        'title': 'Concrete Defect',
        'fields': '{"tags":[],"stampAbbr":"CO","visibility":1}',
        'created': '2018-12-17 01:58:23',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '32821d4a-eaf0-4c9e-bf07-2637b196cf06',
        'title': 'Ceilings and Partitions',
        'fields': '{"tags":[],"stampAbbr":"CP","visibility":1}',
        'created': '2018-12-17 01:57:11',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'cae2a9e3-cbc1-4eba-81b0-eea0df0cfc5a',
        'title': 'Civil Works Defect',
        'fields': '{"tags":[],"stampAbbr":"CVL","visibility":1}',
        'created': '2018-12-17 01:42:54',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '8e23c43d-3fee-4330-8c9d-5172d7aadc0a',
        'title': 'Door / Door Frame Defect',
        'fields': '{"tags":[],"stampAbbr":"DF","visibility":1}',
        'created': '2018-12-17 01:55:46',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '46dd6de9-d851-4ec3-992d-c13543dc1aad',
        'title': 'Electrical Defect',
        'fields': '{"tags":[],"stampAbbr":"EL","visibility":1}',
        'created': '2018-12-14 00:48:18',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '35f0614b-1633-4fff-86b8-d3eaab8af0a7',
        'title': 'Equipment / Furniture Defect',
        'fields': '{"tags":[],"stampAbbr":"EQ","visibility":1}',
        'created': '2018-12-17 01:41:32',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'f606dcfa-9cba-4f60-bb76-269a163e9817',
        'title': 'Environmental Issue',
        'fields': '{"stampAbbr":"EV1"}',
        'created': '2021-02-02 05:41:16',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '76e74bcf-61ec-48e6-bd71-43c6546c6d6d',
        'nodeRole': 1,
    },
    {
        'uuid': '36df22f1-f233-4dd8-85bb-f1d64c4ba4ce',
        'title': 'Fencing Defect',
        'fields': '{"tags":[],"stampAbbr":"FEN","visibility":1}',
        'created': '2018-12-17 01:47:15',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'dddd8680-446f-49f1-a9dc-291327527f7b',
        'title': 'Form fill',
        'fields': '{"stampAbbr":"FF"}',
        'created': '2021-04-15 05:04:48',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '30d460d4-173e-43fb-84d9-9898d8472f7f',
        'title': 'Fire Defect',
        'fields': '{"tags":[],"stampAbbr":"FI","visibility":1}',
        'created': '2018-12-14 22:21:42',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '418749a9-28f6-4cc9-8c10-479f93fe264d',
        'title': 'Flooring Defect',
        'fields': '{"tags":[],"stampAbbr":"FL","visibility":1}',
        'created': '2018-12-14 00:48:43',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'eb5e9914-0af6-45b1-9462-15edda08d891',
        'title': 'Formwork Defect',
        'fields': '{"tags":[],"stampAbbr":"FM","visibility":1}',
        'created': '2019-01-21 01:21:46',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '3afcec8e-78c6-47db-a71f-c2307de1e3f3',
        'title': 'Asset',
        'fields': '{"stampAbbr":"FM1"}',
        'created': '2022-11-04 03:25:53',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '8e0ed9d0-d014-420b-987b-ffa7d918de10',
        'nodeRole': 1,
    },
    {
        'uuid': 'd642155d-711a-43d3-a72d-88db6ce0889e',
        'title': 'Hydraulic Defect',
        'fields': '{"tags":[],"stampAbbr":"HYD","visibility":1}',
        'created': '2018-12-14 00:48:58',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '4a60baf6-f990-4991-b3df-5c7a33d8a8d5',
        'title': '2D Information Request',
        'fields': '{"stampAbbr":"IR2"}',
        'created': '2023-03-01 02:47:25',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'bf5dd877-377d-47a5-b041-c70c7b98b43d',
        'nodeRole': 1,
    },
    {
        'uuid': '67b6bd9b-7f7c-4676-b07d-16603f974c20',
        'title': '3D Information Request',
        'fields': '{"stampAbbr":"IR3"}',
        'created': '2023-03-01 02:47:44',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'bf5dd877-377d-47a5-b041-c70c7b98b43d',
        'nodeRole': 1,
    },
    {
        'uuid': '4a574022-8d91-485a-bca2-fbb6bd5110c6',
        'title': 'Joinery Defect',
        'fields': '{"tags":[],"stampAbbr":"JN","visibility":1}',
        'created': '2018-12-17 02:00:40',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '4507f925-776a-4f0e-a8e3-92831547e01d',
        'title': 'Laundry Chute Defect',
        'fields': '{"tags":[],"stampAbbr":"LC","visibility":1}',
        'created': '2018-12-17 01:53:32',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '14ff4420-6104-4342-9542-1b5723024a14',
        'title': 'Lift Defect',
        'fields': '{"tags":[],"stampAbbr":"LF","visibility":1}',
        'created': '2018-12-17 01:54:05',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '296b31c5-6241-4309-b87d-63a0b7b01706',
        'title': 'Landscaping Defect',
        'fields': '{"tags":[],"stampAbbr":"LS","visibility":1}',
        'created': '2018-12-17 02:02:31',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'a03a9b5a-dce1-47ea-ac00-b6efc0363796',
        'title': 'Mechanical Defect',
        'fields': '{"tags":[],"stampAbbr":"ME","visibility":1}',
        'created': '2018-12-14 00:48:34',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '872facc5-37bb-4a75-af6e-63ebe5f79b4c',
        'title': 'Metalwork Defect',
        'fields': '{"tags":[],"stampAbbr":"MT","visibility":1}',
        'created': '2018-12-17 01:52:03',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '8fd40a64-aa9e-487c-9774-4b96a263e59b',
        'title': 'Nursecall / Access control',
        'fields': '{"tags":[],"stampAbbr":"NC","visibility":1}',
        'created': '2018-12-17 01:50:45',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '3cf6dfae-d120-4c93-9404-fb9ac11f3960',
        'title': 'Photo_Pre Existing',
        'fields': '{"stampAbbr":"PHE"}',
        'created': '2022-11-08 01:46:03',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '79cb32a5-2cac-434d-9ef0-8ff79b17770a',
        'nodeRole': 1,
    },
    {
        'uuid': '44c3044b-2898-4fd4-b2d9-45165ecad89d',
        'title': 'Photo_Progress',
        'fields': '{"stampAbbr":"PHP"}',
        'created': '2022-11-08 01:46:18',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '79cb32a5-2cac-434d-9ef0-8ff79b17770a',
        'nodeRole': 1,
    },
    {
        'uuid': '1a0117ae-a485-4f58-9ecf-43b95e4e777c',
        'title': 'Patient Lifters Defect',
        'fields': '{"tags":[],"stampAbbr":"PL","visibility":1}',
        'created': '2018-12-17 01:52:25',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'f6c554fc-7d82-4253-ace1-46196673ee5e',
        'title': 'Precast Defect',
        'fields': '{"tags":[],"stampAbbr":"PR","visibility":1}',
        'created': '2018-12-17 01:43:32',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '196a7e24-9382-4e1b-9fda-e3ec088dabbf',
        'title': 'Painting Defect',
        'fields': '{"tags":[],"stampAbbr":"PT"}',
        'created': '2018-12-18 23:14:44',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '49f28940-38ff-42db-9b25-e7d17a60e7c3',
        'title': 'Ordered',
        'fields': '{"stampAbbr":"PT1"}',
        'created': '2021-02-02 05:43:45',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '7fb9f627-9bdc-4fab-86a3-0261158cb57e',
        'nodeRole': 1,
    },
    {
        'uuid': 'e582ccdf-a3af-4dcd-b90a-c02a2c199cb5',
        'title': 'Delivered',
        'fields': '{"stampAbbr":"PT2"}',
        'created': '2021-02-02 05:44:00',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '7fb9f627-9bdc-4fab-86a3-0261158cb57e',
        'nodeRole': 1,
    },
    {
        'uuid': '77e26d54-baab-49d7-bea5-f3469865cc92',
        'title': 'Installed',
        'fields': '{"stampAbbr":"PT3"}',
        'created': '2021-02-02 05:44:09',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '7fb9f627-9bdc-4fab-86a3-0261158cb57e',
        'nodeRole': 1,
    },
    {
        'uuid': 'ad95fded-f768-4a08-86c1-1c4172b8bed0',
        'title': 'Paving Defect',
        'fields': '{"tags":[],"stampAbbr":"PV","visibility":1}',
        'created': '2018-12-17 01:58:00',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '5304861e-9f28-43f1-b1f1-daae5fa3ae75',
        'title': 'QA Issue',
        'fields': '{"stampAbbr":"QA1"}',
        'created': '2021-02-02 05:41:45',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf3a9a47-3476-45d1-8e9f-4e65b819087b',
        'nodeRole': 1,
    },
    {
        'uuid': '57665879-fb9c-4613-85b7-5e0f80dbea2e',
        'title': 'QA Architectural Site Inspection',
        'fields': '{"stampAbbr":"QA2"}',
        'created': '2022-11-08 03:49:39',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf3a9a47-3476-45d1-8e9f-4e65b819087b',
        'nodeRole': 1,
    },
    {
        'uuid': 'fb2686be-6d8c-46ea-a58a-9ad46360d33f',
        'title': 'QA Structural Site Inspection',
        'fields': '{"stampAbbr":"QA3"}',
        'created': '2022-11-08 03:50:02',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'cf3a9a47-3476-45d1-8e9f-4e65b819087b',
        'nodeRole': 1,
    },
    {
        'uuid': '03047222-0a0f-4b11-baaa-88d8ea98e976',
        'title': 'Roofing Defect',
        'fields': '{"tags":[],"stampAbbr":"RF","visibility":1}',
        'created': '2018-12-17 01:44:53',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '6c0606c4-fb3a-48d1-bf0b-1ae92c4a293a',
        'title': 'Roof Access / Plant Platforms',
        'fields': '{"tags":[],"stampAbbr":"RFA","visibility":1}',
        'created': '2018-12-17 01:48:53',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'f1b3cde9-c107-4d56-b347-0c38db72d6f3',
        'title': 'Retaining Wall Defect',
        'fields': '{"tags":[],"stampAbbr":"RT","visibility":1}',
        'created': '2018-12-17 02:01:56',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'c6184e3f-9bb7-44bb-9812-39bba8df7605',
        'title': 'SD_Design Issue',
        'fields': '{"stampAbbr":"SD1"}',
        'created': '2021-01-20 00:51:25',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c3eb1c99-bee3-48f9-a7b8-7e37a5d8cfdc',
        'nodeRole': 1,
    },
    {
        'uuid': 'ecff9afd-a463-4f1b-88e3-8cb3b97279a7',
        'title': 'SD_Hazard',
        'fields': '{"stampAbbr":"SD2"}',
        'created': '2022-11-08 01:41:47',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c3eb1c99-bee3-48f9-a7b8-7e37a5d8cfdc',
        'nodeRole': 1,
    },
    {
        'uuid': '7f914670-6bae-4603-89e1-635eb5999dfa',
        'title': 'SD_Housekeeping',
        'fields': '{"stampAbbr":"SD3"}',
        'created': '2022-11-08 01:42:12',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c3eb1c99-bee3-48f9-a7b8-7e37a5d8cfdc',
        'nodeRole': 1,
    },
    {
        'uuid': '61b94e30-2701-4ab2-a1db-53bb62e2454f',
        'title': 'SD_Permits Required',
        'fields': '{"stampAbbr":"SD4"}',
        'created': '2022-11-08 01:42:31',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c3eb1c99-bee3-48f9-a7b8-7e37a5d8cfdc',
        'nodeRole': 1,
    },
    {
        'uuid': '1f584124-7cbe-47e7-8401-a7a829ecd5ed',
        'title': 'SD_Public Safety Issue',
        'fields': '{"stampAbbr":"SD5"}',
        'created': '2022-11-08 01:42:58',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c3eb1c99-bee3-48f9-a7b8-7e37a5d8cfdc',
        'nodeRole': 1,
    },
    {
        'uuid': '46fa837e-88f7-49fb-81a4-7b6aa6dc4198',
        'title': 'SD_Safety Inspection Required',
        'fields': '{"stampAbbr":"SD6"}',
        'created': '2022-11-08 01:43:20',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c3eb1c99-bee3-48f9-a7b8-7e37a5d8cfdc',
        'nodeRole': 1,
    },
    {
        'uuid': '7a7b341e-c1ec-4bcc-9bdc-2955ecc5dbfa',
        'title': 'Security Defect',
        'fields': '{"tags":[],"stampAbbr":"SE","visibility":1}',
        'created': '2018-12-17 01:50:03',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '2c17a68c-70ea-4888-a371-3a1f61e7294f',
        'title': 'Structural Steel Defect',
        'fields': '{"tags":[],"stampAbbr":"ST","visibility":1}',
        'created': '2018-12-17 01:38:57',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '2edc00a0-d3c2-4488-98ca-2a9c46531546',
        'title': 'Sustainability Issue',
        'fields': '{"stampAbbr":"SU1"}',
        'created': '2021-01-20 00:52:44',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '8f943faa-54c5-4b90-b7ba-300f4813f555',
        'nodeRole': 1,
    },
    {
        'uuid': 'd79dccc0-b90e-4779-8bd0-f7e8e8729a1b',
        'title': 'As Built Modelling Scan Area',
        'fields': '{"stampAbbr":"SU2"}',
        'created': '2022-08-03 05:26:18',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '790cefbd-af7b-4c40-b954-529d3e5d29ad',
        'nodeRole': 1,
    },
    {
        'uuid': 'eaea01ab-2cf3-4698-9d22-0850f6a325d1',
        'title': 'Missing Elements To Be Modelled',
        'fields': '{"stampAbbr":"SU3"}',
        'created': '2022-09-19 23:58:24',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '790cefbd-af7b-4c40-b954-529d3e5d29ad',
        'nodeRole': 1,
    },
    {
        'uuid': '12f6a5f7-7d9a-452a-a92e-ee6811c51dd5',
        'title': 'Element Should Be Demolished In Revit Phasing',
        'fields': '{"stampAbbr":"SU4"}',
        'created': '2022-09-19 23:59:17',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '790cefbd-af7b-4c40-b954-529d3e5d29ad',
        'nodeRole': 1,
    },
    {
        'uuid': '3a82093f-717c-46e0-b969-47917362eb7f',
        'title': 'Element Not Aligned With Point Cloud',
        'fields': '{"stampAbbr":"SU5"}',
        'created': '2022-09-19 23:59:44',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '790cefbd-af7b-4c40-b954-529d3e5d29ad',
        'nodeRole': 1,
    },
    {
        'uuid': '2632d41d-6c12-4546-b9d0-dd2bfcdb7fed',
        'title': 'Element Workset Needs To Be Changed',
        'fields': '{"stampAbbr":"SU6"}',
        'created': '2022-09-19 23:59:55',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '790cefbd-af7b-4c40-b954-529d3e5d29ad',
        'nodeRole': 1,
    },
    {
        'uuid': '621f9a1f-73c5-462e-b302-66e17f4ca321',
        'title': 'Element Should Be Deleted',
        'fields': '{"stampAbbr":"SU7"}',
        'created': '2022-09-20 00:00:05',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '790cefbd-af7b-4c40-b954-529d3e5d29ad',
        'nodeRole': 1,
    },
    {
        'uuid': '7bda9654-6b3e-4822-b3b1-12a073a6bbc4',
        'title': 'Markup From Architect',
        'fields': '{"stampAbbr":"SU8"}',
        'created': '2022-09-20 00:00:19',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '790cefbd-af7b-4c40-b954-529d3e5d29ad',
        'nodeRole': 1,
    },
    {
        'uuid': 'eb6d5d1b-52fa-4493-b062-6b6023d6b8d6',
        'title': 'Tender Estimator',
        'fields': '{"stampAbbr":"TES"}',
        'created': '2022-11-08 05:33:08',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '96dce703-3fac-4f70-97e4-38ad06f8d748',
        'nodeRole': 1,
    },
    {
        'uuid': 'bab97e93-b78f-49a0-a036-a46ab54d5be2',
        'title': 'Tiling Defect',
        'fields': '{"tags":[],"stampAbbr":"TL","visibility":1}',
        'created': '2019-01-21 01:57:41',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '431391fc-876a-4171-b9d8-c0f0d7259d6b',
        'title': 'Toilet Partitions Defect',
        'fields': '{"tags":[],"stampAbbr":"TP","visibility":1}',
        'created': '2018-12-17 01:53:01',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': '2350d35f-0869-415b-b482-7c6bc5fef465',
        'title': 'Tender Scope Gap',
        'fields': '{"stampAbbr":"TSG"}',
        'created': '2021-01-20 00:29:39',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': '96dce703-3fac-4f70-97e4-38ad06f8d748',
        'nodeRole': 1,
    },
    {
        'uuid': '7dee7c0e-2371-45eb-82f9-db60deb4ed8d',
        'title': 'Timber Windows/Doors Defect',
        'fields': '{"tags":[],"stampAbbr":"TW","visibility":1}',
        'created': '2018-12-17 01:54:53',
        'updated': '2023-08-09 10:54:46',
        'parentUuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'nodeRole': 1,
    },
    {
        'uuid': 'de7a0e2b-bab7-4ca8-b915-7436db702fe2',
        'title': '00_Concept Stage',
        'fields': '{"stampColor":2}',
        'created': '2022-11-03 23:05:06',
        'updated': '2022-11-04 03:24:11',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'bf5dd877-377d-47a5-b041-c70c7b98b43d',
        'title': '00_Info Request',
        'fields': '[]',
        'created': '2023-03-01 02:46:54',
        'updated': '2023-03-01 02:46:54',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '96dce703-3fac-4f70-97e4-38ad06f8d748',
        'title': '00_Tender',
        'fields': '{"priority":"minor","visibility":1}',
        'created': '2021-01-20 00:29:15',
        'updated': '2022-11-08 03:53:12',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'cf411f02-16d4-432d-ad6a-c3888e5ad600',
        'title': '01*_Design',
        'fields': '{"stampColor":7}',
        'created': '2022-06-22 00:27:29',
        'updated': '2023-03-14 22:39:23',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '59eed0b6-e404-47e9-93cd-bf6eee51346a',
        'title': '02*_Clashes - Design - Auto',
        'fields': '{"stampColor":8,"visibility":1}',
        'created': '2021-01-20 00:30:23',
        'updated': '2023-03-14 22:45:00',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'c00b8d24-7404-44d6-ba4b-7a847db95dc2',
        'title': '03_Trade',
        'fields': '{"stampColor":5}',
        'created': '2022-06-27 22:06:05',
        'updated': '2023-03-14 22:45:11',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '6b4458e0-bf71-46c9-8256-aa02ebc4ed22',
        'title': '04_Clashes - Trade  - Auto',
        'fields': '{"stampColor":4}',
        'created': '2022-06-14 00:27:34',
        'updated': '2023-03-14 22:45:21',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'cf765432-3b63-417c-b0bf-08191d0c5aae',
        'title': '05_2D Drawing Approval',
        'fields': '[]',
        'created': '2022-11-03 23:26:00',
        'updated': '2023-03-14 22:46:29',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'b2275a9a-8732-4fcc-abd2-3d90b453dafd',
        'title': '06_2D Markup',
        'fields': '{"stampColor":1}',
        'created': '2022-06-27 22:06:43',
        'updated': '2023-03-14 22:46:42',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '79cb32a5-2cac-434d-9ef0-8ff79b17770a',
        'title': '10_Photos',
        'fields': '{"stampColor":9}',
        'created': '2022-11-08 01:44:38',
        'updated': '2023-03-14 22:48:00',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '36d7f210-527f-47c1-b3bb-6c4a70e44f4c',
        'title': '11_Buildability',
        'fields': '{"stampColor":8,"visibility":1}',
        'created': '2021-01-20 00:30:23',
        'updated': '2023-02-20 23:49:39',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'c3eb1c99-bee3-48f9-a7b8-7e37a5d8cfdc',
        'title': '12_Safety in Design',
        'fields': '{"stampColor":7,"visibility":1}',
        'created': '2021-01-20 00:49:58',
        'updated': '2022-11-03 23:24:55',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '8f943faa-54c5-4b90-b7ba-300f4813f555',
        'title': '13_Sustainability',
        'fields': '{"stampColor":5,"visibility":1}',
        'created': '2021-01-20 00:50:05',
        'updated': '2022-11-03 23:24:43',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '76e74bcf-61ec-48e6-bd71-43c6546c6d6d',
        'title': '14_Environmental',
        'fields': '{"stampColor":4,"visibility":1}',
        'created': '2021-01-20 00:50:13',
        'updated': '2022-11-03 23:24:38',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'cf3a9a47-3476-45d1-8e9f-4e65b819087b',
        'title': '15_QA Quality Assurance',
        'fields': '{"stampColor":9,"visibility":1}',
        'created': '2021-01-20 00:50:21',
        'updated': '2022-11-03 23:24:32',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '7fb9f627-9bdc-4fab-86a3-0261158cb57e',
        'title': '16_Procurement and Tracking',
        'fields': '{"stampColor":8,"visibility":1}',
        'created': '2021-01-20 00:50:29',
        'updated': '2023-02-20 23:49:49',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'c9c1ddba-12b0-4982-89b6-25fc9b1d073a',
        'title': '20_Defect',
        'fields': '{"stampColor":9}',
        'created': '2018-12-14 00:46:00',
        'updated': '2022-11-04 03:26:52',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '790cefbd-af7b-4c40-b954-529d3e5d29ad',
        'title': '30_Survey and Asbuilt',
        'fields': '{"stampColor":1,"visibility":0}',
        'created': '2022-08-03 05:25:40',
        'updated': '2023-02-20 23:49:54',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '8e0ed9d0-d014-420b-987b-ffa7d918de10',
        'title': '80_FM',
        'fields': '{"stampColor":5}',
        'created': '2022-11-04 03:25:30',
        'updated': '2022-11-08 03:59:17',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'deee3809-6d1a-4618-9d6d-448798361885',
        'title': '98_Company (Public NO)',
        'fields': '{"stampColor":2,"visibility":0}',
        'created': '2022-06-14 00:40:56',
        'updated': '2022-11-08 05:29:42',
        'parentUuid': null,
        'nodeRole': 2,
    },
];
