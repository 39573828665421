import { FieldVariants } from '@/models';

export class LicenseFieldVariants extends FieldVariants {
    public readonly projects: Array<{
        id: number;
        title: string;
        uuid: string;
    }>;

    public readonly projectTags: string[];

    constructor(fieldVariants: any = {}) {
        super(fieldVariants);
        this.projects = fieldVariants.projects;
        this.projectTags = fieldVariants.projectTags || [];
    }
}
