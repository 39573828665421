
























































































































































import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { Dict } from '@/types/Dict';
import Router from '@/router';
import {
    allIssuesPresetUuid,
    AmplitudeEvent,
    BrokenPresetEnum,
    BusEvent,
    Color,
    currentIssuesPresetUuid,
    deletedIssuesPresetUuid,
    DRAFT_KEY,
    IssuesFilterType,
    PresetAction,
} from '@/constants';
import { CustomStatus, Issue, IssueFilterPreset, Project } from '@/models';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, getEmptyFilters, isOverflownElementVertically } from '@/services';
import { IssueTrackerFilterValue } from '@/services/issueTracker/IssueTrackerFilterValue';
import { IIssueFilterPresetUnreadStat } from '@/storage/projectIssueFilterPreset.storage';
import { filterIssues } from '@/storage/projectIssues.storage';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
        Draggable,
        IconSvg24,
        WsCheckbox,
    },
})
export default class PresetList extends Vue {
    @Prop() public presetAction!: string;

    public readonly Color = Color;
    public readonly PresetAction = PresetAction;
    public readonly BrokenPresetEnum = BrokenPresetEnum;
    public resizeObserver = new ResizeObserver(() => 1);
    public presetList!: HTMLElement;
    public width: number = 0;
    public hasNewPresetEvent = false;
    public isTitleTruncatedArr: boolean[] = [];

    public isCheckedPresetObj: any = {};
    public issueFilterPresetsLocal: IssueFilterPreset[] = [];

    public draggedIndex = -1;
    public updatedTime = 0;

    get isDraftSelected() {
        return this.$route.query.filter === DRAFT_KEY;
    }
    get projectId(): number {
        return Number(this.$route.params.projectId);
    }
    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }
    get isAdminRights() {
        return this.project.isAdminRights;
    }
    get isLoadingIssueFilterPresets(): boolean {
        return this.$store.getters.isLoadingIssueFilterPresets(this.projectId);
    }
    get allProjectStatuses(): CustomStatus[] {
        return this.$store.getters.customStatusesByProjectUuid(this.project?.uuid);
    }
    get issueFilterPresets() {
        const issueFilterPresets: IssueFilterPreset[] = this.$store.getters.issueFilterPresetsByProjectId(this.projectId);
        return this.isAdminRights
            ? issueFilterPresets
            : issueFilterPresets.filter(({ uuid }) => uuid !== deletedIssuesPresetUuid);
    }

    get regularIssueFilterPresets() {
        return this.issueFilterPresets.filter(this.isPresetRegular);
    }
    get selectedIssueFilterPreset(): IssueFilterPreset {
        return this.$store.getters.selectedIssueFilterPresetByProjectId(this.projectId);
    }
    get presetStats(): Dict<IIssueFilterPresetUnreadStat> {
        return this.$store.getters.issueFilterPresetsUnreadByProjectId(this.projectId);
    }
    get allUnreadIssues() {
        return this.presetStats[allIssuesPresetUuid] ? this.presetStats[allIssuesPresetUuid].unread : 0;
    }
    get presetsNotifications() {
        return this.issueFilterPresetsLocal.reduce((presetsNotifications, preset)=> {
            const presetStats = this.presetStats[preset.uuid];

            if (!presetStats) {
                presetsNotifications[preset.uuid] = {
                    hasUnread: false,
                    hasDeletedStatus: false,
                };
                return presetsNotifications;
            }

            let hasUnread = false;
            if (presetStats.unread && presetStats.unread > 0) {
                hasUnread = true;
            } else {
                const filteredIssues = filterIssues(this.allProjectIssues, preset.trackerFilters);
                hasUnread = filteredIssues.some((issue: Issue) => issue.read.issue || (issue.read.comments > 0));
            }

            let hasDeletedStatus = false;
            const statusFilter = preset.trackerFilters[IssuesFilterType.customStatus] as IssueTrackerFilterValue;
            if (presetStats.total === 0 && statusFilter) {
                for (const statusName of statusFilter.selections as string[]) {
                    if (this.customStatusesByName[statusName]?.isDeleted) {
                        hasDeletedStatus = true;
                        break;
                    }
                }
            }

            presetsNotifications[preset.uuid] = {
                hasUnread,
                hasDeletedStatus,
            };
            return presetsNotifications;
        }, {} as Dict<{ hasUnread: boolean; hasDeletedStatus: boolean }>);
    }
    get allProjectIssues() {
        return this.$store.getters.allIssuesByProjectId(this.projectId);
    }
    get draftCount() {
        let result: number = this.$store.getters.draftIssues(this.projectId).length;
        const allIssuesIds = this.$store.getters.allIssuesOrderIds(this.projectId);
        this.$store.getters.draftIssues(this.projectId).forEach((id: number) => {
            if (!allIssuesIds.includes(Number(id))) {
                result--;
            }
        });

        return result;
    }

    get userData() {
        return this.$store.getters.userData;
    }

    get userDataProjectMember() {
        return this.$store.getters.projectMemberByEmail(this.userData.email, this.projectId);
    }

    get isAbleToChangeVisibility() {
        return Boolean(this.userDataProjectMember?.accessRole?.permissions.manage_issue_filter_set);
    }

    get customStatusesByName() {
        return _.keyBy(this.$store.getters.customStatusesByProjectUuid(this.project.uuid, false) as CustomStatus[], 'name');
    }

    @Watch('width', { immediate: true })
    public widthChanged() {
        this.calculateTitleTruncated();
    }

    @Watch('presetAction', { immediate: true })
    public onPresetAction(presetAction: string) {
        if (presetAction === PresetAction.share) {
            this.regularIssueFilterPresets.forEach((preset) => {
                this.isCheckedPresetObj[preset.uuid] = Boolean(preset.visibility);
            });
        } else if (presetAction === PresetAction.delete) {
            this.isCheckedPresetObj = {};
        }
    }

    @Watch('issueFilterPresets', { immediate: true })
    public async onPresetsChange(newPresetsState: IssueFilterPreset[], oldPresetsState: IssueFilterPreset[]) {
        this.issueFilterPresetsLocal = _.cloneDeep(newPresetsState);

        const newPresets = _.differenceBy(newPresetsState, oldPresetsState, 'uuid');

        if (newPresets.length && this.hasNewPresetEvent) {
            this.hasNewPresetEvent = false;
            this.$nextTick(() => {
                this.highlightNewPreset(newPresets[0].uuid);
            });
        }

        const leftPresets = _.differenceBy(oldPresetsState, newPresetsState, 'uuid');
        if (!leftPresets.length) {
            return;
        }

        // If selected preset was deleted
        const currentPresetLeft = leftPresets.find((preset) => this.selectedIssueFilterPreset?.uuid === preset.uuid);
        if (!currentPresetLeft) {
            return;
        }

        const foundSamePreset = this.$store.getters.findPresetByFilters(this.projectId, this.selectedIssueFilterPreset.trackerFilters);
        if (foundSamePreset) {
            this.selectFilterPreset(foundSamePreset);
            return;
        }

        // We should select first preset if selected preset was deleted and set url "filter" param for current filters
        const trackerFilters = this.selectedIssueFilterPreset.trackerFilters;
        const trackerSorting = this.$store.getters.selectedIssueFilterPresetByProjectId(this.projectId)?.sorting
            || this.$store.getters.issuesSortByProjectId(this.projectId);

        const activeFilters = _.sortBy(_.values(trackerFilters).filter(({ isActive }) => isActive), 'type');
        const payload = {
            projectId: this.projectId,
            filters: JSON.stringify(activeFilters),
            sorting: trackerSorting ? JSON.stringify(trackerSorting) : undefined,
        };
        const response = await this.$store.dispatch('sendFiltersForHash', payload);

        if (response.hash !== this.$route.query.filter) {
            this.$router.push({ query: { filter: response.hash } });
        }

        this.$store.commit('setSelectedIssueFilterPreset', {
            projectId: this.projectId,
            issueFilterPreset: this.issueFilterPresets[0],
        });
    }

    @Watch('issueFilterPresetsLocal')
    public onReorderPresets(issueFilterPresetsLocal: IssueFilterPreset[]) {
        if (!_.isEqual(issueFilterPresetsLocal, this.issueFilterPresets)) {
            const orderArr = issueFilterPresetsLocal
                .filter(this.isPresetRegular)
                .map((preset, index) => ({ guid: preset.uuid, Order: index }));

            this.order(orderArr);
        }
    }

    @Emit()
    public checkedPresets(isCheckedObj: any) {
        return isCheckedObj;
    }

    @Emit()
    public order(orderArr: Array<{ guid: string, Order: number }>) {
        return orderArr;
    }

    public mounted() {
        this.presetList = document.querySelector('.preset-list') as HTMLElement;
        this.width = this.presetList.clientWidth;
        const resizeCallback = _.debounce(() => this.width = this.presetList.clientWidth, 200);
        this.resizeObserver = new ResizeObserver(resizeCallback);
        this.resizeObserver.observe(this.presetList);
        eventBus.$on(BusEvent.newPresetCreated, this.onNewPresetCreated);
    }

    public updated() {
        const currentTime = new Date().getTime();
        if (currentTime - this.updatedTime > 200) {
            this.updatedTime = currentTime;
            this.calculateTitleTruncated();
        }
    }

    public beforeDestroy() {
        this.resizeObserver.unobserve(this.presetList);
        eventBus.$off(BusEvent.newPresetCreated, this.onNewPresetCreated);
    }

    public calculateTitleTruncated() {
        const filterPresetTitles: HTMLElement[] = Array.from(document.querySelectorAll('.filter-preset-title'));
        this.isTitleTruncatedArr = filterPresetTitles.map(isOverflownElementVertically); // https://stackoverflow.com/a/68130373/7730888
    }

    public onDragStart({ oldIndex }: any) {
        this.draggedIndex = oldIndex;
    }

    public onDragEnd() {
        this.draggedIndex = -1;
    }

    // https://stackoverflow.com/a/68442012/7730888
    public checkMove({ draggedContext }: any) {
        const index = draggedContext.futureIndex;
        const preset = this.issueFilterPresetsLocal[index];
        return this.isPresetRegular(preset);
    }

    public isPresetRegular(issueFilterPreset: IssueFilterPreset) {
        return ![allIssuesPresetUuid, currentIssuesPresetUuid, deletedIssuesPresetUuid].includes(issueFilterPreset.uuid);
    }

    public isPresetDeleted(index: number) {
        return this.isAdminRights && index === this.issueFilterPresets.length - 1 && !this.isDraftSelected;
    }

    public isPresetDraggable(issueFilterPreset: IssueFilterPreset) {
        return this.presetAction === PresetAction.reorder && this.isPresetRegular(issueFilterPreset);
    }

    public isPresetWithCheckbox(issueFilterPreset: IssueFilterPreset) {
        return [PresetAction.share, PresetAction.delete].includes(this.presetAction)
            && this.isPresetRegular(issueFilterPreset);
    }

    public presetCheckbox(issueFilterPreset: IssueFilterPreset) {
        let isDisabled;
        let tooltip;
        if (this.isAbleToChangeVisibility || !issueFilterPreset.visibility) {
            isDisabled = false;
        } else if (!this.userDataProjectMember) {
            isDisabled = true;
            tooltip = this.presetAction === PresetAction.share
                ? this.$t('IssueTracker.presets.onlyMembersUnshare')
                : this.$t('IssueTracker.presets.onlyMembersDelete');
        } else if (!this.isAbleToChangeVisibility) {
            isDisabled = true;
            tooltip = this.presetAction === PresetAction.share
                ? this.$t('IssueTracker.presets.dontHavePermissionToUnshare')
                : this.$t('IssueTracker.presets.dontHavePermissionToDelete');
        }
        return { isDisabled, tooltip };
    }

    public clickCheck(issueFilterPreset: IssueFilterPreset) {
        this.isCheckedPresetObj = {
            ...this.isCheckedPresetObj,
            [issueFilterPreset.uuid]: !this.isCheckedPresetObj[issueFilterPreset.uuid],
        };
        this.checkedPresets(this.isCheckedPresetObj);
    }

    public isSelected(issueFilterPreset: IssueFilterPreset) {
        return !this.isDraftSelected && issueFilterPreset.uuid === this.selectedIssueFilterPreset.uuid;
    }
    public isAllowShowUnreadMarker(issueFilterPreset: IssueFilterPreset) {
        if (deletedIssuesPresetUuid === issueFilterPreset.uuid) {
            return false;
        }
        if (allIssuesPresetUuid === issueFilterPreset.uuid) {
            return !issueFilterPreset.isAllowShowCounts;
        }

        return true;
    }

    public onClickPresetItem(event: MouseEvent, issueFilterPreset: IssueFilterPreset) {
        if (event.ctrlKey || event.metaKey) {
            return event;
        } else {
            event.preventDefault();
            event.stopPropagation();
        }

        this.selectFilterPreset(issueFilterPreset);
    }

    public selectFilterPreset(issueFilterPreset: IssueFilterPreset) {
        amplitudeLog(AmplitudeEvent.itPresetSelected, { presetTitle: issueFilterPreset.title });

        if (this.$route.query?.filter === DRAFT_KEY) {
            this.$router.push({ query: undefined });
        }

        this.$store.commit('setIssueTrackerLink', {});
        this.$store.commit('cleanMultiEditSelectedIssues');
        this.$store.commit('setCurrentPage', { projectId: this.projectId, page: 1 });
        this.$store.commit('setSelectedIssue', { projectId: this.projectId, issue: null });
        this.$store.commit('setSelectedIssueFilterPreset', {
            projectId: this.projectId,
            issueFilterPreset: new IssueFilterPreset(issueFilterPreset),
        });
        this.$store.dispatch('updatePresetFilters', {
            projectId: this.projectId,
            presetFilters: issueFilterPreset.trackerFilters,
        });
    }

    public showDrafts() {
        this.$store.commit('setIssueTrackerLink', {});
        this.$store.commit('cleanMultiEditSelectedIssues');
        this.$store.commit('clearSelectedIssueFilterPreset', { projectId: this.projectId });
        this.$store.commit('setTrackerFilters', { projectId: this.projectId, filters: getEmptyFilters() });
        this.$store.commit('setCurrentPage', { projectId: this.projectId, page: 1 });
        if (Router.currentRoute.query?.filter !== DRAFT_KEY) {
            this.$router.replace({ query: { filter: DRAFT_KEY } });
        }
        this.$store.dispatch('loadIssuesOrderByProjectId', { projectId: this.projectId }).then(() => {
            this.$store.dispatch('loadIssuesByProjectId', { projectId: this.projectId });
        });
    }

    private onNewPresetCreated(newPreset: IssueFilterPreset) {
        this.hasNewPresetEvent = true;

        this.$store.commit('setSelectedIssueFilterPreset', {
            projectId: this.projectId,
            issueFilterPreset: newPreset,
        });

        if (this.$route.query.preset === newPreset.uuid) {
            return;
        }

        this.$router.replace({ query: { preset: newPreset.uuid } });
    }

    public getFilterPresetIdByUuid(uuid: string) {
        return `filter-preset-${uuid}`;
    }

    public getResolvedUrlForPreset(preset: IssueFilterPreset) {
        return this.$router.resolve({ query: { preset: preset.uuid } })?.href || '';
    }

    public highlightNewPreset(presetUuid: string) {
        const presetElement = document.getElementById(this.getFilterPresetIdByUuid(presetUuid));

        if (!presetElement || !this.$refs.presetList) {
            return;
        }

        const presetList = this.$refs.presetList as HTMLElement;
        if (presetList.scrollHeight > presetList.clientHeight) {
            presetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        setTimeout(() => presetElement?.classList.add('highlight-animation'), 300);
        setTimeout(() => presetElement?.classList.remove('highlight-animation'), 2400);
    }
}
