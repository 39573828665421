import _ from 'lodash';

export function isArrayTrivial(arr: any[], comparator: (a: any, b: any) => boolean = _.isEqual) {
    return arr.every((el) => comparator(el, arr[0]));
}

export function invertArrayOfStrings(arr: string[]) {
    return _
        .chain(arr)
        .invert()
        .mapValues(Number)
        .value();
}
