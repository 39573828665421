export interface IBuild {
    id: number;
    name: string;
    changelog: string;
    platforms: any;
    branches: any;
    regions: any;
    active: boolean | number;
    dateCreate: string;
    properties: string;
    patches: string;
    version: string;
    stable: boolean | number;
    extendedVersion: string;
    downloadLink: string;
}

export class Build implements IBuild {
    public id: number;
    public name: string;
    public changelog: string;
    public platforms: any;
    public branches: any;
    public regions: any;
    public active: boolean;
    public dateCreate: string;
    public properties: string;
    public patches: string;
    public version: string;
    public stable: boolean;
    public extendedVersion: string;
    public downloadLink: string;

    constructor(build: IBuild | any = {}) {
        this.id = build.id || 0;
        this.name = build.name || '';
        this.changelog = build.changelog || '';
        this.platforms = build.platforms || [];
        this.branches = build.branches || [];
        this.regions = build.regions || [];
        this.active = !!build.active;
        this.dateCreate = build.dateCreate || '';
        this.properties = build.properties || '';
        this.patches = build.patches || '';
        this.version = build.version || '';
        this.stable = !!build.stable;
        this.extendedVersion = build.extendedVersion || '';
        this.downloadLink = build.downloadLink || '';
    }
}
