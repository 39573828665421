import _ from 'lodash';
import Vue from 'vue';
import LicenseApi from '@/api/license.api';
import { Project } from '@/models';
import { notificationSuccess } from '@/services';

export default {
    state: {
        licenseProjectsObj: {},
        licenseProjectsFiltersParamsObj: {},
        isLoadingLicenseProjectsObj: {},
        isSendingLicenseProjectsActivity: false,
        isSendingGroupDeleteLicenseProjects: false,
        isSendingGroupArchiveLicenseProjects: false,
        isSendingGroupRestoreLicenseProjects: false,
        isSendingGroupEditLicenseProjectTags: false,
    },
    getters: {
        licenseProjectsByLicenseId(state: any): (licenseId: number) => Project[] {
            return (licenseId) => state.licenseProjectsObj[licenseId] || [];
        },
        licenseProjectsById(state: any) {
            return (licenseId: number) => {
                const projects = state.licenseProjectsObj[licenseId];
                return _.keyBy(projects, 'id');
            };
        },
        licenseProjectsFiltersParamsByLicenseId(state: any): (licenseId: number) => any {
            return (licenseId) => state.licenseProjectsFiltersParamsObj[licenseId] || {};
        },
        isLoadingLicenseProjectsByLicenseId(state: any): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingLicenseProjectsObj[licenseId] || false;
        },
        isSendingLicenseProjectsActivity(state: any): boolean {
            return state.isSendingLicenseProjectsActivity;
        },
        isSendingGroupDeleteLicenseProjects(state: any): boolean {
            return state.isSendingGroupDeleteLicenseProjects;
        },
        isSendingGroupArchiveLicenseProjects(state: any): boolean {
            return state.isSendingGroupArchiveLicenseProjects;
        },
        isSendingGroupRestoreLicenseProjects(state: any): boolean {
            return state.isSendingGroupRestoreLicenseProjects;
        },
        isSendingGroupEditLicenseProjectTags(state: any): boolean {
            return state.isSendingGroupEditLicenseProjectTags;
        },
    },
    mutations: {
        setLicenseProjects(state: any, { licenseId, projects }: { licenseId: number, projects: Project[] }) {
            Vue.set(state.licenseProjectsObj, licenseId, projects);
        },
        setLicenseProjectsFiltersParams(state: any, { licenseId, filtersParams }: { licenseId: number; filtersParams: any }) {
            Vue.set(state.licenseProjectsFiltersParamsObj, licenseId, filtersParams);
        },
        setIsLoadingLicenseProjects(state: any, { licenseId, value }: { licenseId: number; value: boolean }) {
            Vue.set(state.isLoadingLicenseProjectsObj, licenseId, value);
        },
        setIsSendingLicenseProjectsActivity(state: any, value: boolean) {
            state.isSendingLicenseProjectsActivity = value;
        },
        setIsSendingGroupDeleteLicenseProjects(state: any, value: boolean) {
            state.isSendingGroupDeleteLicenseProjects = value;
        },
        setIsSendingGroupArchiveLicenseProjects(state: any, value: boolean) {
            state.isSendingGroupArchiveLicenseProjects = value;
        },
        setIsSendingGroupRestoreLicenseProjects(state: any, value: boolean) {
            state.isSendingGroupRestoreLicenseProjects = value;
        },
        setIsSendingGroupEditLicenseProjectTags(state: any, value: boolean) {
            state.isSendingGroupEditLicenseProjectTags = value;
        },
    },
    actions: {
        loadLicenseProjects({ state, commit }: any, { licenseId, params, isForce = false }: { licenseId: number, params?: any, isForce: boolean }): Promise<any> {
            return new Promise((resolve, reject) => {
                if (!isForce && state.licenseProjectsObj[licenseId]) {
                    resolve(state.licenseProjectsObj[licenseId]);
                    return;
                }
                if (state.isLoadingLicenseProjectsObj[licenseId]) {
                    resolve(state.licenseProjectsObj[licenseId]);
                    return;
                }

                commit('setLicenseProjects', { licenseId, projects: [] });
                commit('setIsLoadingLicenseProjects', { licenseId, value: true });
                LicenseApi.getLicenseProjects(licenseId, params).then((response) => {
                    const projects = response.entities.map((project: any) => new Project(project));
                    commit('setLicenseProjects', { licenseId, projects });
                    resolve(projects);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingLicenseProjects', { licenseId, value: false });
                });
            });
        },
        sendLicenseProjectsActivityToEmail({ commit }: any, { licenseId, params }: { licenseId: number, params: any }) {
            commit('setIsSendingLicenseProjectsActivity', true);
            return LicenseApi.postExportLicenseProjectsActivity(licenseId, params).then(() => {
                notificationSuccess('activitySent');
            }).finally(() => {
                commit('setIsSendingLicenseProjectsActivity', false);
            });
        },
        groupDeleteLicenseProjects({ commit }: any, params: any) {
            commit('setIsSendingGroupDeleteLicenseProjects', true);
            return LicenseApi.postGroupDeleteLicenseProjects(params)
                .finally(() => {
                    commit('setIsSendingGroupDeleteLicenseProjects', false);
                });
        },
        groupArchiveLicenseProjects({ commit }: any, params: any) {
            commit('setIsSendingGroupArchiveLicenseProjects', true);
            return LicenseApi.postGroupArchiveLicenseProjects(params)
                .finally(() => {
                    commit('setIsSendingGroupArchiveLicenseProjects', false);
                });
        },
        groupRestoreLicenseProjects({ commit }: any, params: any) {
            commit('setIsSendingGroupRestoreLicenseProjects', true);
            return LicenseApi.postGroupRestoreLicenseProjects(params)
                .finally(() => {
                    commit('setIsSendingGroupRestoreLicenseProjects', false);
                });
        },
        groupAddLicenseProjectsTags({ commit }: any, params: any) {
            commit('setIsSendingGroupEditLicenseProjectTags', true);
            return LicenseApi.postGroupAddLicenseProjectTags(params).finally(() => {
                commit('setIsSendingGroupEditLicenseProjectTags', false);
            });
        },
        groupDeleteLicenseProjectsTags({ commit }: any, params: any) {
            commit('setIsSendingGroupEditLicenseProjectTags', true);
            return LicenseApi.postGroupDeleteLicenseProjectsTags(params).finally(() => {
                commit('setIsSendingGroupEditLicenseProjectTags', false);
            });
        },
    },
};
