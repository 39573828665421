












import { Component, Prop, Vue } from 'vue-property-decorator';
import { MemberStatusEnum, RouterNames } from '@/constants';
import { License } from '@/models';

@Component
export default class MemberAvatar extends Vue {
    @Prop({ required: true }) public memberId!: number;
    @Prop({ required: true }) public avatarSrc!: string;
    @Prop() public status!: MemberStatusEnum;
    @Prop({ type: Boolean, default: false }) public noLink!: boolean;

    public RouterNames = RouterNames;
    public MemberStatusEnum = MemberStatusEnum;

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }
    get license(): License {
        return this.$store.getters.currentLicense;
    }
    get language(): string {
        return this.$store.getters.currentLanguage;
    }

    get avatarLink() {
        return this.avatarSrc ?? '/images/undefined-user.png';
    }

    get isHasLink() {
        return !this.noLink
            && this.license.isAdminRights
            && this.memberId && this.status !== MemberStatusEnum.pendingApproval;
    }
}
