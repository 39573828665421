














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconFileSvg40 from '@/components/common/icon/IconFileSvg40.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg24,
        IconFileSvg40,
        WsTruncate,
    },
})
export default class IssueCommentFileItem extends Vue {
    @Prop({ required: true }) public filename!: string;
    @Prop() public provider!: string; // для cdeLinks
    @Prop() public url!: string; // для cdeLinks
    @Prop({ default: false, type: Boolean }) public isPinnedFormat!: boolean;

    @Emit()
    public click() {
        return;
    }

    public handleClick() {
        if (!this.isPinnedFormat) {
            if (this.url) {
                open(this.url, '_blank');
            }
        } else {
            this.click();
        }
    }
}
