





































































































import _ from 'lodash';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
    // @ts-ignore
import create360Viewer from '360-image-viewer';
import { DiffComment, FileComment, TextComment } from '@/models';
import { replaceImageS3ResourceUrl } from '@/services';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import EventListenersBase from '@/components/common/EventListenersBase.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    name: 'ImageViewer',
    components: {
        IconSvg16,
        IconSvg24,
        WsDialog,
    },
})

export default class ImageViewer extends EventListenersBase {
    @Prop() public comment!: DiffComment | FileComment | TextComment;
    @Prop({ default: true }) public showArrows!: boolean;

    public isShowImageDialog = false;
    public is360mode = false;
    public isToolbarHover = false;
    public isLoading = true;
    public scale = 100;
    public scaleStep = 10;
    public scaleMax = 200;
    public scaleMin = 0;
    public image = new Image();

    get maxSize() {
        const dialogBody = document.querySelector('.dialog-body') as any;
        const maxHeight = dialogBody.offsetHeight - 140;
        const maxWidth = Math.round(maxHeight * dialogBody.offsetWidth / dialogBody.offsetHeight);
        return {
            maxWidth,
            maxHeight,
        };
    }

    get isComment(): boolean {
        return (this.comment as any).preview?.original;
    }

    get s3enabled(): boolean {
        return this.$store.getters.s3enabled;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    @Watch('is360mode')
    public update360() {
        if (this.is360mode) {
            this.show360();
        } else {
            const dialogBodyCanvas = (document.querySelector('canvas') as HTMLElement);
            if (dialogBodyCanvas) {
                dialogBodyCanvas.remove();
            }
            this.fitContainer();
            this.isLoading = true;
            this.scale = 100;
            this.renderImage();
        }
    }

    @Watch('isShowImageDialog')
    public onIsShowImageChanged() {
        if (!this.isShowImageDialog) {
            this.is360mode = false;
            this.scale = 100;
        } else {
            this.is360mode = (this.comment as FileComment).is360;
        }
    }

    @Watch('comment', { deep: true, immediate: true })
    public onCommentChanged() {
        this.isShowImageDialog = true;
        if (!this.is360mode) {
            this.scale = 100;
            this.renderImage();
        }
    }

    @Watch('scale', { deep: true, immediate: true })
    public onScaleChanged(value: number, oldValue: any) {
        if (oldValue !== undefined) {
            this.fitImage();
        }
    }

    @Emit()
    public next() {
        this.is360mode = false;
        this.scale = 100;
        return;
    }

    @Emit()
    public prev() {
        this.is360mode = false;
        this.scale = 100;
        return;
    }

    @Emit()
    public close() {
        this.isShowImageDialog = false;
        return;
    }

    @Emit()
    public apply() {
        return;
    }

    @Emit()
    public save(markup: any) {
        return markup;
    }

    public capitalizeFirstLetter(value: string) {
        return _.upperFirst(value);
    }

    public async show360() {
        this.isLoading = true;
        const imageRow = 'image';
        const image = new Image();
        image.crossOrigin = 'anonymous';
        const canvasFit = require('canvas-fit');

        const preview: any = ((this.comment as FileComment).preview as any).original;

        if (preview) {
            if (!this.s3enabled) {
                image.src = replaceImageS3ResourceUrl(preview);
            } else {
                image.src  = await this.$store.getters.s3InstanceByProjectId(this.projectId).s3loadImageInBase64(preview);
            }
        } else {
            image.src = (this.comment as any).preview.base64;
        }

        image.onload = () => {
            const viewer = create360Viewer({
                [imageRow]: image,
            });
            const dialogBody = (document.querySelector(`#image360${this.comment.uuid}`) as any);
            dialogBody?.appendChild(viewer.canvas);
            viewer.canvas.style.position = 'relative';
            const fit = canvasFit(viewer.canvas, () => [this.maxSize.maxWidth, this.maxSize.maxHeight], devicePixelRatio);
            this.eventListeners.add({ event: 'resize', handler: fit });
            fit();
            this.isLoading = false;
            viewer.start();
        };
    }

    public zoomIn() {
        this.scale = this.scale < this.scaleMax ? this.scale + this.scaleStep : this.scaleMax;
    }

    public zoomOut() {
        this.scale = this.scale > this.scaleMin ? this.scale - this.scaleStep : this.scaleMin;
    }

    public fitContainer() {
        setTimeout(() => {
            if (this.$refs.imagecontainer) {
                (this.$refs.imagecontainer as HTMLElement).style.width = `${this.maxSize.maxWidth}px`;
                (this.$refs.imagecontainer as HTMLElement).style.height = `${this.maxSize.maxHeight}px`;
            }
        });
    }

    public async fitImage() {
        const imageWidth = this.image.width;
        const imageHeight = this.image.height;
        const imagePreview = document.querySelector(`#preview${this.comment.uuid}`) as any;

        if (imagePreview) {
            let newWidth = imageWidth;
            let newHeight = imageHeight;
            if (imageHeight > this.maxSize.maxHeight) {
                newHeight = this.maxSize.maxHeight;
                newWidth = Math.round(this.maxSize.maxHeight * imageWidth / imageHeight);
            }
            if (newWidth > this.maxSize.maxWidth) {
                newWidth = this.maxSize.maxWidth;
                newHeight = Math.round(this.maxSize.maxWidth * imageHeight / imageWidth);
            }

            const scaledWidth = Math.round(newWidth * (this.scale / 100));
            const scaledHeight = Math.round(newHeight * (this.scale / 100));

            imagePreview.width = `${scaledWidth}`;
            imagePreview.height = `${scaledHeight}`;

            if (this.scale !== 100) {
                (this.$refs.imagecontainer as HTMLElement).style.setProperty('overflow', 'scroll', 'important');
            }

            if (scaledHeight < this.maxSize.maxHeight) {
                imagePreview.style.margin = `${(this.maxSize.maxHeight - scaledHeight) / 2}px 0 0 0`;
            } else if (this.scale > 100) {
                imagePreview.style.margin = '0 0 -7px 0';
            }

            if (this.scale === 100) {
                (this.$refs.imagecontainer as HTMLElement).style.setProperty('overflow', 'hidden');
                if (scaledHeight > this.maxSize.maxHeight) {
                    imagePreview.style.margin = '0 0 0 0';
                }
            }

            if (!this.s3enabled) {
                imagePreview.src = this.image.src;
            } else {
                imagePreview.src  = await this.$store.getters.s3InstanceByProjectId(this.projectId).s3loadImageInBase64(this.image.src);
            }

            this.isLoading = false;
        }
    }

    public renderImage() {
        if ((this.comment as any).preview.base64) {
            this.image.src = (this.comment as any).preview.base64;
            this.isShowImageDialog = true;
            setTimeout(() => {
                this.fitImage();
            });
        } else {
            this.image.src = (this.comment as FileComment).preview.original;
            this.image.onload = () => {
                this.fitImage();
            };
        }
    }

    public mounted() {
        if (!this.is360mode) {
            this.fitContainer();
            this.renderImage();
        } else {
            this.show360();
        }
    }

    public saveMarkup(markup: any) {
        this.save(markup);
    }
}
