import { AuthMethodType } from '@/constants';

export class AuthMethod {
    public uuid: string;
    public title: string;
    public type: AuthMethodType;
    public active: boolean;
    public default: boolean;
    public relatedDomains: string[];
    public teamMembers: any[];
    public twoFactor?: boolean;
    public lastActive?: string;
    public additionalGroupDn?: string;
    public additionalUserDn?: string;
    public baseDn?: string;
    public hostname?: string;
    public password?: string;
    public port?: number;
    public timeForCacheSession?: number;
    public useSSL?: boolean;
    public username?: string;
    public userSearchFilter?: string;

    public metadataUrl?: string;
    public loginUrl?: string;
    public logoutUrl?: string;
    public logoutResponseUrl?: string;
    public replyUrl?: string;
    public spEntityId?: string;
    public idpEntityId?: string;
    public x509cert?: string;
    public spMetadataUrl?: string;
    public spLogoutUrl?: string;

    constructor(authMethod: any = {}) {
        this.uuid = authMethod.uuid || '';
        this.title = authMethod.title || '';
        this.type = authMethod.type || '';
        this.active = authMethod.active || false;
        this.default = authMethod.default || false;
        this.relatedDomains = authMethod.relatedDomains || [];
        this.teamMembers = authMethod.teamMembers || [];
        this.twoFactor = authMethod.twoFactor; // тут не нужно дефолтное значение, если двухфактора не поддерживается она не прилетает
        this.lastActive = authMethod.lastActive;
        this.additionalGroupDn = authMethod.additionalGroupDn;
        this.additionalUserDn = authMethod.additionalUserDn;
        this.baseDn = authMethod.baseDn;
        this.hostname = authMethod.hostname;
        this.password = authMethod.password;
        this.port = authMethod.port;
        this.timeForCacheSession = authMethod.timeForCacheSession;
        this.useSSL = authMethod.useSSL;
        this.username = authMethod.username;
        this.userSearchFilter = authMethod.userSearchFilter;

        this.metadataUrl = authMethod.metadataUrl;
        this.loginUrl = authMethod.loginUrl;
        this.logoutUrl = authMethod.logoutUrl;
        this.logoutResponseUrl = authMethod.logoutResponseUrl;
        this.replyUrl = authMethod.replyUrl;
        this.logoutUrl = authMethod.logoutUrl;
        this.spEntityId = authMethod.spEntityId;
        this.idpEntityId = authMethod.idpEntityId;
        this.x509cert = authMethod.x509cert;
        this.spMetadataUrl = authMethod.spMetadataUrl;
        this.spLogoutUrl = authMethod.spLogoutUrl;
    }
}
