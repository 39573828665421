import { IDeviceData, ITaskDefinitionData, ITaskHistory } from '@/types/project/Project';

enum TaskHistoryStatus {
    Failure = 'failure',
}

export class TaskHistory {
    public uuid: string;
    public status: string;
    public createdAt: number;
    public updatedAt: number;
    public startedAt: number;
    public endedAt: number;
    public scheduledStartAt: number;
    public trigger: string;
    public errorEmail: string;
    public lastOnline: number | null;
    public workingEmail: string;
    public creatorId: number;
    public messages: any[];
    public taskDefinitionData: ITaskDefinitionData;
    public deviceData: IDeviceData;

    public constructor(args: ITaskHistory) {
        this.uuid = args.uuid;
        this.status = args.status;
        this.createdAt = args.createdAt;
        this.updatedAt = args.updatedAt;
        this.startedAt = args.startedAt;
        this.endedAt = args.endedAt;
        this.lastOnline = args.lastOnline || null;
        this.scheduledStartAt = args.scheduledStartAt;
        this.trigger = args.trigger;
        this.errorEmail = args.errorEmail;
        this.workingEmail = args.workingEmail;
        this.creatorId = args.creatorId;
        this.taskDefinitionData = args.taskDefinitionData;
        this.deviceData = args.deviceData;
        this.messages = args.messages;
    }

    get isOnDemand() {
        if (this.taskDefinitionData && this.taskDefinitionData.schedule && this.taskDefinitionData.schedule.value) {
            return !this.taskDefinitionData.schedule.value.enabled;
        }

        return false;
    }

    get taskDefinitionUuid() {
        return this.taskDefinitionData.uuid;
    }

    get isFailure() {
        return this.status === TaskHistoryStatus.Failure;
    }
}
