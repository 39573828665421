







import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IssueCommentDiffChanged extends Vue {
    @Prop({ required: true }) public entity!: string; // comment diff key

    @Emit()
    public click() {
        return;
    }
}
