
import { Component, Vue } from 'vue-property-decorator';
import { EventListeners } from '@/services/EventListeners';

@Component
export default class EventListenersBase extends Vue {
    public eventListeners = new EventListeners();

    public beforeDestroy() {
        this.eventListeners.flush();
    }
}
