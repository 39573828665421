import _ from 'lodash';
import Vue from 'vue';
import { Report } from '@/models';
import ProjectApi from '../api/project.api';

export default {
    state: {
        projectReportsObj: {},
        isLoadingProjectReportsObj: {},
        isSendingReport: false,
        isDeletingReport: false,
    },
    getters: {
        projectReportsByProjectId(state: any): (projectId: number) => Report[] {
            return (projectId) => state.projectReportsObj[projectId] || [];
        },
        projectReport(state: any): (projectId: number, reportUuid: string) => Report {
            return (projectId, reportUuid) => _.find(state.projectReportsObj[projectId], (report: Report) => report.uuid === reportUuid) || new Report();
        },
        isLoadingProjectReport(state: any): (projectId: number, reportUuid: string) => boolean {
            return (projectId, reportUuid) => state.isLoadingProjectReportsObj[projectId] && state.isLoadingProjectReportsObj[projectId][reportUuid];
        },
        isSendingReport(state: any): boolean {
            return state.isSendingReport;
        },
        isDeletingReport(state: any): boolean {
            return state.isDeletingReport;
        },
    },
    mutations: {
        setProjectReports(state: any, { projectId, reports }: { projectId: number, reports: any[] }) {
            Vue.set(state.projectReportsObj, projectId, reports);
        },
        setIsLoadingProjectReports(state: any, { projectId, reportUuid, value }: { projectId: number, reportUuid: string, value: boolean }) {
            const isLoadingProjectReportsObj = state.isLoadingProjectReportsObj[projectId] || {};
            Vue.set(state.isLoadingProjectReportsObj, projectId, Object.assign({}, isLoadingProjectReportsObj, { [reportUuid]: value }));
        },
        setIsSendingReport(state: any, value: boolean) {
            state.isSendingReport = value;
        },
        setIsDeletingReport(state: any, value: boolean) {
            state.isDeletingReport = value;
        },
        updateProjectReport(state: any, { projectId, report }: { projectId: number; report: Report }) {
            const reports = state.projectReportsObj[projectId].map((existingReport: Report) => {
                return existingReport.uuid === report.uuid ? report : existingReport;
            });
            Vue.set(state.projectReportsObj, projectId, reports);
        },
    },
    actions: {
        loadReportData({ commit, getters }: any, { projectId, reportUuid, isForce }: { projectId: number, reportUuid: string, isForce: boolean }) {
            return new Promise((resolve, reject) => {
                if (!isForce && getters.isLoadingProjectReport(projectId, reportUuid)) {
                    resolve(getters.projectReport(projectId, reportUuid));
                    return;
                }

                commit('setIsLoadingProjectReports', { projectId, reportUuid, value: true });

                ProjectApi.getProjectReport(projectId, reportUuid).then((response) => {
                    const report = new Report(response);

                    commit('updateProjectReport', { projectId, report });
                    commit('setIsLoadingProjectReports', { projectId, reportUuid, value: false });
                    resolve(report);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        loadReportsByProjectId({ state, commit }: any, { projectId, isForce = false }: { projectId: number, isForce: boolean }) {
            return new Promise((resolve, reject) => {
                if (!isForce && state.projectReportsObj[projectId]) {
                    resolve(state.projectReportsObj[projectId]);
                    return;
                }
                if (!isForce && state.isLoadingProjectReportsObj[projectId]) {
                    return;
                }

                ProjectApi.getProjectReports(projectId).then((response) => {
                    const reports = _.map(response.entities, (report) => new Report(report));
                    commit('setProjectReports', { projectId, reports });
                    resolve(reports);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        saveProjectReport({ commit }: any, { projectId, report }: { projectId: number, report: Report }): Promise<Report> {
            return new Promise((resolve, reject) => {
                const request = report.uuid
                    ? ProjectApi.postEditReport(projectId, report.uuid, report.apiParams)
                    : ProjectApi.postAddReport(projectId, report.apiParams);

                commit('setIsSendingReport', true);
                request.then((response) => {
                    const responseReport = new Report(response);
                    commit('updateProjectReport', { projectId, report: responseReport });
                    resolve(responseReport);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingReport', false);
                });
            });
        },
        deleteProjectReport({ state, commit }: any, { projectId, reportUuid }: { projectId: number, reportUuid: string }): Promise<Report> {
            return new Promise((resolve, reject) => {
                commit('setIsDeletingReport', true);
                ProjectApi.postDeleteReport(projectId, { uuids: [reportUuid] }).then((response: any) => {
                    const reports = (state.projectReportsObj[projectId] || [])
                        .filter((report: Report) => report.uuid !== reportUuid);
                    commit('setProjectReports', { projectId, reports });
                    resolve(response);
                }).catch((error: any) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsDeletingReport', false);
                });
            });
        },
    },
};
