









import { Component, Prop, Vue } from 'vue-property-decorator';
import { StampColorsPaletteRGB, StampColorsPaletteRGBEnum } from '@/constants';

@Component
export default class StampAbbr extends Vue {
    @Prop({ default: false }) public abbr!: string;
    @Prop({ default: false }) public color!: number;

    get stampColor() {
        return StampColorsPaletteRGBEnum[this.color] || StampColorsPaletteRGB[0];
    }

    get style() {
        return `color:rgb(${this.stampColor});border-color:rgb(${this.stampColor});`;
    }

}
