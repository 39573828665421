










import { Component, Prop, Vue } from 'vue-property-decorator';
import { IssueStatusEnum, NumberBooleanType } from '@/constants';
import IssueStatusText from '@/components/project/issueTracker/IssueStatusText.vue';

@Component({
    components: {
        IssueStatusText,
    },
})
export default class IssueStatus extends Vue {
    @Prop() public status!: IssueStatusEnum;
    @Prop() public isAuto!: boolean | NumberBooleanType;
    @Prop({ type: Boolean }) public pointer!: boolean;

    public IssueStatusEnum = IssueStatusEnum;

    get boolIsAuto(): boolean {
        return Boolean(this.isAuto);
    }
}
