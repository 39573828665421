












import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import WsSidebar from '@/components/common/WsSidebar.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';

@Component({
    components: {
        WsSidebar,
        WsCloseButton,
    },
})
export default class WsSidebarHeader extends Vue {
    @Prop({ default: () => [] }) public title!: string;

    public readonly Color = Color;

    @Emit()
    public close(value: boolean) {
        return value;
    }
}
