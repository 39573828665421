





























































































































































import { Component } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import WsDateRangePicker from '@/components/common/WsDateRangePicker.vue';
import WsTableFilterDatepicker from '@/components/common/WsTableFilterDatepicker.vue';
import FilterByDateBase from '@/components/common/FilterByDateBase.vue';

@Component({
    components: {
        WsDateRangePicker,
        WsTableFilterDatepicker,
    },
})
export default class WsTableFilterByDate extends FilterByDateBase {
    public id = uuid();
}
