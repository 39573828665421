import { TEmail, TUuid } from '@/types/common';

export interface User {
    activated: boolean;
    avatar: string;
    country: string;
    dateFormat: number;
    email: string;
    firstname: string;
    frozen: boolean;
    fullname: string;
    id: number;
    language: string;
    lastActive: string;
    lastname: string;
    receiveemails: boolean;
    role: string;
    roles: string[];
    showemail: boolean;
    timeFormat: number;
    twoFactorMethod: string | null;
    uuid: TUuid;
}

export enum IntegratedServices {
    BOX = 'box',
    FORGE = 'forge',
    PROCORE = 'procore',
    SHAREPOINT = 'sharepoint',
    ASITE = 'asite',
}

export interface IUserConnection {
    uuid: TUuid;
    service: IntegratedServices;
    status: number;
    created: string;
    updated: string;
    userInfo: {
        id: number;
        name: string;
        login: TEmail;
    };
    resourceIdentifier?: string;
}

export interface IUser {
    id: number;
    uuid: string;
    email: string;
    role: string;
    firstname: string;
    lastname: string;
    fullName: string;
    avatar: string;
    language: string;
    frozen: any;
    activated: any;
    lastActive: any;
    twoFactorMethod: any;
}

