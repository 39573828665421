import { BooleanNumber } from '@/types/BooleanNumber';
import { RESPONSE_Type } from '@/constants';
import { DiffComment, FileComment, LicenseMember, MarkupComment, TextComment } from '@/models';

export class AbstractComment {
    public id: number;
    public uuid: string;
    public reporter: string; // Это на самом деле автор (тот кто создал коммент)
    public author: LicenseMember; // А это тот кто залил коммент в систему.
    // TODO: вообще тут какой-то свой интерфейс, похож на LicenseMember. Переписать.
    public created: string;
    public reported: string;
    public editedAt: string;
    public pinned: boolean;
    public removedAt: string;
    public files: []; // additional property to display files
    public dateNumber: number; // additional property for sorting
    public focused?: boolean; // выставляем если хотим при выводе сообщения дать ему анимацию
    public type?: string;
    private _pending?: boolean; // отправка коммента на бэк
    private _pendingError?: RESPONSE_Type; // в случае ошибки при отправке
    private _clashSync: BooleanNumber;
    private _rClashSync: BooleanNumber;

    constructor(comment: any = {}) {
        this.id = comment.id;
        this.uuid = comment.uuid;
        this.reporter = comment.reporter;
        this.author = new LicenseMember(comment.author); // это поле не нужно использовать
        this.created = comment.created;
        this.reported = comment.reported; // сюда смотрим на создателя комментария
        this.editedAt = comment.editedAt;
        this.removedAt = comment.removedAt;
        this.files = comment.files;
        this.pinned = comment.pinned;
        this.dateNumber = comment.dateNumber;
        this._clashSync = comment.clashSync;
        this._rClashSync = comment.rClashSync;
    }

    public static create(comment: any = {}) {
        if (comment.type === 'diff') {
            return new DiffComment(comment);
        } else if (comment.type === 'markup') {
            return new MarkupComment(comment);
        } else if (comment.type === 'text') {
            return new TextComment(comment);
        } else if (comment.type === 'file') {
            return new FileComment(comment);
        }
        throw new Error(`Unknown comment type: [${comment.type}]`);
    }

    set pending(value) {
        this._pending = value;
        this._pendingError = undefined;
    }
    get pending() {
        return this._pending;
    }

    set pendingError(value) {
        this._pendingError = value;
        this._pending = undefined;
    }
    get pendingError() {
        return this._pendingError;
    }

    get rejected(): boolean {
        return Boolean(this.pendingError);
    }

    get isFromClashSync(): boolean {
        return !!this._clashSync || !!this._rClashSync;
    }
}
