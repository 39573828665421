import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import { TAmazonAccess, TAmazonAccessResponse, TAmazonStorage } from '@/types/Amazon';
import { ProjectApi } from '@/api';

export class Amazon {
    public projectId: number | null = null;
    public accessKeys: TAmazonAccess | null = null;
    public storage: TAmazonStorage | null = null;
    public s3: any | null = null;
    public timeKeysUpdated: number = 0;

    public constructor({ projectId }: { projectId: number }) {
        this.projectId = projectId;
        return this;
    }

    public async getAccessKeys(projectId: number) {
        const result: TAmazonAccessResponse = await ProjectApi.getAccessKeys({ projectId });
        this.storage = result.storage;
        this.timeKeysUpdated = +Date.now() / 1000;
        delete result.storage;
        return result;
    }

    public keyMustBeUpdated(): boolean {
        if (this.timeKeysUpdated === 0) {
            return true;
        }

        if ((+Date.now() / 1000) - this.timeKeysUpdated > 60 * 60 - 60 * 10) { // 50 min
            return true;
        }

        return false;
    }

    public async setup() {
        const keyMustBeUpdated = this.keyMustBeUpdated();
        if (this.s3 && !keyMustBeUpdated) {
            return;
        }

        if (!this.projectId) {
            throw new Error('Amazon service: no projectId declared');
        }

        if (!this.accessKeys || keyMustBeUpdated) {
            this.accessKeys = await this.getAccessKeys(this.projectId);

            AWS.config.update({
                region: this.accessKeys?.Region,
                credentials: {
                    accessKeyId: this.accessKeys?.AccessKeyId as string,
                    secretAccessKey : this.accessKeys?.SecretAccessKey as string,
                    sessionToken: this.accessKeys?.SessionToken,
                },
            });

            this.s3 = new S3();
        }
    }

    public getStaticName(source: string) {
        return this.projectId + source.split(String(this.projectId))[1];
    }

    public async uintArrayToBase64(source: any) {
        const base64url: any = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(new Blob([new Uint8Array(source)]));
        });

        return base64url.substring(base64url.indexOf(',') + 1);
    }

    public uintArrayToBlob(buffer: any, type: string) {
        return new Blob([new Uint8Array(buffer)], { type });
    }

    public getImageBase64Src(source: string): string {
        return 'data:image/png;base64, ' + source;
    }

    public async s3loadImageInBase64(path: string): Promise<string> {
        const fileData = await this.loadImage(path);
        return fileData ? this.getImageBase64Src(await this.uintArrayToBase64(fileData.body)) : '';
    }

    public async s3loadImageInBlob(path: string): Promise<Blob | null> {
        const fileData = await this.loadImage(path);
        return fileData ? this.uintArrayToBlob(fileData.body, fileData.type) : null;
    }

    public async loadImage(path: string): Promise<{ body: Uint8Array, type: string } | null> {
        if (!path || path.includes('base64')) {
            return null;
        }
        await this.setup();
        const key = this.getStaticName(path);
        const result = await this.s3.getObject({ Bucket: this.storage?.buckets[0].name as string, Key: key }).promise();
        return result['Body'] ? {
            body: result['Body'],
            type: result['ContentType'],
        } : null;
    }

}
