






















import { Component } from 'vue-property-decorator';
import SkeletonBaseTable from '@/components/common/skeleton/SkeletionBaseTable.vue';

@Component
export default class SkeletonMembersTable extends SkeletonBaseTable {}
