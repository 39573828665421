import { Dict } from '@/types/Dict';
import UnsubscribeApi from '@/api/unsubscribe.api';
import { notificationSuccess } from '@/services';

interface IUnsubscribeStorage {
    isUnsubscribeSending: boolean;
    unsubscribeError: string;
    unsubscribeResult: string;
}

interface IUnsubscribeParams {
    entity: keyof typeof UnsubscribeEntity;
    uuid: string;
    email: string;
    hash: string;
    projectId?: number;
    licenseId?: number;
}

enum UnsubscribeEntity {
    dashboard = 'dashboard',
    graph = 'graph',
    report = 'report',
    licenseDashboard = 'license-dashboard',
    licenseDashboardGraph = 'license-dashboard-graph',
}

export default {
    state: {
        isUnsubscribeSending: false,
        unsubscribeError: '',
        unsubscribeResult: '',
    },
    getters: {
        isUnsubscribeSending(state: IUnsubscribeStorage) {
            return state.isUnsubscribeSending;
        },
        unsubscribeResult(state: IUnsubscribeStorage) {
            return state.unsubscribeResult;
        },
        unsubscribeError(state: IUnsubscribeStorage) {
            return state.unsubscribeError;
        },
    },
    mutations: {
        setIsUnsubscribeSending(state: IUnsubscribeStorage, value: boolean) {
            state.isUnsubscribeSending = value;
        },
        setUnsubscribeResult(state: IUnsubscribeStorage, value: string) {
            state.unsubscribeResult = value;
        },
        setUnsubscribeError(state: IUnsubscribeStorage, value: string) {
            state.unsubscribeError = value;
        },
        clearUnsubscribeStatuses(state: IUnsubscribeStorage) {
            state.isUnsubscribeSending = false;
            state.unsubscribeResult = '';
            state.unsubscribeError = '';
        },
    },
    actions: {
        sendUnsubscribe({ commit }: any, { entity, uuid, email, hash, projectId, licenseId }: IUnsubscribeParams) {
            commit('clearUnsubscribeStatuses');
            if (!UnsubscribeEntity[entity]) {
                commit('setUnsubscribeError', 'Undefined unsubscribe entity');
                return;
            }
            if (!uuid) {
                commit('setUnsubscribeError', 'Undefined unsubscribe uuid');
                return;
            }
            if (!email) {
                commit('setUnsubscribeError', 'Undefined unsubscribe email');
                return;
            }
            if (!hash) {
                commit('setUnsubscribeError', 'Undefined unsubscribe hash');
                return;
            }
            const entityId = projectId || licenseId;
            if (!entityId) {
                commit('setUnsubscribeError', 'Undefined entity Id');
                return;
            }
            const urls: Dict = {
                dashboard: `project/${entityId}/dashboard/${uuid}/unsubscribe`,
                graph: `project/${entityId}/dashboard/graph/${uuid}/unsubscribe`,
                report: `project/${entityId}/report/${uuid}/unsubscribe`,
                licenseDashboard: `license/${entityId}/dashboard/${uuid}/unsubscribe`,
                licenseDashboardGraph: `license/${entityId}/dashboard/graph/${uuid}/unsubscribe`,
            };
            commit('setIsUnsubscribeSending', true);
            return UnsubscribeApi.postUnsubscribe(urls[entity], {
                email,
                hash,
            }).then(() => {
                notificationSuccess('unsubscribed');
                commit('setUnsubscribeResult', true);
            }).catch((error) => {
                commit('setUnsubscribeError', error.message);
            }).finally(() => {
                commit('setIsUnsubscribeSending', false);
            });
        },
    },
};
