import { TranslateResult } from 'vue-i18n';
import _ from 'lodash';
import { notify, toast } from '@/services/notifier';
import { getTranslationEndingByCount, i18n } from '@/services';

interface INotificationsPayload {
    text: TranslateResult,
    title?: TranslateResult,
    duration?: number;
}

export enum NotificationType {
    success = 'success',
    warning = 'warning',
    error = 'error'
}

// Here we pass full translated text instead of just key
export function toastSuccess(message: TranslateResult) {
    return showToast(NotificationType.success, message);
}

export function toastWarning(message: TranslateResult, isReloadText = false) {
    return showToast(NotificationType.warning, message, isReloadText);
}

export function toastError(message: TranslateResult, isReloadText = false) {
    return showToast(NotificationType.error, message, isReloadText);
}

// Notifications is mostly for legacy messages, therefore we pass translationKey for compatibility.
export function notificationSuccess(
    payload: string | INotificationsPayload,
    count: number = 1,
    params: object = {},
) {
    return showNotification(NotificationType.success, getDataToShow(payload, count, params));
    
}

export function notificationWarning(
    payload: string | INotificationsPayload,
    count: number = 1,
    params: object = {},
    isReloadText: boolean = false,
) {
    return showNotification(NotificationType.warning, getDataToShow(payload, count, params), isReloadText);
}

export function notificationError(
    payload: string | INotificationsPayload,
    count: number = 1,
    params: object = {},
    isReloadText: boolean = false,
) {
    return showNotification(NotificationType.error, getDataToShow(payload, count, params), isReloadText);
}

function getFullTranslation(translationKey: string, count: number = 1, params: object = {}) {
    return String(i18n.t(`userActionResultNotification.${translationKey}${getTranslationEndingByCount(count)}`, params));
}

function getDataToShow(payload: string | INotificationsPayload, count: number = 1, params: object = {}) {
    return _.isString(payload) ? { text: getFullTranslation(payload, count, params) } : payload;
}

function showNotification(
    type: NotificationType,
    payload: INotificationsPayload,
    isReloadLink: boolean = false,
) {
    if (!notify?.[type]) {
        return;
    }

    notify[type](
        {
            title: payload?.title || i18n.t(`Notification.${type}`),
            text: payload.text,
            isReloadLink,
            ...(payload?.duration && { duration: payload?.duration }),
        },
    );
}

function showToast(
    type: NotificationType,
    message: TranslateResult,
    isReloadLink: boolean = false,
) {
    if (!toast?.[type]) {
        return;
    }

    toast[type](
        {
            text: message,
            isReloadLink,
        },
    );
}
