import _ from 'lodash';
import moment from 'moment';
import { DEADLINE_NOT_SET, EventClass, EventTypeEnum, MomentFormats } from '@/constants';
import { formTimeShiftFromServer, omitDeep } from '@/services';

interface INotificationParams {
    author: string;
    createdAt: string;
    data: any;
    eventType: EventTypeEnum;
    isRead: boolean;
    licenseUuid: string;
    projectId: number;
    projectTitle: string;
    projectUuid: string;
    uuid: string;
}

export class Notification {
    public readonly author: string;
    public readonly createdAt: string;
    public readonly data: any;
    public readonly eventType: EventTypeEnum;
    public readonly licenseUuid: string;
    public readonly projectTitle: string;
    public readonly projectId: number;
    public readonly projectUuid: string;
    public readonly uuid: string;
    public isRead: boolean;
    public readonly issueChangesVariants: any = [];
    public readonly uniq: string[] = [];

    constructor({
        author,
        createdAt,
        data,
        eventType,
        licenseUuid,
        projectTitle,
        projectId,
        projectUuid,
        uuid,
        isRead,
    }: INotificationParams) {
        this.author = author;
        this.createdAt = formTimeShiftFromServer(createdAt, MomentFormats.serverSide);
        this.data = NotificationData.normalizeDeadline(data);
        this.eventType = eventType;
        this.licenseUuid = licenseUuid;
        this.projectTitle = projectTitle;
        this.projectId = projectId;
        this.projectUuid = projectUuid;
        this.uuid = uuid;
        this.isRead = isRead;
        this.issueChangesVariants = this.issueChangesVariantsMethod();
        this.uniq = this.issueChangesVariants.map(JSON.stringify);
    }

    get isInProgress() {
        return Boolean(EventClass.initial[this.eventType]);
    }

    get isIssue() {
        return Boolean(EventClass.issues[this.eventType]);
    }

    public issueChangesVariantsMethod() {
        if (!this.isIssue) {
            return [];
        }
        const result: any[] = [];
        if (Array.isArray(this.data.changes)) {
            this.data.changes.forEach((change: any) => {
                result.push(omitDeep(change, 'timestamp'));
            });
        } else if (this.data.changes.type === 'text') {
            result.push(omitDeep(this.data.changes, 'created'));
        }
        return result;
    }

    get issueId() {
        return this.data.issueId;
    }

    public static instantiate(data: INotificationParams) {
        return new Notification(data);
    }

    public read() {
        this.isRead = true;
    }
}

class NotificationData {
    private static transform(deadlineString: string) {
        return deadlineString === 'Not set'
            ? DEADLINE_NOT_SET
            : moment(deadlineString, 'DD/MMM/YY').format(MomentFormats.serverSide);
    }

    public static normalizeDeadline(data: any) {
        if (data?.changes?.[0]?.field !== 'deadline') {
            return data;
        }
        data = _.cloneDeep(data);
        const { new: newDate, old: oldDate } = data.changes[0].change;
        data.changes[0].change.new = NotificationData.transform(newDate);
        data.changes[0].change.old = NotificationData.transform(oldDate);
        return data;
    }
}
