var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"otp-input"},_vm._l((_vm.inputs),function(ref$1){
var value = ref$1.value;
var index = ref$1.index;
var classObj = ref$1.classObj;
var ref = ref$1.ref;
var onInput = ref$1.onInput;
var onKeydown = ref$1.onKeydown;
var onFocus = ref$1.onFocus;
return _c('input',{key:index,ref:ref,refInFor:true,staticClass:"otp-input-field",class:classObj,attrs:{"type":"text"},domProps:{"value":value},on:{"input":onInput,"keydown":onKeydown,"focus":onFocus}})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }