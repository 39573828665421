import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { LicenseMember, ProjectMember } from '@/models';

@Component
export default class SelectNameFormatterMixin extends Vue {
    public nameFormatter(member: LicenseMember|ProjectMember) {
        return member.fullname && member.fullname.trim() ? `${member.fullname} (${member.email})` : member.email;
    }
}
