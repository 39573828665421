import { CssClassNameStampColor, StampColorsPaletteHexEnum } from '@/constants';
import { enumToObjectsArray } from '@/services/enumConverter';

export const RichStampColorClasses = enumToObjectsArray(StampColorsPaletteHexEnum).reduce((acc: any, item: any) => ({
    ...acc,
    [CssClassNameStampColor[item.value]]: {
        color: item.key,
        align: 'left',
        fontWeight: 'bold',
        padding: [0, 4, 0, 0],
    },
}), {});
