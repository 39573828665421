






















import _ from 'lodash';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { ISelectItem } from '@/types/common';
import { LicenseMember, ProjectMember, User } from '@/models';
import { compareObjsAlphanumerically, FormValidator, i18n } from '@/services';
import VuetifyElement from '@/components/common/VuetifyElement.vue';
import WsCombobox from '@/components/common/WsCombobox.vue';

const EmailKey = 'email';

@Component({
    components: {
        WsCombobox,
    },
})
export default class WsMemberSelect extends VuetifyElement {
    @Prop({ required: true }) public value!: string[] | string; // emails by default but can be other string picked by itemValue key
    @Prop({ required: true, default: () => [] }) public items!: Array<LicenseMember | ProjectMember>;
    @Prop({ type: Boolean, default: true }) public allowCreate!: boolean;
    @Prop({ type: Boolean, default: true }) public multiple!: boolean;
    @Prop({ default: i18n.t('DeliveryOptions.addExternalRecipient') }) public creationText!: TranslateResult;
    @Prop({ default: EmailKey }) public itemValue!: keyof (LicenseMember | ProjectMember);

    @Emit()
    public input(value: string[]) {
        return value;
    }
    @Emit()
    public change(value: string[]) {
        return value;
    }

    get itemValidator() {
        return this.itemValue === EmailKey ? FormValidator.isValidEmail : FormValidator.isRequired;
    }

    get localItems(): ISelectItem[] {
        return this.items.map((item) => {
            return this.memberFormatter(item);
        }).sort(compareObjsAlphanumerically);
    }

    public nameFormatter(member: LicenseMember | ProjectMember): string {
        let target!: User | ProjectMember;
        if (member instanceof LicenseMember) {
            target = member.user;
        }
        if (member instanceof ProjectMember) {
            target = member;
        }
        if (!target) {
            return '';
        }
        return member.activated && target.fullname ? `${target.fullname} (${target.email})` : target.email;
    }
    public selectFormatter(value: string): string {
        const member = this.items.find((item: LicenseMember | ProjectMember) => {
            return item[this.itemValue] === value;
        });
        return member ? this.nameFormatter(member) : value;
    }
    public memberFormatter(member: LicenseMember | ProjectMember): ISelectItem {
        const value = typeof member[this.itemValue] === 'string' ? (member[this.itemValue] as string) : member[EmailKey];
        return {
            text: this.nameFormatter(member),
            value,
        };
    }
    public onChangeValue(newValue: any) {
        let value = newValue;
        if (_.isNil(value)) {
            value = [];
        }

        let checkedValues: string[];

        if (_.isArray(value)) {
            checkedValues = value.reduce((acc: Array<string | number>, item: string | ISelectItem) => {
                if  (_.isString(item)) {
                    if (this.itemValidator(item)) {
                        acc.push(item);
                    }
                } else if (item.value) {
                    acc.push(item.value);
                }
                return acc;
            }, []);
        } else if (this.multiple) {
            checkedValues = _.isString(value) ? [value] : [value?.value];
        } else {
            checkedValues = _.isString(value) ? value : value?.value;
        }

        this.input(checkedValues);
        this.change(checkedValues);
    }
}
