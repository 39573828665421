




















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import SvgSource16 from '@/components/common/SvgSource16.vue';
import SvgSource24 from '@/components/common/SvgSource24.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

// DEPRECATED => IconSvg16 / IconSvg24
@Component({
    components: {
        WsTooltip,
        SvgSource16,
        SvgSource24,
    },
})
export default class WsIconButton extends Vue {
    @Prop() public text!: VueI18n.TranslateResult;
    @Prop() public tooltip!: VueI18n.TranslateResult;
    @Prop({ default: 'edit' }) public icon!: string;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public gray!: boolean;
    @Prop({ type: Number, default: 16 }) public size!: number;
    @Prop({ type: String, default: '#616161' }) public fill!: string;

    @Emit()
    public click() {
        return;
    }

    public handleClick() {
        if (!this.disabled) {
            this.click();
        }
    }
}
