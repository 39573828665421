




























































































































































import moment from 'moment';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import {
    AmplitudeEvent,
    BusEvent,
    Color,
    DEADLINE_NOT_SET,
    IssueStatusEnum,
    MomentFormats,
    RouterNames,
} from '@/constants';
import { CustomStatus, Issue, Project } from '@/models';
import { eventBus } from '@/services/eventBus';
import {
    amplitudeLog,
    copyUrlToBuffer,
    openInAppLink,
} from '@/services';
import CustomStatusItem from '@/components/project/workflow/CustomStatusItem.vue';
import IssuePriorityIcon from '@/components/project/issueTracker/IssuePriorityIcon.vue';
import IssueStatus from '@/components/project/issueTracker/IssueStatus.vue';
import ProjectMemberName from '@/components/project/ProjectMemberName.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import WsMenuIcon from '@/components/common/WsMenuIcon.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import WsTruncate from '@/components/common/WsTruncate.vue';
import StampAbbr from '@/components/common/StampAbbr.vue';
import CustomIssueTypeIcon from '@/components/common/CustomIssueTypeIcon.vue';
import DialogDeleteIssues from '@/components/project/issueTracker/modals/DialogDeleteIssues.vue';

const DiffOfComponentAndTitle = 80; // 80px of paddings and margins;

@Component({
    components: {
        CustomStatusItem,
        WsTruncateAuto,
        WsTooltip,
        WsCheckbox,
        WsMenuIcon,
        WsTruncate,
        IconSvg16,
        IconSvg24,
        IssuePriorityIcon,
        IssueStatus,
        CustomIssueTypeIcon,
        ProjectMemberName,
        StampAbbr,
        DialogDeleteIssues,
    },
})
export default class IssueItem extends Vue {
    @Prop({ required: true }) public issue!: Issue;
    @Prop({ default: false }) public selected!: boolean;

    @Ref() public readonly issueTitle!: HTMLElement;

    public readonly Color = Color;
    public titleMaxSize = 0;
    public isVisibleDialogDeleteIssues = false;
    public IssueStatusEnum = IssueStatusEnum;
    public DEADLINE_NOT_SET = DEADLINE_NOT_SET;

    get issuePrefix() {
        return this.currentProject.abbreviate || this.$t('Simple_word.id');
    }

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get nowDateObj() {
        return moment();
    }

    get deadlineDateObj() {
        return moment(this.issue.deadline, MomentFormats.serverSide).startOf('day');
    }

    get deadlineExpiredDays() {
        return this.deadlineDateObj.diff(this.nowDateObj, 'days', true);
    }

    get deadlineExpiredText() {
        if (this.deadlineExpiredDays > -1 && this.deadlineExpiredDays < 0) {
            return this.$t('DateDifferenceMessages.today');
        }

        if (this.deadlineExpiredDays >= -2 && this.deadlineExpiredDays <= -1) {
            return this.$t('DateDifferenceMessages.yesterday');
        }

        if (this.deadlineExpiredDays >= 0 && this.deadlineExpiredDays < 1) {
            return this.$t('DateDifferenceMessages.tomorrow');
        }

        const deadlineExpiredDaysAbs = Math.abs(Math.ceil(this.deadlineExpiredDays));

        if (deadlineExpiredDaysAbs < 30) {
            return this.$t('DateDifferenceMessages.days', { count: deadlineExpiredDaysAbs });
        }

        if (deadlineExpiredDaysAbs >= 30 && deadlineExpiredDaysAbs < 365) {
            return this.$t('DateDifferenceMessages.moreMonth', { count: Math.round(deadlineExpiredDaysAbs / 30) });
        }

        return this.$t('DateDifferenceMessages.moreYear', { count: Math.round(deadlineExpiredDaysAbs / 365) });
    }

    get deadline() {
        return this.issue.deadline;
    }

    get currentUserEmail() {
        return this.$store.getters.userData.email;
    }

    get iamWatcher() {
        return Boolean(this.issue.watchers.find((email) => email === this.currentUserEmail));
    }

    get isNew(): boolean {
        return this.issue.isUnread && (this.issue.status !== IssueStatusEnum.closed && (this.iamWatcher || this.issue.assignee === this.currentUserEmail));
    }

    get isIssueInMultiSelect() {
        return Boolean(this.$store.getters.multiSelectedIssues.filter((issue: Issue) => issue.id === this.issue.id).length);
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get permissions() {
        return this.currentProject.permissions;
    }

    get canDelete(): boolean {
        const deleteMarker = 'deleteMarker';
        return this.issue.hasPermissions(this.currentUserEmail, this.permissions[deleteMarker])
            && this.issue.status !== IssueStatusEnum.deleted;
    }

    get customTypeIcon() {
        if (!this.issue.customType) {
            return undefined;
        }

        return this.$store.getters.customIssueTypeByUuid(this.currentProject.uuid, this.issue.customType);
    }

    get customStatus(): CustomStatus | undefined {
        return this.$store.getters.customIssueStatusByUuid(this.currentProject.uuid, this.issue.customStatus);
    }

    @Watch('issue.title')
    onChangeIssueTitle() {
        this.titleMaxSize = this.calcMaxTitleSize(this.$el.clientWidth);
    }

    @Emit('click')
    public onClickComponent({ clickOnCheckbox }: { clickOnCheckbox: boolean; }) {
        return {
            issue: this.issue,
            clickOnCheckbox,
            value: !this.isIssueInMultiSelect,
        };
    }

    @Emit('mounted')
    public emitMountedEvent() {
        return;
    }

    public mounted() {
        eventBus.$on(BusEvent.issuesColumnWidth, this.onChangeComponentWidth);

        this.titleMaxSize = this.calcMaxTitleSize(this.$el.clientWidth);

        this.emitMountedEvent();
    }

    public beforeDestroy() {
        eventBus.$off(BusEvent.issuesColumnWidth, this.onChangeComponentWidth);
    }

    private onChangeComponentWidth(width: number) {
        setTimeout(() => { // Pass the event to the next event loop
            this.titleMaxSize = this.calcMaxTitleSize(width);
        });
    }

    private getTextWidthInPx(text: string) {
        const fakeSpan = document.createElement('span');
        fakeSpan.style.position = 'fixed';
        fakeSpan.style.top = '-1000px';
        fakeSpan.style.fontSize = '14px'; // like in css
        fakeSpan.style.fontWeight = '500';
        fakeSpan.innerText = text;
        document.body.appendChild(fakeSpan);
        const width = fakeSpan.clientWidth;
        fakeSpan.remove();

        return width;
    }

    public calcMaxTitleSize(elementWidth: number) {
        let truncatedTitle = this.issue.title;
        const doubleElementWidth = elementWidth * 2; // Because of the title have two lines
        const doublePadding = DiffOfComponentAndTitle * 2;
        const maxTitleWidth = doubleElementWidth - doublePadding;

        for (let i = 0; i < 10; i++) {
            const titleWidth = this.getTextWidthInPx(truncatedTitle);
            const diff = maxTitleWidth - titleWidth;

            if (diff > 0) {
                return truncatedTitle.length;
            }

            const aproximateLetterWidth = Math.round(titleWidth / truncatedTitle.length);
            const hiddenLettersCount = Math.round(Math.abs(diff) / aproximateLetterWidth);
            const smoothHiddenLettersCount = Math.round(hiddenLettersCount / 2); // All chars have different width, and we should do several's attempts to find best length

            truncatedTitle = truncatedTitle.slice(0, truncatedTitle.length - smoothHiddenLettersCount);
        }

        return truncatedTitle.length;
    }

    public onIssueSelectedChange() {
        this.$store.dispatch('handleIssueMultiSelect', this.issue);
    }

    public copyIssueUrl() {
        const itPath = this.$router.resolve({
            name: RouterNames.ProjectIssueTracker,
            params: {
                language: this.language,
                licenseId: String(this.licenseId),
                projectId: String(this.projectId),
            },
        }).href;
        const issueUrl = `${location.origin}${itPath}?id=${this.issue.id}`;
        copyUrlToBuffer(issueUrl);

        amplitudeLog(AmplitudeEvent.itIssueCopyLink, { type: 'web' });
    }

    public copyAppIssueUrl() {
        copyUrlToBuffer(openInAppLink(this.currentProject, this.projectId, this.issue.id));
        amplitudeLog(AmplitudeEvent.itIssueCopyLink, { type: 'app' });
    }

    public printIssue() {
        const uuid = this.issue.uuid;
        eventBus.$emit(BusEvent.printIssue, { uuid });
    }

    public async deleteIssue() {
        this.onIssueSelectedChange();

        await this.$store.dispatch('deleteIssue', { projectId: this.projectId, issueUuid: this.issue.uuid });
        await this.$store.dispatch('disableIssueFromOrder', { projectId: this.projectId, issueUuid: this.issue.uuid });
        await this.$store.dispatch('loadIssuesByProjectId', { projectId: this.projectId });
    }

    public async undelete() {
        this.onIssueSelectedChange();

        await this.$store.dispatch('undeleteIssue', {
            projectId: this.projectId,
            issueUuid: this.issue.uuid,
        });

        await this.$store.dispatch('loadDeletedIssues', {
            projectId: this.projectId,
            params: { reportSort: [{ field: 'priority', direction: 'asc' }] },
        }); // @todo issue sort base
    }
}
