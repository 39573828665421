export enum FilterLocation {    // it's mandatory, that keys and values in this enum are the same
    myProjects = 'myProjects',
    projectMembers = 'projectMembers',
    issueTracker = 'issueTracker',
    licenseDashboards = 'licenseDashboards',
    projectReports = 'projectReports',
    projectActionLogs = 'projectActionLogs',
    projectDashboards = 'projectDashboards',
    licenseMembers = 'licenseMembers',
    licenseMembersApproval = 'licenseMembersApproval',
    licenseProjects = 'licenseProjects',
    licenseActionLogs = 'licenseActionLogs',
    projectRoles = 'projectRoles',
    licenseActivity = 'licenseActivity',
    projectActivity = 'projectActivity',
    additionalFields = 'additionalFields'
}
