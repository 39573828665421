interface IAdmin {
    uuid: string;
    user: {
        fullname: string;
        email: string;
    };
}

export class ProjectAdmin {
    public readonly fullname: string;
    public readonly uuid: string;
    public readonly email: string;

    constructor({ user: { fullname, email }, uuid }: IAdmin) {
        this.fullname = fullname.trim();
        this.uuid = uuid;
        this.email = email;
    }

    private get searchValue() {
        return `${this.fullname} ${this.email}`.toLowerCase();
    }

    get text() { // used in .sort(compareObjsAlphanumerically)
        return this.fullname || this.email;
    }

    private has(searchQuery: string) {
        return this.searchValue.includes(searchQuery.toLowerCase());
    }

    public static instantiate(data: IAdmin) {
        return new ProjectAdmin(data);
    }

    public static has(searchQuery: string) {
        return (projectAdmin: ProjectAdmin) => projectAdmin.has(searchQuery);
    }
}
