import _ from 'lodash';
import { IssueFilterExpr, IssueFilterExprT } from '@/constants';
import { ITableFilter } from '@/models';

export function propertyPassesFilter(propertyValue: string | number | Array<string | number>, filter: ITableFilter): boolean {
    if (!filter.selections?.length) {
        return true;
    }
    const intersectionLength = _.intersection(filter.selections, _.castArray(propertyValue)).length;
    switch (filter.modifier) {
        case IssueFilterExpr.HAS_ALL_IN:
            return intersectionLength === filter.selections.length;
        case IssueFilterExpr.HAS_NOT_ALL_IN:
            return intersectionLength === 0;
        case IssueFilterExpr.HAS_ONE_IN:
        default:
            return intersectionLength > 0;
    }
}

export function modifierAsStringParam(expr: IssueFilterExprT | undefined): 'union' | 'intersection' | 'exclusive' {
    switch (expr) {
        case IssueFilterExpr.HAS_ALL_IN:
            return 'intersection';
        case IssueFilterExpr.HAS_NOT_ALL_IN:
            return 'exclusive';
        case IssueFilterExpr.HAS_ONE_IN:
        default:
            return 'union';
    }
}
