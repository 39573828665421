import _ from 'lodash';
import { TUuid } from '@/types/common';
import { Dict } from '@/types/Dict';
import { stableStringify } from '@/services/StringMethods';

export enum Comparison {
    Minus = -1,
    Zero = 0,
    Plus = 1,
}

function applyIsDesc(isDesc: boolean, comparison: Comparison) {
    return (isDesc ? -comparison : comparison) as Comparison;
}

export function compare<T extends number | string>(left: T, right: T, isDesc = false) {
    const comparison = function getComparison() {
        switch (true) {
            case left === right:
                return Comparison.Zero;
            case left < right:
                return Comparison.Minus;
            default:
                return Comparison.Plus;
        }
    }();
    return applyIsDesc(isDesc, comparison);
}

export function compareStrings(left: string, right: string, isDesc = false) {
    return compare(left.toLowerCase().trim(), right.toLowerCase().trim(), isDesc);
}

export function compareNumbers(left: number, right: number, isDesc = false) {
    return compare(left, right, isDesc);
}

function compareAlphanumericallyRaw(a: string | undefined, b: string | undefined, isDesc = false) {
    if (a === undefined) {
        return b === a ? Comparison.Zero : Comparison.Minus;
    } else if (b === undefined) {
        return Comparison.Plus;
    }
    a = a.trim().toLowerCase();
    b = b.trim().toLowerCase();
    const comparison = a.localeCompare(b, 'en-US', { numeric: true }) as Comparison;
    return applyIsDesc(isDesc, comparison);
}

export const compareAlphanumerically = _.memoize(
    compareAlphanumericallyRaw,
    (...args: any[]) => stableStringify(args),
);

export function compareObjsAlphanumerically(a: any, b: any, isDesc = false, prop = 'text') {
    return compareAlphanumerically(a[prop]?.toString() || '', b[prop]?.toString() || '', isDesc);
}

export function compareByUuid<T extends { uuid: TUuid }>(x: T, y: T) {
    return x.uuid === y.uuid;
}

export function getCompareObjectsAlphanumericallyBySortValue<T>(getSortValue: (item: T) => string) {
    return (a: T, b: T, isDesc = false) => (
        compareObjectsAlphanumericallyBySortValue(a, b, isDesc, getSortValue)
    );
}

export function compareObjectsAlphanumericallyBySortValue<T extends Dict>(
    a: T,
    b: T,
    isDesc = false,
    getSortValue: (item: T) => string = (item) => item.text,
) {
    return compareAlphanumerically(getSortValue(a), getSortValue(b), isDesc);
}
