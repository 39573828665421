export interface MultiEditIssueFieldPayload {
    add: any[];
    remove: any[];
}

// typeguard
export const isMultiEditIssueFieldPayload = (obj: any): obj is MultiEditIssueFieldPayload => {
    return Boolean(obj)
        && Array.isArray(obj.add)
        && Array.isArray(obj.remove);
};
