






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IToastAction } from '@/types/INotifier';
import { ToastType } from '@/constants/Notifier/ToastType';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

const toastTypeDefault = ToastType.INFO;

const toastIcon = {
    [ToastType.INFO]: 'info-new',
    [ToastType.WARNING]: 'error',
    [ToastType.SUCCESS]: 'checked',
    [ToastType.ERROR]: 'error',
};

@Component({
    components: {
        WsCloseButton,
        IconSvg16,
    },
})
export default class WsToast extends Vue {
    @Prop({ type: String, default: toastTypeDefault }) type!: ToastType;
    @Prop({ type: String, required: true }) text!: string;
    @Prop({ type: Boolean, default: false }) isReloadLink!: boolean;
    @Prop({ type: Object, default: null }) action!: IToastAction;

    get icon() {
        return toastIcon[this.type] || toastIcon[toastTypeDefault];
    }

    @Emit()
    public close() {
        return;
    }

    public onActionClick() {
        this.action.callback();
        this.close();
    }

    public reload() {
        window.location.reload();
    }
}
