import { Dict } from '@/types/Dict';
import { PermissionNamesEnum } from '@/constants/AccessRoles';

export const governingPermissions: Dict<PermissionNamesEnum[]> = {
    [PermissionNamesEnum.append_2d]: [PermissionNamesEnum.edit_2d],
    [PermissionNamesEnum.append_3d]: [PermissionNamesEnum.edit_3d],
    [PermissionNamesEnum.edit_issue_status]: [PermissionNamesEnum.close_issue],
    [PermissionNamesEnum.tag_issue]: [PermissionNamesEnum.create_new_tags, PermissionNamesEnum.manage_issue_tags_across_project],
    [PermissionNamesEnum.create_new_tags]: [PermissionNamesEnum.manage_issue_tags_across_project],
    [PermissionNamesEnum.assign_object_properties]: [PermissionNamesEnum.manage_object_properties],
    [PermissionNamesEnum.create_issue]: [PermissionNamesEnum.allow_clash_sync],
    [PermissionNamesEnum.create_clash_test]: [PermissionNamesEnum.clash_test_admin],
    [PermissionNamesEnum.view_public_clash_test]: [PermissionNamesEnum.clash_test_admin],
};
