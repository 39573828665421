
/* eslint-disable import/order */
import 'babel-polyfill';

import '../public/fonts/solo-icons/css/solo-icons.css'; // иконки от Соло, сгенерированы на сайте http://fontello.com/
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './storage';
import VueCookies from 'vue-cookies';
import VueI18n from 'vue-i18n';
import { i18n } from '@/services';
// @ts-ignore
import VueSimpleSVG from 'vue-simple-svg';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.css'; // для vuetify
// @ts-ignore
import VueNativeSock from 'vue-native-websocket';
import websocketStorage from './storage/websocket.storage';
// @ts-ignore
import VueAmplitude from 'vue-amplitude';
// @ts-ignore
import settings from 'reviztoSettings';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { SentryService } from '@/services';
// @ts-ignore
import VueObserveVisibility from 'vue-observe-visibility';
// @ts-ignore
import VueDragscroll from 'vue-dragscroll';
import VueClipboard from 'vue-clipboard2';
// @ts-ignore
import VueHorizontal from 'vue-horizontal';
import { initHttpInterceptors } from './api/interceptors';
import LoadingDirective from '@/directives/LoadingDirective';
initHttpInterceptors();

if (process.env.NODE_ENV !== 'development') {
    const { baseHost } = settings;
    const dsn : string =  baseHost.includes('stage') ? 'https://8d0d45c4cbaf87ae0b9398102a6c3136@o522121.ingest.sentry.io/4506081508458496' : 'https://e4ce101d43f2441d871093259a7f7eac@o522121.ingest.sentry.io/5633130';

    Sentry.init({
        Vue,
        dsn,
        integrations: [new Integrations.BrowserTracing()],
        beforeSend(event: any): any {
            SentryService.cleanHttpSensitive(event);
        },
        beforeBreadcrumb(breadcrumb: any) {
            SentryService.cleanHttpSensitive(breadcrumb);
            return breadcrumb;
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(Vuetify);
Vue.use(VueI18n);
Vue.use(VueSimpleSVG);
Vue.use(VueObserveVisibility);
Vue.use(VueDragscroll);

VueClipboard.config.autoSetContainer;

Vue.use(VueClipboard);
Vue.use(VueHorizontal);

Vue.directive('loading', LoadingDirective);

Vue.use(VueNativeSock, 'wss://revizto.com', {
    connectManually: true,
    store: websocketStorage,
});

const { amplitudeApiKey } = settings;
if (amplitudeApiKey) {
    Vue.use(VueAmplitude, { apiKey: amplitudeApiKey });
}

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1d6fed',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#CC2929',
                info: '#2196F3',
                success: '#24CE08',
                warning: '#FFD32E',
            },
            dark: {
                primary: '#1d6fed',
            },
        },
    },
});

const requireComponent = require.context(
    './components/common',  // Относительный путь до каталога компонентов
    false,                  // Обрабатывать или нет подкаталоги
    /Ws[A-Z0-9]\w+\.(vue)$/,   // Регулярное выражение для определения файлов базовых компонентов
);

requireComponent.keys().forEach((fileName) => {
    // Получение имени компонента
    const componentName = fileName
        .replace(/\.\w+$/, '')
        .split('/')
        .pop();

    if (componentName) {
        // Получение конфигурации компонента
        const componentConfig = requireComponent(fileName);

        // Глобальная регистрация компонента
        Vue.component(componentName, componentConfig.default || componentConfig);
    }
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
