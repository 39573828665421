import { IssueTrackerFields } from '@/constants';

export const IssueTrackerDiffInlineProperties = [
    IssueTrackerFields.title,
    IssueTrackerFields.visibility,
    IssueTrackerFields.deadline,
    IssueTrackerFields.priority,
    IssueTrackerFields.created,
    IssueTrackerFields.assignee,
    IssueTrackerFields.reporter,
    IssueTrackerFields.stampColor,
    IssueTrackerFields.stampAbbr,
];
export type IssueTrackerDiffInlinePropertiesType = typeof IssueTrackerDiffInlineProperties[number];

export const IssueTrackerDiffMultilineProperties = [
    IssueTrackerFields.watchers,
    IssueTrackerFields.tags,
    IssueTrackerFields.level,
    IssueTrackerFields.room,
    IssueTrackerFields.zone,
    IssueTrackerFields.area,
    IssueTrackerFields.space,
    IssueTrackerFields.grid,
];
export type IssueTrackerDiffMultilinePropertiesType = typeof IssueTrackerDiffMultilineProperties[number];

export const IssueTrackerDiffSpecialProperties = [
    IssueTrackerFields.status,
    IssueTrackerFields.customStatus,
    IssueTrackerFields.customType,
    IssueTrackerFields.procore,
    IssueTrackerFields.cdeLinks,
    IssueTrackerFields.deletedAt,
];
