import _ from 'lodash';
import { TranslateResult } from 'vue-i18n';
import { Dict } from '@/types/Dict';
import { governingPermissions, NumberBooleanType } from '@/constants';
import { UnreachableError } from '@/models/Errors/UnreachableError';
import { i18n } from '@/services/i18n';
import { normalizeValueByEnum } from '@/services/normalizeValueByEnum';

export type TPermissions = Dict<NumberBooleanType>;

export enum AccessRoleSystemEnum {
    NONE = 0,
    OWNER = 1,
    LICENSE_ADMINISTRATOR = 2,
    ADMIN = 3,
}

export class AccessRole {
    public id: number;
    public uuid: string;
    public name: string;
    private readonly _system: number;
    public filter: string;
    // the filter variable was born due to the presence of system roles and the fact that the 'Admin' and 'License administrator' roles are the same,
    // it makes it easier to filter roles, since system roles are oriented to the system variable and custom roles to the name variable.
    public _permissions: TPermissions;
    public statistics: {
        members: number;
        projects: number;
    };

    constructor(accessRole: any = {}) {
        this.id = accessRole.id;
        this.uuid = accessRole.uuid;
        this.name = accessRole.name;
        this._system = accessRole.system;
        this.filter = accessRole.system ? String(accessRole.system) : accessRole.name;
        this._permissions = accessRole.permissions || {};
        this.statistics = accessRole.statistics || {};
    }

    get permissions() {
        return this._permissions;
    }

    set permissions(value: TPermissions) {
        this._permissions = value;
    }

    get searchValue() {
        return this.name.toLowerCase();
    }

    get system(): AccessRoleSystemEnum {
        return normalizeValueByEnum(this._system, AccessRoleSystemEnum, AccessRoleSystemEnum.NONE);
    }

    get normalizedPermissions() {
        return _.mapValues(this.permissions, (value: NumberBooleanType, key: string) => {
            const hasGoverningPermission = governingPermissions[key]
                ?.some((governingPermission) => this.permissions[governingPermission]);
            return this.permissions.manage_project_rights || hasGoverningPermission ? 1 : value;
        });
    }

    get localizedName(): TranslateResult { // Used in MyProjects
        switch (this.system) {
            case AccessRoleSystemEnum.OWNER:
                return i18n.t('Project.accessRole.1');
            case AccessRoleSystemEnum.LICENSE_ADMINISTRATOR:
                return i18n.t('Project.accessRole.2');
            case AccessRoleSystemEnum.ADMIN: // Deprecated role, just use non-translated name that can be in any language
            case AccessRoleSystemEnum.NONE:
                return this.name;
            default: {
                const _never: never = this.system;
                throw new UnreachableError(this.system);
            }
        }
    }

    private has(searchQuery: string) {
        return this.searchValue.includes(searchQuery.toLowerCase());
    }

    public static has(searchQuery: string) {
        return (project: AccessRole) => project.has(searchQuery);
    }
}
