import { Vue } from 'vue-property-decorator';
import { i18n, notificationError, notificationSuccess } from '@/services';

export async function copyUrlToBuffer(urlToCopy: string, container?: HTMLElement) {
    try {
        await Vue.prototype.$copyText(urlToCopy, container);
        notificationSuccess({
            title: i18n.t('Simple_word.success'),
            text: i18n.t('Collocation.linkCopied'),
        });
    } catch (e) {
        notificationError({
            title: i18n.t('Simple_word.error'),
            text: i18n.t('Collocation.linkCopyError'),
        });
    }
}
