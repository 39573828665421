













import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { sanitizeHtml } from '@/services';

@Component({
    methods: {
        sanitizeHtml,
    },
})
export default class WsIconPopover extends Vue {
    @Prop({ default: '!' }) public iconType!: string;
    @Prop() public title!: TranslateResult;
    @Prop({ default: () => [] }) public texts!: TranslateResult[];
}
