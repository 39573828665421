import Vue from 'vue';
import LicenseApi from '@/api/license.api';
import { notificationSuccess } from '@/services';

export default {
    state: {
        licenseMembersActivityObj: {},
        isLoadingLicenseMembersActivityObj: {},
        isSendingLicenseMemberActivity: false,
    },
    getters: {
        licenseMembersActivityByLicenseId(state: any): (licenseId: number) => any {
            return (licenseId) => state.licenseMembersActivityObj[licenseId] || {};
        },
        isLoadingLicenseMembersActivityByLicenseId(state: any): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingLicenseMembersActivityObj[licenseId];
        },
        isSendingLicenseMemberActivity(state: any): boolean {
            return state.isSendingLicenseMemberActivity;
        },
    },
    mutations: {
        setLicenseMembersActivity(state: any, { licenseId, data }: { licenseId: number, data: any }) {
            Vue.set(state.licenseMembersActivityObj, licenseId, data);
        },
        setIsLoadingLicenseMembersActivity(state: any, { licenseId, value }: { licenseId: number; value: boolean }) {
            Vue.set(state.isLoadingLicenseMembersActivityObj, licenseId, value);
        },
        setIsSendingLicenseMemberActivity(state: any, value: boolean) {
            state.isSendingLicenseMemberActivity = value;
        },
    },
    actions: {
        loadLicenseMembersActivity({ commit }: any, { licenseId, params }: { licenseId: number, params: any }) {
            commit('setIsLoadingLicenseMembersActivity', { licenseId, value: true });
            return LicenseApi.postLicenseMembersActivity(licenseId, params).then((response) => {
                commit('setLicenseMembersActivity', { licenseId, data: response });
            }).finally(() => {
                commit('setIsLoadingLicenseMembersActivity', { licenseId, value: false });
            });
        },
        sendLicenseMembersActivityToEmail({ commit }: any, { params }: { params: any }) {
            commit('setIsSendingLicenseMemberActivity', true);
            return LicenseApi.postExportLicenseMemberActivity(params).then(() => {
                notificationSuccess('activitySent');
            }).finally(() => {
                commit('setIsSendingLicenseMemberActivity', false);
            });
        },
    },
};
