import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class TimeListMixin extends Vue {
    get timeList() {
        const startHour = 0; // начало рабочего дня
        const endHour = 23; // конец рабочего дня
        const periodInMinutes = 60;

        const result = [];
        const stepsCount = Math.floor((endHour - startHour) * 60 / periodInMinutes);

        for (let i = 0; i <= stepsCount; i++) {
            const addHour = Math.floor(periodInMinutes * i / 60);
            const addMinutes = Math.floor(periodInMinutes * i % 60);
            result.push(doubleChar(startHour + addHour) + ':' + doubleChar(addMinutes));
        }

        return result;

        function doubleChar(value: number) {
            const resultString = String(value);
            return resultString.length === 1 ? '0' + resultString : resultString;
        }
    }
}
