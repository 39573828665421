
export enum CustomStatusCategory {
    ToDo = 'To do',
    Tracking = 'Tracking',
    Completed = 'Completed',
}

export const CustomStatusCategoryList = [
    CustomStatusCategory.ToDo,
    CustomStatusCategory.Tracking,
    CustomStatusCategory.Completed,
];
