

















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Dict } from '@/types/Dict';
import { IssueStatusEnum, IssueTrackerFields } from '@/constants';
import { IDiffItem, IssueTrackerDiffInlinePropertiesType } from '@/models';
import { dateFormatter } from '@/services/MomentFormats';
import CustomStatusItem from '@/components/project/workflow/CustomStatusItem.vue';
import IssueStatusText from '@/components/project/issueTracker/IssueStatusText.vue';
import IssueCommentDiffChanged from '@/components/project/issueTracker/comments/IssueCommentDiffChanged.vue';
import IssueStatus from '@/components/project/issueTracker/IssueStatus.vue';

@Component({
    components: {
        CustomStatusItem,
        IssueCommentDiffChanged,
        IssueStatusText,
        IssueStatus,
    },
    methods: {
        dateFormatter,
    },
})
export default class IssueCommentDiffStatus extends Vue {
    @Prop({ required: true }) public entity!: IssueTrackerDiffInlinePropertiesType;
    @Prop({ required: true }) public diff!: Dict<IDiffItem>;

    public readonly IssueStatusDeleted = IssueStatusEnum.deleted;

    get projectUuid(): string {
        const projectId = this.$route.params.projectId;
        return this.$store.getters.projectById(projectId)?.uuid || '';
    }

    get hasDeletedStatus() {
        return this.diff.status?.new === IssueStatusEnum.deleted;
    }

    get customStatusDiff() {
        if (this.diff.customStatus && this.entity == IssueTrackerFields.customStatus) {
            return {
                newStatus: this.$store.getters.customIssueStatusByUuid(this.projectUuid, this.diff.customStatus.new),
                oldStatus: this.$store.getters.customIssueStatusByUuid(this.projectUuid, this.diff.customStatus.old),
            };
        } else {
            return undefined;
        }
    }

    get isSkipThisDiff() {
        return Boolean(this.entity == IssueTrackerFields.status && this.diff.customStatus);
    }

    get isShowStandardStatus() {
        return this.entity == IssueTrackerFields.status && !this.diff.customStatus;
    }

    @Emit()
    public click() {
        return;
    }
}
