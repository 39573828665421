

































































































































































































































































































































































import _ from 'lodash';
import moment from 'moment';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
    AmplitudeEvent,
    Color,
    EventClass,
    EventTypeEnum,
    IssueFilterExpr,
    IssuesFilterType,
    RouterNames,
} from '@/constants';
import {
    LicenseMember,
    NotificationRecord,
    NotificationRecordStatus,
    NotificationRecordType,
    ProjectMember,
} from '@/models';
import { amplitudeLog, getTranslationEndingByCount, timeFormatter } from '@/services';
import { IssueTrackerFilterValue } from '@/services/issueTracker/IssueTrackerFilterValue';
import WsMarkdownText from '@/components/common/WsMarkdownText.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IssueCommentDiff from '@/components/project/issueTracker/comments/IssueCommentDiff.vue';
import MemberAvatar from '@/components/common/MemberAvatar.vue';

enum DownloadFileType {
    Report = 'report',
    Dashboard = 'dashboard',
    Graph = 'graph',
}

@Component({
    components: {
        IconSvg16,
        IssueCommentDiff,
        MemberAvatar,
        WsMarkdownText,
    },
})
export default class NotificationItem extends Vue {
    @Prop() public record!: NotificationRecord;

    public readonly Color = Color;
    public readonly NotificationRecordType = NotificationRecordType;
    public readonly EventTypeEnum = EventTypeEnum;
    public readonly getTEndByCount = getTranslationEndingByCount;

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): string {
        return String(this.$store.getters.licenseByUuid(this.record.licenseUuid).id);
    }

    get projectId(): string {
        return String(this.record.projectId);
    }

    get isShowAuthor() {
        return this.record.authorEmail && this.record.authorEmail !== this.$store.getters.userData.email;
    }

    get issueSuccessString() {
        const ending = getTranslationEndingByCount(this.countSuccess);
        const params = { count: this.countSuccess };
        switch (this.record.eventType) {
            case EventTypeEnum.ISSUE_CREATED:
                return this.$t(`Notification.issuesAdded${ending}`, params);
            case EventTypeEnum.ISSUE_DELETED:
                return this.$t(`Notification.issuesDeleted${ending}`, params);
            case EventTypeEnum.ISSUE_RESTORED:
                return this.$t(`Notification.issuesRestored${ending}`, params);
            case EventTypeEnum.ISSUE_COMMENT_ADDED:
            case EventTypeEnum.ISSUE_EDITED:
                return this.$t(`Notification.issuesUpdated${ending}`, params);
            default:
                return '';
        }
    }

    get time(): string {
        const time = moment.utc(this.record.timestamp).local();
        return timeFormatter(time);
    }

    get isInProgress() {
        return this.record.isInProgress;
    }

    get isIssue() {
        return Boolean(EventClass.issues[this.record.eventType]);
    }

    get comments() {
        if (this.record.isSameIssueChanges) {
            return this.record.data?.[0]?.comments || [];
        }
        return [];
    }

    get reportTitle() {
        return this.record.data?.reportData?.reportTitle || '';
    }

    get dashboardTitle() {
        return this.record.data?.dashboardData?.dashboardTitle || '';
    }

    get graphDashboardTitle() {
        return this.record.data?.graphData?.dashboardTitle || '';
    }

    get graphTitle() {
        return this.record.data?.graphData?.graphTitle || '';
    }

    get successIssuesIds() {
        return this.isIssue
            ? this.record.data
                .filter((item: any) => item.status === NotificationRecordStatus.success)
                .map(({ issueId }: any) => String(issueId))
            : [];
    }

    get countSuccess() {
        return this.successIssuesIds.length;
    }

    get errorItems() {
        return this.isIssue
            ? this.record.data.filter((item: any) => item.status === NotificationRecordStatus.error)
            : [];
    }

    get errorItemsByErrorType() {
        const groups = _.groupBy(this.errorItems, 'errorCode');
        return _.entries(groups).map(([errorCode, arr]) => ({
            errorCode,
            errorMsg: arr[0].errorMsg,
            ids: arr.map((item) => String(item.issueId)),
        }));
    }

    get countError() {
        return this.errorItems.length;
    }

    get authorMember() {
        const projectMembers = this.$store.getters.projectMembersByProjectId(this.projectId);
        const licenseMembers = this.$store.getters.licenseMembersByLicenseId(this.licenseId);
        return projectMembers?.find((member: ProjectMember) => member.email === this.record.authorEmail)
            || licenseMembers.find((member: LicenseMember) => member.email === this.record.authorEmail)
            || {};
    }
    get authorName() {
        return this.authorMember.fullname || this.record.authorEmail;
    }

    @Emit()
    public close() {
        return;
    }

    public isRecordOneOf(...eventTypes: EventTypeEnum[]) {
        return eventTypes.includes(this.record.eventType);
    }

    public openSuccessIssues() {
        this.openIssuesInIssueTracker(this.successIssuesIds);
    }

    public async openIssuesInIssueTracker(ids: string[]) {
        const filters = {
            [IssuesFilterType.id]: new IssueTrackerFilterValue(IssuesFilterType.id, IssueFilterExpr.IN, ids),
        };

        await Promise.all([
            this.$store.dispatch('updateTrackerFilters', {
                projectId: this.projectId,
                filters,
            }),
            this.$router.push({
                name: RouterNames.ProjectIssueTracker,
                params: {
                    language: this.language,
                    licenseId: this.licenseId,
                    projectId: this.projectId,
                },
            }),
        ]);
        amplitudeLog(AmplitudeEvent.ncOpenIssue, { issuesCount: ids.length });

        this.close();
    }

    public async viewRevisions() {
        await this.$router.push({
            name: RouterNames.ProjectRevisions,
            params: {
                language: this.language,
                licenseId: this.licenseId,
                projectId: this.projectId,
            },
        });
        this.close();
        amplitudeLog(AmplitudeEvent.ncViewVersionsClick);
    }

    public downloadReport() {
        this.downloadEntity(this.record.data?.reportData?.url, DownloadFileType.Report);
    }

    public downloadDashboard() {
        this.downloadEntity(this.record.data.dashboardData.url, DownloadFileType.Dashboard);
    }

    public downloadGraph() {
        this.downloadEntity(this.record.data.graphData.url, DownloadFileType.Graph);
    }

    public downloadEntity(url: string, fileType: DownloadFileType) {
        if (url) {
            open(url);
            amplitudeLog(AmplitudeEvent.ncDownloadClick, { fileType });
        }
        this.close();
    }

    public async viewApprovalList() {
        await this.$router.push({
            name: RouterNames.LicenseMembersApproval,
            params: {
                language: this.language,
                licenseId: this.licenseId,
            },
        });
        this.close();
    }
}
