import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { stableStringify } from '@/services';

export class Categorize {
    /**
     * Принимает на вход массив объектов с одним ключом первого уровня типа [
     *      { 'vasya@pupkin.org': { method: 'abc', role: 3 } },
     *      { 'illegal@illegal.net': { method: 'illegal', role: -1 } },
     *      { 'petya@pupkin.org': { method: 'abc', role: 3 } },
     * ]
     * и раскидывает их на группы с одинаковым внутренним объектом. В примере выше будет две группы.
     * @param arr
     */
    public static arrayOfObjectsByValue(arr: Dict<Dict>[]): Array<{ keys: string[], properties: Dict }> {
        return _
            .chain(arr)
            .groupBy((obj) => stableStringify(_.values(obj)[0]))
            .map((value) => ({
                keys: value.map((obj) => _.keys(obj)[0]),
                properties: _.values(value[0])[0],
            }))
            .value();
    }
}
