import { Module } from 'vuex';
import { Amazon } from '@/services';

interface IAmazonStorageState {
    s3enabled: boolean;
    s3instances: { [key: number]: Amazon };
}

export default {
    state: {
        s3enabled: false,
        s3instances: {},
    } as IAmazonStorageState,
    getters: {
        s3enabled(state: IAmazonStorageState): boolean {
            return state.s3enabled;
        },
        s3InstanceByProjectId(state: IAmazonStorageState): (projectId: number) => Amazon {
            return (projectId) => state.s3instances[projectId];
        },
    },
    mutations: {
        setS3Instance(state: IAmazonStorageState, { projectId, instance }: { projectId: number, instance: Amazon}) {
            state.s3instances[projectId] = instance;
        },
    },
    actions: {
         setS3Instance({ commit }: any, projectId: number) {
            commit('setS3Instance', {
                projectId,
                instance: new Amazon({ projectId }),
            });
       },
    },
} as Module<IAmazonStorageState, any>;
