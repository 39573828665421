
















import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator';
@Component({})
export default class WsCheckbox2 extends Vue {
    @VModel({ type: Boolean }) checked!: boolean;
    @Prop() public label!: any;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public blocked!: boolean;
    @Prop({ type: Boolean, default: false }) public indeterminate!: boolean;

    @Emit()
    public change() {
        return !this.checked;
    }

    public toggle() {
        if (!this.disabled && !this.blocked) {
            this.checked = !this.checked;
            this.change();
        }
    }
}
