































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import { Color, TooltipPositionsType } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

let id = 0;

@Component({
    components: {
        WsTooltip,
        IconSvg16,
        IconSvg24,
    },
})
export default class WsButton extends Vue {
    @Prop({ type: Boolean, default: false }) public text!: boolean;
    @Prop() public tooltip!: VueI18n.TranslateResult; // appear if button enabled
    @Prop() public tooltipIfDisabled!: VueI18n.TranslateResult; // appear if button disabled
    @Prop({ type: Boolean }) public tooltipStable!: boolean; // this.tooltip should appear every time, ignore tooltipIfDisabled
    @Prop({ type: String }) public icon!: string; // IconSvg16/24 - доступна во всех размерах
    @Prop({ default: '16' }) public iconSize!: '16' | '24';
    @Prop({ type: String }) public iconColor!: string; // IconSvg color
    @Prop({ type: String }) public iconActionColor!: string; // IconSvg action color
    @Prop({ default: 400 }) public openDelay!: number;
    @Prop({ default: 'default' }) public type!: 'error' | 'danger' | 'warning' | 'info' | 'success' | 'primary';
    @Prop({ default: 'default' }) public size!: 'xlarge' | 'large' | 'default' | 'small' | 'xsmall';
    // button sizes: xlarge - 48px, large - 36px, default - 32px, small - 28px, xsmall - 20px
    @Prop() public placement!: TooltipPositionsType;

    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public loading!: boolean;
    @Prop({ type: Boolean, default: false }) public round!: boolean;
    @Prop({ type: Boolean, default: true }) public plain!: boolean;
    @Prop({ type: Boolean, default: true }) public outlined!: boolean;
    @Prop({ type: Boolean, default: false }) public contained!: boolean;
    // Так как по умолчанию в дизайне WS кнопки соответствуют стилю plain + outlined, добавил возможность отключать оба свойства через contained
    @Prop({ type: Boolean, default: false }) public circle!: boolean;

    // new design system 2023 props
    @Prop({ type: Boolean, default: false }) public new!: boolean;
    @Prop({ type: Boolean, default: false }) public secondary!: boolean;
    @Prop({ type: Boolean, default: false }) public tertiary!: boolean;
    @Prop({ type: Boolean, default: false }) public lowPriority!: boolean;
    @Prop({ type: Boolean, default: false }) public danger!: boolean;
    @Prop({ type: Boolean, default: false }) public small!: boolean;
    @Prop({ type: Boolean, default: false }) public large!: boolean;
    @Prop({ type: Boolean, default: false }) public xs!: boolean;

    public readonly Color = Color;

    public id = `ws-button-${id++}`;

    get isXSmallSize(): boolean {
        return this.size === 'xsmall';
    }

    get isSmallSize(): boolean {
        return this.size === 'small';
    }

    get isLargeSize(): boolean {
        return this.size === 'large';
    }

    get isXLargeSize(): boolean {
        return this.size === 'xlarge';
    }

    get tooltipContent() {
        if (this.tooltipStable) {
            return this.tooltip;
        }
        return this.disabled ? this.tooltipIfDisabled : this.tooltip;
    }

    /* new design system 2023 props */

    get isNew() {
        return this.new;
    }

    get spacing() {
        if (this.xs) {
            return 'xs';
        }
        if (this.small) {
            return 'small';
        }
        if (this.large) {
            return 'large';
        }
        return 'medium';
    }

    get buttonType() {
        if (this.secondary) {
            return 'secondary';
        }
        if (this.tertiary) {
            return 'tertiary';
        }
        if (this.lowPriority) {
            return 'low-priority';
        }
        if (this.danger) {
            return 'danger';
        }
        return 'primary';
    }

    get iconColorByType() {
        if (!this.isNew) {
            return '';
        }

        switch (this.buttonType) {
            case 'primary':
                return Color.darkBlue;
            case 'secondary':
            case 'tertiary':
            case 'low-priority':
                return Color.accent100;
            case 'danger':
                return Color.errorLight;
            default:
                return '';
        }
    }

    @Emit()
    public click(e: MouseEvent): MouseEvent {
        return e;
    }

    public onClick(e: MouseEvent) {
        if (this.disabled) {
            e.preventDefault();
            e.stopPropagation();

            return;
        }

        this.click(e);
    }
}
