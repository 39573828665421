export class FileReaderQueue {
    public reader: FileReader;
    public queue: any[];
    public isReading: boolean = false;

    constructor() {
        this.reader = new FileReader();
        this.queue = [];
        this.isReading = false;

        this.reader.onload = () => {
            const { resolve } = this.queue.shift();
            resolve(this.reader.result);
            this.isReading = false;
            this.processQueue();
        };

        this.reader.onerror = () => {
            const { reject } = this.queue.shift();
            reject(this.reader.error);
            this.isReading = false;
            this.processQueue();
        };
    }

    readFile(file: string) {
        return new Promise((resolve, reject) => {
            this.queue.push({ file, resolve, reject });
            this.processQueue();
        });
    }

    processQueue() {
        if (this.isReading || this.queue.length === 0) {
            return;
        }

        const { file } = this.queue[0];
        this.isReading = true;
        this.reader.readAsArrayBuffer(file);
    }
}
