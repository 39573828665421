



















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { PasswordSpec } from '@/constants';
import { getPasswordRequirements } from '@/models';
import { PasswordContains } from '@/services';

@Component
export default class PasswordMustContain extends Vue {
    @Prop({ type: String, default: '' }) public password!: string;

    public readonly bullet = String.fromCharCode(0x2B24);

    public isPasswordChanged = false;

    get requirements() {
        const passwordContains = new PasswordContains(this.password);
        const PasswordRequirements = getPasswordRequirements(this.isPasswordChanged);

        return new PasswordRequirements([
            [
                this.$t('Login.atLeastMCharsNotMoreThanNChars', {
                    m: PasswordSpec.MinimumLength,
                    n: PasswordSpec.MaximumLength,
                }),
                passwordContains.atLeastMCharsNotMoreThanNChars,
            ],
            [
                this.$t('Login.oneLowercase'),
                passwordContains.atLeastOneLowercaseLetter,
            ],
            [
                this.$t('Login.oneUppercase'),
                passwordContains.atLeastOneUppercaseLetter,
            ],
            [
                this.$t('Login.aNumber'),
                passwordContains.atLeastOneNumber,
            ],
        ]);
    }

    @Emit('is-valid-password')
    @Watch('password')
    public onPasswordChange() {
        this.isPasswordChanged = true;
        return this.requirements.areMet;
    }
}
