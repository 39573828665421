

































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        WsButton,
    },
})
export default class DialogBoardingContent extends Vue {
    @Prop({ required: true }) public title!: string;
    @Prop({ required: true }) public media!: string;
    @Prop({ required: true }) public description!: string;
    @Prop({ type: Boolean, default: false }) public downloadButton!: boolean;
    @Prop({ type: Boolean, default: false }) public video!: boolean;

    @Emit()
    public click() {
        return;
    }
}

