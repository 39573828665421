























































































































import _ from 'lodash';
import { Component, Watch } from 'vue-property-decorator';
import {
    AllSettledResult,
    AmplitudeEvent,
    BusEvent,
    ISSUES_PER_PAGE,
    IssueSortBase,
} from '@/constants';
import { Issue, License, Project } from '@/models';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, isFiltersForDeletedIssues, issuesSortApiParams, notificationSuccess, notificationWarning } from '@/services';
import WsSelect from '@/components/common/WsSelect.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import WsDialog from '@/components/common/WsDialog.vue';
import WsButton from '@/components/common/WsButton.vue';
import WsInput from '@/components/common/WsInput.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import IssueSort from '@/components/project/issueTracker/IssueSort.vue';
import TrackerColumnComponentBase from '@/components/project/issueTracker/columns/TrackerColumnComponentBase.vue';
import IssueList from '@/components/project/issueTracker/IssueList.vue';
import IssueColumn from '@/components/project/issueTracker/IssueColumn.vue';
import DialogDeleteIssues from '@/components/project/issueTracker/modals/DialogDeleteIssues.vue';
import IssuesPrintToPdfModal from '@/components/project/issueTracker/modals/IssuesPrintToPdfModal.vue';
import IssueItemColumnCheck from '@/components/project/issueTracker/IssueItemColumnCheck.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    name: 'TrackerIssues',
    components: {
        DialogDeleteIssues,
        IssueColumn,
        IssueList,
        IconSvg24,
        IssueSort,
        WsCheckbox,
        WsDialog,
        WsButton,
        WsSelect,
        WsInput,
        WsTooltip,
        IssuesPrintToPdfModal,
        IssueItemColumnCheck,
    },
})
export default class TrackerIssues extends TrackerColumnComponentBase {
    public sortOpened = false;
    public isConfirmRestore = false;
    public isVisibleDialogDeleteIssues = false;
    public itemsPerPage = ISSUES_PER_PAGE;
    public frameCount = 1;
    public issuesUuids: string[] = [];
    public isOpenPrintDialog = false;
    public issuesUuidsForPrint: string[] = [];
    public listHasScroll = false;

    public resizeObserver: ResizeObserver | undefined = undefined;
    public trackerIssues: HTMLElement | undefined = undefined;
    public printIssueHandler: (...args: any[]) => void = () => 1;

    get loading(): boolean {
        return this.$store.getters.isLoadingIssuesByProjectId(this.projectId);
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get projectUuid(): string {
        return this.currentProject.uuid;
    }

    get deletedIssues(): Issue[] {
        return this.$store.getters.deletedIssuesByProjectId(this.projectId);
    }

    get issueCount(): number | undefined {
        const value = this.$store.getters.issuesCountByProjectId(this.projectId);
        return this.loading ? undefined : value;
    }

    get isMultiselectEditModeActive(): boolean {
        return this.$store.getters.isMultiselectEditModeActive;
    }

    get multiSelectedIssues(): Issue[] {
        return this.$store.getters.multiSelectedIssues;
    }

    get isShowDeleteButton() {
        return Boolean(!this.isDeletedIssuesByProjectId && this.multiSelectedIssues.length);
    }

    get activeIssues(): Issue[] {
        return this.$store.getters.issuesPageByProjectId(this.projectId);
    }

    get currentPage() {
        return this.$store.getters.currentPageObjByProjectId(this.projectId);
    }

    get trackerFilters(): TrackerFilters {
        return this.$store.getters.trackerFiltersByProjectId(this.projectId);
    }

    get issues() {
        const issues = isFiltersForDeletedIssues(this.trackerFilters) ? this.deletedIssues : this.activeIssues;

        // Caching. This is necessary so that frameCount is not unnecessarily recalculated
        const uuids = issues.map((i: Issue) => i.uuid);
        if (_.difference(uuids, this.issuesUuids).length || _.difference(this.issuesUuids, uuids).length) {
            this.frameCount++;
            this.issuesUuids = uuids;
        }

        return issues;
    }

    get showMultiRestoreButton(): boolean {
        return this.multiSelectedIssues.length && this.isDeletedIssuesByProjectId;
    }

    get isDeletedIssuesByProjectId() {
        return this.$store.getters.isDeletedIssuesByProjectId(this.projectId);
    }

    get hasIssuesWithNewComments(): boolean {
        return Boolean(this.issues.find((issue: Issue) => issue.countUnreadComments > 0 || issue.isUnread));
    }

    get isIssueTrackerLink(): boolean {
        return Boolean(this.$store.getters.issueTrackerLink?.graphUuid || this.$store.getters.issueTrackerLink?.lineUuid);
    }

    get license(): License {
        return this.$store.getters.currentLicense;
    }

    get project(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get selectedIssue() {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    @Watch('issues.length', { immediate: true, deep: true })
    public onChangeLength() {
        this.checkListHasScroll();
    }

    public created() {
        this.printIssueHandler = ({ uuid }: { uuid: string }) => {
            this.issuesUuidsForPrint = [uuid];
            this.openPrint();
            amplitudeLog(AmplitudeEvent.itPrintOneIssue);
        };

        eventBus.$on(BusEvent.printIssue, this.printIssueHandler);
    }

    public mounted() {
        this.eventListeners.add({ node: document, event: 'keyup', handler: this.handleKeys });
        this.eventListeners.add({ node: document, event: 'keydown', handler: this.handleKeyDown });

        if (!this.loading && (!this.issues.length || this.isIssueTrackerLink)) {
            this.$store.dispatch('loadIssuesOrderByProjectId', { projectId: this.projectId })
                .then(() => {
                    this.$store.dispatch('loadIssuesByProjectId', { projectId: this.projectId });
                });
        }

        const resizeCallback = _.debounce(() => {
            if (!this.trackerIssues) {
                return;
            }

            eventBus.$emit(BusEvent.issuesColumnWidth, this.trackerIssues.clientWidth);
        }, 50);

        this.resizeObserver = new ResizeObserver(resizeCallback);
        this.trackerIssues = document.querySelector('.tracker-issues') as HTMLElement;
        this.resizeObserver.observe(this.trackerIssues);
    }

    public beforeDestroy() {
        if (this.resizeObserver && this.trackerIssues) {
            this.resizeObserver.unobserve(this.trackerIssues);
        }

        eventBus.$off(BusEvent.printIssue, this.printIssueHandler);
    }

    public handleKeys(event: KeyboardEvent) {
        if (!this.issues.length) {
            return;
        }
        const ISSUE_HEIGHT = 132;
        if (['TEXTAREA', 'INPUT'].includes((event.target as HTMLElement).nodeName)) {
            return;
        }

        const issueList: any = document.querySelector('.issue-list .items');
        const issuePerScreen = Math.floor(issueList.offsetHeight / ISSUE_HEIGHT);
        const scrolledIssues = Math.ceil(issueList.scrollTop / ISSUE_HEIGHT);

        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        let isScroll = false;

        const currentIssueIndex = this.issues.findIndex((issue: Issue) => issue.uuid === this.selectedIssue.uuid);
        let newIndex: number = currentIssueIndex;
        if (isDown) {
            newIndex = (currentIssueIndex === (this.issues.length - 1)) ? 0 : currentIssueIndex + 1;
            isScroll = newIndex === issuePerScreen + scrolledIssues;
        }
        if (isUp) {
            newIndex =  (currentIssueIndex === 0) ? (this.issues.length - 1) : currentIssueIndex - 1;
            isScroll = newIndex === scrolledIssues - 1;
        }

        const box: any = document.querySelector('.items');
        let top = newIndex * ISSUE_HEIGHT;

        if (isUp) {
            top = top - issuePerScreen * ISSUE_HEIGHT;
        }

        if (newIndex === 0) {
            top = 0;
            isScroll = true;
        }
        if (newIndex === (this.issues.length - 1)) {
            top = box.scrollHeight;
            isScroll = true;
        }

        if (isScroll) {
            box.scrollTop = top;
        }
        this.$store.commit('setSelectedIssue', { projectId: this.projectId, issue: this.issues[newIndex] });
    }

    public handleKeyDown(event: KeyboardEvent) {
        if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
            event.preventDefault();
        }
    }

    public async readAllUnseen() {
        await this.$store.dispatch('readAllUnseenIssues', {
            projectId: this.projectId,
            projectUuid: this.currentProject.uuid,
        });

        amplitudeLog(AmplitudeEvent.itMarkAllAsRead);

        this.issues.forEach((issue: Issue) => issue.read = { comments: 0, issue: false });
        this.$store.dispatch('loadIssueFilterPresetsUnread', {
            projectId: this.projectId,
            projectUuid: this.currentProject.uuid,
            isForce: true,
        });
    }

    public multiRestoreIssue() {
        let success = 0;
        let fail = 0;
        const undeleteRequests: any[] = [];

        this.multiSelectedIssues.forEach((issue: Issue) => {
            undeleteRequests.push(this.$store.dispatch('undeleteIssue', {
                projectId: this.projectId,
                issueUuid: issue.uuid,
            }));
        });

        Promise.allSettled(undeleteRequests).then((promisesResult: any[]) => {
            promisesResult.forEach((result) => {
                if (result.status === AllSettledResult.fulfilled) {
                    success++;
                } else {
                    fail++;
                }
            });

            if (success) {
                notificationSuccess('issuesSuccessfullyRestored', 1, { count: success });
            }

            if (fail) {
                notificationWarning('IssuesCantBeRestored', 1, { count: fail });
            }

            this.$store.commit('cleanMultiEditSelectedIssues');
            this.$store.dispatch('loadDeletedIssues', {
                projectId: this.projectId,
                params: { reportSort: issuesSortApiParams(IssueSortBase) },
            });
        });

        this.isConfirmRestore = false;
    }

    public printIssues() {
        this.issuesUuidsForPrint = this.multiSelectedIssues.map(({ uuid }) => uuid);
        this.openPrint();
        amplitudeLog(AmplitudeEvent.itPrintMultipleIssues, { count: this.multiSelectedIssues.length });
    }

    public openPrint() {
        this.isOpenPrintDialog = true;
    }

    public multiDeleteIssue() {
        this.isVisibleDialogDeleteIssues = false;
        this.$store.dispatch('multiDeleteIssues', this.projectId).then(() => {
            this.$store.dispatch('loadIssuesByProjectId', { projectId: this.projectId }).then(() => {
                this.$store.commit('setSelectedIssue', { projectId: this.projectId, issue: this.issues[0] });
            });
        });
    }

    public checkListHasScroll(): void {
        this.listHasScroll = (this.$refs.list as IssueList)?.hasScroll();
    }

    public openMultiCommentModal(): void {
        eventBus.$emit(BusEvent.showMultiIssueComment);
        amplitudeLog(AmplitudeEvent.itOpenMulticommentFromList, { count: this.multiSelectedIssues.length });
    }
}
