import _ from 'lodash';
import moment from 'moment';
import { Dict } from '@/types/Dict';
import { EventClass, EventTypeEnum, IssuesFilterType, MS_IN_SEC } from '@/constants';
import { Notification, NotificationGroup, UnreachableError } from '@/models';

export enum NotificationRecordStatus {
    in_progress = 'in_progress',
    success = 'success',
    error = 'error',
}

export enum NotificationRecordType {
    issue = 'issue',
    otherNormal = 'otherNormal',
    otherProgress = 'otherProgress',
}

enum CommentType {
    diff = 'diff',
    text = 'text',
    common = 'common',
}

function getType(type: CommentType) {
    switch (type) {
        case CommentType.diff:
            return CommentType.diff;
        case CommentType.text:
        case CommentType.common:
            return CommentType.text;
        default: {
            const _never: never = type;
            throw new UnreachableError(type);
        }
    }
}

export class NotificationRecord {
    public readonly operationId: string;
    public readonly projectId: number;
    public readonly projectTitle: string;
    public readonly type: NotificationRecordType;
    public readonly eventType: EventTypeEnum;
    public readonly authorEmail: string;
    public readonly timestamp: number;
    public readonly isInProgress: boolean;
    public readonly isLocal: boolean;
    public readonly licenseUuid: number;
    public readonly isSameIssueChanges?: boolean;
    public readonly countsWithComments: any[];
    public data: any;
    public isRead: boolean;

    constructor({
        operationId,
        projectId,
        projectTitle,
        licenseUuid,
        type,
        eventType,
        authorEmail,
        timestamp,
        data,
        isInProgress,
        isLocal,
        isSameIssueChanges,
        countsWithComments = [],
        isRead,
    }: any) {
        this.operationId = operationId;
        this.projectId = projectId;
        this.projectTitle = projectTitle;
        this.licenseUuid = licenseUuid;
        this.type = type;
        this.eventType = eventType;
        this.authorEmail = authorEmail;
        this.timestamp = timestamp * MS_IN_SEC;
        this.data = data;
        this.isInProgress = Boolean(isInProgress);
        this.isLocal = Boolean(isLocal);
        this.isSameIssueChanges = Boolean(isSameIssueChanges);
        this.countsWithComments = countsWithComments || [];
        this.isRead = Boolean(isRead);
    }

    get startOfDay() {
        return moment(this.timestamp).startOf('day').unix() * MS_IN_SEC;
    }

    get isDiff() {
        return this.data?.[0]?.comments?.length && this.data?.[0]?.comments[0]?.type === CommentType.diff;
    }

    get isText() {
        return this.data?.[0]?.comments?.length && this.data?.[0]?.comments[0]?.type === CommentType.text;
    }

    get isReport() {
        return Boolean(EventClass.report[this.eventType]);
    }

    get isProjectDashboard() {
        return Boolean(EventClass.projectDashboard[this.eventType]);
    }

    get isProjectGraph() {
        return Boolean(EventClass.projectGraph[this.eventType]);
    }

    get isLicenseDashboard() {
        return Boolean(EventClass.licenseDashboard[this.eventType]);
    }

    get isLicenseGraph() {
        return Boolean(EventClass.licenseGraph[this.eventType]);
    }

    get isExtendedReport() {
        return this.isReport
            || this.isProjectDashboard
            || this.isProjectGraph
            || this.isLicenseDashboard
            || this.isLicenseGraph;
    }

    get isOtherNormal() {
        return Boolean(EventClass.otherNormal[this.eventType]);
    }

    get isIssue() {
        return Boolean(EventClass.issues[this.eventType]);
    }

    public read() {
        if (this.isLocal) {
            this.isRead = true;
        }
    }

    public static createArrayFromNotificationGroup(group: NotificationGroup) {
        if (group.isIssue) {
            return getSplittedGroup(group).map(NotificationRecord.createFromNotificationIssueGroup);
        } else if (group.isOtherNormal) {
            return [NotificationRecord.createFromNotificationOtherNormalGroup(group)];
        } else if (group.isOtherProgress) {
            return [NotificationRecord.createFromNotificationOtherProgressGroup(group)];
        }
    }

    public static createFromNotificationOtherNormalGroup(group: NotificationGroup): NotificationRecord {
        const operationId = group.operationId;
        const type = NotificationRecordType.otherNormal;
        const { author, createdAt, eventType, licenseUuid, projectId, projectTitle } = group.notifications[0];
        const { isRead } = group;
        const data = group.notifications.map((notification: Notification) => notification.data);
        const timestamp = new Date(createdAt).getTime() / MS_IN_SEC;
        const authorEmail = author;
        return new NotificationRecord({
            operationId,
            licenseUuid,
            projectId,
            projectTitle,
            isRead,
            type,
            eventType,
            authorEmail,
            timestamp,
            data,
        });
    }

    public static createFromNotificationOtherProgressGroup(group: NotificationGroup): NotificationRecord {
        const operationId = group.operationId;
        const type = NotificationRecordType.otherProgress;
        const notification = _.maxBy(group.notifications, 'eventType') as Notification;
        const { author, createdAt, eventType, licenseUuid, projectId, projectTitle, data, isInProgress } = notification;
        const { isRead } = group;
        const timestamp = new Date(createdAt).getTime() / MS_IN_SEC;
        const authorEmail = author;
        return new NotificationRecord({
            operationId,
            licenseUuid,
            projectId,
            projectTitle,
            isRead,
            type,
            eventType,
            authorEmail,
            timestamp,
            data,
            isInProgress,
        });
    }

    public static createFromNotificationIssueGroup(group: NotificationGroup): NotificationRecord {
        const countsWithComments = group.issueVariantsWithCount.map(({ count, issueChangesVariant }) => {
            const [field, change] = getChangePair(issueChangesVariant);
            return {
                count,
                comments: [{
                    reporter: issueChangesVariant.author.email,
                    diff: {
                        [field]: change,
                    },
                    text: issueChangesVariant.text,
                }],
            };
        });
        const operationId = group.operationId;
        const notification = group.notifications[0];
        const { author, createdAt, eventType, licenseUuid, projectId, projectTitle } = notification;
        const { isRead } = group;
        const type = NotificationRecordType.issue;
        const timestamp = new Date(createdAt).getTime() / MS_IN_SEC;
        const authorEmail = author;
        const data = group.notifications.map(({ author: reporter, data: { changes, issueId }, createdAt: created }) => {
            const isChangesNonEmptyArray = _.isArray(changes) && changes.length;
            const status = NotificationRecordStatus.success;
            const comments = [{
                reporter,
                diff: isChangesNonEmptyArray ? _.fromPairs(changes.map(getChangePair)) : {},
                created,
                text: changes?.text || '',
                type: isChangesNonEmptyArray ? getType(changes[0].type) : null,
            }];
            return {
                status,
                issueId,
                comments,
            };
        });
        return new NotificationRecord({
            operationId,
            licenseUuid,
            projectId,
            projectTitle,
            isRead,
            type,
            eventType,
            authorEmail,
            timestamp,
            data,
            isSameIssueChanges: group.isSameIssueChanges, // all the diffs are the same for different notifications for one operationId
            countsWithComments,
        });
    }
}

function getChangePair({ field, change }: Dict) {
    if ([IssuesFilterType.assignee, IssuesFilterType.reporter].includes(field)) {
        const transformedChange = {
            new: change.new?.email || '',
            old: change.old?.email || '',
        };
        return [field, transformedChange];
    }
    return [field, change];
}

function getSplittedGroup(group: NotificationGroup) {
    const { notifications } = group;

    const createdNotifications = _.filter(notifications, { eventType: EventTypeEnum.ISSUE_CREATED });
    const commentNotifications = _.filter(notifications, { eventType: EventTypeEnum.ISSUE_COMMENT_ADDED });
    const otherNotifications = _.differenceBy(
        notifications,
        [...createdNotifications, ...commentNotifications],
        'eventType',
    );
    const createdGroup = new NotificationGroup({ operationId: group.operationId + '_created', notifications: createdNotifications });
    const commentGroup = new NotificationGroup({ operationId: group.operationId + '_comment', notifications: commentNotifications });
    const otherGroup = new NotificationGroup({ operationId: group.operationId + '_other', notifications: otherNotifications });
    return [createdGroup, commentGroup, otherGroup].filter((g) => g.notifications.length);
}
