import _ from 'lodash';
import { invertArrayOfStrings } from '@/services/ArrayService';
import { getCompareObjectsAlphanumericallyBySortValue } from '@/services/Comparators';

function reduceOr<T>(arr: T[], defaultValue: T) {
    return arr.reduce((acc, item) => acc || item, arr[0]) || defaultValue;
}

export function sortByKeysArray<T>(items: T[], keys: string[], ...sortKeys: string[]): T[] {
    if (!sortKeys.length) {
        sortKeys = ['sortKey'];
    }

    const makeGetSortValue = (sortKey = '') => (item: T): string => (sortKey ? _.get(item, sortKey) : item) || '';
    const sortKeyPrimary = sortKeys[0];
    const getSortValuePrimary = makeGetSortValue(sortKeyPrimary);

    const indexByKey = invertArrayOfStrings(keys);

    const getIndex = (item: T, sortKey: string) => {
        const getSortValue = makeGetSortValue(sortKey);
        const sortValue = getSortValue(item);
        return indexByKey[sortValue] ?? Infinity;
    };
    const compareBySortKey = (itemA: T, itemB: T) => (sortKey: string) => {
        return getIndex(itemA, sortKey) - getIndex(itemB, sortKey) || 0;
    };
    const getCompareByKeys = (itemA: T, itemB: T) => sortKeys.map(compareBySortKey(itemA, itemB));
    const compareByKeys = (itemA: T, itemB: T) => reduceOr(getCompareByKeys(itemA, itemB), 0);
    const itemsSortableByKeys = items.filter((item) => _.has(indexByKey, getSortValuePrimary(item)));
    const itemsSortedByKeys = itemsSortableByKeys.slice().sort(compareByKeys);

    const getCompareAlphanumerically = (itemA: T, itemB: T) => sortKeys
        .map((sortKey) => getCompareObjectsAlphanumericallyBySortValue(makeGetSortValue(sortKey)))
        .map((comparator) => comparator(itemA, itemB));

    const compareAlphanumerically = (itemA: T, itemB: T) => {
        const comparisons = getCompareAlphanumerically(itemA, itemB);
        return reduceOr(comparisons, 0);
    };
    const itemsSortableAlphanumerically = _.differenceBy(items, itemsSortableByKeys, getSortValuePrimary);
    const itemsSortedAlphanumerically = itemsSortableAlphanumerically.slice().sort(compareAlphanumerically);

    return itemsSortedByKeys.concat(itemsSortedAlphanumerically);
}
