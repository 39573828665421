import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { IStatusItem } from '@/types/workflow';
import { IMultiselectNewValueItem, MapOfIssuesNeedingStatusChangeByTypes } from '@/types/issue-tracker';
import { isMultiEditIssueFieldPayload } from '@/types/MultiEditIssueFieldPayload';
import { MutableIssueField } from '@/types/MutableIssueField';
import { Dict } from '@/types/Dict';
import router from '@/router';
import { DEFAULT_ISSUE_TYPE_UUID } from '@/constants/issue/customIssueType';
import {
    AllSettledResult,
    AmplitudeEvent,
    BusEvent,
    deletedIssuesPresetUuid,
    IssueDetailsInputType,
    IssuePriorityEnum,
    IssueTrackerFields,
    MomentFormats,
    NAVISWORKS_TYPE_UUID,
    NotifierUserID,
    PermissionNameByIssueField,
    RESPONSE,
} from '@/constants';
import { CustomStatus, Issue, Workflow } from '@/models';
import { eventBus } from '@/services/eventBus';
import {
    amplitudeLog,
    createDiff,
    createDiffCommentObject,
    notificationSuccess,
    notificationWarning,
} from '@/services';
import { filterIssues } from '@/storage/projectIssues.storage';

const CLOSED_BY_MULTISELECT_COLUMNS = [
    'TrackerPresets',
    'TrackerPreview',
    'TrackerChat',
];

interface MultiSelectStorage {
    multiEditNewValues: Dict<IMultiselectNewValueItem>;
    isLoadingMultiEdit: boolean;
    selectedIssuesUuids: string[];
    multiEditStatusReplaceMaps: Dict<Dict<string>> | null;
}

interface IMultiselectFieldsValues {
    [IssueTrackerFields.title]: string[];
    [IssueTrackerFields.customStatus]: string[];
    [IssueTrackerFields.customType]: string[];
    [IssueTrackerFields.status]: string[];
    [IssueTrackerFields.stampAbbr]: string[];
    [IssueTrackerFields.stampColor]: number[];
    [IssueTrackerFields.priority]: IssuePriorityEnum[];
    [IssueTrackerFields.deadline]: Moment[];
    [IssueTrackerFields.assignee]: string[];
    [IssueTrackerFields.reporter]: string[];
    [IssueTrackerFields.watchers]: string[];
    [IssueTrackerFields.tags]: string[];
    [IssueTrackerFields.visibility]: boolean[];
}

interface WorkflowIssuesItem {
    issues: Issue[];
    workflow: Workflow;
}

enum rows {
    value = 'value',
    sourceValue = 'sourceValue',
    row = 'row',
    type = 'type',
    currentUserEmail = 'currentUserEmail',
}

export default {
    state: {
        multiEditNewValues: {},
        isLoadingMultiEdit: false,
        selectedIssuesUuids: [],
        multiEditStatusReplaceMaps: null,
    } as MultiSelectStorage,
    getters: {
        isMultiEditLoading(state: MultiSelectStorage): boolean {
            return state.isLoadingMultiEdit;
        },
        multiSelectedIssues(state: MultiSelectStorage, getters, rootState, rootGetters): Issue[] {
            const projectId = Number(router.currentRoute.params.projectId);
            const filterPresetUuid = rootGetters.selectedIssueFilterPresetByProjectId(projectId)?.uuid;

            if (filterPresetUuid === deletedIssuesPresetUuid) {
                return state.selectedIssuesUuids
                    .map((issueUuid): Issue => rootGetters.deletedIssuesByProjectIdObj(projectId)[issueUuid])
                    .filter(Boolean) ?? [];
            }

            return state.selectedIssuesUuids
                .map((issueUuid): Issue => rootGetters.allIssuesByProjectIdObj(projectId)[issueUuid])
                .filter(Boolean) ?? [];
        },
        isMultiselectEditModeActive(state: MultiSelectStorage): boolean {
            return state.selectedIssuesUuids.length > 1;
        },
        isColumnClosed(state: MultiSelectStorage, getters): (column: string) => boolean {
            return (column) => getters.isMultiselectEditModeActive && CLOSED_BY_MULTISELECT_COLUMNS.includes(column);
        },
        multiEditNewValues(state: MultiSelectStorage): Dict<IMultiselectNewValueItem> {
            return state.multiEditNewValues;
        },
        multiSelectIssueValues(state: MultiSelectStorage, getters): any {
            const result: IMultiselectFieldsValues = {
                [IssueTrackerFields.title]: [],
                [IssueTrackerFields.status]: [],
                [IssueTrackerFields.customStatus]: [],
                [IssueTrackerFields.customType]: [],
                [IssueTrackerFields.stampAbbr]: [],
                [IssueTrackerFields.stampColor]: [],
                [IssueTrackerFields.priority]: [],
                [IssueTrackerFields.deadline]: [],
                [IssueTrackerFields.assignee]: [],
                [IssueTrackerFields.reporter]: [],
                [IssueTrackerFields.watchers]: [],
                [IssueTrackerFields.tags]: [],
                [IssueTrackerFields.visibility]: [],
            };

            getters.multiSelectedIssues.forEach((issue: Issue) => {
                if (issue.customStatus) {
                    result[IssueTrackerFields.customStatus].push(issue.customStatus);
                    result[IssueTrackerFields.customType].push(issue.customType);
                } else {
                    result[IssueTrackerFields.status].push(issue.status);
                }

                result[IssueTrackerFields.title].push(issue.title);
                result[IssueTrackerFields.stampAbbr].push(issue.stampAbbr);
                result[IssueTrackerFields.stampColor].push(issue.stampColor);
                result[IssueTrackerFields.priority].push(issue.priority);
                result[IssueTrackerFields.deadline].push(issue.deadline);
                result[IssueTrackerFields.assignee].push(issue.assignee);
                result[IssueTrackerFields.reporter].push(issue.reporter);

                if (!result[IssueTrackerFields.watchers].find((watchers: string) => {
                    const splitedWatchers = watchers.split(',');

                    if (splitedWatchers.length !== issue.watchers.length) {
                        return false;
                    }

                    if (watchers.split(',').every((watcher: string) => issue.watchers.includes(watcher))) {
                        return true;
                    }

                    if (issue.watchers.every((watcher: string) => watchers.split(',').includes(watcher))) {
                        return true;
                    }

                    return;
                })) {
                    result[IssueTrackerFields.watchers].push(issue.watchers.join(','));
                }

                result[IssueTrackerFields.tags].push(JSON.stringify(issue.tags));
                result[IssueTrackerFields.visibility].push(issue.visibility);
            });

            Object.keys(result).forEach((k: string) => {
                const key = k as keyof IMultiselectFieldsValues;
                result[key] = _.uniq<any>(result[key]);

                if (key !== IssueTrackerFields.visibility) {
                    result[key] = (result[key] as any[]).filter((value: any) => value);
                }
            });

            return result;
        },
        getMultiEditStatusReplaceMaps(state: MultiSelectStorage) {
            return state.multiEditStatusReplaceMaps;
        },
        getNewValuesPermissionsErrors(state: MultiSelectStorage, getters): (userEmail: string, permissions: Dict<number>) => Dict<Issue[]> {
            return (userEmail: string, permissions: Dict<number>) => {
                const issues = getters.multiSelectedIssues as Issue[];
                const values = getters.multiEditNewValues as Dict<IMultiselectNewValueItem>;

                return Object.keys(values).reduce((acc: Dict, key: string) => {
                    const permissionForField = PermissionNameByIssueField[key];

                    if (!permissionForField) {
                        return acc;
                    }

                    acc[key] = issues.filter((issue: Issue) => {
                        return !issue.hasPermissions(userEmail, permissions[permissionForField]);
                    });

                    return acc;
                }, {});
            };
        },
        /**
         * Case when user in multiselect mode and select custom status, but not select custom type
         * and some issues has custom type not compatible with selected status
         */
        issuesHasConflictWithSelectedStatus(state: MultiSelectStorage, getters: any, _, rootGetters: any): (projectUuid: number) => Issue[] {
            return (projectUuid: number) => {
                if (!getters.isMultiselectEditModeActive) {
                    return [];
                }

                if (getters.multiEditNewValues[IssueTrackerFields.customType]?.value) {
                    return [];
                }

                const selectedStatusUuid = getters.multiEditNewValues[IssueTrackerFields.customStatus]?.value;

                if (!selectedStatusUuid) {
                    return [];
                }

                if (getters.getMultiEditStatusReplaceMaps) {
                    return [];
                }

                const allSelectedIssues = getters.multiSelectedIssues as Issue[];
                const issuesByWorkflow = allSelectedIssues.reduce((acc, issue) => {
                    const workflow = rootGetters.workflowByCustomTypeUuid(projectUuid, issue.customType) as Workflow;
                    if (!acc[workflow.uuid]) {
                        acc[workflow.uuid] = {
                            issues: [issue],
                            workflow,
                        } as WorkflowIssuesItem;
                        return acc;
                    }

                    acc[workflow.uuid].issues.push(issue);
                    return acc;
                }, {} as Dict<WorkflowIssuesItem>);

                const issuesWithProblems: Issue[] = [];

                Object.values(issuesByWorkflow).forEach((workflowIssuesItem) => {
                    const statusesUuids = workflowIssuesItem.workflow.statuses.map((status: IStatusItem) => status.uuid);

                    if (statusesUuids.includes(selectedStatusUuid)) {
                        return;
                    }

                    issuesWithProblems.push(...workflowIssuesItem.issues);
                });

                return issuesWithProblems;
            };
        },
        /**
         * Case when user in multiselect mode and select custom status, but the selected status is not allowed by permissions
         */
        issuesHasNotAllowedToChangeStatusCategoryDone(state: MultiSelectStorage, getters: any, _, rootGetters: any): (projectUuid: string, projectId: number) => Issue[] {
            return (projectUuid: string, projectId: number) => {
                if (!getters.isMultiselectEditModeActive) {
                    return [];
                }

                if (getters.multiEditNewValues[IssueTrackerFields.customType]?.value) {
                    return [];
                }

                const selectedStatusUuid = getters.multiEditNewValues[IssueTrackerFields.customStatus]?.value;

                if (!selectedStatusUuid) {
                    return [];
                }

                const customStatus = rootGetters.customIssueStatusByUuid(projectUuid, selectedStatusUuid) as CustomStatus;

                if (!customStatus.isCompletedCategory) {
                    return [];
                }

                const userEmail = rootGetters.userData.email;
                const project = rootGetters.projectById(projectId);
                const allSelectedIssues = getters.multiSelectedIssues as Issue[];

                return allSelectedIssues.filter((issue) => {
                    return !issue.hasPermissions(userEmail, project.permissions.closeIssue);
                });
            };
        },
        /**
         * User can first select custom status and then select not compatible type. We should show warning
         */
        selectedTypeNotCompatibleWithSelectedStatus(
            state: MultiSelectStorage,
            getters: any,
            rootState,
            rootGetters: any,
        ): (projectUuid: number) => boolean {
            return (projectUuid: number) => {
                const selectedType = state.multiEditNewValues[IssueTrackerFields.customType]?.value;
                const selectedStatus = state.multiEditNewValues[IssueTrackerFields.customStatus]?.value;
                if (!selectedType || !selectedStatus) {
                    return false;
                }

                const workflow = rootGetters.workflowByCustomTypeUuid(projectUuid, selectedType) as Workflow;

                return !workflow.statuses.find((status: IStatusItem) => status.uuid === selectedStatus);
            };
        },
        getMapOfIssuesNeedingStatusChangeByTypes(
            state: MultiSelectStorage,
            getters: any,
            _,
            rootGetters: any,
        ): (projectUuid: number) => MapOfIssuesNeedingStatusChangeByTypes {
            return (projectUuid: number) => {
                const result: MapOfIssuesNeedingStatusChangeByTypes = { issuesCount: 0, maps: {} };

                if (!getters.isMultiselectEditModeActive) {
                    return result;
                }

                if (getters.getMultiEditStatusReplaceMaps) {
                    return result;
                }

                const selectedStatusUuid = getters.multiEditNewValues[IssueTrackerFields.customStatus]?.value;
                const selectedTypeUuid = getters.multiEditNewValues[IssueTrackerFields.customType]?.value;

                if (selectedStatusUuid || !selectedTypeUuid) {
                    // If user select status and type it's need prevent apply and no need of a map for status change.
                    // If user not select type we can't make map for status replacement.
                    return result;
                }

                const targetWorkflow = rootGetters.workflowByCustomTypeUuid(projectUuid, selectedTypeUuid) as Workflow;
                const targetStatusesUuids = targetWorkflow.statuses.map((status: IStatusItem) => status.uuid);

                return getters.multiSelectedIssues.reduce((acc: any, issue: Issue) => {
                    if (issue.customType === selectedTypeUuid) {
                        return acc;
                    }

                    if (targetStatusesUuids.includes(issue.customStatus)) {
                        return acc;
                    }

                    acc.issuesCount++;

                    if (!acc.maps[issue.customType]) {
                        acc.maps[issue.customType] = {
                            issuesCount: 1,
                            statuses: { [issue.customStatus]: [issue] },
                        };
                        return acc;
                    }

                    acc.maps[issue.customType].issuesCount++;

                    if (!acc.maps[issue.customType].statuses[issue.customStatus]) {
                        acc.maps[issue.customType].statuses[issue.customStatus] = [issue];
                        return acc;
                    }

                    acc.maps[issue.customType].statuses[issue.customStatus].push(issue);
                    return acc;
                }, result);
            };
        },
        getNavisIssuesTryingChangeType(state: MultiSelectStorage, getters: any): (projectUuid: number) => Issue[] {
            return () => {
                if (!getters.isMultiselectEditModeActive) {
                    return [];
                }

                const selectedTypeUuid = state.multiEditNewValues[IssueTrackerFields.customType]?.value;

                if (!selectedTypeUuid) {
                    return [];
                }

                return getters.multiSelectedIssues.filter((issue: Issue) => {
                    return issue.customType === NAVISWORKS_TYPE_UUID;
                });
            };
        },
    },
    mutations: {
        setIsLoadingMultiEdit(state: MultiSelectStorage, value: boolean) {
            state.isLoadingMultiEdit = value;
        },
        addIssueToMultiSelect(state: MultiSelectStorage, issue: Issue) {
            const indexOfIssue = state.selectedIssuesUuids.indexOf(issue.uuid);

            if (indexOfIssue !== -1) {
                return;
            }

            state.selectedIssuesUuids.push(issue.uuid);
        },
        removeIssueFromMultiSelect(state: MultiSelectStorage, deletedIssue: Issue) {
            const indexOfDeletedIssue = state.selectedIssuesUuids.indexOf(deletedIssue.uuid);

            if (indexOfDeletedIssue === -1) {
                return;
            }

            state.selectedIssuesUuids.splice(indexOfDeletedIssue, 1);
        },
        addMultiEditValue(state: MultiSelectStorage, values: IMultiselectNewValueItem) {
            Vue.set(state.multiEditNewValues, values.row, values);
        },
        removeMultiEditValue(state: MultiSelectStorage, row: IssueTrackerFields) {
            Vue.delete(state.multiEditNewValues, row);
        },
        clearMultiEditValue(state: MultiSelectStorage) {
            Vue.set(state, 'multiEditNewValues', {});
        },
        cleanMultiEditSelectedIssues(state: MultiSelectStorage) {
            state.selectedIssuesUuids = [];
        },
        multiSelectAllIssues(state: MultiSelectStorage, allIssues: Issue[]) {
            state.selectedIssuesUuids = allIssues.map(({ uuid }: Issue) => uuid);
        },
        saveMultiEditStatusReplaceMaps(state: MultiSelectStorage, maps: Dict<Dict<string>> | null) {
            state.multiEditStatusReplaceMaps = maps;
        },
    },
    actions: {
        setMultiSelectEditMode({ dispatch }: ActionContext<MultiSelectStorage, any>, value: boolean) {
            if (!value) {
                dispatch('clearMultiSelect');
            }
        },
        handleNewMultiSelect({ commit }: ActionContext<MultiSelectStorage, any>, issue: Issue) {
            commit('cleanMultiEditSelectedIssues');
            commit('addIssueToMultiSelect', issue);
        },
        handleIssueMultiSelect({ state, commit, getters }: ActionContext<MultiSelectStorage, any>, issue: Issue) {
            if (getters.multiSelectedIssues.find((currentIssue: Issue) => currentIssue.id === issue.id)) {
                commit('removeIssueFromMultiSelect', issue);
            } else {
                commit('addIssueToMultiSelect', issue);
            }

            if (state.selectedIssuesUuids.length <= 1) {
                commit('clearMultiEditValue');
            }
        },
        applyMultiEditValues({ state, commit, dispatch, getters, rootGetters }: ActionContext<MultiSelectStorage, any>, projectId: number) {
            return new Promise((resolve) => {
                commit('setIsLoadingMultiEdit', true);

                const requests: any[] = [];
                const newValues: Dict<Dict> = {};
                let commentUuidsAll: string[] = [];
                const commentsUuidsByIssues: Dict<string> = {};
                const time = moment().utc().format(MomentFormats.serverSide);
                const diffFields: Set<string> = new Set();
                const replacedStatusesByIssueUuid: Dict<string> = {};

                getters.multiSelectedIssues.forEach((issue: Issue) => {
                    const diff: any = {};
                    let currentUserEmail = '';

                    Object.keys(state.multiEditNewValues).forEach((key: string) => {
                        if (!_.isEqual((issue as any)[key], state.multiEditNewValues[key][rows.value])) {
                            let value = state.multiEditNewValues[key][rows.value];

                            if ([MutableIssueField.watchers, MutableIssueField.tags].includes(key as MutableIssueField) && isMultiEditIssueFieldPayload(value)) {
                                value = [...issue[key as keyof Issue]];

                                state.multiEditNewValues[key][rows.value].add.forEach((addValue: string) => {
                                    if (!value.includes(addValue)) {
                                        value.push(addValue);
                                    }
                                });

                                state.multiEditNewValues[key][rows.value].remove.forEach((removeValue: string) => {
                                    value = value.filter((val: any) => val !== removeValue);
                                });

                                if (_.isEqual((issue as any)[key], value)) {
                                    return;
                                }
                            }

                            const isTypeField = key === MutableIssueField.customType;
                            const replaceMapForCurrentIssueType = getters.getMultiEditStatusReplaceMaps?.[issue.customType];

                            if (isTypeField
                                && !state.multiEditNewValues[MutableIssueField.customStatus]?.[rows.value]
                                && replaceMapForCurrentIssueType
                            ) {
                                const replacedStatusUuid = replaceMapForCurrentIssueType[issue.customStatus];
                                diff[MutableIssueField.customStatus] = createDiff({
                                    row: MutableIssueField.customStatus,
                                    type: IssueDetailsInputType.select,
                                    sourceValue: issue.customStatus,
                                    value: replaceMapForCurrentIssueType[issue.customStatus],
                                });

                                replacedStatusesByIssueUuid[issue.uuid] = replacedStatusUuid;
                            }

                            diff[state.multiEditNewValues[key][rows.row]] = createDiff({
                                row: state.multiEditNewValues[key][rows.row],
                                type: state.multiEditNewValues[key][rows.type],
                                sourceValue: (issue as any)[key],
                                value,
                            });

                            // diff fields for amplitude events.
                            _.keys(diff).forEach((field) => diffFields.add(field));

                            _.set(newValues, `${issue.uuid}.${key}`, value);
                        }

                        currentUserEmail = state.multiEditNewValues[key][rows.currentUserEmail];
                    });

                    if (_.isEmpty(diff)) {
                        return;
                    }

                    const commentsObj = createDiffCommentObject({
                        diff,
                        [rows.currentUserEmail]: currentUserEmail,
                        time,
                    });

                    Object.keys(commentsObj).forEach((diffUuid) => {
                        commentsUuidsByIssues[diffUuid] = issue.uuid;
                    });

                    commentUuidsAll = [...commentUuidsAll, ..._.keys(commentsObj)];

                    const contentToSend = {
                        project_id: projectId,
                        issue_uuid: issue.uuid,
                        comments: JSON.stringify(commentsObj),
                        [NotifierUserID]: rootGetters.notifierActorId,
                    };

                    requests.push(dispatch('addComments', { issueUuid: issue.uuid, contentToSend }));
                });

                const successDiffs: string[] = [];
                const failedDiffs: string[] = [];

                Promise.allSettled(requests).then((results: any[]) => {
                    results.forEach((response: any) => {
                        if (response.status === AllSettledResult.fulfilled && response.value[0] && response.value[0].result === RESPONSE.SUCCESS) {
                            const commentUuids = response.value.map((item: any) => item.data.uuid);
                            commit('markRecordsAsSuccess', commentUuids);
                            successDiffs.push(response.value[0].data.uuid);
                        } else {
                            if (response?.value?.[0]) {
                                const payload = response.value.map((item: any) => ({
                                    uuid: item.data.uuid,
                                    errorCode: item.result,
                                    errorMsg: item.message,
                                }));

                                commit('markRecordsAsError', payload);
                            }

                            failedDiffs.push(response?.value?.[0]?.data?.uuid || response?.reason?.code);
                        }
                    });

                    if (successDiffs.length > 0) {
                        const filters = rootGetters.trackerFiltersByProjectId(projectId);

                        const issuesWithSuccessDiffs = successDiffs.map((successDiff) => {
                            return getters.multiSelectedIssues.find((issue: Issue) => issue.uuid === commentsUuidsByIssues[successDiff]);
                        });

                        issuesWithSuccessDiffs.forEach((issue: Issue) => {
                            if (!issue) {
                                return;
                            }

                            let changeCount = 0;
                            if (filterIssues([issue], filters).length) {
                                changeCount--;
                            }

                            _.keys(state.multiEditNewValues).forEach((key: string) => {
                                if (!_.isEqual((issue as any)[key], state.multiEditNewValues[key][rows.value])) {
                                    issue.setFieldValue(key, newValues[issue.uuid][key]);
                                }
                            });

                            if (replacedStatusesByIssueUuid[issue.uuid]) {
                                issue.setFieldValue(MutableIssueField.customStatus, replacedStatusesByIssueUuid[issue.uuid]);
                            }

                            if (filterIssues([issue], filters).length) {
                                changeCount++;
                                dispatch('enableIssueFromOrder', { projectId, issueUuid: issue.uuid });
                            } else {
                                dispatch('disableIssueFromOrder', { projectId, issueUuid: issue.uuid });
                            }

                            const issuesCount = rootGetters.issuesCountByProjectId(projectId);
                            commit('setIssuesCount', { projectId, issuesCount: issuesCount + changeCount });
                        });
                    }

                    if (failedDiffs.length) {
                        notificationWarning('IssuesCantBeUpdated', 1, { count: failedDiffs.length }, true);
                    }

                    commit('clearMultiEditValue');
                    commit('cleanMultiEditSelectedIssues');
                    commit('saveMultiEditStatusReplaceMaps', null);
                    commit('resolveRecordsInProgress', commentUuidsAll);
                    eventBus.$emit(BusEvent.cancelIssueEdit);
                    resolve();
                }).finally(() => {
                    commit('setIsLoadingMultiEdit', false);
                    amplitudeLog(AmplitudeEvent.itIssueEdit, { fieldName: [...diffFields], issuesEdited: requests.length });
                });
            });
        },
        applySingleEditValue(
          { commit, dispatch, rootGetters }: ActionContext<MultiSelectStorage, any>,
          {
              projectId,
              issue,
              payload,
          }: {
              projectId: number;
              issue: Issue;
              payload: {
                  row: string;
                  type: string;
                  sourceValue: any;
                  value: any;
                  currentUserEmail: string;
              };
          },
        ) {
            return new Promise((resolve, reject) => {
                commit('setIsLoadingMultiEdit', true);

                const time = moment().utc().format(MomentFormats.serverSide);
                const diff: any = {};
                diff[payload.row] = createDiff(payload);

                if (_.isEqual((issue as any)[payload.row], payload.value) || _.isEmpty(diff)) {
                    return reject();
                }

                if (payload.row === MutableIssueField.customStatus && !rootGetters.currentLicense.isWorkflowEnabled && issue.customType !== DEFAULT_ISSUE_TYPE_UUID) {
                    // For case when license lose Revizto Plus status and user try to change status of issue with custom type
                    // need to change type to default
                    diff[MutableIssueField.customType] = createDiff({
                        row: MutableIssueField.customType,
                        sourceValue: issue.customType,
                        value: DEFAULT_ISSUE_TYPE_UUID,
                        currentUserEmail: payload.currentUserEmail,
                        type: IssueDetailsInputType.select,
                    });
                }

                const commentsObj = createDiffCommentObject({
                    diff,
                    currentUserEmail: payload.currentUserEmail,
                    time,
                });

                const contentToSend = {
                    project_id: projectId,
                    issue_uuid: issue.uuid,
                    comments: JSON.stringify(commentsObj),
                    [NotifierUserID]: rootGetters.notifierActorId,
                };

                dispatch('addComments', {
                    issueUuid: issue.uuid,
                    contentToSend,
                }).then((response: any) => {
                    if (response[0]?.result !== RESPONSE.SUCCESS) {
                        dispatch('addNotificationRecordErrorUpdateIssue', { projectId, issueId: issue.id });
                        notificationWarning('IssuesCantBeUpdated', 1, { count: 1 }, true);
                        return reject(response);
                    }

                    issue.setFieldValue(payload.row, payload.value);

                    commit('addCommentsForIssue', {
                        projectId,
                        issueUuid: issue.uuid,
                        comments: response,
                    });

                    notificationSuccess('issuesSuccessfullyUpdated', 1, { count: 1 });
                    commit('clearMultiEditValue');
                    resolve(response);
                }).catch((err) => {
                    dispatch('addNotificationRecordErrorUpdateIssue', { projectId, issueId: issue.id });
                    notificationWarning('IssuesCantBeUpdated', 1, { count: 1 }, true);
                    reject(err);
                }).finally(() => {
                    commit('setIsLoadingMultiEdit', false);
                });

                amplitudeLog(AmplitudeEvent.itIssueEdit, { fieldName: [payload.row], issuesEdited: 1 });
            });
        },
        applyMultiEditForMultipleValues(
            { commit, dispatch, rootGetters }: ActionContext<MultiSelectStorage, any>,
            {
                projectId,
                issue,
                payload,
            }: {
                projectId: number;
                issue: Issue;
                payload: {
                    rows: string[];
                    types: string[];
                    sourceValue: any[];
                    value: any[];
                    currentUserEmail: string;
                };
            },
        ) {
            return new Promise((resolve, reject) => {
                commit('setIsLoadingMultiEdit', true);

                const time = moment().utc().format(MomentFormats.serverSide);
                const diff: any = {};

                payload.rows.forEach((row: string, index: number) => {
                    diff[row] = createDiff({
                        row,
                        type: payload.types[index],
                        sourceValue: payload.sourceValue[index],
                        value: payload.value[index],
                    });
                });

                const commentsObj = createDiffCommentObject({
                    diff,
                    currentUserEmail: payload.currentUserEmail,
                    time,
                });

                const contentToSend = {
                    project_id: projectId,
                    issue_uuid: issue.uuid,
                    comments: JSON.stringify(commentsObj),
                    [NotifierUserID]: rootGetters.notifierActorId,
                };

                dispatch('addComments', {
                    issueUuid: issue.uuid,
                    contentToSend,
                }).then((response: any) => {
                    if (response[0]?.result !== RESPONSE.SUCCESS) {
                        dispatch('addNotificationRecordErrorUpdateIssue', { projectId, issueId: issue.id });
                        notificationWarning('IssuesCantBeUpdated', 1, { count: 1 }, true);
                        return reject(response);
                    }

                    payload.rows.forEach((row: string, index: number) => {
                        issue.setFieldValue(row, payload.value[index]);
                    });

                    commit('addCommentsForIssue', {
                        projectId,
                        issueUuid: issue.uuid,
                        comments: response,
                    });

                    notificationSuccess('issuesSuccessfullyUpdated', 1, { count: 1 });
                    commit('clearMultiEditValue');
                    resolve(response);
                }).catch((err) => {
                    dispatch('addNotificationRecordErrorUpdateIssue', { projectId, issueId: issue.id });
                    notificationWarning('IssuesCantBeUpdated', 1, { count: 1 }, true);
                    reject(err);
                }).finally(() => {
                    commit('setIsLoadingMultiEdit', false);
                });

                amplitudeLog(AmplitudeEvent.itIssueEdit, { fieldName: payload.rows, issuesEdited: 1 });
            });
        },
        multiDeleteIssues({ commit, dispatch, getters }: ActionContext<MultiSelectStorage, any>, projectId: number) {
            return new Promise((resolve) => {
                commit('setIsLoadingMultiEdit', true);

                const requests: Array<Promise<any>> = getters.multiSelectedIssues.map((issue: Issue) => {
                    return dispatch('deleteIssue', { issueUuid: issue.uuid, projectId });
                });

                let success = 0;
                let fail = 0;
                Promise.allSettled(requests).then((results: any[]) => {
                    results.forEach((response: any) => {
                        if (response.status === AllSettledResult.fulfilled && response.value.data === null) {
                            success++;
                        } else {
                            fail++;
                        }
                    });

                    if (success > 0) {
                        notificationSuccess('issuesSuccessfullyDeleted', 1, { count: success });
                    }

                    if (fail > 0) {
                        notificationWarning('IssuesCantBeDeleted', 1, { count: fail });
                    }

                    commit('clearMultiEditValue');
                    commit('cleanMultiEditSelectedIssues');
                    eventBus.$emit(BusEvent.cancelIssueEdit);
                    resolve();
                }).finally(() => {
                    commit('setIsLoadingMultiEdit', false);
                });
            });
        },
        clearMultiSelect({ commit }) {
            commit('cleanMultiEditSelectedIssues');
            commit('clearMultiEditValue');
            commit('saveMultiEditStatusReplaceMaps', null);
        },
    },
} as Module<MultiSelectStorage, any>;
