import _ from 'lodash';
import { RESPONSE } from '@/constants';
import { i18n, membersChangeRoleResponsesParser } from '@/services';

export const getMessagesOnMembersAddition = _.flowRight(getMessages, getMemberArrays);

interface ISimpleMessage {
    type: string;
    textKey: string;
    list: string;
}

function simpleMessage(members: any[], type: 'success' | 'errors', key: string): ISimpleMessage {
    return {
        type,
        textKey: `License.addMember.${type}.${key}`,
        list: `${members.map((item: any) => `"${item.email}"`).join(', ')}` as string,
    };
}

function getMessages(memberArrays: { [key: string]: any }) {
    const successes = [
        'added',
        'activated',
        'changed',
    ].filter((eventName) => (memberArrays[eventName] && memberArrays[eventName].length));
    const errors = [
        'reactiveOverflowing',
        'residence',
        'reactiveResidence',
        'notAcceptedMails',
        'emptyMails',
        'overflowUsers',
        'residenceUsers',
        'internalError',
        'yourselfError',
    ].filter((eventName) => (memberArrays[eventName] && memberArrays[eventName].length));
    const successMessages = successes.map((key: string) => {
        const message = simpleMessage(memberArrays[key], 'success', key);
        return { type: message.type, text: i18n.t(message.textKey, { list: message.list }) };
    });
    const errorMessages = errors.map((key: string) => {
        const message = simpleMessage(memberArrays[key], 'errors', key);
        return { type: message.type, text: i18n.t(message.textKey, { list: message.list }) };
    });
    return [...successMessages, ...errorMessages];
}

function getMemberArrays(response: any) {
    const added: any[] = [];
    const overflowing: any[] = [];
    const residence: any[] = [];
    const activated: any[] = [];
    const reactiveOverflowing: any[] = [];
    const reactiveResidence: any[] = [];
    const changed: any[] = [];
    const overflowUsers: any[] = [];
    const residenceUsers: any[] = [];
    const guests: any[] = [];
    const invited: any[] = [];
    const notAcceptedMails: any[] = [];
    const emptyMails: any[] = [];
    const internalError: any[] = [];
    const yourselfError: any[] = [];

    const addedParsedResponses = membersChangeRoleResponsesParser(response.added);
    reactiveOverflowing.push(...addedParsedResponses.overflow);
    reactiveResidence.push(...addedParsedResponses.residence);
    added.push(...addedParsedResponses.success);
    internalError.push(...addedParsedResponses.error);

    const activatedParsedResponses = membersChangeRoleResponsesParser(response.activated);
    reactiveOverflowing.push(...activatedParsedResponses.overflow);
    reactiveResidence.push(...activatedParsedResponses.residence);
    activated.push(...activatedParsedResponses.success);
    internalError.push(...activatedParsedResponses.error);

    const changedParsedResponses = membersChangeRoleResponsesParser(response.changed);
    overflowUsers.push(...changedParsedResponses.overflow);
    residenceUsers.push(...changedParsedResponses.residence);
    changed.push(...changedParsedResponses.success);
    internalError.push(...changedParsedResponses.error);
    yourselfError.push(...changedParsedResponses.yourself);

    response.invited.forEach((value: any) => {
        switch (value.code) {
            case RESPONSE.LICENCE_OVERFLOW:
                overflowing.push(value);
                break;
            case RESPONSE.NO_AVAILABLE_LICENSE:
                residence.push(value);
                break;
            case RESPONSE.INVALID_PARAMS:
                if (value.email) {
                    notAcceptedMails.push(value);
                } else {
                    emptyMails.push(value);
                }
                break;
            default:
                invited.push(value);
        }
    });

    guests.push(...response.guests);

    return {
        added,
        overflowing,
        residence,
        activated,
        reactiveOverflowing,
        reactiveResidence,
        changed,
        overflowUsers,
        residenceUsers,
        guests,
        invited,
        notAcceptedMails,
        emptyMails,
        internalError,
        yourselfError,
    };
}

