import { RESPONSE } from '@/constants';
import { getTranslationEndingByCount, i18n } from '@/services';
import { toastError } from './NotificationService';

export function handleInviteLicenseMemberErrors(resultCode: number, count: number = 1, isUpdate: boolean = false) {
    const ender = getTranslationEndingByCount(count);

    // We have two errors pools, for add and for update users.
    let errorMapperFinal;
    
    const errorsMapper = {
        [RESPONSE.DATABASE_ERROR]: i18n.t(`LicenseMembers.sidebar.notifications.internalError${ender}`),
        [RESPONSE.INVALID_PARAMS]: i18n.t('LicenseMembers.sidebar.notifications.invalidEmail'),
        [RESPONSE.NO_AVAILABLE_LICENSE]: i18n.t('LicenseMembers.sidebar.notifications.guestError'),
        [RESPONSE.LICENSE_EXPIRED]: i18n.t(`LicenseMembers.sidebar.notifications.licenseExpired${ender}`),
        [RESPONSE.LICENCE_OVERFLOW_BY_USER]: i18n.t(`LicenseMembers.sidebar.notifications.licenseLimitError${ender}`),
        [RESPONSE.LICENCE_OVERFLOW]: i18n.t(`LicenseMembers.sidebar.notifications.licenseLimitError${ender}`),
        [RESPONSE.OWNER_CHANGE_FORBIDDEN]: i18n.t('LicenseMembers.sidebar.notifications.ownRoleChangeError'),
        [RESPONSE.SUPER_ADMIN_CHANGE_FORBIDDEN]: i18n.t('LicenseMembers.sidebar.notifications.superAdminChangeError'),
        [RESPONSE.AUTH_METHOD_CHANGE_FORBIDDEN]: i18n.t('LicenseMembers.sidebar.notifications.authMethodChangeError'),
        [RESPONSE.OBJECT_NOT_EXISTS]: i18n.t('errors.empty_authMethod'),
        [RESPONSE.AUTH_METHOD_NOT_ALLOWED]: i18n.t(`LicenseMembers.sidebar.notifications.authMethodNotAllowed${ender}`),
    };

    const errorMapperUpdate = {
        ...errorsMapper,
        [RESPONSE.DATABASE_ERROR]: i18n.t(`LicenseMembers.sidebar.notifications.internalErrorUpdate${ender}`),
        [RESPONSE.LICENCE_OVERFLOW_BY_USER]: i18n.t(`LicenseMembers.sidebar.notifications.licenseLimitErrorUpdate${ender}`),
        [RESPONSE.LICENCE_OVERFLOW]: i18n.t(`LicenseMembers.sidebar.notifications.licenseLimitErrorUpdate${ender}`),
        [RESPONSE.AUTH_METHOD_NOT_ALLOWED]: i18n.t(`LicenseMembers.sidebar.notifications.authMethodNotAllowedUpdate${ender}`),
    };

    if (isUpdate) {
        errorMapperFinal = errorMapperUpdate;
    } else {
        errorMapperFinal = errorsMapper;
    }
    
    toastError(errorMapperFinal[resultCode] || i18n.t('errors.server_error'));
}
