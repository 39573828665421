import { User } from '@/models';

export class ProjectDashboardPreset {
    public readonly uuid: string;
    public readonly created: number;
    public readonly title: string;
    public readonly author: User;

    constructor(projectDashboardPreset: any = {}) {
        this.uuid = projectDashboardPreset.uuid || '';
        this.created = projectDashboardPreset.created || 0;
        this.title = projectDashboardPreset.title || '';
        this.author = new User(projectDashboardPreset.author);
    }
}
