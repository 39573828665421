export interface IntegrationServiceItemParams {
    name?: string;
    needResourceIdentifier?: boolean;
}

export class IntegrationServiceItem {
    public name: string;
    public needResourceIdentifier: boolean;

    constructor(service: string, params: IntegrationServiceItemParams = {}) {
        this.name = params?.name ?? service;
        this.needResourceIdentifier = Boolean(params?.needResourceIdentifier);
    }
}
