var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoadingIssueFilterPresets),expression:"isLoadingIssueFilterPresets"}],ref:"presetList",staticClass:"preset-list"},[_c('Draggable',{attrs:{"disabled":_vm.presetAction !== _vm.PresetAction.reorder,"move":_vm.checkMove,"handle":".handle"},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.issueFilterPresetsLocal),callback:function ($$v) {_vm.issueFilterPresetsLocal=$$v},expression:"issueFilterPresetsLocal"}},_vm._l((_vm.issueFilterPresetsLocal),function(filterPreset,index){return _c('div',{key:filterPreset.uuid,staticClass:"filter-preset",class:{ handle: _vm.isPresetRegular(filterPreset) && _vm.presetAction === _vm.PresetAction.reorder },attrs:{"id":_vm.getFilterPresetIdByUuid(filterPreset.uuid)}},[_c('a',{staticClass:"filter-preset-item",class:{
                    'filter-preset-item-last': _vm.isPresetDeleted(index),
                    'filter-preset-item-second': index === 1,
                    'selected-preset': _vm.isSelected(filterPreset),
                    'dragged-preset': index === _vm.draggedIndex,
                    grab: _vm.presetAction === _vm.PresetAction.reorder
                },attrs:{"href":_vm.getResolvedUrlForPreset(filterPreset)},on:{"click":function($event){return _vm.onClickPresetItem($event, filterPreset)}}},[(_vm.isPresetDraggable(filterPreset))?_c('span',{staticClass:"drag-icon-wrapper"},[_c('v-icon',{attrs:{"size":"medium"}},[_vm._v("menu")])],1):_vm._e(),_c('div',{staticClass:"title-wrapper"},[_c('WsTooltip',{attrs:{"disabled":!_vm.isTitleTruncatedArr[index],"tooltip":filterPreset.title},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({staticClass:"filter-preset-title"},on),[_vm._v(" "+_vm._s(filterPreset.title)+" ")])]}}],null,true)})],1),_c('div',{staticClass:"preset-icons"},[_c('WsTooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [([_vm.BrokenPresetEnum.brokenOnFrontend, _vm.BrokenPresetEnum.brokenInApp].includes(filterPreset.broken))?_c('span',_vm._g({staticClass:"filter-preset-icon"},on),[_c('IconSvg24',{attrs:{"icon-name":"notification-attention","color":_vm.Color.primaryYellow,"stateless":"","passive":""}})],1):_vm._e()]}},{key:"tooltip",fn:function(){return [(filterPreset.broken === _vm.BrokenPresetEnum.brokenOnFrontend)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('IssueTracker.presetBrokenOnFrontend'))+" ")]):(filterPreset.broken === _vm.BrokenPresetEnum.brokenInApp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('IssueTracker.presetBrokenInApp'))+" ")]):_vm._e()]},proxy:true}],null,true)}),(_vm.presetsNotifications[filterPreset.uuid].hasDeletedStatus)?_c('WsTooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({staticClass:"filter-preset-icon"},on),[_c('IconSvg24',{attrs:{"icon-name":"notification-attention","color":_vm.Color.primaryYellow,"stateless":"","passive":""}})],1)]}},{key:"tooltip",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('IssueTracker.presetHasDeletedStatus'))+" ")])]},proxy:true}],null,true)}):_vm._e(),(filterPreset.visibility)?_c('span',{staticClass:"filter-preset-icon"},[_c('IconSvg24',{attrs:{"icon-name":"sharing-busts","color":_vm.Color.mediumGray,"stateless":"","passive":""}})],1):_vm._e()],1),_c('div',{staticClass:"preset-icons"},[(_vm.allUnreadIssues && filterPreset.isAllowShowCounts)?_c('span',{staticClass:"filter-preset-icon"},[_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.allUnreadIssues))])]):_vm._e(),(_vm.isAllowShowUnreadMarker(filterPreset))?[(_vm.presetsNotifications[filterPreset.uuid].hasUnread)?_c('span',{staticClass:"filter-preset-icon"},[_c('span',{staticClass:"green-icon"})]):_vm._e()]:_vm._e()],2),(_vm.isPresetWithCheckbox(filterPreset))?_c('WsTooltip',{attrs:{"disabled":!_vm.presetCheckbox(filterPreset).tooltip,"tooltip":_vm.presetCheckbox(filterPreset).tooltip}},[_c('WsCheckbox',{attrs:{"value":_vm.isCheckedPresetObj[filterPreset.uuid],"disabled":_vm.presetCheckbox(filterPreset).isDisabled},on:{"change":function($event){return _vm.clickCheck(filterPreset)}},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}})],1):_vm._e()],1),(index === 0 && _vm.draftCount)?[_c('div',{staticClass:"filter-preset filter-preset-item-first"},[_c('div',{staticClass:"filter-preset-item",class:{ 'selected-preset': _vm.isDraftSelected },on:{"click":_vm.showDrafts}},[_c('span',{staticClass:"filter-preset-title"},[_vm._v(_vm._s(_vm.$t('IssueTracker.presets.drafts')))]),_c('div'),_c('div',{staticClass:"filter-preset-icon"},[_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.draftCount))])])])])]:_vm._e()],2)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }