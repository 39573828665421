import { DirectiveFunction } from 'vue';

type LoadingSize = 'xsmall' | 'small' | 'medium' | 'large';
const elligableSizes = ['xsmall', 'small', 'medium', 'large'];
let size: LoadingSize = 'large';
const isLoadingClass = 'loading';
const isShowIsLoadingClass = 'show';

const LoadingDirective: DirectiveFunction = (el: HTMLElement, { value, oldValue, arg }) => {
    if (oldValue === value) {
        return;
    }

    const typedArg = arg as LoadingSize;

    if (arg && elligableSizes.includes(typedArg)) {
        size = typedArg;
    }

    // Show loader
    if (value && !el.classList.contains(isLoadingClass)) {
        el.classList.add(isLoadingClass, size, isShowIsLoadingClass);

        // loader shows only with this class. it's neccesary for animation.
        setTimeout(() => {
            el.classList.add(isShowIsLoadingClass);
        });
        return;
    }

    // Hide loader
    if (!value) {
        el.classList.remove(isShowIsLoadingClass);

        // 100 - is animation duration.
        setTimeout(() => {
            el.classList.remove(isLoadingClass, size);
        }, 100);
    }
};

export default LoadingDirective;