var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.addedArray.length)?_c('div',{staticClass:"items-row highlighting"},[_vm._v(" "+_vm._s(_vm.$t('IssueTracker.diffComments.added'))+" "),_vm._l((_vm.addedArray),function(ref){
var deleted = ref.deleted;
var added = ref.added;
var value = ref.value;
return _c('v-chip',{key:value,class:{ deleted: deleted, added: added },attrs:{"small":""}},[(_vm.entity === 'watchers')?_c('WsMemberName',{attrs:{"email":value,"tooltip":""}}):_c('span',{staticClass:"highlighting"},[_c('WsTooltip',{attrs:{"tooltip":value,"activator":_vm.truncateString(value, 15),"block":""}})],1)],1)})],2):_vm._e(),(_vm.deletedArray.length)?_c('div',{staticClass:"items-row highlighting"},[_vm._v(" "+_vm._s(_vm.$t('IssueTracker.diffComments.deleted'))+" "),_vm._l((_vm.deletedArray),function(ref){
var deleted = ref.deleted;
var added = ref.added;
var value = ref.value;
return _c('v-chip',{key:value,class:{ deleted: deleted, added: added },attrs:{"small":""}},[(_vm.entity === 'watchers')?_c('WsMemberName',{attrs:{"email":value,"tooltip":""}}):_c('span',{staticClass:"highlighting"},[_c('WsTooltip',{attrs:{"tooltip":value,"activator":_vm.truncateString(value, 15),"block":""}})],1)],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }