


































import { Component, Emit, Prop } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import { TranslateResult } from 'vue-i18n';
import {
    IssueFilterPreset,
    LicenseMember,
    MemberForApproval,
    MemberForApprovalEnhanced,
    Project,
    ProjectMember,
} from '@/models';
import { isOverflownElementHorizontally, nameFilter, sanitizeHtml } from '@/services';
import WsTooltip from '@/components/common/WsTooltip.vue';
import EventListenersBase from '@/components/common/EventListenersBase.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

type IItem = MemberForApprovalEnhanced
    | MemberForApproval
    | LicenseMember
    | ProjectMember
    | Project
    | IssueFilterPreset;

@Component({
    components: {
        WsTooltip,
        IconSvg24,
    },
    methods: {
        nameFilter,
        sanitizeHtml,
    },
})
export default class AffectedItems extends EventListenersBase {
    @Prop() public items!: IItem[];
    @Prop() public question!: TranslateResult;
    @Prop() public description!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public removable!: boolean;
    @Prop({ type: Boolean, default: false }) public isSingleMode!: boolean;
    @Prop({ type: Boolean, default: false }) public noLabel!: boolean;

    public localItemsWithUuids: Array<{ item: IItem, uuid: string }> = [];
    public isTitleTruncatedArr: boolean[] = [];

    get localItems() {
        return this.localItemsWithUuids.map(({ item }) => item);
    }
    get isProjects(): boolean {
        return Boolean(this.items.length && this.items[0] instanceof Project);
    }
    get itemLabel(): TranslateResult {
        if (this.noLabel) {
            return '';
        }

        if (this.isProjects) {
            return this.$t('Simple_word.projects');
        }

        if (this.items.length === 1) {
            return this.$t('Simple_word.user');
        }

        return this.$t('Simple_word.users');
    }

    @Emit()
    public remove(uuid: string) {
        this.localItemsWithUuids = this.localItemsWithUuids.filter(({ uuid: runningUuid }) => runningUuid !== uuid);
        return this.localItems;
    }

    public created() {
        this.localItemsWithUuids = this.items.map((item) => {
            const uuid = uuidv4();
            return { item, uuid };
        });
    }

    public async mounted() {
        await this.$nextTick();
        this.calculateTitleTruncated();
        this.eventListeners.add({ event: 'resize', handler: this.calculateTitleTruncated });
    }

    public calculateTitleTruncated() {
        const titles: HTMLElement[] = Array.from(document.querySelectorAll('.item-title'));
        this.isTitleTruncatedArr = titles.map(isOverflownElementHorizontally);
    }
}
