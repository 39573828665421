import _ from 'lodash';
import { IIssueFilter } from '@/types/IIssueFilter';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import { getEmptyFilters } from '@/services';

export function convertDesktopFilterSetToTrackerFilters(presetFilters: IIssueFilter[]): TrackerFilters {
    const presetFiltersByType = _.keyBy(presetFilters, 'type');

    const filterTypes = _.keys(getEmptyFilters());
    return filterTypes.reduce((acc, filterType) => {
        const presetFilter: any = presetFiltersByType[filterType];

        return presetFilter ? { ...acc, [filterType]: presetFilter.convertToPanelFilter() } : acc;
    }, getEmptyFilters());
}
