import { render, staticRenderFns } from "./ProjectsDropdownItem.vue?vue&type=template&id=d460b846&scoped=true&"
import script from "./ProjectsDropdownItem.vue?vue&type=script&lang=ts&"
export * from "./ProjectsDropdownItem.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectsDropdownItem.vue?vue&type=style&index=0&id=d460b846&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d460b846",
  null
  
)

export default component.exports