import axios from 'axios';
import axiosRetry from 'axios-retry';
import { RESPONSE } from '@/constants';
import Storage from '@/storage';

export function initHttpInterceptors() {
    axios.interceptors.response.use((response) => {
        const url = `${response.config?.baseURL || ''}${response.config?.url}`;
        if (!url.includes('regions.')) {
            const result = RESPONSE.SUCCESS;
            Storage.dispatch('pushRequestLog', { result, url });
        }

        return response;
    }, (error) => {
        if (error.result === RESPONSE.LICENSE_BLOCKED) {
            Storage.dispatch('loadLicenses', true);
            return;
        }
        const url = `${error.config?.baseURL || ''}${error.config?.url}`;
        const result = error.code;
        Storage.dispatch('pushRequestLog', { result, url });

        return Promise.reject(error);
    });

    axiosRetry(axios, {
        retries: 5,
        retryDelay: (retryCount: number) => retryCount * 500,
        retryCondition: (error: any) => error,
    });
}
