import { Dict } from '@/types/Dict';
import { FormItemType } from '@/constants';
import { FormSortField, FormSortItem } from '@/models';

export class FormSort {
    public readonly type = FormItemType.sort;
    public readonly name: string;
    public readonly label: string;
    public readonly options: FormSortField[];
    public readonly sorts: FormSortItem[];
    public readonly directions: Dict<string>;
    public readonly multiple: boolean;
    public readonly clearable: boolean;
    public readonly filterable: boolean;
    public readonly defaultFirstOption: boolean;

    constructor(params: any = {}) {
        this.name = params.name;
        this.label = params.label;
        this.options = params.options;
        this.sorts = params.sorts;
        this.directions = params.directions;

        this.multiple = params.multiple || false;
        this.clearable = params.clearable || true;
        this.filterable = params.filterable || true;
        this.defaultFirstOption = params.defaultFirstOption || true;
    }
}
