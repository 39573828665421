































import { Component } from 'vue-property-decorator';
import { AmplitudeEvent, LanguageKey } from '@/constants';
import { amplitudeMixin } from '@/mixins';
import HeaderSelect from '@/components/header/HeaderSelect.vue';

@Component({
    components: {
        HeaderSelect,
    },
})
export default class UserWidget extends amplitudeMixin {
    get language() {
        return this.$route.params.language;
    }
    get userData() {
        return this.$store.getters.userData;
    }
    get userAvatarSrc() {
        return this.userData.avatar || '/images/undefined-user.png';
    }
    get eulaHref() {
        let lang = this.language;
        if (lang === LanguageKey.cn) {
            lang = 'zh';
        } else if (lang === LanguageKey.jp) {
            lang = 'ja';
        }
        return `https://revizto.com/${lang}/eula/`;
    }

    public handleLogout() {
        this.amplitudeLog(AmplitudeEvent.logoutClicked);
        this.$store.dispatch('logout', { isGoToLogin: true });
    }
    public setSize(size: number) {
        this.$store.commit('setHeaderComponentSize', { name: 'user', size });
    }
}
