
























































































import _ from 'lodash';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { MultiEditIssueFieldPayload } from '@/types/MultiEditIssueFieldPayload';
import { LOCAL_STORAGE_WATCHERS_KEY } from '@/constants';
import { MAX_NOT_COLLAPSED_LENGTH } from '@/constants/IssueTracker';
import { Issue, ProjectMembersOption } from '@/models';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';
import IssueDetailsEditPopover from '@/components/project/issueTracker/details/IssueDetailsEditPopover.vue';
import ProjectMemberName from '@/components/project/ProjectMemberName.vue';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import IssueDetailsBase from '@/components/project/issueTracker/details/IssueDetailsBase.vue';
import DialogMultiEditWatchers from '@/components/project/issueTracker/modals/DialogMultiEditWatchers.vue';

@Component({
    components: {
        DialogMultiEditWatchers,
        IssueDetailsItem,
        IssueDetailsEditPopover,
        ProjectMemberName,
        WsTruncateAuto,
        IconSvg16,
    },
})
export default class IssueDetailsWatchers extends IssueDetailsBase {
    @Prop({ required: true }) public label!: any;
    @Prop({ required: true, type: Boolean, default: false }) public editable!: boolean;
    @Prop({ required: true }) public items!: ProjectMembersOption[];

    public readonly collapsingKey = LOCAL_STORAGE_WATCHERS_KEY;
    public isShowDialogMultiEditWatchers = false;
    public multiselectChanges: null | {
        previous: string[];
        added: string[];
        removed: string[];
    } = null;
    public readonly getCommaForListItem = getCommaForListItem;

    get counter(): number {
        return this.currentValue?.length ?? 0;
    }

    get projectMembersByEmail() {
        const projectMembers = this.$store.getters.projectMembersByProjectId(this.projectId);
        return _.keyBy(projectMembers, 'email');
    }

    get joinedList(): string {
        if (Array.isArray(this.currentValue)) {
            return this.currentValue?.map(this.memberFormatter).join(this.$t('Punctuation.comma') as string);
        }

        return '';
    }

    get collapsable() {
        return this.stringLongerThanPossible(this.joinedList) || this.currentValue?.length > 2;
    }

    get currentValue() {
        if (this.multiEditNewValues) {
            return this.multiEditNewValues.value;
        }

        if (this.showFieldMultipleText) {
            return [];
        }

        return this.currentIssue[this.valueKey as keyof Issue];
    }

    get multiSelectIssues(): Issue[] {
        return this.$store.getters.multiSelectedIssues;
    }

    get watcherEntries() {
        const watcherEntries = new Map();

        this.multiSelectIssues.forEach((issue: Issue) => {
            issue.watchers.forEach((watcher) => {
                const currentWatcherCount = watcherEntries.get(watcher);

                if (currentWatcherCount) {
                    watcherEntries.set(watcher, currentWatcherCount + 1);
                } else {
                    watcherEntries.set(watcher, 1);
                }
            });
        });

        return Array.from(watcherEntries, ([watcher, count]) => ({
            watcher,
            count,
        }));
    }

    @Watch('multiSelectIssues')
    public onMultiSelectIssuesChange() {
        this.multiselectChanges = null;
        this.isShowDialogMultiEditWatchers = false;
    }

    public handleSelect(value: string[]) {
        this.change(value);
    }

    public memberFormatter(email: string) {
        return this.projectMembersByEmail[email]?.fullname || email;
    }

    public stringLongerThanPossible(string: string) {
        return string?.length > MAX_NOT_COLLAPSED_LENGTH;
    }

    public openMultiEditWatchers() {
        this.isShowDialogMultiEditWatchers = !this.isShowDialogMultiEditWatchers;
    }

    public saveMultiEdit(payload: MultiEditIssueFieldPayload) {
        this.multiselectChanges = {
            previous: [...this.currentIssue.watchers],
            added: payload.add,
            removed: payload.remove,
        };

        this.change(payload);
        this.isShowDialogMultiEditWatchers = false;
    }

    public cancelMultiEdit() {
        this.isShowDialogMultiEditWatchers = false;
        this.multiselectChanges = null;
    }
}
