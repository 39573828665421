



































































































































import _ from 'lodash';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import { amplitudeLog } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsInputSearch from '@/components/common/WsInputSearch.vue';
import WsButton from '@/components/common/WsButton.vue';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

export interface ListItem {
    text: string;
    value: any;
    key?: string | number;
    fields?: object;
    search?: string;
}

const DefaultMaxVisibleItems = 20;

@Component({
    components: {
        IconSvg16,
        WsInputSearch,
        WsButton,
    },
})
export default class IssueDetailsEditPopover extends EventListenersBase {
    @Prop({ required: true }) public items!: Array<ListItem | string>;
    @Prop({ default: () => [] }) public recentUsedItems!: Array<ListItem | string>;
    @Prop({ default: '' }) public initialValue!: string[] | string;
    @Prop({ default: 'value' }) public searchField!: string;
    @Prop({ type: Boolean, default: false }) public searchable!: boolean;
    @Prop({ type: Boolean, default: false }) public confirmation!: boolean;
    @Prop({ type: Boolean, default: false }) public multiselect!: boolean;
    @Prop({ type: Boolean, default: false }) public autoWidth!: boolean;
    @Prop({ type: Boolean, default: true }) public isReset!: boolean;

    public isOpenPopover: boolean = false;

    public search: string = '';
    public localModel: string[] = [];
    public countOfVisibleItems: number = DefaultMaxVisibleItems;
    public handleItemsListScroll: any;

    get shouldConfirm() {
        return [this.multiselect, this.confirmation].includes(true);
    }

    get closeOnContentClick() {
        return ![this.searchable, this.shouldConfirm].includes(true);
    }

    get normalizedItems(): ListItem[] {
        return _.uniqBy(this.items.map((item: ListItem | string) => {
            if (typeof item === 'string') {
                return {
                    text: item,
                    value: item,
                };
            }

            return item;
        }), this.searchField);
    }

    get filteredItems(): ListItem[] {
        function searchIn(where: any | any[], what: string) {
            const searchString = _.isArray(where) ? where.join(' ') : where;
            const lowerCaseWhat = what.toLowerCase();

            return String(searchString).toLowerCase().includes(lowerCaseWhat);
        }

        return this.normalizedItems
            .filter(({ value, text, search }) => searchIn([value, text, search], this.search));
    }

    get visibleItems(): ListItem[] {
        return this.filteredItems.slice(0, this.countOfVisibleItems);
    }

    get allSelected() {
        return this.filteredItems.length === this.localModel.length;
    }

    @Emit()
    public change(value: string | string[]) {
        return value;
    }

    public created() {
        this.setInitial();

        this.handleItemsListScroll = _.debounce((e: Event) => {
            if (!e.target) {
                return;
            }

            const el = e.target as HTMLElement;

            if (el.scrollTop + el.clientHeight === el.scrollHeight) {
                this.countOfVisibleItems += DefaultMaxVisibleItems;
            }
        }, 100);
    }

    public edit() {
        dispatchEvent(new CustomEvent('closeEditSlots'));
        this.countOfVisibleItems = DefaultMaxVisibleItems;
        this.setInitial();
        this.isOpenPopover = true;

        this.$nextTick(() => {
            this.addScrollListener();
        });
    }

    public selectItem(value: string) {
        if (!this.shouldConfirm) {
            if (!this.isActive(value)) {
                this.change(value);
            }
            this.close();
        }

        if (this.multiselect) {
            this.toggleValue(value);
        }
    }

    public selectRecentItem(value: string) {
        amplitudeLog(AmplitudeEvent.itUsedRecentUsers, { assigneeToMe: false });
        this.selectItem(value);
    }

    public confirm() {
        const isEqual = _.isEqual(this.localModel, this.initialValue);
        if (!isEqual) {
            this.change(this.localModel);
        }
        this.close();
    }

    public close() {
        this.isOpenPopover = false;
    }

    public reset() {
        this.setInitial();
        this.close();
    }

    public toggleValue(value: string) {
        const index = this.localModel.indexOf(value);

        if (index > -1) {
            this.localModel.splice(index, 1);
        } else {
            this.localModel.push(value);
        }
    }

    public isActive(item: string) {
        return this.localModel.includes(item);
    }

    public setInitial() {
        this.localModel = _.castArray(this.initialValue).slice();
    }

    public toggleAll() {
        if (this.allSelected) {
            this.localModel = [];
        } else {
            this.localModel = this.filteredItems.map(({ value }) => value);
        }
    }

    public addScrollListener() {
        const itemsList = (this.$refs.itemsList as Vue | undefined)?.$el as HTMLElement | undefined;

        if (!itemsList) {
            return;
        }

        this.eventListeners.add({ node: itemsList, event: 'scroll', handler: this.handleItemsListScroll });
    }

    public onSearchInput() {
        this.countOfVisibleItems = DefaultMaxVisibleItems;
    }

    public setEmpty() {
        this.change('');
        this.close();
    }
}
