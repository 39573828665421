





import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';

@Component
export default class ReturnToLoginButton extends Vue {
    @Prop() public text!: string;

    public navigateToLogin() {
        this.$router.replace({ name: RouterNames.Login });
    }
}
