






























import { Component } from 'vue-property-decorator';
import IconFileSvgBase from '@/components/common/icon/IconFileSvgBase.vue';

@Component
export default class IconFileSvg40 extends IconFileSvgBase {
    public size = 40;
}
