



















































































import VueI18n, { TranslateResult } from 'vue-i18n';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import { ColorService, i18n } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';
import WsSelect from '@/components/common/WsSelect.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';

@Component({
    components: {
        WsCloseButton,
        IconSvg16,
        WsSelect,
    },
})
export default class WsColorPicker extends Vue {
    @Prop({ required: true, default: '' }) public value!: string;
    @Prop({ default: () => [] }) public dashboardsColors!: string[];
    @Prop({ default: false, type: Boolean }) public minimal!: boolean;
    @Prop({ default: i18n.t('ColorPicker.buttonOpen') }) public buttonTitle!: TranslateResult;
    @Prop({ type: Boolean, default: false }) public rectangle!: boolean;

    public isVisible = false;
    public widthWindow = 258;
    public type = 'hex';
    public model = { [this.type]: '#000000' }; // такое объявление отключает режим выбора прозрачности
    public initialColor = '';
    public isCollapsedPicker: boolean = true;
    public selectedColors: string[] = [];
    public randomColors: string[] = ColorService.generateColorList(24);

    public readonly reviztoColors: string[] = [
        Color.richBlack, Color.selectBlack, Color.defaultBlack, Color.mediumGray, Color.disableGray, Color.light,
        Color.primaryRed, Color.paletteRed, Color.primaryOrange, Color.primaryYellow, Color.paletteViolet, Color.paletteRedLight,
        Color.paletteGreen, Color.miskOldGreen, Color.primaryGreen, Color.palettePurple, Color.paletteBlue, Color.primaryBlue,
        Color.miskGray, Color.primaryLightBlue, Color.extraLight,
    ];
    public readonly stampsColors: string[] = [
        Color.paletteRed, Color.paletteOrange, Color.paletteYellow, Color.paletteLime, Color.paletteGreen,
        Color.paletteSky, Color.paletteBlue, Color.palettePurple, Color.paletteViolet, Color.richBlack,
    ];
    public readonly statusColors: string[] = [
        Color.primaryRed, Color.primaryOrange, Color.primaryGreen, Color.mediumGray, Color.selectBlack,
    ];
    public readonly priorityColors: string[] = [
        '#660000', Color.primaryRed, Color.primaryOrange, Color.miskOldGreen, '#24B2B2',
    ];

    get recentColors(): string[] {
        return this.$store.getters.recentColors;
    }
    get color() {
        return this.value.trim().toUpperCase() || this.model[this.type];
    }
    set color(color: string) {
        this.input(color);
        this.model[this.type] = color;
    }
    get palettes(): Array<{ text: VueI18n.TranslateResult, value: string[] }> {
        const palettes = [
            {
                text: this.$t('ColorPicker.palette.revizto'),
                value: this.reviztoColors,
            },
            {
                text: this.$t('ColorPicker.palette.stamps'),
                value: this.stampsColors,
            },
            {
                text: this.$t('ColorPicker.palette.status'),
                value: this.statusColors,
            },
            {
                text: this.$t('ColorPicker.palette.priority'),
                value: this.priorityColors,
            },
            {
                text: this.$t('ColorPicker.palette.random'),
                value: this.randomColors,
            },
        ];
        if (this.dashboardsColors.length) {
            palettes.push({
                text: this.$t('ColorPicker.palette.dashboard'),
                value: this.dashboardsColors,
            });
        }
        return palettes;
    }
    get isNeedContrastBorder(): boolean {
        return ColorService.contrastHexColor(this.color) === '#000000';
    }

    @Emit()
    public input(color: string) {
        return color;
    }

    @Emit()
    public change(color: string) {
        this.addColorToRecent(color);
        return color;
    }

    public created() {
        this.color = this.value.trim().toUpperCase() || this.randomColors[0];
        this.selectedColors = this.reviztoColors;
        this.input(this.color);
        this.initialColor = this.color;
    }

    public selectColor(color: string) {
        this.color = color;
        this.close(color);
    }

    public close(event: string | MouseEvent) {
        this.isVisible = false;
        const color = event instanceof MouseEvent ? this.color : event;
        this.input(color);
        this.change(color);
    }
    public clickOutside() {
        this.close(this.color);
    }

    public addColorToRecent(color: string) {
        const isChanged = this.initialColor !== color;
        if (!isChanged) {
            return;
        }
        this.$store.dispatch('addRecentColor', color);
        this.initialColor = color;
    }

    public checkSelectColor(color: string) {
        return this.color === color;
    }
}
