
import moment, { unitOfTime } from 'moment';
import { TranslateResult } from 'vue-i18n';
import { TimeUnit } from '@/constants';

const makeShiftForAbsoluteDates = () => moment().utcOffset() * secondsInMinute + secondsInHour * 12;
const secondsInMinute = 60;
const secondsInHour = 3600;
const TimeUnitStrings: { [key: number]: unitOfTime.DurationConstructor } = {
    [TimeUnit.DAYS]: 'day',
    [TimeUnit.WEEKS]: 'week',
    [TimeUnit.MONTHS]: 'month',
    [TimeUnit.YEARS]: 'year',
};
const getUtcMidday = () => moment().startOf('day').hour(12).add(moment().utcOffset(), 'minutes');

function dateObjToMoment(dateObj: any) {
    const { isRelative, value, units } = dateObj;
    if (!isRelative) {
        return moment(value, 'DD-MM-YYYY').add(makeShiftForAbsoluteDates(), 'second');
    }
    const timeUnit = TimeUnitStrings[[
        TimeUnit.DAYS,
        TimeUnit.WEEKS,
        TimeUnit.MONTHS,
        TimeUnit.YEARS,
    ][units]];
    return getUtcMidday().add(value, timeUnit);
}

const parseDate = (source: string, currentDateFormat: string) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const dateFormatArray = currentDateFormat.split(/[.\\/,-]/);
    const yearPosition = dateFormatArray.findIndex((el: string) => el === 'YYYY');
    const monthPosition = dateFormatArray.findIndex((el: string) => el === 'MM');
    const dayPosition = dateFormatArray.findIndex((el: string) => el === 'DD');
    const splitted = source.split('');
    const yearIndex = yearPosition === 0 ? 0 : 4;
    const monthIndex = yearPosition === 0
        ? (source.length === 6) ? 2 : 4
        : (monthPosition === 0) ? 0 : 2;
    const dayIndex = yearPosition === 0
        ? (source.length === 6) ? 4 : 6
        : (dayPosition === 0) ? 0 : 2;
    let day: number | string | null;
    let month: number | string | null;
    let year: number | string | null;

    const updateYear = (localYear: number | string): string => {
        if (Number(localYear) < 10) { return `202${localYear}`; }
        if (Number(localYear) < 100) { return `20${localYear}`; }
        if (Number(localYear) < 1000) { return `1${localYear}`; }
        return String(localYear);
    };

    const joinDate = (localYear: string, localMonth: string, localDay: string) => {
        let resultDate = '';
        for (let i = 0; i < 3; i++) {
            if (i === yearPosition) {
                resultDate += localYear;
            }
            if (i === monthPosition) {
                resultDate += localMonth;
            }
            if (i === dayPosition) {
                resultDate += localDay;
            }
            if (i !== 2) {
                resultDate += '/';
            }
        }
        return resultDate;
    };

    // transform "1" => 1 day of current month"
    if (source.length === 1) {
        source = joinDate(String(currentYear), String(currentMonth), source);
    }

    // transform "45" => 04/05 / "23" => "23 day of current month"
    if (source.length === 2) {
        if (+source <= 31) {
            source = joinDate(String(currentYear), String(currentMonth), source);
        } else {
            source = joinDate(String(currentYear), splitted[1], splitted[0]);
        }
    }

    // transform "143" => 14/03 current year, cannot be after 31/09 because 3110 have 4 digits
    if (source.length === 3 && !isNaN(+source) && Number(source) <= 319) {
        source = joinDate(String(currentYear), splitted[2], splitted[0] + splitted[1]);
    }

    // transform "0905" => 09/05 current year
    if (source.length === 4 && !isNaN(+source)) {
        source = joinDate(String(currentYear), splitted[2] + splitted[3], splitted[0] + splitted[1]);
    }

    // transform "09052" => 09/05/2022
    if (source.length === 5 && !isNaN(+source)) {
        source = joinDate(String(updateYear(splitted[4])), splitted[2] + splitted[3], splitted[0] + splitted[1]);
    }

    // transform "090522" => 09/05/22
    if (source.length === 6 && !isNaN(+source)) {
        source = joinDate(String(updateYear(splitted[yearIndex] + splitted[yearIndex + 1])), splitted[monthIndex] + splitted[monthIndex + 1], splitted[dayIndex] + splitted[dayIndex + 1]);
    }
    // transform "09052022" => 09/05/22
    if (source.length === 8 && !isNaN(+source)) {
        source = joinDate(String(updateYear(splitted[yearIndex] + splitted[yearIndex + 1] + splitted[yearIndex + 2] + splitted[yearIndex + 3])), splitted[monthIndex] + splitted[monthIndex + 1], splitted[dayIndex] + splitted[dayIndex + 1]);
    }

    const dateArray = source.split(/[.\\/,-]/).map(Number);
    year = dateArray[yearPosition] < 2050 ? dateArray[yearPosition] : null;
    month = dateArray[monthPosition] <= 12 ? dateArray[monthPosition] : null;
    day = dateArray[dayPosition] <= 31 ? dateArray[dayPosition] : null;

    const result = moment(`${day}-${month}-${year}`, 'DD-MM-YYYY');
    return result.isValid() ? result : null;
};

const convertMinToHoursAndMin = (min: number, hoursAbbr: TranslateResult, minAbbr: TranslateResult, secAbbr: TranslateResult) => {
    min = Math.floor(min);
    const h: number = Math.floor(min / 3600);
    const m: number = Math.floor((min - h * 3600) / 60);
    const s: number = min % 60;

    const hText = h > 0 ? `${h}${hoursAbbr} ` : '';
    const mText = m > 0 ? `${m}${minAbbr} ` : '';
    const sText = s > 0 ? `${s}${secAbbr}` : '';

    return `${hText}${mText}${sText}`;
};

export { getUtcMidday, dateObjToMoment, parseDate, convertMinToHoursAndMin };
