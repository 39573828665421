






















import { Component, Emit, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';
import { Issue } from '@/models';
import { groupsByDate, issueDateFormatter } from '@/services';
import IssueComment from '@/components/project/issueTracker/comments/IssueComment.vue';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg24,
        IssueComment,
    },
})
export default class PinnedComments extends Vue {
    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId);
    }

    get pinnedComments() {
        return this.currentIssue ? this.$store.getters.pinnedCommentsByIssue(this.projectId, this.currentIssue.uuid) : [];
    }

    get userData() {
        return this.$store.getters.userData;
    }

    get groupsByDate() {
        return groupsByDate(this.pinnedComments);
    }

    get language(): string {
        return this.$route.params.language;
    }

    get licenseId(): number {
        return this.$store.getters.currentLicenseId;
    }

    @Emit()
    public close() {
        return;
    }

    public dateFormatter(date: any) {
        return issueDateFormatter(date);
    }

    public scrollToComment(commentUuid: string) {
        this.close();
        this.$router.push({
            name: RouterNames.ProjectIssueTracker,
            params: {
                language: this.language,
                licenseId: String(this.licenseId),
                projectId: String(this.projectId),
            },
            hash: '#' + commentUuid,
        });
    }

    public clickOutsideHandler() {
        this.close();
    }
}
