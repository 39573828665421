import { RESPONSE } from '@/constants';
import { i18n } from '@/services';

export function changePasswordErrorMessage(error: any) {
    switch (error?.result) {
        case RESPONSE.WEAK_PASSWORD:
            return i18n.t('changePassword.errors.weakPassword');
        case RESPONSE.REPEATING_PASSWORD:
            return i18n.t('changePassword.errors.repeatingPassword');
        default:
            return error?.message || '';
    }
}
