






import { Component, Prop, Vue } from 'vue-property-decorator';
import SkeletonDefaultTable from '@/components/common/skeleton/SkeletonDefaultTable.vue';
import SkeletonFilterPanel from '@/components/common/skeleton/SkeletonFilterPanel.vue';
import SkeletonIssue from '@/components/common/skeleton/SkeletonIssue.vue';
import SkeletonMembersTable from '@/components/common/skeleton/SkeletonMembersTable.vue';
import SkeletonProjectsTable from '@/components/common/skeleton/SkeletonProjectsTable.vue';
import SkeletonTableHeader from '@/components/common/skeleton/SkeletonTableHeader.vue';
import SkeletonSimple from '@/components/common/skeleton/SkeletonSimple.vue';

const componentByContent = {
    'default-table': 'SkeletonDefaultTable',
    'filter-panel': 'SkeletonFilterPanel',
    'issue': 'SkeletonIssue',
    'members-table': 'SkeletonMembersTable',
    'projects-table': 'SkeletonProjectsTable',
    'table-header': 'SkeletonTableHeader',
    'simple': 'SkeletonSimple',
} as { [key: string]: string };

@Component({
    components: {
        SkeletonDefaultTable,
        SkeletonFilterPanel,
        SkeletonIssue,
        SkeletonMembersTable,
        SkeletonProjectsTable,
        SkeletonTableHeader,
        SkeletonSimple,
    },
})
export default class WsSkeletonLoader extends Vue {
    @Prop({ type: String }) public content!: 'issue' | 'projects-table' | 'members-table' | 'default-table' | 'table-header' | 'filter-panel';
    @Prop({ type: Object, default: () => ({}) }) public options!: { [key: string]: any };

    public getComponentByContent(contentKey: string): string {
        return componentByContent[contentKey] || 'SkeletonBone';
    }
}
