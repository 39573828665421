import _ from 'lodash';
import { AuthMethodType } from '@/constants';

interface ILoginLicense {
    allowApiAccess: boolean;
    authType: AuthMethodType;
    licenseId: number;
    licenseName: string;
    licenseUuid: string;
    spEntityId: string;
}

export class LoginLicense {
    public allowApiAccess: boolean;
    public authMethodType: AuthMethodType;
    public id: number;
    public name: string;
    public uuid: string;
    public spEntityId: string;

    constructor(loginLicense: ILoginLicense) {
        this.allowApiAccess = loginLicense.allowApiAccess;
        this.authMethodType = loginLicense.authType;
        this.id = loginLicense.licenseId;
        this.name = _.unescape(loginLicense.licenseName);
        this.uuid = loginLicense.licenseUuid;
        this.spEntityId = loginLicense.spEntityId || '';
    }

    public static instantiate(loginLicense: ILoginLicense) {
        return new LoginLicense(loginLicense);
    }
}
