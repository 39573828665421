













































import { Component } from 'vue-property-decorator';
import IconSvgBase from '@/components/common/icon/IconSvgBase.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

@Component({
    components: {
        WsTooltip,
    },
})
export default class IconSvg24 extends IconSvgBase {}
