import _ from 'lodash';

export enum ChartType {
    pie = 1,
    bar = 2,
    statusFlow = 3,
    timeLine = 4,
    doughnut = 5,
    treeMap = 7,
    scatter = 8,
}

export const ChartTypeList = [
    ChartType.pie,
    ChartType.doughnut,
    ChartType.bar,
    ChartType.statusFlow,
    ChartType.timeLine,
    ChartType.treeMap,
    ChartType.scatter,
];

export const ChartTypeListLicense = _.without(ChartTypeList, ChartType.statusFlow);
