
import { Component, Emit, Prop } from 'vue-property-decorator';
import WsInput from '@/components/common/WsInput.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class WsInputNumber extends WsInput {
    @Prop({ default: 'number' }) public type!: 'number';

    @Emit()
    public input(value: string) {
        return Number(String(value).trim()) as any; // hack for avoid type of input emit in WsInput
    }

    @Emit()
    public change(value: string) {
        return Number(String(value).trim()) as any;
    }
}
