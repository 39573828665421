export const DACH = [
    {
        'uuid': '25801317-ee47-4e1d-be26-e5aa4d91c728',
        'title': 'ARC Architektur:',
        'fields': '{"stampAbbr":"ARC"}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '1f9c698c-c8f3-4d76-9ce8-1b6751786a72',
        'nodeRole': 1,
    },
    {
        'uuid': '3e8aebf3-b980-42d1-8279-7949be2b86ce',
        'title': 'ATR Attributemangel:',
        'fields': '{"stampAbbr":"ATR"}',
        'created': '2023-06-06 10:28:03',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8a3a3d50-71be-4ca1-a45c-75596559fdfa',
        'nodeRole': 1,
    },
    {
        'uuid': 'e2ba46bc-2902-4379-b7de-0b8832765e10',
        'title': 'AUS Aussparungen:',
        'fields': '{"stampAbbr":"AUS","stampColor":9}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8aed8616-768f-4c5a-8a12-f00c05135f14',
        'nodeRole': 1,
    },
    {
        'uuid': '7bc6057b-030d-49af-9d8a-0a2f0de678c4',
        'title': 'ELE Elektro:',
        'fields': '{"stampAbbr":"ELE","stampColor":2}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8aed8616-768f-4c5a-8a12-f00c05135f14',
        'nodeRole': 1,
    },
    {
        'uuid': '79a0ec3e-45c7-4df5-8856-d3cb7d64ccc2',
        'title': 'ENG Engineering:',
        'fields': '{"stampAbbr":"ENG","stampColor":10}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '5bab044f-c04f-458a-8012-96e364580be3',
        'nodeRole': 1,
    },
    {
        'uuid': '343d70f6-5ad7-4cfe-8749-8bb9fb5e32fb',
        'title': 'FM Facility Management:',
        'fields': '{"stampAbbr":"FM","stampColor":10}',
        'created': '2022-12-21 16:13:21',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '5bab044f-c04f-458a-8012-96e364580be3',
        'nodeRole': 1,
    },
    {
        'uuid': 'd5b0f3fd-31e1-4924-80fa-453dfae31e49',
        'title': 'Foto',
        'fields': '{"stampAbbr":"FOT"}',
        'created': '2021-09-01 09:51:51',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': 'd82a7cd9-8623-4b9d-b960-21487fb559e1',
        'nodeRole': 1,
    },
    {
        'uuid': 'dc59fe61-04dc-4339-8320-b9d4ed1d9703',
        'title': 'HUK Heizung+Kälte:',
        'fields': '{"stampAbbr":"HUK","stampColor":1}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8aed8616-768f-4c5a-8a12-f00c05135f14',
        'nodeRole': 1,
    },
    {
        'uuid': 'db5f7854-9c0c-41c3-bf80-09bef0cb1e3b',
        'title': 'Informations Anfrage:',
        'fields': '{"stampAbbr":"IAN"}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': 'd82a7cd9-8623-4b9d-b960-21487fb559e1',
        'nodeRole': 1,
    },
    {
        'uuid': 'e4679a42-3c63-4ad6-bd35-69600b7c195e',
        'title': 'KAR allgemein:',
        'fields': '{"stampAbbr":"KAR"}',
        'created': '2023-06-06 10:21:17',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '1f9c698c-c8f3-4d76-9ce8-1b6751786a72',
        'nodeRole': 1,
    },
    {
        'uuid': '190316d8-40bb-4643-ba09-2ac3edb3daf1',
        'title': 'KGT allgemein:',
        'fields': '{"stampAbbr":"KGT","stampColor":1}',
        'created': '2023-06-06 10:24:19',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8aed8616-768f-4c5a-8a12-f00c05135f14',
        'nodeRole': 1,
    },
    {
        'uuid': '9a3d1a7d-af0b-4267-8852-d16b76f90170',
        'title': 'KOL Kollisionspendenz Allgemein:',
        'fields': '{"stampAbbr":"KOL"}',
        'created': '2022-12-22 15:25:12',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8a3a3d50-71be-4ca1-a45c-75596559fdfa',
        'nodeRole': 1,
    },
    {
        'uuid': 'e94f060b-8e84-46d7-bd30-3248adf5e5eb',
        'title': 'NTZ Nutzer:',
        'fields': '{"stampAbbr":"NTZ","stampColor":10}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '5bab044f-c04f-458a-8012-96e364580be3',
        'nodeRole': 1,
    },
    {
        'uuid': 'fb5f8062-e8aa-4fa3-8a64-e7bacd92ad69',
        'title': 'QR Stempel: Verortung',
        'fields': '{"stampAbbr":"QR"}',
        'created': '2023-03-22 12:13:22',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': 'd82a7cd9-8623-4b9d-b960-21487fb559e1',
        'nodeRole': 1,
    },
    {
        'uuid': 'dcf07ddd-e48a-440f-982d-3bff595533c5',
        'title': 'RLT Lüftung Klima:',
        'fields': '{"stampAbbr":"RLT","stampColor":7}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8aed8616-768f-4c5a-8a12-f00c05135f14',
        'nodeRole': 1,
    },
    {
        'uuid': '9526b366-4c8c-4363-8398-3cdb3a6b99ce',
        'title': 'SAN Sanitär:',
        'fields': '{"stampAbbr":"SAN","stampColor":5}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8aed8616-768f-4c5a-8a12-f00c05135f14',
        'nodeRole': 1,
    },
    {
        'uuid': '8776c3cb-ff89-4a03-ba07-a0651f155281',
        'title': 'SPR Sprinkler:',
        'fields': '{"stampAbbr":"SPR","stampColor":5}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '8aed8616-768f-4c5a-8a12-f00c05135f14',
        'nodeRole': 1,
    },
    {
        'uuid': '8cdd579a-8961-4ea0-acfb-e67a339b20d1',
        'title': 'TRS Tragwerk Sperrzonen:',
        'fields': '{"stampAbbr":"TRS"}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '1f9c698c-c8f3-4d76-9ce8-1b6751786a72',
        'nodeRole': 1,
    },
    {
        'uuid': 'ba8a89f3-fd94-439a-ad6a-8945255710d2',
        'title': 'TRW Tragwerkplaner:',
        'fields': '{"stampAbbr":"TRW"}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-08-09 10:14:19',
        'parentUuid': '1f9c698c-c8f3-4d76-9ce8-1b6751786a72',
        'nodeRole': 1,
    },
    {
        'uuid': 'd82a7cd9-8623-4b9d-b960-21487fb559e1',
        'title': 'ALLGEMEINES',
        'fields': '{"deadline":"2000-01-01 00:00:00","priority":"major","stampColor":10,"visibility":0,"deadline_offset":{"units":0,"value":14}}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-07-19 16:40:16',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '5bab044f-c04f-458a-8012-96e364580be3',
        'title': 'BH BAUHERR_NUTZER',
        'fields': '{"deadline":"2000-01-01 00:00:00","priority":"major","stampColor":10,"deadline_offset":{"units":0,"value":14}}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-07-19 16:40:54',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '8a3a3d50-71be-4ca1-a45c-75596559fdfa',
        'title': 'KGK KOORD_GESAMTKOORDINATOR',
        'fields': '{"deadline":"2000-01-01 00:00:00","priority":"major","stampColor":6,"deadline_offset":{"units":0,"value":14}}',
        'created': '2020-12-03 17:12:03',
        'updated': '2023-07-19 16:40:58',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '8aed8616-768f-4c5a-8a12-f00c05135f14',
        'title': 'KGT KOORD_GEBAEUDETECHNIK',
        'fields': '{"deadline":"2000-01-01 00:00:00","priority":"major","deadline_offset":{"units":0,"value":14}}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-07-19 16:41:04',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '1f9c698c-c8f3-4d76-9ce8-1b6751786a72',
        'title': 'KOA KOORD_ARCHITEKTUR',
        'fields': '{"deadline":"2000-01-01 00:00:00","priority":"major","stampColor":8,"deadline_offset":{"units":0,"value":14}}',
        'created': '2020-12-30 08:38:20',
        'updated': '2023-07-19 16:41:08',
        'parentUuid': null,
        'nodeRole': 2,
    },
];
