






























































import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Component, Ref } from 'vue-property-decorator';
import { AbstractComment, IPreview, Issue, MarkupComment } from '@/models';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import IssueColumn from '@/components/project/issueTracker/IssueColumn.vue';
import TrackerColumnComponentBase from '@/components/project/issueTracker/columns/TrackerColumnComponentBase.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';

const scaleSteps = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3];
const initialStep = 9;
const scrollSize = 6;

@Component({
    name: 'TrackerPreview',
    components: {
        WsTooltip,
        IconSvg24,
        IssueColumn,
    },
})
export default class TrackerPreview extends TrackerColumnComponentBase {
    public currentStep = initialStep;
    public oldStep = initialStep;
    public readonly wsImageId = `preview-image-${uuidv4()}`;
    public readonly realImageId = `ws-image-${this.wsImageId}`;
    public startFit = true; // just for starting picture size detection
    @Ref('container') public readonly $container!: HTMLDivElement;

    public isVerticalFit = false;

    get isAllowEditMarkup() {
        const comments = (this.currentIssueComments as any);
        return comments.filter((comment: any) => comment.markup)[comments.filter((comment: any) => comment.markup).length - 1];
    }

    get image(): HTMLImageElement {
        return document.getElementById(this.realImageId) as HTMLImageElement;
    }

    get scale() {
        return scaleSteps[this.currentStep];
    }

    get verticalRate() {
        return this.image.naturalHeight / this.$container.offsetHeight;
    }

    get horizontalRate() {
        return this.image.naturalWidth / this.$container.offsetWidth;
    }

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get currentIssue(): Issue {
        return this.$store.getters.selectedIssueByProjectId(this.projectId) || {};
    }

    get currentIssueComments(): AbstractComment[] {
        return this.$store.getters.commentsByIssue(this.projectId, this.currentIssue.uuid);
    }

    get issuePreview(): IPreview|null {
        let preview: IPreview|null = null;
        _.forEachRight(this.currentIssueComments, (comment: AbstractComment) => {
            if (comment instanceof MarkupComment) {
                preview = comment.preview;
                return false;
            }
        });
        return preview || this.currentIssue.preview;
    }

    public zoomIn() {
        if (this.currentStep < scaleSteps.length - 2) {
            this.oldStep = this.currentStep;
            this.currentStep++;
            this.updateSize();
        }
    }

    public zoomOut() {
        if (this.currentStep > 1) {
            this.oldStep = this.currentStep;
            this.currentStep--;
            this.updateSize();
        }
    }

    public fitZoom() {
        // When zoom is less then 100% the problem with pixels rounding occurs, so image become 1 pixel smaller than container.
        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        this.currentStep = initialStep;
        this.startFit = false;
        const pixelDensitiyOffset = browserZoomLevel < 100 && (this.image.height !== this.$container.clientHeight) ? 1 : 0;
        const isImageWidthLessThanParentWidth = this.image.width < this.$container.clientWidth;
        const isImageHeightEqualParentHeight = this.image.height + pixelDensitiyOffset === this.$container.clientHeight;
        this.isVerticalFit = isImageWidthLessThanParentWidth || isImageHeightEqualParentHeight;
        this.isVerticalFit ? this.setFitHorizontal() : this.setFitVertical();
    }

    public setFitHorizontal() {
        const willBeVerticalScroll = Number(this.verticalRate > this.horizontalRate);
        const scrollShift = willBeVerticalScroll * scrollSize;
        const containerWidth = this.$container.offsetWidth - scrollShift;
        this.image.height = this.image.naturalHeight * (containerWidth / this.image.naturalWidth);
        this.image.width = containerWidth;
    }

    public setFitVertical() {
        const willBeHorizontalScroll = Number(this.verticalRate < this.horizontalRate);
        const scrollShift = willBeHorizontalScroll * scrollSize;
        const containerHeight = this.$container.offsetHeight - scrollShift;
        this.image.width = this.image.naturalWidth * (containerHeight / this.image.naturalHeight);
        this.image.height = containerHeight;
    }

    public updateSize() {
        this.image.width = this.newSize(this.image.width);
        this.image.height = this.newSize(this.image.height);
        this.startFit = false;
    }

    public newSize(size: number) {
        return size / scaleSteps[this.oldStep] * this.scale;
    }

    public editMarkup() {
        window.dispatchEvent(new Event('editMarkup'));
    }

    public onWheel(event: WheelEvent) {
        const eventElement = event.target as HTMLElement | null;
        if (eventElement !== null && eventElement.id !== this.realImageId) {
            return;
        }

        if (event.deltaY > 0) {
            this.zoomOut();
        } else {
            this.zoomIn();
        }
    }

    public created() {
        this.eventListeners.add({ event: 'wheel', handler: this.onWheel });
    }
}
