export enum TimePeriodForTimeLine {
    fourWeeks = 5,
    eightWeeks = 1,
    sixMonths = 2,
    year = 3,
    threeYears = 4,
}

export const TimePeriodForTimeLineList = [
    TimePeriodForTimeLine.fourWeeks,
    TimePeriodForTimeLine.eightWeeks,
    TimePeriodForTimeLine.sixMonths,
    TimePeriodForTimeLine.year,
    TimePeriodForTimeLine.threeYears,
];
