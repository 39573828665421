


























































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class WsInput extends VuetifyElement {
    @Prop({ default: 'text' }) public type!: 'text' | 'password' | 'email' | 'number';
    @Prop({ type: Boolean, default: false }) public showPassword!: boolean;
    @Prop({ type: Boolean, default: false }) public autofocus!: boolean;
    @Prop({ type: Boolean, default: false }) public validateOnBlur!: boolean;
    @Prop({ type: Number, default: 1024 }) public maxlength!: number;
    @Prop({ type: String }) public name!: string;
    @Prop() public appendIcon!: string;
    @Prop({ default: -Infinity }) public min!: number;
    @Prop({ default: Infinity }) public max!: number;
    @Prop({ type: String }) public inputDataTest!: string;
    @Prop({ type: String }) public labelDataTest!: string;
    @Prop({ type: String }) public errorDataTest!: string;

    @Prop({ type: Boolean }) public new!: boolean;
    @Prop({ type: Boolean }) public small!: boolean;
    @Prop({ type: String }) public placeholder!: string;
    @Prop({ type: Boolean, default: false }) public search!: boolean;
    @Prop({ type: Boolean, default: false }) public isLimit!: boolean;

    public showText = false;

    @Emit()
    public input(value: string) {
        return value?.trim();
    }

    @Emit()
    public change(value: string) {
        return value?.trim();
    }

    @Emit()
    public enter() {
        return this.model.startDate;
    }

    @Emit()
    public click() {
        return;
    }

    @Emit('blur')
    public onBlur() {
        if (this.errorDataTest) {
            this.addErrorMessageDataTest();
        }
        return;
    }

    @Emit('error')
    public onError(value: boolean) {
        return value;
    }

    get valueLength() {
        return this.model?.length || 0;
    }

    get localType() {
        if (this.type === 'password') {
            return this.showText ? 'text' : 'password';
        }
        return this.type;
    }

    get icon() {
        if (this.appendIcon) {
            return this.appendIcon;
        }
        if (this.showPassword) {
            return this.showText ? 'mdi-eye' : 'mdi-eye-off';
        }
        return '';
    }

    get isNew() {
        return this.new;
    }

    get spacing() {
        if (this.small) {
            return 'small';
        }
        return 'medium';
    }

    @Watch('errorMessages', { immediate: true })
    public onChangeErrorMessages(value: TranslateResult[] | TranslateResult) {
        if (value && this.errorDataTest) {
            this.addErrorMessageDataTest();
        }
    }

    public mounted() {
        if (this.inputDataTest) {
            this.addInputDataTest();
        }

        if (this.labelDataTest) {
            this.addLabelDataTest();
        }
    }

    public addInputDataTest() {
        const vTextFieldEl = this.$refs?.vtextfield as Vue;
        const inputEl = vTextFieldEl?.$refs?.input as HTMLInputElement;

        if (!inputEl) {
            return;
        }

        inputEl.dataset.test = this.inputDataTest;
    }

    public addLabelDataTest() {
        const vTextFieldEl = this.$refs?.vtextfield as Vue;
        const labelEl = vTextFieldEl?.$refs?.label as HTMLInputElement;

        if (!labelEl) {
            return;
        }

        labelEl.dataset.test = this.labelDataTest;
    }

    public onUpdateError(value: boolean) {
        this.onError(value);
        this.addErrorMessageDataTest();
    }

    public addErrorMessageDataTest() {
        this.$nextTick(() => {
            const messagesElement = this.$el.querySelector<HTMLDivElement>('.v-messages__message');

            if (!messagesElement) {
                return;
            }

            messagesElement.dataset.test = this.errorDataTest;
        });
    }
}
