import { PasswordSpec } from '@/constants';

export class PasswordContains {
    constructor(
        private readonly password: string,
    ) {}

    private get atLeastMChars() {
        return this.password.length >= PasswordSpec.MinimumLength;
    }

    private get notMoreThanNChars() {
        return this.password.length <= PasswordSpec.MaximumLength;
    }

    get atLeastMCharsNotMoreThanNChars() {
        return this.atLeastMChars && this.notMoreThanNChars;
    }

    get atLeastOneLowercaseLetter() {
        return /[a-z]/.test(this.password);
    }

    get atLeastOneUppercaseLetter() {
        return /[A-Z]/.test(this.password);
    }

    get atLeastOneNumber() {
        return /\d/.test(this.password);
    }
}
