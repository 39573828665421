
































































































import { Component, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { TRulesObj } from '@/types/TRulesObj';
import { AmplitudeEvent, RESPONSE, RouterNames } from '@/constants';
import UserApi from '@/api/user.api';
import { amplitudeLog, FormValidator, isMobile, sanitizeHtml } from '@/services';
import ModalHeader from '@/components/common/ModalHeader.vue';
import PasswordMustContain from '@/components/user/PasswordMustContain.vue';
import WsInput from '@/components/common/WsInput.vue';
import WsCheckbox from '@/components/common/WsCheckbox.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        ModalHeader,
        PasswordMustContain,
        WsInput,
        WsCheckbox,
        WsButton,
    },
    methods: {
        sanitizeHtml,
    },
})
export default class RegisterForm extends Vue {
    public valid = true;
    public isLoading = false;
    public isSending = false;
    public registerForm = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        acceptEula: false,
    };
    public error: TranslateResult = '';
    public successEmail = '';

    public isValidPassword: boolean = false;

    public isValidFirstName: boolean = true;
    public isValidLastName: boolean = true;

    get licenseId() {
        return this.$store.getters.currentLicenseId;
    }
    get language(): string {
        return this.$route.params.language;
    }
    get registerData() {
        return {
            uid: this.$route.params.uid,
            token: this.$route.params.token,
        };
    }
    get rules(): TRulesObj {
        return {
            firstName: [
                FormValidator.required,
                () => this.isValidFirstName || this.$t('errors.invalid_first_name'),
            ],
            lastName: [
                FormValidator.required,
                () => this.isValidLastName || this.$t('errors.invalid_last_name'),
            ],
            email: [
                FormValidator.required,
                FormValidator.email,
            ],
            password: [
                () => this.isValidPassword,
            ],
            passwordConfirm: [
                FormValidator.required,
                (value: string) => this.registerForm.password.trim() === value.trim() || this.$t('errors.pass_dont_match'),
            ],
            acceptEula: [
                FormValidator.required,
            ],
        };
    }

    public created() {
        amplitudeLog(AmplitudeEvent.startRegistration);

        if (this.registerData.uid && this.registerData.token) {
            this.isLoading = true;
            UserApi.postCheckInvite(this.registerData).then((response) => {
                this.registerForm.email = response.email;
                this.registerForm.firstName = response.firstname;
                this.registerForm.lastName = response.lastname;
            }).catch((error) => {
                switch (error.result) {
                    case RESPONSE.EXPIRED_CODE:
                        this.error = this.$t('errors.registrationLinkExpired');
                        this.successEmail = error.data.email;
                        break;
                    case RESPONSE.ALREADY_INVITED:
                        this.error = this.$t('UserExists', { email: error.data.email });
                        this.successEmail = error.data.email;
                        break;
                    case RESPONSE.USER_NOT_EXISTS:
                    case RESPONSE.WRONG_CODE:
                    case RESPONSE.INVALID_DATA:
                        this.error = this.$t('errors.invalid_url');
                        break;
                    default:
                        this.error = this.$t('errors.unexpected');
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }

    public close() {
        this.$store.dispatch('navigateBack');
    }
    public secondaryValidate() {
        if (this.registerForm.password && this.registerForm.password === this.registerForm.passwordConfirm) {
            const form = this.$refs.registerForm as HTMLFormElement;
            form.validate();
        }
    }
    public async register(): Promise<void> {
        const form = this.$refs.registerForm as HTMLFormElement;
        if (!form.validate()) {
            return;
        }

        amplitudeLog(AmplitudeEvent.submitRegistration);

        this.isSending = true;
        const params = {
            email: this.registerForm.email,
            password: this.registerForm.password.trim(),
            firstname: this.registerForm.firstName,
            lastname: this.registerForm.lastName,
        };

        let postRegisterResponse;
        try {
            postRegisterResponse = await UserApi.postRegister(params, this.registerData.token);
        } catch (error) {
            if (error.result === RESPONSE.ALREADY_INVITED) {
                this.error = this.$t('UserExists', { email: error?.data?.email || '' });
                this.successEmail = error?.data?.email;
            }
            const invalidFields = error.message.split(/'/).filter((chunk: string, idx: number) => idx % 2);
            this.isSending = false;
            this.isValidFirstName = !invalidFields.includes('firstname');
            this.isValidLastName = !invalidFields.includes('lastname');
            form.validate();
            return;
        }

        this.$store.commit('setRegionId', postRegisterResponse.region);
        await this.$store.dispatch('authorize', { email: params.email, password: params.password });
        await this.$store.dispatch('downloadRegionServiceData');
        await this.$store.dispatch('loadLicenses');
        if (!this.$store.getters.licensesWithValidAuth.length) {
            this.$router.push({
                name: RouterNames.SelectLicense,
                params: {
                    language: this.language,
                },
            });
        } else {
            this.$store.commit('setIsShowBoardingWelcome', !isMobile());
            this.$store.dispatch('connectNotifier');
            this.$router.push({
                name: RouterNames.MyLicenseProjects,
                params: {
                    licenseId: this.licenseId,
                    language: this.language,
                },
            });
        }
    }

    public passwordValidation(value: boolean) {
        this.isValidPassword = value;
    }

    public onChangeFirstName() {
        this.isValidFirstName = true;
    }
    public onChangeLastName() {
        this.isValidLastName = true;
    }
}
