
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IActionBarItem } from '@/types/ui';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
  components: {
    IconSvg24,
  },
})
export default class ActionBar extends Vue {
  @Prop({ default: () => [] }) public items!: IActionBarItem[];
}
