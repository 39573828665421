interface IBulkResultObject {
    [uuid: string]: number | string;
}

export interface IBulkResult {
    ok?: IBulkResultObject;
    error?: IBulkResultObject;
}

export class BulkResult {
    public ok: IBulkResultObject;
    public error: IBulkResultObject;

    constructor(params: IBulkResult) {
        this.ok = params.ok || {};
        this.error = params.error || {};
    }
}
