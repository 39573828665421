export const UK1 = [
    {
        'uuid': 'ad693de4-3d13-41e5-a1c3-ba9d747d5800',
        'title': 'Modelling Issue',
        'fields': '{"stampAbbr":"A1"}',
        'created': '2023-06-06 07:21:53',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'e3e1f821-23d3-4c34-84fa-ccf2d3ac64b0',
        'nodeRole': 1,
    },
    {
        'uuid': '7c9357eb-9651-4fdd-838c-cd6ada379d41',
        'title': 'Drawing Issue',
        'fields': '{"stampAbbr":"A2"}',
        'created': '2023-06-06 07:22:06',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'e3e1f821-23d3-4c34-84fa-ccf2d3ac64b0',
        'nodeRole': 1,
    },
    {
        'uuid': '4e67ac96-fc17-46d1-bfef-8772e677b0cf',
        'title': 'Design Review',
        'fields': '{"stampAbbr":"A3"}',
        'created': '2023-06-06 07:22:26',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'e3e1f821-23d3-4c34-84fa-ccf2d3ac64b0',
        'nodeRole': 1,
    },
    {
        'uuid': '96a45735-8f9e-446c-bf0a-782e0a0b590b',
        'title': 'Architect Clash',
        'fields': '{"stampAbbr":"A4"}',
        'created': '2023-06-06 07:22:39',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'e3e1f821-23d3-4c34-84fa-ccf2d3ac64b0',
        'nodeRole': 1,
    },
    {
        'uuid': 'dff1a966-ece0-4ee3-891d-76ba77ea459f',
        'title': 'Modelling Issue',
        'fields': '{"stampAbbr":"EL1"}',
        'created': '2023-06-06 07:24:50',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '1e7c8ed0-7980-42f5-ba2b-80b1d0bb2c86',
        'nodeRole': 1,
    },
    {
        'uuid': '4d7d0b1a-61c6-47f9-ad7a-0a10fb219465',
        'title': 'Drawing Issue',
        'fields': '{"stampAbbr":"EL2"}',
        'created': '2023-06-06 07:25:24',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '1e7c8ed0-7980-42f5-ba2b-80b1d0bb2c86',
        'nodeRole': 1,
    },
    {
        'uuid': 'c37dd02d-89cf-4954-8d0f-5a50883c4e18',
        'title': 'Design Review',
        'fields': '{"stampAbbr":"EL3"}',
        'created': '2023-06-06 07:25:31',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '1e7c8ed0-7980-42f5-ba2b-80b1d0bb2c86',
        'nodeRole': 1,
    },
    {
        'uuid': 'fa1309e2-c452-4540-83b2-29028ab27109',
        'title': 'Electrical Clash',
        'fields': '{"stampAbbr":"EL4"}',
        'created': '2023-06-06 07:25:40',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '1e7c8ed0-7980-42f5-ba2b-80b1d0bb2c86',
        'nodeRole': 1,
    },
    {
        'uuid': '96704a62-5c08-46d4-b2dc-141b3499e943',
        'title': 'Modelling Issue',
        'fields': '{"stampAbbr":"M1"}',
        'created': '2023-06-06 07:24:12',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'b703797c-6bec-4c7a-b014-543b50c3f65c',
        'nodeRole': 1,
    },
    {
        'uuid': '0c101f42-5da3-417a-83ba-e72f9e6ae723',
        'title': 'Drawing Issue',
        'fields': '{"stampAbbr":"M2"}',
        'created': '2023-06-06 07:24:24',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'b703797c-6bec-4c7a-b014-543b50c3f65c',
        'nodeRole': 1,
    },
    {
        'uuid': '7f58e7d2-3163-4ece-85c8-a3004a9e1037',
        'title': 'Design Review',
        'fields': '{"stampAbbr":"M3"}',
        'created': '2023-06-06 07:24:31',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'b703797c-6bec-4c7a-b014-543b50c3f65c',
        'nodeRole': 1,
    },
    {
        'uuid': '135f773f-376d-430f-8e65-150f83ee37c0',
        'title': 'Mechanical Clash',
        'fields': '{"stampAbbr":"M4"}',
        'created': '2023-06-06 07:24:36',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'b703797c-6bec-4c7a-b014-543b50c3f65c',
        'nodeRole': 1,
    },
    {
        'uuid': '78c44186-faf4-4da2-9300-8157378a55fd',
        'title': 'Modelling Issue',
        'fields': '{"stampAbbr":"P1"}',
        'created': '2023-06-06 07:25:54',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'e713bd93-f569-43c5-bba1-f08039cb4560',
        'nodeRole': 1,
    },
    {
        'uuid': '64c41c16-51d8-4b08-bdbd-d2043dceacce',
        'title': 'Drawing Issue',
        'fields': '{"stampAbbr":"P2"}',
        'created': '2023-06-06 07:26:12',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'e713bd93-f569-43c5-bba1-f08039cb4560',
        'nodeRole': 1,
    },
    {
        'uuid': 'a65ee4db-1608-4f16-8585-c2987321c3bf',
        'title': 'Design Review',
        'fields': '{"stampAbbr":"P3"}',
        'created': '2023-06-06 07:26:19',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'e713bd93-f569-43c5-bba1-f08039cb4560',
        'nodeRole': 1,
    },
    {
        'uuid': '01ef6d53-312e-48bc-b9ba-b4167aac6561',
        'title': 'Pipework Clash',
        'fields': '{"stampAbbr":"P4"}',
        'created': '2023-06-06 07:26:26',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': 'e713bd93-f569-43c5-bba1-f08039cb4560',
        'nodeRole': 1,
    },
    {
        'uuid': '0f75f241-1f19-4503-aac6-e37d9f885230',
        'title': 'Modelling Issue',
        'fields': '{"stampAbbr":"S1"}',
        'created': '2023-06-06 07:22:50',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '6afdcaff-5305-4b1f-b57f-5d03b9769719',
        'nodeRole': 1,
    },
    {
        'uuid': '13f332cc-b843-4d44-9472-d126b642d176',
        'title': 'Drawing Issue',
        'fields': '{"stampAbbr":"S2"}',
        'created': '2023-06-06 07:22:59',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '6afdcaff-5305-4b1f-b57f-5d03b9769719',
        'nodeRole': 1,
    },
    {
        'uuid': 'e9913432-4c7b-409b-9029-dc5daecd369d',
        'title': 'Design Review',
        'fields': '{"stampAbbr":"S3"}',
        'created': '2023-06-06 07:23:07',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '6afdcaff-5305-4b1f-b57f-5d03b9769719',
        'nodeRole': 1,
    },
    {
        'uuid': '52980192-b2c9-413c-bf54-2cc61ac95392',
        'title': 'Structural Clash',
        'fields': '{"stampAbbr":"S4"}',
        'created': '2023-06-06 07:23:15',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '6afdcaff-5305-4b1f-b57f-5d03b9769719',
        'nodeRole': 1,
    },
    {
        'uuid': 'de2f6c19-119a-4348-8181-e60fd168f2ca',
        'title': 'Modelling Issue',
        'fields': '{"stampAbbr":"SC1"}',
        'created': '2023-06-06 07:26:48',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '4e7c8675-ac8b-4306-b3c2-93f803efd1c5',
        'nodeRole': 1,
    },
    {
        'uuid': '01b3d304-9633-4450-9736-59545de6f44a',
        'title': 'Drawing Issue',
        'fields': '{"stampAbbr":"SC2"}',
        'created': '2023-06-06 07:27:04',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '4e7c8675-ac8b-4306-b3c2-93f803efd1c5',
        'nodeRole': 1,
    },
    {
        'uuid': '0c65b653-68e2-4604-95f1-52eff85f20f0',
        'title': 'Design Review',
        'fields': '{"stampAbbr":"SC3"}',
        'created': '2023-06-06 07:27:13',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '4e7c8675-ac8b-4306-b3c2-93f803efd1c5',
        'nodeRole': 1,
    },
    {
        'uuid': 'fbc77a39-3eb5-4ed1-bd3c-a52719763230',
        'title': 'Subcontractor Clash',
        'fields': '{"stampAbbr":"SC4"}',
        'created': '2023-06-06 07:27:20',
        'updated': '2023-08-09 10:49:53',
        'parentUuid': '4e7c8675-ac8b-4306-b3c2-93f803efd1c5',
        'nodeRole': 1,
    },
    {
        'uuid': 'e3e1f821-23d3-4c34-84fa-ccf2d3ac64b0',
        'title': '1. Architectural',
        'fields': '{"stampColor":7}',
        'created': '2023-06-06 07:11:09',
        'updated': '2023-06-09 14:01:59',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '6afdcaff-5305-4b1f-b57f-5d03b9769719',
        'title': '2. Structural',
        'fields': '{"stampColor":5}',
        'created': '2023-06-06 07:11:15',
        'updated': '2023-06-09 14:02:21',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'b703797c-6bec-4c7a-b014-543b50c3f65c',
        'title': '3. Mechanical',
        'fields': '{"stampColor":9}',
        'created': '2023-06-06 07:11:19',
        'updated': '2023-06-09 14:02:35',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '1e7c8ed0-7980-42f5-ba2b-80b1d0bb2c86',
        'title': '4. Electrical',
        'fields': '{"stampColor":2}',
        'created': '2023-06-06 07:11:23',
        'updated': '2023-06-09 14:02:54',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': 'e713bd93-f569-43c5-bba1-f08039cb4560',
        'title': '5. Pipework',
        'fields': '[]',
        'created': '2023-06-06 07:11:44',
        'updated': '2023-06-06 07:11:44',
        'parentUuid': null,
        'nodeRole': 2,
    },
    {
        'uuid': '4e7c8675-ac8b-4306-b3c2-93f803efd1c5',
        'title': '6. Subcontractor',
        'fields': '{"stampColor":10}',
        'created': '2023-06-06 07:11:52',
        'updated': '2023-06-09 14:03:20',
        'parentUuid': null,
        'nodeRole': 2,
    },
];
