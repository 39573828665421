import Vue from 'vue';
import LicenseApi from '@/api/license.api';
import ProjectApi from '@/api/project.api';
import { MemberForApproval } from '@/models';
import { makeMembersForApproval } from '@/services';

interface IMembersApprovalStorage {
    licenseMembersForApprovalObj: { [licenseId: string]: MemberForApproval[] };
    projectMembersForApprovalObj: { [projectId: string]: MemberForApproval[] };
    isLoadingLicenseMembersForApprovalObj: { [licenseId: string]: boolean };
    isLoadingProjectMembersForApprovalObj: { [projectId: string]: boolean };
    isReadyDataForApprovalObj: { [licenseId: string]: boolean };
    isSendingRejectPendingMembersObj: { [licenseId: string]: boolean };
}

export default {
    state: {
        licenseMembersForApprovalObj: {},
        projectMembersForApprovalObj: {},
        isLoadingLicenseMembersForApprovalObj: {},
        isLoadingProjectMembersForApprovalObj: {},
        isReadyDataForApprovalObj: {},
        isSendingRejectPendingMembersObj: {},
    } as IMembersApprovalStorage,
    getters: {
        licenseMembersForApprovalByLicenseId(state: IMembersApprovalStorage): (licenseId: number) => MemberForApproval[] {
            return (licenseId) => (state.licenseMembersForApprovalObj[licenseId] || []);
        },
        projectMembersForApprovalByProjectId(state: IMembersApprovalStorage): (projectId: number) => MemberForApproval[] {
            return (projectId: number) => (state.projectMembersForApprovalObj[projectId] || []);
        },
        isLoadingLicenseMembersApprovalByLicenseId(state: IMembersApprovalStorage): (licenseId: number) => boolean {
            return (licenseId) => state.isLoadingLicenseMembersForApprovalObj[licenseId];
        },
        isLoadingProjectMembersApprovalByProjectId(state: IMembersApprovalStorage): (projectId: number) => boolean {
            return (licenseId) => state.isLoadingProjectMembersForApprovalObj[licenseId];
        },
        isReadyDataForApprovalByLicenseId(state: IMembersApprovalStorage): (licenseId: number) => boolean {
            return (licenseId: number) => state.isReadyDataForApprovalObj[licenseId];
        },
        isSendingRejectPendingMembersByLicenseId(state: IMembersApprovalStorage): (licenseId: number) => boolean {
            return (licenseId: number) => state.isSendingRejectPendingMembersObj[licenseId];
        },
    },
    mutations: {
        setLicenseMembersForApproval(state: IMembersApprovalStorage, { licenseId, membersForApproval }: { licenseId: number, membersForApproval: MemberForApproval[]}) {
            Vue.set(state.licenseMembersForApprovalObj, licenseId, membersForApproval);
        },
        setProjectMembersForApproval(state: IMembersApprovalStorage, { projectId, membersForApproval }: { projectId: number, membersForApproval: MemberForApproval[] }) {
            Vue.set(state.projectMembersForApprovalObj, projectId, membersForApproval);
        },
        setIsLoadingLicenseMembersForApproval(state: IMembersApprovalStorage, { licenseId, value }: { licenseId: number, value: boolean }) {
            Vue.set(state.isLoadingLicenseMembersForApprovalObj, licenseId, value);
        },
        setIsLoadingProjectMembersForApproval(state: IMembersApprovalStorage, { projectId, value }: { projectId: number, value: boolean }) {
            Vue.set(state.isLoadingProjectMembersForApprovalObj, projectId, value);
        },
        setIsReadyDataForApprovalObj(state: IMembersApprovalStorage, { licenseId, value }: { licenseId: number, value: boolean }) {
            Vue.set(state.isReadyDataForApprovalObj, licenseId, value);
        },
        setIsSendingRejectPendingMembers(state: IMembersApprovalStorage, { licenseId, value }: { licenseId: number, value: boolean }) {
            Vue.set(state.isSendingRejectPendingMembersObj, licenseId, value);
        },
    },
    actions: {
        loadLicenseMembersForApproval({ state, commit }: any, { licenseId, isForce = false }: { licenseId: number, isForce: boolean }): Promise<any> {
            return new Promise((resolve, reject) => {
                if (state.isLoadingLicenseMembersForApprovalObj[licenseId]) {
                    return;
                }

                if (!isForce && state.licenseMembersForApprovalObj[licenseId]) {
                    resolve(state.licenseMembersForApprovalObj[licenseId]);
                    return;
                }

                commit('setIsLoadingLicenseMembersForApproval', { licenseId, value: true });
                LicenseApi.getPendingMembers(licenseId).then((response) => {
                    const membersForApproval: MemberForApproval[] = makeMembersForApproval(response);
                    commit('setLicenseMembersForApproval', { licenseId, membersForApproval });
                    resolve(membersForApproval);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingLicenseMembersForApproval', { licenseId, value: false });
                });
            });
        },
        loadProjectMembersForApproval({ state, commit }: any, { projectId, isForce = false }: { projectId: number, isForce: boolean }): Promise<any> {
            return new Promise((resolve) => {
                if (state.isLoadingProjectMembersForApprovalObj[projectId]) {
                    return;
                }

                if (!isForce && state.projectMembersForApprovalObj[projectId]) {
                    resolve(state.projectMembersForApprovalObj[projectId]);
                    return;
                }

                commit('setIsLoadingProjectMembersForApproval', { projectId, value: true });

                ProjectApi.getPendingMembers(projectId).then((response) => {
                    const membersForApproval: MemberForApproval[] = makeMembersForApproval(response);

                    commit('setProjectMembersForApproval', { projectId, membersForApproval });
                    resolve(membersForApproval);
                }).catch(() => {
                    resolve([]);
                }).finally(() => {
                    commit('setIsLoadingProjectMembersForApproval', { projectId, value: false });
                });
            });
        },
        sendRejectPendingMembers({ commit }: any, { licenseId, projectUuid, emails, message }: { licenseId: number, projectUuid: string, emails: string[], message: string }) {
            commit('setIsSendingRejectPendingMembers', { licenseId, value: true });
            return LicenseApi.rejectPendingMembers({ licenseId, projectUuid, emails, message })
                .then((response) => {
                    commit('setIsSendingRejectPendingMembers', { licenseId, value: false });
                    return response;
                });
        },
        async handleDataForApprovalPromises({ commit }: any, { licenseId, promises }: { licenseId: number, promises: Array<Promise<any>> }) {
            commit('setIsReadyDataForApprovalObj', { licenseId, value: false });
            await Promise.all(promises);
            commit('setIsReadyDataForApprovalObj', { licenseId, value: true });
        },
    },
};
