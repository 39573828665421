










































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    components: {
        IconSvg16,
    },
})
export default class WsFileInput extends Vue {
    @Prop() public value: File[] | null = null;
    @Prop({ type: String, default: '*' }) public accept!: string;
    @Prop({ default: '' }) public label!: TranslateResult;
    @Prop({ type: Boolean, default: true }) public multiple!: boolean;
    @Prop({ type: Boolean, default: true }) public singleLine!: boolean;
    @Prop({ type: Boolean, default: false }) public hideIcon!: boolean;
    @Prop({ type: Boolean, default: true }) public smallChips!: boolean;
    @Prop({ type: Boolean, default: false }) public counter!: boolean;
    @Prop({ type: Boolean, default: false }) public showSize!: boolean;
    @Prop({ type: Boolean, default: false }) public loading!: boolean;
    @Prop({ type: Boolean, default: true }) public showFileList!: boolean;
    @Prop({ type: Boolean, default: false }) public onlySlot!: boolean;
    @Prop({ type: String, default: '' }) public placeholder!: TranslateResult;
    @Prop({ type: String, default: '$file' }) public appendIcon!: string;

    @Emit('change')
    public change(files: File[]) {
        return files;
    }

    onClick(event: MouseEvent) {
        event.stopPropagation();
    }

    public removeSelected(selection: any) {
        const newValue = [...this.value as File[]];
        newValue.splice(selection.index, 1);

        this.change(newValue);
    }

    public onClickAppendIcon() {
        (this.$refs.fileInput as any)?.$refs?.input.click();
    }
}
