





















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class WsEdit extends Vue {
    @Prop({ default: '' }) public title!: string;

    @Emit()
    public action() {
        return;
    }

}
