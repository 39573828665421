




























































































import _ from 'lodash';
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AmplitudeEvent, BusEvent, Color } from '@/constants';
import { EventTypesError, EventTypesGenerated } from '@/constants/EventTypeEnum';
import { NotificationRecord, UnreachableError } from '@/models';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog, dateFullMonthFormatter, getOperationId, i18n, sortArrayOfObjects } from '@/services';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';
import NotificationItem from '@/components/header/NotificationItem.vue';
import WsSwitch from '@/components/common/WsSwitch.vue';
import WsLazyRender from '@/components/common/WsLazyRender.vue';
import NotificationFilter from '@/components/header/NotificationFilter.vue';
import WsTableButton from '@/components/common/WsTableButton.vue';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';
import WsTabsHeader from '@/components/common/WsTabsHeader.vue';

enum Tab {
    issues = 'issues',
    reports = 'reports',
    other = 'other',
}

function getRecordsWithDates(records: NotificationRecord[]) {
    const categories = _.groupBy(records, (record) => record.startOfDay);
    const array = _.entries(categories).map(([date, recordsChunk]) => {
        const numberDate = Number(date);
        const formattedDate = formatDate(numberDate);
        const sortedRecords = sortArrayOfObjects(recordsChunk, 'timestamp');
        return [
            {
                date: formattedDate,
                operationId: getOperationId(numberDate),
                timestamp: numberDate,
            },
            ...sortedRecords,
        ];
    });
    return _.orderBy(array, [(arr) => -arr[0].timestamp]).flat();
}

function formatDate(timestamp: number) {
    const timestampMoment = moment(timestamp);
    const isToday = timestampMoment.isSame(moment(), 'day');
    return isToday ? i18n.t('Simple_word.today') : dateFullMonthFormatter(timestampMoment);
}

@Component({
    components: {
        WsTooltip,
        IconSvg24,
        NotificationFilter,
        NotificationItem,
        WsLazyRender,
        WsSwitch,
        WsTableButton,
        WsCloseButton,
        WsTabsHeader,
    },
})
export default class NotificationWidget extends Vue {
    public readonly Color = Color;
    public readonly NotificationRecord = NotificationRecord;

    public currentTab: Tab = Tab.issues;
    public currentTabIdx = 0;

    public isOpen = false;
    public isShowNewOnly = false;

    public recordsFromFilters: NotificationRecord[] = [];

    get refreshKey() {
        return JSON.stringify(this.recordsFromFilters) + this.currentTab;
    }

    get licenseId(): number | null {
        return this.$store.getters.currentLicenseId;
    }

    get records(): NotificationRecord[] {
        return this.$store.getters.notificationRecords;
    }

    get unreadRecords() {
        return this.records.filter((record) => !record.isRead);
    }

    get recordsFilteredByUnread() {
        return this.isShowNewOnly ? this.unreadRecords : this.records;
    }

    get recordsFiltered() {
        if (!this.recordsFromFilters.length) {
            return this.recordsFilteredByUnread;
        }
        return _.intersectionBy(this.recordsFilteredByUnread, this.recordsFromFilters, 'operationId');
    }

    get issueRecords() {
        const records = _.filter(this.recordsFiltered, 'isIssue');
        return records.map((record) => {
            const issueGroups = _.groupBy(record.data, 'issueId');
            const normalizedIssueGroups = _.values(issueGroups).map((issueGroup) => {
                return _.maxBy(issueGroup, (group) => moment(group.comments[0].created).unix());
            });
            const newRecord = _.cloneDeep(record);
            newRecord.data = normalizedIssueGroups;
            return newRecord;
        });
    }

    get extendedReportRecords() {
        return _.filter(this.recordsFiltered, 'isExtendedReport');
    }

    get otherRecordsNotExtendedReport() {
        return _.filter(this.recordsFiltered, 'isOtherNormal');
    }

    get currentRecordsWithDates() {
        const currentRecords = (() => {
            switch (this.currentTab) {
                case Tab.issues:
                    return this.issueRecords;
                case Tab.reports:
                    return this.extendedReportRecords;
                case Tab.other:
                    return this.otherRecordsNotExtendedReport;
                default: {
                    const _never: never = this.currentTab;
                    throw new UnreachableError(this.currentTab);
                }
            }
        })();

        return getRecordsWithDates(currentRecords);
    }

    get countNewRecords() {
        return this.unreadRecords.length;
    }

    get countNewIssueRecords() {
        return _.intersectionBy(this.unreadRecords, this.issueRecords, 'operationId').length;
    }

    get countNewExtendedReportRecords() {
        return _.intersection(this.unreadRecords, this.extendedReportRecords).length;
    }

    get countNewOtherRecordsNotExtendedReport() {
        return _.intersection(this.unreadRecords, this.otherRecordsNotExtendedReport).length;
    }

    get countReportsSendToAmplitude() {
        return {
            processing: this.extendedReportRecords.filter((record) => record.isInProgress).length,
            generated:  this.extendedReportRecords.filter((record) => EventTypesGenerated.includes(record.eventType)).length,
            error:  this.extendedReportRecords.filter((record) => EventTypesError.includes(record.eventType)).length,
        };
    }

    get tabs() {
        return [
            {
                tab: Tab.issues,
                translate: 'Notification.issueUpdates',
                count: this.countNewIssueRecords,
            },
            {
                tab: Tab.reports,
                translate: 'Notification.reports',
                count: this.countNewExtendedReportRecords,
            },
            {
                tab: Tab.other,
                translate: 'Notification.other',
                count: this.countNewOtherRecordsNotExtendedReport,
            },
        ];
    }

    @Watch('isOpen')
    public onToggle(value: boolean) {
        this.recordsFromFilters = [];
        eventBus.$emit(BusEvent.isOpenNotifications, value);
    }

    public created() {
        this.$store.dispatch('getNotifications');
    }

    public onFilterAuthorChange(event: NotificationRecord[]) {
        // It is only author filter on this widget, so type is hardcoded here.
        amplitudeLog(AmplitudeEvent.ncFilterChange, { type: 'author' });
        this.recordsFromFilters = event;
    }

    public onTabsChange() {
        amplitudeLog(
            AmplitudeEvent.ncTabClick,
            {
                tabName: this.currentTab,
                ...(this.currentTab === Tab.reports ? this.countReportsSendToAmplitude : {}),
            },
        );
    }

    public markAllAsRead() {
        const unreadOnCurrentTab = this.tabs.find(({ tab }) => tab === this.currentTab)?.count;
        amplitudeLog(AmplitudeEvent.ncReadAll, { tabName: this.currentTab, count: unreadOnCurrentTab });

        this.$store.dispatch('readAllNotifications');

    }

    public read(record: NotificationRecord) {
        this.$store.dispatch('readNotification', record);
    }

    public logOnClickMenuActivator() {
        amplitudeLog(AmplitudeEvent.ncOpen,
            {
                count: this.countNewRecords,
                isShowUnreadOnly: this.isShowNewOnly,
                issuesUpdatesUnread: this.countNewIssueRecords,
                reportsUnread: this.countNewExtendedReportRecords,
                otherUnread: this.countNewOtherRecordsNotExtendedReport,
            },
        );
    }

    public logOnChangeIsShowOnly(isShowNewOnly: boolean) {
        if (isShowNewOnly) {
            amplitudeLog(AmplitudeEvent.ncToggleNew);
        }
    }
}
