export interface IFormSelectBase {
    name: string;
    label: string;

    translationCode?: string;

    multiple?: boolean;
    clearable?: boolean;
    filterable?: boolean;
    defaultFirstOption?: boolean;
    value?: any;
}

export class FormSelectBase {
    public readonly name: string;
    public readonly label: string;
    public readonly translationCode: string;

    public readonly multiple: boolean;
    public readonly clearable: boolean;
    public readonly filterable: boolean;
    public readonly defaultFirstOption: boolean;

    public readonly value: any;

    protected constructor(params: IFormSelectBase) {
        this.name = params.name;
        this.label = params.label;
        this.translationCode = params.translationCode || '';

        this.multiple = params.multiple || false;
        this.clearable = params.clearable || false;
        this.filterable = params.filterable || true;
        this.defaultFirstOption = params.defaultFirstOption || true;
        this.value = params.value;
    }
}
