type TElement = Element | Window | Document | FileReader | null | undefined;
type TFunc = (...args: any[]) => void;

interface IEventListenerParams {
    node?: TElement;
    selector?: string;
    event: string;
    handler: TFunc;
}

export class EventListeners {
    private listeners: EventListener[] = [];

    public add(params: IEventListenerParams) {
        const eventListener = new EventListener(params);
        this.listeners.push(eventListener);
    }

    public flush() {
        this.listeners.forEach((eventListener) => eventListener.flush());
        this.listeners = [];
    }
}

class EventListener {
    private readonly node?: any;
    private readonly selector?: string;
    private readonly event: string;
    private readonly handler: TFunc;

    constructor({ node, selector, event, handler }: IEventListenerParams) {
        this.node = node;
        this.selector = selector;
        this.event = event;
        this.handler = handler;
        this.element?.addEventListener(this.event, this.handler);
    }

    private get element(): TElement {
        if (this.selector) {
            return (this.node || document).querySelector(this.selector);
        } else {
            return this.node || window;
        }
    }

    public flush() {
        this.element?.removeEventListener(this.event, this.handler);
    }
}
