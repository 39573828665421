



















import { Component, Vue } from 'vue-property-decorator';
import SkeletonBone from '@/components/common/skeleton/SkeletonBone.vue';

@Component({
    components: {
        SkeletonBone,
    },
})
export default class SkeletonFilterPanel extends Vue {}
