

















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n, { TranslateResult } from 'vue-i18n';
import { i18n } from '@/services';
import WsTooltip from '@/components/common/WsTooltip.vue';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: { WsButton, WsTooltip },
})
export default class WsFormActionButton extends Vue {
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public loading!: boolean;
    @Prop({ type: Boolean, default: true }) public round!: boolean;
    @Prop({ type: Boolean, default: true }) public plain!: boolean;
    @Prop({ default: 'big' }) public size!: 'small' | 'medium' | 'big';
    @Prop() public tooltip!: VueI18n.TranslateResult; // appear if button enabled
    @Prop({ default: () => i18n.t('Form.requiredFields') }) public tooltipIfDisabled!: VueI18n.TranslateResult; // appear if button disabled
    @Prop({ type: Boolean }) public tooltipStable!: VueI18n.TranslateResult; // this.tooltip should appear every time, ignore tooltipIfDisabled
    @Prop({ default: () => i18n.t('Form.requiredFields') }) public content!: TranslateResult;
    @Prop({ default: '' }) public icon!: string; // https://element.eleme.io/#/en-US/component/icon

    @Emit()
    public click() {
        return;
    }
}
