/**
 * For numbers greater than 10000 produces 10k etc instead.
 * Numbers less than 10000 remain unchanged.
 * 12345 -> 12K
 * 12645 -> 12K
 * 2345 -> 2345
 */
export class NumberFormat {
    public static to10k(num: number) {
        if (num < 10000) {
            return num;
        }
        return Math.floor(num / 1000) + 'K';
    }
    public static to1K(num: number) {
        if (num < 1000) {
            return num;
        }
        if (num < 10000) {
            return Math.round(num / 100) / 10 + 'K';
        }
        return Math.round(num / 1000) + 'K';
    }
}
