import Http from './http';

export interface CodeRequestPayload {
    resourceIdentifier?: string; // sharepoint / asite
}

export interface IntegrationPayload extends CodeRequestPayload {
    code: string;
}

export default class IntegrationsApi {
    public static postAccessCode(service: string, payload: IntegrationPayload): Promise<any> {
        return Http.post(`${service}/access-code/save`, payload);
    }
}
