export const enum IssueSort {
    field = 'field',
    direction = 'direction',
}

export const enum IssueDirections {
    ASC = 'asc',
    DESC = 'desc',
}

export const enum IssueSorts {
    primary = 'primary',
    secondary = 'secondary',
    unread = 'unread',
}

export enum IssueSortsApi {
    Primary = 'Primary',
    Secondary = 'Secondary',
    UnreadFirst = 'UnreadFirst',
}

export enum IssueSortBy {
    assignee = 'assignee',
    created = 'created',
    deadline = 'deadline',
    comment = 'commented',
    update = 'updated',
    priority = 'priority',
    status = 'status',
    clashes = 'clashcount',
}
