export enum IssueTrackerFields {
    customType = 'customType',
    customStatus = 'customStatus',
    status = 'status',
    title = 'title',
    stampColor = 'stampColor',
    stampAbbr = 'stampAbbr',
    priority = 'priority',
    deadline = 'deadline',
    reporter = 'reporter',
    assignee = 'assignee',
    watchers = 'watchers',
    created = 'created',
    watch = 'watch',
    visibility = 'visibility',
    tags = 'tags',
    procore = 'procore',
    procoreStatus = 'procoreStatus',
    stamp = 'stamp',
    clashSourceFile = 'clashSourceFile',
    grid = 'grid',
    level = 'level',
    room = 'room',
    space = 'space',
    area = 'area',
    zone = 'zone',
    cdeLinks = 'cdeLinks',
    deletedAt = 'deletedAt',
}
