import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { CustomStatusNameToLegacyIssueStatus, LegacyIssueStatusToCustomStatusName } from '@/constants/issue/IssueStatus';
import { IssuesGrouping } from '@/constants/IssuesGrouping';
import {
    CachedRawChartData,
    isLegacyRawChartDataStatusFlow,
    isRawChartData,
    isRawChartDataScatter,
    isRawChartDataStatusFlow,
} from '@/models/ChartView';

export class NormalizeStatus {
    public static forward(status: string): string;
    public static forward<T>(value: T): T;
    public static forward(status: any) {
        if (!_.isString(status)) {
            return status;
        }
        return CustomStatusNameToLegacyIssueStatus[status] ?? status;
    }

    public static reverse(status: string): string;
    public static reverse<T>(value: T): T;
    public static reverse(status: any) {
        if (!_.isString(status)) {
            return status;
        }
        return LegacyIssueStatusToCustomStatusName[status] ?? status;
    }

    public static getFromTo(statusPair: string) {
        const index = statusPair.indexOf('-'); // handles the case when data is in legacy form but 'to'-status contains '-'
        const from = statusPair.slice(0, index);
        const to = statusPair.slice(index + 1);
        if (!from || !to) {
            return null;
        }
        return _.mapValues({ from, to }, this.forward);
    }

    public static normalizeKey(statusKey: IssuesGrouping) {
        return statusKey === IssuesGrouping.customStatus ? IssuesGrouping.status : statusKey;
    }

    public static ofFieldsObj(fields: Dict) {
        return _.mapValues(fields, (value, key) => {
            const isStatus = key === IssuesGrouping.status || key === IssuesGrouping.customStatus;
            return isStatus ? this.forward(value) : value;
        });
    }

    public static ofRawChartData(rawChartData: any) {
        if (isRawChartData(rawChartData)) {
            if (isRawChartDataStatusFlow(rawChartData)) {
                const resultItems = rawChartData
                    .resultItems
                    .map(({ aggregate, statusFlowValues }) => ({
                        aggregate,
                        fields: _.mapValues(statusFlowValues, this.forward),
                    }));
                return { ...rawChartData, resultItems };
            }

            if (isLegacyRawChartDataStatusFlow(rawChartData)) {
                const resultItems = rawChartData
                    .resultItems
                    .map(({ aggregate, fields: { status } }) => ({
                        aggregate,
                        fields: this.getFromTo(status),
                    }));
                return { ...rawChartData, resultItems };
            }

            const resultItems = rawChartData
                .resultItems
                .map((item) => ({
                    ...item,
                    fields: this.ofFieldsObj(item.fields),
                }));
            return { ...rawChartData, resultItems };
        }

        if (isRawChartDataScatter(rawChartData)) {
            const groups = rawChartData.groups.map((group) => {
                const fields = this.ofFieldsObj(group.fields);
                return { ...group, fields };
            });
            return { ...rawChartData, groups };
        }

        return rawChartData;
    }

    public static ofCachedRawChartData(cachedRawChartData: CachedRawChartData) {
        if (!cachedRawChartData?.graph) {
            return cachedRawChartData;
        }
        return {
            ...cachedRawChartData,
            graph: this.ofRawChartData(cachedRawChartData.graph),
        };
    }
}
