





















import { Component, Emit } from 'vue-property-decorator';
import { IssuesFilterType, LOCAL_STORAGE_GRID_KEY } from '@/constants';
import { MAX_NOT_COLLAPSED_LENGTH } from '@/constants/IssueTracker';
import { Issue } from '@/models';
import IssueDetailsClashSimple from '@/components/project/issueTracker/details/IssueDetailsClashSimple.vue';
import IssueDetailsItem from '@/components/project/issueTracker/details/IssueDetailsItem.vue';

@Component({
    components: {
        IssueDetailsItem,
    },
})
export default class IssueDetailsClashGrid extends IssueDetailsClashSimple {
    public readonly collapsingKey = LOCAL_STORAGE_GRID_KEY;

    get joinedList(): string {
        return this.currentValue?.join(this.$t('Punctuation.comma'));
    }

    get collapsable() {
        return this.stringLongerThanPossible(this.joinedList) || this.currentValue?.length > 2;
    }

    @Emit()
    public click(value: string) {
        return { value, type: IssuesFilterType.clashGridX as keyof Issue };
    }

    public stringLongerThanPossible(string: string) {
        if (!string) {
            return false;
        }
        return string.length > MAX_NOT_COLLAPSED_LENGTH;
    }
}
