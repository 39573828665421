import { Module } from 'vuex';
import UserApi from '../api/user.api';

export interface IForgotPasswordStorage {
    isSending: boolean;
    isSent: boolean;
    error: string;
    confirm: {
        userId: number;
        code: string;
        error: string;
        changed: boolean;
    };
    forgottenLicenses: IForgottenLicense[];
}

export interface IForgottenLicense {
    licenseName: string;
    authType: string;
    error?: string;
    hostname?: string;
    port?: number;
}

export default {
    state: {
        isSending: false,
        isSent: false,
        error: '',
        confirm: {
            userId: 0,
            code: '',
            error: '',
            changed: false,
        },
        forgottenLicenses: [],
    } as IForgotPasswordStorage,
    getters: {
        forgotFormError(state: IForgotPasswordStorage): string {
            return state.error;
        },
        forgotIsSending(state: IForgotPasswordStorage): boolean {
            return state.isSending;
        },
        forgotIsSent(state: IForgotPasswordStorage): boolean {
            return state.isSent;
        },
        isPasswordChanged(state: IForgotPasswordStorage): boolean {
            return state.confirm.changed;
        },
        forgottenLicenses(state: IForgotPasswordStorage) {
            return state.forgottenLicenses;
        },
    },
    mutations: {
        setForgottenLicenses(state: IForgotPasswordStorage, data: any) {
            state.forgottenLicenses = data;
            state.confirm.userId = data.userId || 0;
            state.confirm.code = data.code || '';
        },
        setConfirmationCodeError(state: IForgotPasswordStorage, errorTranslation: string) {
            state.confirm.error = errorTranslation;
        },
        setPasswordChanged(state: IForgotPasswordStorage, isChangedSuccess: boolean) {
            state.confirm.changed = isChangedSuccess;
        },
        setForgotPasswordFormError(state: IForgotPasswordStorage, error: string = '') {
            state.error = error;
        },
    },
    actions: {
        forgotPassword({ state, commit }: any, email: string) {
            state.isSending = true;
            return UserApi.getForgotPassword(email).then(() => {
                commit('setForgotPasswordFormError');
                state.isSending = false;
                state.isSent = true;
            }).catch((e) => {
                commit('setForgotPasswordFormError', e.message);
                state.isSending = false;
            });
        },
        userLicensesOnForgot({ commit }: any, data: any) {
            return UserApi.postUserLicenses(data).then((response) => {
                    commit('setForgottenLicenses', response);
            });
        },
        setForgottenPassword({ commit }: any, params: any) {
            return UserApi.postChangePassword(params).then(() => {
                commit('setPasswordChanged', true);
                commit('setConfirmationCodeError', '');
            }).catch((e) => {
                commit('setConfirmationCodeError', 'Collocation.internalError');
                throw e;
            });
        },
        checkSNPKey(context, params: any) {
            return UserApi.postCheckSNPKey(params);
        },
    },
} as Module<IForgotPasswordStorage, any>;
