import { MS_IN_SEC } from '@/constants';
import { sleep } from '@/services/AsynchronousSleep';

const emptyFn = () => null;

export async function startTimer(
    seconds: number,
    callbackStart: () => void = emptyFn,
    callbackEnd: () => void = emptyFn,
    callbackSecondsLeft: (secondsLeft: number) => void = emptyFn,
) {
    callbackStart();
    for (let secondsLeft = seconds; secondsLeft > 0; secondsLeft--) {
        callbackSecondsLeft(secondsLeft);
        await sleep(MS_IN_SEC);
    }
    callbackEnd();
}
