import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { FormItemType } from '@/constants';
import { FormSelectBase, IFormSelectBase } from '@/models/forms/FormSelectBase';

export interface IFormSelect extends IFormSelectBase {
    options: Array<string | number> | Dict<string | number>;
}

export class FormSelect extends FormSelectBase {
    public readonly type = FormItemType.select;
    public readonly options: Dict<string | number>;

    constructor(params: IFormSelect) {
        super(params);

        const { options } = params;
        this.options = _.isArray(options) ? _.zipObject(options, options) : options;
    }
}
