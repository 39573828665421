import { ActionLogTypeEnum } from '@/constants';
import { ActionLog } from '@/models';
import { i18n } from '@/services';

export function actionLogTypeFormatter(type: ActionLogTypeEnum) {
    const translationKey = ActionLogTypeEnum[type]?.toLowerCase();
    return translationKey ? i18n.t(`ActionTypes.${translationKey}`) : String(type);
}

export function actionLogStatusFormatter(log: ActionLog) {
    return i18n.t(log.statusTranslationKey);
}

export function actionLogCreatorFormatter(log: ActionLog) {
    return log.creator.fullnameWithEmail;
}
