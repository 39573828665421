














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/constants';
import ReviztoLogoFull from '@/components/svg/ReviztoLogoFull.vue';
import WsCloseButton from '@/components/common/WsCloseButton.vue';

@Component({
    components: {
        ReviztoLogoFull,
        WsCloseButton,
    },
})
export default class ModalHeader extends Vue {
    @Prop({ type: Boolean, default: true }) public hasClose!: boolean;

    @Emit()
    public close() {
        return;
    }
    public redirectToMain() {
        this.close();
        this.$router.push({ name: RouterNames.StartPage });
    }
}
