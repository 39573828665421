import { v4 as uuidv4 } from 'uuid';

export { syncTabs, isTabFirst };

interface IStorageData {
    uuid: string;
    time: number;
}

const TIMEOUT = 1000;
const TAB_KEY = 'reviztoTab';

function syncTabs(): void {
    let iAmActive = false;
    const uuid = uuidv4();

    setInterval(handleInterval, TIMEOUT);

    function handleInterval(): void {
        const now = getTime();
        const { time: lastTime, uuid: activeUuid } = readData();
        if (uuid === activeUuid) {
            iAmActive = true;
        } else {
            iAmActive = now - lastTime > TIMEOUT;
        }
        if (iAmActive) {
            writeData({ uuid, time: now });
        }
    }
}

function isTabFirst(): boolean {
    return getTime() - readData().time > TIMEOUT * 2;
}

function getTime(): number {
    return new Date().getTime();
}

function readData(): IStorageData {
    const rawData = localStorage.getItem(TAB_KEY);
    if (!rawData) {
        return { uuid: '', time: 0 };
    }
    const { uuid, time } = JSON.parse(rawData);
    return { uuid, time };
}

function writeData({ uuid, time }: IStorageData): void {
    const rawData: string = JSON.stringify({ uuid, time });
    localStorage.setItem(TAB_KEY, rawData);
}
