export class LicenseOwner {
    public id: number;
    public uuid: string;
    public email: string;
    public firstname: string;
    public lastname: string;
    public role: string;
    public frozen: boolean;

    constructor(licenseOwner: any = {}) {
        this.id = licenseOwner.id;
        this.uuid = licenseOwner.uuid;
        this.email = licenseOwner.email;
        this.firstname = licenseOwner.firstname;
        this.lastname = licenseOwner.lastname;
        this.role = licenseOwner.role;
        this.frozen = licenseOwner.frozen;
    }
}
