



















































import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { MultiEditIssueFieldPayload } from '@/types/MultiEditIssueFieldPayload';
import { ProjectMember, ProjectMembersOption } from '@/models';
import { getCommaForListItem } from '@/services/GetCommaForListItem';
import WsButton from '@/components/common/WsButton.vue';
import WsCombobox from '@/components/common/WsCombobox.vue';
import WsDialog from '@/components/common/WsDialog.vue';

@Component({
    components: {
        WsButton,
        WsCombobox,
        WsDialog,
    },
})
export default class DialogMultiEditWatchers extends Vue {
    @Prop({ default: () => [] }) public watcherEntries!: Array<{
        count: number;
        watcher: string;
    }>;
    @Prop() public issueCount!: number;
    @Prop({ default: () => [] }) public items!: ProjectMembersOption[];

    public readonly getCommaForListItem = getCommaForListItem;

    public addWatchers: string[] = [];
    public removeWatchers: string[] = [];

    get projectId() {
        return Number(this.$route.params.projectId);
    }

    get projectMembers(): ProjectMember[] {
        return this.$store.getters.projectMembersByProjectId(this.projectId);
    }

    get projectMembersByEmail() {
        return _.keyBy(this.projectMembers, 'email');
    }

    @Emit()
    public save(): MultiEditIssueFieldPayload {
        return {
            add: this.addWatchers,
            remove: this.removeWatchers,
        };
    }

    @Emit()
    public cancel() {
        return;
    }

    @Emit()
    public input(value: string[]) {
        return value;
    }

    @Watch('localValue')
    public watchLocalValue(newValue: string[]) {
        this.input(newValue);
    }

    public getWatcherName(watcherEmail: string) {
        return this.projectMembersByEmail[watcherEmail]?.fullname || watcherEmail;
    }
}
