







import { Component } from 'vue-property-decorator';
import WsTruncateAuto from '@/components/common/WsTruncateAuto.vue';
import ProjectMemberName from '@/components/project/ProjectMemberName.vue';

@Component({
    components: {
        WsTruncateAuto,
    },
})
export default class ProjectMemberItem extends ProjectMemberName {
}
