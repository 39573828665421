import _ from 'lodash';
import { Dict } from '@/types/Dict';

export const DateFormat = {
    'DD/MM/YYYY': 1,
    'MM/DD/YYYY': 2,
    'DD.MM.YYYY': 3,
    'MM-DD-YYYY': 4,
    'DD-MM-YYYY': 5,
    'YYYY-MM-DD': 6,
};

export const DateFormatByNumber = _.invert(DateFormat) as Dict<DateFormatKeys>;

export type DateFormatKeys = keyof typeof DateFormat;
export type DateFormatT = typeof DateFormat[DateFormatKeys];

export const DateFormatForChartDot = 'DD MMM YY';
