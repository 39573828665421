// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { User } from '@/types/User';
import { RandomService } from '@/services/RandomService';

export const getDefaultUserInfo = () => ({
    country: VueCookies.get('country') || 'us',
    language: VueCookies.get('language') || 'en',
    key: VueCookies.get('key') || '',
    device_id: VueCookies.get('device_id') || RandomService.deviceId(),
    data: {} as User,
});
