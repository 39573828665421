import Vue from 'vue';
// @ts-ignore
import settings from 'reviztoSettings';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

export class CookieHelper {
    public static setCookie(key: string, value: string) {
        Vue.$cookies.set(
            key,
            value,
            '365d',
            undefined,
            '.' + settings.baseHost,
            settings.workspaceUrl.includes('https://') ? true : undefined,
        );
    }

    public static removeCookie(key: string) {
        Vue.$cookies.remove(key, undefined, settings.baseHost);
    }
}
