


































































import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { truncateString } from '@/services';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

@Component({
    methods: {
        truncateString,
    },
    components: {
        IconSvg16,
    },
})
export default class HeaderSelect extends Vue {
    @Prop({ default: true }) public arrow!: boolean;
    @Prop({ default: '' }) public dataTest!: string;
    @Prop() public title!: TranslateResult;
    @Prop({ default: '' }) public activatorText!: TranslateResult;

    @Ref() public readonly dropRef!: HTMLElement;

    public open = false;

    get isDropdownExist() {
        return Boolean(this.$slots.list);
    }

    get isActivatorSlot() {
        return Boolean(this.$slots.activator);
    }

    get sizeForLicense() {
        return `${this.$store.getters.headerFreeSpace - 50}px`;
    }

    get menuTitle() {
        return this.title ? `${this.title}${this.$t('Punctuation.colon')}` : '';
    }

    get activeItemName() {
        return truncateString(this.activatorText as string, 30) || '';
    }

    @Emit()
    public size() {
        return this.dropRef.offsetWidth;
    }

    @Emit()
    public change(value: boolean) {
        this.open = value;
        return value;
    }

    public close() {
        this.open = false;
    }

    public mounted() {
        this.size();
        // This is hack due to prop 'content-props' in v-menu is not working.
        const headerDropdown = document.querySelector(`.${this.dataTest}`);
        headerDropdown?.setAttribute('data-test', this.dataTest);
    }

    public updated() {
        this.size();
    }
}
