import _ from 'lodash';
import { TranslateResult } from 'vue-i18n';

type TPasswordRequirements = Array<[TranslateResult, boolean]>;

export function getPasswordRequirements(isPasswordChanged: boolean) {
    class PasswordRequirement {
        constructor(
            public readonly text: TranslateResult,
            private readonly isMet: boolean,
            public readonly key: number,
        ) {}

        get classObject() {
            return {
                active: this.isMet && isPasswordChanged,
                failed: !this.isMet && isPasswordChanged,
            };
        }
    }

    return class PasswordRequirements {
        constructor(
            private readonly data: TPasswordRequirements,
        ) {}

        get list() {
            return this.data.map(([text, isMet], key) => new PasswordRequirement(text, isMet, key));
        }

        get areMet() {
            return _.every(this.list, 'isMet');
        }

        get topLineTranslationKey() {
            return this.areMet ? 'passwordContains' : 'passwordMustContain';
        }
    };
}
