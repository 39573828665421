import _ from 'lodash';

export class SequentialRequests {
    public static currentRequestedParams: any;  // параметры запроса, который выполняется в текущий момент
    public static lastRequestedParams: any;     // параметры запроса, который вызывался последним
    public static hashedRequests: any = {};

    public static tick(request: any, params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.lastRequestedParams = _.cloneDeep(params);
            if (!this.currentRequestedParams) {
                try {
                    const hashKey = JSON.stringify(params);
                    if (this.hashedRequests[hashKey]) {
                        resolve(this.hashedRequests[hashKey]);
                        return;
                    }

                    this.currentRequestedParams = _.cloneDeep(params);
                    request(this.currentRequestedParams).then((response: any) => {
                        this.hashedRequests[hashKey] = response;
                        if (_.isEqual(this.lastRequestedParams, this.currentRequestedParams)) {
                            this.currentRequestedParams = undefined;
                            resolve(response);
                        } else {
                            this.currentRequestedParams = undefined; // currentRequestedParams должен обнулиться раньше вызова tick
                            this.tick(request, this.lastRequestedParams).then((res: any) => {
                                resolve(res);
                            });
                        }
                    });
                } catch (e) {
                    reject(e);
                }
            }
        });
    }
}
