import { IFeedbackPayload } from '@/storage/support.storage';
import http from './http';

export default class SupportApi {
    public static postFeedback({ name, email, id, message }: IFeedbackPayload) {
        return http.post('support/feedback', {
            name,
            email,
            id,
            notmessage: message,
        });
    }
}
