export enum TargetTypeEnum {
    LICENSE = 1,
    PROJECT = 2,
    TEAM_MEMBER = 3,
    TEAM_ROLE = 4,
    AUTH_METHOD = 5,
    DASHBOARD = 6,
    DASHBOARD_GRAPH = 7,
    SEARCH_SET = 8,
}
