export enum VersionNumber {
    v5 = 5,
    v4 = 4,
    unknown = 0,
}

export const VersionNumberList = [
    VersionNumber.v5,
    VersionNumber.v4,
];
