import { IStatFiltersData } from '@/types/IStatFiltersData';
import { IssueFilterExpr } from '@/constants/issue/IssueFilterExpr';
import { modifierAsStringParam } from '@/services/IssueFilterService'; // sic!

const defaultAction = modifierAsStringParam(IssueFilterExpr.HAS_ONE_IN);

export const DefaultActivityFiltersData = {
    get filtersData(): IStatFiltersData {
        return {
            startDate: NaN,
            endDate: NaN,
            tags: [],
            tagAction: defaultAction,
            companies: [],
            companiesAction: defaultAction,
            departments: [],
            departmentsAction: defaultAction,
            locations: [],
            locationsAction: defaultAction,
        };
    },
};
