import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { Dict } from '@/types/Dict';
import { IActivityRangePeriod } from '@/types/IActivityRangePeriod';
import Router from '@/router';
import { ActivityStatisticsType, AmplitudeEvent, FilterLocation, RouterNames } from '@/constants';
import { amplitudeLog } from '@/services';

type TContext = ActionContext<ICommonStorage, any>;

type TFiltersCollapsed = { [key in FilterLocation]: boolean };

const localStorageFilterKeys = _.mapValues(FilterLocation, (value) => `${value}_FilterCollapsed`);

function getFromLocalStorage(key: string) {
    return JSON.parse(String(localStorage.getItem(key)));
}

function getBoolFromLocalStorage(key: string) {
    return Boolean(getFromLocalStorage(key));
}
function getInitialFiltersCollapsed() {
    return _.mapValues(FilterLocation, (value) => getBoolFromLocalStorage(localStorageFilterKeys[value]));
}

interface ICommonStorage {
    startPathAfterLogin: string;
    isStartScreen: boolean;
    isShowActivityGraph: boolean;
    activityRangePeriod: IActivityRangePeriod;
    minDate: number | null;
    activityStatisticsType: ActivityStatisticsType;
    usedColors: Dict<string>;
    isChartSettingsCollapsed: boolean;
    recentColors: string[];
    filtersCollapsed: TFiltersCollapsed;
    requestLogs: any[];
}

const defaultPeriod: IActivityRangePeriod = {
    startDate: moment().startOf('day').subtract(6, 'months').unix(),
    endDate: moment().endOf('day').unix(),
};

export default {
    state: {
        startPathAfterLogin: '/',
        isStartScreen: false,
        isShowActivityGraph: getBoolFromLocalStorage('showActivityGraph'),
        activityRangePeriod: getFromLocalStorage('activityRangePeriod2') || defaultPeriod,
        minDate: null,
        activityStatisticsType: getFromLocalStorage('activityStatisticsType') || ActivityStatisticsType.cumulativeLinear,
        usedColors: {},
        isChartSettingsCollapsed: getBoolFromLocalStorage('chartSettingsCollapsed'),
        recentColors: getFromLocalStorage('recentColors') ?? [],
        filtersCollapsed: getInitialFiltersCollapsed(),
        requestLogs: [],
    } as ICommonStorage,
    getters: {
        startPathAfterLogin(state: ICommonStorage) {
            return state.startPathAfterLogin;
        },
        isStartScreen(state: ICommonStorage) {
            return state.isStartScreen;
        },
        isShowActivityGraph(state: ICommonStorage) {
            return state.isShowActivityGraph;
        },
        isChartSettingsCollapsed(state: ICommonStorage) {
            return state.isChartSettingsCollapsed;
        },
        activityStatisticsType(state: ICommonStorage) {
            return state.activityStatisticsType;
        },
        isDialogLoading(state: ICommonStorage, getters, rootState, rootGetters): boolean {
            // Getter for users activity in dialogs which needs loader
            return rootGetters.isSendingGroupEmail
                || rootGetters.isSendingGroupEditLicenseMemberTags
                || rootGetters.isSendingGroupChangeRole
                || rootGetters.isSendingGroupDeactivation
                || rootGetters.isSendingGroupActivation
                || rootGetters.isSendingGroupDeletion
                || rootGetters.isSendingGroupEditLicenseProjectTags
                || rootGetters.isSendingGroupArchiveLicenseProjects
                || rootGetters.isSendingGroupRestoreLicenseProjects
                || rootGetters.isSendingGroupDeleteLicenseProjects
                || rootGetters.isDeletingProjectMembers;
        },
        activityRangePeriod(state: ICommonStorage): IActivityRangePeriod {
            const startDate = state.activityRangePeriod.startDate;
            const endDate = Math.max(startDate, state.activityRangePeriod.endDate);
            return { startDate, endDate };
        },
        recentColors(state: ICommonStorage) {
            return state.recentColors;
        },
        isFilterCollapsed(state: ICommonStorage) {
            return (filterLocation: FilterLocation) => Boolean(state.filtersCollapsed[filterLocation]);
        },
        requestLogs(state: ICommonStorage) {
            return state.requestLogs;
        },
        minDate(state: ICommonStorage) {
            return state.minDate;
        },
    },
    mutations: {
        setStartPathAfterLogin(state: ICommonStorage, value: string) {
            state.startPathAfterLogin = value;
        },
        setIsStartScreen(state: ICommonStorage, value: boolean) {
            state.isStartScreen = value;
        },
        setIsShowActivityGraph(state: ICommonStorage, value: boolean) {
            state.isShowActivityGraph = value;
            localStorage.setItem('showActivityGraph', JSON.stringify(value));
        },
        setIsChartSettingsCollapsed(state: ICommonStorage, value: boolean) {
            state.isChartSettingsCollapsed = value;
            localStorage.setItem('chartSettingsCollapsed', JSON.stringify(value));
        },
        setActivityRangePeriod(state: ICommonStorage, period: IActivityRangePeriod) {
            state.activityRangePeriod = period;
            localStorage.setItem('activityRangePeriod2', JSON.stringify(period));
        },
        setActivityType(state: ICommonStorage, type: ActivityStatisticsType) {
            state.activityStatisticsType = type;
            localStorage.setItem('activityStatisticsType', JSON.stringify(type));
        },
        setRecentColors(state: ICommonStorage, colors: string[]) {
            Vue.set(state, 'recentColors', colors);
            localStorage.setItem('recentColors', JSON.stringify(colors));
        },
        setFiltersCollapsed(
            state: ICommonStorage,
            { filterLocation, value }: { filterLocation: FilterLocation, value: boolean },
        ) {
            state.filtersCollapsed[filterLocation] = value;
            localStorage.setItem(localStorageFilterKeys[filterLocation], JSON.stringify(value));
        },
        setRequestLogs(state: ICommonStorage, requestLogs: any[]) {
            Vue.set(state, 'requestLogs', requestLogs);
        },
        setMinDate(state: ICommonStorage, minDate: number | null) {
            Vue.set(state, 'minDate', minDate);
        },
        clearMinDate(state: ICommonStorage) {
            Vue.set(state, 'minDate', null);
        },
    },
    actions: {
        navigateBack({ state }: TContext) {
            state.isStartScreen ? Router.push({ name: RouterNames.StartPage }) : Router.go(-1);
        },
        addRecentColor({ state, commit }: TContext, color: string) {
            const limitColors = 12;
            const allRecent = state.recentColors;
            const colorIndex = allRecent.indexOf(color);
            if (colorIndex !== -1) {
                allRecent.splice(colorIndex, 1);
            } else if (allRecent.length === limitColors) {
                allRecent.splice(limitColors - 1, 1);
            }
            allRecent.unshift(color);
            commit('setRecentColors', allRecent);
        },
        toggleFiltersCollapsed({ getters, commit }: TContext, filterLocation: FilterLocation) {
            const isCollapsed = getters.isFilterCollapsed(filterLocation);
            amplitudeLog(AmplitudeEvent.changeFiltersVisibility, {
                action: isCollapsed ? 'expand' : 'collapse',
                filterLocation,
            });
            commit('setFiltersCollapsed', {
                filterLocation,
                value: !isCollapsed,
            });
        },
        pushRequestLog({ state, commit }: TContext, { result, url }: any) {
            const date = moment.utc();
            const requestLog = {
                globalTime: date.format('HH:mm:ss'),
                localTime: date.local().format('HH:mm:ss'),
                result,
                url,
            };
            const requestLogs = state.requestLogs;
            requestLogs.push(requestLog);
            commit('setRequestLogs', requestLogs);
        },
    },
} as Module<ICommonStorage, any>;
