




































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { LanguageKey, Languages } from '@/constants';
import WsPopover from '@/components/common/WsPopover.vue';

@Component({
    components: {
        WsPopover,
    },
})
export default class LocaleSwitcher extends Vue {
    @Prop({ default: LanguageKey.en }) public currentLanguage!: string;
    @Prop({ default: LanguageKey.en }) public defaultLanguage!: string;
    public isLanguageSelectOpened = false;

    get languagesOptions() {
        return Languages;
    }

    @Emit('select')
    public select(language: string) {
        return language;
    }

    public selectLocale(localeKey: any) {
        this.select(localeKey);
        this.isLanguageSelectOpened = false;
    }
}
