import http from '@/api/http';

interface GetNotificationsResponse {
    count: number;
    entities: any[];
    page: number;
}

export default class NotificationApi {
    public static getNotifications(params = {}): Promise<GetNotificationsResponse> {
        return http.post('notifications/list', params).then((response) => response.data);
    }

    public static markAsRead(params: { uuids: string }): Promise<any> {
        return http.post('notifications/mark-read', params).then((response) => response.data);
    }
}
