import _ from 'lodash';
import {
    AnyFeedbackQuestion,
    FeedbackNotify,
    FeedbackPoll,
    FeedbackResult,
} from '@/services';

class FeedbackCollectorBase {
    private notify: FeedbackNotify = _.noop;
    private endCallback: (results: FeedbackResult[]) => any = _.noop;
    private questions: AnyFeedbackQuestion[] = [];
    private currentQuestion = 0;
    private results: FeedbackResult[] = [];
    private endTimeoutId: number | any = 0;

    public setNotifier(notify: FeedbackNotify) {
        this.notify = notify;
    }

    public poll(props: FeedbackPoll = {
        questions: [],
        endCallback: _.noop,
    }) {
        if (!this.notify) {
            return;
        }

        if (!props.questions.length) {
            return;
        }

        this.questions = props.questions;
        this.endCallback = props.endCallback;

        this.nextQuestion();
    }

    private nextQuestion() {
        const nextQuestion = this.questions[this.currentQuestion];

        this.notify({
            question: nextQuestion,
            answerCallback: (result: FeedbackResult) => this.setAnswer(result),
        });

        if (nextQuestion.closeDelay) {
            this.endTimeoutId = setTimeout(() => {
                this.endTimeoutId = 0;
                this.endPoll();
            }, nextQuestion.closeDelay);
        }
    }

    private setAnswer(result: FeedbackResult) {
        if (_.isNull(result)) {
            const questionsWithAnswer = this.questions.filter((q) => !q.closeDelay);
            this.results.push(..._.fill(Array(questionsWithAnswer.length - this.results.length), null));

            this.endPoll();
            return;
        }

        this.results.push(result);
        this.currentQuestion++;

        if (this.questions[this.currentQuestion]) {
            this.nextQuestion();
        } else {
            this.endPoll();
        }
    }

    private endPoll() {
        this.notify({ isEnd: true });
        this.endCallback(this.results);

        this.results = [];
        this.questions = [];
        this.currentQuestion = 0;
        this.endCallback = _.noop;

        if (this.endTimeoutId) {
            clearTimeout(this.endTimeoutId);
            this.endTimeoutId = 0;
        }
    }

    public cancel() {
        this.notify({ isEnd: true });
        this.results = [];
        this.questions = [];
        this.currentQuestion = 0;
        this.endCallback = _.noop;

        if (this.endTimeoutId) {
            clearTimeout(this.endTimeoutId);
            this.endTimeoutId = 0;
        }
    }
}

export const FeedbackCollector = new FeedbackCollectorBase();
