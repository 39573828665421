import _ from 'lodash';
import { Component } from 'vue-property-decorator';
import { AmplitudeEvent } from '@/constants';
import {
    amplitudeLog,
    AnyFeedbackQuestion,
    FeedbackCollector,
    FeedbackResult,
    FeedbackType,
} from '@/services';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

const FeedbackStorageKey = 'feedback-stop-list';
const FeedbackSessionKey = 'feedback-stop-flag';
const FeedbackTimeoutMS = 3600000; // 1 hour

const getFeedbackQuestionsByType = (type: FeedbackType): AnyFeedbackQuestion[] => {
    let questions: AnyFeedbackQuestion[] = [];

    switch (type) {
        case FeedbackType.DashboardsCreating: {
            questions = [{
                titleKey: 'Feedback.dashboards.creating.title',
                isRating: true,
            }, {
                titleKey: 'Feedback.whatBetter',
                isOpenQuestion: true,
                maxLength: 800,
            }];

            break;
        }
        case FeedbackType.IssueTracker: {
            questions = [{
                titleKey: 'Feedback.issueTracker.general.title',
                isRating: true,
            }, {
                titleKey: 'Feedback.whatBetter',
                isOpenQuestion: true,
                maxLength: 800,
            }];
        }
    }

    questions.push({
        titleKey: 'Feedback.thanks',
        closeDelay: 3000,
    });

    return questions;
};

const getPageValueByFeedbackType = (type: FeedbackType): string => {
    switch (type) {
        case FeedbackType.DashboardsCreating: {
            return 'Dashboard';
        }
        case FeedbackType.IssueTracker: {
            return 'IssueTracker';
        }
    }

    return 'Unknown';
};

const getActionValueByFeedbackType = (type: FeedbackType): string => {
    switch (type) {
        case FeedbackType.DashboardsCreating: {
            return 'Open a dashboard';
        }
        case FeedbackType.IssueTracker: {
            return 'Open the Issue Tracker';
        }
    }

    return 'Unknown';
};

const getAmplitudeEventByFeedbackType = (type: FeedbackType): string | undefined => {
    switch (type) {
        case FeedbackType.DashboardsCreating: {
            return AmplitudeEvent.feedbackProjectDashboards;
        }
        case FeedbackType.IssueTracker: {
            return AmplitudeEvent.feedbackIssueTracker;
        }
    }

    return;
};

interface FeedbackLog {
    page: string;
    action: string;
    answers: { [key: string]: FeedbackResult };
}

@Component
export default class FeedbackMixin extends EventListenersBase {
    public feedbackTimeout: number | undefined = undefined;
    public startFeedback(type: FeedbackType) {
        if (this.getFeedbackStopList().includes(type)) {
            return;
        }

        if (!this.checkFeedbackPause()) {
            return;
        }

        FeedbackCollector.poll({
            questions: getFeedbackQuestionsByType(type),
            endCallback: (results: FeedbackResult[]) => this.collectFeedback(type, results),
        });
    }

    public collectFeedback(type: FeedbackType, results: FeedbackResult[]) {
        const log = {
            page: getPageValueByFeedbackType(type),
            action: getActionValueByFeedbackType(type),
            answers: {},
        } as FeedbackLog;

        results.forEach((answer, index) => {
            log.answers[`q${index + 1}`] = _.isNull(answer) ? 0 : answer;
        }, log);

        const eventType = getAmplitudeEventByFeedbackType(type);
        if (eventType) {
            amplitudeLog(eventType, log);
        }

        this.addFeedbackInStopList(type);
    }

    public getFeedbackStopList(): string[] {
        const stopList = localStorage.getItem(FeedbackStorageKey) || '';
        return stopList.split(',');
    }

    public addFeedbackInStopList(type: FeedbackType) {
        const storageValue = localStorage.getItem(FeedbackStorageKey) || '';
        const stopList = storageValue ? storageValue.split(',') : [];

        stopList.push(type);
        localStorage.setItem(FeedbackStorageKey, stopList.join(','));
    }

    public checkFeedbackPause(): boolean {
        const lastFeedbackCall = Number(sessionStorage.getItem(FeedbackSessionKey));

        if (!lastFeedbackCall || Date.now() - lastFeedbackCall > FeedbackTimeoutMS) {
            this.addFeedbackPauseFlag();
            return true;
        }

        return false;
    }

    public addFeedbackPauseFlag() {
        sessionStorage.setItem(FeedbackSessionKey, String(Date.now()));
    }

    public beforeDestroy() {
        FeedbackCollector.cancel();
        clearTimeout(this.feedbackTimeout);
    }
}
