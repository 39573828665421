export const RouterNames = {
    StartPage: 'StartPage',
    NotFound: 'NotFound',
    ProjectSettings: 'ProjectSettings',
    ProjectMembers: 'ProjectMembers',
    ProjectDashboard: 'ProjectDashboard',
    ProjectDashboardChart: 'ProjectDashboardChart',
    ProjectDashboards: 'ProjectDashboards',
    ProjectReports: 'ProjectReports',
    ProjectIssueTracker: 'ProjectIssueTracker',
    ProjectNotifications: 'ProjectNotifications',
    ProjectActivity: 'ProjectActivity',
    ProjectRevisions: 'ProjectRevisions',
    ProjectLog: 'ProjectLog',
    ProjectRoles: 'ProjectRoles',
    CompareRoles: 'CompareRoles',
    LicenseMemberSettings: 'LicenseMemberSettings',
    DashboardPresets: 'DashboardPresets',

    MyLicenseProjects: 'MyLicenseProjects',
    MyLicenseProjectsArchived: 'MyLicenseProjectsArchived',
    LicenseSettings: 'LicenseSettings',
    LicenseDashboard: 'LicenseDashboard',
    LicenseDashboardChart: 'LicenseDashboardChart',
    LicenseDashboards: 'LicenseDashboards',
    LicenseMembers: 'LicenseMembers',
    LicenseMembersApproval: 'LicenseMembersApproval',
    LicenseMembersAuthMethods: 'LicenseMembersAuthMethods',
    LicenseMembersImportUsers: 'LicenseMembersImportUsers',
    LicenseMembersAdditionalFields: 'LicenseMembersAdditionalFields',
    LicenseProjects: 'LicenseProjects',
    ProjectAccessRole: 'ProjectAccessRole',
    LicenseActivity: 'LicenseActivity',
    Login: 'Login',
    OAuth: 'OAuth',
    ForgotPassword: 'ForgotPassword',
    Register: 'Register',
    ChangePassword: 'ChangePassword',
    UserSettings: 'UserSettings',
    UserSecurity: 'UserSecurity',
    GlobalNotifications: 'GlobalNotifications',
    DevicesAndBrowsers: 'DevicesAndBrowsers',
    Integrations: 'Integrations',
    RedirectApp: 'RedirectApp',
    Download: 'Download',
    DownloadSpecificVersion: 'DownloadSpecificVersion',
    Unsubscribe: 'Unsubscribe',
    ValidateUser: 'ValidateUser',
    ReportingHistory: 'ReportingHistory',
    ActionLog: 'ActionLog',
    ContactUs: 'ContactUs',
    Integration: 'Integration',
    IntegrationCatch: 'IntegrationCatch',
    Icons: 'Icons',

    SelectLicense: 'SelectLicense',
    AppAuthorized: 'AppAuthorized',
    WsAuthorized: 'WsAuthorized',
    SAMLIntermediate: 'SAMLIntermediate',

    AccessCode: 'AccessCode',
    IssueSettings: 'IssueSettings',
    WorkflowsSettings: 'WorkflowsSettings',
    Workflow: 'Workflow',
    Workflows: 'Workflows',
    IssueTypes: 'IssueTypes',
    LoginOAuth2Redirect: 'LoginOAuth2Redirect',

    LicenseDeleted: 'LicenseDeleted',
    LastLicenseDeleted: 'LastLicenseDeleted',
    Scheduler: 'Scheduler',
    SchedulerTasks: 'SchedulerTasks',
    SchedulerUpcoming: 'SchedulerUpcoming',
    SchedulerHistory: 'SchedulerHistory',
};

type RouterNamesKeys = keyof typeof RouterNames;
export type RouterNamesT = typeof RouterNames[RouterNamesKeys];
