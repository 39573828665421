var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-notification-widget notification-widget"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"bell-wrapper flex-align-center",on:{"click":_vm.logOnClickMenuActivator}},'div',attrs,false),on),[_c('IconSvg24',{attrs:{"icon-name":("notification" + (_vm.countNewRecords ? '-dot' : '')),"color":_vm.Color.white}})],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[(_vm.isOpen)?_c('div',{staticClass:"notification-panel"},[_c('div',{staticClass:"notification-header"},[_c('div',[_vm._v(_vm._s(_vm.$t('Notification.notifications')))]),_c('WsCloseButton',{attrs:{"color":_vm.Color.selectBlack},on:{"click":function($event){_vm.isOpen = false}}})],1),_c('div',{staticClass:"notification-main"},[_c('WsTabsHeader',{on:{"change":_vm.onTabsChange},scopedSlots:_vm._u([{key:"customTabs",fn:function(){return _vm._l((_vm.tabs),function(ref){
var tab = ref.tab;
var translate = ref.translate;
var count = ref.count;
return _c('v-tab',{key:tab,staticClass:"tab-item",class:{ active: _vm.currentTab === tab },attrs:{"ripple":false},on:{"click":function($event){_vm.currentTab = tab}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t(translate)))]),_c('span',{staticClass:"counter"},[_vm._v("("+_vm._s(count)+")")])])})},proxy:true}],null,false,3812163534),model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}}),(_vm.records.length)?_c('NotificationFilter',{staticClass:"notification-filter",attrs:{"records":_vm.recordsFilteredByUnread},on:{"change":_vm.onFilterAuthorChange}}):_vm._e(),(!_vm.records.length)?_c('div',{staticClass:"no-notifications"},[_vm._v(" "+_vm._s(_vm.$t('Notification.noNotifications'))+" ")]):_c('div',{staticClass:"controls-wrapper"},[_c('WsSwitch',{attrs:{"label":String(_vm.$t('Notification.showUnreadOnly'))},on:{"change":_vm.logOnChangeIsShowOnly},model:{value:(_vm.isShowNewOnly),callback:function ($$v) {_vm.isShowNewOnly=$$v},expression:"isShowNewOnly"}}),_c('WsTableButton',{attrs:{"disabled":!_vm.unreadRecords.length},on:{"click":_vm.markAllAsRead}},[_vm._v(" "+_vm._s(_vm.$t('Notification.markAllAsRead'))+" ")])],1),(_vm.records.length && !_vm.recordsFilteredByUnread.length)?_c('div',{staticClass:"no-notifications all-read"},[_vm._v(" "+_vm._s(_vm.$t('Notification.allNotificationsRead'))+" ")]):_vm._e(),_c('hr'),_c('WsLazyRender',{key:_vm.refreshKey,attrs:{"items":_vm.currentRecordsWithDates},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var lazyRecords = ref.items;
return _vm._l((lazyRecords),function(record){return _c('div',{key:record.operationId},[(record.date)?_c('div',{staticClass:"notification-date"},[_vm._v(_vm._s(record.date))]):(record instanceof _vm.NotificationRecord)?_c('NotificationItem',{attrs:{"record":record},on:{"close":function($event){_vm.isOpen = false}},nativeOn:{"click":function($event){return _vm.read(record)}}}):_vm._e()],1)})}}],null,false,3790653369)})],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }