import { Component } from 'vue-property-decorator';
import EventListenersBase from '@/components/common/EventListenersBase.vue';

@Component
export default class ResizeMixin extends EventListenersBase {
    public handleResize() {
        // tslint:disable-next-line
        window.console.error('метод handleResize должен быть определен в компоненте ' + this.$options.name);
    }

    public mounted() {
        this.eventListeners.add({
            event: 'resize',
            handler: this.handleResize,
        });
    }
}
