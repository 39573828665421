import { IssueCreatedFromInvert, IssueFilterExpr, IssuePriorityEnum, IssueStatusEnum, IssueTypes } from '@/constants';
import { FilterSetEnum } from '@/services';

const RuleType = {
    Regular: 'Regular',
    Clash: 'Clash',
};

export class ToProtobuf {
    public static statusToNumber(status: string): string {
        const statuses = [
            IssueStatusEnum.open,
            IssueStatusEnum.in_progress,
            IssueStatusEnum.solved,
            IssueStatusEnum.closed,
            IssueStatusEnum.deleted,
        ];
        const index = statuses.findIndex((aStatus) => aStatus === status);
        return String(index);
    }

    public static typeNumberToString(issueType: number) {
        return {
            [IssueTypes.regular]: RuleType.Regular,
            [IssueTypes.clash]: RuleType.Clash,
        }[issueType];
    }

    public static bindingNumberToString(num: number) {
        return IssueCreatedFromInvert[num];
    }

    public static convertExpression(expr: number) {
        return {
            [IssueFilterExpr.HAS_ALL_IN]: FilterSetEnum.All,
            [IssueFilterExpr.IN]: FilterSetEnum.Any,
            [IssueFilterExpr.HAS_ONE_IN]: FilterSetEnum.Any,
            [IssueFilterExpr.HAS_NOT_ALL_IN]: FilterSetEnum.NoneOf,
            [IssueFilterExpr.NOT_IN]: FilterSetEnum.NoneOf,
        }[expr];
    }

    public static priorityToNumberString(priority: string) {
        const priorities = [
            IssuePriorityEnum.none,
            IssuePriorityEnum.trivial,
            IssuePriorityEnum.minor,
            IssuePriorityEnum.major,
            IssuePriorityEnum.critical,
            IssuePriorityEnum.blocker,
        ];
        const index = priorities.findIndex((elem) => elem === priority);
        return String(index);
    }

    public static dateFilterToItems(filter: any) {
        const ruleObj = {
            type: filter.dateFilterType,
            days: filter.days,
            includeNow: filter.includeNow,
            date: filter.date,
            date2: filter.date2,
        };
        return [JSON.stringify(ruleObj)];
    }
}
