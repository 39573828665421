import { SortDirections } from '@/constants';

export class FormSortField {
    public readonly name: string;
    public readonly additionalField?: string[];
    public directions: { [key: string]: string };
    public directionValue: string;
    public values: string[];

    constructor(params: any = {}) {
        this.name = params.name;
        this.additionalField = params.additionalField;
        this.directions = params.directions || SortDirections;
        this.directionValue = params.directionValue || SortDirections.asc;
        this.values = params.values || [];
    }

    public static instantiate(params: any = {}): FormSortField {
        return new FormSortField(params);
    }
}
