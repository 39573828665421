import _ from 'lodash';
import { CaseConverter } from '@/services';

interface INotificationsEventsParams {
    assignee_changed: number;
    comment_added: number;
    deadline_changed: number;
    issue_closed: number;
    markup_changed: number;
    priority_changed: number;
    publicity_changed: number;
    reporter_changed: number;
    status_changed: number;
    tags_changed: number;
    title_changed: number;
    watchers_changed: number;
}

interface INotificationsSelectionParams {
    assigned_to_me: number;
    watched_by_me: number;
}

export interface INotificationsParams {
    uuid: string;
    frequency: number;
    events: INotificationsEventsParams;
    selection: INotificationsSelectionParams;
}

export class NotificationsEvents {
    public assigneeChanged: boolean;
    public commentAdded: boolean;
    public deadlineChanged: boolean;
    public issueClosed: boolean;
    public markupChanged: boolean;
    public priorityChanged: boolean;
    public publicityChanged: boolean;
    public reporterChanged: boolean;
    public statusChanged: boolean;
    public tagsChanged: boolean;
    public titleChanged: boolean;
    public watchersChanged: boolean;
    public editIssueCustomType: boolean;

    constructor(events: any = {}) {
        this.assigneeChanged = Boolean(events.assigneeChanged);
        this.commentAdded = Boolean(events.commentAdded);
        this.deadlineChanged = Boolean(events.deadlineChanged);
        this.issueClosed = Boolean(events.issueClosed);
        this.markupChanged = Boolean(events.markupChanged);
        this.priorityChanged = Boolean(events.priorityChanged);
        this.publicityChanged = Boolean(events.publicityChanged);
        this.reporterChanged = Boolean(events.reporterChanged);
        this.statusChanged = Boolean(events.statusChanged);
        this.tagsChanged = Boolean(events.tagsChanged);
        this.titleChanged = Boolean(events.titleChanged);
        this.watchersChanged = Boolean(events.watchersChanged);
        this.editIssueCustomType = Boolean(events.editIssueCustomType);
    }

    get apiParams(): INotificationsEventsParams {
        const params = _.mapValues(this, Number);
        return CaseConverter.toSnakeCase(params);
    }
}

export class NotificationsSelection {
    public assignedToMe: boolean;
    public watchedByMe: boolean;

    constructor(selection: any = {}) {
        this.assignedToMe = Boolean(selection.assignedToMe);
        this.watchedByMe = Boolean(selection.watchedByMe);
    }

    get apiParams(): INotificationsSelectionParams {
        const params = _.mapValues(this, Number);
        return CaseConverter.toSnakeCase(params);
    }
}

export class Notifications {
    public uuid: string;
    public frequency: number;
    public events: NotificationsEvents;
    public selection: NotificationsSelection;

    constructor(notifications?: INotificationsParams | any) {
        notifications = notifications || {};
        notifications = CaseConverter.toCamelCase(notifications);

        this.uuid = notifications.uuid || '';
        this.frequency = notifications.frequency;
        this.events = new NotificationsEvents(notifications.events);
        this.selection = new NotificationsSelection(notifications.selection);
    }

    get apiParams(): INotificationsParams {
        const params = {
            uuid: this.uuid,
            frequency: this.frequency,
            events: this.events.apiParams,
            selection: this.selection.apiParams,
        };

        return CaseConverter.toSnakeCase(params);
    }
}
