
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import { Color, TooltipPositionsType } from '@/constants';

@Component
export default class IconSvgBase extends Vue {
    @Prop({ required: true }) public iconName!: string;
    @Prop({ default: 24 }) public size!: number;
    @Prop({ default: Color.defaultBlack }) public color!: string;
    @Prop({ default: Color.primaryBlue }) public colorFocus!: string; // color for hover and click (if no colorActive) and other
    @Prop({ type: String }) public colorActive!: string; // color on click / $white
    @Prop({ type: String }) public strokeWidth!: string;
    @Prop({ type: Boolean, default: false }) public parentHover!: boolean;
    @Prop({ type: Boolean, default: false }) public focused!: boolean;
    @Prop({ type: Boolean, default: false }) public filled!: boolean;
    @Prop({ type: Boolean, default: false }) public bordered!: boolean;

    @Prop({ type: Boolean, default: false }) public inline!: boolean; // aligns with the text, works better with fontlike
    @Prop({ type: Boolean, default: false }) public fontlike!: boolean; // makes the icon same size as the font, preferably use with inline

    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public passive!: boolean; // as disabled, it switches hover off but doesn't apply grey color
    @Prop({ type: Boolean, default: false }) public stateless!: boolean;
    @Prop({ type: Boolean, default: false }) public square!: boolean;

    @Prop() public tooltip!: VueI18n.TranslateResult; // appear if icon enabled
    @Prop() public tooltipIfDisabled!: VueI18n.TranslateResult; // appear if icon disabled
    @Prop({ type: Boolean }) public tooltipStable!: boolean; // this.tooltip should appear every time, ignore tooltipIfDisabled
    @Prop({ type: Number }) public tooltipMaxWidth!: number;
    @Prop({ type: String }) public activatorClass!: string;
    @Prop({ default: 'top' }) public placement!: TooltipPositionsType; // change only if it REALLY important
    @Prop({ type: Boolean }) public block!: boolean; // activator as block
    @Prop({ default: 400 }) public openDelay!: number;

    @Prop({ type: Boolean, default: false }) public edit!: boolean;
    @Prop({ type: Boolean, default: false }) public trash!: boolean;
    @Prop({ type: Boolean, default: false }) public recoverTrash!: boolean;
    @Prop({ type: Boolean, default: false }) public selected!: boolean;
    @Prop({ type: Boolean, default: false }) public stopPropagation!: boolean;

    @Emit()
    public click(event?: MouseEvent) {
        return event;
    }

    get url() {
        return require(`./svg/${this.iconName}_24.svg`);
    }

    get tooltipContent() {
        if (this.tooltipStable) {
            return this.tooltip;
        }
        return this.disabled ? this.tooltipIfDisabled : this.tooltip;
    }

    get activatorClasses() {
        return {
            [this.activatorClass]: Boolean(this.activatorClass),
            'svg-icon': true,
            inline: this.inline,
            fontlike: this.fontlike,
        };
    }

    get generatedStyle() {
        const styles = {
            '--color': this.color,
            '--color-focus': this.colorFocus,
            '--color-active': this.colorActive || this.colorFocus,
        } as { [key: string]: any };

        if (this.strokeWidth) {
            styles['stroke-width'] = this.strokeWidth;
        }

        return styles;
    }

    public handleClick(event: MouseEvent) {
        if (!this.disabled) {
            this.click(event);
        }

        if (this.stopPropagation) {
            event.stopPropagation();
        }
    }

}
