// @ts-ignore
import settings from 'reviztoSettings';
import { Module } from 'vuex';

interface IHttpParams {
    baseHost: string;
    apiHost: string;
    protocol: string;
    version: string;
    regionsPath: string;
    workspaceUrl: string;
    nonce: string;
    updaterPath: string;
    releaseRegion: string;
}
interface IHttpParamsStorage {
    httpParams: IHttpParams;
    twoFAIssuer: string;
}

export default {
    state: {
        twoFAIssuer: settings.twoFAIssuer || 'Revizto',
    } as IHttpParamsStorage,
    getters: {
        twoFAIssuer(state: IHttpParamsStorage): string {
            return state.twoFAIssuer;
        },
    },
} as Module<IHttpParamsStorage, any>;
