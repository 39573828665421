import { v4 as uuidv4 } from 'uuid';

export class ProjectRevision {
    public number: number;
    public id: number;
    public uuid: string;
    public created: string;
    public author: {
        email: string;
        fullname: string;
        id: number;
    };
    public search: string;
    public description: string;
    public projectVersion: string;

    constructor(revision: any = {}) {
        this.number = revision.number;
        this.id = revision.id;
        this.uuid = revision.uuid || uuidv4(); // есть старые ревизии, у которых нет уидов
        this.created = revision.created;
        this.author = revision.author || {};
        this.search = `${this.author.fullname} ${this.author.email}`;
        this.description = revision.description;
        this.projectVersion = revision.project_version || '';
    }

    get apiParams(): any {
        const result: any = Object.assign({ project_version: this.projectVersion }, this);
        delete result.projectVersion;
        return result;
    }

    get productVersion() {
        let productVersion;
        try {
            productVersion = JSON.parse(this.description.split('*').join('')).productVersion;
        } catch (error) {
            productVersion = '';
        }
        return productVersion;
    }

    get productVersionShort() {
        return this.productVersion.replace(/\(.*\)/, '');
    }
}
