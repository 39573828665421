import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import Vuex, { Module } from 'vuex';
import { CommentType } from '@/types/CommentType';
import type { SchedulerNotifierMessage } from '@/types/scheduler/SchedulerNotifier';
import Router from '@/router';
import {
    allIssuesPresetUuid,
    DEADLINE_NOT_SET,
    IssueTrackerFields,
    IssueVisibility,
    MomentFormats,
    RESPONSE,
    RouterNames,
} from '@/constants';
import { SchedulerNotifierMessageCode } from '@/constants/project/scheduler/scheduler';
import {
    AbstractComment,
    AccessRole,
    DashboardChart,
    Issue,
    IssueFilterPreset,
    IssueStamp,
    Project,
    ProjectMember,
} from '@/models';
import { TaskDefinition } from '@/models/TaskDefinition';
import { TaskHistory } from '@/models/TaskHistory';
import { FileReaderQueue } from '@/services/FileReaderQueue';
import { FromProtobuf, mixIssueFieldVariants, Msg, Protobuf, RandomService } from '@/services';
import { filterIssues, IssueOrderRecordStatusEnum } from '@/storage/projectIssues.storage';

Vue.use(Vuex);

enum IssueOperationCode {
    Add = 0,
    Get = 1,
    Change = 2,
    Delete = 3,
    AddComments = 4,
    Readed = 5,
    ReadedComments = 6,
    GetChanges = 7,
    GetComments = 8,
    AddTags = 9,
    EditTags = 10,
    DelTags = 11,
}

export const enum ClientState {
    Uninitialized,
    Connecting,
    Connected,
    Authenticating,
    JoinedLobby,
    Joining,
    Joined,
    Leaving,
    Disconnecting,
    Disconnected,
}

const LobbyProject = 0;

interface IWebsocketStorage {
    isConnectedToNotifier: boolean;
    notifierConnectionState: number;
    notifierProjectConnected: number;
    notifierActorId: number;
    notifierRandomGuid: string;
    pingIntervalId: number;
    projectDashboardChartsIntervalId: number;
}

const assignedRow = 'assigned';
const assigneeRow = 'assignee';

const getIssueProtobufField = (fields: any, code: number): string[]  => {
    const result = [] as string[];
    Object.keys(fields).forEach((key: any) => {
        // bool logic
        if (fields[key] & code) {
            let foundField = _.lowerFirst(key);
            if (foundField === assignedRow) {
                foundField = assigneeRow;
            }
            result.push(foundField);
        }
    });
    return result;
};

const transformProtobufDiffRowToIssueField = (rowName: string) => {
    switch (rowName) {
        case 'custom_Status': {
            return IssueTrackerFields.customStatus;
        }
        case 'custom_Type': {
            return IssueTrackerFields.customType;
        }
        default: {
            return rowName;
        }
    }
};

const fileReaderQueue = new FileReaderQueue();

export default {
    state: {
        isConnectedToNotifier: false,
        notifierConnectionState: ClientState.Uninitialized,
        notifierProjectConnected: LobbyProject,
        notifierActorId: 0,
        notifierRandomGuid: RandomService.string(16),
        pingIntervalId: 0,
        projectDashboardChartsIntervalId: 0,
    } as IWebsocketStorage,
    getters: {
        isConnectedToNotifier(state: IWebsocketStorage): boolean {
            return state.isConnectedToNotifier;
        },
        notifierRandomGuid(state: IWebsocketStorage): string {
            return state.notifierRandomGuid;
        },
        notifierProjectConnected(state: IWebsocketStorage): number {
            return state.notifierProjectConnected;
        },
        notifierConnectionState(state: IWebsocketStorage): number {
            return state.notifierConnectionState;
        },
        pingIntervalId(state: IWebsocketStorage): any {
            return state.pingIntervalId;
        },
        notifierActorId(state: IWebsocketStorage): any {
            return state.notifierActorId;
        },
    },
    mutations: {
        setIsConnectedToNotifier(state: IWebsocketStorage, isConnected: boolean) {
            state.isConnectedToNotifier = isConnected;
        },
        setNotifierConnectionState(state: IWebsocketStorage, connectionState: number) {
            state.notifierConnectionState = connectionState;
        },
        setNotifierActorId(state: IWebsocketStorage, notifierActorId: number) {
            state.notifierActorId = notifierActorId;
        },
        setNotifierProjectConnected(state: IWebsocketStorage, projectId: number) {
            state.notifierProjectConnected = projectId;
        },
        setPingIntervalId(state: IWebsocketStorage, pingIntervalId: any) {
            state.pingIntervalId = pingIntervalId;
        },
        resetPingIntervalId(state: IWebsocketStorage) {
            clearInterval(state.pingIntervalId);
        },
        setProjectDashboardChartsIntervalId(state: IWebsocketStorage, value: number) {
            state.projectDashboardChartsIntervalId = value;
        },
        resetProjectDashboardChartsIntervalId(state: IWebsocketStorage) {
            clearInterval(state.projectDashboardChartsIntervalId);
        },
    },
    actions: {
        sendMessage(context: any, message: any) {
            Vue.prototype.$socket.send(message);
        },
        connectNotifier({ rootGetters, commit, dispatch, state }: any) {
            if (state.notifierConnectionState !== ClientState.Uninitialized && state.notifierConnectionState !== ClientState.Disconnected) {
                return;
            }
            if (rootGetters.notifierUrl) {
                Vue.prototype.$connect(rootGetters.notifierUrl, { // 'ws://192.168.30.13:8888'
                    reconnection: true,
                });
                Vue.prototype.$socket.onopen = (event: Event) => { dispatch('openSocket', event); };
                Vue.prototype.$socket.onclose = () => { dispatch('closeSocket'); };
                Vue.prototype.$socket.onerror = (event: Event) => { dispatch('errorSocket', event); };
                Vue.prototype.$socket.onmessage = (event: MessageEvent) => { dispatch('parseMessage', event); };
                commit('setNotifierConnectionState', ClientState.Connecting);
            }
        },
        disconnectNotifier({ dispatch }: any) {
            Vue.prototype.$disconnect();
            dispatch('closeSocket');
        },
        openSocket({ commit, dispatch }: any, event: Event) {
            Vue.prototype.$socket = event.currentTarget;
            commit('setIsConnectedToNotifier', true);
            commit('setNotifierConnectionState', ClientState.Connected);
            dispatch('authenticateNotifier');
        },
        closeSocket({ commit }: any) {
            commit('resetPingIntervalId');
            commit('resetProjectDashboardChartsIntervalId');
            commit('setIsConnectedToNotifier', false);
            commit('setNotifierConnectionState', ClientState.Disconnected);
        },
        errorSocket({ dispatch }: any, event: Event) {
            dispatch('closeSocket', event);
        },
        authenticateNotifier({ dispatch }: any) {
            dispatch('sendMessage', Protobuf.getMsgWsAuthenticate());
        },
        uniqueNotifier({ getters, dispatch }: any) {
            dispatch('sendMessage', Protobuf.getMsgWsUnique(getters.notifierRandomGuid));
        },
        propsNotifier({ rootGetters, dispatch }: any) {
            dispatch('sendMessage', Protobuf.getMsgWsProps(rootGetters.userData));
        },
        initPingMessages({ dispatch, commit }: any) {
            const pingInterval = 15000;
            const pingIntervalId = setInterval(() => {
                dispatch('sendMessage', Protobuf.getMsgWsPing());
            }, pingInterval);
            commit('setPingIntervalId', pingIntervalId);
        },
        initProjectDashboardChartsUpdater({ commit, dispatch }: any) {
            const chartsUpdaterInterval = 1000;
            const intervalId = setInterval(() => {
                dispatch('checkProjectDashboardChartsForUpdater');
            }, chartsUpdaterInterval);
            commit('setProjectDashboardChartsIntervalId', intervalId);
        },
        checkProjectDashboardChartsForUpdater({ dispatch, rootGetters }: any) {
            const UPDATE_INTERVAL_IN_SECONDS = 30;
            const route = Router.app.$route;
            if (route.name === RouterNames.ProjectDashboards) {
                const projectId = Number(route.params.projectId);
                const dashboardUuid = rootGetters.selectedProjectDashboardByProjectId(projectId).uuid;
                const charts = rootGetters.projectDashboardChartsByDashboardUuid(dashboardUuid);

                charts.forEach((chart: DashboardChart) => {
                    const chartUuid = chart.uuid;
                    const chartLastLoad = rootGetters.projectDashboardChartLastLoad({ dashboardUuid, chartUuid });
                    if (chartLastLoad) {
                        const countSecondsAfterLastLoad = moment().unix() - chartLastLoad.loadTime;
                        if (countSecondsAfterLastLoad > UPDATE_INTERVAL_IN_SECONDS && chartLastLoad.isChanged) {
                            dispatch('loadProjectDashboardChartView', { projectId, dashboardUuid, chartUuid, isForce: true });
                        }
                    }
                });
            }
        },
        joinProjectNotifier({ getters, commit, dispatch }: any, project: Project) {
            if (!project.id || (getters.notifierProjectConnected === project.id)) {
                return;
            }
            if (getters.notifierProjectConnected) {
                dispatch('leaveProjectNotifier');
            }
            dispatch('sendMessage', Protobuf.getMsgWsJoinProject(project));
            commit('setNotifierProjectConnected', project.id);
        },
        leaveProjectNotifier({ getters, commit, dispatch }: any) {
            if (getters.notifierProjectConnected === LobbyProject) {
                return;
            }

            dispatch('sendMessage', Protobuf.getMsgWsLeaveProject());
            commit('setNotifierProjectConnected', LobbyProject);
        },
        parseMessage({ dispatch }: any, message: MessageEvent) {
            fileReaderQueue.readFile(message.data).then((result) => {
                if (!(result instanceof ArrayBuffer)) {
                    return;
                }

                const msg = Protobuf.getMsgWsDecodeArrayBuffer(result);

                if (msg.operation) {
                    switch (msg.operation.val) {
                        case Protobuf.OperationEnum.Authenticate:
                            dispatch('parseAuthenticationMessages', msg);
                            break;

                        case Protobuf.OperationEnum.Join:
                            dispatch('parseJoinMessages', msg);
                            break;

                        case Protobuf.OperationEnum.Leave:
                            dispatch('parseLeaveMessages', msg);
                            break;
                    }
                }

                if (msg.eventCode) {
                    switch (msg.eventCode.val) {
                        case Protobuf.EventEnum.Marker_Operation:
                            dispatch('parseIssueOperationMessages', {
                                message: Protobuf.decodeIssueOperationMsg(msg),
                                projectId: Number(Router.app.$route.params.projectId),
                            });
                            break;

                        case Protobuf.EventEnum.Join:
                            dispatch('parseJoinEventMessages', msg);
                            break;

                        case Protobuf.EventEnum.Leave:
                            dispatch('parseLeaveEventMessages', msg);
                            break;

                        case Protobuf.EventEnum.UserDataChange_Operation:
                            dispatch('parseUserDataChangeMessages', msg);
                            break;

                        case Protobuf.EventEnum.Project_Operation:
                            dispatch('parseProjectMessages', msg);
                            break;

                        case Protobuf.EventEnum.Mail_Operation:
                            dispatch('parseMailOperationMessages', msg);
                            break;

                        case Protobuf.EventEnum.License_Operation:
                            dispatch('parseLicenseMessages', msg);
                            break;
                        case Protobuf.EventEnum.FilterPresets_Operation: {
                            const projectId = Router.currentRoute.params.projectId;
                            dispatch('loadIssueFilterPresetsByProjectId', { projectId, isForce: true });
                            break;
                        }

                        case Protobuf.EventEnum.IssuePresets_Operation:
                            dispatch('parseIssuePresetOperation', {
                                projectId: Router.currentRoute.params.projectId,
                                message: Protobuf.decodeIssueTemplate(msg),
                            });
                            break;

                        case Protobuf.EventEnum.ExternalService_Operation:
                            dispatch('parseChangeExternalConnectionMessages', msg);
                            break;

                        case Protobuf.EventEnum.NotificationOperation: {
                            dispatch('parseNotificationOperationMessages', msg);
                            break;
                        }

                        case Protobuf.EventEnum.Scheduler_Operation: {
                            dispatch('parseSchedulerMessages', Protobuf.decodeSchedulerOperation(msg));
                            break;
                        }
                    }
                }
            });
        },

        // Парсер отдельных сообщений
        parseAuthenticationMessages({ commit, dispatch }: any, message: Msg) {
            if (message.operation.val !== Protobuf.OperationEnum.Authenticate) {
                throw new Error(message);
            }
            // @todo тут нужно добавить обработку ошибок
            commit('setNotifierActorId', message.actorId.val);
            commit('setNotifierConnectionState', ClientState.JoinedLobby);
            dispatch('uniqueNotifier');
            dispatch('initPingMessages');
            // dispatch('initProjectDashboardChartsUpdater');
            dispatch('propsNotifier');
        },
        parseJoinMessages(context: any, message: Msg) {
            if (message.operation.val !== Protobuf.OperationEnum.Join) {
                throw new Error(message);
            }
            if (message.returnCode) {
                throw new Error(message);
            }
        },
        parseLeaveMessages(context: any, message: Msg) {
            if (message.operation.val !== Protobuf.OperationEnum.Leave) {
                throw new Error(message);
            }
            // @todo тут нужно добавить обработку ошибок
        },
        parseJoinEventMessages() {
            // if (message.operation.val !== Protobuf.OperationEnum.Leave) {
            //     throw new Error(message);
            // }
            // @todo тут нужно добавить обработку ошибок
        },
        parseLeaveEventMessages() {
            // if (message.operation.val !== Protobuf.OperationEnum.Leave) {
            //     throw new Error(message);
            // }
            // @todo тут нужно добавить обработку ошибок
        },
        parseUserDataChangeMessages({ commit }: any, message: Msg) {
            if (message.eventCode.val !== Protobuf.EventEnum.UserDataChange_Operation) {
                throw new Error(message);
            }

            const userData = JSON.parse(String.fromCharCode.apply(null, message.args));
            commit('setUserData', userData, { root: true });
        },
        parseIssuesChangeMessages({ commit, rootGetters }: any, message: Msg) {
            if (message.eventCode.val !== Protobuf.EventEnum.Marker_Operation) {
                throw new Error(message);
            }

            if (Protobuf.isDoParseIssuesChangeMessages(message)) {
                const projectId = Number(Router.app.$route.params.projectId);
                const dashboardUuid = rootGetters.selectedProjectDashboardByProjectId(projectId).uuid;
                const charts = rootGetters.projectDashboardChartsByDashboardUuid(dashboardUuid);

                charts.forEach((chart: DashboardChart) => {
                    const chartUuid = chart.uuid;
                    const chartLastLoad = rootGetters.projectDashboardChartLastLoad({ dashboardUuid, chartUuid });
                    /*
                        этот комментарий для того кто в будущем решит использовать этот код,
                        здесь sentry ругается на то что chartLastLoad is undefined,
                        https://sentry.io/organizations/revizto/issues/2338735822/events/cca210af4b85449d9d731d9b9edbe3e5/
                        не стали решать эту проблему так как в планах переделать принцип работы нотифаера для дашбордов
                     */
                    chartLastLoad.isChanged = true;
                    commit('setProjectDashboardChartLoadTime', {
                        dashboardUuid,
                        chartUuid,
                        chartLastLoad,
                    }, { root: true });
                });
            }
        },
        async parseProjectMessages({ getters, commit, dispatch, rootGetters }: any, message: Msg) {
            if (message.eventCode.val !== Protobuf.EventEnum.Project_Operation) {
                throw new Error(message);
            }
            const projectOperation = Protobuf.decodeProjectOperationMsg(message);
            switch (projectOperation.code) {
                case (Protobuf.ProjectOperation.Code.UpdateRevision): {
                    const projectId = projectOperation.projectId || getters.notifierProjectConnected;
                    if (projectId) {
                        dispatch('loadProjectRevisions', {
                            projectId,
                            isForce: true,
                        }, { root: true });
                        dispatch('loadProjectFieldVariants', {
                            projectId: projectOperation.projectId || Router.currentRoute.params.projectId,
                            isForce: true,
                        });
                    }
                    break;
                }
                case (Protobuf.ProjectOperation.Code.UpdateAccess): {
                    const projectId = projectOperation.projectId || getters.notifierProjectConnected;
                    if (projectId) {
                        dispatch('loadProjectById', {
                            projectId,
                            isForce: true,
                        }, { root: true }).catch(({ result }: { result: number }) => {
                            if ([
                                RESPONSE.OBJECT_NOT_EXISTS,
                                RESPONSE.SECURITY_ERROR,
                                RESPONSE.NOT_FOUND,
                            ].includes(result)) {
                                Router.push({ name: RouterNames.MyLicenseProjects, params: { licenseId: rootGetters.currentLicenseId } });
                            }
                        });
                    }
                    break;
                }
                case (Protobuf.ProjectOperation.Code.UpdateTeam): {
                    const projectId = projectOperation.projectId || Router.currentRoute.params.projectId;
                    await dispatch('loadProjectMembers', {
                        projectId,
                        isForce: true,
                    }, { root: true });
                    commit('setProjectFieldVariants', {
                        projectId,
                        fieldVariants: {
                            ...getters.fieldVariantsByProjectId(projectId),
                            assignees: getters.projectMembersByProjectId(projectId).map((user: any) => user.email),
                            reporters: getters.projectMembersByProjectId(projectId).map((user: any) => user.email),
                        },
                    });
                    break;
                }
                case (Protobuf.ProjectOperation.Code.UpdateLicense): {
                    if (!projectOperation.projectId) {
                        return; // projectId не прилетает из нотифаера, поэтому при смене лицензии получаем undefined
                    }
                    dispatch('loadProjectById', {
                        projectId: projectOperation.projectId,
                        isForce: true,
                    }, { root: true }).then(() => {
                        Router.replace({ params: Object.assign({}, Router.currentRoute.params, { licenseId: projectOperation.newLicense }) });
                    }).catch((error: any) => {
                        if (error.result === RESPONSE.OBJECT_NOT_EXISTS) {
                            Router.push({ name: RouterNames.MyLicenseProjects, params: { licenseId: rootGetters.currentLicenseId } });
                        }
                        if (error.result === RESPONSE.SECURITY_ERROR) {
                            Router.push({ name: RouterNames.MyLicenseProjects, params: { licenseId: rootGetters.currentLicenseId } });
                        }
                        if (error.result === RESPONSE.NOT_FOUND) {
                            Router.push({ name: RouterNames.MyLicenseProjects, params: { licenseId: rootGetters.currentLicenseId } });
                        }
                    });
                    const projectId = projectOperation.projectId || Router.currentRoute.params.projectId;
                    await dispatch('loadProjectMembers', {
                        projectId,
                        isForce: true,
                    }, { root: true });
                    commit('setProjectFieldVariants', {
                        projectId,
                        fieldVariants: {
                            ...getters.fieldVariantsByProjectId(projectId),
                            assignees: getters.projectMembersByProjectId(projectId).map((user: any) => user.email),
                            reporters: getters.projectMembersByProjectId(projectId).map((user: any) => user.email),
                        },
                    });
                    break;
                }
                case (Protobuf.ProjectOperation.Code.UpdateReport): {
                    dispatch('loadReportsByProjectId', {
                        projectId: projectOperation.projectId || getters.notifierProjectConnected,
                        isForce: true,
                    });
                    break;
                }
                case (Protobuf.ProjectOperation.Code.UpdateDashboard): {
                    dispatch('loadProjectDashboards', {
                        projectId: projectOperation.projectId || getters.notifierProjectConnected,
                        isForce: true,
                    });
                    break;
                }
                case (Protobuf.ProjectOperation.Code.UpdateGraph): {
                    const projectId = projectOperation.projectId || getters.notifierProjectConnected;
                    const dashboardUuid = projectOperation.relatedEntity.uuid;
                    dispatch('loadProjectDashboardCharts', { projectId, dashboardUuid, isForce: true }).then(() => {
                        const projectDashboardChartsUuids = rootGetters.projectDashboardChartsByDashboardUuid(projectOperation.relatedEntity.uuid).map((chart: DashboardChart) => chart.uuid);
                        for (const chartUuid of projectDashboardChartsUuids) {
                            dispatch('loadProjectDashboardChartView', { projectId, dashboardUuid, chartUuid, isForce: true });
                        }
                    });
                    break;
                }

                case (Protobuf.ProjectOperation.Code.UpdateWorkflowRelatedProps): {
                    const project = getters.projectById(getters.notifierProjectConnected);
                    dispatch('loadProjectWorkflowSettings', project.uuid);
                    dispatch('loadProjectFieldVariants', {
                        projectId: project.id,
                        isForce: true,
                    });
                    if (getters.selectedIssueByProjectId(project.id)) {
                        dispatch('reloadIssuesById', {
                            projectId: project.id,
                            issueId: getters.selectedIssueByProjectId(project.id).id,
                        });
                        dispatch('loadCommentsForIssue', {
                            projectId: project.id,
                            issueUuid: getters.selectedIssueByProjectId(project.id).uuid,
                            isForce: true,
                        });
                    }
                }
            }
        },
        parseNotificationOperationMessages({ commit, dispatch }, message: Msg) {
            const { code, content } = Protobuf.decodeNotificationOperationMsg(message);
            switch (code) {
                case Protobuf.NotificationOperation.Code.NewNotification: {
                    commit('addGroup', { notification: content, operationId: content.operationId });
                    break;
                }
                case Protobuf.NotificationOperation.Code.MarkRead: {
                    dispatch('readNotificationUuids', content);
                    break;
                }
                case Protobuf.NotificationOperation.Code.MarkUnread: {
                    // todo if ws gets the option to unread notifications
                    break;
                }
            }
        },
        parseMailOperationMessages({ rootGetters, dispatch }: any, message: Msg) {
            if (message.eventCode.val !== Protobuf.EventEnum.Mail_Operation) {
                throw new Error(message);
            }

            const mailOperation = Protobuf.decodeMailOperationMsg(message);
            const license = rootGetters.licenseById(mailOperation.licenseId);
            if (license && license.uuid) {
                dispatch('loadLicenseMyProjects', { license, isForce: true }, { root: true });
            }
        },
        parseLicenseMessages({ rootGetters, dispatch }: any, message: Msg) {
            if (message.eventCode.val !== Protobuf.EventEnum.License_Operation) {
                throw new Error(message);
            }

            const licenseOperation = Protobuf.decodeLicenseOperationMsg(message);
            if (licenseOperation.code === Protobuf.LicenseOperation.Code.NewRole || licenseOperation.code === Protobuf.LicenseOperation.Code.DeleteRole) {
                dispatch('loadAccessRoles', {
                    licenseId: licenseOperation.licenseId,
                }, { root: true });
            }
            if (licenseOperation.code === Protobuf.LicenseOperation.Code.EditRole) {
                const roles = rootGetters.accessRolesByLicenseId(licenseOperation.licenseId);
                const changedRole = roles.find((accessRole: AccessRole) => accessRole.uuid === licenseOperation.uuid);
                if (changedRole) {
                    dispatch('loadAccessRolePermissions', {
                        licenseId: licenseOperation.licenseId,
                        accessRoleId: changedRole.id,
                    }, { root: true });
                }
            }
            if (licenseOperation.code === Protobuf.LicenseOperation.Code.ProjectAdded || licenseOperation.code === Protobuf.LicenseOperation.Code.ProjectDeleted) {
                dispatch('loadLicenseProjects', {
                    licenseId: licenseOperation.licenseId,
                    isForce: true,
                }, { root: true });
            }

            if (licenseOperation.code === Protobuf.LicenseOperation.Code.LicenseUpdated) {
                dispatch('loadLicenseById', {
                    licenseId: licenseOperation.licenseId,
                    isForce: true,
                });
            }
        },
        parseChangeExternalConnectionMessages({ dispatch }: any, message: Msg) {
            if (message.eventCode.val !== Protobuf.EventEnum.ExternalService_Operation) {
                throw new Error(message);
            }
            dispatch('getUserConnection');
        },
        //  Обработка событий изменения штампов
        parseIssuePresetOperation({ getters, commit }: any, { projectId, message }: any) {
            enum NodeRoles {
                Stamp = 1,
                Category = 2,
            }
            const presets: any[] = JSON.parse(message.presets);
            if (message.operation === Protobuf.IssuePresetOperationEnum.Remove) {
                presets.forEach((preset: any) => {
                    const fields = JSON.parse(preset.fields);

                    if (preset.nodeRole === NodeRoles.Stamp) {
                        const stamps = getters.fieldVariantsByProjectId(projectId).stamps || [];

                        commit('setProjectFieldVariants', {
                            projectId,
                            fieldVariants: {
                                ...getters.fieldVariantsByProjectId(projectId),
                                stamps: stamps.filter((stamp: any) => fields.stampAbbr !== stamp.abbreviation),
                            },
                        });
                    }

                    if (preset.nodeRole === NodeRoles.Category) {
                        commit('setProjectFieldVariants', {
                            projectId,
                            fieldVariants: {
                                ...getters.fieldVariantsByProjectId(projectId),
                                stampCategories: getters.fieldVariantsByProjectId(projectId).stampCategories
                                    ?.filter((stampCategory: any) => stampCategory.uuid !== preset.uuid),
                            },
                        });
                    }
                    commit('setStampList', {
                        list: getters.stampListByProjectId(projectId)
                            .filter((stamp: IssueStamp) => stamp.uuid !== preset.uuid),
                        projectId,
                    });
                });
            }
            if (message.operation === Protobuf.IssuePresetOperationEnum.Edit) {
                presets.forEach((preset: any) => {
                    const fields = JSON.parse(preset.fields);
                    const stampListItem = getters.stampListByProjectId(projectId).filter((stamp: IssueStamp) => stamp.uuid === preset.uuid)[0];

                    if (preset.nodeRole === NodeRoles.Stamp) {
                        const stamps = getters.fieldVariantsByProjectId(projectId).stamps || [];

                        commit('setProjectFieldVariants', {
                            projectId,
                            fieldVariants: {
                                ...getters.fieldVariantsByProjectId(projectId),
                                stamps: stamps.map((stamp: any) => {
                                    if (stamp.abbreviation === stampListItem.fields.stampAbbr) {
                                        return {
                                            abbreviation: fields.stampAbbr,
                                            color: fields.stampColor || 1,
                                            title: preset.title,
                                        };
                                    }
                                    return stamp;
                                }),
                            },
                        });
                    }
                    if (preset.nodeRole === NodeRoles.Category) {
                        commit('setProjectFieldVariants', {
                            projectId,
                            fieldVariants: {
                                ...getters.fieldVariantsByProjectId(projectId),
                                stampCategories: [
                                    ...getters.fieldVariantsByProjectId(projectId)?.stampCategories
                                        .filter((stampCategory: any) => stampCategory.uuid !== preset.uuid),
                                    preset,
                                ],
                            },
                        });
                    }
                    commit('setStampList', {
                        list: [
                            ...getters.stampListByProjectId(projectId)
                                .filter((stamp: IssueStamp) => stamp.uuid !== preset.uuid),
                            preset,
                        ],
                        projectId,
                    });
                });
            }
            if (message.operation === Protobuf.IssuePresetOperationEnum.Add) {
                presets.forEach((preset: any) => {
                    const fields = JSON.parse(preset.fields);
                    if (preset.nodeRole === NodeRoles.Stamp) {
                        const stamps = getters.fieldVariantsByProjectId(projectId).stamps || [];

                        commit('setProjectFieldVariants', {
                            projectId,
                            fieldVariants: {
                                ...getters.fieldVariantsByProjectId(projectId),
                                stamps: [
                                    ...stamps,
                                    {
                                        abbreviation: fields.stampAbbr,
                                        color: fields.stampColor || 1,
                                        title: preset.title,
                                    },
                                ],
                            },
                        });
                    }
                    if (preset.nodeRole === NodeRoles.Category) {
                        commit('setProjectFieldVariants', {
                            projectId,
                            fieldVariants: {
                                ...getters.fieldVariantsByProjectId(projectId),
                                stampCategories: [
                                    ...getters.fieldVariantsByProjectId(projectId).stampCategories,
                                    preset,
                                ],
                            },
                        });
                    }
                    commit('setStampList', {
                        list: [
                            ...getters.stampListByProjectId(projectId),
                            preset,
                        ],
                        projectId,
                    });
                });
            }
        },
        /*
        *  parse issue/comments notifier messages
        */
        async parseIssueOperationMessages({ rootGetters, getters, commit, dispatch }: any, { message, projectId }: { message: any, projectId: number }) {
            if ([IssueOperationCode.AddTags, IssueOperationCode.EditTags, IssueOperationCode.DelTags].includes(message.code)) {
                if (message.code === IssueOperationCode.DelTags) {
                    commit('setProjectFieldVariants', {
                        projectId,
                        fieldVariants: {
                            ...getters.fieldVariantsByProjectId(projectId),
                            tags: getters.fieldVariantsByProjectId(projectId).tags.filter((item: string) => !message.tags.includes(item)),
                        },
                    });
                }
                if (message.code === IssueOperationCode.AddTags) {
                    const existTags = getters.fieldVariantsByProjectId(projectId).tags || [];
                    commit('setProjectFieldVariants', {
                        projectId,
                        fieldVariants: {
                            ...getters.fieldVariantsByProjectId(projectId),
                            tags: [
                                ...existTags,
                                ...message.tags,
                            ],
                        },
                    });
                }
                if (message.code === IssueOperationCode.EditTags) {
                    const existTags = getters.fieldVariantsByProjectId(projectId).tags || [];
                    const filteredExistTags = existTags.filter((item: string) => !message.tags.includes(item));

                    commit('setProjectFieldVariants', {
                        projectId,
                        fieldVariants: {
                            ...getters.fieldVariantsByProjectId(projectId),
                            tags: [
                                ...filteredExistTags,
                                message.newTag,
                            ],
                        },
                    });
                }
                dispatch('loadProjectById', { projectId, isForce: true });
            }

            if (!getters.isIssuesLoaded(projectId)) {
                return;
            }

            const diffRow = 'diff';
            let sourceIssue: any;

            const parseProtobufDate = (date: number) => {
                return moment.utc((Number(date) - 621355968000000000) / 10000).format(MomentFormats.serverSide);
            };

            const updateIssueReads = (uuid: string, newCommentsCount: number, issueFromMessage: Issue) => {
                const issue = getters.issuesByProjectId(projectId).find((item: Issue) => item.uuid === uuid);
                if (newCommentsCount && issue) {
                    issue.read.comments += newCommentsCount;
                }
                if (newCommentsCount && issue && (issue.isUnread || (issue.countUnreadComments > 0)))  {
                    const unreadIssuesCounts = getters.issueFilterPresetsUnreadByProjectId(projectId);

                    if (unreadIssuesCounts[allIssuesPresetUuid]) {
                        unreadIssuesCounts[allIssuesPresetUuid]!.unread = (unreadIssuesCounts[allIssuesPresetUuid]?.unread || 0) + 1;
                        commit('setIssueFilterPresetsUnread', unreadIssuesCounts);
                    }
                }

                if (
                    newCommentsCount
                    && issueFromMessage
                    && (rootGetters.searchIssueFromOrder({ projectId, issueUuid: issueFromMessage.uuid }) !== -1)
                ) {
                    dispatch('addIssueToOrderListToProject', { projectId, changedIssue: issueFromMessage });
                    dispatch('enableIssueFromOrder', { projectId, issueUuid: issueFromMessage.uuid });
                }
            };

            const getIssueFromMessage = (sourceIssueFromMessage: any): Issue => {
                return new Issue({
                    id: sourceIssueFromMessage.id,
                    uuid: sourceIssueFromMessage.guid,
                    author: getters.projectMembersByProjectId(projectId).filter((member: ProjectMember) => member.email === sourceIssueFromMessage.author)[0],
                    created: {
                        value: parseProtobufDate(sourceIssueFromMessage.created),
                    },
                    preview: sourceIssueFromMessage.snapshot ? sourceIssueFromMessage.snapshot.links : null,
                    customStatus: {
                        value: sourceIssueFromMessage.customStatus,
                    },
                    customType: {
                        value: sourceIssueFromMessage.customType,
                    },
                    markup: {
                        value: sourceIssueFromMessage.markups,
                    },
                    assignee: {
                        value: sourceIssueFromMessage.assigned,
                    },
                    title: {
                        value: sourceIssueFromMessage.title,
                    },
                    visibility: {
                        value: !(sourceIssueFromMessage.visibility ?? 1),
                    },
                    watchers: {
                        value: sourceIssueFromMessage.watchers || [],
                    },
                    tags: {
                        value: sourceIssueFromMessage.tags || [],
                    },
                    reporter: {
                        value: sourceIssueFromMessage.reporter,
                    },
                    status: {
                        value: FromProtobuf.numberToStatus(sourceIssueFromMessage.status),
                    },
                    priority: {
                        value: FromProtobuf.numberToPriority(sourceIssueFromMessage.priority || 0),
                    },
                    deadline: {
                        value: sourceIssueFromMessage.deadline
                            ? parseProtobufDate(sourceIssueFromMessage.deadline)
                            : DEADLINE_NOT_SET,
                    },
                    unread: {
                        comments: 0,
                        issue: rootGetters.userData.email !== sourceIssueFromMessage.author,
                    },
                    sheet: {
                        value: [],
                    },
                    statusAuto: {
                        value: 0,
                    },
                    stampAbbr: {
                        value: sourceIssueFromMessage.stampAbbr,
                    },
                    stampColor: {
                        value: sourceIssueFromMessage.stampColor,
                    },
                });
            };

            const canSeeIssue = (issue: Issue, project: Project, currentUserEmail: string): boolean => {
                return project.isAdminRights
                    || [issue.reporter, issue.author.email, ...issue.watchers].includes(currentUserEmail)
                    || (project.isCanViewPublicIssues && (issue.visibility === Boolean(IssueVisibility.public)));
            };

            const addNewIssueToIssueList = (newIssue: Issue) => {
                commit('addIssues', {
                    projectId,
                    issues: [
                        newIssue,
                    ],
                });
                dispatch('addIssueToOrderListToProject', { projectId, changedIssue: newIssue });
                dispatch('enableIssueFromOrder', { projectId, issueUuid: newIssue.uuid });
            };

            if (message.code === IssueOperationCode.Add || !message.code) {
                sourceIssue = message.outMarker;
                const issues = getters.allIssuesByProjectId(projectId).map((issue: Issue) => issue.uuid);
                if (issues.includes(sourceIssue.guid)) {
                    return;
                }

                const newIssue: Issue = getIssueFromMessage(message.outMarker);
                commit('setProjectFieldVariants', {
                    projectId,
                    fieldVariants: mixIssueFieldVariants(newIssue, getters.fieldVariantsByProjectId(projectId)),
                });

                const project: Project = rootGetters.projectById(projectId);
                if (!project.uuid || !canSeeIssue(newIssue, project, rootGetters.userData.email)) {
                    return;
                }

                const filters = rootGetters.trackerFiltersByProjectId(projectId);
                const issuesCount = rootGetters.issuesCountByProjectId(projectId);
                if (filterIssues([newIssue], filters).length) {
                    commit('setIssuesCount', { projectId, issuesCount: issuesCount + 1 });
                }

                // инкрементим счётчик для алл ишьюс пресета
                if (rootGetters.userData.email !== sourceIssue.author) {
                    const unreadIssuesCounts = getters.issueFilterPresetsUnreadByProjectId(projectId);

                    if (unreadIssuesCounts[allIssuesPresetUuid]) {
                        unreadIssuesCounts[allIssuesPresetUuid].unread = (unreadIssuesCounts[allIssuesPresetUuid]?.unread || 0) + 1;
                        commit('setIssueFilterPresetsUnread', unreadIssuesCounts);
                    }
                }

                addNewIssueToIssueList(newIssue);
                return;
            }

            if (message.code === IssueOperationCode.Change) {
                dispatch('loadProjectById', { projectId, isForce: true });
                const issue = getters.allIssuesByProjectId(projectId).find((searchIssue: Issue) => searchIssue.uuid === message.outMarker.guid);
                const filters = rootGetters.trackerFiltersByProjectId(projectId);

                sourceIssue = message.outMarker;

                if (!issue) {
                    const unreadIssuesCounts = getters.issueFilterPresetsUnreadByProjectId(projectId);

                    if (unreadIssuesCounts[allIssuesPresetUuid]) {
                        unreadIssuesCounts[allIssuesPresetUuid].unread = (unreadIssuesCounts[allIssuesPresetUuid]?.unread || 0) + 1;
                        commit('setIssueFilterPresetsUnread', unreadIssuesCounts);

                        const allIssuePreset = getters.issueFilterPresetsByProjectId(projectId)
                            .find((preset: IssueFilterPreset) => preset.uuid === allIssuesPresetUuid);

                        allIssuePreset.isAllowShowCounts = false;
                    }

                }

                let changeCount = 0;
                const newIssue: Issue = getIssueFromMessage(message.outMarker);

                commit('setProjectFieldVariants', {
                    projectId,
                    fieldVariants: mixIssueFieldVariants(newIssue, getters.fieldVariantsByProjectId(projectId)),
                });

                const project: Project = rootGetters.projectById(projectId);

                const canSeeCurrentIssue = project.uuid && canSeeIssue(newIssue, project, rootGetters.userData.email);
                let foundNumber;
                if (filterIssues([newIssue], filters).length && canSeeCurrentIssue) {
                    foundNumber = rootGetters.searchIssueFromOrder({
                        projectId,
                        issueUuid: newIssue.uuid,
                        statusToSearch: IssueOrderRecordStatusEnum.enable,
                    });

                    // if foundNumber === 1, issue is found, and we do nothing
                    if (foundNumber === 0) {
                        changeCount++;
                    } else if (foundNumber === -1) {
                        await dispatch('addIssueToOrderListToProject', { projectId, changedIssue: newIssue });
                        changeCount++;
                    }
                    if (foundNumber !== 1) {
                        await dispatch('enableIssueFromOrder', { projectId, issueUuid: newIssue.uuid });
                    }

                    if (issue) {
                        issue._title.value = sourceIssue.title;
                        issue.author = getters.projectMembersByProjectId(projectId).filter((member: ProjectMember) => member.email === sourceIssue.author)[0];
                        issue._created.value = parseProtobufDate(sourceIssue.created);
                        issue._preview = sourceIssue.snapshot ? sourceIssue.snapshot.links : null;
                        issue._assignee.value = sourceIssue.assigned;
                        issue._visibility.value = !(sourceIssue.visibility ?? 1);
                        issue._watchers.value = sourceIssue.watchers || [];
                        issue._tags.value = sourceIssue.tags || [];
                        issue._reporter.value = sourceIssue.reporter;
                        issue._status.value = FromProtobuf.numberToStatus(sourceIssue.status);
                        issue._priority.value = FromProtobuf.numberToPriority(sourceIssue.priority || 0);
                        issue._deadline.value = sourceIssue.deadline
                            ? parseProtobufDate(sourceIssue.deadline)
                            : DEADLINE_NOT_SET;
                        issue._statusAuto.value = sourceIssue.statusAuto;
                        issue._stampAbbr.value = sourceIssue.stampAbbr;
                        issue._customStatus.value = sourceIssue.customStatus;
                        issue._customType.value = sourceIssue.customType;
                        issue._stampColor.value = sourceIssue.stampColor;
                        issue._procore.value = sourceIssue.procore || null;
                        if (getters.selectedIssueByProjectId(projectId)?.uuid === newIssue.uuid) {
                            commit('setMarkupEditorContent', sourceIssue.markups);
                            if (sourceIssue.snapshot?.links?.original) {
                                commit('setMarkupEditorPreviousBackground', {
                                    original: rootGetters.markupEditor?.background?.original,
                                    small: rootGetters.markupEditor?.background?.small,
                                });
                                commit('setMarkupEditorBackground', {
                                    original: sourceIssue.snapshot.links.original,
                                    small: sourceIssue.snapshot.links.small,
                                });
                            }
                            dispatch('reloadIssuesById', {
                                projectId: project.id,
                                issueId: getters.selectedIssueByProjectId(project.id).id,
                            });
                            dispatch('loadCommentsForIssue', {
                                projectId: project.id,
                                issueUuid: getters.selectedIssueByProjectId(project.id).uuid,
                                isForce: true,
                            });
                        }
                    } else {
                        addNewIssueToIssueList(newIssue);
                    }
                } else {
                    foundNumber = rootGetters.searchIssueFromOrder({
                        projectId,
                        issueUuid: newIssue.uuid,
                        statusToSearch: IssueOrderRecordStatusEnum.disable,
                    });
                    // if foundNumber === 0, issue is found, but it is in another status, it should be disabled
                    if (foundNumber === 0) {
                        changeCount--;
                        dispatch('disableIssueFromOrder', { projectId, issueUuid: newIssue.uuid });
                        if (getters.selectedIssueByProjectId(projectId).uuid === newIssue.uuid) {
                            commit('setSelectedIssue', { projectId, issue: null });
                        }
                    }
                }
                const issuesCount = rootGetters.issuesCountByProjectId(projectId);
                commit('setIssuesCount', { projectId, issuesCount: issuesCount + changeCount });

                return;
            }

            if (message.code === IssueOperationCode.Delete) {
                const newIssue: Issue = getIssueFromMessage(message.outMarker);
                const issuesCount = rootGetters.issuesCountByProjectId(projectId);
                const isDecreased = await dispatch('disableIssueFromOrder', { projectId, issueUuid: newIssue.uuid });
                if (isDecreased) {
                    commit('setIssuesCount', { projectId, issuesCount: issuesCount - Number(isDecreased) });
                }

                const issue = getters.allIssuesByProjectId(projectId).find((searchIssue: Issue) => searchIssue.uuid === message.outMarker.guid);
                if (!issue) {
                    return;
                }

                commit('removeIssues', {
                    projectId,
                    issues: [
                        issue,
                    ],
                });

                commit('removeCommentsForIssue', {
                    projectId,
                    issueUuid: issue.uuid,
                });

                if (getters.selectedIssueByProjectId(projectId).uuid === newIssue.uuid) {
                    commit('setSelectedIssue', { projectId, issue: null });
                }

                return;
            }

            if (message.code === IssueOperationCode.AddComments) {
                if (getters.isMarkupLoading) {
                    return;
                }
                const changedIssue = getIssueFromMessage(message.outMarker);

                const project: Project = rootGetters.projectById(projectId);
                if (!project.uuid || !canSeeIssue(changedIssue, project, rootGetters.userData.email)) {
                    return;
                }

                const issueUuid = message.outMarker.guid;
                const isIssueCommentsLoadedFromRequest = getters.isIssueCommentsLoadedFromRequest(projectId, issueUuid);

                if (!isIssueCommentsLoadedFromRequest) {
                    return;
                }

                const issueComments = getters.commentsByIssue(projectId, issueUuid).map((comment: AbstractComment) => comment.uuid);
                let newCommentsCount = 0;
                const comments: any = [];

                message.inComments.forEach((comment: any) => {
                    if (issueComments.includes(comment.guid)) {
                        return;
                    }
                    switch (comment.type) {
                        case (Protobuf.Issue.Comment.CommentType.Differences):
                            comment.type = CommentType.Diff;
                            break;
                        case (Protobuf.Issue.Comment.CommentType.Markups):
                            comment.type = CommentType.Markup;
                            break;
                        case (Protobuf.Issue.Comment.CommentType.Attachment):
                            comment.type = CommentType.File;
                            break;
                        default:
                            comment.type = CommentType.Text;
                    }

                    comment.uuid = comment.guid;
                    delete comment.guid;

                    comment.created = parseProtobufDate(comment.created);
                    comment.author = getters.projectMembersByProjectId(projectId).filter((member: ProjectMember) => member.email === comment.author)[0];

                    if (rootGetters.userData.email !== comment.author) {
                        newCommentsCount++;
                    }

                    if ([CommentType.File, CommentType.Markup].includes(comment.type)) {

                        comment.mimetype = comment.mimeType;
                        delete comment.mimeType;

                        comment.preview = comment.links;
                        delete comment.links;
                    }

                    if (comment.type === CommentType.Diff) {
                        const differences0 = getIssueProtobufField(Protobuf.Issue.Field0, comment.differences0);
                        const differences1 = getIssueProtobufField(Protobuf.Issue.Field1, comment.differences1);
                        const receivedFields = differences0.concat(differences1);

                        for (const receivedField of receivedFields) {
                            const transformedField = transformProtobufDiffRowToIssueField(receivedField);
                            const field = (IssueTrackerFields as any)[transformedField];
                            if (!field) {
                                continue;
                            }

                            let oldValue: any;
                            let newValue: any;

                            if ([IssueTrackerFields.customStatus, IssueTrackerFields.customType].includes(field)) {
                                oldValue = comment.prevMarker[field];
                                newValue = comment.newMarker[field];
                            }

                            if (field === IssueTrackerFields.status) {
                                oldValue = FromProtobuf.numberToStatus(comment.prevMarker[field]);
                                newValue = FromProtobuf.numberToStatus(comment.newMarker[field]);
                            }

                            if ([
                                IssueTrackerFields.title,
                                IssueTrackerFields.watchers,
                                IssueTrackerFields.tags,
                                IssueTrackerFields.stampColor,
                                IssueTrackerFields.stampAbbr,
                                IssueTrackerFields.reporter,
                                IssueTrackerFields.procore,
                            ].includes(field as IssueTrackerFields)) {
                                oldValue = comment.prevMarker[field];
                                newValue = comment.newMarker[field];
                            }

                            if (field === IssueTrackerFields.assignee) {
                                oldValue = comment.prevMarker[assignedRow];
                                newValue = comment.newMarker[assignedRow];
                            }

                            if (field === IssueTrackerFields.visibility) {
                                oldValue = Number(!comment.prevMarker[field]);
                                newValue = Number(!comment.newMarker[field]);
                            }

                            if (field === IssueTrackerFields.deadline) {
                                oldValue = parseProtobufDate(comment.prevMarker[field]);
                                newValue = parseProtobufDate(comment.newMarker[field]);
                            }

                            if (field === IssueTrackerFields.priority) {
                                oldValue = FromProtobuf.numberToPriority(comment.prevMarker[field]);
                                newValue = FromProtobuf.numberToPriority(comment.newMarker[field]);
                            }

                            comment[diffRow] = {
                                ...comment[diffRow],
                                [field]: {
                                    old: oldValue,
                                    new: newValue,
                                },
                            };
                        }
                    }

                    commit('addCommentsForIssue', {
                        projectId,
                        issueUuid,
                        comments: [
                            {
                                data: comment,
                            },
                        ],
                    });
                    dispatch('reloadIssuesById', {
                        projectId,
                        issueId: message.outMarker.id,
                    });
                    comments.push(comment);
                });

                updateIssueReads(issueUuid, newCommentsCount, getIssueFromMessage(message.outMarker));
            }
        },
        parseSchedulerMessages({ commit }: any, message: SchedulerNotifierMessage) {
            const code = message.code;
            switch (code) {
                case SchedulerNotifierMessageCode.TaskDefinitionAdd:
                    commit('setSchedulerTask', { projectUuid: message.projectUuid, task: new TaskDefinition(JSON.parse(message.bodyJson)) });
                    break;

                case SchedulerNotifierMessageCode.TaskDefinitionModify:
                    commit('setSchedulerTask', { projectUuid: message.projectUuid, task: new TaskDefinition(JSON.parse(message.bodyJson)) });
                    break;

                case SchedulerNotifierMessageCode.TaskDefinitionDelete:
                    commit('removeSchedulerTask', { projectUuid: message.projectUuid, uuid: message.taskDefinitionUuid });
                    break;

                case SchedulerNotifierMessageCode.TaskRunAdd:
                    commit('setSchedulerTaskHistory', { projectUuid: message.projectUuid, taskHistory: new TaskHistory(JSON.parse(message.bodyJson)) });
                    break;

                case SchedulerNotifierMessageCode.TaskRunModify:
                    commit('setSchedulerTaskHistory', { projectUuid: message.projectUuid, taskHistory: new TaskHistory(JSON.parse(message.bodyJson)) });
                    break;
            }
        },
    },
} as Module<IWebsocketStorage, any>;
