import _ from 'lodash';
// @ts-ignore
import pako from 'pako';
import http from '@/api/http';
import { FieldVariants, IssuesFilterConstructor, Project, ProjectMember } from '@/models';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import {
    ArgId,
    EventEnum,
    Issue,
    IssueFilterSetOrder,
    IssueFilterSetRules,
    IssueOperation,
    IssuePresetOperation,
    IssuePresetOperationEnum,
    Issue as IssueProtobuf,
    LicenseOperation,
    MailOperation,
    Msg,
    NotificationOperation,
    Obj,
    ObjInt,
    ObjItem,
    ObjItemList,
    OperationEnum,
    ProductEnum,
    ProjectOperation,
    PropertyId,
    SchedulerOperation,
    SortingPreset,
// @ts-ignore
} from '@/protobuf/Message';
import { ISorting } from '@/services/IssueOrderParse';
import { StrToUuinArrayConverter } from '@/services/StrToUuinArrayConverter';
import { IssueOrderEncode, IssueOrderParse } from '@/services/IssueOrderParse';

export {
    FilterSetEnum,
    Issue,
    IssueFilterSetOrder,
    IssueFilterSetRule,
    IssueFilterSetRules,
    Msg,
    SortingPreset,
    RuleTypeEnum,
    IssuePresetOperation,
// @ts-ignore
} from '@/protobuf/Message';

export class Protobuf {
    public static OperationEnum = OperationEnum;
    public static EventEnum = EventEnum;
    public static ProjectOperation = ProjectOperation;
    public static NotificationOperation = NotificationOperation;
    public static LicenseOperation = LicenseOperation;
    public static Issue = Issue;
    public static IssuePresetOperationEnum = IssuePresetOperationEnum;

    public static encodeToProtobufString(codesArr: number[]) {
        return btoa(String.fromCharCode(...codesArr));
    }

    public static decodeFromProtobufString(value: any) {
        return StrToUuinArrayConverter(atob(value));
    }

    public static decodeFromProtobufString64(value: any) {
        return this.decodeFromProtobufString(value.protoBase64);
    }

    public static encodeFilters(filters: TrackerFilters, projectFieldVariants: FieldVariants, projectMembers: ProjectMember[]) {
        const protoFilters = _.values(filters)
            .map((filter: any) => {
                return IssuesFilterConstructor.encodeToProtobuf(filter, projectFieldVariants, projectMembers);
            })
            .filter(Boolean);
        return this.encodeToProtobufString(
            IssueFilterSetRules.encode(new IssueFilterSetRules({ Rules: protoFilters })).finish(),
        );
    }

    public static encodeSorting(sorting: ISorting) {
        const protoSorting = IssueOrderEncode(sorting);
        return this.encodeToProtobufString(SortingPreset.encode(protoSorting).finish());
    }

    public static decodeSorting(sorting: any) {
        const protoSort = SortingPreset.decode(this.decodeFromProtobufString(sorting));
        return IssueOrderParse(protoSort);
    }

    public static encodeOrder(orderArr: any[]) {
        return this.encodeToProtobufString(IssueFilterSetOrder.encode({ Items: orderArr }).finish());
    }

    public static decodeOrder(order: any) {
        return IssueFilterSetOrder.decode(this.decodeFromProtobufString(order));
    }

    public static decodeClashes(value: any) {
        return IssueProtobuf.ClashStorage.decode(this.decodeFromProtobufString64(value));
    }

    public static decodeIssueTemplate(value: any) {
        return IssuePresetOperation.decode(value.args);
    }

    public static decodeSchedulerOperation(value: any) {
        return SchedulerOperation.decode(value.args);
    }

    public static decodeRClashes(value: any) {
        return IssueProtobuf.RClashStorage.decode(this.decodeFromProtobufString64(value));
    }

    public static decodeProperty(value: any) {
        return IssueProtobuf.Prop.decode(this.decodeFromProtobufString(value));
    }

    public static decodeInternalProperties(value: any) {
        return IssueProtobuf.InternalProperties.decode(this.decodeFromProtobufString(value));
    }

    public static encodeInternalProperties(internalProperties: any) {
        return this.encodeToProtobufString(IssueProtobuf.InternalProperties.encode(internalProperties).finish());
    }

    public static decompressClashes(compressed: any) {
        try {
            const unziped = pako.inflate(compressed);
            return IssueProtobuf.ClashStorage.Compressed.decode(unziped);
        } catch (e) {
            window.console.warn('Protobuf.decompressClashes error', e);
            return;
        }
    }

    public static decompressRClashes(compressed: any) {
        try {
            const unziped = pako.inflate(compressed);
            return IssueProtobuf.RClashStorage.Compressed.decode(unziped);
        } catch (e) {
            window.console.warn('Protobuf.decompressRClashes error', e);
            return;
        }
    }

    public static decodeRules(filters: any[]) {
        return IssueFilterSetRules.decode(this.decodeFromProtobufString(filters)).Rules;
    }

    public static getMsgWsAuthenticate() {
        const authCheckPath = 'auth/check';
        const asString = http.getFillPath(authCheckPath, {});
        const key = ArgId.Auth;
        const obj = new Obj({ asString });
        const items = [new ObjItem({ key, obj })];
        const list = new ObjItemList({ items });
        const operation = new ObjInt({ val: OperationEnum.Authenticate });
        const args = ObjItemList.encode(list).finish();
        const msg = new Msg({ operation, args });
        return Msg.encode(msg).finish();
    }

    public static getMsgWsUnique(guid: string) {
        const key = ArgId.Guid;
        const obj = new Obj({ asString: guid });
        const items = [new ObjItem({ key, obj })];
        const list = new ObjItemList({ items });
        const operation = new ObjInt({ val: OperationEnum.Unique });
        const args = ObjItemList.encode(list).finish();
        const msg = new Msg({ operation, args });
        return Msg.encode(msg).finish();
    }

    public static getMsgWsProps(userData: any) {
        const key = PropertyId.ActorFlags;
        const obj = new Obj({ asInt: 0 });
        const items = [new ObjItem({ key, obj })];
        const data = {
            [PropertyId.NickName]: userData.id.toString(),
            [PropertyId.NickName]: 'start',
            [PropertyId.ProductVer]: 'web',
            [PropertyId.FirstName]: userData.firstname,
            [PropertyId.LastName]: userData.lastname,
            [PropertyId.AvatarUrl]: userData.avatar,
            [PropertyId.EMail]: userData.email,
            [PropertyId.Product]: String(ProductEnum.ViewerWeb),
        };
        const additionalItems = _.entries(data).map(([aKey, value]) => {
            return new ObjItem({ key: aKey, obj: new Obj({ asString: value }) });
        });
        const list = new ObjItemList({
            items: [...items, ...additionalItems],
        });
        const eventCode = new ObjInt({ val: EventEnum.Props });
        const args = ObjItemList.encode(list).finish();
        const msg = new Msg({ eventCode, args });

        return Msg.encode(msg).finish();
    }

    public static getMsgWsPing() {
        const msg = new Msg();
        return Msg.encode(msg).finish();
    }

    public static getMsgWsJoinProject(project: Project) {
        const key1 = ArgId.Room;
        const obj1 = new Obj({ asString: project.id.toString() });
        const key2 = ArgId.LicenseId;
        const obj2 = new Obj({ asInt: new ObjInt({ val: project.licenseId }) });
        const items = [
            new ObjItem({ key: key1, obj: obj1 }),
            new ObjItem({ key: key2, obj: obj2 }),
        ];
        const list = new ObjItemList({ items });
        const operation = new ObjInt({ val: OperationEnum.Join });
        const args = ObjItemList.encode(list).finish();
        const msg = new Msg({ operation, args });
        return Msg.encode(msg).finish();
    }

    public static getMsgWsLeaveProject() {
        const operation = new ObjInt({ val: OperationEnum.Leave });
        const msg = new Msg({ operation });
        return Msg.encode(msg).finish();
    }

    public static getMsgWsDecodeArrayBuffer(result: ArrayBuffer) {
        return Msg.decode(new Uint8Array(result));
    }

    public static isDoParseIssuesChangeMessages(message: Msg) {
        const issueOperation = IssueOperation.decode(message.args);
        return !issueOperation.code || [
            IssueOperation.Code.Add,
            IssueOperation.Code.Change,
            IssueOperation.Code.Delete,
        ].includes(issueOperation.code);
    }

    public static decodeProjectOperationMsg(message: Msg) {
        return ProjectOperation.decode(message.args);
    }

    public static decodeMailOperationMsg(message: Msg) {
        return MailOperation.decode(message.args);
    }

    public static decodeLicenseOperationMsg(message: Msg) {
        return  LicenseOperation.decode(message.args);
    }

    public static decodeIssueOperationMsg(message: Msg) {
        return IssueOperation.decode(message.args);
    }

    public static decodeNotificationOperationMsg(message: Msg) {
        const decoded = NotificationOperation.decode(message.args);
        const { code, notificationContent } = decoded;
        return { code, content: JSON.parse(notificationContent) };
    }
}
