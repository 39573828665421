import { enumToKeysArray } from '@/services/enumConverter';

export enum StampColorsPaletteRGBEnum {
    '227, 17, 41' = 1,
    '250, 129, 28',
    '253, 222, 50',
    '109, 220, 51',
    '23, 148, 39',
    '78, 242, 242',
    '29, 0, 229',
    '138, 23, 255',
    '239, 15, 255',
    '0, 0, 0',
}

export const StampColorsPaletteRGBText = {
    [StampColorsPaletteRGBEnum['227, 17, 41'] as number]: 'red',
    [StampColorsPaletteRGBEnum['250, 129, 28'] as number]: 'orange',
    [StampColorsPaletteRGBEnum['253, 222, 50'] as number]: 'yellow',
    [StampColorsPaletteRGBEnum['109, 220, 51'] as number]: 'lime',
    [StampColorsPaletteRGBEnum['23, 148, 39'] as number]: 'green',
    [StampColorsPaletteRGBEnum['78, 242, 242'] as number]: 'sky',
    [StampColorsPaletteRGBEnum['29, 0, 229'] as number]: 'blue',
    [StampColorsPaletteRGBEnum['138, 23, 255'] as number]: 'purple',
    [StampColorsPaletteRGBEnum['239, 15, 255'] as number]: 'violet',
    [StampColorsPaletteRGBEnum['0, 0, 0'] as number]: 'black',
};

export const StampColorsPaletteRGB = enumToKeysArray(StampColorsPaletteRGBEnum);

