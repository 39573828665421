export const IssueFilterExpr = {
    IN: 1,
    NOT_IN: -1,

    HAS_ONE_IN: 2,
    HAS_ALL_IN: 3,
    HAS_NOT_ALL_IN: -3,

    EQUALS: 4,
    NOT_EQUALS: -4,

    LESS_THAN: 5,
    LESS_OR_EQUAL_THAN: 6,
    MORE_THAN: 7,
    MORE_OR_EQUAL_THAN: 8,
    INTERVAL: 11,

    SET: 9,
    NOT_SET: -9,

    IS_NULL: 10,
    IS_NOT_NULL: -10,

    INCLUDE: 12,
    NOT_INCLUDE: -12,
};

type Keys = keyof typeof IssueFilterExpr;
export type IssueFilterExprT = typeof IssueFilterExpr[Keys];
