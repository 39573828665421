export interface IBranch {
    id: number;
    name: string;
    active: boolean;
    isLatest: boolean;
}

export class Branch implements IBranch {
    public id: number;
    public name: string;
    public active: boolean;
    public isLatest: boolean;

    constructor(branch: IBranch | any = {}) {
        this.id = branch.id || 0;
        this.name = branch.name || '';
        this.active = branch.active || false;
        this.isLatest = branch.isLatest || false;
    }
}
