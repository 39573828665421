export enum CameFrom {
    wrongLicense = 'wrongLicense',
    wrongProject = 'wrongProject',
    wrongProjectDashboard = 'wrongProjectDashboard',
    wrongLicenseDashboard = 'wrongLicenseDashboard',
    wrongLicenseMember = 'wrongLicenseMember',
    wrongPage = 'wrongPage',
    issueSettings = 'issueSettings',
    workflowSettings = 'workflowSettings',
}
