




























import moment from 'moment';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
// @ts-ignore
import DateRangePicker from 'vue2-daterange-picker';
import { Dict } from '@/types/Dict';
import { DateFormatByNumber } from '@/constants';
import { DatepickerDateRange, IDateRange } from '@/models';

enum Position {
    Left = 'left',
    Right = 'right',
    Center = 'center',
}

@Component({
    components: {
        DateRangePicker,
    },
})
export default class WsDateRangePicker extends Vue {
    @Prop({ required: true }) public value!: DatepickerDateRange | IDateRange;
    @Prop() public localeData!: any;
    @Prop({ type: Boolean, default: true }) public autoApply!: boolean;
    @Prop({ type: Boolean, default: true }) public showDropdowns!: boolean;
    @Prop({ type: Boolean, default: true }) public showWeekNumbers!: boolean;
    @Prop({ type: Boolean, default: false }) public linkedCalendars!: boolean;
    @Prop({ type: Boolean, default: false }) public singleDatePicker!: boolean;
    @Prop({ type: Boolean, default: false }) public dynamicOpens!: boolean;
    @Prop() public ranges!: Dict<Date[]>;
    @Prop({ default: null }) public minDate!: Date | string;
    @Prop({ default: () => new Date() }) public maxDate!: Date;
    @Prop({ default: Position.Right }) public opens!: Position;

    public calculatedOpens = this.opens;

    get model(): DatepickerDateRange | IDateRange {
        return this.value;
    }
    set model(value: DatepickerDateRange | IDateRange) {
        const { startDate, endDate } = value;
        const startDateTweaked = moment(startDate).startOf('day');
        const endDateTweaked = moment(endDate).endOf('day');
        const valueTweaked = { startDate: startDateTweaked, endDate: endDateTweaked };
        if (this.value instanceof DatepickerDateRange) {
            this.input(new DatepickerDateRange(valueTweaked));
            return;
        }
        this.input(valueTweaked);
    }

    get locale() {
        const dateFormat = String(DateFormatByNumber[this.$store.getters.userData.dateFormat]).toLowerCase();
        return Object.assign({
            format: dateFormat,
        }, this.localeData);
    }

    @Emit()
    public input(value: any) {
        return value;
    }

    @Emit()
    public update() {
        return;
    }

    @Emit()
    public toggle(value: boolean) {
        if (value && this.dynamicOpens) {
            this.calculateOpens();
        }

        return value;
    }

    public calculateOpens() {
        const xCoordinate = this.$el.getBoundingClientRect().x;
        if (xCoordinate < 135) {
            this.calculatedOpens = Position.Right;
        } else if (xCoordinate + 370 > innerWidth && innerWidth > 700) {
            this.calculatedOpens = Position.Left;
        } else {
            this.calculatedOpens = Position.Center;
        }
    }

    public show() {
        (this.$refs?.datepicker as any)?.togglePicker(true, true);
    }

    public hide() {
        (this.$refs?.datepicker as any)?.togglePicker(false, true);
    }
}
