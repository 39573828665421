export enum ExtraFieldsManagementMode {
    none,
    any,
    listed,
}

export const ExtraFieldsManagementModeList = [
    ExtraFieldsManagementMode.none,
    ExtraFieldsManagementMode.any,
    ExtraFieldsManagementMode.listed,
];
