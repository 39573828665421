


















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { IssueSort, IssueSortBy, IssueSorts } from '@/constants';
import { Project } from '@/models';
import { TrackerFilters } from '@/models/ProjectIssuesFilters';
import { ISorting } from '@/services/IssueOrderParse';
import WsCheckbox from '@/components/common/WsCheckbox.vue';

@Component({
    components: {
        WsCheckbox,
    },
})
export default class IssueSortComponent extends Vue {
    public readonly IssueSortBy = IssueSortBy;

    get projectId(): number {
        return Number(this.$route.params.projectId);
    }

    get trackerColumns() {
        return this.$store.getters.trackerColumns;
    }

    get currentProject(): Project {
        return this.$store.getters.projectById(this.projectId);
    }

    get issuesSort(): ISorting {
        return this.$store.getters.selectedIssueFilterPresetByProjectId(this.projectId)?.sorting
            || this.$store.getters.issuesSortByProjectId(this.projectId);
    }

    get isUnread() {
        return Boolean(this.issuesSort[IssueSorts.unread]);
    }
    set isUnread(value: boolean) {
        const sort = { ...this.issuesSort, [IssueSorts.unread]: value };
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get primaryDirection() {
        return this.issuesSort[IssueSorts.primary][IssueSort.direction];
    }
    set primaryDirection(value: any) {
        const sort = { ...this.issuesSort };
        sort[IssueSorts.primary][IssueSort.direction] = value;
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get secondaryDirection() {
        return this.issuesSort[IssueSorts.secondary][IssueSort.direction];
    }
    set secondaryDirection(value: any) {
        const sort = { ...this.issuesSort };
        sort[IssueSorts.secondary][IssueSort.direction] = value;
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get primaryField() {
        return this.issuesSort[IssueSorts.primary][IssueSort.field];
    }
    set primaryField(value: any) {
        const sort = { ...this.issuesSort };
        sort[IssueSorts.primary][IssueSort.field] = value;
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get secondaryField() {
        return this.issuesSort[IssueSorts.secondary][IssueSort.field];
    }
    set secondaryField(value: any) {
        const sort = { ...this.issuesSort };
        sort[IssueSorts.secondary][IssueSort.field] = value;
        this.$store.commit('setIssueSort', { projectId: this.projectId, sort });
    }

    get trackerFilters(): TrackerFilters {
        return this.$store.getters.trackerFiltersByProjectId(this.projectId);
    }

    @Watch('issuesSort', { immediate: true, deep: true })
    public issuesSortChanged(value: any, oldValue: any) {
        if (oldValue !== undefined) {
            this.$store.dispatch('updateTrackerFilters', { projectId: this.projectId, filters: this.trackerFilters });
            this.$store.commit('setCurrentPage', { projectId: this.projectId, page: 1 });
        }
    }
}
