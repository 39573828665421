



























import { Component, Emit, Prop } from 'vue-property-decorator';
import { ITab } from '@/types/ui';
import { Color } from '@/constants';
import VuetifyElement from '@/components/common/VuetifyElement.vue';

@Component
export default class WsTableHeader extends VuetifyElement {
    @Prop() public tabs!: ITab[];
    @Prop({ type: Boolean, default: false }) public fixedTabs!: boolean;

    public readonly Color = Color;

    @Emit()
    public change(index: number) {
        return index;
    }
}
