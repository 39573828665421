import axios from 'axios';
import { httpParams } from '@/api/httpParams';

export default class UpdaterHttp {
    /**
     * Core-метод для get-запроса
     * @param url
     * @param params - параметры запроса
     * @returns {Promise}
     */
    public static get(url: string, params: any = {}) {
        params.region = httpParams.releaseRegion;
        return axios({
            method: 'get',
            baseURL: httpParams.updaterPath,
            url,
            params,
            transformResponse: this.transformResponse,
        });
    }

    /**
     * Функция-interceptor для запросов
     * @param response - результат выполнения запроса
     */
    private static transformResponse(response: string): any {
        const responseObj = JSON.parse(response);
        if (responseObj.result !== 'ok') {
            throw new Error(responseObj.data);
        }
        return responseObj.data;
    }
}
