import _ from 'lodash';
import Vue from 'vue';
import ProjectApi from '@/api/project.api';
import { Activities, Activity } from '@/models';
import { notificationSuccess } from '@/services';

export default {
    state: {
        projectMembersActivityObj: {},
        isLoadingProjectMembersActivityObj: {},
        isSendingProjectMembersActivity: false,
    },
    getters: {
        projectMembersActivityByProjectId(state: any): (projectId: number) => any {
            return (projectId) => state.projectMembersActivityObj[projectId] || {};
        },
        isLoadingProjectMembersActivityByProjectId(state: any): (projectId: number) => boolean {
            return (projectId) => state.isLoadingProjectMembersActivityObj[projectId];
        },
        isSendingProjectMembersActivity(state: any): boolean {
            return state.isSendingProjectMembersActivity;
        },
    },
    mutations: {
        setProjectMembersActivity(state: any, { projectId, data }: { projectId: number, data: any }) {
            Vue.set(state.projectMembersActivityObj, projectId, data);
        },
        setIsLoadingProjectMembersActivity(state: any, { projectId, value }: { projectId: number; value: boolean }) {
            Vue.set(state.isLoadingProjectMembersActivityObj, projectId, value);
        },
        setIsSendingProjectMembersActivity(state: any, value: boolean) {
            state.isSendingProjectMembersActivity = value;
        },
    },
    actions: {
        loadProjectMembersActivity({ commit }: any, { licenseId, projectId, params }: { licenseId: number, projectId: number, params: any }) {
            return new Promise((resolve) => {
                commit('setIsLoadingProjectMembersActivity', { projectId, value: true });
                ProjectApi.postProjectMembersActivity(licenseId, projectId, params).then((response) => {
                    const activities = new Activities();
                    _.forEach(response.members, (value, memberId) => {
                        _.forEach(value, (valueObject, date) => {
                            const activity = new Activity(valueObject);
                            activities.set(date, memberId, activity);
                        });
                    });

                    commit('setProjectMembersActivity', { projectId, data: activities.get() });
                    resolve(activities.get());
                }).finally(() => {
                    commit('setIsLoadingProjectMembersActivity', { projectId, value: false });
                });
            });
        },
        sendProjectMembersActivityToEmail({ commit }: any, { params }: { params: any }) {
            commit('setIsSendingProjectMembersActivity', true);
            return ProjectApi.postExportProjectMembersActivity(params).then(() => {
                notificationSuccess('activitySent');
            }).finally(() => {
                commit('setIsSendingProjectMembersActivity', false);
            });
        },
    },
};
