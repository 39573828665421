import { Module } from 'vuex';
import { Vue } from 'vue-property-decorator';

export interface IHeaderComponentSize {
    name: string;
    size: number;
}
interface IHeaderStorage {
    width: number;
    components: {
        [componentName: string]: number; // размер в пикселях для компонента
    };
}

export default {
    state: {
        width: innerWidth,
        components: {},
    } as IHeaderStorage,
    getters: {
        headerFreeSpace(state: IHeaderStorage): number {
            return state.width - Object.values(state.components).reduce((prev, next) => prev + next, 0);
        },
    },
    mutations: {
        setHeaderComponentSize(state: IHeaderStorage, { name, size }: IHeaderComponentSize) {
            Vue.set(state.components, name, size);
        },
        setScreenWidth(state: IHeaderStorage, value: number) {
            Vue.set(state, 'width', value);
        },
    },
} as Module<IHeaderStorage, any>;
