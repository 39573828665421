import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

export class CaseConverter {
    public static toCamelCase(source: { [key: string]: any }, options?: any): any {
        return camelCaseKeys(source, Object.assign({ deep: true }, options));
    }

    public static toSnakeCase(source: object, options?: any): any {
        return snakeCaseKeys(source, Object.assign({ deep: true }, options));
    }
}
