import { v4 as uuid } from 'uuid';
import { INotify, IToast } from '@/types/INotifier';
import { Dict } from '@/types/Dict';
import { NOTIFIER_DURATION_DEFAULT } from '@/constants/Notifier/NotifierDurationDefault';
import { NotificationType } from '@/constants/Notifier/NotificationType';
import { ToastType } from '@/constants/Notifier/ToastType';
import { NotifierView } from '@/constants/Notifier/NotifierView';
import { BusEvent } from '@/constants';
import { eventBus } from '@/services/eventBus';

const notificationDuration: Dict<number> = {
    [NotificationType.INFO]: NOTIFIER_DURATION_DEFAULT,
    [NotificationType.SUCCESS]: NOTIFIER_DURATION_DEFAULT,
    [NotificationType.WARNING]: NOTIFIER_DURATION_DEFAULT,
    [NotificationType.ERROR]: Infinity,
    [NotificationType.CONFIRMATION]: Infinity,
};

const toastDuration: Dict<number>  = {
    [ToastType.INFO]: NOTIFIER_DURATION_DEFAULT,
    [ToastType.SUCCESS]: NOTIFIER_DURATION_DEFAULT,
    [ToastType.WARNING]: NOTIFIER_DURATION_DEFAULT,
    [ToastType.ERROR]: Infinity,
};

const duration: Record<number, Dict<number>> = {
    [NotifierView.NOTIFICATION]: notificationDuration,
    [NotifierView.TOAST]: toastDuration,
};

const showNotification = (view: NotifierView, type: NotificationType | ToastType, arg: any) => {
    const id = uuid();

    const options = typeof arg === 'string'
        ? { text: arg }
        : arg;

    eventBus.$emit(BusEvent.addNotifier, {
        id,
        view,
        type,
        duration: duration[view][type],
        ...options,
    });

    return {
        id,
        close: () => {
            eventBus.$emit(BusEvent.clearNotifier, id);
        },
    };
};

export const notify: INotify = {
    info: (arg) => showNotification(NotifierView.NOTIFICATION, NotificationType.INFO, arg),
    success: (arg) => showNotification(NotifierView.NOTIFICATION, NotificationType.SUCCESS, arg),
    warning: (arg) => showNotification(NotifierView.NOTIFICATION, NotificationType.WARNING, arg),
    error: (arg) => showNotification(NotifierView.NOTIFICATION, NotificationType.ERROR, arg),
    confirm: (arg) => showNotification(NotifierView.NOTIFICATION, NotificationType.CONFIRMATION, arg),
    closeAll: () => eventBus.$emit(BusEvent.clearAllNotifier, NotifierView.NOTIFICATION),
};

export const toast: IToast = {
    info: (arg) => showNotification(NotifierView.TOAST, ToastType.INFO, arg),
    success: (arg) => showNotification(NotifierView.TOAST, ToastType.SUCCESS, arg),
    warning: (arg) => showNotification(NotifierView.TOAST, ToastType.WARNING, arg),
    error: (arg) => showNotification(NotifierView.TOAST, ToastType.ERROR, arg),
    closeAll: () => eventBus.$emit(BusEvent.clearAllNotifier, NotifierView.TOAST),
};