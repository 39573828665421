import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { IObject } from '@/types/IObject';

/**
 * This function does the same as _.omit(), but recursively
 */
export function omitDeep(obj: Dict, keyToOmit: string): Dict {
    const localObj = _.cloneDeep(obj);
    go(localObj);
    return localObj;

    function go(recursiveObj: IObject): void {
        _.forIn(recursiveObj, (value, key) => {
            if (key === keyToOmit) {
                delete recursiveObj[key];
            } else if (_.isObject(value)) {
                go(value as IObject);
            }
        });
    }
}
