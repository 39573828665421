export enum LicenseMemberActionNames {
    addUser = 'addUser',
    setRole = 'setRole',
    activate = 'activate',
    deactivate = 'deactivate',
    mailNew = 'mailNew',
    mailResend = 'mailResend',
    exportExcel = 'exportExcel',
    removeFromProjects = 'removeFromProjects',
    delete = 'delete',
    editTags = 'editTags',
    setAuthMethod = 'setAuthMethod'
}