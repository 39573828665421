import _ from 'lodash';
import { IssueTrackerFields } from '@/constants';
import { AbstractComment, DiffComment, FileComment, TextComment } from '@/models';
import { i18n, mappedDiff, timeFormatter } from '@/services';

/*
*   Класс для текстового поиска по комментами в issue tracker
*   В классе реализуется два метода
*   - commentContent формирует из содержимого comment разных типов строку, чтобы по ней можно было осуществить
*     текстовый поиск и отфильтровать комменты, которые содержат вхождения строки
*   - contains показывает есть ли входжение искомой строки в этом комменте
*/

export class IssueCommentFinder {
    public comment: AbstractComment;

    constructor(comment: any = {}) {
        this.comment = comment;
    }

    get commentContent(): string {
        const stringValuesArray = [this.comment.author.fullname, timeFormatter(this.comment.created, true)];
        const diffText = i18n.t('Simple_word.changed');

        if (this.comment instanceof TextComment) {
            stringValuesArray.push(this.comment.text);
        }

        if (this.comment instanceof FileComment) {
            stringValuesArray.push(this.comment.filename);
        }

        if (this.comment instanceof DiffComment) {
            Object.keys(this.comment.diff).forEach((key: string) => {
                const diff = (this.comment as DiffComment).diff[key];
                stringValuesArray.push(`${diffText} ${key}:`);

                if ([IssueTrackerFields.tags, IssueTrackerFields.watchers].includes(key as IssueTrackerFields)) {
                    const mappedDiffArray: Array<{ deleted: boolean; added: boolean; value: string }> = mappedDiff(diff);
                    const added = mappedDiffArray.filter((item: any) => item.added);
                    const deleted = mappedDiffArray.filter((item: any) => item.deleted);
                    if (added.length) {
                        stringValuesArray.push(`${i18n.t('Simple_word.added')}: ${added.map(({ value }) => value).join(' ')}`);
                    }
                    if (deleted.length) {
                        stringValuesArray.push(`${i18n.t('Simple_word.deleted')}: ${deleted.map(({ value }) => value).join(' ')}`);
                    }

                }

                if ([IssueTrackerFields.title, IssueTrackerFields.deadline].includes(key as IssueTrackerFields)) {
                    stringValuesArray.push(diff.old as string);
                    stringValuesArray.push('➝');
                    stringValuesArray.push(diff.new as string);
                }

                if (key === IssueTrackerFields.status) {
                    stringValuesArray.push(i18n.t(`Dashboard.chart.settings.filter.status.${diff.old}`) as string);
                    stringValuesArray.push(i18n.t(`Dashboard.chart.settings.filter.status.${diff.new}`) as string);
                }

                if (key === IssueTrackerFields.priority) {
                    stringValuesArray.push(i18n.t(`Dashboard.chart.settings.filter.priority.${diff.old}`) as string);
                    stringValuesArray.push(i18n.t(`Dashboard.chart.settings.filter.priority.${diff.new}`) as string);
                }
            });
        }

        return stringValuesArray.join(' ');
    }

    public contains(searchQuery: string): boolean {
        const words = searchQuery.split(' ').filter(Boolean);
        if (RegExp(words.map(_.escapeRegExp).join('[\\w. :➝*]+'), 'igu').test(this.commentContent)) {
            return true;
        }

        return false;
    }

}
