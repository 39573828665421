

































import _ from 'lodash';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { amplitudeLog } from '@/services';

interface WsTableSelectLogKeys {
    openMenu: string;
    selectCurrentPage: string;
    selectAllPages: string;
    unselectCurrentPage: string;
    unselectAllPages: string;
}

@Component
export default class WsTableSelect extends Vue {
    @Prop({ required: true }) public items!: any[];
    @Prop({ required: true }) public selected!: any[];
    @Prop({ required: true }) public props!: any;
    @Prop({ required: true }) public tableRef!: any;
    @Prop() public logKeys!: WsTableSelectLogKeys;

    get itemsOnCurrentPage(): any[] {
        return this.tableRef?.internalCurrentItems || [];
    }
    get selectedItemsOnOthersPages(): any[] {
        return _.difference(this.selected, this.itemsOnCurrentPage);
    }
    get isSelectedAllItemsOnPage(): boolean {
        return Boolean(this.itemsOnCurrentPage.length)
            && this.itemsOnCurrentPage.length === _.intersection(this.selected, this.itemsOnCurrentPage).length;
    }
    get isSelectedAtLeastOneItemOnPage(): boolean {
        return Boolean(_.intersection(this.selected, this.itemsOnCurrentPage).length);
    }

    @Emit()
    public change(items: any[]): any[] {
        return items;
    }

    @Emit()
    public selectCurrent() {
        return;
    }

    @Emit()
    public unselectCurrent() {
        return;
    }

    public onOpenMenu() {
        if (this.logKeys?.openMenu) {
            amplitudeLog(this.logKeys.openMenu);
        }
    }

    public checkboxAction() {
        const selectedItemsOnCurrentPage = this.isSelectedAtLeastOneItemOnPage ? [] : this.itemsOnCurrentPage;
        this.change(_.concat(this.selectedItemsOnOthersPages, selectedItemsOnCurrentPage));
        if (selectedItemsOnCurrentPage.length) {
            this.selectCurrent();
            if (this.logKeys?.selectCurrentPage) {
                amplitudeLog(this.logKeys.selectCurrentPage);
            }
        } else {
            if (this.logKeys?.unselectCurrentPage) {
                amplitudeLog(this.logKeys.unselectCurrentPage);
            }
            this.unselectCurrent();
        }
    }

    public selectCurrentPage() {
        this.change(this.itemsOnCurrentPage);
        this.selectCurrent();

        if (this.logKeys?.selectCurrentPage) {
            amplitudeLog(this.logKeys.selectCurrentPage);
        }
    }

    public selectAllPages() {
        this.change(this.items);

        if (this.logKeys?.selectAllPages) {
            amplitudeLog(this.logKeys.selectAllPages);
        }
    }

    public unselectCurrentPage() {
        this.change(this.selectedItemsOnOthersPages);
        this.unselectCurrent();

        if (this.logKeys?.unselectCurrentPage) {
            amplitudeLog(this.logKeys.unselectCurrentPage);
        }
    }

    public unselectAllPages() {
        this.change([]);
        this.unselectCurrent();

        if (this.logKeys?.unselectAllPages) {
            amplitudeLog(this.logKeys.unselectAllPages);
        }
    }
}
