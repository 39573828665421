
































import { Component, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import VuetifyElement from '@/components/common/VuetifyElement.vue';
import LineType1 from '@/components/common/icon/markup/LineType1.vue';
import LineType2 from '@/components/common/icon/markup/LineType2.vue';
import LineType3 from '@/components/common/icon/markup/LineType3.vue';
import LineType4 from '@/components/common/icon/markup/LineType4.vue';
import LineType5 from '@/components/common/icon/markup/LineType5.vue';
import LineType6 from '@/components/common/icon/markup/LineType6.vue';
import LineType7 from '@/components/common/icon/markup/LineType7.vue';
import LineType8 from '@/components/common/icon/markup/LineType8.vue';
import LineType9 from '@/components/common/icon/markup/LineType9.vue';
import LineType10 from '@/components/common/icon/markup/LineType10.vue';
import LineType11 from '@/components/common/icon/markup/LineType11.vue';
import LineType12 from '@/components/common/icon/markup/LineType12.vue';
import ArrowType1 from '@/components/common/icon/markup/ArrowType1.vue';
import ArrowType2 from '@/components/common/icon/markup/ArrowType2.vue';
import ArrowType3 from '@/components/common/icon/markup/ArrowType3.vue';
import ArrowType4 from '@/components/common/icon/markup/ArrowType4.vue';

// https://vuetifyjs.com/en/api/v-select/#props

@Component({
    components: {
        LineType1,
        LineType2,
        LineType3,
        LineType4,
        LineType5,
        LineType6,
        LineType7,
        LineType8,
        LineType9,
        LineType10,
        LineType11,
        LineType12,
        ArrowType1,
        ArrowType2,
        ArrowType3,
        ArrowType4,
    },
})
export default class WsSelectLine extends VuetifyElement {
    @Prop({ required: true }) public items!: any[];
    @Prop({ required: true }) public itemText!: TranslateResult;
    @Prop({ default: false, type: Boolean }) public rgb!: boolean;
    @Prop({ default: false, type: Boolean }) public arrows!: boolean;
    @Prop({ default: false, type: Boolean }) public rounded!: boolean;

    get prefix() {
        return this.arrows ? 'Arrow' : 'Line';
    }
}
