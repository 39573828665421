interface IInput {
    field: string;
    count: number;
}

export class Activities {
    private store: any = {};

    public set(date: string, memberId: string, { field, count }: IInput) {
        this.store[date] = this.store[date] || {};
        this.store[date][memberId] = this.store[date][memberId] || {};
        this.store[date][memberId][field] = count;
    }

    public get() {
        return this.store;
    }
}
