export enum ActionLogTypeEnum {
    PROJECT_CREATION      = 10,
    PROJECT_ARCHIVATION   = 11,
    PROJECT_EDITION       = 12,
    PROJECT_DELETION      = 13,
    PROJECT_MOVEMENT_OUT  = 14,
    PROJECT_MOVEMENT_IN   = 15,
    PROJECT_UNARCHIVATION = 16,
    PROJECT_REVISION      = 17,

    LICENSE_MEMBER_ADDITION      = 21,
    LICENSE_MEMBER_CHANGE_ROLE   = 22,
    LICENSE_MEMBER_DEACTIVATION  = 23,
    LICENSE_MEMBER_DELETION      = 24,
    LICENSE_MEMBER_ACTIVATION    = 25,
    LICENSE_MEMBER_CHANGE_FIELDS = 26,

    LICENSE_ROLE_ADDITION = 31,
    LICENSE_ROLE_EDITION  = 32,
    LICENSE_ROLE_DELETION = 33,

    PROJECT_MEMBER_ADDITION    = 41,
    PROJECT_MEMBER_CHANGE_ROLE = 42,
    PROJECT_MEMBER_DELETION    = 43,

    LICENSE_SUPER_ADMIN_REPLACE = 54,
    LICENSE_BLOCK               = 55,
    LICENSE_UNBLOCK             = 56,

    AUTH_METHOD_CREATION = 61,
    AUTH_METHOD_UPDATE   = 62,
    AUTH_METHOD_DELETION = 63,
    AUTH_METHOD_ASSIGN   = 64,
    AUTH_METHOD_REVOKE   = 65,
    AUTH_METHOD_REPLACE  = 66,

    DASHBOARD_CREATION = 71,
    DASHBOARD_UPDATE   = 72,
    DASHBOARD_DELETION = 73,
    DASHBOARD_IMPORT   = 74,
    DASHBOARD_EXPORT   = 75,
    DASHBOARD_COPY     = 76,

    DASHBOARD_GRAPH_CREATION = 81,
    DASHBOARD_GRAPH_UPDATE   = 82,
    DASHBOARD_GRAPH_DELETION = 83,
    DASHBOARD_GRAPH_IMPORT   = 84,
    DASHBOARD_GRAPH_COPY     = 85,

    SEARCH_SET_CREATION  = 91,
    SEARCH_SET_DELETION  = 92,
    SEARCH_SET_UPDATE    = 93,
    SEARCH_SET_SHARING   = 94,
    SEARCH_SET_UNSHARING = 95,
    SEARCH_SET_MOVE      = 96,
    SEARCH_SET_RENAME    = 97,

    WORKFLOW_CREATE_TYPE = 101,
    WORKFLOW_EDIT_TYPE = 102,
    WORKFLOW_DELETE_TYPE = 103,
    WORKFLOW_RESTORE_TYPE = 104,

    WORKFLOW_ADD = 111,
    WORKFLOW_EDIT = 112,
    WORKFLOW_DELETE = 113,
    WORKFLOW_ADD_STATUS = 115,
    WORKFLOW_REMOVE_STATUS = 116,
    WORKFLOW_STATUS_REORDER = 117,
    WORKFLOW_REMOVE_STATUS_FROM_PROJECT = 121,

    SHARING_INFO_UPDATE = 131,
}
