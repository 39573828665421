import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { enumToKeysArray } from '@/services/enumConverter';

export enum IssueStatusEnum {
    open = 'open',
    in_progress = 'in_progress',
    solved = 'solved',
    closed = 'closed',
    deleted = 'deleted',
}

export const LegacyStatuses = [
    IssueStatusEnum.open,
    IssueStatusEnum.in_progress,
    IssueStatusEnum.solved,
    IssueStatusEnum.closed,
];

export const IssueStatusColors: { [key in IssueStatusEnum]: string } = {
    [IssueStatusEnum.open]: 'rgb(207, 39, 39)',
    [IssueStatusEnum.in_progress]: 'rgb(254, 161, 49)',
    [IssueStatusEnum.solved]: 'rgb(80, 179, 43)',
    [IssueStatusEnum.closed]: 'rgb(128, 128, 128)',
    [IssueStatusEnum.deleted]: 'rgb(0, 0, 0)',
};

export const IssueStatusList = enumToKeysArray(IssueStatusEnum);

/**
 * Use only when need show legacy status name as custom status from default type "Default type"
 */
export const LegacyIssueStatusToCustomStatusName: Dict<string> = {
    [IssueStatusEnum.open]: 'Open',
    [IssueStatusEnum.in_progress]: 'In progress',
    [IssueStatusEnum.solved]: 'Solved',
    [IssueStatusEnum.closed]: 'Closed',
    [IssueStatusEnum.deleted]: 'Deleted',
};

export const CustomStatusNameToLegacyIssueStatus = _.invert(LegacyIssueStatusToCustomStatusName);
