import { v4 as uuidv4 } from 'uuid';
import { IMutableField } from '@/types/common';
import { parseSchedule } from '@/services';

export class ScheduledTask {
    public lastOnline: number | null;
    private _uuid: string; // uuid of TaskDefinition, not uniq for ScheduledTask
    private _displayName: IMutableField<string>;
    private _alias: IMutableField<string>;
    private _type: string;
    private _projectUuid: string;
    private _deviceId: string;
    private _creatorEmail: string;
    private _schedule: any;
    private _scheduleSkip: any;
    private _additionalProperties: any;
    private _settings: any;
    private _advanced: any;
    private _statistics: any;
    private _notifications: any;
    private _createdAt: any;
    private _updatedAt: any;
    private _executeAt: number;
    private _localUuid: string; // local uniq uuid

    constructor(args: any) {
        this._uuid = args.uuid;
        this._displayName = args.displayName;
        this._alias = args.alias;
        this._type = args.type;
        this._projectUuid = args.projectUuid;
        this._deviceId = args.deviceId;
        this.lastOnline = args.lastOnline || null;
        this._creatorEmail = args.creatorEmail;
        this._schedule = args.schedule;
        this._scheduleSkip = args.scheduleSkip;
        this._additionalProperties = args.additionalProperties;
        this._settings = args.settings;
        this._advanced = args.advanced;
        this._statistics = args.statistics;
        this._notifications = args.notifications;
        this._createdAt = args.createdAt;
        this._updatedAt = args.updatedAt;
        this._executeAt = args.executeAt;
        this._localUuid = uuidv4();
    }

    get uuid() {
        return this._uuid;
    }
    get displayName(): string {
        return this._displayName.value;
    }
    get alias(): string {
        return this._alias.value;
    }
    get type(): string {
        return this._type;
    }
    get taskType() {
        return this._settings?.value?.body?.from ? this._type + '_' + this._settings?.value?.body?.from : this._type;
    }
    get projectUuid(): string {
        return this._projectUuid;
    }
    get deviceId(): string {
        return this._deviceId;
    }
    get creatorEmail(): string {
        return this._creatorEmail;
    }
    get averageDuration(): number {
        return this._statistics.value.averageDuration;
    }
    get executeAt(): number {
        return this._executeAt;
    }

    get localUuid(): string {
        return this._localUuid;
    }

    get schedule(): any {
        return parseSchedule(this._schedule.value);
    }
}
