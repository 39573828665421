










import { Component, Vue } from 'vue-property-decorator';
import WsIcon from '@/components/common/WsIcon.vue';

@Component({
    components: {
        WsIcon,
    },
})
export default class WsTableRowMenu extends Vue {
    public isOpen: boolean = false;
}
