import Vue from 'vue';
import { UserApi } from '@/api';
import { Notifications } from '@/models';
import { notificationSuccess } from '@/services';

export default {
    state: {
        globalNotifications: new Notifications(),
        projectsNotificationsObj: {},
        isLoadingGlobalNotifications: false,
        isSendingGlobalNotifications: false,
        isLoadingProjectNotifications: false,
        isSendingProjectNotifications: false,
    },
    getters: {
        globalNotifications(state: any): Notifications {
            return state.globalNotifications;
        },
        projectNotificationsByProjectId(state: any): (projectId: number) => Notifications {
            return (projectId) => state.projectsNotificationsObj[projectId] || new Notifications();
        },
        isLoadingGlobalNotifications(state: any): boolean {
            return state.isLoadingGlobalNotifications;
        },
        isSendingGlobalNotifications(state: any): boolean {
            return state.isSendingGlobalNotifications;
        },
        isLoadingProjectNotifications(state: any): boolean {
            return state.isLoadingProjectNotifications;
        },
        isSendingProjectNotifications(state: any): boolean {
            return state.isSendingProjectNotifications;
        },
    },
    mutations: {
        setGlobalNotifications(state: any, notifications: Notifications) {
            state.globalNotifications = notifications;
        },
        setProjectNotifications(state: any, { projectId, notifications }: { projectId: number, notifications: Notifications }) {
            Vue.set(state.projectsNotificationsObj, projectId, notifications);
        },
        setIsLoadingGlobalNotifications(state: any, value: boolean) {
            state.isLoadingGlobalNotifications = value;
        },
        setIsSendingGlobalNotifications(state: any, value: boolean) {
            state.isSendingGlobalNotifications = value;
        },
        setIsLoadingProjectNotifications(state: any, value: boolean) {
            state.isLoadingProjectNotifications = value;
        },
        setIsSendingProjectNotifications(state: any, value: boolean) {
            state.isSendingProjectNotifications = value;
        },
    },
    actions: {
        loadGlobalNotifications({ state, commit }: any, isForce = false): Promise<Notifications> {
            return new Promise((resolve, reject) => {
                if (!isForce && state.globalNotifications.uuid) {
                    resolve(state.globalNotifications);
                    return;
                }

                commit('setIsLoadingGlobalNotifications', true);
                UserApi.getGlobalNotifications().then((response: any) => {
                    const notifications = new Notifications(response);
                    commit('setGlobalNotifications', notifications);
                    resolve(notifications);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingGlobalNotifications', false);
                });
            });
        },
        sendGlobalNotifications({ commit }: any, notifications: Notifications): Promise<Notifications> {
            return new Promise((resolve, reject) => {
                commit('setIsSendingGlobalNotifications', true);
                UserApi.postGlobalNotifications(notifications.apiParams).then(() => {
                    notificationSuccess('allSaved');
                    // api не возвращает сохраненную сущность, поэтому имитируем перезапись данных с помощью местной переменной
                    commit('setGlobalNotifications', notifications);
                    resolve(notifications);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingGlobalNotifications', false);
                });
            });
        },
        loadProjectNotifications({ commit }: any, projectId: number): Promise<Notifications> {
            // флаг isForce здесь не нужен, потому что нужно всегда выполнять этот запрос когда происходит переход на компонент где он вызывается
            // так как нужно будет обязательно перезапросить данные для случая, когда в проекте используются глобальные настройки, при следующей очередностьи действий:
            // переход на ProjectNotifications -> переход на GlobalNotifications -> изменение GlobalNotifications -> переход на ProjectNotifications
            return new Promise((resolve, reject) => {
                commit('setIsLoadingProjectNotifications', true);
                UserApi.getProjectNotifications(projectId).then((response) => {
                    const notifications = new Notifications(response);
                    commit('setProjectNotifications', { projectId, notifications });
                    resolve(notifications);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsLoadingProjectNotifications', false);
                });
            });
        },
        sendProjectNotifications({ commit }: any, { projectId, notifications }: any): Promise<Notifications> {
            return new Promise((resolve, reject) => {
                commit('setIsSendingProjectNotifications', true);
                UserApi.postProjectNotifications(projectId, notifications.apiParams).then(() => {
                    notificationSuccess('allSaved');
                    // api не возвращает сохраненную сущность, поэтому имитируем перезапись данных с помощью местной переменной
                    resolve(notifications);
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingProjectNotifications', false);
                });
            });
        },
        sendProjectNotificationsAsGlobal({ commit }: any, projectId: number): Promise<any> {
            return new Promise<void>((resolve, reject) => {
                commit('setIsSendingProjectNotifications', true);
                UserApi.deleteProjectNotifications(projectId).then(() => {
                    notificationSuccess('allSaved');
                    resolve();
                }).catch((error) => {
                    reject(error);
                }).finally(() => {
                    commit('setIsSendingProjectNotifications', false);
                });
            });
        },
    },
};
