/* eslint-disable import/order */
import Vue from 'vue';
import Router, { RawLocation, Route } from 'vue-router';
import Storage from '@/storage';
import { AmplitudeEvent, ProjectsTab, RouterNames, SAMLCookieNames } from '@/constants';
import { amplitudeInstance, amplitudeLog, isIncorrectLang, isTabFirst, loadLanguageAsync, syncTabs } from '@/services';
import RouterPage from '@/components/views/RouterPage.vue';
import Login from '@/components/user/Login.vue';

// @ts-ignore
import VueCookies from 'vue-cookies/vue-cookies.js';
import { ErrorHandler } from 'vue-router/types/router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            // список старых урлов для редиректа
            path: '/project/:projectId',
            children: [
                {
                    path: '',
                    redirect: { name: RouterNames.ProjectSettings },
                },
                {
                    path: 'info',
                    redirect: { name: RouterNames.ProjectSettings },
                },
                {
                    path: 'invitation',
                    redirect: { name: RouterNames.ProjectMembers },
                },
                {
                    path: 'dashboard',
                    redirect: { name: RouterNames.ProjectDashboards },
                },
                {
                    path: 'reporting',
                    redirect: { name: RouterNames.ProjectReports },
                },
                {
                    path: 'notifications',
                    redirect: { name: RouterNames.ProjectNotifications },
                },
            ],
        },
        {
            path: '/:language?',
            component: RouterPage,
            async beforeEnter(to: Route, from: Route, next: any) {
                await Storage.dispatch('downloadRegions');
                Storage.commit('loadRecentProjects');
                // Read tables headers
                Storage.commit('loadAllTableControls');
                Storage.commit('loadAllTableSort');

                // If it's not a public route
                if (!to.matched.some((record) => record.meta.allowedWithoutAuth)) {
                    const promises = [
                        Storage.dispatch('loadLicenses'),
                        Storage.dispatch('downloadUserData'),
                        Storage.dispatch('downloadRegionServiceData'),
                    ];

                    await Promise.all(promises).then(() => {
                        amplitudeInstance.setUserId(Storage.getters.userData.uuid);

                        // Check if this is an open authorized tab first
                        // if first, then send message in size
                        if (isTabFirst()) {
                            amplitudeLog(AmplitudeEvent.wsOpen, {
                                viewportWidth: innerWidth,
                                viewportHeight: innerHeight,
                                browserWidth: outerWidth,
                                browserHeight: outerHeight,
                                screenWidth: screen.width,
                                screenHeight: screen.height,
                            });
                        }

                        // Push to localStorage from a tab that considers itself active
                        // if the active tab is closed, another one takes over,
                        // as long as there are active authorized tabs
                        syncTabs();

                        Storage.dispatch('connectNotifier');
                    });

                    next();
                    return;
                }

                next();
            },
            children: [
                {
                    path: 'operational', // старый урл
                    component: () => import(/* webpackChunkName: 'MyProjects' */ './components/MyProjects.vue'),
                },
                {
                    path: 'suspended', // старый урл
                    component: () => import(/* webpackChunkName: 'MyProjects' */ './components/MyProjects.vue'),
                },
                {
                    path: 'archived', // старый урл
                    component: () => import(/* webpackChunkName: 'MyProjects' */ './components/MyProjects.vue'),
                },
                {
                    path: 'license/:licenseId',
                    component: () => import(/* webpackChunkName: 'LicenseRouter' */ './components/views/LicenseRouter.vue'),
                    children: [
                        {
                            path: '',
                            name: RouterNames.LicenseSettings,
                            component: () => import(/* webpackChunkName: 'LicenseSettings' */ './components/license/LicenseSettings.vue'),
                        },
                        {
                            path: 'operational',
                            name: RouterNames.MyLicenseProjects,
                            meta: { tab: ProjectsTab.operational },
                            component: () => import(/* webpackChunkName: 'MyLicenseProjects' */ './components/MyProjects.vue'),
                        },
                        {
                            path: 'archived',
                            name: RouterNames.MyLicenseProjectsArchived,
                            meta: { tab: ProjectsTab.archived },
                            component: () => import(/* webpackChunkName: 'MyProjects' */ './components/MyProjects.vue'),
                        },
                        {
                            path: 'dashboard',
                            name: RouterNames.LicenseDashboards,
                            component: () => import(/* webpackChunkName: 'LicenseDashboards' */ './components/license/dashboards/LicenseDashboards.vue'),
                            meta: { isNeedAdmin: true },
                        },
                        {
                            path: 'dashboard/:dashboardUuid',
                            name: RouterNames.LicenseDashboard,
                            component: () => import(/* webpackChunkName: 'LicenseDashboardComponent' */ './components/license/dashboards/LicenseDashboardComponent.vue'),
                            meta: { isNeedAdmin: true },
                        },
                        {
                            path: 'dashboard/:dashboardUuid/chart/:chartUuid',
                            name: RouterNames.LicenseDashboardChart,
                            component: () => import(/* webpackChunkName: 'LicenseDashboardChart' */ './components/chart/ChartSettings.vue'),
                            meta: { isNeedAdmin: true },
                        },
                        {
                            path: 'team',
                            redirect: { name: RouterNames.LicenseMembers },
                        },
                        {
                            path: 'members',
                            component: () => import(/* webpackChunkName: 'LicenseMembersRouter' */ './components/views/LicenseMembersRouter.vue'),
                            meta: { isNeedAdmin: true },
                            children: [
                                {
                                    path: '',
                                    name: RouterNames.LicenseMembers,
                                    component: () => import(/* webpackChunkName: 'LicenseMembers' */ './components/license/LicenseMembers.vue'),
                                },
                                {
                                    path: 'approval',
                                    name: RouterNames.LicenseMembersApproval,
                                    component: () => import(/* webpackChunkName: 'LicenseMembersApproval' */ './components/license/LicenseMembersApproval.vue'),
                                },
                                {
                                    path: 'auth-methods',
                                    name: RouterNames.LicenseMembersAuthMethods,
                                    component: () => import(/* webpackChunkName: 'LicenseMembersAuthMethods' */ './components/license/LicenseMembersAuthMethods.vue'),
                                },
                                {
                                    path: 'import-users',
                                    name: RouterNames.LicenseMembersImportUsers,
                                    component: () => import(/* webpackChunkName: 'LicenseMembersImportUsers' */ './components/license/AddLicenseMembers.vue'),
                                },
                                {
                                    path: 'activity',
                                    name: RouterNames.LicenseActivity,
                                    component: () => import(/* webpackChunkName: 'LicenseActivity' */ './components/license/LicenseActivity.vue'),
                                },
                                {
                                    path: 'additional-fields',
                                    name: RouterNames.LicenseMembersAdditionalFields,
                                    component: () => import(/* webpackChunkName: 'LicenseMembersAdditionalFields' */ './components/license/additionalFields/AdditionalFields.vue'),
                                },
                            ],
                        },
                        {
                            path: 'projects',
                            name: RouterNames.LicenseProjects,
                            component: () => import(/* webpackChunkName: 'LicenseProjects' */ './components/license/LicenseProjects.vue'),
                            meta: { isNeedAdmin: true },
                        },
                        {
                            path: 'project-roles',
                            name: RouterNames.ProjectRoles,
                            component: () => import(/* webpackChunkName: 'ProjectRoles' */ './components/license/roleComparison/ProjectRoles.vue'),
                            meta: { isNeedAdmin: true },
                        },
                        {
                            path: 'compare-roles/:rolesUuids',
                            name: RouterNames.CompareRoles,
                            component: () => import(/* webpackChunkName: 'CompareRoles' */ './components/license/roleComparison/CompareRoles.vue'),
                            meta: { isNeedAdmin: true },
                        },
                        {
                            path: 'role/:accessRoleId?',
                            name: RouterNames.ProjectAccessRole,
                            component: () => import(/* webpackChunkName: 'ProjectAccessRole' */ './components/license/ProjectAccessRole.vue'),
                            meta: { isNeedAdmin: true },
                        },
                        {
                            path: 'member/:memberId',
                            name: RouterNames.LicenseMemberSettings,
                            component: () => import(/* webpackChunkName: 'LicenseMemberSettings' */ './components/license/member/LicenseMemberSettings.vue'),
                        },
                        {
                            path: 'dashboard-presets',
                            name: RouterNames.DashboardPresets,
                            component: () => import(/* webpackChunkName: 'DashboardPresets' */ './components/license/DashboardPresets.vue'),
                        },
                        {
                            path: 'reporting-history',
                            name: RouterNames.ReportingHistory,
                            component: () => import(/* webpackChunkName: 'ReportingHistory' */ './components/license/ReportingHistory.vue'),
                        },
                        {
                            path: 'action-log',
                            name: RouterNames.ActionLog,
                            component: () => import(/* webpackChunkName: 'LicenseActionLogs' */ './components/license/LicenseActionLogs.vue'),
                            meta: { isNeedAdmin: true },
                        },
                        {
                            path: 'project/:projectId',
                            component: () => import(/* webpackChunkName: 'ProjectRouter' */ './components/views/ProjectRouter.vue'),
                            children: [
                                {
                                    path: '',
                                    redirect: { name: RouterNames.ProjectSettings },
                                },
                                {
                                    path: 'info',
                                    name: RouterNames.ProjectSettings,
                                    component: () => import(/* webpackChunkName: 'ProjectSettings' */ './components/project/ProjectSettings.vue'),
                                },
                                {
                                    path: 'invitation',
                                    redirect: { name: RouterNames.ProjectMembers },
                                },
                                {
                                    path: 'members',
                                    name: RouterNames.ProjectMembers,
                                    component: () => import(/* webpackChunkName: 'ProjectMembers' */ './components/project/ProjectMembers.vue'),
                                },
                                {
                                    path: 'issue-tracker',
                                    name: RouterNames.ProjectIssueTracker,
                                    component: () => import(/* webpackChunkName: 'ProjectIssueTracker' */ './components/project/issueTracker/ProjectIssueTracker.vue'),
                                },
                                {
                                    path: 'dashboard',
                                    name: RouterNames.ProjectDashboards,
                                    component: () => import(/* webpackChunkName: 'ProjectDashboards' */ './components/project/dashboards/ProjectDashboards.vue'),
                                },
                                {
                                    path: 'dashboard/:dashboardUuid',
                                    name: RouterNames.ProjectDashboard,
                                    component: () => import(/* webpackChunkName: 'ProjectDashboard' */ './components/project/dashboards/ProjectDashboardComponent.vue'),
                                },
                                {
                                    path: 'dashboard/:dashboardUuid/chart/:chartUuid',
                                    name: RouterNames.ProjectDashboardChart,
                                    component: () => import(/* webpackChunkName: 'ProjectDashboardChart' */ './components/chart/ChartSettings.vue'),
                                },
                                {
                                    path: 'reporting',
                                    name: RouterNames.ProjectReports,
                                    component: () => import(/* webpackChunkName: 'ProjectReports' */ './components/project/ProjectReports.vue'),
                                },
                                {
                                    path: 'scheduler',
                                    name: RouterNames.Scheduler,
                                    component: () => import(/* webpackChunkName: 'Scheduler' */ './components/project/scheduler/Scheduler.vue'),
                                    children: [
                                        {
                                            path: '',
                                            redirect: { name: RouterNames.SchedulerTasks },
                                        },
                                        {
                                            path: 'tasks',
                                            name: RouterNames.SchedulerTasks,
                                            component: () => import(/* webpackChunkName: 'Scheduler' */ './components/project/scheduler/SchedulerTasks.vue'),
                                        },
                                        {
                                            path: 'upcoming',
                                            name: RouterNames.SchedulerUpcoming,
                                            component: () => import(/* webpackChunkName: 'Scheduler' */ './components/project/scheduler/SchedulerUpcomingTasks.vue'),
                                        },
                                        {
                                            path: 'history',
                                            name: RouterNames.SchedulerHistory,
                                            component: () => import(/* webpackChunkName: 'Scheduler' */ './components/project/scheduler/SchedulerTaskHistory.vue'),
                                        },
                                    ],
                                },
                                {
                                    path: 'activity',
                                    name: RouterNames.ProjectActivity,
                                    component: () => import(/* webpackChunkName: 'ProjectActivity' */ './components/project/ProjectActivity.vue'),
                                },
                                {
                                    path: 'revisions',
                                    redirect: { name: RouterNames.ProjectRevisions },
                                },
                                {
                                    path: 'versions',
                                    name: RouterNames.ProjectRevisions,
                                    component: () => import(/* webpackChunkName: 'ProjectRevisions' */ './components/project/ProjectRevisions.vue'),
                                },
                                {
                                    path: 'notifications',
                                    name: RouterNames.ProjectNotifications,
                                    component: () => import(/* webpackChunkName: 'ProjectNotifications' */ './components/project/ProjectNotifications.vue'),
                                },
                                {
                                    path: 'log',
                                    name: RouterNames.ProjectLog,
                                    component: () => import(/* webpackChunkName: 'ProjectActionLogs' */ './components/project/ProjectActionLogs.vue'),
                                },
                                {
                                    path: 'issue-settings',
                                    name: RouterNames.IssueSettings,
                                    component: () => import(/* webpackChunkName: 'ProjectIssueSettings' */ './components/project/ProjectIssueSettings.vue'),
                                },
                                {
                                    path: 'workflows-settings',
                                    component: () => import(/* webpackChunkName: 'WorkflowsRouter' */ './components/project/workflow/WorkflowsRouter.vue'),
                                    children: [
                                        {
                                            path: '',
                                            redirect: { name: RouterNames.Workflows },
                                        },
                                        {
                                            path: 'workflow',
                                            name: RouterNames.Workflows,
                                            component: () => import(/* webpackChunkName: 'ProjectWorkflows' */ './components/project/workflow/Workflows.vue'),
                                            children: [
                                                {
                                                    path: ':workflowUuid',
                                                    name: RouterNames.Workflow,
                                                },
                                            ],
                                        },
                                        {
                                            path: 'issue-types',
                                            name: RouterNames.IssueTypes,
                                            component: () => import(/* webpackChunkName: 'ProjectIssueTypes' */ './components/project/workflow/IssueTypes.vue'),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'license-deleted',
                            name: RouterNames.LicenseDeleted,
                            component: () => import(/* webpackChunkName: 'LicenseDeleted' */ './components/license/LicenseDeleted.vue'),
                            meta: { isNeedAdmin: true },
                        },
                    ],
                },
                {
                    path: 'last-license-deleted',
                    name: RouterNames.LastLicenseDeleted,
                    component: () => import(/* webpackChunkName: 'LicenseDeleted' */ './components/license/LicenseDeleted.vue'),
                },
                {
                    path: 'user',
                    component: () => import(/* webpackChunkName: 'UserRouter' */ './components/views/UserRouter.vue'),
                    children: [
                        {
                            path: 'settings',
                            name: RouterNames.UserSettings,
                            component: () => import(/* webpackChunkName: 'UserSettings' */ './components/user/UserSettings.vue'),
                        },
                        {
                            path: 'security',
                            name: RouterNames.UserSecurity,
                            component: () => import(/* webpackChunkName: 'UserSecurity' */ './components/user/UserSecurity.vue'),
                        },
                        {
                            path: 'global-notifications',
                            name: RouterNames.GlobalNotifications,
                            component: () => import(/* webpackChunkName: 'GlobalNotifications' */ './components/user/GlobalNotifications.vue'),
                        },
                        {
                            path: 'devices-and-browsers',
                            name: RouterNames.DevicesAndBrowsers,
                            component: () => import(/* webpackChunkName: 'DevicesAndBrowsers' */ './components/user/DevicesAndBrowsers.vue'),
                        },
                        {
                            path: 'integrations',
                            name: RouterNames.Integrations,
                            component: () => import(/* webpackChunkName: 'DevicesAndBrowsers' */ './components/user/Integrations.vue'),
                        },
                    ],
                },
                {
                    path: 'contact-us',
                    name: RouterNames.ContactUs,
                    component: () => import(/* webpackChunkName: 'ContactUs' */ './components/ContactUs.vue'),
                },
                {
                    path: 'download/v:branch',
                    name: RouterNames.DownloadSpecificVersion,
                    component: () => import(/* webpackChunkName: 'Download' */ './components/download/Download.vue'),
                },
                {
                    path: 'download',
                    name: RouterNames.Download,
                    component: () => import(/* webpackChunkName: 'Download' */ './components/download/Download.vue'),
                },
                {
                    path: 'integration/:service/catch',
                    name: RouterNames.IntegrationCatch,
                    component: () => import(/* webpackChunkName: 'Integrations' */ './components/IntegrationsCatch.vue'),
                },
                {
                    path: 'integration/:service/:id?',
                    name: RouterNames.Integration,
                    component: () => import(/* webpackChunkName: 'Integrations' */ './components/Integrations.vue'),
                },
                {
                    path: 'icons',
                    name: RouterNames.Icons,
                    component: () => import(/* webpackChunkName: 'Icons' */ './components/common/icon/IconGallery.vue'),
                },
                {
                    path: 'login',
                    name: RouterNames.Login,
                    meta: { allowedWithoutAuth: true },
                    component: Login,
                    beforeEnter(to: Route, from: Route, next: any) {
                        if (!to.query.app_sso_key) {
                            Storage.dispatch('logout');
                        }
                        next();
                    },
                },
                {
                    path: 'oauth2redirect',
                    name: RouterNames.LoginOAuth2Redirect,
                    meta: { allowedWithoutAuth: true },
                    component: () => import(/* webpackChunkName: 'LoginOAuth2Redirect' */ '@/components/user/LoginOAuth2Redirect.vue'),
                },
                {
                    path: 'oauth',
                    name: RouterNames.OAuth,
                    meta: { allowedWithoutAuth: true },
                    component: () => import(/* webpackChunkName: 'Login' */ './components/user/Login.vue'),
                    beforeEnter(to: Route, from: Route, next: any) {
                        Storage.dispatch('logout');
                        next();
                    },
                },
                {
                    path: 'forgot',
                    name: RouterNames.ForgotPassword,
                    meta: { allowedWithoutAuth: true, isForgotPasswordRoute: true },
                    component: () => import(/* webpackChunkName: 'Login' */ './components/user/Login.vue'),
                    beforeEnter(to: Route, from: Route, next: any) {
                        Storage.dispatch('logout');
                        next();
                    },
                },
                {
                    path: 'select-license',
                    name: RouterNames.SelectLicense,
                    meta: { allowedWithoutAuth: true },
                    component: () => import(/* webpackChunkName: 'SelectLicense' */ './components/user/SelectLicense.vue'),
                },
                {
                    path: 'app-authorized',
                    name: RouterNames.AppAuthorized,
                    meta: { allowedWithoutAuth: true },
                    component: () => import(/* webpackChunkName: 'AppAuthorized' */ './components/user/AppAuthorized.vue'),
                },
                {
                    path: 'ws-authorized',
                    name: RouterNames.WsAuthorized,
                    meta: { allowedWithoutAuth: true },
                    component: () => import(/* webpackChunkName: 'WsAuthorized' */ './components/user/WsAuthorized.vue'),
                },
                {
                    path: 'saml-intermediate',
                    name: RouterNames.SAMLIntermediate,
                    meta: { allowedWithoutAuth: true },
                    beforeEnter(to: Route, from: Route, next: any) {
                        const { SAMLResponse } = to.query;
                        VueCookies.set(SAMLCookieNames.SAMLResponse, SAMLResponse);
                        const appSSO = Storage.getters.appSSO;
                        const redirectPath = appSSO.isEmpty ? 'ws-authorized' : 'app-authorized';
                        next({ path: redirectPath });
                    },
                },
                {
                    path: 'user/change_password/:key',
                    name: RouterNames.ChangePassword,
                    meta: { allowedWithoutAuth: true, isChangePasswordRoute: true  },
                    component: () => import(/* webpackChunkName: 'Login' */ './components/user/Login.vue'),
                    beforeEnter(to: Route, from: Route, next: any) {
                        Storage.dispatch('logout');
                        next();
                    },
                },
                {
                    path: 'register/:uid/:token',
                    name: RouterNames.Register,
                    meta: { allowedWithoutAuth: true, isRegisterRoute: true },
                    component: () => import(/* webpackChunkName: 'Login' */ './components/user/Login.vue'),
                    beforeEnter(to: Route, from: Route, next: any) {
                        Storage.dispatch('logout');
                        next();
                    },
                },
                {
                    path: 'validateUser/:userUuid',
                    name: RouterNames.ValidateUser,
                    meta: { allowedWithoutAuth: true },
                    component: () => import(/* webpackChunkName: 'ValidateUser' */ './components/ValidateUser.vue'),
                },
                {
                    path: 'unsubscribe/:entity',
                    name: RouterNames.Unsubscribe,
                    meta: { allowedWithoutAuth: true },
                    component: () => import(/* webpackChunkName: 'Unsubscribe' */ './components/Unsubscribe.vue'),
                },
                {
                    path: 'accessCode',
                    name: RouterNames.AccessCode,
                    meta: { allowedWithoutAuth: true },
                    component: () => import('@/components/AccessCode.vue'),
                },
                {
                    path: 'redirect-app',
                    name: RouterNames.RedirectApp,
                    meta: { allowedWithoutAuth: true },
                    component: () => import('@/components/RedirectApp.vue'),
                },
                {
                    path: '',
                    name: RouterNames.StartPage,
                    redirect: { name: RouterNames.MyLicenseProjects },
                },
                {
                    path: '*',
                    name: RouterNames.NotFound,
                    meta: { allowedWithoutAuth: true }, // todo нужно прокомментировать или удалить
                    component: () => import(/* webpackChunkName: 'NotFound' */ './components/NotFound.vue'),
                },
            ],
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

// The idea is to remove the error about Redundant navigation
// The solution is inspired by this comment:
// https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed/63263736#63263736
const originalPush = router.push;
// @ts-ignore
router.push = async function push(location: RawLocation, onComplete?: () => void, onAbort?: ErrorHandler) {
    if (onComplete || onAbort) {
        return originalPush.call(this, location, onComplete, onAbort);
    }

    try {
        await originalPush.call(this, location);
    } catch (error) {
        if (Router.isNavigationFailure(error)) {
            return error;
        }
        return Promise.reject(error);
    }
};

router.beforeEach((to: Route, from: Route, next: any) => {
    if (
        !from.matched.some((record) => record.meta.allowedWithoutAuth)
        && to.matched.some((record) => record.meta.allowedWithoutAuth)
    ) {
        Storage.commit('setStartPathAfterLogin', to.query.redirect || from.fullPath);
    }

    if (from.name !== RouterNames.Login) {
        Storage.commit('setIsStartScreen', from.fullPath === '/');
    }

    if (Number(to.params.licenseId)) {
        Storage.dispatch('changeCurrentLicenseId', Number(to.params.licenseId));
    }

    const language = isIncorrectLang(to.params.language) ? Storage.getters.currentLanguage : to.params.language;
    Storage.commit('setCurrentLanguage', language);
    loadLanguageAsync(language);

    // Если пользователь не авторизован редиректим на авторизацию
    // query redirect нужен, ибо в роутере входной шаг еще не записан и не попадет в from для navigateBack
    if (!to.matched.some((record) => record.meta.allowedWithoutAuth) && !Storage.getters.isAuthenticate) {
        next({ name: RouterNames.Login, query: { redirect: to.redirectedFrom || to.fullPath } });
        return;
    }

    next();
});

export default router;
