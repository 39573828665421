import { IActionLog } from '@/models';
import Http from './http';

export default class ActionLogApi {
    /** Получение списка действий в лицензии */
    public static getLicenseLogs(licenseId: number, page: number): Promise<{ list: IActionLog[], split: boolean }> {
        return Http.get(`action-log/license/${licenseId}`, { page }).then((response: any) => response.data);
    }

    /** Получение списка действий в проекте */
    public static getProjectLogs(projectId: number, page: number): Promise<{ list: IActionLog[], split: boolean }> {
        return Http.get(`action-log/project/${projectId}`, { page }).then((response: any) => response.data);
    }
}
