
























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { sanitizeHtml } from '@/services';

@Component({})
export default class WsRadio extends Vue {
    @Prop({ required: true }) public value!: number | string;
    @Prop({ type: String }) public label!: string;
    @Prop({ type: Array }) public items!: Array<{ value: number, text: string, subtext?: string }>;
    @Prop({ type: Boolean, default: false }) public disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public frozen!: boolean;

    get model() {
        return this.value;
    }
    set model(value: any) {
        this.input(value);
    }

    @Emit()
    public input(value: any) {
        return value;
    }

    public readonly sanitizeHtml = sanitizeHtml;

    public toggle(value: number) {
        if (!this.disabled) {
            this.input(value);
        }
    }
}
