









































import { Component, Emit, Vue } from 'vue-property-decorator';
import { Color } from '@/constants';
import IconSvg16 from '@/components/common/icon/IconSvg16.vue';

const SMALL_SCREEN_HEIGHT = 900;

@Component({
    components: {
        IconSvg16,
    },
})
export default class DialogBoarding extends Vue {
    public readonly Color = Color;
    public isVisible = true;

    get isHasFooter() {
        return Boolean(this.$slots.footer);
    }

    @Emit()
    public close() {
        return;
    }

    public mounted() {
        const vdialogComponent = this.$refs.vdialog as Vue | undefined;
        const topOffset = innerHeight > SMALL_SCREEN_HEIGHT ? '15vh' : '5vh';

        if (!vdialogComponent?.$refs.dialog) {
            return;
        }

        (vdialogComponent.$refs.dialog as HTMLElement).style.marginTop = topOffset;
    }
}
