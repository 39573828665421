import _ from 'lodash';
import Vue from 'vue';
import { RecentProject } from '@/models';

const LOCAL_STORAGE_KEY = 'recentProjects';

interface IRecentProjectsObj {
    [userId: number]: {
        [regionId: string]: {
            [licenseID: number]: RecentProject[];
        };
    };
}
interface IRecentProjectsState {
    recentProjectsObj: IRecentProjectsObj;
}

export default {
    state: {
        recentProjectsObj: {},
    },
    getters: {
        recentProjects(state: any): (licenseId: number, userId: number, regionId: string) => RecentProject[] {
            return (licenseId, userId, regionId) => {
                return (state.recentProjectsObj[userId]?.[regionId]?.[licenseId] || []).map(RecentProject.instantiate);
            };
        },
    },
    mutations: {
        loadRecentProjects(state: IRecentProjectsState) {
            const dataFromLocalStorage: any = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (dataFromLocalStorage) {
                state.recentProjectsObj = JSON.parse(dataFromLocalStorage);
            }
        },
        addRecentProject(state: IRecentProjectsState, { licenseId, newRecentProject, userId, regionId }: { licenseId: number, newRecentProject: RecentProject, userId: number, regionId: string }) {
            const existingRecentProjects = state.recentProjectsObj[userId]?.[regionId]?.[licenseId] ?? [];
            const modifiedRecentProjects = [newRecentProject, ...existingRecentProjects];
            const recentProjectsSlice = _.uniqBy(modifiedRecentProjects, ({ uuid }) => uuid).slice(0, 3);
            Vue.set(state.recentProjectsObj, userId, {
                [regionId]: {
                    [licenseId]: recentProjectsSlice,
                },
            });

            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.recentProjectsObj));
        },
        removeRecentProject(state: IRecentProjectsState, { licenseId, recentProjectsUuid, userId, regionId }: { licenseId: number, recentProjectsUuid: string[], userId: number, regionId: string }) {
            const existingRecentProjects = state.recentProjectsObj[userId]?.[regionId]?.[licenseId] ?? [];
            const modifiedRecentProjects = existingRecentProjects.filter((project: RecentProject) => !recentProjectsUuid.includes(project.uuid));
            const recentProjectsSlice = _.uniqBy(modifiedRecentProjects, ({ uuid }) => uuid).slice(0, 3);
            Vue.set(state.recentProjectsObj, userId, {
                [regionId]: {
                    [licenseId]: recentProjectsSlice,
                },
            });

            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state.recentProjectsObj));
        },
    },
};
