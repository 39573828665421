import _ from 'lodash';
import { Dict } from '@/types/Dict';
import { NumberBooleanType } from '@/constants';

function deboolify<K>(value: K | boolean) {
    return _.isBoolean(value) ? Number(value) as NumberBooleanType : value;
}

/**
 * Casts all the Boolean values in the object to 0s ans 1s
 * @param obj
 */
export function shallowDeboolifyObject(obj: Dict) {
    return _.mapValues(obj, deboolify);
}
