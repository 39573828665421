export function isChangedCreatedOrRemoved(value: any | null, valueList: any[]): 'changed' | 'created' | 'removed' {
    if (!value) {
        return 'removed';
    }

    if (value && valueList.includes(value)) {
        return 'changed';
    }

    return 'created';
}