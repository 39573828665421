



























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import WsButton from '@/components/common/WsButton.vue';

@Component({
    components: {
        WsButton,
    },
})
export default class WsFilterControls extends Vue {
    @Prop({ type: String, required: true }) public location!: string;

    get isShowFilters() {
        return !this.$store.getters.isFilterCollapsed(this.location);
    }

    @Emit()
    public resetAll() {
        return;
    }

    public toggle() {
        this.$store.dispatch('toggleFiltersCollapsed', this.location);
    }
}
