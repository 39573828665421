






































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import { AmplitudeEvent, BusEvent } from '@/constants';
import { eventBus } from '@/services/eventBus';
import { amplitudeLog } from '@/services';
import { IColumnSize, IColumnToggle } from '@/storage/issueTracker.storage';
import IconSvg24 from '@/components/common/icon/IconSvg24.vue';

@Component({
    components: {
        IconSvg24,
    },
})
export default class IssueColumn extends Vue {
    @Prop({ required: true }) public componentName!: string;
    @Prop({ required: true }) public icon!: string; // https://vuetifyjs.com/en/components/icons/
    @Prop({ required: true }) public title!: VueI18n.TranslateResult;
    @Prop() public issuesCount!: number | undefined;
    @Prop({ default: false }) public collapsed!: boolean;
    @Prop({ default: true }) public showContent!: boolean;
    @Prop({ default: false }) public hideTitle!: boolean;
    @Prop({ default: true }) public collapsable!: boolean;
    @Prop({ type: Boolean, default: true }) public resizable!: boolean;
    @Prop({ type: Boolean, default: false }) public loading!: boolean;
    @Prop({ default: 'right' }) public collapsingSide!: 'right' | 'left';
    @Prop({ default: false }) public hasIssuesCheckedContent!: boolean;

    get selectedIssuesCount() {
        return this.$store.getters.multiSelectedIssues.length;
    }

    get isBackgroundSolid() {
        return this.hasIssuesCheckedContent && !this.collapsed;
    }

    get isColumnClosed() {
        return this.$store.getters.isColumnClosed(this.componentName);
    }

    get isIssuesLoaded() {
        return this.issuesCount !== undefined;
    }

    @Emit()
    public toggleCollapseColumn() {
        amplitudeLog(AmplitudeEvent.itPanelsVisibilityChange, {
            panel: this.componentName,
            action: this.collapsed ? 'show' : 'collapse',
        });

        const toggle: IColumnToggle = {
            name: this.componentName,
            side: this.collapsingSide,
            collapsed: !this.collapsed,
        };

        this.$store.dispatch('toggleColumn', toggle);
        eventBus.$emit(BusEvent.toggleColumn);

        return !this.collapsed;
    }

    @Emit()
    public resize(data: any) {
        const resize: IColumnSize = {
            name: this.componentName,
            size: data.width,
        };
        this.$store.dispatch('resizeColumn', resize);
        return;
    }

    public toggleAllHeader() {
        if (this.collapsed) {
            this.toggleCollapseColumn();
        }
    }
}
