import { render, staticRenderFns } from "./WsCheckbox.vue?vue&type=template&id=266b9fc7&scoped=true&"
import script from "./WsCheckbox.vue?vue&type=script&lang=ts&"
export * from "./WsCheckbox.vue?vue&type=script&lang=ts&"
import style0 from "./WsCheckbox.vue?vue&type=style&index=0&id=266b9fc7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266b9fc7",
  null
  
)

export default component.exports