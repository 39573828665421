const shortMonth = 'MMM DD, YYYY';
const fullMonth = 'MMMM DD, YYYY';
const time = 'HH:mm';
const serverSide = 'YYYY-MM-DD HH:mm:ss';

export const MomentFormats = {
    time,
    default: shortMonth,
    defaultAndTime: `${shortMonth} ${time}`,
    fullMonth,
    fullMonthAndTime: `${fullMonth} ${time}`,
    serverSide,
};
