import { IssuesFilterType } from '@/constants';

export const TrackerFilterType = [
    IssuesFilterType.customType,
    IssuesFilterType.stampAbbr,
    IssuesFilterType.binding,
    IssuesFilterType.assignee,
    IssuesFilterType.assigneeCompany,
    IssuesFilterType.assigneeDepartment,
    IssuesFilterType.assigneeLocation,
    IssuesFilterType.watchers,
    IssuesFilterType.priority,
    IssuesFilterType.customStatus,
    IssuesFilterType.statusCategory,
    IssuesFilterType.deadline,
    IssuesFilterType.reporter,
    IssuesFilterType.tags,
    IssuesFilterType.stampCategory,
    IssuesFilterType.type,
    IssuesFilterType.clashDiscipline,
    IssuesFilterType.clashLevel,
    IssuesFilterType.clashArea,
    IssuesFilterType.clashRoom,
    IssuesFilterType.clashSpace,
    IssuesFilterType.clashZone,
    IssuesFilterType.clashSourceFile,
    IssuesFilterType.clashTest,
    IssuesFilterType.clashGridX,
    IssuesFilterType.clashGridY,
    IssuesFilterType.clashCategory,
    IssuesFilterType.procoreType,
    IssuesFilterType.procoreStatus,
    IssuesFilterType.mainSheet,
    IssuesFilterType.mainSheetTag,
    IssuesFilterType.visibility,
    IssuesFilterType.closed,
    IssuesFilterType.created,
    IssuesFilterType.stampColor,
    IssuesFilterType.id,
];

export type TrackerFilterTypeT = typeof TrackerFilterType[number];
