interface IHostnameAndPort {
    hostname: string;
    port: number;
}

const PORT_HTTP = 80;
const PORT_HTTPS = 443;

const protocolHostnameAndPortRegex = (function makeHostnameProtocolAndPortReg() {
    const regParts = {
        startAnchor: '^',
        protocolAndSlashes: '(?:(?<protocol>^[^:]+)(?::[/]+))',
        hostname: '(?<hostname>[^:/]+)',
        port: String.raw`(?::(?<port>\d*))`,
    };
    const regString = [
        regParts.startAnchor,
        regParts.protocolAndSlashes + '?',
        regParts.hostname,
        regParts.port + '?',
    ].join('');
    return RegExp(regString);
})();

function extractProtocolHostnameAndPort(url: string) {
    if (!protocolHostnameAndPortRegex.test(url)) {
        return null;
    }
    const match: any = url.match(protocolHostnameAndPortRegex);
    const { protocol, hostname, port } = match.groups;
    return { protocol, hostname, port };
}

function getHostnameAndPort(url: string): IHostnameAndPort | null {
    url = url ? String(url).trim() : '';
    const extractedParts = extractProtocolHostnameAndPort(url);
    if (!extractedParts) {
        return extractedParts;
    }
    const { protocol, hostname, port: portFromUrl } = extractedParts;
    const port = (function getPort() {
        if (portFromUrl) {
            return Number(portFromUrl);
        } else if (protocol === 'http') {
            return PORT_HTTP;
        } else {
            return PORT_HTTPS;
        }
    })();
    return { hostname, port };
}

export const URLParseService = {
    getHostnameAndPort,
};
