import { MemberRoleEnum } from '@/constants';

const Field = {
    guest: 'guest',
    paid: 'paid',
};

export class Activity {
    public constructor(
        public readonly valueObject: any,
    ) {}

    private get licenseRole() {
        return getNumber(this.valueObject.license_role);
    }

    public get isGuest() {
        return this.licenseRole === MemberRoleEnum.guest;
    }

    public get field() {
        return this.isGuest ? Field.guest : Field.paid;
    }

    public get count() {
        return getNumber(this.valueObject.events.open) + getNumber(this.valueObject.events.continue);
    }
}

function getNumber(str: string) {
    return parseInt(str, 10) || 0;
}
