export function SvgToPngConverter(svgElement: Node, width = 1024, height = 768): Promise<string> {
    return new Promise((resolve, reject) => {
        const svgData = new XMLSerializer().serializeToString(svgElement as Node);
        const svgDataBase64 = btoa(unescape(encodeURIComponent(svgData)));
        const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`;

        const image = new Image();

        const loadHandler = () => {
            image.removeEventListener('load', loadHandler);

            const canvas = document.createElement('canvas');

            canvas.setAttribute('width', width + 'px');
            canvas.setAttribute('height', height + 'px');

            const context = canvas.getContext('2d') as CanvasRenderingContext2D;
            context.drawImage(image, 0, 0, width, height);

            const dataUrl = canvas.toDataURL('image/png');
            resolve(dataUrl);
        };

        image.addEventListener('load', loadHandler);

        const errorHandler = (e: Event) => {
            image.removeEventListener('error', errorHandler);

            reject(e);
        };

        image.addEventListener('error', errorHandler);

        image.src = svgDataUrl;
    });
}
